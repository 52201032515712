import { IIcon } from '../../model/icon';

export const chat: IIcon = {
  id: `CHAT`,
  svg: `
  <g clip-path="url(#clip25)">
  <path fill="#F29C1F"
    d="M75.86 94.83v1.72c0 1.9-1.54 3.44-3.45 3.45H27.6a3.46 3.46 0 01-3.45-3.45v-1.72c0-10.3 6.12-19.62 15.57-23.73a18.97 18.97 0 0020.58 0 25.46 25.46 0 017.99 5.45 25.72 25.72 0 017.58 18.28z" />
  <path fill="#E57E25"
    d="M39.66 91.38V100H36.2v-8.62a1.72 1.72 0 113.45 0zM63.8 91.38V100h-3.46v-8.62a1.72 1.72 0 113.45 0zM39.7 71.1a25.88 25.88 0 00-15.56 23.73H2.98A3 3 0 010 91.84v-4.03c0-8.73 5.07-16.66 13-20.31a15.5 15.5 0 0021.05-2.07 18.68 18.68 0 005.66 5.67z" />
  <path fill="#F9C795"
    d="M34.05 65.43A15.52 15.52 0 1118.02 40.3l.07-.03 3.19-.55a15.47 15.47 0 0112.78 5.2v20.52z" />
  <path fill="#D25627" d="M13.8 87.93v6.9h-3.46v-6.9a1.72 1.72 0 013.45 0z" />
  <path fill="#E57E25"
    d="M100 87.81v4.03a3 3 0 01-2.98 2.99H75.86a25.72 25.72 0 00-7.58-18.28c-2.3-2.3-5-4.16-7.99-5.45 2.28-1.46 4.2-3.4 5.66-5.67A15.5 15.5 0 0087 67.5a22.33 22.33 0 0113 20.31z" />
  <path fill="#F9C795"
    d="M65.95 44.91a15.47 15.47 0 0112.77-5.2l3.2.55.06.03a15.52 15.52 0 11-16.03 25.14V44.91z" />
  <path fill="#D25627" d="M89.66 87.93v6.9H86.2v-6.9a1.72 1.72 0 113.45 0z" />
  <path fill="#FDD7AD" d="M54.03 36.66a18.98 18.98 0 11-8.06 0h8.06z" />
  <path fill="#3B97D3"
    d="M83.8 24.45a21.81 21.81 0 01-1.89 15.8 18.1 18.1 0 01-2 2.85 31.66 31.66 0 00-1.19-3.4c-1.36-3.34-3.94-8.25-8.05-10.68h-.01v-.07c-4.27.92-8.6 1.52-12.95 1.8v.03l-3.68 5.88-1.94 3.12L50 43.1l-2.09-3.32-1.94-3.12-3.68-5.88v-.02c-4.35-.3-8.67-.9-12.93-1.81h-.02v.07h-.01c-4.1 2.43-6.7 7.34-8.05 10.69-.46 1.1-.86 2.24-1.2 3.4a18.1 18.1 0 01-2-2.85v-.02a21.81 21.81 0 01-1.87-15.8c-4.78-2.5-7.59-5.6-7.59-8.92C8.62 6.95 27.16 0 50 0c22.84 0 41.38 6.95 41.38 15.52 0 3.32-2.81 6.43-7.59 8.93z" />
  <path fill="#fff"
    d="M50 18.97a3.45 3.45 0 100-6.9 3.45 3.45 0 000 6.9zM34.48 18.97a3.45 3.45 0 100-6.9 3.45 3.45 0 000 6.9zM65.52 18.97a3.45 3.45 0 100-6.9 3.45 3.45 0 000 6.9z" />
</g>
<defs>
  <clipPath id="clip25">
    <path fill="#fff" d="M0 0h100v100H0z" />
  </clipPath>
</defs>
  `
};
