import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { NotEmptyURLSearchParams } from 'src/app/shared/types/not-empty-url-search-params.type';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';

import { IPost } from '../../../../models/post';
import { IComment, ICommentForm } from '../../../../models/comment';
import { PostsFacade } from 'src/app/modules/posts/store';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  constructor(
    private base: BaseService,
    private convertIdService: ConvertIdService,
    private readonly _postsFacade: PostsFacade
  ) {}

  getPosts(page: number, isPrivate: boolean = false): Observable<IPost[]> {
    const url = isPrivate ? 'news/private' : 'news';

    const params = new NotEmptyURLSearchParams({
      page: page ? page + '' : undefined
    });

    return this.base
      .get(`${url}?${params}`)
      .pipe(map((res) => [...(res.advertising || []), ...(res.posts || [])]));
  }

  getPostsByUserId(userId: string, page: number) {
    return this._postsFacade.loadPostsForUser(userId, page);
  }

  getPostById(id) {
    return this._postsFacade.loadPost(id);
  }

  addPost(post: {
    description: string;
    links?: string[];
    foto: number[];
    file: number[];
  }) {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links = post.links?.length > 0 ? post.links : [''];

    return this.base
      .post('post/add', {
        ...post,
        links
      })
      .pipe(map((response: any) => response.post));
  }

  addPostToUser(
    post: {
      description: string;
      links?: string[];
      foto?: number[];
      file?: number[];
    },
    userId: string
  ) {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links = post.links?.length > 0 ? post.links : [''];

    return this.base
      .post(
        `user/post-add/${this.convertIdService.convertIdStringToNumber(
          userId
        )}`,
        {
          ...post,
          links
        }
      )
      .pipe(map((response: any) => response.post));
  }

  addGroupPost(
    post: {
      description: string;
      links?: string[];
      foto?: number[];
      file?: number[];
    },
    id: number
  ) {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links = post.links?.length > 0 ? post.links : [''];

    return this.base
      .post(`group/post/${id}`, {
        ...post,
        links
      })
      .pipe(map((response: any) => response.post));
  }

  updatePost(
    post: {
      description: string;
      links?: string[];
      foto?: number[];
      file?: number[];
    },
    id
  ) {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links = post.links?.length > 0 ? post.links : [''];

    return this.base
      .post(`post/save/${id}`, { ...post, links, share: id })
      .pipe(map((response: any) => response.post));
  }

  sharePost(share: {
    description: string;
    links?: string[];
    foto?: number[];
    file?: number[];
    share: number;
  }): Observable<IPost> {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links = share.links?.length > 0 ? share.links : [''];

    return this.base
      .post(`post/add`, {
        ...share,
        links
      })
      .pipe(map((response: any) => response.post));
  }

  likePost(id) {
    return this.base
      .get(`post/like/${id}`)
      .pipe(map((response: any) => response.like));
  }

  likeComment(id) {
    return this.base
      .get(`comment/like/${id}`)
      .pipe(map((response: any) => response.like));
  }

  deletePost(id) {
    return this.base
      .get(`post/del/${id}`)
      .pipe
      // map((response: any) => response)
      ();
  }

  complainePost(id) {
    return this.base
      .get(`post/complain/${id}`)
      .pipe(map((response: any) => response));
  }

  getCommentsToPost({ post, offset }: { post: IPost; offset: number }) {
    return this.base
      .get(`comment/post/${post.id}?offset=${offset}&order=desc`)
      .pipe(map((res) => res.comments));
  }

  deleteComment(id) {
    return this.base.get(`comment/del/${id}`).pipe(map((res) => res));
  }

  addCommentToPost(comment: ICommentForm) {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links = comment.links?.length > 0 ? comment.links : [''];

    return this.base
      .post('comment/add', {
        ...comment,
        links
      })
      .pipe(map((response: any) => response.comment));
  }

  replyComment(form: ICommentForm): Observable<IComment> {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links = form.links?.length > 0 ? form.links : [''];

    return this.base
      .post('comment/reply', {
        ...form,
        links
      })
      .pipe(map((res) => res['comment'] as IComment));
  }

  public pinPost(post: IPost) {
    return this.base.post(`post/pin/${post.id}`, {}).pipe(map(() => post));
  }

  public unpinPost(post: IPost) {
    return this.base.post(`post/unpin/${post.id}`, {}).pipe(map(() => post));
  }
}

export interface IMessageWithAssetIds {
  readonly text: string;
  readonly imagesIds: number[];
  readonly docsIds: number[];
}
