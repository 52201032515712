import { IIcon } from '../../model/icon';

export const image: IIcon = {
  id: `IMAGE`,
  svg: `
  <path d="M5.5 9V.7c0-.4.3-.7.7-.7h13.1c.4 0 .7.3.7.7V11c0 .4-.3.7-.7.7h-4.1L5.5 9z" fill="#2FA8CC" />
  <path d="M8.6 3.4a1 1 0 100-2 1 1 0 000 2z" fill="#F0C419" />
  <path d="M20 9.7V11c0 .4-.3.7-.7.7h-4.1l-7.3-2H20z" fill="#2980BA" />
  <path d="M20 5.5v4.2h-2l-4.5-4.3L15 3.7c.4.3 1 .4 1.5.4s1-.1 1.5-.5l1.9 2z" fill="#2C3E50" />
  <path d="M18 9.7H8L5.4 9v-.4L9.7 5c.5.4 1 .6 1.7.6.6 0 1.1-.2 1.6-.6l.5.5L18 9.7z" fill="#35495E" />
  <path
    d="M9 7.6h2.6c.3 0 .5.1.7.4l.5 1-2.5.7L8 9l.5-1c.1-.3.4-.4.6-.4zM2 7.6h.8c.3 0 .6.3.6.7v1.4h-2V8.3c0-.4.3-.7.7-.7z"
    fill="#3F5C6C" />
  <path d="M14.5 9H.7c-.4 0-.7.3-.7.7v9.6c0 .4.3.7.7.7h13.8c.4 0 .7-.3.7-.7V9.7c0-.4-.3-.7-.7-.7z" fill="#955BA5" />
  <path d="M9 18.6a4.1 4.1 0 100-8.3 4.1 4.1 0 000 8.3z" fill="#35495E" />
  <path d="M11.7 14.5a2.8 2.8 0 11-5.5 0 2.8 2.8 0 015.5 0z" fill="#2FA8CC" />
  <path d="M11 13.1A2.8 2.8 0 016.3 15v-.5a2.8 2.8 0 014.7-1.9v.5z" fill="#67B9CC" />
  <path d="M9 15.5a1 1 0 100-2 1 1 0 000 2z" fill="#3B97D3" />
  <path d="M3.4 11.7a.7.7 0 100-1.4.7.7 0 000 1.4z" fill="#F0C419" />
  <path d="M3.1 12.8V20h-.7v-7.2a.3.3 0 01.7 0z" fill="#894B9D" />
  <path
    d="M13 5c-.5.3-1 .5-1.6.5-.6 0-1.2-.2-1.7-.6l1.4-1.2c.2-.2.4-.2.5 0L13 4.9zM18.1 3.6c-.4.4-1 .6-1.5.5-.6 0-1.1-.1-1.5-.4l1.2-1.4a.3.3 0 01.5 0l1.3 1.3z"
    fill="#ECF0F1" />`
};
