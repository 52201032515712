<div class="dialog-content-overlay">
  <button class="btn-close" (click)="closeDialog(); false"></button>
  <h1 mat-dialog-title>
    <ng-container *ngIf="!isEdit; else editProjectTitle">Create project</ng-container>
    <ng-template #editProjectTitle>Edit project</ng-template>
  </h1>
  <div mat-dialog-content class="mb-4">
    <div class="form-wrapper py-0 px-3">
      <form [formGroup]="competitionProjectForm" (ngSubmit)="onSubmit()">
        <label class="background-wrapper d-flex mb-4">
          <div class="holder">
            <img [src]="loadedImage" class="loadedImage" *ngIf="loadedImage" />
            <img [src]="currentImage | baseUrl" class="loadedImage" *ngIf="currentImage" />
          </div>
          <div class="d-flex flex-column align-items-center justify-content-center m-auto">
            <div class="plus-icon">
              <input type="file" (change)="onImageChange($event)" accept="image/png, image/jpeg" />
              <svg viewBox="0 0 15 14">
                <use href="#plus-white"></use>
              </svg>
            </div>
            <div class="placeholder mt-2">
              <span>Add an image</span>
            </div>
          </div>
        </label>
        <mat-form-field class="w-100">
          <mat-label>Title</mat-label>
          <input matInput placeholder="Title*" formControlName="title" required autocomplete="off" />
          <mat-hint>i.e. FBLA Rushmore Academy or Science Club Rushmore Academy</mat-hint>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Topic</mat-label>
          <input matInput placeholder="Topic" formControlName="topic" autocomplete="off" />
          <mat-hint>i.e. FBLA Rushmore Academy or Science Club Rushmore Academy</mat-hint>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Starts date*</mat-label>
          <input
            matInput
            (click)="date_start.open(); false"
            (focus)="date_start.open(); false"
            [matDatepicker]="date_start"
            [min]="minDate"
            [max]="maxDate"
            formControlName="start_at"
            autocomplete="off"
            [readonly]="true"
          />
          <mat-datepicker-toggle matSuffix [for]="date_start"></mat-datepicker-toggle>
          <mat-datepicker #date_start></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Deadline date*</mat-label>
          <input
            matInput
            (click)="date_end.open(); false"
            (focus)="date_end.open(); false"
            [matDatepicker]="date_end"
            [min]="minEndDate"
            [max]="maxDate"
            formControlName="end_at"
            autocomplete="off"
            [readonly]="true"
          />
          <mat-datepicker-toggle matSuffix [for]="date_end"></mat-datepicker-toggle>
          <mat-datepicker #date_end></mat-datepicker>
        </mat-form-field>
        <h3>Description</h3>
        <app-editor [ctx]="description" class="editor rp-editor mb-3"></app-editor>
        <div class="add-item-field-wrapper d-flex">
          <mat-form-field class="w-100">
            <mat-label>Article link</mat-label>
            <input matInput placeholder="Article link" type="url" formControlName="url" autocomplete="off" />
          </mat-form-field>
          <button
            class="add-item-btn d-flex align-items-center justify-content-center"
            title="Create new article"
            (click)="createNewArticle(); false"
          >
            <svg viewBox="0 0 15 14">
              <use href="#plus-white" />
            </svg>
          </button>
          <button
            class="add-item-btn d-flex align-items-center justify-content-center"
            title="Remove article"
            (click)="removeArticle(); false"
            *ngIf="isArticle$ | async"
          >
            <svg viewBox="0 0 15 14">
              <use href="#plus-white" style="transform: rotateZ(45deg); transform-origin: center center" />
            </svg>
          </button>
        </div>
        <h3>Description/Instructions for jury</h3>
        <app-editor [ctx]="description_for_jury" class="editor rp-editor mb-3"></app-editor>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSubmit(); false" [disabled]="!competitionProjectForm.valid">
      <ng-container *ngIf="!isEdit; else editBtn">Create</ng-container>
      <ng-template #editBtn>Update</ng-template>
    </button>
  </div>
</div>
