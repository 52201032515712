import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { productionOnly } from 'src/app/utils';

declare const fbq;

@Injectable({
  providedIn: 'root'
})
export class FBQService {
  @productionOnly
  load() {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );

    (window as any).fbq('init', environment.fbqID);
  }

  @FBQService.withFBQ
  @productionOnly
  trackPageView() {
    fbq('track', 'PageView');
  }

  @FBQService.withFBQ
  @productionOnly
  trackCompleteRegistration({
    name
  }: {
    name: FBQService.CompleteRegistrationContent;
  }) {
    fbq('track', 'CompleteRegistration', { content_name: name });
  }

  static withFBQ(
    _target: Object,
    _propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args) {
      return typeof fbq !== 'undefined'
        ? originalMethod.apply(this, args)
        : () => {};
    };

    return descriptor;
  }
}

export namespace FBQService {
  export enum CompleteRegistrationContent {
    EMAIL_MODAL = 'email modal',
    BASIC_INFORMATION = 'basic information',
    EXTRACURRICULARS_INFORMATION = 'extracurriculars information',
    AVATAR = 'avatar'
  }
}
