import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, takeUntil } from 'rxjs/operators';

import { TopService } from './top.service';
import { searchTop, searchTopSuccess } from './top.actions';

@Injectable()
export class TopEffects implements OnDestroy {
  componentDestroy$ = new Subject();

  constructor(private actions$: Actions, private service: TopService) {}

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  effectForSearchGroups$ = createEffect(() =>
    this.actions$.pipe(
      takeUntil(this.componentDestroy$),
      ofType(searchTop),
      switchMap((params) =>
        this.service.searchTop(params).pipe(
          switchMap(
            ({ people, events, groups, organizations, discussions, clubs }) => [
              searchTopSuccess({
                people: this.normalize(people),
                events: this.normalize(events),
                groups: this.normalize(groups),
                organizations: this.normalize(organizations),
                discussions: this.normalize(discussions),
                clubs: this.normalize(clubs)
              })
            ]
          )
        )
      )
    )
  );

  private normalize(items) {
    return items.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
