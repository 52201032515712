import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ICompetitionParticipant } from '../../../model';

@Component({
  selector: 'app-competition-view-participant',
  templateUrl: './competition-view-participant.component.html',
  styleUrls: ['./competition-view-participant.component.scss']
})
export class CompetitionViewParticipantComponent {
  @Input() participant: ICompetitionParticipant;
  @Input() isAdminView: boolean;
  @Input() showAccept: boolean = true;
  @Input() showDecline: boolean = true;

  @Output() accept = new EventEmitter<number>();
  @Output() decline = new EventEmitter<number>();

  onAccept() {
    this.accept.emit(this.participant.id);
  }

  onDecline() {
    this.decline.emit(this.participant.id);
  }
}
