import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild
} from '@angular/core';
import { BaseService } from '../../../../core/services/base.service';
import { People } from '../../../../core/services/people/people.model';
import { fromEvent, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-message-tag-options',
  templateUrl: './tag-options.component.html',
  styleUrls: ['./tag-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageTagOptionsComponent implements OnInit, OnChanges {
  @Input() readonly tag: string;
  @Output() readonly select = new EventEmitter<People>();
  @Output() readonly leaveFocus = new EventEmitter<never>();

  @ViewChildren(`Option`)
  readonly optionsRef: QueryList<ElementRef<HTMLButtonElement>>;

  public state: string;
  public prevLen: number;
  public focusedIndex: number;

  public options: Observable<People[]> = of([]);

  constructor(readonly base: BaseService) {}
  // ---------------------------------------------
  //                   Lifecycle
  // ---------------------------------------------
  ngOnInit(): void {}

  ngOnChanges() {
    const noMatches = `No matching profiles`;
    const len = this.tag.length;
    const charsForSearch = 3;
    const diff = charsForSearch - len;

    if (len < charsForSearch)
      return (this.state = `${diff} chars before search`);
    if (this.state === noMatches && len >= this.prevLen) return; // #Return#

    this.prevLen = len;
    this.state = `Searching for profiles`;
    this.options = this.base.get(`user/find/${this.tag}`).pipe(
      map((response) => response['users'] ?? []),
      tap((users) => {
        this.state = users.length === 0 ? noMatches : ``;
      })
    );
  }
  // ---------------------------------------------
  //                   Events
  // ---------------------------------------------
  public onSelect(user: People) {
    this.select.emit(user);
  }

  public onKeyDown(e: KeyboardEvent) {
    const code = e.keyCode;
    const isDown = code === 40 || code === 83;
    const isUp = code === 38 || code === 87;
    const isBackTab = code === 9 && e.shiftKey;

    if (isDown || isUp) {
      e.preventDefault();
      const modif = isDown ? 1 : -1;
      const index = this.focusedIndex + modif;
      this.setFocused(index);
    } else if (isBackTab && this.focusedIndex === 0) {
      e.preventDefault();
      this.leaveFocus.emit();
    }
  }

  public onFocus(index: number) {
    this.focusedIndex = index;
  }
  // ---------------------------------------------
  //                   Utils
  // ---------------------------------------------
  public setFocused(index: number) {
    const elem = this.optionsRef.toArray()[index];
    if (!elem) return; // #Return#
    elem.nativeElement.focus();
  }
}
