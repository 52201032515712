import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import {
  IProfile,
  ProfileLinkService
} from 'src/app/shared/services/links/profile-link/profile-link.service';

import { AppBaseUrlPipe } from '../../../baseurl/pipes';

@Directive({
  selector: 'a[profile]'
})
export class ProfileLinkDirective implements OnDestroy {
  @Input('profile') set profile(profile: null | IProfile) {
    this._profile$.next(profile);
  }

  @Input('target') set target(target: null | ITarget) {
    this._target$.next(target);
  }

  private readonly _profile$ = new BehaviorSubject<IProfile>(null);
  private readonly _target$ = new BehaviorSubject<ITarget>('_blank');
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _profileLinkService: ProfileLinkService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe
  ) {
    this._profile$
      .pipe(
        // TODO: hot fix default profile with id 0
        // fix profile store to not use default profile object with default values
        // and remove this check
        tap((_) =>
          _ && _.id
            ? this._el.nativeElement.setAttribute('href', this.generateRoute(_))
            : this._el.nativeElement.removeAttribute('href')
        ),
        takeUntil(this._destroy$)
      )
      .subscribe();

    this._target$
      .pipe(
        tap((_) =>
          _
            ? this._el.nativeElement.setAttribute('target', _)
            : this._el.nativeElement.removeAttribute('target')
        ),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private generateRoute(profile: IProfile) {
    const link = this._profileLinkService.generateProfileLink(profile);
    const url = this._router.serializeUrl(this._router.createUrlTree(link));

    return this._appBaseUrlPipe.transform(url);
  }
}

type ITarget = '_self' | '_blank' | '_parent' | '_top';
