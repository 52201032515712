import { IStudent } from '../../shared/models/user/student';

export const GUEST: IStudent = {
  id: 0,
  type: `student`,
  activity: 0,
  afterschool: [],
  evaluation: ``,
  features: [],
  honorsandavards: [],
  icon: ``,
  icon_id: 0,
  interest: [],
  interests: [],
  is_friend: false,
  is_admin: false,
  is_mobile: 0,
  key: ``,
  language: [],
  list_interest: [],
  location_id: null,
  member_of_array: [],
  name: ``,
  otherexperience: [],
  previosschool: [],
  progress: 0,
  school: ``,
  scores: [],
  status_id: null,
  teachers_search: [],
  parents: null,
  location: {
    city: {
      id: null,
      name: ``
    },
    country: {
      id: null,
      name: ``
    },
    id: null,
    lat: ``,
    lng: ``,
    zip: ``
  },
  student: {
    about_me: ``,
    born: ``,
    cur_school_id: null,
    currentschool: {
      address: {
        id: null,
        lat: ``,
        lng: ``,
        name: ``
      },
      address_id: null,
      id: null,
      name: ``
    },
    id: null,
    lastname: ``,
    school_id: null,
    youtube: ``
  }
};
