import { Injectable } from '@angular/core';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { loadJob, loadJobSuccess } from './job.actions';
import { JobService } from './job.service';
import { of } from 'rxjs';
import { RoutingService } from '../routing.service';
import { Error403Component } from 'src/app/pages/error-403/error-403.component';

@Injectable()
export class JobEffects {
  constructor(
    private actions$: Actions,
    private jobService: JobService,
    private routing: RoutingService
  ) {}

  effectForLoadJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadJob),
      switchMap((params) =>
        this.jobService.getJobById(params.id).pipe(
          switchMap(({ job }) => [loadJobSuccess({ job })]),
          catchError((_) => this.handleJobError(_.error, { id: params.id }))
        )
      )
    )
  );

  private handleJobError(error, params) {
    console.log('handleJobError: ', error);

    switch (error?.code) {
      case 403:
        this.routing.goTo403({
          skipLocationChange: true,
          state: { type: Error403Component.Type.Organization, ...params }
        });
        break;
      default:
        this.routing.goTo404({ skipLocationChange: true });
    }

    return of(loadJobSuccess({ job: null }));
  }
}
