import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import { Request } from './request.model';
import * as RequestsActions from './requests.actions';

export const requestsFeatureKey = 'requests';

const AMOUNT_OF_USERS_ON_ONE_PAGE = 20;

export interface State {
  requests: { [id: number]: Request };
  loading: boolean;
  count: number;
  amountOfPages: number;
}

export const initialState: State = {
  requests: null,
  loading: false,
  count: null,
  amountOfPages: null
};

export const reducer = createReducer(
  initialState,
  on(RequestsActions.loadRequests, (state, action): State => {
    return {
      ...state,
      loading: true,
      requests: null
    };
  }),
  on(RequestsActions.loadRequestsSuccess, (state, action): State => {
    return {
      ...state,
      requests: action.requests,
      count: action.count,
      loading: false,
      amountOfPages: Math.ceil(action.count / AMOUNT_OF_USERS_ON_ONE_PAGE)
    };
  }),
  on(RequestsActions.acceptRequestSuccess, (state, action): State => {
    const requests = { ...state.requests };
    delete requests[action.id];

    const count = state.count - 1;

    return {
      ...state,
      requests,
      count,
      loading: false,
      amountOfPages: Math.ceil(count / AMOUNT_OF_USERS_ON_ONE_PAGE)
    };
  }),
  on(RequestsActions.rejectRequestSuccess, (state, action): State => {
    const requests = { ...state.requests };
    delete requests[action.id];

    const count = state.count - 1;

    return {
      ...state,
      requests,
      count,
      loading: false,
      amountOfPages: Math.ceil(count / AMOUNT_OF_USERS_ON_ONE_PAGE)
    };
  })
);

export const selectState = createFeatureSelector<State>(requestsFeatureKey);

export const selectRequests = () =>
  createSelector(selectState, (state: State) => {
    if (!state.requests) {
      return {
        items: null,
        loading: state.loading,
        count: state.count,
        amountOfPages: 0
      };
    }

    return {
      items: Object.values(state.requests),
      loading: state.loading,
      count: state.count,
      amountOfPages: state.amountOfPages
    };
  });
