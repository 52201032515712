import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupChatsSectionsService {
  private readonly _LOCAL_STORAGE_OPEN_SECTIONS_ID =
    'group-chats-open-sections';

  private readonly _statusObjects = new Map<number, BehaviorSubject<boolean>>();

  isOpened$(id: number) {
    return this.getStatusObject$(id);
  }

  open(id: number) {
    const isOpen = true;

    this.setStatusToStorage(id, isOpen);

    const statusObject = this.getStatusObject$(id);
    statusObject.next(isOpen);
  }

  close(id: number) {
    const isOpen = false;

    this.setStatusToStorage(id, isOpen);

    const statusObject = this.getStatusObject$(id);
    statusObject.next(isOpen);
  }

  private getStatusObject$(id: number) {
    if (this._statusObjects.has(id)) {
      return this._statusObjects.get(id);
    }

    const status = this.getStatusFromStorage(id);

    const statusObject = new BehaviorSubject(status);

    this._statusObjects.set(id, statusObject);

    return statusObject;
  }

  private getStatusFromStorage(id: number) {
    const statusObjectsJSON =
      localStorage.getItem(this._LOCAL_STORAGE_OPEN_SECTIONS_ID) || '{}';
    const statusObjects = JSON.parse(statusObjectsJSON);

    return statusObjects[id] || false;
  }

  private setStatusToStorage(id: number, status: boolean) {
    const statusObjectsJSON =
      localStorage.getItem(this._LOCAL_STORAGE_OPEN_SECTIONS_ID) || '{}';
    const statusObjects = JSON.parse(statusObjectsJSON);
    statusObjects[id] = status;

    localStorage.setItem(
      this._LOCAL_STORAGE_OPEN_SECTIONS_ID,
      JSON.stringify(statusObjects)
    );
  }
}
