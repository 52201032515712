import { Action } from '@ngrx/store';

import {
  IClub,
  IClubMediaFile,
  IClubMediaPhoto,
  IClubMember,
  IClubMemberType
} from '../../model';

export enum ActionTypes {
  LOAD_CLUB = '[Club] Load Club',
  LOAD_CLUB_FAILURE = '[Club] Load Club Failure',
  LOAD_CLUB_SUCCESS = '[Club] Load Club Success',

  REFRESH_CLUB = '[Club] Refresh Club',
  REFRESH_CLUB_FAILURE = '[Club] Refresh Club Failure',
  REFRESH_CLUB_SUCCESS = '[Club] Refresh Club Success',

  LOAD_CLUB_MEMBERS = '[Club] Load Club Members',
  LOAD_CLUB_MEMBERS_FAILURE = '[Club] Load Club Members Failure',
  LOAD_CLUB_MEMBERS_SUCCESS = '[Club] Load Club Members Success',

  LOAD_CLUB_MEDIA_PHOTOS = '[Club] Load Club Media Photos',
  LOAD_CLUB_MEDIA_PHOTOS_FAILURE = '[Club] Load Club Media Photos Failure',
  LOAD_CLUB_MEDIA_PHOTOS_SUCCESS = '[Club] Load Club Media Photos Success',

  CLUB_UPLOAD_MEDIA_PHOTO = '[Club] Upload Media Photo',
  CLUB_UPLOAD_MEDIA_PHOTO_FAILURE = '[Club] Upload Media Photo Failure',
  CLUB_UPLOAD_MEDIA_PHOTO_SUCCESS = '[Club] Upload Media Photo Success',

  LOAD_CLUB_MEDIA_FILES = '[Club] Load Club Media Files',
  LOAD_CLUB_MEDIA_FILES_FAILURE = '[Club] Load Club Media Files Failure',
  LOAD_CLUB_MEDIA_FILES_SUCCESS = '[Club] Load Club Media Files Success',

  CLUB_UPLOAD_MEDIA_FILE = '[Club] Upload Media Files',
  CLUB_UPLOAD_MEDIA_FILE_FAILURE = '[Club] Upload Media Files Failure',
  CLUB_UPLOAD_MEDIA_FILE_SUCCESS = '[Club] Upload Media Files Success',

  LOAD_CLUB_REQUESTS = '[Club] Load Club Requests',
  LOAD_CLUB_REQUESTS_FAILURE = '[Club] Load Club Requests Failure',
  LOAD_CLUB_REQUESTS_SUCCESS = '[Club] Load Club Requests Success',

  CLUB_UPDATE_MEMBER_TYPE = '[Club] Update Member Type',
  CLUB_UPDATE_MEMBER_TYPE_FAILURE = '[Club] Update Member Type Failure',
  CLUB_UPDATE_MEMBER_TYPE_SUCCESS = '[Club] Update Member Type Success',

  CLUB_CONNECT_MEMBER = '[Club] Connect Member',
  CLUB_CONNECT_MEMBER_FAILURE = '[Club] Connect Member Failure',
  CLUB_CONNECT_MEMBER_SUCCESS = '[Club] Connect Member Success',

  CLUB_DISCONNECT_MEMBER = '[Club] Disconnect Member',
  CLUB_DISCONNECT_MEMBER_FAILURE = '[Club] Disconnect Member Failure',
  CLUB_DISCONNECT_MEMBER_SUCCESS = '[Club] Disconnect Member Success',

  CLUB_REMOVE_MEMBER = '[Club] Remove Member',
  CLUB_REMOVE_MEMBER_FAILURE = '[Club] Remove Member Failure',
  CLUB_REMOVE_MEMBER_SUCCESS = '[Club] Remove Member Success',

  CLUB_ACCEPT_MEMBER_REQUEST = '[Club] Accept Member Request',
  CLUB_ACCEPT_MEMBER_REQUEST_FAILURE = '[Club] Accept Member Request Failure',
  CLUB_ACCEPT_MEMBER_REQUEST_SUCCESS = '[Club] Accept Member Request Success',

  CLUB_DECLINE_MEMBER_REQUEST = '[Club] Decline Member Request',
  CLUB_DECLINE_MEMBER_REQUEST_FAILURE = '[Club] Decline Member Request Failure',
  CLUB_DECLINE_MEMBER_REQUEST_SUCCESS = '[Club] Decline Member Request Success',

  CLUB_INVITE_MEMBER = '[Club] Invite Member',
  CLUB_INVITE_MEMBER_FAILURE = '[Club] Invite Member Failure',
  CLUB_INVITE_MEMBER_SUCCESS = '[Club] Invite Member Success',

  JOIN_TO_CLUB = '[Club] Join to Club',
  JOIN_TO_CLUB_FAILURE = '[Club] Join to Club Failure',
  JOIN_TO_CLUB_SUCCESS = '[Club] Join to Club Success',

  LEAVE_CLUB = '[Club] Leave Club',
  LEAVE_CLUB_FAILURE = '[Club] Leave Club Failure',
  LEAVE_CLUB_SUCCESS = '[Club] Leave Club Success',

  UPDATE_CLUB = '[Club] Update Club',
  UPDATE_CLUB_FAILURE = '[Club] Update Club Failure',
  UPDATE_CLUB_SUCCESS = '[Club] Update Club Success',

  REMOVE_CLUB = '[Club] Remove Club',
  REMOVE_CLUB_FAILURE = '[Club] Remove Club Failure',
  REMOVE_CLUB_SUCCESS = '[Club] Remove Club Success'
}

export class LoadClubAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB;

  constructor(public payload: { clubId: number }) {}
}

export class LoadClubFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadClubSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_SUCCESS;

  constructor(public payload: { club: IClub }) {}
}

export class RefreshClubAction implements Action {
  readonly type = ActionTypes.REFRESH_CLUB;

  constructor(public payload: { clubId: number }) {}
}

export class RefreshClubFailureAction implements Action {
  readonly type = ActionTypes.REFRESH_CLUB_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class RefreshClubSuccessAction implements Action {
  readonly type = ActionTypes.REFRESH_CLUB_SUCCESS;

  constructor(public payload: { club: IClub }) {}
}

export class LoadClubMembersAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEMBERS;

  constructor(
    public payload: { clubId: number; term?: string; page: number }
  ) {}
}

export class LoadClubMembersFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEMBERS_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadClubMembersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEMBERS_SUCCESS;

  constructor(
    public payload: { members: IClubMember[]; membersTotalCount: number }
  ) {}
}

export class LoadClubMediaPhotosAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEDIA_PHOTOS;

  constructor(public payload: { clubId: number; page: number }) {}
}

export class LoadClubMediaPhotosFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEDIA_PHOTOS_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadClubMediaPhotosSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEDIA_PHOTOS_SUCCESS;

  constructor(
    public payload: { photos: IClubMediaPhoto[]; photosTotalCount: number }
  ) {}
}

export class ClubUploadMediaPhotoAction implements Action {
  readonly type = ActionTypes.CLUB_UPLOAD_MEDIA_PHOTO;

  constructor(public payload: { clubId: number; file: File }) {}
}

export class ClubUploadMediaPhotoFailureAction implements Action {
  readonly type = ActionTypes.CLUB_UPLOAD_MEDIA_PHOTO_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubUploadMediaPhotoSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_UPLOAD_MEDIA_PHOTO_SUCCESS;

  constructor(public payload: { photo: IClubMediaPhoto }) {}
}

export class LoadClubMediaFilesAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEDIA_FILES;

  constructor(public payload: { clubId: number; page: number }) {}
}

export class LoadClubMediaFilesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEDIA_FILES_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadClubMediaFilesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_MEDIA_FILES_SUCCESS;

  constructor(
    public payload: { files: IClubMediaFile[]; filesTotalCount: number }
  ) {}
}

export class ClubUploadMediaFileAction implements Action {
  readonly type = ActionTypes.CLUB_UPLOAD_MEDIA_FILE;

  constructor(public payload: { clubId: number; file: File }) {}
}

export class ClubUploadMediaFileFailureAction implements Action {
  readonly type = ActionTypes.CLUB_UPLOAD_MEDIA_FILE_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubUploadMediaFileSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_UPLOAD_MEDIA_FILE_SUCCESS;

  constructor(public payload: { file: IClubMediaFile }) {}
}

export class ClubUpdateMemberTypeAction implements Action {
  readonly type = ActionTypes.CLUB_UPDATE_MEMBER_TYPE;

  constructor(
    public payload: { clubId: number; memberId: number; type: IClubMemberType }
  ) {}
}

export class ClubUpdateMemberTypeFailureAction implements Action {
  readonly type = ActionTypes.CLUB_UPDATE_MEMBER_TYPE_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubUpdateMemberTypeSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_UPDATE_MEMBER_TYPE_SUCCESS;

  constructor(
    public payload: { clubId: number; memberId: number; type: IClubMemberType }
  ) {}
}

export class ClubConnectMemberAction implements Action {
  readonly type = ActionTypes.CLUB_CONNECT_MEMBER;

  constructor(public payload: { memberId: number }) {}
}

export class ClubConnectMemberFailureAction implements Action {
  readonly type = ActionTypes.CLUB_CONNECT_MEMBER_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubConnectMemberSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_CONNECT_MEMBER_SUCCESS;

  constructor(public payload: { memberId: number }) {}
}

export class ClubDisconnectMemberAction implements Action {
  readonly type = ActionTypes.CLUB_DISCONNECT_MEMBER;

  constructor(public payload: { memberId: number }) {}
}

export class ClubDisconnectMemberFailureAction implements Action {
  readonly type = ActionTypes.CLUB_DISCONNECT_MEMBER_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubDisconnectMemberSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_DISCONNECT_MEMBER_SUCCESS;

  constructor(public payload: { memberId: number }) {}
}

export class ClubRemoveMemberAction implements Action {
  readonly type = ActionTypes.CLUB_REMOVE_MEMBER;

  constructor(public payload: { clubId: number; memberId: number }) {}
}

export class ClubRemoveMemberFailureAction implements Action {
  readonly type = ActionTypes.CLUB_REMOVE_MEMBER_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubRemoveMemberSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_REMOVE_MEMBER_SUCCESS;

  constructor(public payload: { clubId: number; memberId: number }) {}
}

export class LoadClubRequestsAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_REQUESTS;

  constructor(public payload: { clubId: number; page: number }) {}
}

export class LoadClubRequestsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_REQUESTS_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadClubRequestsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CLUB_REQUESTS_SUCCESS;

  constructor(
    public payload: { requests: IClubMember[]; requestsTotalCount: number }
  ) {}
}

export class ClubAcceptMemberRequestAction implements Action {
  readonly type = ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST;

  constructor(public payload: { clubId: number; memberId: number }) {}
}

export class ClubAcceptMemberRequestFailureAction implements Action {
  readonly type = ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubAcceptMemberRequestSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST_SUCCESS;

  constructor(public payload: { clubId: number; memberId: number }) {}
}

export class ClubDeclineMemberRequestAction implements Action {
  readonly type = ActionTypes.CLUB_DECLINE_MEMBER_REQUEST;

  constructor(public payload: { clubId: number; memberId: number }) {}
}

export class ClubDeclineMemberRequestFailureAction implements Action {
  readonly type = ActionTypes.CLUB_DECLINE_MEMBER_REQUEST_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubDeclineMemberRequestSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_DECLINE_MEMBER_REQUEST_SUCCESS;

  constructor(public payload: { clubId: number; memberId: number }) {}
}

export class ClubInviteMemberAction implements Action {
  readonly type = ActionTypes.CLUB_INVITE_MEMBER;

  constructor(public payload: { clubId: number; email: string }) {}
}

export class ClubInviteMemberFailureAction implements Action {
  readonly type = ActionTypes.CLUB_INVITE_MEMBER_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class ClubInviteMemberSuccessAction implements Action {
  readonly type = ActionTypes.CLUB_INVITE_MEMBER_SUCCESS;
}

export class JoinToClubAction implements Action {
  readonly type = ActionTypes.JOIN_TO_CLUB;

  constructor(public payload: { clubId: number; message?: string }) {}
}

export class JoinToClubFailureAction implements Action {
  readonly type = ActionTypes.JOIN_TO_CLUB_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class JoinToClubSuccessAction implements Action {
  readonly type = ActionTypes.JOIN_TO_CLUB_SUCCESS;
}

export class LeaveClubAction implements Action {
  readonly type = ActionTypes.LEAVE_CLUB;

  constructor(public payload: { clubId: number }) {}
}

export class LeaveClubFailureAction implements Action {
  readonly type = ActionTypes.LEAVE_CLUB_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LeaveClubSuccessAction implements Action {
  readonly type = ActionTypes.LEAVE_CLUB_SUCCESS;

  constructor(public payload: { clubId: number }) {}
}

export class UpdateClubAction implements Action {
  readonly type = ActionTypes.UPDATE_CLUB;

  constructor(public payload: { id: number; club: IClub }) {}
}

export class UpdateClubFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_CLUB_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class UpdateClubSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_CLUB_SUCCESS;

  constructor(public payload: { club: IClub }) {}
}

export class RemoveClubAction implements Action {
  readonly type = ActionTypes.REMOVE_CLUB;

  constructor(public payload: { id: number }) {}
}

export class RemoveClubFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_CLUB_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class RemoveClubSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_CLUB_SUCCESS;

  constructor(public payload: { id: number }) {}
}

export type Actions =
  | LoadClubAction
  | LoadClubFailureAction
  | LoadClubSuccessAction
  | RefreshClubAction
  | RefreshClubFailureAction
  | RefreshClubSuccessAction
  | LoadClubMembersAction
  | LoadClubMembersFailureAction
  | LoadClubMembersSuccessAction
  | ClubUpdateMemberTypeAction
  | ClubUpdateMemberTypeFailureAction
  | ClubUpdateMemberTypeSuccessAction
  | ClubConnectMemberAction
  | ClubConnectMemberFailureAction
  | ClubConnectMemberSuccessAction
  | ClubDisconnectMemberAction
  | ClubDisconnectMemberFailureAction
  | ClubDisconnectMemberSuccessAction
  | ClubRemoveMemberAction
  | ClubRemoveMemberFailureAction
  | ClubRemoveMemberSuccessAction
  | LoadClubMediaPhotosAction
  | LoadClubMediaPhotosFailureAction
  | LoadClubMediaPhotosSuccessAction
  | ClubUploadMediaPhotoAction
  | ClubUploadMediaPhotoFailureAction
  | ClubUploadMediaPhotoSuccessAction
  | LoadClubMediaFilesAction
  | LoadClubMediaFilesFailureAction
  | LoadClubMediaFilesSuccessAction
  | ClubUploadMediaFileAction
  | ClubUploadMediaFileFailureAction
  | ClubUploadMediaFileSuccessAction
  | LoadClubRequestsAction
  | LoadClubRequestsFailureAction
  | LoadClubRequestsSuccessAction
  | ClubAcceptMemberRequestAction
  | ClubAcceptMemberRequestFailureAction
  | ClubAcceptMemberRequestSuccessAction
  | ClubDeclineMemberRequestAction
  | ClubDeclineMemberRequestFailureAction
  | ClubDeclineMemberRequestSuccessAction
  | ClubInviteMemberAction
  | ClubInviteMemberFailureAction
  | ClubInviteMemberSuccessAction
  | JoinToClubAction
  | JoinToClubFailureAction
  | JoinToClubSuccessAction
  | LeaveClubAction
  | LeaveClubFailureAction
  | LeaveClubSuccessAction
  | UpdateClubAction
  | UpdateClubFailureAction
  | UpdateClubSuccessAction
  | RemoveClubAction
  | RemoveClubFailureAction
  | RemoveClubSuccessAction;
