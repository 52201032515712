import { IIcon } from '../../model/icon';

export const startup: IIcon = {
  id: `STARTUP`,
  svg: `
  <g clip-path="url(#clip6)">
  <path
    d="M9.7 14.6l-2.2 4.9a.3.3 0 01-.6 0L6.2 18a.3.3 0 00-.6 0l-1.9 1.9a.3.3 0 01-.6-.2V17c0-.2 0-.2-.2-.2H.3c-.3 0-.4-.6-.2-.8l2-1.7a.3.3 0 00-.1-.6l-1.5-.6a.3.3 0 010-.7l5-2.2 3.8.7.4 3.6z"
    fill="#F0C419" />
  <path
    d="M7.2 10.9l-3.4 1.6a.3.3 0 000 .6l1.4.7-1.7 1.4a.2.2 0 000 .3h.8a.2.2 0 01.2.2v.7a.2.2 0 00.3.1l1.4-1.7.7 1.4a.3.3 0 00.6 0L9 12.8l-2-2z"
    fill="#F29C1F" />
  <path
    d="M12.5 12.4l.6 2c0 .4 0 .8-.2 1.2L10.5 19a.3.3 0 01-.6-.2l.2-3.1c0-.3 0-.5-.2-.8l-1-2v-.2l-1.6-1.6h-.1L5 10a2 2 0 00-.8-.2l-3.1.2a.3.3 0 01-.2-.6l3.4-2.4c.4-.2.8-.3 1.2-.2l2 .6 4.9 5z"
    fill="#C03A2B" />
  <path d="M9 12.8l-2.5 1.8a.8.8 0 01-1.1-1.1l1.8-2.4V11L9 12.8z" fill="#E64C3C" />
  <path
    d="M17 9.4c-3.3 3.3-7.4 4.5-9.1 2.7-1.8-1.7-.6-5.8 2.7-9a12.2 12.2 0 018-3.1A1.4 1.4 0 0120 1.4c0 .6 0 1.1-.2 1.7-.3 2-1.2 4.6-2.9 6.3z"
    fill="#4482C3" />
  <path d="M19.8 3c-.6-.2-1.2-.6-1.7-1.1-.5-.5-.9-1-1.2-1.7l1.7-.2A1.4 1.4 0 0120 1.4c0 .6 0 1.1-.2 1.7z"
    fill="#C03A2B" />
  <path d="M17.6 3.8a1.4 1.4 0 11-2.8 0 1.4 1.4 0 012.8 0zM14.7 7.7a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0z"
    fill="#A5A5A4" />
  <path d="M13.3 7.7a1 1 0 11-2 0 1 1 0 012 0z" fill="#5E5F62" />
</g>
<defs>
  <clipPath id="clip6">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>`
};
