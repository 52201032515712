import { Injectable } from '@angular/core';
import { switchMap, map, tap, catchError } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  attachFiles,
  attachFilesSuccess,
  attachPhotos,
  attachPhotosSuccess,
  loadClubFiles,
  loadClubFilesSuccess,
  loadClubPhotos,
  loadClubPhotosSuccess,
  removePhoto,
  removePhotoSuccess,
  removeFile,
  removeFilesSuccess,
  loadResources,
  loadResourcesSuccess,
  loadResourcesSuccessNoPermissionsError,
  postResource,
  postResourceSuccess,
  likeResourceSuccess,
  likeResource,
  updateResource,
  updateResourceSuccess,
  deleteResource,
  deleteResourceSuccess,
  saveResource,
  saveResourceSuccess,
  unsaveResource,
  unsaveResourceSuccess,
  searchClubs,
  searchClubsSuccess
} from './clubs.actions';
import { ClubsService } from './clubs.service';
import { RoutingService } from '../routing.service';
import { empty, of } from 'rxjs';

@Injectable()
export class ClubsEffects {
  constructor(
    private actions$: Actions,
    private clubsService: ClubsService,
    private appRouting: RoutingService
  ) {}

  effectForLoadPhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClubPhotos),
      switchMap((params) =>
        this.clubsService.photos(params.id, params.page).pipe(
          switchMap(({ count, photos }) => [
            loadClubPhotosSuccess({
              id: params.id,
              count,
              photos: this.normalize(photos)
            })
          ])
        )
      )
    )
  );

  effectForLoadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClubFiles),
      switchMap((params) =>
        this.clubsService.files(params.id, params.page).pipe(
          switchMap(({ count, files }) => [
            loadClubFilesSuccess({
              id: params.id,
              count,
              files: this.normalize(files)
            })
          ])
        )
      )
    )
  );

  effectForLoadResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadResources),
      switchMap((params) =>
        this.clubsService.loadResources(params.id, params.page).pipe(
          switchMap(({ resources }) => [
            loadResourcesSuccess({
              id: params.id,
              resources: this.normalize(resources)
            })
          ]),
          catchError((err) => {
            if (err.error.code === 403) {
              return of(
                loadResourcesSuccessNoPermissionsError({ id: params.id })
              );
            } else {
              this.appRouting.goToUnexpectedErrorPage({
                skipLocationChange: true
              });
              return empty();
            }
          })
        )
      )
    )
  );

  effectForAttachPhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachPhotos),
      switchMap((params) =>
        this.clubsService.addPhotos(params.id, params.photos).pipe(
          switchMap(({ photos }) => [
            attachPhotosSuccess({
              id: params.id,
              photos: this.normalize(photos)
            })
          ])
        )
      )
    )
  );

  effectForAttachFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(attachFiles),
      switchMap((params) =>
        this.clubsService.addFiles(params.id, params.files).pipe(
          tap((_) => console.log('AAA: ', _)),
          switchMap(({ files }) => [
            attachFilesSuccess({ id: params.id, files: this.normalize(files) })
          ])
        )
      )
    )
  );

  effectForRemovePhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePhoto),
      switchMap((params) =>
        this.clubsService
          .removePhoto(params.id, params.photoID)
          .pipe(
            switchMap(() => [
              removePhotoSuccess({ id: params.id, photoID: params.photoID })
            ])
          )
      )
    )
  );

  effectForRemoveFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFile),
      switchMap((params) =>
        this.clubsService
          .removeFile(params.id, params.fileID)
          .pipe(
            switchMap(() => [
              removeFilesSuccess({ id: params.id, fileID: params.fileID })
            ])
          )
      )
    )
  );

  effectForPostResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postResource),
      switchMap((params) =>
        this.clubsService.postResource(params.id, params.message).pipe(
          tap((_) => console.log('post: ', _)),
          switchMap(({ post }) => [
            postResourceSuccess({ id: params.id, post })
          ])
        )
      )
    )
  );

  effectForUpdateResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateResource),
      switchMap((params) =>
        this.clubsService.updateResource(params.resourceID, params.post).pipe(
          tap((_) => console.log('update post: ', _)),
          switchMap(({ post }) => [
            updateResourceSuccess({
              id: params.id,
              resourceID: params.resourceID,
              post
            })
          ])
        )
      )
    )
  );

  effectForLikeResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(likeResource),
      switchMap((params) =>
        this.clubsService.likeResource(params.resourceID).pipe(
          tap((_) => console.log('like: ', _)),
          switchMap(({ count }) => [
            likeResourceSuccess({
              id: params.id,
              resourceID: params.resourceID,
              amountOfLikes: count
            })
          ])
        )
      )
    )
  );

  effectForDeleteResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteResource),
      switchMap((params) =>
        this.clubsService.deleteResource(params.resourceID).pipe(
          tap((_) => console.log('delete resource: ', _)),
          switchMap(() => [
            deleteResourceSuccess({
              id: params.id,
              resourceID: params.resourceID
            })
          ])
        )
      )
    )
  );

  effectForSaveResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveResource),
      switchMap((params) =>
        this.clubsService.saveResource(params.resourceID).pipe(
          tap((_) => console.log('save resource: ', _)),
          switchMap(() => [
            saveResourceSuccess({
              id: params.id,
              resourceID: params.resourceID
            })
          ])
        )
      )
    )
  );

  effectForUnSaveResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unsaveResource),
      switchMap((params) =>
        this.clubsService.unsaveResource(params.resourceID).pipe(
          tap((_) => console.log('unsave resource: ', _)),
          switchMap(() => [
            unsaveResourceSuccess({
              id: params.id,
              resourceID: params.resourceID
            })
          ])
        )
      )
    )
  );

  effectForSearchClubs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchClubs),
      switchMap((params) =>
        this.clubsService
          .searchClubs(params.searchParams)
          .pipe(
            switchMap(({ count, clubs }) => [
              searchClubsSuccess({ count, clubs: this.normalize(clubs) })
            ])
          )
      )
    )
  );

  private normalize(items) {
    return items.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
