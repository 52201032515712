import { createAction, props } from '@ngrx/store';

import { Event, People, Group, Organization, Discussion } from './top.model';
import { Club } from '../clubs/club.model';

export const searchTop = createAction(
  '[Search/API] Search Top',
  props<{ q: string }>()
);

export const searchTopSuccess = createAction(
  '[Search/API] Search Top Success',
  props<{
    people: {
      [id: string]: People;
    };
    events: {
      [id: string]: Event;
    };
    groups: {
      [id: string]: Group;
    };
    organizations: {
      [id: string]: Organization;
    };
    discussions: {
      [id: string]: Discussion;
    };
    clubs: {
      [id: string]: Club;
    };
  }>()
);
