import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, competitionsFeatureKey } from '../reducers';
import * as UpcomingCompetitions from '../reducers/upcoming-competitions.reducer';

const competitionsModuleState = createFeatureSelector<State>(
  competitionsFeatureKey
);

const selectUpcomingCompetitionsState = createSelector(
  competitionsModuleState,
  (state: State) => state.upcomingCompetitions
);

export const getUpcomingCompetitions = createSelector(
  selectUpcomingCompetitionsState,
  UpcomingCompetitions.selectCompetitions
);

export const isLoadingUpcomingCompetitions = createSelector(
  selectUpcomingCompetitionsState,
  UpcomingCompetitions.isLoading
);

export const hasMoreUpcomingCompetitionsToLoad = createSelector(
  selectUpcomingCompetitionsState,
  UpcomingCompetitions.hasMoreCompetitionsToLoad
);

export const getLastLoadedPageUpcomingCompetitions = createSelector(
  selectUpcomingCompetitionsState,
  UpcomingCompetitions.lastLoadedPage
);
