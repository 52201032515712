import { createAction, props } from '@ngrx/store';

import { Request } from './request.model';

export const loadRequests = createAction(
  '[Requests/API] Load',
  props<{ page: number; search: string }>()
);

export const loadRequestsSuccess = createAction(
  '[Requests/API] Load Success',
  props<{ requests: Request[]; count: number }>()
);

export const acceptRequest = createAction(
  '[Requests/API] Accept',
  props<{ id: number; page: number }>()
);

export const acceptRequestSuccess = createAction(
  '[Requests/API] Accept Success',
  props<{ id: number }>()
);

export const rejectRequest = createAction(
  '[Requests/API] Reject',
  props<{ id: number; page: number }>()
);

export const rejectRequestSuccess = createAction(
  '[Requests/API] Reject Success',
  props<{ id: number }>()
);

export const disconnectRequest = createAction(
  '[Requests/API] Disconnect',
  props<{ id: number; page: number }>()
);

export const disconnectRequestSuccess = createAction(
  '[Requests/API] Disconnect Success',
  props<{ id: number }>()
);
