import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { RoutingService } from 'src/app/core/services/routing.service';
import { CategoriesService } from 'src/app/shared/services/categories.service';

import { IArticle, IEditProject, INewProject, IProject } from '../../../model';
import { CreateNewArticleDialogComponent } from '../create-new-article-dialog/create-new-article-dialog.component';
import { BaseUrlPipe } from 'src/app/shared/pipes/base-url/base-url.pipe';

@Component({
  selector: 'app-create-new-project-dialog',
  templateUrl: './create-new-project-dialog.component.html',
  styleUrls: ['./create-new-project-dialog.component.scss']
})
export class CreateNewProjectDialogComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject();
  private readonly _article = new BehaviorSubject<IArticle>(null);

  readonly types = [
    {
      id: 1,
      title: 'Club Content'
    },
    {
      id: 2,
      title: 'Club Project'
    }
  ];

  readonly projectForm = new FormGroup({
    type_id: new FormControl(null, Validators.required),
    topic: new FormControl(null, Validators.required),
    title: new FormControl(null, Validators.required),
    organization: new FormGroup({
      id: new FormControl(null, Validators.required),
      name: new FormControl(null, [
        Validators.required,
        (formControl) => {
          return formControl.parent?.get('id').invalid ? { error: true } : null;
        }
      ])
    }),
    description: new FormControl(null, Validators.required),
    url: new FormControl(null, Validators.required)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { categoryId: number; project?: IProject },
    private readonly dialogRef: MatDialogRef<
      CreateNewProjectDialogComponent,
      INewProject | IEditProject
    >,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly routing: RoutingService,
    private readonly categoriesService: CategoriesService,
    private readonly baseUrl: BaseUrlPipe
  ) {
    this._article
      .pipe(
        tap((_) => this.onArticle(_)),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => _);
  }

  ngOnInit() {
    if (this.data?.project) {
      this.projectForm.patchValue(this.data.project);
      this.projectForm.controls.organization.patchValue(this.data.project.user);
    }

    if (this.data?.project?.article) {
      this._article.next(this.data.project.article);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get title() {
    return this.data.project?.title;
  }

  get isEditMode() {
    return !!this.data?.project;
  }

  get link$() {
    return this._article.pipe(
      filter((_) => !!_),
      map((_) => this.routing.getDiscussionLink(_.id, _.name)),
      map((_) => this.router.serializeUrl(this.router.createUrlTree(_)))
    );
  }

  get isArticle$() {
    return this._article.pipe(map((_) => !!_));
  }

  get categories() {
    return this.categoriesService.CATEGORIES;
  }

  close(): void {
    this.dialogRef.close();
  }

  createNewArticle() {
    const dialogRef = this.dialog.open<
      CreateNewArticleDialogComponent,
      void,
      IArticle
    >(CreateNewArticleDialogComponent);

    return dialogRef
      .afterClosed()
      .pipe(
        filter((_) => !!_),
        tap((_) => this._article.next(_))
      )
      .subscribe((_) => _);
  }

  removeArticle() {
    this._article.next(null);
  }

  onSubmit() {
    const response: INewProject & { id?: number } = {
      id: this.data?.project?.id,
      title: this.projectForm.value.title,
      topic: this.projectForm.value.topic,
      type_id: this.projectForm.value.type_id,
      category_id: this.data.categoryId,
      user_id: this.projectForm.value.organization.id,
      description: this.projectForm.value.description,
      article_id: null,
      url: null
    };

    if (this._article.value) {
      response.article_id = this._article.value.id;
      response.url = null;
    } else {
      response.url = this.projectForm.value.url;
      response.article_id = null;
    }

    this.dialogRef.close(response);
  }

  compareTypes(id1: number, id2: number) {
    return id1 === id2;
  }

  onSelectOrganizationFromList(organization) {
    this.projectForm.controls.organization.patchValue(organization, {
      emitEvent: false
    });
  }

  onSearchChange(_value: string) {
    this.projectForm.controls.organization.patchValue({ id: null });
  }

  private onArticle(article?: IArticle) {
    if (!article) {
      this.projectForm.get('url').patchValue(null);
      this.projectForm.get('url').enable({ onlySelf: true });

      this.projectForm.updateValueAndValidity();

      return;
    }

    const articleRawLink = this.routing.getDiscussionLink(
      article.id,
      article.name
    );
    const articleLink = this.router.serializeUrl(
      this.router.createUrlTree(articleRawLink)
    );

    this.projectForm.get('url').patchValue(this.baseUrl.transform(articleLink));
    this.projectForm.get('url').disable({ onlySelf: true });

    this.projectForm.updateValueAndValidity();
  }
}
