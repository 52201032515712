import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private base: BaseService) {}

  getList(page) {
    return this.base.get(`notification?page=${page}`).pipe(map((_) => _));
  }

  readNotification(id: number) {
    return this.base.get(`notification/saw/${id}`);
  }

  readAll() {
    return of({});
  }
}
