import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipModule
} from '@angular/material/tooltip';

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  exports: [MatTooltipModule],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 1000 } }
  ]
})
export class AppTooltipModule {}
