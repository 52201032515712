import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  Observable,
  merge,
  BehaviorSubject,
  Subject,
  combineLatest
} from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, filter, tap, takeUntil } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

import { environment } from 'src/environments/environment';
import {
  loadNotifications,
  resetNextPageToLoad
} from 'src/app/core/services/notifications/notifications.actions';
import {
  State as NotificationsState,
  selectNotifications,
  selectNextPageToLoad
} from 'src/app/core/services/notifications/notifications.reducer';
import { Notification } from 'src/app/core/services/notifications/notification.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { newMessage } from 'src/app/core/services/dialogs/dialogs.actions';
import { MessagesService } from 'src/app/pages/messages/messages.service';
import { ChatFacade, NoticeCounterFacade } from 'src/app/root-store';

import { HeaderService } from './header.service';
import { TUser } from 'src/app/shared/models/user/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [HeaderService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  userIsloged$: Observable<boolean>;
  showLoginButton$: Observable<boolean>;
  showProfileMenu = false;
  searchTerm: string;
  showNotifications$ = new BehaviorSubject(false);
  notifications$: Observable<Notification[]>;
  notificationsListenNextPageToLoad$: Observable<number>;
  user$: Observable<TUser>;
  userIslogged$: Observable<boolean>;

  private readonly newMessageAudio: HTMLAudioElement = new Audio(
    'assets/audio/message.mp3'
  );
  private buttonClose = true;
  private dropListClose = true;
  private notificationsPage = 1;
  private componentDestroy$ = new Subject();

  constructor(
    private readonly updates$: Actions,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly activatedRout: ActivatedRoute,
    private readonly notificationsStore: Store<NotificationsState>,
    private readonly messageService: MessagesService,
    private readonly chatFacade: ChatFacade,
    private readonly noticeCounterFacade: NoticeCounterFacade
  ) {
    this.newMessageAudio.load();

    this.notifications$ = this.notificationsStore.pipe(
      takeUntil(this.componentDestroy$),
      select(selectNotifications())
    );
    this.notificationsListenNextPageToLoad$ = this.notificationsStore.pipe(
      takeUntil(this.componentDestroy$),
      select(selectNextPageToLoad())
    );

    this.notificationsListenNextPageToLoad$
      .pipe(takeUntil(this.componentDestroy$))
      .subscribe((_) => {
        this.notificationsPage = _;
      });

    this.activatedRout.queryParams
      .pipe(takeUntil(this.componentDestroy$))
      .subscribe((p) => {
        this.searchTerm = p.q;
      });

    this.showNotifications$
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !_),
        tap((_) => this.notificationsStore.dispatch(resetNextPageToLoad()))
      )
      .subscribe((_) => _);

    this.user$ = this.auth.user$.pipe(takeUntil(this.componentDestroy$));

    this.userIslogged$ = this.auth.isFullyAuthorized$.pipe(
      takeUntil(this.componentDestroy$)
    );

    this.probablyPlayNewMessage();
  }

  ngOnInit() {
    this.showLoginButton$ = merge(
      this.router.events.pipe(
        filter((url) => url instanceof NavigationEnd),
        map((route: any) => route.url === '/')
      ),
      this.userIslogged$
    );
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  @HostListener('document:click') onOutSideClick() {
    this.showNotifications$.next(false);
  }

  get collegesURL() {
    return environment.collegesURL;
  }

  get amountOfNewDialogs$() {
    return this.noticeCounterFacade.messages$;
  }

  get amountOfNewNotifications$() {
    return this.noticeCounterFacade.notifications$;
  }

  get amountOfUnreadMessages$() {
    return combineLatest([
      this.chatFacade.amountOfUnreadChannels$,
      this.amountOfNewDialogs$
    ]).pipe(map((_) => _[0] + _[1]));
  }

  onToggleNotifications() {
    this.showNotifications$.next(!this.showNotifications$.value);

    if (!this.showNotifications$.value) {
      return;
    }

    this.notificationsStore.dispatch(
      loadNotifications({ page: this.notificationsPage })
    );
  }

  onLoadMoreNotifications() {
    this.notificationsStore.dispatch(
      loadNotifications({ page: this.notificationsPage })
    );
  }

  onSearch() {
    this.router.navigate(['/search'], {
      queryParams: { q: this.searchTerm }
    });
  }

  onChangeButtonState() {
    if (this.isButtonClosed()) {
      this.openButton();
    } else {
      this.closeButton();
    }
  }

  changeDropDownState() {
    if (this.isDropListClosed()) {
      this.openDropList();
    } else {
      this.closeDropList();
    }
  }

  onClickedOutside() {
    this.closeDropList();
  }

  onClickedButtonOutside() {
    this.closeButton();
  }

  closeButton() {
    this.buttonClose = true;
  }

  closeDropList() {
    this.dropListClose = true;
  }

  openButton() {
    this.buttonClose = false;
  }

  openDropList() {
    this.dropListClose = false;
  }

  isButtonClosed(): boolean {
    return this.buttonClose;
  }

  isDropListClosed(): boolean {
    return this.dropListClose;
  }

  logout() {
    this.auth.logout();
  }

  get currentURL() {
    return window?.location?.href;
  }

  private probablyPlayNewMessage() {
    this.updates$
      .pipe(
        ofType(newMessage),
        takeUntil(this.componentDestroy$),
        tap((_) => console.log('probablyPlayNewMessage 3: ', _)),
        filter(
          (_) =>
            _.message.sender.id !== this.auth.userID() &&
            Number(this.messageService.selectedDiaglogID$.value) !==
              Number(_.dialog.id)
        ),
        tap(() => this.newMessageAudio.play())
      )
      .subscribe((_) => _);
  }

  goToHome() {
    if (this.router.url.includes('/news')) {
      location.reload();
    }
  }
}
