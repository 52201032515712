import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  OnChanges,
  ChangeDetectorRef
} from '@angular/core';
import { IDoc } from '../../../models/doc';
import { WriteMessageModalComponent } from 'src/app/shared/components/write-message-modal/write-message-modal.component';
import { IPost } from '../../../models/post';
import { IMessage, IMessageCtx } from '../../message/message.component';
import { ICommentsCtx } from '../../comments/list/comments.component';
import { IComment } from '../../../models/comment';
import { RoutingService } from '../../../../core/services/routing.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { getUserIdFromLink } from '../../../utils/getUserIdFromLink';
import { TStatus } from '../../../models/status';
import { ICommentsState } from '../../../ui/post/data/model/state';
import { ILinkPreview } from '../../../models/link-preview';
import { DialogService } from '../../../../core/services/dialogs/dialogs.service';
import { MatDialog } from '@angular/material/dialog';
import { SharePostDialogComponent } from './share/share.component';
import { filter } from 'rxjs/operators';
import { PostsFacade } from 'src/app/core/services/posts/posts.facade';
import { DogToLinkPipe } from 'src/app/shared/pipes/dog-to-link.pipe';
import { CopyToClipboardService } from 'src/app/shared/services/copy-to-clipboard/copy-to-clibboard.service';
import { PostLinkService } from 'src/app/shared/services/links';

// ==================================================
//                   Model
// ==================================================

export interface ICommentsInfo {
  values: Observable<IComment[]>;
  status: Observable<TStatus>;
  count: Observable<number>;
}

export interface IPostCtx {
  share?: (post: IPost, message: IMessage) => void;
  like?: (post: IPost) => void;
  delete?: (post: IPost) => void;
  complain?: (post: IPost) => void;
  update?: (post: IPost, message: IMessage) => void;
  save?: (post: IPost) => void;
  pin?: (post: IPost) => void;
  modifyers?: {
    isGroup?: boolean;
    // TODO: Check if method exists
    forbidDelete?: boolean;
    isForbidComments?: Observable<boolean>;
  };
  comments?: {
    get?: (post: IPost) => ICommentsInfo;
    add?: (post: IPost, message: IMessage) => void;
    delete?: (post: IPost, comment: IComment) => void;
    like?: (post: IPost, comment: IComment) => void;
    reply?: (post: IPost, comment: IComment, message: IMessage) => void;
    paginate?: (post: IPost, comments: IComment[], paginations: number) => void;
    replies?: {
      get?: (post: IPost, comment: IComment) => Observable<IComment[]>;
    };
  };
}
// ==================================================
//                   Component
// ==================================================
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostComponent implements OnInit, OnChanges {
  @Input() readonly post: IPost;
  @Input() readonly ctx: IPostCtx;

  readonly commentsCtx: ICommentsCtx = {
    delete: (comment) => this.ctx.comments.delete(this.post, comment),
    like: (comment) => this.ctx.comments.like(this.post, comment),
    reply: (comment, message) =>
      this.ctx.comments.reply(this.post, comment, message),
    paginate: (comments, count) =>
      this.ctx.comments.paginate(this.post, comments, count),
    replies: {
      get: (comment) => this.ctx.comments.replies.get(this.post, comment)
    }
  };

  readonly writeCommentCtx: IMessageCtx = {
    placeholder: `Write comment`,
    submit: (message) => this.ctx.comments.add(this.post, message),
    isSupportTags: true,
    isSupportLinkPreview: true
  };

  readonly editPostCtx: IMessageCtx = {
    placeholder: ``,
    close: () => this.toggleEditMode(),
    isHideAvatar: true,
    isTextarea: true,
    isSupportTags: true,
    isSupportLinkPreview: true,
    edit: () => ({
      text: this.dogToLinkPipe.transform(this.post.raw_text, this.post.tags),
      docs: this.post.docs,
      images: this.post.fotos
    }),
    submit: (message) => {
      this.toggleEditMode();
      this.ctx.update(this.post, message);
    }
  };

  public comments: null | ICommentsInfo;
  public editMode: boolean;
  public linkPreview: ILinkPreview[] = [];
  public isAutoPost: boolean;

  @ViewChild(WriteMessageModalComponent, { static: true })
  writeMessageModalComponent: WriteMessageModalComponent;

  get isViewable() {
    return this.auth.isFullyAuthorized;
  }

  constructor(
    private readonly router: Router,
    readonly routing: RoutingService,
    readonly matDialog: MatDialog,
    private readonly postsFacade: PostsFacade,
    private readonly auth: AuthService,
    private readonly dogToLinkPipe: DogToLinkPipe,
    private readonly postLinkService: PostLinkService,
    private readonly copyToClipboardService: CopyToClipboardService
  ) {}
  // ---------------------------------------------
  //                   Lifecycle
  // ---------------------------------------------
  ngOnInit(): void {
    this.isAutoPost = !!this.post.auto;
  }

  ngOnChanges() {
    this.comments = this.ctx.comments?.get(this.post);
  }

  get isLikeSupported() {
    return !!this.ctx.like;
  }

  get isShareSupported() {
    return !!this.ctx.share;
  }

  get isSaveSupported() {
    return !!this.ctx.save;
  }

  get isDeleteSupported() {
    return !!this.ctx.delete;
  }

  get isUpdateSupported() {
    return !!this.ctx.update;
  }

  get isComplainSupported() {
    return !!this.ctx.complain;
  }

  // ---------------------------------------------
  //
  // ---------------------------------------------
  public openDoc(doc: IDoc) {
    this.routing.openDoc(doc);
  }

  public toggleEditMode() {
    this.editMode = !this.editMode;
  }
  // ---------------------------------------------
  //                   Events
  // ---------------------------------------------
  public share() {
    if (!this.ctx.share) {
      return;
    }

    this.matDialog
      .open(SharePostDialogComponent, {
        // SharePostDialogComponent has autofocus logic under the hood
        autoFocus: false
      })
      .afterClosed()
      .pipe(filter((message) => !!message))
      .subscribe((message: IMessage) => {
        this.ctx.share(this.post, message);
      });
  }

  public like() {
    if (!this.ctx.like) {
      return;
    }

    this.ctx.like(this.post);
  }

  public delete() {
    if (!this.ctx.delete) {
      return;
    }

    this.ctx.delete(this.post);
  }

  public complaine() {
    this.ctx.complain(this.post);
  }

  public pin() {
    this.ctx.pin(this.post);
  }

  public save() {
    this.ctx.save(this.post);
  }

  onViewed(id: number) {
    this.postsFacade.onViewed(id);
  }

  onCopyLink(post: IPost) {
    const link = this.router.serializeUrl(
      this.router.createUrlTree([
        ...this.postLinkService.generatePostLink(post)
      ])
    );

    this.copyToClipboardService.copy(window.location.origin + link);
  }
}
