import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import moment from 'moment';

import { IPost } from '../../../../../models/post';

@Component({
  selector: 'app-post-group-header',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostGroupHeaderComponent {
  @Input() readonly post: IPost;

  get createdTime() {
    return moment.utc(this.post.created_at.date).valueOf();
  }
}
