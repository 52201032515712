import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { Job } from 'src/app/core/services/eduniverse/eduniverse.model';
import { RoutingService } from '../../../core/services/routing.service';

@Component({
  selector: 'app-jobs-right-bar',
  templateUrl: './jobs-right-bar.component.html',
  styleUrls: ['./jobs-right-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsRightBarComponent {
  @Input() jobs: { totalCount: number; items: Job[] };

  constructor(private routing: RoutingService) {}

  public goToProfile(profile) {
    this.routing.goToProfile(profile, `job`);
  }
}
