import { IIcon } from '../../model/icon';

export const subNetwork: IIcon = {
  id: `SUB_NETWORK`,
  svg: `
  <path fill="#4482C3" d="M7.5 13.966a6.466 6.466 0 100-12.932 6.466 6.466 0 000 12.931z" />
  <path fill="#4FBA6F"
    d="M5.635 3.334c-.287.478-.049 1.052.148 1.562a.747.747 0 01.064.538.883.883 0 01-.325.351c-.378.293-.757.582-1.136.87-.28.187-.519.432-.698.718-.3.577.008 1.257-.122 1.88-.075.352-.936 1.384-1.373 1.938a6.462 6.462 0 013.528-9.906l.002.008c.497.358.629 1.04.3 1.557a5.676 5.676 0 00-.388.484zM13.966 7.5c0 .469-.05.936-.153 1.394h-.003a.872.872 0 01-.78-.202c-.34-.279-.619-.693-1.118-.628a.976.976 0 00-.678.468c-.421.68-.204 1.546-.558 2.232a2.99 2.99 0 01-.802.871c-.002.003-.008.006-.01.01a.593.593 0 01-.957-.545 2.424 2.424 0 00-.445-1.725c-.29-.39-.721-.747-.719-1.23.008-.655.8-1.05 1.32-1.276.283-.117.524-.318.69-.577.465-.778-.256-1.386-.621-2.04a2.332 2.332 0 01.528-2.845A6.46 6.46 0 0113.966 7.5zM9.66 1.407l.002-.003.016-.01M8.048 13.94a6.442 6.442 0 01-4.596-1.396l1.027-.756a.765.765 0 01.51-.147c.388.044.651.44 1.024.553.292.09.602-.003.905.01a.45.45 0 01.176.042.46.46 0 01.145.13c.362.465.44 1.109.81 1.564z" />
  <path fill="#CC4B4C" d="M12.672 5.431a2.328 2.328 0 100-4.655 2.328 2.328 0 000 4.655z" />
  <path fill="#E6E7E8"
    d="M13.966 4.355c.001.301-.097.594-.28.834a2.243 2.243 0 01-2.027 0 1.375 1.375 0 01.532-2.085h.003c.276.233.68.233.957 0h.002c.497.217.816.709.813 1.25z" />
  <path fill="#fff"
    d="M13.448 2.586a.763.763 0 01-.295.608h-.002a.765.765 0 01-.957 0h-.003a.776.776 0 111.257-.608z" />
  <path fill="#E4C05C" d="M2.328 4.655a2.328 2.328 0 100-4.655 2.328 2.328 0 000 4.655z" />
  <path fill="#E6E7E8"
    d="M3.62 3.579c.002.301-.096.594-.279.834a2.243 2.243 0 01-2.027 0 1.375 1.375 0 01.533-2.085h.002c.276.233.68.233.957 0h.003c.496.217.816.709.812 1.25z" />
  <path fill="#fff"
    d="M3.103 1.81a.762.762 0 01-.294.608h-.003a.765.765 0 01-.957 0h-.002a.776.776 0 111.257-.608z" />
  <path fill="#CC66A1" d="M2.328 15a2.328 2.328 0 100-4.655 2.328 2.328 0 000 4.655z" />
  <path fill="#E6E7E8"
    d="M3.62 13.924c.002.3-.096.594-.279.834a2.243 2.243 0 01-2.027 0 1.375 1.375 0 01.533-2.086h.002c.276.234.68.234.957 0h.003c.496.218.816.71.812 1.252z" />
  <path fill="#fff"
    d="M3.103 12.155a.762.762 0 01-.294.608h-.003a.765.765 0 01-.957 0h-.002a.776.776 0 111.257-.608z" />
`
};
