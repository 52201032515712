import { Injectable } from '@angular/core';
import { switchMap, tap, concatMap, catchError } from 'rxjs/operators';
import { of, forkJoin, EMPTY } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ImagesService } from 'src/app/shared/stores/images/images.service';
import {
  loadGroupRequests,
  loadGroupRequestsSuccess,
  loadGroupMembers,
  loadGroupMembersSuccess,
  unrequestGroup,
  unrequestGroupSuccess,
  accessGroup,
  accessGroupSuccess,
  unaccessGroup,
  unaccessGroupSuccess,
  subscribeGroup,
  subscribeGroupSuccess,
  unsubscribeGroup,
  unsubscribeGroupSuccess,
  loadGroupInfo,
  loadGroupInfoSuccess,
  updateGroupInfo,
  updateGroupInfoSuccess,
  removeGroup,
  removeGroupSuccess
} from './group.actions';
import { GroupService } from './group.service';
import { RoutingService } from '../routing.service';

@Injectable()
export class GroupEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly groupService: GroupService,
    private readonly imagesService: ImagesService,
    private readonly routingService: RoutingService,
    private readonly snackBar: MatSnackBar
  ) {}

  effectForLoadGroupInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroupInfo),
      switchMap(({ id }) =>
        this.groupService
          .getGroupInfo(id)
          .pipe(switchMap(({ group }) => [loadGroupInfoSuccess({ group })]))
      )
    )
  );

  effectForRemoveGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeGroup),
      switchMap((params) =>
        this.groupService.removeGroup(params.id).pipe(
          tap(() => this.routingService.goToGroups()),
          tap(() => this.snackBar.open('Group has been removed', 'OK')),
          switchMap(() => [removeGroupSuccess({ id: params.id })])
        )
      ),
      catchError(() => {
        this.snackBar.open('Something went wrong', 'OK');
        return EMPTY;
      })
    )
  );

  effectForUpdateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGroupInfo),
      tap((_) => console.log('update group effect: ', _)),
      concatMap((params) =>
        forkJoin([
          of(params),
          params.group.image
            ? this.imagesService.add([params.group.image])
            : of([])
        ])
      ),
      tap((_) => console.log('after loaded app: ', _)),
      switchMap((params) =>
        this.groupService
          .updateGroup(params[0].id, {
            ...params[0].group,
            foto: [params[1][0]?.id || this.getGroupPhotoID(params[0].group)]
          })
          .pipe(switchMap(({ group }) => [updateGroupInfoSuccess({ group })]))
      )
    )
  );

  effectForLoadRequests = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroupRequests),
      switchMap(({ id, page, searchTerm }) =>
        this.groupService.groupRequests(id, page, searchTerm).pipe(
          switchMap(({ requests, count }) => [
            loadGroupRequestsSuccess({
              requests: this.normalize(requests),
              count
            })
          ])
        )
      )
    )
  );

  effectForUnRequestGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(unrequestGroup),
      switchMap(({ group_id, user_id }) =>
        this.groupService
          .unrequestGroup(group_id, user_id)
          .pipe(switchMap(() => [unrequestGroupSuccess({ group_id, user_id })]))
      )
    )
  );

  effectForSubscribeGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeGroup),
      switchMap(({ group_id, user_id, text }) =>
        this.groupService
          .subscribeGroup(group_id, text)
          .pipe(switchMap(() => [subscribeGroupSuccess({ group_id, user_id })]))
      )
    )
  );

  effectForUnSubscribeGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribeGroup),
      switchMap(({ group_id, user_id }) =>
        this.groupService
          .unsubscribeGroup(group_id)
          .pipe(
            switchMap(() => [unsubscribeGroupSuccess({ group_id, user_id })])
          )
      )
    )
  );

  effectForAccessGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(accessGroup),
      switchMap(({ group_id, user }) =>
        this.groupService
          .accessGroup(group_id, user.id)
          .pipe(switchMap(() => [accessGroupSuccess({ group_id, user })]))
      )
    )
  );

  effectForUnaccessGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(unaccessGroup),
      switchMap(({ group_id, user }) =>
        this.groupService
          .unaccessGroup(group_id, user.id)
          .pipe(switchMap(() => [unaccessGroupSuccess({ group_id, user })]))
      )
    )
  );

  effectForGroupMembers = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroupMembers),
      switchMap(({ id, page, searchTerm }) =>
        this.groupService
          .getGroupNetwork(id, page, searchTerm)
          .pipe(
            switchMap(({ users, count }) => [
              loadGroupMembersSuccess({ members: this.normalize(users), count })
            ])
          )
      )
    )
  );

  private getGroupPhotoID(group) {
    if (group.icon_id && group.icon_id > 0) {
      return group.icon_id;
    }

    return undefined;
  }

  private normalize(groups) {
    if (!groups) {
      return groups;
    }

    return groups.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
