import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { IPost } from '../../../models/post';
import { IPostCtx } from '../item/post.component';
import { Observable } from 'rxjs';
import { IComment } from '../../../models/comment';
import { TStatus, isStatus } from '../../../models/status';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { ConvertIdService } from '../../../../core/services/convert-id.service';

// ==================================================
//                   Model
// ==================================================
export interface IPostsCtx extends IPostCtx {
  status: Observable<TStatus>;
  paginate?: (posts: IPost[], page: number) => void;
}
// ==================================================
//                   Component
// ==================================================
@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostsComponent implements OnInit {
  @Input() readonly ctx: IPostsCtx;
  @Input() readonly posts: IPost[];
  @Input() readonly hideMargin: boolean = false;
  @Input() readonly isGroupPosts?: boolean;
  @Input() readonly isPrivateGroup?: boolean;
  @Input() readonly isSubscriber?: boolean;
  @Input() readonly isClubPost?: boolean;
  @Input() readonly isResource?: boolean;

  public status$: Observable<TStatus>;
  public page = 1;
  public comments: {
    [id: number]: Observable<IComment[]>;
  };

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.status$ = this.ctx.status.pipe(shareReplay(1));
  }

  get profileId$() {
    return this.activatedRoute.params.pipe(map((_) => _.userId));
  }

  identify(_index: number, post: IPost) {
    return post.id;
  }

  public paginate = async () => {
    const status = await this.status$.pipe(take(1)).toPromise();
    const isMergable = isStatus(status, `MERGABLE`);

    if (!isMergable) return; // #Return#

    this.page += 1;
    this.ctx.paginate(this.posts, this.page);
  };
}
