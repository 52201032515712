<div class="members-list">
  <a
    [profile]="member"
    class="member-item group-chat-member-item d-flex align-items-center"
    *ngFor="let member of members$ | async | orderBy: 'isOwner': 'desc'; trackBy: memberTrackBy"
  >
    <div class="remove" *ngIf="isAdmin && !(member.id | isCurrentUser | async)" (click)="onRemove(member.id); false"
      >&times;</div
    >
    <div class="avatar rounded-circle">
      <app-avatar [src]="member.icon" [placeholder]="member | initials" class="img-fluid"></app-avatar>
    </div>
    <div class="data">
      <div class="position" *ngIf="member.isOwner">Channel admin</div>
      <span class="name">{{ member.name }}</span>
      <!-- <div class="position">Online 6 hour ago</div> -->
    </div>
  </a>
</div>
