import { IIcon } from '../../model/icon';

export const subEvents: IIcon = {
  id: `SUB_EVENTS`,
  svg: `
  <path fill="#E6E7E8" d="M14.5 4.66v9.3c0 .58-.47 1.04-1.04 1.04H1.56C1 15 .53 14.54.53 13.97V4.66h13.96z" />
  <path fill="#C03A2B" d="M.53 4.66v-2.6c0-.56.46-1.03 1.03-1.03h11.9c.57 0 1.03.47 1.03 1.04v2.59H.53z" />
  <path fill="#2C2F38"
    d="M3.89 1.7a1.03 1.03 0 11-1.04 0M8.03 1.7a1.03 1.03 0 11-1.04 0M12.17 1.7a1.03 1.03 0 11-1.04 0" />
  <path fill="#A5A5A4"
    d="M1.82 5.95h-.26a.26.26 0 010-.52h.26a.26.26 0 010 .52zM3.11 5.95h-.26a.26.26 0 010-.52h.26a.26.26 0 010 .52zM4.4 5.95h-.25a.26.26 0 010-.52h.26a.26.26 0 010 .52zM5.7 5.95h-.26a.26.26 0 010-.52h.26a.26.26 0 010 .52zM7 5.95h-.27a.26.26 0 010-.52H7a.26.26 0 010 .52zM8.29 5.95h-.26a.26.26 0 010-.52h.26a.26.26 0 010 .52zM9.58 5.95h-.26a.26.26 0 010-.52h.26a.26.26 0 010 .52zM10.87 5.95h-.26a.26.26 0 110-.52h.26a.26.26 0 110 .52zM12.17 5.95h-.26a.26.26 0 010-.52h.26a.26.26 0 010 .52zM13.46 5.95h-.26a.26.26 0 010-.52h.26a.26.26 0 010 .52zM9.58 13.7H4.15a.26.26 0 010-.51h5.43a.26.26 0 010 .52zM11.13 13.7h-.52a.26.26 0 110-.51h.52a.26.26 0 010 .52z" />
  <path fill="#5E5F62"
    d="M3.37 0c.29 0 .52.23.52.52v1.55a.52.52 0 11-1.04 0V.52c0-.29.24-.52.52-.52zM7.51 0c.29 0 .52.23.52.52v1.55a.52.52 0 01-1.04 0V.52c0-.29.23-.52.52-.52zM11.65 0c.28 0 .52.23.52.52v1.55a.52.52 0 01-1.04 0V.52c0-.29.23-.52.52-.52z" />
  <path fill="#24AE5F"
    d="M5.28 10.67l1.03 1.02 3.87-4.77a.54.54 0 01.9.1c.08.18.07.39-.05.55l-4.1 5.66a.52.52 0 01-.8.05l-1.65-1.83a.53.53 0 01-.08-.6l.02-.04a.54.54 0 01.86-.14z" />

  `
};
