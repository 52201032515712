import { createAction, props } from '@ngrx/store';
import { IDoc } from '../../models/doc';

export const loadDocs = createAction('[FILE] Load Docs');

export const loadDocsSuccess = createAction(
  '[FILE] Load Docs Success',
  props<{ docs: IDoc[] }>()
);

export const addDocs = createAction(
  '[FILE] Add Docs',
  props<{ docs: File[] }>()
);

export const addDocsSuccess = createAction(
  '[FILE] Add Docs Success',
  props<{ docs: IDoc[] }>()
);
