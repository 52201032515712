import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { NoticeCountersActions } from './actions';
import { State } from './reducers';
import {
  getNetworkRequests,
  getMessages,
  getNotifications,
  getWeeklyReadAt,
  isLoaded$,
  getAmountOfNewPosts,
  getAmountOfNewPrivatePosts,
  getAmountOfSavedPosts
} from './selectors';

@Injectable({
  providedIn: 'root'
})
export class NoticeCounterFacade {
  readonly networkRequests$: Observable<number>;
  readonly messages$: Observable<number>;
  readonly notifications$: Observable<number>;
  readonly weeklyReadAt$: Observable<number>;
  readonly amountOfNewPosts$: Observable<number>;
  readonly amountOfNewPrivatePosts$: Observable<number>;
  readonly amountOfSavedPosts$: Observable<number>;
  readonly isLoaded$: Observable<boolean>;

  constructor(private readonly store: Store<State>) {
    this.networkRequests$ = this.store.pipe(select(getNetworkRequests));
    this.messages$ = this.store.pipe(select(getMessages));
    this.notifications$ = this.store.pipe(select(getNotifications));
    this.weeklyReadAt$ = this.store.pipe(select(getWeeklyReadAt));
    this.amountOfNewPosts$ = this.store.pipe(select(getAmountOfNewPosts));
    this.amountOfNewPrivatePosts$ = this.store.pipe(
      select(getAmountOfNewPrivatePosts)
    );
    this.amountOfSavedPosts$ = this.store.pipe(select(getAmountOfSavedPosts));
    this.isLoaded$ = this.store.pipe(select(isLoaded$));
  }

  loadNoticeCounters() {
    this.store.dispatch(new NoticeCountersActions.LoadNoticeCountersAction());
  }

  cleanNoticeCounters() {
    this.store.dispatch(new NoticeCountersActions.CleanNoticeCountersAction());
  }
}
