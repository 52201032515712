import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ProjectsStoreEffects } from './effects';
import { ProjectsService } from './service';
import { ProjectsFacade } from './facade';
import { projectsFeatureKey, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(projectsFeatureKey, reducers),
    EffectsModule.forFeature([ProjectsStoreEffects])
  ],
  providers: [ProjectsStoreEffects, ProjectsFacade, ProjectsService]
})
export class ProjectsStoreModule {}
