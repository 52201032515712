import { ActionReducerMap } from '@ngrx/store';

import {
  State as ClubInfoReducerState,
  Reducer as ClubInfoReducer
} from './club-info.reducer';
import {
  State as ClubMediaFilesReducerState,
  Reducer as ClubMediaFilesReducer
} from './club-media-files.reducer';
import {
  State as ClubMediaPhotosReducerState,
  Reducer as ClubMediaPhotosReducer
} from './club-media-photos.reducer';
import {
  State as ClubMembersReducerState,
  Reducer as ClubMembersReducer
} from './club-members.reducer';
import {
  State as ClubRequestsReducerState,
  Reducer as ClubRequestsReducer
} from './club-requests.reducer';

export interface State {
  clubInfo: ClubInfoReducerState;
  clubMediaFiles: ClubMediaFilesReducerState;
  clubMediaPhotos: ClubMediaPhotosReducerState;
  clubMembers: ClubMembersReducerState;
  clubRequests: ClubRequestsReducerState;
}

export const clubFeatureKey = 'clubs';

export const reducers: ActionReducerMap<State> = {
  clubInfo: ClubInfoReducer,
  clubMediaFiles: ClubMediaFilesReducer,
  clubMediaPhotos: ClubMediaPhotosReducer,
  clubMembers: ClubMembersReducer,
  clubRequests: ClubRequestsReducer
};
