<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="loginModalLongTitle">Start your journey</h5>
  </div>
  <div class="modal-body">
    <div class="form-row mb-2 text-center"
      >Join our unique RoundPier community to discover new opportunities, connect with others and bring your projects,
      organizations or clubs</div
    >
    <div class="form-row flex-column flex-md-row btns justify-content-center align-items-center mt-3">
      <a class="btn" [routerLink]="'/'" fragment="sign_up_modal" mat-dialog-close>Join now</a>
    </div>
  </div>
  <button type="button" class="close" mat-dialog-close>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
