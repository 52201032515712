import { IIcon } from '../../model/icon';

export const work: IIcon = {
  id: `WORK`,
  svg: `
  <g clip-path="url(#clip7)">
  <path d="M3.7 3.9l1.7-2.7.6.4-1.8 2.7-.5-.4zM14 1.6l.6-.4L16.3 4l-.5.4L14 1.6z" fill="#A56A43" />
  <path d="M2 3.8h16c1 0 2 1 2 2v11.8c0 .7-.6 1.4-1.4 1.4H1.4C.6 19 0 18.3 0 17.6V5.9c0-1.2 1-2.1 2-2.1z"
    fill="#A56A43" />
  <path
    d="M19 4.1c-.2.3-.4.7-.4 1v6.3c0 .4-.3.7-.7.7H2.1a.7.7 0 01-.7-.7V5.2c0-.4-.2-.8-.5-1a2 2 0 011.2-.4h15.8l1.2.3zM14.1 1H6a.7.7 0 100 1.4H14a.7.7 0 100-1.4z"
    fill="#CB8252" />
  <path d="M9 13.4v-.3c0-1-.7-1.4-.7-2.8A1.7 1.7 0 0110 8.6c1 .1 1.7 1 1.6 2 0 1.2-.7 1.5-.7 2.5v.3H9z"
    fill="#F29C1F" />
  <path d="M9 13.1v-1.4c0-.4.3-.7.7-.7h.6c.4 0 .7.3.7.7v4.9a1 1 0 11-2 0V13z" fill="#F0C419" />
  <path
    d="M2 18h-.6a.3.3 0 010-.8H2a.3.3 0 010 .7zM7.6 18H3.4a.3.3 0 010-.8h4.2a.3.3 0 010 .7zM18.6 18H18a.3.3 0 010-.8h.7a.3.3 0 010 .7zM16.6 18h-4.2a.3.3 0 110-.8h4.2a.3.3 0 010 .7z"
    fill="#805333" />
  <path d="M11 13.4H9c-.2 0-.4.2-.4.4v.7c0 .2.2.3.4.3h2c.2 0 .4-.1.4-.3v-.7c0-.2-.2-.4-.4-.4z" fill="#F3D55B" />
</g>
<defs>
  <clipPath id="clip7">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>
  `
};
