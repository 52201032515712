import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { environment } from 'src/environments/environment';
import { RegistrationSharedModule } from 'src/app/modules/registration/shared/registration.shared.module';

import { AmplitudeModule, NoopAmplitudeModule } from '../amplitude';
import { AppLoaderModule } from '../loader/loader.module';
import {
  AppSignInDialogComponent,
  AppForgotPasswordDialogComponent,
  AppForgotPasswordCheckEmailDialogComponent
} from './components';
import {
  FORGOT_PASSWORD_CHECK_EMAIL_DIALOG,
  FORGOT_PASSWORD_DIALOG,
  SIGN_IN_DIALOG
} from './sign-in-dialog.token';

@NgModule({
  declarations: [
    AppSignInDialogComponent,
    AppForgotPasswordDialogComponent,
    AppForgotPasswordCheckEmailDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    RegistrationSharedModule,
    environment.production
      ? AmplitudeModule.forRoot(environment.amplitude)
      : NoopAmplitudeModule,
    AppLoaderModule
  ],
  exports: [AppSignInDialogComponent],
  providers: [
    { provide: SIGN_IN_DIALOG, useValue: AppSignInDialogComponent },
    {
      provide: FORGOT_PASSWORD_CHECK_EMAIL_DIALOG,
      useValue: AppForgotPasswordCheckEmailDialogComponent
    },
    {
      provide: FORGOT_PASSWORD_DIALOG,
      useValue: AppForgotPasswordDialogComponent
    }
  ]
})
export class AppSigninDialogModule {}
