import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from 'src/environments/environment';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  AppRouterLinkModule,
  AppSigninDialogModule,
  AppStoreIconModule
} from 'src/app/shared/modules';
import {
  AmplitudeModule,
  NoopAmplitudeModule
} from 'src/app/shared/modules/amplitude';

import { HeaderComponent } from './header.component';
import { NoAuthHeaderComponent } from './no-auth-header/no-auth-header.component';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  declarations: [HeaderComponent, NoAuthHeaderComponent, NotificationComponent],
  imports: [
    CommonModule,
    SharedModule,
    environment.production
      ? AmplitudeModule.forRoot(environment.amplitude)
      : NoopAmplitudeModule,
    AppSigninDialogModule,
    AppStoreIconModule.forRoot({
      appleStoreURL: environment.appleStoreURL,
      googlePlayURL: environment.googlePlayURL
    }),
    AppRouterLinkModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}
