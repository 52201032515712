import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from 'src/environments/environment';
import {
  AmplitudeModule,
  NoopAmplitudeModule
} from 'src/app/shared/modules/amplitude';
import { AppHideForModule, AppRouterLinkModule } from 'src/app/shared/modules';
import {
  AutocompleteModule,
  AppIconsModule,
  AppLinksModule,
  AppAvatarModule,
  AppInitialsModule,
  AppShellRenderModule,
  AppBaseURLModule
} from 'src/app/shared/modules';

import { YearsFromDatePipe } from './pipes/years-from-date.pipe';
import { SidebarBlockComponent } from './components/sidebar-block/sidebar-block.component';
import { SidebarTitleComponent } from './components/sidebar-title/sidebar-title.component';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { BaseUrlPipe } from './pipes/base-url/base-url.pipe';
import { IsCurrentUserPipe } from './pipes/is-current-user.pipe';
import { ReverseArrayPipe } from './pipes/reverse-array.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { DogToLinkPipe } from './pipes/dog-to-link.pipe';
import { FormControlArrayComponent } from './components/form-control-array/form-control-array.component';
import { BaseFormControlComponent } from './components/base-form-control/base-form-control.component';
import { BaseFormControlArrayComponent } from './components/base-form-control-array/base-form-control-array.component';
import { ButtonPlusComponent } from './components/button-plus/button-plus.component';
import { RandomElementsPipe } from './pipes/randomElements.pipe';
import {
  ImageCropperComponent,
  InputComponent,
  DateComponent,
  AutocompleteComponent,
  TagsComponent,
  TextComponent,
  MultiTagsComponent,
  EditorModule
} from './form';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RouterModule } from '@angular/router';
import { SelectFileComponent } from './components/select-file/select-file.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MessageEmojiComponent } from './components/message/emojis/emojis.component';
import { MessageImagesComponent } from './components/message/images/images.component';
import { MessageDocsComponent } from './components/message/docs/docs.component';
import { MessageComponent } from './components/message/message.component';
import { NewMessageInputFieldComponent } from './components/message/new-message-input-field/new-message-input-field.component';
import { DocComponent } from './components/message/docs/doc/doc.component';
import { MessagePhotosComponent } from './components/message/photos/photos.component';
import { MessageLinkPreviewComponent } from './components/message/message-post-link-preview/message-post-link-preview.component';
import { NewMessageDialogComponent } from './components/new-message-dialog/new-message-dialog.component';
import { PageCounterComponent } from './components/page-counter/page-counter.component';
import { VisibilityTriggerDirective } from './components/visibility-trigger/visibility-trigger.component';
import { WriteMessageModalComponent } from './components/write-message-modal/write-message-modal.component';

import { LinksPipe } from './pipes/links.pipe';
import { TextTruncatePipe } from './pipes/text-truncate.pipe';
import { Nl2brPipe } from './pipes/nl2br.pipe';

import { CommentComponent } from './components/comments/item/comment.component';
import { CommentsComponent } from './components/comments/list/comments.component';
import { PostComponent } from './components/posts/item/post.component';
import { PostsComponent } from './components/posts/list/posts.component';
import { NoPostsComponent } from './components/posts/no-posts/no-posts.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { SuggestedProfilesRightBarComponent } from './components/suggested-profiles-right-bar/suggested-profiles-right-bar.component';
import { SummerProgramsRightBarComponent } from './components/summer-programs-right-bar/summer-programs-right-bar.component';
import { AfterSchoolProgramsRightBarComponent } from './components/after-school-programs-right-bar/after-school-programs-right-bar.component';
import { JobsRightBarComponent } from './components/jobs-right-bar/jobs-right-bar.component';
import { ReadMoreDirective } from './directives/read-more.directive';
import { SuggestedArticlesComponent } from './components/suggested-articles/suggested-articles.component';
import { LinkComponent } from './components/link/link.component';
import { MessageTagOptionsComponent } from './components/message/tag-options/tag-options.component';
import { CreatePrivateGroupDialogComponent } from './components/create-private-group-dialog/create-private-group-dialog.component';
import { CreateSchoolClubChapterDialogComponent } from './components/create-school-club-chapter-dialog/create-school-club-chapter-dialog.component';
import { RpProgramsComponent } from './components/rp-programs/rp-programs.component';
import { SelectComponent } from './form/select/select.component';
import { LayoutModule } from '@angular/cdk/layout';
import { YourSchoolComponent } from './components/sidebars/your-school/your-school.component';
import { NewLinePipe } from './pipes/new-line.pipe';
import { PostDiscussionHeaderComponent } from './components/posts/item/headers/discussion/discussion.component';
import { PostUserHeaderComponent } from './components/posts/item/headers/user/user.component';
import { PostGroupHeaderComponent } from './components/posts/item/headers/group/group.component';
import { PostContentComponent } from './components/posts/item/content/content.component';
import { SharePostDialogComponent } from './components/posts/item/share/share.component';
import { ReferencesComponent } from './components/references/references.component';
import { ConnectDialog403Component } from './components/403-connect-dialog/403-connect-dialog.component';
import { ReferenceAutocompleteComponent } from './components/reference-autocomplete/reference-autocomplete.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseSelectComponent } from './components/base-select/base-select.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';

import { MetaService } from './services/meta.service';
import { ClearAgeGroupPipe } from './pipes/clear-age-group.pipe';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { PlatformService } from './services/platform.service';
import { ConvertNameToUrlPipe } from './pipes/convert-name-to-url.pipe';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { LinkPrefixPipe } from './pipes/links-prefix.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ImagesUrlPipe } from './pipes/images-url.pipe';
import { PostDetailsComponent } from './components/posts/post-details/post-details.component';
import { PasswordComponent } from './form/password/password.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { StripTagsPipe } from './pipes/strip-tags.pipe';
import { MobileViewDirective } from './directives/mobile-view.directive';
import { PostURLTextPipe } from './pipes/post-url-text.pipe';
import { ProfileBadgeComponent } from './components/profile-badge/profile-badge.component';
import { OrganizationBadgeComponent } from './components/organization-badge/organization-badge.component';
import { DiscussionBadgeComponent } from './components/discussion-badge/discussion-badge.component';
import { GroupBadgeComponent } from './components/group-badge/group-badge.component';
import { EventBadgeComponent } from './components/event-badge/event-badge.component';
import { NbspClearPipe } from './pipes/nbsp-clear.pipe';
import { CropImageModalComponent } from './modals/crop-image-modal/crop-image-modal.component';
import { ScrolltoInvalidControlDirective } from './directives/scrollto-invalid-control.directive';
import { StartYourJourneyDialogComponent } from './components/start-your-journey-dialog/start-your-journey-dialog.component';
import { HTTPPrefixPipe } from './pipes/http-prefix.pipe';
import { DownloadAppDialogComponent } from './components/download-app-dialog/download-app-dialog.component';
import { FirstVisitService } from './services/first-visit.service';
import { MessageTextComponent } from './components/message/text/text.component';
import { SafeTextPipe } from './pipes/safe-text.pipe';
import { CreateAttachedSchoolClubChapterDialogComponent } from './components/create-attached-school-club-chapter-dialog/create-attached-school-club-chapter-dialog.component';
import { GroupCategoryNamePipe } from './pipes/group-category-name.pipe';
import { IsDefaultImagePipe } from './pipes/is-default-image.pipe';
import { NewMessageDialogWithAdditionalFieldComponent } from './components/new-message-dialog-with-additional-field/new-message-dialog-with-additional-field.component';
import { PostEyeSignComponent } from './components/post-eye-sign/post-eye-sign.component';
import { IsViewableDirective } from './directives/is-viewable/is-viewable.directive';
import { LogoComponent } from './components/logo/logo.component';
import { GlobalLoaderComponent } from './components/global-loader/global-loader.component';
import { DefaultOrgAvatarComponent } from './components/default-org-avatar/default-org-avatar.component';
import { LocationComponent } from './components/location/location.component';
import { CategoriesService } from './services/categories.service';
import { IsOrgPipe } from './pipes/is-org.pipe';
import { SwitchMultiCasePipe } from './pipes/switch-multi-case.pipe';
import { SanitizeAttributesPipe } from './pipes/sanitize-attributes/sanitize-attributes.pipe';
import { SafeUserInputPipe } from './pipes/safe-user-input/safe-user-input.pipe';
import { Autocomplete2Component } from './components/autocomplete-2/autocomplete-2.component';
import { FBQService } from './services/fbq.service';
import { CopyToClipboardService } from './services/copy-to-clipboard/copy-to-clibboard.service';

import LinksComponents from './components/links';
import LinksServices from './services/links';

import { PaginatorComponent } from './components/paginator/paginator.component';
import { IsActiveLinkDirective } from './directives/is-active-link/is-active-link.directive';
import { ProfileImgPipe } from './pipes/profile-img.pipe';
import { SelectDocsComponent } from './components/select-docs/select-docs.component';
import { SelectImagesComponent } from './components/select-images/select-images.component';

import { SpaceTrimDirective } from './directives/space-trim/space-trim.directive';
import { ProfileNameTitlePipe } from './pipes/profile-name-title/profile-name-title.pipe';
import { ProfileTypeTitlePipe } from './pipes/profile-type-title/profile-type-title.pipe';

import { HideUntilLoadedDirective } from './directives/hide-until-loaded/hide-until-loaded.directive';
import { ShowUntilLoadedDirective } from './directives/show-until-loaded/show-until-loaded.directive';

import LoadersComponents from './components/loaders';
import Dialogs from './components/dialogs';
import Pipes from './pipes';
import Guards from './guards';

import { PostSharedModule } from 'src/app/modules/posts/shared/shared.module';
import { OrganizationSearchComponent } from './components/organization-search/organization-search.component';
import { AppBaseUrlPipe } from './modules/baseurl/pipes';
import { SendMessageComponent } from './components/send-message/send-message.component';

const SERVICES = [
  MetaService,
  PlatformService,
  FirstVisitService,
  CategoriesService,
  FBQService,
  CopyToClipboardService,
  ...LinksServices
];

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  ImageCropperModule,
  RouterModule,
  LayoutModule,
  NgSelectModule,
  EditorModule,
  PostSharedModule,
  AutocompleteModule,
  AppIconsModule,
  AppLinksModule,
  AppAvatarModule,
  AppInitialsModule,
  AppShellRenderModule,
  AppBaseURLModule,
  AppHideForModule,
  AppRouterLinkModule
];

const MATERIALS = [
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatSortModule,
  MatTableModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSelectModule,
  MatCheckboxModule,
  MatSliderModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatBadgeModule,
  MatIconModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatTabsModule,
  MatChipsModule
];

const PIPES = [
  YearsFromDatePipe,
  BaseUrlPipe,
  IsCurrentUserPipe,
  ReverseArrayPipe,
  OrderByPipe,
  DogToLinkPipe,
  FileSizePipe,
  LinksPipe,
  TextTruncatePipe,
  Nl2brPipe,
  RandomElementsPipe,
  NewLinePipe,
  ClearAgeGroupPipe,
  ConvertNameToUrlPipe,
  LinkPrefixPipe,
  ImagesUrlPipe,
  StripTagsPipe,
  PostURLTextPipe,
  NbspClearPipe,
  HTTPPrefixPipe,
  SafeTextPipe,
  GroupCategoryNamePipe,
  IsDefaultImagePipe,
  IsOrgPipe,
  SwitchMultiCasePipe,
  SanitizeAttributesPipe,
  SafeUserInputPipe,
  ProfileImgPipe,
  ProfileNameTitlePipe,
  ProfileTypeTitlePipe,
  ...Pipes
];

const DIRECTIVES = [
  VisibilityTriggerDirective,
  ReadMoreDirective,
  MobileViewDirective,
  ScrolltoInvalidControlDirective,
  IsActiveLinkDirective,
  SpaceTrimDirective,
  HideUntilLoadedDirective,
  ShowUntilLoadedDirective
];

const COMPONENTS = [
  SidebarBlockComponent,
  SidebarTitleComponent,
  SidebarItemComponent,
  FormControlArrayComponent,
  ButtonPlusComponent,
  AutocompleteComponent,
  CheckboxComponent,
  SelectComponent,
  InputComponent,
  PasswordComponent,
  DateComponent,
  TagsComponent,
  TextComponent,
  LinkComponent,
  MultiTagsComponent,
  ImageCropperComponent,
  SelectFileComponent,

  MessageEmojiComponent,
  MessageImagesComponent,
  MessageDocsComponent,
  MessageTextComponent,
  MessageComponent,
  NewMessageInputFieldComponent,
  DocComponent,
  MessagePhotosComponent,
  MessageLinkPreviewComponent,
  CommentComponent,
  CommentsComponent,
  MessageTagOptionsComponent,
  PostsComponent,
  PostComponent,
  NoPostsComponent,
  PostDiscussionHeaderComponent,
  PostUserHeaderComponent,
  PostGroupHeaderComponent,
  PostContentComponent,
  SharePostDialogComponent,
  StartYourJourneyDialogComponent,

  NewMessageDialogComponent,
  NewMessageDialogWithAdditionalFieldComponent,
  PageCounterComponent,
  WriteMessageModalComponent,
  UserNameComponent,
  ConnectDialog403Component,

  SuggestedProfilesRightBarComponent,
  SummerProgramsRightBarComponent,
  AfterSchoolProgramsRightBarComponent,
  JobsRightBarComponent,
  SuggestedArticlesComponent,
  YourSchoolComponent,
  CreatePrivateGroupDialogComponent,
  CreateSchoolClubChapterDialogComponent,
  CreateAttachedSchoolClubChapterDialogComponent,
  RpProgramsComponent,
  ReferencesComponent,
  ReferenceAutocompleteComponent,
  BaseSelectComponent,
  CustomSelectComponent,

  ImageViewerComponent,
  ConfirmDialogComponent,

  ProfileBadgeComponent,
  OrganizationBadgeComponent,
  DiscussionBadgeComponent,
  GroupBadgeComponent,
  EventBadgeComponent,
  CropImageModalComponent,

  LogoComponent,
  GlobalLoaderComponent,
  DefaultOrgAvatarComponent,
  LocationComponent,
  PaginatorComponent,
  Autocomplete2Component,
  SelectDocsComponent,
  SelectImagesComponent,

  OrganizationSearchComponent,

  ...LoadersComponents,
  ...LinksComponents,
  ...Dialogs
];

@NgModule({
  declarations: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    BaseFormControlComponent,
    BaseFormControlArrayComponent,
    PostDetailsComponent,
    ProfileMenuComponent,
    DownloadAppDialogComponent,
    PostEyeSignComponent,
    IsViewableDirective,
    ProfileImgPipe,
    SendMessageComponent
  ],
  imports: [
    CommonModule,
    ...MODULES,
    ...MATERIALS,
    environment.production
      ? AmplitudeModule.forRoot(environment.amplitude)
      : NoopAmplitudeModule
  ],
  exports: [
    ...MODULES,
    ...MATERIALS,
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    ProfileMenuComponent,
    SendMessageComponent
  ],
  entryComponents: [WriteMessageModalComponent],
  providers: [...Guards, ...SERVICES, ...PIPES, AppBaseUrlPipe]
})
export class SharedModule {}
