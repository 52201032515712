import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { environment } from 'src/environments/environment';
import { HeaderModule } from 'src/app/pages/components/header/header.module';
import { CompetitionsShortListModule } from 'src/app/modules/competitions';
import {
  AppHideForModule,
  AppLeftNavModule,
  AppStickyScrollModule
} from 'src/app/shared/modules';

import Components from './components';
import Services from './services';

@NgModule({
  declarations: [...Components],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    AppHideForModule,
    CompetitionsShortListModule,
    AppLeftNavModule.forRoot({
      collegesURL: environment.collegesURL
    }),
    AppStickyScrollModule
  ],
  exports: [...Components],
  providers: [...Services]
})
export class TemplatesModuleModule {}
