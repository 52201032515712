import { IIcon } from '../../model/icon';

export const save: IIcon = {
  id: `SAVE`,
  svg: `<g clip-path="url(#clip0)">
  <path d="M15.9734 3.19629V19.9999L9.99988 15.8904L4.02637 19.9999V3.19629H15.9734Z" fill="#BDBDBD"/>
  <path d="M4.02637 0H15.9734V5H4.02637V0Z" fill="#828282"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="20" height="20" fill="white"/>
  </clipPath>
  </defs>`
};

export const saved: IIcon = {
  id: `SAVED`,
  svg: `<g clip-path="url(#clip0)">
  <path d="M15.9734 3.19629V19.9999L9.99988 15.8904L4.02637 19.9999V3.19629H15.9734Z" fill="#EB5757"/>
  <path d="M4.02637 0H15.9734V5H4.02637V0Z" fill="#C03A2B"/>
  </g>
  <defs>
  <clipPath id="clip0">
  <rect width="20" height="20" fill="white"/>
  </clipPath>
  </defs>`
};
