import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { PostState } from 'src/app/shared/ui/post/data/model/state';
import { postView } from 'src/app/core/services/posts/posts.actions';

@Injectable({
  providedIn: 'root'
})
export class PostsFacade {
  constructor(private readonly store: Store<PostState>) {}

  onViewed(id: number) {
    this.store.dispatch(postView({ id }));
  }
}
