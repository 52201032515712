import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'yearsFromDate'
})
export class YearsFromDatePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    const diff = moment().diff(moment(value, 'YYYY-MM-DD'), 'years', false);

    return `${Number.isSafeInteger(diff) ? diff : 'Some'} years old`;
  }
}
