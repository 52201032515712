import { Directive, Optional, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[type=text], [matInput], textarea',
  host: { '(blur)': 'onBlur($event.target); false' }
})
export class SpaceTrimDirective {
  constructor(
    private readonly renderer: Renderer2,
    @Optional() private readonly ngControl: NgControl
  ) {}

  onBlur(element: HTMLInputElement) {
    if (element.readOnly) {
      return;
    }

    const operations: Array<(_: string) => string> = [
      (_: string) => _.trim(),
      this.isTextarea(element)
        ? (_: string) => _
        : (_: string) => _.replace(/\s{2,}/g, ' ')
    ];

    const value = operations.reduce((acc, operation) => {
      return operation(acc);
    }, element.value);

    if (this.ngControl?.control) {
      // if it's a matInput element
      return this.ngControl.control.setValue(value);
    }

    this.renderer.setProperty(element, 'value', value);
  }

  private isTextarea(element: HTMLInputElement) {
    return element.tagName === 'TEXTAREA';
  }
}
