import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { IImage } from '../../models/image';

export interface IImagesState extends EntityState<IImage> {}

export const imagesKey = `images`;

export const imagesAdapter = createEntityAdapter<IImage>({
  selectId: ({ id }) => id,
  sortComparer: (a, b) => a.id - b.id
});

export const imagesInitialState = imagesAdapter.getInitialState({});
