<div class="d-flex content-form">
  <div class="avatar rounded-circle d-none d-md-block">
    <img src="https://via.placeholder.com/150x150" class="img-fluid" />
  </div>
  <div class="input-wrapper d-flex">
    <input type="text" placeholder="Post an update, article or question" />
    <div class="btns d-flex align-items-center">
      <button>
        <svg viewBox="0 0 16 16"><use href="#new-files" /></svg>
      </button>
      <button>
        <svg viewBox="0 0 16 16"><use href="#new-photo" /></svg>
      </button>
      <button>
        <svg viewBox="0 0 16 16"><use href="#new-smile" /></svg>
      </button>
    </div>
  </div>
  <button class="send">
    <svg viewBox="0 0 26 25"><use href="#new-send" /></svg>
  </button>
</div>
