import moment from 'moment';

import { countNewDialogsSuccess } from 'src/app/core/services/dialogs/dialogs.actions';
import { loadNotificationsSuccess } from 'src/app/core/services/notifications/notifications.actions';
import {
  acceptRequestSuccess,
  rejectRequestSuccess
} from 'src/app/core/services/requests/requests.actions';
import { WeeklyActions } from 'src/app/modules/weekly/store/actions';
import * as UserActions from 'src/app/core/services/user/user.actions';
import { PostsActions } from 'src/app/modules/posts/actions';

import { NoticeCountersActions } from '../actions';

export interface State {
  loaded: boolean;
  networkRequests: number;
  messages: number;
  notifications: number;
  weeklyReadAt: number;
  amountOfNewPosts: number;
  amountOfNewPrivatePosts: number;
  amountOfSavedPosts: number;
}

const initialState: State = {
  loaded: false,
  networkRequests: 0,
  messages: 0,
  notifications: 0,
  weeklyReadAt: null,
  amountOfNewPosts: 0,
  amountOfNewPrivatePosts: 0,
  amountOfSavedPosts: 0
};

export function Reducer(
  state = initialState,
  action:
    | NoticeCountersActions.Actions
    | WeeklyActions.Actions
    // TODO: fix any type to countNewDialogsSuccess.type and loadNotificationsSuccess.type and acceptRequestSuccess.type
    | any
): State {
  switch (action.type) {
    case NoticeCountersActions.ActionTypes.LOAD_NOTICE_COUNTERS:
      return initialState;
    case NoticeCountersActions.ActionTypes.LOAD_NOTICE_COUNTERS_SUCCESS:
      return {
        ...state,
        loaded: true,
        networkRequests: action.payload.networkRequests,
        messages: action.payload.newMessages,
        notifications: action.payload.notifications,
        weeklyReadAt: action.payload.weeklyReadAt,
        amountOfNewPosts: action.payload.amountOfNewPosts,
        amountOfNewPrivatePosts: action.payload.amountOfNewPrivatePosts,
        amountOfSavedPosts: action.payload.amountOfSavedPosts
      };
    case WeeklyActions.ActionTypes.READ_WEEKLY_SUCCESS:
      return {
        ...state,
        weeklyReadAt: moment.utc().valueOf()
      };
    case loadNotificationsSuccess.type:
      return {
        ...state,
        notifications: 0
      };
    case countNewDialogsSuccess.type:
      return {
        ...state,
        messages: action.count
      };
    case rejectRequestSuccess.type:
    case acceptRequestSuccess.type:
      const networkRequests = state.networkRequests
        ? state.networkRequests - 1
        : 0;

      return {
        ...state,
        networkRequests
      };
    case NoticeCountersActions.ActionTypes.CLEAN_NOTICE_COUNTERS:
      return initialState;
    case UserActions.userLogout.type:
      return initialState;
    case PostsActions.ActionTypes.READ_NEWS_SUCCESS:
      return {
        ...state,
        amountOfNewPosts: 0
      };
    case PostsActions.ActionTypes.READ_PRIVATE_NEWS_SUCCESS:
      return {
        ...state,
        amountOfNewPrivatePosts: 0
      };
    default:
      return state;
  }
}

export const getNetworkRequests = (state: State) => state.networkRequests;
export const getMessages = (state: State) => state.messages;
export const getNotifications = (state: State) => state.notifications;
export const getWeeklyReadAt = (state: State) => state.weeklyReadAt;
export const getAmountOfNewPosts = (state: State) => state.amountOfNewPosts;
export const getAmountOfNewPrivatePosts = (state: State) =>
  state.amountOfNewPrivatePosts;
export const getAmountOfSavedPosts = (state: State) => state.amountOfSavedPosts;
export const isLoaded$ = (state: State) => state.loaded;
