import { IIcon } from '../../model/icon';

export const bell: IIcon = {
  id: `BELL`,
  svg: `
  <g clip-path="url(#clip4)">
      <path d="M10.4 27.2c.2 1.6 2.2 2.8 4.6 2.8 2.4 0 4.4-1.2 4.6-2.8h-9.2z" fill="#F29C1F" />
      <path
        d="M27.2 25.8c-2.7 1-7.2 1.6-12.2 1.6-5 0-9.5-.6-12.2-1.6a2.6 2.6 0 01-1-4.2l.4-.3c1.8-2 4-5.1 4-8.9v-.8A9.1 9.1 0 0112 3.2V3a3.1 3.1 0 116.2 0l1 .5c.8.4 1.5 1 2.1 1.6a8.8 8.8 0 012.6 6.2v1c0 3.8 2.2 7 4 8.9l.4.3.3.5a2.6 2.6 0 01-1.3 3.7z"
        fill="#F0C419" />
      <path
        d="M11.9 3.2v1.5a.5.5 0 11-1 0v-1l1-.5zM19.1 3.6v1a.5.5 0 11-1 0V3.3c.4 0 .7.2 1 .4zM5.7 22.5a.5.5 0 01-.6.4 46.6 46.6 0 01-3.6-.8c0-.2.2-.3.3-.5l.4-.3a50.6 50.6 0 003.4.8c0 .1.1.3 0 .4zM28.5 22.1l-.1.1a56.4 56.4 0 01-20.6.9.5.5 0 01.5-.7 58.1 58.1 0 0019.5-1.1l.4.3.3.5zM15 4.7a.5.5 0 01-.5-.6V2.6a.5.5 0 111 0V4c0 .3-.2.6-.5.6z"
        fill="#F29C1F" />
      <path d="M10.3 13.4a.5.5 0 01-.5-.5 2 2 0 012.1-2 .5.5 0 110 1 1 1 0 00-1 1c0 .3-.3.5-.6.5z" fill="#FB7B76" />
    </g>
    <defs>
      <clipPath id="clip4">
        <path fill="#fff" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>`
};
