import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { IDoc } from '../../models/doc';
import { addDocs, addDocsSuccess } from './docs.actions';
import { selectAllDocs } from './docs.selectors';

@Injectable({
  providedIn: `root`
})
export class DocsFacade {
  readonly all = this.store.select(selectAllDocs);

  constructor(protected store: Store, protected actions: Actions) {}

  public async add(docs: File[]): Promise<IDoc[]> {
    if (docs.length === 0) return []; // #Return#

    this.store.dispatch(addDocs({ docs }));

    const _docs =
      (await this.actions
        .pipe(
          ofType(addDocsSuccess),
          take(1),
          map(({ docs }) => docs)
        )
        .toPromise()) ?? [];

    return _docs;
  }
}
