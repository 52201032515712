import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'submissionStatus'
})
export class SubmissionStatusPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 4:
        return 'Review sent';
      case 3:
        return 'Rechecked';
      case 2:
        return 'Reviewed';
      case 1:
        return 'Jury appointed';
      case 0:
      default:
        return 'Submitted';
    }
  }
}
