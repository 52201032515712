import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompetitionsFacade } from '../../../store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ICompetitionReview } from '../../../model';

export interface Data {
  review: ICompetitionReview;
}

@Component({
  selector: 'app-competition-jury-rate-submission-dialog',
  templateUrl: './competition-jury-rate-submission-dialog.component.html',
  styleUrls: ['./competition-jury-rate-submission-dialog.component.scss']
})
export class CompetitionJuryRateSubmissionDialogComponent {
  get review() {
    return this.data.review;
  }

  constructor(
    private dialogRef: MatDialogRef<CompetitionJuryRateSubmissionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {
    this.resultForm.get('note').setValue(data.review.note);
    this.resultForm.get('rating').setValue(data.review.rating);

    console.log(data.review);
  }

  readonly fields = {
    rating: new FormControl('', Validators.required),
    note: new FormControl('', Validators.required)
  };

  resultForm = new FormGroup(this.fields);

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close({ ...this.review, ...this.resultForm.value });
  }
}
