import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-organization-badge',
  templateUrl: './organization-badge.component.html',
  styleUrls: ['./organization-badge.component.scss']
})
export class OrganizationBadgeComponent {
  @Input() organization: any;

  @Output() follow = new EventEmitter<number>();
  @Output() unfollow = new EventEmitter<number>();

  onFollow(organization) {
    this.follow.emit(organization.id);
  }

  onUnfollow(organization) {
    this.unfollow.emit(organization.id);
  }
}
