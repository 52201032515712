import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  UrlSegment,
  UrlMatchResult
} from '@angular/router';

import { ChatComponent } from './containers/chat/chat.component';

const routes: Routes = [
  {
    matcher: (segments: UrlSegment[]): UrlMatchResult => {
      return segments?.length ? null : { consumed: [new UrlSegment('', {})] };
    },
    component: ChatComponent
  },
  {
    path: ':id',
    component: ChatComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule {}
