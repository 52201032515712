import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import { IDiscussion } from '../../../shared/models/discussion';

@Injectable({
  providedIn: 'root'
})
export class DiscussionsService {
  constructor(private base: BaseService) {}

  searchDiscussions(
    params
  ): Observable<{ count: number; discussions: IDiscussion[] }> {
    return this.search('discussions', params).pipe(
      map((_) => ({ count: _.count, discussions: _.discussions }))
    );
  }

  private search(kind, params) {
    console.log('kinds: ', kind, params);

    const requestParams = { ...params };
    const { q, page } = requestParams;

    delete requestParams['q'];

    const urlParams = new URLSearchParams({
      ...params,
      text: q ? q : '',
      page
    });

    return this.base.get(`search/${kind}?${urlParams}`);
  }
}
