import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { SubjectSubscriber } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  selectedDialogID$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(private base: BaseService) {}

  get selectedDiaglogID$() {
    return this.selectedDialogID$;
  }

  selectDialogID(id: number) {
    this.selectedDialogID$.next(id);
  }

  selectedDialogID() {
    return this.selectedDialogID$.value;
  }

  getDialogs() {
    return this.base.get('message').pipe(map((res) => res.dialogs));
  }

  getDialogById(id) {
    return this.base
      .get(`message/dialog/${id}`)
      .pipe(map((res) => res.messages));
  }
}
