import { Location } from '@angular/common';
import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  takeUntil,
  tap
} from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

import { APP_META_URL } from '../providers/meta-url.provider';

@Injectable()
export class MetaService implements OnDestroy {
  private readonly _url$ = new Subject<string>();
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _base: BaseService,
    private readonly _meta: Meta,
    private readonly _title: Title,
    private readonly _location: Location,
    @Optional() @Inject(APP_META_URL) private readonly _appMetaURL: string
  ) {
    this._url$
      .pipe(
        distinctUntilChanged(),
        mergeMap((_) => this.fetchMeta(_)),
        tap((_) => this.setMeta(_)),
        takeUntil(this._destroy$)
      )
      .subscribe();

    if (this._appMetaURL) {
      this._url$.next(this._appMetaURL);
    }

    this._location.onUrlChange((_) => {
      this._url$.next(_);
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private fetchMeta(pageURL: string): Observable<IMetaData> {
    const url = this.adjustURL(pageURL);

    return this._base
      .get(`meta?referer=${url}`)
      .pipe(map((response: any) => response.meta));
  }

  private setMeta(data: IMetaData) {
    // NOTE: don't just add tag, remove it first
    // to avoid duplications

    this._meta.removeTag("name='keywords'");
    this._meta.removeTag("name='description'");
    this._meta.removeTag("property='og:title'");
    this._meta.removeTag("property='og:description'");
    this._meta.removeTag("property='og:type'");
    this._meta.removeTag("property='og:url'");
    this._meta.removeTag("property='og:image'");
    this._meta.removeTag("property='og:image:width'");
    this._meta.removeTag("property='og:image:height'");
    this._meta.removeTag("property='og:site_name'");

    this._meta.addTags([
      { name: 'description', content: data.description },
      { name: 'keywords', content: data.keywords },
      { property: 'og:title', content: data.og_title },
      { property: 'og:description', content: data.og_description },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: data.og_url },
      { property: 'og:image', content: data.og_image },
      { property: 'og:image:width', content: data.og_image_width },
      { property: 'og:image:height', content: data.og_image_height },
      { property: 'og:site_name', content: data.og_site_name }
    ]);

    this._title.setTitle(data.title);
  }

  private adjustURL(url: string) {
    const _url = new URL(url);

    return `https://roundpier.com${_url.pathname}`;
  }
}

interface IMetaData {
  description: string;
  keywords: string;
  og_title: string;
  og_description: string;
  og_url: string;
  og_image: string;
  og_image_width: string;
  og_image_height: string;
  og_site_name: string;
  title: string;
}
