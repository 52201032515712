import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import Components, { AppSeeMoreComponent } from './components';

@NgModule({
  declarations: [...Components],
  imports: [CommonModule],
  exports: [AppSeeMoreComponent]
})
export class AppSeeMoreModule {}
