import {
  Component,
  Input,
  HostBinding,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { IDoc } from '../../../../models/doc';

@Component({
  selector: 'app-message-doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.scss']
})
export class DocComponent implements OnInit, AfterViewInit {
  @Input() readonly doc: IDoc;

  @Output() load = new EventEmitter();

  @HostBinding('title') public title: string;

  ngOnInit() {
    this.title = this.doc.name;
  }

  ngAfterViewInit() {
    this.load.emit();
  }
}
