import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { IImage, ILocalImage } from 'src/app/shared/models/image';
import { ImagesFacade } from 'src/app/shared/stores/images/images.facade';

interface IContext {
  upload: Subject<void>;
  uploaded: Subject<number[]>;
}

@Component({
  selector: 'app-select-images',
  templateUrl: './select-images.component.html',
  styleUrls: ['./select-images.component.scss']
})
export class SelectImagesComponent implements OnInit, OnDestroy {
  @Input() ctx: IContext;

  private readonly _uploadInProgress$ = new BehaviorSubject(false);
  private readonly _componentDestroy$ = new Subject();

  readonly images = {
    stream: this.imagesFacade.all,
    saved: new Set<IImage>(),
    local: new Set<ILocalImage>()
  };

  constructor(public imagesFacade: ImagesFacade) {}

  ngOnInit() {
    this.handleUploadEvent();
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  private handleUploadEvent() {
    if (!this.ctx.upload) {
      return;
    }

    this.ctx.upload
      .pipe(
        filter((_) => !this._uploadInProgress$.value),
        tap((_) => this._uploadInProgress$.next(true)),
        mergeMap((_) => this.handleUpload()),
        tap((_) => this._uploadInProgress$.next(false)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  private async handleUpload() {
    const savedImages = [...this.images.saved].map(({ id }) => id);
    const _images = [...this.images.local].map(({ file }) => file);

    this.images.saved.clear();
    this.images.local.clear();

    // TODO: handle error case
    const images = await this.imagesFacade.add(_images);

    const addedImages = images.map(({ id }) => id);

    this.ctx.uploaded.next([...addedImages, ...savedImages]);
    this.ctx.uploaded.complete();
  }
}
