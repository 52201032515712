<section class="main">
  <div class="d-none d-sm-block">
    <ng-container *ngIf="auth.user$ | async as user">
      <app-avatar *ngIf="!ctx.isHideAvatar" [src]="user.icon" [placeholder]="user | initials" class="ava"> </app-avatar>
    </ng-container>
  </div>

  <div class="input" [class.long]="ctx.isTextarea || isLongMessage">
    <app-new-message-input-field
      class="text"
      [placeholder]="placeholder"
      [focus]="focus"
      [tagDataSupplier$]="tagDataSupplier$"
      [removeTag$]="removeTag$"
      [emojiSupplier$]="emojiSupplier$"
      (tag)="onSearchByTag($event)"
      [message]="message$ | async"
      (messageChange)="onMessageChange($event)"
    >
    </app-new-message-input-field>

    <div class="control" [class.long]="ctx.isTextarea || isLongMessage">
      <button (click)="onSelectAsset('DOCS'); false">
        <svg viewBox="0 0 16 16"><use href="#new-files" /></svg>
      </button>
      <button (click)="onSelectAsset('IMAGES'); false">
        <svg viewBox="0 0 16 16"><use href="#new-photo" /></svg>
      </button>
      <button (click)="onSelectAsset('EMOJIS'); false">
        <svg viewBox="0 0 16 16"><use href="#new-smile" /></svg>
      </button>
    </div>
  </div>

  <div *ngIf="!ctx.isHideControls" class="action">
    <button class="send" (click)="onSubmit(); false" [disabled]="isDisabled">
      <svg viewBox="0 0 26 25"><use href="#new-send" /></svg>
    </button>
    <button *ngIf="ctx.close" class="close-button" (click)="onClose(); false"></button>
  </div>
</section>

<div *ngIf="isSuggestArticle$ | async" class="article-redirect">
  <div>
    <span>Text seems long, maybe you'll want to</span>
    <app-link [route]="['discussions', 'new']">
      <span (click)="onArticleRedirect()">&nbsp;write an article&nbsp;</span>
    </app-link>
    <span>?</span>
  </div>
  <button class="article-redirect-hide" (click)="rejectArticle()">Hide</button>
</div>

<a href *ngIf="isShowOptions$ | async" (click)="onRemoveTag(); false">Not a tag</a>

<app-message-tag-options *ngIf="isShowOptions$ | async" [tag]="tag$ | async" (select)="onUserTagSelect($event)">
</app-message-tag-options>

<section class="assets" [ngSwitch]="asset">
  <app-message-docs *ngSwitchCase="'DOCS'" [ctx]="docs" [editable]="editable" class="asset"></app-message-docs>
  <app-message-images *ngSwitchCase="'IMAGES'" [ctx]="images" [editable]="editable" class="asset"> </app-message-images>
  <app-emoji *ngSwitchCase="'EMOJIS'" class="asset" (select)="onEmojiSelection($event)"></app-emoji>
</section>

<ng-container *ngIf="isSupportLinkPreview">
  <app-message-post-link-preview
    [url]="url"
    (show)="onShowPreviewLink(url)"
    (hide)="onHidePreviewLink(url)"
    *ngFor="let url of links$ | async"
  >
  </app-message-post-link-preview>
</ng-container>
