import { createReducer, on } from '@ngrx/store';
import { docsInitialState, docsAdapter } from './docs.state';
import { loadDocsSuccess, addDocsSuccess } from './docs.actions';

export const docsReducer = createReducer(
  docsInitialState,
  on(loadDocsSuccess, (state, action) =>
    docsAdapter.setAll(action.docs, {
      ...state
    })
  ),
  on(addDocsSuccess, (state, action) =>
    docsAdapter.addMany(action.docs, {
      ...state
    })
  )
);
