<app-comment *ngFor="let comment of firstThree" [comment]="comment" [ctx]="ctx" class="post-comments"></app-comment>

<ng-container *ngIf="isShowMore">
  <app-comment *ngFor="let comment of other" [comment]="comment" [ctx]="ctx" class="post-comments"></app-comment>
</ng-container>

<section *ngIf="status === 'LOADING'">
  <app-loader-2></app-loader-2>
</section>

<section *ngIf="status !== 'LOADING'" class="actions" [class.full]="isShowMore && status !== 'FULL'">
  <button
    *ngIf="(!isShowMore && other.length) || (!isShowMore && status === 'READY')"
    class="action"
    (click)="showMore()"
    >View more comments</button
  >
  <button *ngIf="isShowMore && status !== 'FULL'" class="action" (click)="paginate()">Load more comments</button>
  <button *ngIf="isShowMore" class="action" (click)="hideMore()">Back to short list</button>
</section>
