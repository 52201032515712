import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { RoutingService } from 'src/app/core/services/routing.service';
import { IDoc } from 'src/app/shared/models/doc';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-dialog-message',
  templateUrl: './chat-dialog-message.component.html',
  styleUrls: ['./chat-dialog-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatDialogMessageComponent implements OnInit, OnChanges {
  // TODO: add type
  @Input() message: any;

  @Output() rendered = new EventEmitter<void>();

  private amountOfRenderedTexts$ = new BehaviorSubject(0);
  private amountOfRenderedDocs$ = new BehaviorSubject(0);
  private amountOfRenderedImages$ = new BehaviorSubject(0);

  constructor(private readonly routingService: RoutingService) {}

  ngOnInit() {
    const amountOfTextContent = this.message.content ? 1 : 0;
    const amountOfDocs = this.message.files.length;
    const amountOfImages = this.message.photos.length;

    combineLatest([
      this.amountOfRenderedTexts$,
      this.amountOfRenderedImages$,
      this.amountOfRenderedDocs$
    ])
      .pipe(
        filter(
          ([
            amountOfRenderedTexts,
            amountOfRenderedImages,
            amountOfRenderedDocs
          ]) =>
            amountOfRenderedTexts === amountOfTextContent &&
            amountOfRenderedImages + amountOfRenderedDocs ===
              amountOfImages + amountOfDocs
        ),
        take(1),
        tap((_) => this.rendered.emit())
      )
      .subscribe((_) => _);
  }

  ngOnChanges(changes: any) {
    if (
      changes.selectedDialog?.currentValue &&
      changes.selectedDialog?.previousValue &&
      changes.selectedDialog?.currentValue !==
        changes.selectedDialog?.previousValue
    ) {
      this.amountOfRenderedTexts$.next(0);
      this.amountOfRenderedDocs$.next(0);
      this.amountOfRenderedImages$.next(0);
    }
  }

  // TODO: use plain link with target="_blank"
  openFile(doc: IDoc) {
    this.routingService.openDoc(doc);
  }

  onPhotosLoaded() {
    this.amountOfRenderedImages$.next(this.amountOfRenderedImages$.value + 1);
  }

  onDocRendered() {
    this.amountOfRenderedDocs$.next(this.amountOfRenderedDocs$.value + 1);
  }

  onMessageTextLoaded() {
    this.amountOfRenderedTexts$.next(1);
  }
}
