import { Input, Directive, ElementRef, HostListener } from '@angular/core';

// ==================================================
//                   Model
// ==================================================
export interface IVisibilityCtx {
  readonly visible: Function;
  readonly hidden: Function;
}
// ==================================================
//                   Directive
// ==================================================
@Directive({
  selector: '[visibility]'
})
export class VisibilityTriggerDirective {
  protected isCurrentlyVisible = false;
  protected onVisible: Function;
  protected onHidden: Function;

  @Input('visibility') private set ctx(ctx: IVisibilityCtx | Function) {
    if (typeof ctx === 'function') {
      this.onVisible = ctx;
      this.onHidden = () => null;
    } else {
      this.onVisible = ctx.visible;
      this.onHidden = ctx.hidden;
    }
  }

  @HostListener('window:scroll') protected onScroll() {
    const isVisible = this.isVisible();

    if (this.isCurrentlyVisible === isVisible) return; // #Return#
    this.isCurrentlyVisible = isVisible;
    if (isVisible) this.onVisible();
    else this.onHidden();
  }

  constructor(private hostRef: ElementRef<HTMLElement>) {}

  protected isVisible() {
    const rect = this.hostRef.nativeElement.getBoundingClientRect();
    const viewportWidth =
      window.innerWidth ?? document.documentElement.clientWidth;
    const viewportHeight =
      window.innerHeight ?? document.documentElement.clientHeight;

    const isTop = rect.top >= 0;
    const isLeft = rect.left >= 0;
    const isBottom = rect.bottom <= viewportHeight;
    const isRight = rect.right <= viewportWidth;

    return isTop && isLeft && isBottom && isRight;
  }
}
