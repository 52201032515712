<div class="ava-placaeholder">
  <a [article]="{ id: post.obj_id, name: post.description, tags: post.tags }">
    <img src="/assets/img/default/eduniverse/disscution.png" alt="" />
  </a>
</div>

<div>
  <div class="name d-flex align-items-center">
    <div class="author d-flex align-items-center">
      <div class="name d-flex align-items-center">
        <a
          class="text-reset text-decoration-none"
          [article]="{ id: post.obj_id, name: post.description, tags: post.tags }"
        >
          <span class="text" [innerHTML]="post.description | safeUserInput:{tags: post.tags}"></span>
        </a>
      </div>
    </div>
  </div>

  <div class="author d-flex align-items-center">
    <a [profile]="post.user">
      <app-avatar [src]="post.user.icon" [placeholder]="post.user | initials" class="share-group-user-ava"></app-avatar>
    </a>
    <div class="d-flex align-items-center">
      <a [profile]="post.user" class="name">{{ post.user.name }}</a>
      <svg viewBox="0 0 20 20" *ngIf="post.user?.is_creditable" class="verify-icon">
        <use href="#verified" />
      </svg>
    </div>
  </div>
</div>

<span class="create_time_ago ml-auto">{{ createdTime | timeAgo }}</span>
