import { IIcon } from '../../../model/icon';

export const reg_educator_basic: IIcon = {
  id: `REG_EDUCATOR_BASIC`,
  svg: ` 
  <g clip-path="url(#clip0)">
      <path d="M10.7685 16.9219L10.1298 17.8775L8.80869 19.8595L7.48734 17.8775L6.84863 16.9219H10.7685Z" fill="#FDD7AD"/>
      <path d="M7.4873 14.4551H10.1298V17.877H7.4873V14.4551Z" fill="#FDD7AD"/>
      <path d="M7.4872 18.3166C7.24405 18.3166 7.04688 18.1195 7.04688 17.8761V14.4542C7.04688 14.2108 7.24405 14.0137 7.4872 14.0137C7.73055 14.0137 7.92772 14.2108 7.92772 14.4542V17.8761C7.92772 18.1195 7.73055 18.3166 7.4872 18.3166Z" fill="#F09372"/>
      <path d="M10.1298 18.3166C9.88663 18.3166 9.68945 18.1195 9.68945 17.8761V14.4542C9.68945 14.2108 9.88663 14.0137 10.1298 14.0137C10.3731 14.0137 10.5703 14.2108 10.5703 14.4542V17.8761C10.5703 18.1195 10.3731 18.3166 10.1298 18.3166Z" fill="#F09372"/>
      <path d="M17.6169 24.0038V24.7042C17.6155 25.1901 17.222 25.5837 16.7361 25.5851H0.880837C0.394955 25.5837 0.00141128 25.1901 0 24.7042V22.0615C0.00120966 19.6194 1.67377 17.4955 4.04753 16.9219H5.61102L8.487 19.5644L8.80857 19.8595L9.11683 19.5468L11.7196 16.9219H13.5694C14.6158 17.1747 15.5595 17.7418 16.2738 18.5471L17.6169 24.0038Z" fill="#285680"/>
      <path d="M10.1298 14.4551V15.3315C9.25663 15.4958 8.36048 15.4958 7.4873 15.3315V14.4551H10.1298Z" fill="#F09372"/>
      <path d="M9.249 19.8596V25.5851H8.36816V19.8596C8.36917 19.7497 8.41171 19.6443 8.48711 19.5644L8.80868 19.8596L9.11694 19.5469C9.20001 19.6299 9.24739 19.742 9.249 19.8596Z" fill="#2C3E50"/>
      <path d="M11.0107 23.8233C11.0107 24.0665 10.8135 24.2636 10.5704 24.2636C10.3271 24.2636 10.1299 24.0665 10.1299 23.8233C10.1299 23.58 10.3271 23.3828 10.5704 23.3828C10.8135 23.3828 11.0107 23.58 11.0107 23.8233Z" fill="#ECF0F1"/>
      <path d="M22.8049 24.3695C22.7562 24.3695 22.7078 24.3612 22.6618 24.3453L21.8291 24.059C21.81 24.0525 21.7912 24.0445 21.7731 24.0354L15.9978 22.0415C15.7675 21.9647 15.5181 22.0874 15.4385 22.3169C15.3587 22.5467 15.1075 22.6683 14.8776 22.5885C14.6478 22.5084 14.5262 22.2574 14.6061 22.0276L17.4818 13.7035C17.5955 13.372 17.8365 13.0994 18.1516 12.9458C18.4665 12.7924 18.8298 12.7704 19.1608 12.8849L25.4061 15.0432C25.8654 15.2022 26.1093 15.7028 25.9519 16.1627L23.2213 24.0727C23.16 24.2503 22.9928 24.3695 22.8049 24.3695ZM25.1194 15.875L25.5358 16.0186L25.1194 15.8748V15.875Z" fill="#955BA5"/>
      <path d="M21.9725 23.6374V23.6419L21.3957 25.3066L15.5687 23.2939C15.1615 23.1532 14.9153 22.7391 14.9863 22.3141C15.057 21.8891 15.4242 21.5774 15.855 21.5762C15.9524 21.577 16.0492 21.5933 16.1413 21.6248L21.9725 23.6374Z" fill="#FAC176"/>
      <path d="M17.5512 20.691C17.4086 20.6908 17.2752 20.6218 17.1925 20.5059C17.1101 20.3899 17.0885 20.2412 17.1349 20.1065L18.8598 15.1112C18.9112 14.9624 19.0381 14.8523 19.1925 14.8225C19.3471 14.7926 19.5058 14.8473 19.6088 14.9662C19.712 15.085 19.7439 15.2499 19.6927 15.3987L17.9675 20.394C17.9062 20.5718 17.7391 20.691 17.5512 20.691Z" fill="#3D324C"/>
      <path d="M23.1514 17.9662C23.1024 17.9662 23.054 17.958 23.0077 17.942L20.5101 17.0795C20.3613 17.0281 20.2512 16.9013 20.2214 16.7469C20.1916 16.5925 20.2462 16.4338 20.3649 16.3306C20.4839 16.2273 20.6486 16.1955 20.7974 16.2467L23.295 17.1094C23.4988 17.1793 23.622 17.3862 23.5865 17.5987C23.5508 17.8112 23.3667 17.9666 23.1514 17.9662Z" fill="#3D324C"/>
      <path d="M22.5767 19.6312C22.5279 19.631 22.4793 19.6229 22.4332 19.6068L19.9354 18.7443C19.7858 18.6937 19.6747 18.5667 19.6445 18.4117C19.614 18.2566 19.6691 18.0974 19.7886 17.9939C19.908 17.8907 20.0737 17.8593 20.2227 17.9117L22.7205 18.7742C22.9241 18.8441 23.0473 19.0512 23.0118 19.2635C22.9761 19.476 22.792 19.6314 22.5767 19.6312Z" fill="#3D324C"/>
      <path d="M22.001 21.2964C21.9522 21.2964 21.9036 21.2881 21.8574 21.272L19.3599 20.4095C19.2111 20.3583 19.101 20.2315 19.071 20.0769C19.0412 19.9224 19.096 19.7638 19.2147 19.6605C19.3335 19.5575 19.4984 19.5255 19.6472 19.5769L22.1445 20.4394C22.3484 20.5093 22.4715 20.7164 22.4359 20.9289C22.4004 21.1412 22.2163 21.2966 22.001 21.2964Z" fill="#3D324C"/>
      <path d="M8.80861 19.8589L7.44754 21.5599C7.35701 21.6732 7.21629 21.7346 7.07153 21.724C6.92678 21.7133 6.79654 21.632 6.72335 21.5066L4.22117 17.217C4.15665 17.1063 4.14335 16.973 4.18508 16.8518C4.22661 16.7307 4.31895 16.6335 4.4379 16.5859L5.83566 16.0271C6.02921 15.9502 6.25017 16.0202 6.36428 16.1944L6.84855 16.9212L7.48725 17.8768L8.80861 19.8589Z" fill="#ECF0F1"/>
      <path d="M13.3958 17.217L10.8937 21.5068C10.8205 21.6322 10.6902 21.7135 10.5455 21.724C10.4007 21.7346 10.26 21.6734 10.1693 21.5601L8.80859 19.8589L10.1297 17.8768L10.7685 16.9212L11.2529 16.1944C11.367 16.0202 11.5878 15.9502 11.7813 16.0271L13.1789 16.5861C13.2979 16.6337 13.3904 16.7307 13.4319 16.8518C13.4737 16.973 13.4604 17.1063 13.3958 17.217Z" fill="#ECF0F1"/>
      <path d="M16.9166 8.22727C16.7535 8.05994 16.5294 7.96619 16.2957 7.9674H16.2913C16.2957 7.8926 16.2957 7.37729 16.2957 7.30692C16.2336 3.23298 12.8822 -0.019806 8.80848 0.0398707C4.73453 -0.019806 1.38316 3.23298 1.32127 7.30692C1.32127 7.37729 1.32127 7.8926 1.3255 7.9674H1.32127C0.836191 7.97103 0.443857 8.36336 0.44043 8.84824C0.441841 9.33412 0.835385 9.72766 1.32127 9.72907H2.32972C2.93758 12.495 5.6065 14.5738 8.80848 14.5738C12.0103 14.5738 14.6792 12.495 15.287 9.72907H16.2957C16.7816 9.72766 17.1751 9.33412 17.1765 8.84824C17.1777 8.61457 17.084 8.39058 16.9166 8.22727Z" fill="#FDD7AD"/>
      <path d="M2.32972 9.72847H1.32127C0.835385 9.72706 0.441841 9.33352 0.44043 8.84763C0.443857 8.36276 0.836191 7.97043 1.32127 7.9668H2.23295C2.21138 8.1555 2.20109 8.34522 2.2021 8.53494C2.20271 8.74401 2.2144 8.95267 2.23718 9.16033C2.25936 9.35428 2.29021 9.53916 2.32972 9.72847Z" fill="#F09372"/>
      <path d="M17.1766 8.84763C17.1752 9.33352 16.7816 9.72706 16.2958 9.72847H15.2871C15.3268 9.53916 15.3577 9.35428 15.3796 9.16033C15.4024 8.95267 15.4143 8.74401 15.4149 8.53494C15.4157 8.34522 15.4055 8.1555 15.3841 7.9668H16.2958C16.7806 7.97043 17.1729 8.36276 17.1766 8.84763Z" fill="#F09372"/>
      <path d="M15.415 8.53481C15.415 8.64066 15.4105 8.7463 15.4061 8.84751C15.3972 8.95336 15.3886 9.059 15.3797 9.16021C15.3577 9.35416 15.3269 9.53904 15.2872 9.72835C14.6793 12.4942 12.0104 14.5731 8.8086 14.5731C5.60662 14.5731 2.93769 12.4942 2.32984 9.72835C2.29032 9.53904 2.25947 9.35416 2.2373 9.16021C2.22863 9.059 2.21976 8.95336 2.21089 8.84751C2.20665 8.7463 2.20222 8.64066 2.20222 8.53481C2.20121 8.3451 2.21149 8.15538 2.23306 7.96668C2.23306 7.94914 2.2373 7.9316 2.2373 7.91385C2.2506 7.77293 2.27701 7.632 2.29899 7.49108C2.33871 7.29733 2.38709 7.10802 2.44435 6.9185V6.91407C3.12257 5.35501 5.24553 1.5101 8.8086 4.00301C12.3717 1.5101 14.4944 5.35501 15.1726 6.91407V6.9185C15.2299 7.10802 15.2783 7.29733 15.318 7.49108C15.34 7.632 15.3664 7.77293 15.3797 7.91385C15.3797 7.9316 15.3841 7.94914 15.3841 7.96668C15.4055 8.15538 15.4158 8.3451 15.415 8.53481Z" fill="#FDD7AD"/>
      <path d="M16.2957 7.30692C16.2957 7.37729 16.2957 7.8926 16.2913 7.9674H15.384C15.3707 7.80893 15.3443 7.65027 15.3179 7.4918C15.2782 7.29805 15.2298 7.10874 15.1725 6.91923V6.91479C14.4943 5.35574 12.3716 1.51082 8.8085 4.00374C5.24544 1.51082 3.12248 5.35574 2.44426 6.91479V6.91923C2.387 7.10874 2.33862 7.29805 2.2989 7.4918C2.27249 7.65027 2.24608 7.80893 2.23297 7.9674H1.32552C1.32129 7.8926 1.32129 7.37729 1.32129 7.30692C1.38318 3.23298 4.73456 -0.0198061 8.8085 0.0398707C12.8822 -0.0198061 16.2336 3.23298 16.2957 7.30692Z" fill="#AF8066"/>
      <path d="M7.92782 7.96686C7.92782 8.4342 7.74234 8.88238 7.4119 9.21282C7.08146 9.54326 6.63328 9.72874 6.16595 9.72854C5.81394 9.73056 5.46959 9.62451 5.17947 9.42471C4.56476 9.01101 4.27525 8.25658 4.45508 7.53784C4.63492 6.81889 5.2458 6.28986 5.98289 6.21426C6.71997 6.13886 7.42541 6.53321 7.74718 7.20054C7.86814 7.43784 7.93024 7.70074 7.92782 7.96686Z" fill="#9FC9D3"/>
      <path d="M13.2128 7.96687C13.213 8.4342 13.0273 8.88238 12.6969 9.21282C12.3666 9.54326 11.9185 9.72874 11.4511 9.72854C10.4804 9.7233 9.6949 8.93762 9.68945 7.96687C9.68865 7.1447 10.257 6.4316 11.0588 6.24915C11.8604 6.06669 12.6816 6.46346 13.0366 7.20498C13.1537 7.44187 13.214 7.70276 13.2128 7.96687Z" fill="#9FC9D3"/>
      <path d="M4.84473 7.96766C4.84392 8.2106 4.64715 8.40737 4.40421 8.40818C3.71752 8.41141 3.03769 8.54427 2.4004 8.80011C2.34254 8.83257 2.27722 8.8493 2.21089 8.8485C2.20665 8.74729 2.20222 8.64165 2.20222 8.5358C2.20121 8.34608 2.21149 8.15637 2.23306 7.96766C2.23306 7.95012 2.2373 7.93258 2.2373 7.91484C2.93285 7.66525 3.6653 7.5344 4.40421 7.52734C4.64715 7.52795 4.84392 7.72472 4.84473 7.96766Z" fill="#35495E"/>
      <path d="M15.4147 8.5358C15.4147 8.64165 15.4103 8.74729 15.4058 8.8485C15.3395 8.8493 15.2742 8.83257 15.2165 8.80011C14.5792 8.54427 13.8992 8.41141 13.2125 8.40818C12.9694 8.40818 12.772 8.21101 12.772 7.96766C12.772 7.72452 12.9694 7.52734 13.2125 7.52734C13.9514 7.5344 14.6839 7.66525 15.3794 7.91484C15.3794 7.93258 15.3838 7.95012 15.3838 7.96766C15.4052 8.15637 15.4155 8.34608 15.4147 8.5358Z" fill="#35495E"/>
      <path d="M9.68929 8.40729C9.57236 8.40749 9.46026 8.36092 9.37781 8.27826C9.22398 8.11919 9.02539 8.01052 8.80846 7.96677C8.59092 8.01072 8.39173 8.1198 8.2377 8.27947C8.06452 8.44681 7.78932 8.44419 7.61957 8.27362C7.44981 8.10306 7.4482 7.82786 7.61614 7.65549C7.93448 7.3317 8.35645 7.13009 8.80846 7.08594C9.26027 7.13009 9.68224 7.3317 10.0006 7.65549C10.1266 7.78149 10.1643 7.97081 10.0961 8.13532C10.0278 8.29983 9.86731 8.40729 9.68929 8.40729Z" fill="#35495E"/>
      <path d="M7.92756 7.96749C7.92756 8.43483 7.74208 8.88301 7.41164 9.21345C7.0812 9.54389 6.63302 9.72937 6.16568 9.72917C5.81367 9.73118 5.46932 9.62514 5.1792 9.42534C5.36851 8.5445 5.96326 7.40379 7.74692 7.20117C7.86788 7.43847 7.92998 7.70137 7.92756 7.96749Z" fill="#67B9CC"/>
      <path d="M13.2126 7.96696C13.2128 8.4343 13.0271 8.88248 12.6967 9.21292C12.3665 9.54336 11.9183 9.72884 11.451 9.72864C11.1112 9.72924 10.7788 9.62965 10.4951 9.44255C10.7165 8.19519 11.7711 7.26657 13.0364 7.20508C13.1536 7.44197 13.2138 7.70285 13.2126 7.96696Z" fill="#67B9CC"/>
      <path d="M6.16586 10.1698C4.94974 10.1698 3.96387 9.18394 3.96387 7.96762C3.96387 6.7515 4.94974 5.76562 6.16586 5.76562C7.38217 5.76562 8.36805 6.7515 8.36805 7.96762C8.36664 9.18333 7.38157 10.1684 6.16586 10.1698ZM6.16586 6.64646C5.43623 6.64646 4.8447 7.23799 4.8447 7.96762C4.8447 8.69745 5.43623 9.28898 6.16586 9.28898C6.89569 9.28898 7.48721 8.69745 7.48721 7.96762C7.48641 7.23839 6.89528 6.64727 6.16586 6.64646Z" fill="#35495E"/>
      <path d="M11.4512 10.1698C10.2349 10.1698 9.24902 9.18394 9.24902 7.96762C9.24902 6.7515 10.2349 5.76562 11.4512 5.76562C12.6673 5.76562 13.6534 6.7515 13.6534 7.96762C13.6518 9.18333 12.6667 10.1684 11.4512 10.1698ZM11.4512 6.64646C10.7214 6.64646 10.1299 7.23799 10.1299 7.96762C10.1299 8.69745 10.7214 9.28898 11.4512 9.28898C12.1808 9.28898 12.7724 8.69745 12.7724 7.96762C12.7716 7.23839 12.1806 6.64727 11.4512 6.64646Z" fill="#35495E"/>
      <path d="M22.2291 26.0323C22.1803 26.0323 22.1317 26.024 22.0855 26.0079L15.4245 23.7077C14.9753 23.5559 14.6421 23.1751 14.5509 22.7099C14.46 22.2446 14.6251 21.7664 14.9838 21.4563C15.3425 21.1464 15.8396 21.0523 16.2868 21.2099L22.9478 23.5101C23.0974 23.5607 23.2085 23.6878 23.2387 23.8426C23.2692 23.9976 23.2141 24.1571 23.0946 24.2603C22.9752 24.3638 22.8095 24.3952 22.6605 24.3428L15.9995 22.0426C15.7711 21.9678 15.5247 22.0902 15.4463 22.3174C15.3677 22.5446 15.4858 22.7928 15.7118 22.8751L22.3728 25.1752C22.5764 25.2452 22.6996 25.4523 22.6641 25.6646C22.6285 25.8771 22.4444 26.0325 22.2291 26.0323Z" fill="#894B9D"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="26" height="26" fill="white"/>
      </clipPath>
      </defs>
  `
};
