export const medal = {
  id: `MEDAL`,
  title: `Medal`,
  svg: `
  <g clip-path="url(#clip0)">
    <path fill="#D75A4A" d="M13.03 10.04v.53c.03 1.01-1.25 1.47-2.07.76l-.08-.09c-.37-.42-1.39-.42-1.76 0l-.08.09c-.85.71-2.04.26-2.04-.83v-.53c0-.19-.05-.36-.13-.51V20l3.15-3.9 3.15 3.86V9.46c-.1.17-.15.37-.14.58z" />
    <path fill="#EDDE64" d="M9.08.44c.46.42 1.36.42 1.84 0 .7-.86 2.1-.37 2.1.74v.27c0 .69.58 1.23 1.26 1.17l.46-.03a1.18 1.18 0 01.92 2l-.31.31c-.59.38-.58 1.12 0 1.66l.31.31c.77.78.17 2.1-.92 2l-.46-.03a1.16 1.16 0 00-1.25 1.2v.53c.03 1.01-1.25 1.47-2.07.76l-.08-.09c-.37-.42-1.39-.42-1.76 0l-.08.09c-.85.71-2.04.26-2.04-.83v-.53c0-.64-.6-1.18-1.28-1.13l-.46.04a1.18 1.18 0 01-.92-2l.31-.32c.64-.66.62-1.38 0-1.66l-.3-.3a1.18 1.18 0 01.91-2.01l.46.03C6.4 2.68 7 2.14 7 1.45v-.27c0-1.11 1.4-1.6 2.1-.74z" />
    <path fill="#fff" d="M10.02 14.7a.35.35 0 01-.35-.35V12.6a.35.35 0 11.7 0v1.75c0 .2-.16.35-.35.35z" />
    <path fill="#ECBA16" d="M10.02 2.64l.75 1.88 2.26.37-1.5 1.51.37 2.26-1.88-1.13-1.89 1.13.38-2.26-1.5-1.5 2.25-.38.76-1.88z" />
  </g>
  <defs>
    <clipPath id="clip0">
      <path fill="#fff" d="M0 0h20v20H0z" />
    </clipPath>
  </defs>
  `
};
