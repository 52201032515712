import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import _ from 'lodash';

import { Group } from 'src/app/core/services/groups/group.model';
import { AutoCompleteService } from '../../../core/services/autocomplete.service';
import { getValue } from '../../../registration/mappers';
import { IAutocompleteCtx, IInputCtx } from '../../form';

@Component({
  selector: 'app-create-private-group-dialog',
  templateUrl: './create-private-group-dialog.component.html',
  styleUrls: ['./create-private-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatePrivateGroupDialogComponent {
  privateAccess = { id: 3, title: 'Private Group - only by invitations' };

  ages = [
    { id: 1, title: '3-6 years' },
    { id: 2, title: '7-9 years' },
    { id: 3, title: '10-13 years' },
    { id: 4, title: '14-17 years' },
    { id: 5, title: 'any' }
  ];

  get isAlreadyCreated() {
    return this.data?.groupInfo?.id;
  }

  get name() {
    return this.data?.groupInfo?.name;
  }

  loadedImage: string | ArrayBuffer = null;
  newKeyword = '';

  readonly fields = {
    image: new FormControl(),
    country: new FormControl(),
    city: new FormControl(),
    name: new FormControl(null, Validators.required),
    age_group_id: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    access: new FormControl(this.privateAccess.id, Validators.required),
    zip: new FormControl(),
    keywords: new FormControl([])
  };

  newGroup = new FormGroup(this.fields);

  readonly country = this.auto.getCountry(this.fields.country);
  readonly city = this.auto.getCity(this.fields.city);
  readonly zip: IInputCtx = {
    control: this.fields.zip,
    placeholder: `Zip / Post Code`
  };

  constructor(
    private auto: AutoCompleteService,
    private dialogRef: MatDialogRef<Group>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { groupInfo?: Group }
  ) {
    this.probablyPatchForm(this.data?.groupInfo);
  }

  get keywords() {
    return this.newGroup.value.keywords;
  }

  get currentImage() {
    if (this.newGroup.value.image instanceof File) {
      return null;
    }

    return this.newGroup.value.image;
  }

  onChangeKeyword(newValue) {
    this.newKeyword = newValue;
  }

  onSubmit() {
    let payload = this.newGroup.value;

    payload = {
      ...payload,
      country: getValue(payload.country),
      city: getValue(payload.city)
    };

    this.dialogRef.close({
      type: 'submit',
      id: this.data?.groupInfo.id,
      payload
    });
  }

  onAddKeyword() {
    if (!this.newKeyword) {
      return;
    }

    this.newGroup.patchValue({
      keywords: _.uniqBy([...this.newGroup.value.keywords, this.newKeyword])
    });

    this.newKeyword = '';
  }

  onRemoveKeyword(keyword) {
    this.newGroup.patchValue({
      keywords: this.newGroup.value.keywords.filter((k) => k !== keyword)
    });
  }

  onImageChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      const that = this;
      reader.onload = function () {
        that.newGroup.patchValue({
          image: file
        });

        that.loadedImage = this.result;

        that.cd.markForCheck();
      };
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  private probablyPatchForm(groupInfo?: Group) {
    if (!groupInfo) {
      return;
    }

    this.newGroup.patchValue({
      ...groupInfo,
      age_group_id: groupInfo.agegroup.id,
      country: groupInfo.location?.country.name,
      city: groupInfo.location?.city?.name,
      zip: groupInfo.location?.zip,
      keywords: groupInfo.keywords?.map((keyword) => keyword.name),
      organization: groupInfo.body?.name,
      address: groupInfo.body?.address.name,
      image: groupInfo.icon
    });
  }
}
