import { Component, Output, EventEmitter, Input } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import _ from 'lodash';

import { IChannel, IGroupChat } from '../../../model';
import { GroupChatsSectionsService } from '../../services';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-chat-list-of-chats',
  templateUrl: './list-of-chats.component.html',
  styleUrls: ['./list-of-chats.component.scss']
})
export class ListOfChatsComponent {
  @Input() activeChatId: number;
  @Input() chats: IGroupChat[];
  @Input() channelLinkGenerator: (id: number) => string;

  @Output() addChannel = new EventEmitter<number>();
  @Output() editChat = new EventEmitter<IGroupChat>();

  constructor(
    private readonly authService: AuthService,
    private readonly groupChatsSectionsService: GroupChatsSectionsService
  ) {}

  isOpened$(id: number) {
    return this.groupChatsSectionsService.isOpened$(id);
  }

  isMyGroupChat(chat: IGroupChat) {
    return this.authService.isMyId(chat.ownerId);
  }

  channels(chat: IGroupChat) {
    const channels = chat.channels.filter((_) => !_.isDeleted);

    return _.orderBy(
      channels,
      (channel: IChannel) => {
        return channel.message?.createdAt || channel.createdAt;
      },
      'desc'
    );
  }

  onToggle(id: number) {
    this.groupChatsSectionsService
      .isOpened$(id)
      .pipe(
        take(1),
        tap((_) =>
          _
            ? this.groupChatsSectionsService.close(id)
            : this.groupChatsSectionsService.open(id)
        )
      )
      .subscribe((_) => _);
  }

  getRoute(id: number) {
    return this.channelLinkGenerator(id);
  }

  isChatHasChannelsWithNewMessages(chat: IGroupChat) {
    return !!chat.channels.find((_) => _.isUnread);
  }

  onAddChannel(id: number) {
    this.addChannel.emit(id);
  }

  onEditChat(chat: IGroupChat) {
    this.editChat.emit(chat);
  }

  chatTrackBy(_index: number, chat: { id: number }) {
    return chat.id;
  }

  channelTrackBy(_index: number, channel: { id: number }) {
    return channel.id;
  }
}
