import { TEmoji } from '../model/type';

export const objects: TEmoji[] = [
  ['👓', 'glasses'],
  ['🕶️', 'sunglasses'],
  ['🥽', 'goggles'],
  ['🥼', 'lab coat'],
  ['🦺', 'safety vest'],
  ['👔', 'necktie'],
  ['👕', 't-shirt'],
  ['👖', 'jeans'],
  ['🧣', 'scarf'],
  ['🧤', 'gloves'],
  ['🧥', 'coat'],
  ['🧦', 'socks'],
  ['👗', 'dress'],
  ['👘', 'kimono'],
  ['🥻', 'sari'],
  ['🩱', 'one-piece swimsuit'],
  ['🩲', 'briefs'],
  ['🩳', 'shorts'],
  ['👙', 'bikini'],
  ['👚', 'woman’s clothes'],
  ['👛', 'purse'],
  ['👜', 'handbag'],
  ['👝', 'clutch bag'],
  ['🛍️', 'shopping bags'],
  ['🎒', 'backpack'],
  ['🩴', 'thong sandal'],
  ['👞', 'man’s shoe'],
  ['👟', 'running shoe'],
  ['🥾', 'hiking boot'],
  ['🥿', 'flat shoe'],
  ['👠', 'high-heeled shoe'],
  ['👡', 'woman’s sandal'],
  ['🩰', 'ballet shoes'],
  ['👢', 'woman’s boot'],
  ['👑', 'crown'],
  ['👒', 'woman’s hat'],
  ['🎩', 'top hat'],
  ['🎓', 'graduation cap'],
  ['🧢', 'billed cap'],
  ['⛑️', 'rescue worker’s helmet'],
  ['📿', 'prayer beads'],
  ['💄', 'lipstick'],
  ['💍', 'ring'],
  ['💎', 'gem stone'],
  ['🔇', 'muted speaker'],
  ['🔈', 'speaker low volume'],
  ['🔉', 'speaker medium volume'],
  ['🔊', 'speaker high volume'],
  ['📢', 'loudspeaker'],
  ['📣', 'megaphone'],
  ['📯', 'postal horn'],
  ['🔔', 'bell'],
  ['🔕', 'bell with slash'],
  ['🎼', 'musical score'],
  ['🎵', 'musical note'],
  ['🎶', 'musical notes'],
  ['🎙️', 'studio microphone'],
  ['🎚️', 'level slider'],
  ['🎛️', 'control knobs'],
  ['🎤', 'microphone'],
  ['🎧', 'headphone'],
  ['📻', 'radio'],
  ['🎷', 'saxophone'],
  ['🎸', 'guitar'],
  ['🎹', 'musical keyboard'],
  ['🎺', 'trumpet'],
  ['🎻', 'violin'],
  ['🪕', 'banjo'],
  ['🥁', 'drum'],
  ['📱', 'mobile phone'],
  ['📲', 'mobile phone with arrow'],
  ['☎️', 'telephone'],
  ['📞', 'telephone receiver'],
  ['📟', 'pager'],
  ['📠', 'fax machine'],
  ['🔋', 'battery'],
  ['🔌', 'electric plug'],
  ['💻', 'laptop'],
  ['🖥️', 'desktop computer'],
  ['🖨️', 'printer'],
  ['⌨️', 'keyboard'],
  ['🖱️', 'computer mouse'],
  ['🖲️', 'trackball'],
  ['💽', 'computer disk'],
  ['💾', 'floppy disk'],
  ['💿', 'optical disk'],
  ['📀', 'dvd'],
  ['🧮', 'abacus'],
  ['🎥', 'movie camera'],
  ['🎞️', 'film frames'],
  ['📽️', 'film projector'],
  ['🎬', 'clapper board'],
  ['📺', 'television'],
  ['📷', 'camera'],
  ['📸', 'camera with flash'],
  ['📹', 'video camera'],
  ['📼', 'videocassette'],
  ['🔍', 'magnifying glass tilted left'],
  ['🔎', 'magnifying glass tilted right'],
  ['🕯️', 'candle'],
  ['💡', 'light bulb'],
  ['🔦', 'flashlight'],
  ['🏮', 'red paper lantern'],
  ['🪔', 'diya lamp'],
  ['📔', 'notebook with decorative cover'],
  ['📕', 'closed book'],
  ['📖', 'open book'],
  ['📗', 'green book'],
  ['📘', 'blue book'],
  ['📙', 'orange book'],
  ['📚', 'books'],
  ['📓', 'notebook'],
  ['📒', 'ledger'],
  ['📃', 'page with curl'],
  ['📜', 'scroll'],
  ['📄', 'page facing up'],
  ['📰', 'newspaper'],
  ['🗞️', 'rolled-up newspaper'],
  ['📑', 'bookmark tabs'],
  ['🔖', 'bookmark'],
  ['🏷️', 'label'],
  ['💰', 'money bag'],
  ['💴', 'yen banknote'],
  ['💵', 'dollar banknote'],
  ['💶', 'euro banknote'],
  ['💷', 'pound banknote'],
  ['💸', 'money with wings'],
  ['💳', 'credit card'],
  ['🧾', 'receipt'],
  ['💹', 'chart increasing with yen'],
  ['✉️', 'envelope'],
  ['📧', 'e-mail'],
  ['📨', 'incoming envelope'],
  ['📩', 'envelope with arrow'],
  ['📤', 'outbox tray'],
  ['📥', 'inbox tray'],
  ['📦', 'package'],
  ['📫', 'closed mailbox with raised flag'],
  ['📪', 'closed mailbox with lowered flag'],
  ['📬', 'open mailbox with raised flag'],
  ['📭', 'open mailbox with lowered flag'],
  ['📮', 'postbox'],
  ['🗳️', 'ballot box with ballot'],
  ['✏️', 'pencil'],
  ['✒️', 'black nib'],
  ['🖋️', 'fountain pen'],
  ['🖊️', 'pen'],
  ['🖌️', 'paintbrush'],
  ['🖍️', 'crayon'],
  ['📝', 'memo'],
  ['💼', 'briefcase'],
  ['📁', 'file folder'],
  ['📂', 'open file folder'],
  ['🗂️', 'card index dividers'],
  ['📅', 'calendar'],
  ['📆', 'tear-off calendar'],
  ['🗒️', 'spiral notepad'],
  ['🗓️', 'spiral calendar'],
  ['📇', 'card index'],
  ['📈', 'chart increasing'],
  ['📉', 'chart decreasing'],
  ['📊', 'bar chart'],
  ['📋', 'clipboard'],
  ['📌', 'pushpin'],
  ['📍', 'round pushpin'],
  ['📎', 'paperclip'],
  ['🖇️', 'linked paperclips'],
  ['📏', 'straight ruler'],
  ['📐', 'triangular ruler'],
  ['✂️', 'scissors'],
  ['🗃️', 'card file box'],
  ['🗄️', 'file cabinet'],
  ['🗑️', 'wastebasket'],
  ['🔒', 'locked'],
  ['🔓', 'unlocked'],
  ['🔏', 'locked with pen'],
  ['🔐', 'locked with key'],
  ['🔑', 'key'],
  ['🗝️', 'old key'],
  ['🔨', 'hammer'],
  ['🪓', 'axe'],
  ['⛏️', 'pick'],
  ['⚒️', 'hammer and pick'],
  ['🛠️', 'hammer and wrench'],
  ['🗡️', 'dagger'],
  ['⚔️', 'crossed swords'],
  ['🔫', 'pistol'],
  ['🏹', 'bow and arrow'],
  ['🛡️', 'shield'],
  ['🔧', 'wrench'],
  ['🔩', 'nut and bolt'],
  ['⚙️', 'gear'],
  ['🗜️', 'clamp'],
  ['⚖️', 'balance scale'],
  ['🦯', 'white cane'],
  ['🔗', 'link'],
  ['⛓️', 'chains'],
  ['🧰', 'toolbox'],
  ['🧲', 'magnet'],
  ['⚗️', 'alembic'],
  ['🧪', 'test tube'],
  ['🧫', 'petri dish'],
  ['🧬', 'dna'],
  ['🔬', 'microscope'],
  ['🔭', 'telescope'],
  ['📡', 'satellite antenna'],
  ['💉', 'syringe'],
  ['🩸', 'drop of blood'],
  ['💊', 'pill'],
  ['🩹', 'adhesive bandage'],
  ['🩺', 'stethoscope'],
  ['🚪', 'door'],
  ['🛏️', 'bed'],
  ['🛋️', 'couch and lamp'],
  ['🪑', 'chair'],
  ['🚽', 'toilet'],
  ['🚿', 'shower'],
  ['🛁', 'bathtub'],
  ['🪒', 'razor'],
  ['🧴', 'lotion bottle'],
  ['🧷', 'safety pin'],
  ['🧹', 'broom'],
  ['🧺', 'basket'],
  ['🧻', 'roll of paper'],
  ['🧼', 'soap'],
  ['🧽', 'sponge'],
  ['🧯', 'fire extinguisher'],
  ['🛒', 'shopping cart'],
  ['🚬', 'cigarette'],
  ['⚰️', 'coffin'],
  ['⚱️', 'funeral urn'],
  ['🗿', 'moai']
];
