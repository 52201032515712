import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RegistrationStoreEffects } from './registration.effects';
import { RegistrationService } from './registration.service';
import { RegistrationFacade } from './registration.facade';
import { registrationFeatureKey, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(registrationFeatureKey, reducers),
    EffectsModule.forFeature([RegistrationStoreEffects])
  ],
  providers: [RegistrationStoreEffects, RegistrationService, RegistrationFacade]
})
export class RegistrationStoreModule {}
