import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ViewableItem {
  id: number;
  is_viewed: boolean;
}

@Directive({
  selector: '[appIsViewable]'
})
export class IsViewableDirective implements OnInit {
  @Input() viewableOn: boolean;
  @Input() viewableItem: ViewableItem;

  @Output() viewed = new EventEmitter<number>();

  private _topWasViewed = false;
  private _bottomWasViewed = false;
  private _timeout: ReturnType<typeof setTimeout>;
  private readonly _viewed = new Subject();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly scrollDispatcher: ScrollDispatcher
  ) {}

  ngOnInit() {
    if (!this.viewableOn) {
      return;
    }

    if (this.viewableItem.is_viewed) {
      return;
    }

    this.componentWasMoved();

    this.scrollDispatcher
      .scrolled(100)
      .pipe(takeUntil(this._viewed))
      .subscribe(this.componentWasMoved);
  }

  private componentWasMoved = () => {
    clearTimeout(this._timeout);

    this._timeout = setTimeout(this.componentWasProbablyViewed, 1000);
  };

  private componentWasProbablyViewed = () => {
    const bounding = this.elementRef.nativeElement.getBoundingClientRect();

    // if item is big enough to be rendered within a screen entirely
    // monitor whether top and bottom of is were rendered, and if both - the item was viewed completely
    this._topWasViewed =
      this._topWasViewed || (bounding.top >= 0 && bounding.left >= 0);
    this._bottomWasViewed =
      this._bottomWasViewed ||
      bounding.top + bounding.height <=
        (window.innerHeight || document.documentElement.clientHeight);

    if (this._topWasViewed && this._bottomWasViewed) {
      this.componentWasViewed();
    }
  };

  private componentWasViewed = () => {
    clearTimeout(this._timeout);

    this._viewed.next();
    this._viewed.complete();

    this.viewed.emit(this.viewableItem.id);
  };
}
