<div class="dialog-content-overlay">
  <button type="button" class="btn-close" (click)="onClose(); false"></button>
  <div class="modal-title mt-4"> Account connection error </div>
  <div class="modal-description modal-error">
    Your account cannot be connected because this email has not been registered with us.
  </div>
  <div class="form mx-auto mt-3 mb-4">
    <div class="form-el">
      <button class="button" (click)="onClose(); false">Done</button>
    </div>
  </div>
</div>
