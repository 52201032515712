import { IProfileEntity } from '../../core/services/routing.service';
import { convertIdNumberToString } from './idConverter';

// TODO: refactor
// what the fuck ?
export function getUserIdFromLink(
  event: Event,
  description: string
): IProfileEntity | null {
  const target = event.target as HTMLLinkElement;

  const isLinkPreview =
    target.parentElement?.classList.contains('link-preview');
  const isParseLink = target.classList.contains('parse-link');
  const isLinkified = target.classList.contains('linkified');
  const isNotLink = target.tagName !== 'A';

  if (isLinkified || isParseLink || isNotLink || isLinkPreview) return null; // #Return#

  event.preventDefault();

  let userId: string;
  let userName = target.textContent
    .substring(1)
    .replace(/ /g, '.')
    .toLowerCase();
  const tagSplit = description.split('@');
  const dataId = target.getAttribute(`data-id`);

  if (dataId) {
    userId = dataId;
  } else if (target.href) {
    const url = new URL(target.href);
    const attr = url.searchParams.get('user_id');

    userId = convertIdNumberToString(attr);
  } else if (tagSplit.length) {
    userId = tagSplit[0].match(/\d+/)[0];
  }

  // console.log({
  //   userName,
  //   tagSplit,
  //   dataId,
  // })

  if (userId) {
    return {
      id: userId,
      type: `student`,
      name: userName
    };
  }
}
