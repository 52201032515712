import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent<T> implements OnInit {
  @Input() readonly ctx: ISelectCtx<T>;

  constructor() {}

  ngOnInit(): void {}

  public getTitle(value: T) {
    if (value === undefined || value === null) return ``;
    return this.ctx.getTitle(value);
  }

  public compareOptions(a: T, b: T) {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}

export interface ISelectCtx<T> {
  placeholder: string;
  control: AbstractControl;
  options: Observable<T[]>;
  getTitle: (value: T) => string;
}
