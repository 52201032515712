import { createAction, props } from '@ngrx/store';

import { InfoMessage } from './info-message.model';

export const loadInfoMessages = createAction(
  '[InfoMessage/API] Load InfoMessages',
  props<{ infoMessages: InfoMessage[] }>()
);

export const addInfoMessage = createAction(
  '[InfoMessage/API] Add InfoMessage',
  props<{ infoMessage: InfoMessage }>()
);

export const deleteInfoMessage = createAction(
  '[InfoMessage/API] Delete InfoMessage',
  props<{ id: number }>()
);

export const clearInfoMessages = createAction(
  '[InfoMessage/API] Clear InfoMessages'
);
