import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { RoutingService } from 'src/app/core/services/routing.service';
import { Article } from 'src/app/core/services/eduniverse/eduniverse.model';

@Component({
  selector: 'app-suggested-articles',
  templateUrl: './suggested-articles.component.html',
  styleUrls: ['./suggested-articles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestedArticlesComponent {
  @Input() articles: { items: Article[] };

  constructor(public routing: RoutingService) {}
}
