import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';
import moment from 'moment';

import { IOrganization } from 'src/app/shared/models/user/organization';
import * as ContactsActions from 'src/app/core/services/contacts/contacts.actions';
import * as GroupsActions from 'src/app/core/services/groups/groups.actions';
import * as OrganizationsActions from 'src/app/core/services/organizations/organizations.actions';
import * as OrganizationActions from './organization.actions';
import { Member, Chapter, Job, Review } from './organization.model';

export const organizationFeatureKey = 'organization';

const AMOUNT_OF_USERS_ON_ONE_PAGE = 20;

export interface State {
  organization: IOrganization;

  chapters: { [id: number]: Chapter };
  chaptersCount: number;
  chaptersAmountOfPages: number;

  members: { [id: number]: Member };
  membersCount: number;
  membersAmountOfPages: number;

  jobs: { [id: number]: Job };
  jobsCount: number;
  jobsAmountOfPages: number;
  jobsNextPageToLoad: number;
  loadingJobs: boolean;
  isCreatingNewJob: boolean;

  reviews: Review[];
}

export const initialState: State = {
  organization: null,

  chapters: null,
  chaptersCount: 0,
  chaptersAmountOfPages: 0,

  members: null,
  membersCount: 0,
  membersAmountOfPages: 0,

  jobs: null,
  jobsCount: 0,
  jobsAmountOfPages: 0,
  jobsNextPageToLoad: 0,
  loadingJobs: false,
  isCreatingNewJob: false,

  reviews: null
};

export const reducer = createReducer(
  initialState,
  on(OrganizationActions.clearOrganization, (state, action): State => {
    return initialState;
  }),
  on(OrganizationActions.loadOrganization, (state, action): State => {
    return {
      ...state,
      organization: null
    };
  }),
  on(OrganizationActions.loadOrganizationSuccess, (state, action): State => {
    return {
      ...state,
      organization: action.organization
    };
  }),
  on(OrganizationActions.getMembers, (state, action): State => {
    return {
      ...state,
      members: null,
      membersCount: 0,
      membersAmountOfPages: 0
    };
  }),
  on(OrganizationActions.getOrgMembers, (state, action): State => {
    return {
      ...state,
      members: null,
      membersCount: 0,
      membersAmountOfPages: 0
    };
  }),
  on(OrganizationActions.getMembersSuccess, (state, action): State => {
    return {
      ...state,
      members: action.members,
      membersCount: action.count,
      membersAmountOfPages: Math.ceil(
        action.count / AMOUNT_OF_USERS_ON_ONE_PAGE
      )
    };
  }),
  on(OrganizationActions.getOrgMembersSuccess, (state, action): State => {
    return {
      ...state,
      members: action.members,
      membersCount: action.count,
      membersAmountOfPages: Math.ceil(
        action.count / AMOUNT_OF_USERS_ON_ONE_PAGE
      )
    };
  }),
  on(OrganizationActions.loadOrgChapters, (state, action): State => {
    return {
      ...state,
      chapters: null,
      chaptersCount: 0,
      chaptersAmountOfPages: 0
    };
  }),
  on(OrganizationActions.loadOrgChaptersSuccess, (state, action): State => {
    return {
      ...state,
      chapters: action.chapters,
      chaptersCount: action.count,
      chaptersAmountOfPages: Math.ceil(
        action.count / AMOUNT_OF_USERS_ON_ONE_PAGE
      )
    };
  }),
  on(GroupsActions.createGroupSuccess, (state, action): State => {
    if (!state.organization) {
      return state;
    }

    const currChapters = state.chapters ? { ...state.chapters } : {};

    return {
      ...state,
      organization: {
        ...state.organization,
        org: {
          ...state.organization.org,
          counts: {
            ...state.organization.org.counts,
            clubs: state.organization.org.counts.clubs + 1
          }
        }
      },
      chapters: {
        ...currChapters,
        [action.group.id]: action.group
      }
    };
  }),
  on(OrganizationActions.loadOrgJobs, (state, action): State => {
    return {
      ...state,
      loadingJobs: true
    };
  }),
  on(OrganizationActions.loadOrgJobsSuccess, (state, action): State => {
    const currentJobs = state.jobs ? state.jobs : [];
    const nextPageToLoad =
      Object.keys(action.jobs).length == AMOUNT_OF_USERS_ON_ONE_PAGE
        ? action.page + 1
        : action.page;

    return {
      ...state,
      jobs: {
        ...currentJobs,
        ...action.jobs
      },
      jobsCount: action.count,
      jobsNextPageToLoad: nextPageToLoad,
      jobsAmountOfPages: Math.ceil(action.count / AMOUNT_OF_USERS_ON_ONE_PAGE),
      loadingJobs: false
    };
  }),
  on(OrganizationActions.createJob, (state, action): State => {
    return {
      ...state,
      isCreatingNewJob: true
    };
  }),
  on(OrganizationActions.createJobSuccess, (state, action): State => {
    const currentJobs = state.jobs ? { ...state.jobs } : {};

    return {
      ...state,
      jobs: {
        ...action.job,
        ...currentJobs
      },
      jobsCount: state.jobsCount + 1,
      jobsAmountOfPages: Math.ceil(
        state.jobsCount + 1 / AMOUNT_OF_USERS_ON_ONE_PAGE
      ),
      isCreatingNewJob: false
    };
  }),
  on(OrganizationActions.deleteJobSuccess, (state, action): State => {
    const currentJobs = state.jobs ? { ...state.jobs } : {};
    delete currentJobs[action.job.id];

    return {
      ...state,
      jobs: {
        ...currentJobs
      },
      jobsCount: state.jobsCount - 1,
      jobsAmountOfPages: Math.ceil(
        state.jobsCount - 1 / AMOUNT_OF_USERS_ON_ONE_PAGE
      ),
      isCreatingNewJob: false
    };
  }),
  on(OrganizationsActions.orgSubscribeToSuccess, (state, action): State => {
    if (action.id !== state.organization?.id) {
      return state;
    }

    return {
      ...state,
      organization: {
        ...state.organization,
        friend_request: true
      }
    };
  }),
  on(OrganizationsActions.orgUnSubscribeFromSuccess, (state, action): State => {
    if (action.id !== state.organization?.id) {
      return state;
    }

    return {
      ...state,
      organization: {
        ...state.organization,
        is_friend: false,
        friend_request: false
      }
    };
  }),
  on(OrganizationActions.loadOrgReviews, (state, action): State => {
    return {
      ...state,
      reviews: null
    };
  }),
  on(OrganizationActions.loadOrgReviewsSuccess, (state, action): State => {
    return {
      ...state,
      reviews: action.reviews
    };
  }),
  on(OrganizationActions.addReviewSuccess, (state, action): State => {
    return {
      ...state,
      reviews: [action.review, ...state.reviews]
    };
  }),
  on(ContactsActions.addContactSuccess, (state, action): State => {
    if (!state.members || !state.members[action.id]) {
      return state;
    }

    return {
      ...state,
      members: {
        ...state.members,
        [action.id]: {
          ...state.members[action.id],
          friend_request: true
        }
      }
    };
  }),
  on(ContactsActions.removeContactSuccess, (state, action): State => {
    if (!state.members || !state.members[action.id]) {
      return state;
    }

    return {
      ...state,
      members: {
        ...state.members,
        [action.id]: {
          ...state.members[action.id],
          friend_request: false,
          is_friend: false
        }
      }
    };
  }),
  on(GroupsActions.subscribeToSuccess, (state, action): State => {
    if (!state.chapters || !state.chapters[action.id]) {
      return state;
    }

    return {
      ...state,
      chapters: {
        ...state.chapters,
        [action.id]: {
          ...state.chapters[action.id],
          is_requested: true
        }
      }
    };
  }),
  on(GroupsActions.unsubscribeFrom, (state, action): State => {
    if (!state.chapters || !state.chapters[action.id]) {
      return state;
    }

    return {
      ...state,
      chapters: {
        ...state.chapters,
        [action.id]: {
          ...state.chapters[action.id],
          is_requested: false,
          is_subscriber: false
        }
      }
    };
  })
);

export const selectState = createFeatureSelector<State>(organizationFeatureKey);

export const selectMembers = () =>
  createSelector(selectState, (state: State) => {
    if (!state.members) {
      return { items: null, count: 0, amountOfPages: 0 };
    }

    return {
      items: Object.values(state.members),
      count: state.membersCount,
      amountOfPages: state.membersAmountOfPages
    };
  });

export const selectOrganization = () =>
  createSelector(selectState, (state: State) => {
    return state.organization;
  });

export const selectChapters = () =>
  createSelector(selectState, (state: State) => {
    if (!state.chapters) {
      return { items: null, count: 0, amountOfPages: 0 };
    }

    return {
      items: Object.values(state.chapters),
      count: state.chaptersCount,
      amountOfPages: state.chaptersAmountOfPages
    };
  });

export const selectJobs = () =>
  createSelector(selectState, (state: State) => {
    if (!state.jobs) {
      return {
        items: null,
        count: 0,
        amountOfPages: 0,
        isCreatingNewJob: false,
        nextPageToLoad: 0,
        loading: state.loadingJobs
      };
    }

    return {
      items: _.sortBy(
        Object.values(state.jobs),
        (job) => -moment(job.created_at.date)
      ),
      count: state.jobsCount,
      amountOfPages: state.jobsAmountOfPages,
      isCreatingNewJob: state.isCreatingNewJob,
      nextPageToLoad: state.jobsNextPageToLoad,
      loading: state.loadingJobs
    };
  });

export const selectReviews = () =>
  createSelector(selectState, (state: State) => {
    if (!state.reviews) {
      return null;
    }

    return state.reviews;
  });
