import { ActionReducerMap } from '@ngrx/store';

import {
  State as CurrentCompetitionsReducerState,
  Reducer as CurrentCompetitionsReducer
} from './current-competitions.reducer';
import {
  State as UpcomingCompetitionsReducerState,
  Reducer as UpcomingCompetitionsReducer
} from './upcoming-competitions.reducer';
import {
  State as PreviousCompetitionsReducerState,
  Reducer as PreviousCompetitionsReducer
} from './previous-competitions.reducer';
import {
  State as MyCompetitionsReducerState,
  Reducer as MyCompetitionsReducer
} from './my-competitions.reducer';
import {
  State as CompetitionsShortListState,
  Reducer as CompetitionsShortListReducer
} from './competitions-short-list.reducer';

import {
  State as CompetitionReducerState,
  Reducer as CompetitionReducer
} from './competition.reducer';

export interface State {
  competition: CompetitionReducerState;
  currentCompetitions: CurrentCompetitionsReducerState;
  upcomingCompetitions: UpcomingCompetitionsReducerState;
  previousCompetitions: PreviousCompetitionsReducerState;
  myCompetitions: MyCompetitionsReducerState;
  competitionsShortList: CompetitionsShortListState;
}

export const competitionsFeatureKey = 'competitions';
export const reducers: ActionReducerMap<State> = {
  competition: CompetitionReducer,
  currentCompetitions: CurrentCompetitionsReducer,
  upcomingCompetitions: UpcomingCompetitionsReducer,
  previousCompetitions: PreviousCompetitionsReducer,
  myCompetitions: MyCompetitionsReducer,
  competitionsShortList: CompetitionsShortListReducer
};

export {
  CompetitionReducer,
  CurrentCompetitionsReducer,
  UpcomingCompetitionsReducer,
  PreviousCompetitionsReducer,
  MyCompetitionsReducer,
  CompetitionsShortListReducer
};
