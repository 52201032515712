<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title>Add participants to {{ chatTitle$ | async }}, #{{ channelTitle$ | async }}</h1>
  <div mat-dialog-content>
    <div class="form-wrapper p-0">
      <form (ngSubmit)="onSave()">
        <div class="members-wrapper mb-3">
          <div class="members-header d-flex align-items-center">
            <div class="icon">
              <svg viewBox="0 0 20 20">
                <use href="#group" />
              </svg>
            </div>
            Group Participants
          </div>
          <app-loader-three-dots *ngIf="isLoading$ | async; else members"></app-loader-three-dots>
          <ng-template #members>
            <div class="members-list">
              <div class="members-item d-flex align-items-center" *ngFor="let member of chatMembers$ | async">
                <div class="author d-flex align-items-center">
                  <div class="avatar rounded-circle">
                    <app-avatar [src]="member.icon" [placeholder]="member | initials" class="img-fluid"> </app-avatar>
                  </div>
                  <div>
                    <div class="name d-flex align-items-center"> {{ member.name }} </div>
                    <div class="status"> {{ member.type_name }} </div>
                  </div>
                </div>
                <div class="actions">
                  <div
                    class="action action-del"
                    *ngIf="isSelectedContact(member.id); else add"
                    (click)="onUnSelectContact(member.id); false"
                  >
                    Delete
                  </div>
                  <ng-template #add>
                    <div class="action action-add" (click)="onSelectContact(member.id); false"> Add + </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSave(); false">Save</button>
  </div>
</div>
