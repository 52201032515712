import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

import { ConvertNameService } from 'src/app/shared/services/convert-name.service';
import { AppBaseUrlPipe } from 'src/app/shared/modules/baseurl/pipes';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';

// TODO: add tests

@Pipe({
  name: 'dogToLink'
})
export class DogToLinkPipe implements PipeTransform {
  constructor(
    private readonly _router: Router,
    private readonly _convertIdService: ConvertIdService,
    private readonly _convertNameService: ConvertNameService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe
  ) {}

  transform(value: string = '', ...args: any[]): string {
    const tags = args[0];

    if (!tags || !tags.length) {
      return value;
    }

    const text = tags.reduce(this.applyTagToText.bind(this), value);

    return text;
  }

  private applyTagToText(text, tag) {
    const url = this.generateURL(tag);
    if (!url) {
      return text;
    }

    const tagNode = document.createElement('a');
    tagNode.setAttribute('contenteditable', 'false');
    tagNode.setAttribute('target', '_blank');
    // TODO: how to name this data-tag so no one use it ?
    // should set data-tag in order to have a link to the user while editing
    tagNode.setAttribute('data-tag', tag.user_id + '');
    tagNode.appendChild(document.createTextNode(tag.text));
    tagNode.href = url.toString();

    return text.split(tag.string).join(tagNode.outerHTML);
  }

  private generateURL(tag) {
    const name = tag.text.slice(1);
    const id = tag.user_id + ''.slice(2);

    const url = this._router.serializeUrl(
      this._router.createUrlTree([
        this._convertIdService.convertIdNumberToString(id),
        this._convertNameService.convertName(name)
      ])
    );

    return this._appBaseUrlPipe.transform(url);
  }
}
