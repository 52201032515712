import { Pipe, PipeTransform } from '@angular/core';

import { SanitizeAttributesPipe } from '../sanitize-attributes/sanitize-attributes.pipe';
import { LinksPipe } from '../links/links.pipe';
import { Nl2brPipe } from '../nl2br/nl2br.pipe';
import { DogToLinkPipe } from '../dog-to-link/dog-to-link.pipe';

interface Args {
  tags?: Array<object>;
}

@Pipe({
  name: 'safeUserInput'
})
export class SafeUserInputPipe implements PipeTransform {
  sanitizeChain: PipeTransform[] = [];
  customLogicChain: PipeTransform[] = [];

  constructor(
    private readonly _sanitizeAttributesPipe: SanitizeAttributesPipe,
    private readonly _linksPipe: LinksPipe,
    private readonly _nl2brPipe: Nl2brPipe,
    private readonly _dogToLinkPipe: DogToLinkPipe
  ) {
    this.sanitizeChain = [this._sanitizeAttributesPipe, this._nl2brPipe];

    // use two different arrays of chains in order to
    // customize them during runtime depending on arguments
    this.customLogicChain = [this._linksPipe];
  }

  transform(value: string, ...args0: Array<Args>): string {
    const args: Args = args0 && args0[0];

    const chain = [
      ...this.sanitizeChain,
      // NOTE: hotfix
      // In order to correctly process tag logic (@=) we need to process it before linksPipe
      // It may cause some problems, if @={id} would be the part of the link, we will replace it

      // TODO: this logic should be outside of this module
      // but in order to address the problem listed above, and do it in one place, we decided to move it inside.
      // Remove it from here after tag identifier fix
      ...(args?.tags ? [this._dogToLinkPipe] : []),
      ...this.customLogicChain
    ];

    return chain.reduce((acc, curr) => {
      const args1 = this.argsForFunction(curr, args);

      return curr.transform(acc, ...args1);
    }, value);
  }

  private argsForFunction(pipeInstance: PipeTransform, args: Args) {
    switch (true) {
      case pipeInstance instanceof DogToLinkPipe:
        return [args.tags];
      default:
        return [];
    }
  }
}
