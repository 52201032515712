<div class="icon">
  <a [group]="review.group">
    <app-avatar [src]="review.group.icon" [placeholder]="review.group | initials" class="img-fluid"> </app-avatar>
  </a>
</div>
<div class="ceil-content w-100">
  <div class="ceil-text">
    <div class="d-flex align-items-center justify-content-between">
      <div class="ceil-title"> {{ review.group.name }} </div>
      <div class="ceil-date"> {{ review.created_at.date | date }} </div>
    </div>

    <div class="ceil-sub-profile d-flex align-items-center">
      <div class="ceil-sub-profile-avatar">
        <a [profile]="review.user">
          <app-avatar [src]="review.user.icon" [placeholder]="review.user | initials" class="img-fluid"></app-avatar>
        </a>
      </div>
      <div class="ceil-sub-profile-name">
        <a [profile]="review.user">{{ review.user.name }}</a>
      </div>
    </div>

    <div class="ceil-description"> {{ review.description | textTruncate: 200 }} </div>
    <a
      [href]="photo.url | baseUrl"
      target="_blank"
      class="ceil-file d-flex"
      *ngFor="let photo of review.fotos.slice(0, 2)"
    >
      <div class="ceil-file-icon">
        <svg viewBox="0 0 11 13">
          <use href="#file" />
        </svg>
      </div>
      {{ photo | mimeToExtension }}
    </a>
    <a [href]="doc.url | baseUrl" target="_blank" class="ceil-file d-flex" *ngFor="let doc of review.docs.slice(0, 2)">
      <div class="ceil-file-icon">
        <svg viewBox="0 0 11 13">
          <use href="#file" />
        </svg>
      </div>
      {{ doc | mimeToExtension }}
    </a>
    <div class="ceil-actions">
      <a href="#" (click)="onReadMore(); false">Read</a>
    </div>
  </div>
</div>
