import { IIcon } from '../../model/icon';

export const options: IIcon = {
  id: `OPTIONS`,
  svg: `
  <g clip-path="url(#clip13)">
  <path fill="#3B97D3"
    d="M7.5 2.3L8.2.45c.1-.26.35-.44.63-.44h2.36c.28 0 .53.18.63.44l.69 1.83 2.8 1.62 1.93-.32c.28-.04.56.09.7.33l1.18 2.04c.14.25.11.55-.07.77L17.8 8.24v3.24l1.24 1.5c.18.23.2.53.07.78l-1.18 2.04a.68.68 0 01-.7.33l-1.93-.32-2.8 1.62-.69 1.83a.68.68 0 01-.63.44H8.82a.68.68 0 01-.63-.44l-.69-1.83-2.8-1.62-1.93.32a.68.68 0 01-.7-.33L.89 13.76a.68.68 0 01.07-.77l1.24-1.51V8.24L.96 6.74a.68.68 0 01-.07-.78l1.18-2.04a.68.68 0 01.7-.33l1.93.32L7.5 2.3z" />
  <path fill="#2980BA" d="M10 15.62A5.76 5.76 0 1010 4.1a5.76 5.76 0 000 11.52z" />
  <path fill="#fff"
    d="M6.61 10.87a1.02 1.02 0 100-2.03 1.02 1.02 0 000 2.03zM10 10.87a1.02 1.02 0 100-2.03 1.02 1.02 0 000 2.03zM13.39 10.87a1.02 1.02 0 100-2.03 1.02 1.02 0 000 2.03z" />
  <path fill="#285680"
    d="M10 15.95a6.1 6.1 0 110-12.19 6.1 6.1 0 010 12.2zm0-11.5a5.42 5.42 0 100 10.83 5.42 5.42 0 000-10.84z" />
</g>
<defs>
  <clipPath id="clip13">
    <path fill="#fff" d="M0 .02h20v20H0z" />
  </clipPath>
</defs>`
};
