import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { BaseSelectComponent } from '../base-select/base-select.component';

@Component({
  selector: 'app-reference-autocomplete',
  templateUrl: './reference-autocomplete.component.html',
  styleUrls: ['./reference-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReferenceAutocompleteComponent),
      multi: true
    }
  ]
})
export class ReferenceAutocompleteComponent
  extends BaseSelectComponent
  implements OnInit
{
  list$: Observable<any>;
  selectPlaceholder = 'Select subject(s)';
  constructor(private service: BaseService) {
    super();
  }

  ngOnInit(): void {
    this.list$ = this.service.getFields('interest');
  }
}
