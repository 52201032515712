<div class="profile-menu">
  <div class="user d-flex align-items-center">
    <a [profile]="user" [amplitude]="'/header/profile-menu/profile'">
      <app-avatar [src]="user.icon" [placeholder]="user | initials" class="avatar"> </app-avatar>
    </a>

    <div class="text-center">
      <div class="d-flex align-items-center">
        <a [profile]="user" class="name" [amplitude]="'/header/profile-menu/profile'">{{ user | profileNameTitle }}</a>
      </div>

      <div class="position"> {{ user | profileTypeTitle }} </div>
    </div>

    <a [profile]="user" [amplitude]="'/header/profile-menu/profile'" class="btn view-profile-btn">View profile</a>
  </div>

  <!-- <div class="profile-menu-item">
    <a href="#">Post a job</app-link>
  </div> -->
  <!-- <div class="profile-menu-item">
    <app-link [route]="['tutoring', 'become']">Become a Tutor</app-link>
    <span>Admissions Consultant, Coach</span>
  </div>
  <div class="profile-menu-item">
    <app-link [route]="['tutoring', 'find']">Find a Tutor</app-link>
    <span>Admissions Consultant, Coach</span>
  </div> -->
  <div class="profile-menu-item">
    <app-link [route]="['network']" [amplitude]="'/header/profile-menu/network'">
      <span>
        Network
        <span
          [matBadge]="amountOfNetworkRequests$ | async"
          *ngIf="amountOfNetworkRequests$ | async"
          matBadgeSize="small"
        ></span>
      </span>
    </app-link>
  </div>
  <div class="profile-menu-item">
    <app-link [route]="['settings']" [amplitude]="'/header/profile-menu/settings'"
      ><span>Settings and Privacy</span></app-link
    >
  </div>
  <!--  <div class="profile-menu-item">-->
  <!--    <a href="https://old.roundpier.com/">Switch to old roundpier</a>-->
  <!--  </div>-->
  <div class="profile-menu-item" (click)="logout()">
    <!-- NOTE: First, send event to  amplitude and then logout -->
    <span [amplitude]="'/header/profile-menu/logout'">Logout</span>
  </div>
</div>
