import { IIcon } from '../../model/icon';

export const subEditProfile: IIcon = {
  id: `SUB_EDIT_PROFILE`,
  svg: `
  <g clip-path="url(#clip19)">
      <path fill="#F9C795" d="M13.14 1.15l.7 1.95-1.3.26-1.29-.26.7-1.95h1.19z" />
      <path fill="#464F5D" d="M13.14 1.15c-.38.2-.82.2-1.19 0l.35-.98a.26.26 0 01.49 0l.35.98z" />
      <path fill="#F3D55B" d="M12.54 3.1h-1.29v8.54h2.59V3.1h-1.3z" />
      <path fill="#FF5364"
        d="M8.15 0h1.03c.43 0 .78.35.78.78v12.41c0 .43-.35.78-.78.78H1.94a.78.78 0 01-.78-.78V.78c0-.43.35-.78.78-.78h6.2z" />
      <path fill="#FB7B76"
        d="M9.96.78v.25s-8.48 0-8.79 12a.7.7 0 010-.1V.78c0-.43.34-.78.77-.78h1.03l.74.79c.15.16.36.24.57.24h2.56c.22 0 .42-.08.57-.24L8.15 0h1.03c.43 0 .78.35.78.78z" />
      <path fill="#464F5D"
        d="M9.96 12.93v1.3c0 .42-.35.77-.78.77H1.94a.78.78 0 01-.78-.78v-1.29c0 .43.35.78.78.78h7.24c.43 0 .78-.35.78-.78zM8.15 0L7.4.79a.77.77 0 01-.57.24H4.28A.77.77 0 013.71.8L2.97 0h5.18z" />
      <path fill="#F0C419"
        d="M7.89 8.52a3.1 3.1 0 01-4.66 0c.01-1.02.68-1.91 1.65-2.21.43.2.93.2 1.36 0 .97.3 1.64 1.2 1.65 2.2z" />
      <path fill="#285680"
        d="M4.88 6.3c-.97.3-1.64 1.2-1.65 2.22a3.1 3.1 0 012.33-5.16 1.55 1.55 0 00-.68 2.95zM8.66 6.47c0 .75-.27 1.48-.77 2.05C7.88 7.5 7.2 6.6 6.24 6.3a1.55 1.55 0 00-.68-2.95 3.1 3.1 0 013.1 3.1z" />
      <path fill="#FDD7AD" d="M7.11 4.91c0 .6-.34 1.14-.87 1.4a1.55 1.55 0 11.87-1.4z" />
      <path fill="#fff"
        d="M4.53 10.86H2.97a.52.52 0 00-.51.52v.52c0 .28.23.51.51.51h1.56c.28 0 .51-.23.51-.51v-.52a.52.52 0 00-.51-.52zM8.15 10.86H6.59a.52.52 0 00-.51.52v.52c0 .28.23.51.51.51h1.56c.28 0 .51-.23.51-.51v-.52a.52.52 0 00-.51-.52z" />
      <path fill="#BDC3C7" d="M11.25 11.38h2.59v1.3h-2.59v-1.3z" />
      <path fill="#F0C419" d="M12.54 3.1h1.3v8.28h-1.3V3.1z" />
      <path fill="#FF5364" d="M13.84 12.41v1.56c0 .57-.47 1.03-1.04 1.03h-.52c-.57 0-1.03-.46-1.03-1.03V12.4h2.59z" />
      <path fill="#FB7B76" d="M12.28 14.22a.26.26 0 100-.51.26.26 0 000 .51z" />
    </g>
    <defs>
      <clipPath id="clip19">
        <path fill="#fff" d="M0 0h15v15H0z" />
      </clipPath>
    </defs>
  
  `
};
