import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as GroupActions from './groups.actions';
import { Group } from './group.model';

export const groupsFeatureKey = 'groups';

const AMOUNT_OF_USERS_ON_ONE_PAGE = 20;

export interface State extends EntityState<Group> {
  amountOfPages: number;
  amountOfOrgsPages: number;
  count: number;
  selectedGroup: {
    requests: { [id: number]: Request };
  };
  orgs: { [key: number]: Group };
  orgsCount: number;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Group> = createEntityAdapter<Group>({
  selectId: (model) => model.id,
  sortComparer: (a, b) => b.members - a.members
});

export const initialState: State = adapter.getInitialState({
  amountOfPages: null,
  amountOfOrgsPages: null,
  count: null,
  selectedGroup: {
    requests: null
  },
  orgs: null,
  orgsCount: null,
  isLoading: false
});

export const reducer = createReducer(
  initialState,
  on(GroupActions.resetGroups, (state, action): State => initialState),
  on(
    GroupActions.loadGroups,
    (state, action): State => adapter.removeAll({ ...state, isLoading: true })
  ),
  on(
    GroupActions.loadGroupsSuccess,
    (state, action): State => ({
      ...adapter.setAll(action.groups, state),
      amountOfPages: Math.ceil(action.count / AMOUNT_OF_USERS_ON_ONE_PAGE),
      count: action.count,
      isLoading: false
    })
  ),
  on(
    GroupActions.loadOrgsSuccess,
    (state, action): State => ({
      ...state,
      orgs: { ...action.orgs },
      amountOfOrgsPages: Math.ceil(action.count / AMOUNT_OF_USERS_ON_ONE_PAGE),
      orgsCount: action.count
    })
  ),
  on(
    GroupActions.searchGroups,
    (state, action): State => adapter.removeAll({ ...state, isLoading: true })
  ),
  on(GroupActions.searchGroupsSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.groups, state),
      count: action.count,
      isLoading: false
    };
  }),
  on(GroupActions.subscribeToSuccess, (state, action): State => {
    if (!selectTotal(state)) {
      return state;
    }

    const group = selectEntities(state)[action.id];

    if (!group) {
      return state;
    }

    const isPrivate = group.access === 3;

    return adapter.setOne(
      {
        ...group,
        is_subscriber: !isPrivate,
        is_requested: isPrivate
      },
      state
    );
  }),
  on(GroupActions.unsubscribeFromSuccess, (state, action): State => {
    if (!selectTotal(state)) {
      return state;
    }
    const group = selectEntities(state)[action.id];

    if (!group) {
      return state;
    }

    return adapter.setOne(
      {
        ...group,
        is_subscriber: false
      },
      state
    );
  }),
  on(GroupActions.unrequestGroupSuccess, (state, action): State => {
    if (!selectTotal(state)) {
      return state;
    }

    const group = selectEntities(state)[action.group_id];

    if (!group) {
      return state;
    }

    return adapter.setOne(
      {
        ...group,
        is_subscriber: false,
        is_requested: false
      },
      state
    );
  }),
  on(
    GroupActions.createGroupSuccess,
    (state, action): State => adapter.addOne(action.group, state)
  )
);

export const selectState = createFeatureSelector<State>(groupsFeatureKey);

export const { selectEntities, selectTotal, selectAll } =
  adapter.getSelectors();

export const selectGroups = () =>
  createSelector(selectState, (state: State) => {
    if (!selectTotal(state)) {
      return {
        items: null,
        totalCount: state.count,
        isLoading: state.isLoading
      };
    }

    return {
      items: selectAll(state),
      totalCount: state.count,
      isLoading: state.isLoading
    };
  });

export const selectOrgs = () =>
  createSelector(selectState, (state: State) => {
    if (!state.orgs) {
      return { items: null, count: state.count };
    }

    const sortedGroups = _.orderBy(
      Object.values(state.orgs),
      ['members', 'id'],
      ['desc', 'desc']
    );

    return { items: sortedGroups, count: state.count };
  });

export const selectAmountOfPages = () =>
  createSelector(selectState, (state: State) => {
    return state.amountOfPages;
  });

export const selectAmountOfOrgsPages = () =>
  createSelector(selectState, (state: State) => {
    return state.amountOfOrgsPages;
  });
