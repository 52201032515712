import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ConvertIdService } from 'src/app/core/services/convert-id.service';
import { MatDialog } from '@angular/material/dialog';
import { ConnectDialog403Component } from 'src/app/shared/components/403-connect-dialog/403-connect-dialog.component';
import { ContactsService } from 'src/app/core/services/contacts/contacts.service';
import { SocketService } from 'src/app/core/services/socket.service';

@Component({
  selector: 'app-error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Error403Component {
  @Input() profileID: string;

  @Output() connect = new EventEmitter<string>();

  state: { type: Error403Component.Type; id: string | number };

  constructor(
    private convertIdService: ConvertIdService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private contactService: ContactsService,
    private readonly socketService: SocketService
  ) {
    this.state = {
      type: this.router.getCurrentNavigation().extras.state.type,
      id: this.router.getCurrentNavigation().extras.state.id
    };
  }

  get isUser() {
    return this.state.type === Error403Component.Type.User;
  }

  get isOrganization() {
    return this.state.type === Error403Component.Type.Organization;
  }

  onConnect() {
    const id = Number(this.getID());
    this.contactService
      .addContact(id)
      .pipe(
        tap((_) => this.connectRequestHasBeenSent()),
        tap((_) => this.openConnectDialog())
      )
      .subscribe((_) => _);
  }

  private openConnectDialog() {
    const dialogRef = this.dialog.open(ConnectDialog403Component);

    dialogRef
      .afterClosed()
      .pipe(
        filter((_) => !!_?.text),
        map(({ text }) => this.send(text))
      )
      .subscribe((_) => _);
  }

  private send(text: string) {
    this.sendMessage(this.getID(), text);
  }

  private sendMessage(userID: string, text: string) {
    this.socketService.sendMessage(userID, text);
    this.messageHasBeenSent();
  }

  private connectRequestHasBeenSent() {
    this.snackBar.open('Connection request has been sent!', 'OK', {
      duration: 10 * 1000
    });
  }

  private messageHasBeenSent() {
    this.snackBar.open('Message has been sent!', 'OK', {
      duration: 10 * 1000
    });
  }

  private getID() {
    return Number.isSafeInteger(Number(this.state.id))
      ? this.state.id + ''
      : this.convertIdService.convertIdStringToNumber(this.state.id + '');
  }
}

export namespace Error403Component {
  export enum Type {
    User,
    Organization
  }
}
