<mat-form-field>
  <input
    [placeholder]="ctx.placeholder"
    matInput
    [formControl]="ctx.control"
    autocomplete="off"
    [matAutocomplete]="auto"
  />
  <mat-hint *ngIf="ctx.hint">i.e. {{ ctx.hint }}</mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="getInputTitle.bind(this)"
    (optionSelected)="onSelect($event)"
  >
    <mat-option *ngFor="let option of ctx.options | async" [value]="option"> {{ getOptionTitle(option) }} </mat-option>
  </mat-autocomplete>
</mat-form-field>
