import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { CompetitionsService } from '../../../store/service';
import { ICompetitionJury } from '../../../model';

export const minLengthArray = (min: number) => {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.filter((v) => !!v).length >= min) {
      return null;
    }

    return { MinLengthArray: true };
  };
};

@Component({
  selector: 'app-competition-upsert-juri',
  templateUrl: './competition-upsert-jury.component.html',
  styleUrls: ['./competition-upsert-jury.component.scss']
})
export class CompetitionUpsertJuryComponent {
  readonly jury = this.formBuilder.group({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    projects_id: new FormArray([], [Validators.required, minLengthArray(1)])
  });

  private _projects: { id: number; title: string }[];

  public readonly projects$: Observable<[{ id: number; title: string }]> =
    this.competitionService
      .getProjectsAllList({ competitionId: this.data.competitionId })
      .pipe(
        tap((_) =>
          _.forEach((project) =>
            (this.jury.get('projects_id') as FormArray).push(
              new FormControl(
                !!this.data?.jury?.projects_id?.includes(project.id)
              )
            )
          )
        ),
        tap((_) => (this._projects = _)),
        first()
      );

  get juryId() {
    return this.data?.jury?.id;
  }

  constructor(
    private dialogRef: MatDialogRef<
      CompetitionUpsertJuryComponent,
      ICompetitionJury
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: { competitionId: number; jury?: ICompetitionJury },
    private readonly competitionService: CompetitionsService,
    private readonly formBuilder: FormBuilder
  ) {
    if (this.data?.jury) {
      this.jury.patchValue(this.data?.jury);
    }
  }

  onSubmit() {
    const res = {
      id: this.data?.jury?.id,
      ...this.jury.value,
      projects_id: [
        ...(this.jury.get('projects_id').value as Array<boolean>)
          .map((_, i) => (_ ? this._projects[i].id : _))
          .filter((_) => _)
      ]
    };

    this.dialogRef.close(res);
  }
}
