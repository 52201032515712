import { activities } from './../../../shared/components/message/emojis/groups/activities';
import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import {
  SummerProgram,
  AfterSchool,
  Job,
  Organization,
  Article
} from './eduniverse.model';
import * as EduniverseActions from './eduniverse.actions';

export const eduniversesFeatureKey = 'eduniverses';

export interface State {
  summercamps: { items: SummerProgram[]; totalCount: number };
  afterSchoolPrograms: { items: AfterSchool[]; totalCount: number };
  activities: { items: AfterSchool[]; totalCount: number };
  jobs: { items: Job[]; totalCount: number };
  organizations: { items: Organization[]; totalCount: number };
  articles: { items: Article[]; totalCount: number };
  questions: { items: Article[]; totalCount: number };
}

export const initialState: State = {
  summercamps: { items: null, totalCount: null },
  afterSchoolPrograms: { items: null, totalCount: null },
  activities: { items: null, totalCount: null },
  jobs: { items: null, totalCount: null },
  organizations: { items: null, totalCount: null },
  articles: { items: null, totalCount: null },
  questions: { items: null, totalCount: null }
};

export const reducer = createReducer(
  initialState,
  on(EduniverseActions.loadSummerProgram, (state, action) => {
    return {
      ...state,
      summercamps: { items: null, totalCount: null }
    };
  }),
  on(EduniverseActions.loadSummerProgramSuccess, (state, action) => {
    return {
      ...state,
      summercamps: { items: action.summercamps, totalCount: action.totalCount }
    };
  }),
  on(EduniverseActions.loadAfterSchool, (state, action) => {
    return {
      ...state,
      afterSchoolPrograms: { items: null, totalCount: null }
    };
  }),
  on(EduniverseActions.loadAfterSchoolSuccess, (state, action) => {
    return {
      ...state,
      afterSchoolPrograms: {
        items: action.after_school,
        totalCount: action.totalCount
      }
    };
  }),
  on(EduniverseActions.loadActivitiesSuccess, (state, action) => {
    return {
      ...state,
      activities: { items: action.activities, totalCount: action.totalCount }
    };
  }),
  on(EduniverseActions.loadJobs, (state, action) => {
    return {
      ...state,
      jobs: { items: null, totalCount: null }
    };
  }),
  on(EduniverseActions.loadJobsSuccess, (state, action) => {
    return {
      ...state,
      jobs: { items: action.jobs, totalCount: action.totalCount }
    };
  }),
  on(EduniverseActions.loadStudentOrganizations, (state, action) => {
    return {
      ...state,
      organizations: { items: null, totalCount: null }
    };
  }),
  on(EduniverseActions.loadStudentOrganizationsSuccess, (state, action) => {
    return {
      ...state,
      organizations: {
        items: action.organizations,
        totalCount: action.totalCount
      }
    };
  }),
  on(EduniverseActions.loadArticles, (state, action) => {
    return {
      ...state,
      articles: { items: null, totalCount: null }
    };
  }),
  on(EduniverseActions.loadArticlesSuccess, (state, action) => {
    return {
      ...state,
      articles: { items: action.articles, totalCount: action.totalCount }
    };
  }),
  on(EduniverseActions.loadCollegeQuestions, (state, action) => {
    return {
      ...state,
      articles: { items: null, totalCount: null }
    };
  }),
  on(EduniverseActions.loadCollegeQuestionsSuccess, (state, action) => {
    return {
      ...state,
      questions: { items: action.questions, totalCount: action.totalCount }
    };
  })
);

export const selectState = createFeatureSelector<State>(eduniversesFeatureKey);

export const selectSummerPrograms = () =>
  createSelector(selectState, (state: State) => state.summercamps);

export const selectAfterSchoolPrograms = () =>
  createSelector(selectState, (state: State) => state.afterSchoolPrograms);

export const selectActivities = () =>
  createSelector(selectState, (state: State) => state.activities);

export const selectJobs = () =>
  createSelector(selectState, (state: State) => state.jobs);

export const selectOrganizations = () =>
  createSelector(selectState, (state: State) => state.organizations);

export const selectArticles = () =>
  createSelector(selectState, (state: State) => state.articles);

export const selectQuestions = () =>
  createSelector(selectState, (state: State) => state.questions);
