import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import moment from 'moment';

import { BaseService } from 'src/app/core/services/base.service';
import { Observable } from 'rxjs';
import { INoticeCounters } from '../model';

@Injectable({
  providedIn: 'root'
})
export class NoticeCounterService {
  constructor(private base: BaseService) {}

  loadCounters(): Observable<INoticeCounters> {
    return this.base.get('counters').pipe(
      map((_) => ({
        networkRequests: _.network_requests,
        newMessages: _.new_messages,
        notifications: _.notifications,
        weeklyReadAt: this.weeklyReadAt(_.weekly_read_at),
        amountOfNewPosts: _.amount_of_new_posts,
        amountOfNewPrivatePosts: _.amount_of_new_private_posts,
        amountOfSavedPosts: _.amount_of_saved_posts
      }))
    );
  }

  private weeklyReadAt(_) {
    if (_ === null) {
      return null;
    }

    if (!_?.date) {
      return;
    }

    return moment.utc(_.date).valueOf();
  }
}
