import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Router, UrlSerializer } from '@angular/router';
import { CookieModule } from 'ngx-cookie';
import * as Sentry from '@sentry/angular';
import { NgxHotjarModule } from 'ngx-hotjar';

import { environment } from 'src/environments/environment';
import {
  AmplitudeModule,
  NoopAmplitudeModule
} from 'src/app/shared/modules/amplitude';
import {
  AppSignupDialogModule,
  AppAccountConnectedDialogModule,
  AppSigninDialogModule,
  AccountModule
} from 'src/app/shared/modules';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { SharedModule } from './shared/shared.module';
import { appReducers, appEffects } from './shared/stores/_root/state';
import { metaReducers } from './shared/stores/_root/meta';
import { FooterComponent } from './pages/components/footer/footer.component';
// import { HeaderComponent } from './pages/components/header/header.component';
// import { HeaderSSRComponent } from './pages/components/header-ssr/header-ssr.component';
import { InfoMessageComponent } from './pages/components/info-message/info-message.component';
import { InfoMessageEffects } from './pages/components/info-message/info-message.effects';
import * as fromLoader from './pages/components/loader/loader.reducer';
import * as fromInfoMessage from './pages/components/info-message/info-message.reducer';
// import { NoAuthHeaderComponent } from './pages/components/no-auth-header/no-auth-header.component';
// import { NotificationComponent } from './pages/components/notification/notification.component';
import { LoaderComponent } from './pages/components/loader/loader.component';
import { StorageModule } from 'src/app/core/storage/storage.module';
import { HeaderModule } from 'src/app/pages/components/header/header.module';
import { CustomUrlSerializer } from 'src/app/providers/custom-url.serializer';

import { RootStoreModule } from 'src/app/root-store';
import { ApplicationModule, MetaModule } from 'src/app/modules';

import { SentryErrorHandler } from './handlers/SentryErrorHandler';
import { TemplatesModuleModule } from './templates/templates.module';

@NgModule({
  declarations: [
    AppComponent,
    InfoMessageComponent,
    FooterComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CookieModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    RootStoreModule,
    SharedModule,
    CoreModule,
    HeaderModule,
    MetaModule,
    EffectsModule.forRoot(appEffects),
    EntityDataModule.forRoot(entityConfig),
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AppModule.isHjSupported
      ? NgxHotjarModule.forRoot(environment.hj.id, environment.hj.version)
      : [],
    StoreModule.forFeature(fromLoader.loadersFeatureKey, fromLoader.reducer),
    StoreModule.forFeature(
      fromInfoMessage.infoMessagesFeatureKey,
      fromInfoMessage.reducer
    ),
    EffectsModule.forFeature([InfoMessageEffects]),
    StorageModule.forRoot(),
    environment.production
      ? AmplitudeModule.forRoot(environment.amplitude)
      : NoopAmplitudeModule,
    ApplicationModule,
    TemplatesModuleModule,
    AppSigninDialogModule,
    AppSignupDialogModule,
    AppAccountConnectedDialogModule,
    AccountModule
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        return;
      },
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: APP_BASE_HREF,
      useValue: environment.url
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static get isHjSupported(): boolean {
    return environment.production && !!environment.hj;
  }
}
