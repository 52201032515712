<section class="images">
  <div *ngFor="let image of ctx.local" class="image selected" (click)="onLocalUnselect(image)">
    <img [src]="image.base64" />
  </div>

  <div *ngFor="let image of images" class="image" [class.selected]="ctx.saved.has(image)" (click)="onSelect(image)">
    <img [src]="image.url | baseUrl" [alt]="image.name" />
  </div>
</section>

<app-select-file class="selector" [types]="'.jpg, .jpeg, .png, .gif'" (select)="onLocalSelect($event)">
  <div class="icon-wrapper" (click)="preLocalSelect($event); false">
    <div [icon]="'PLUS:15'"></div>
  </div>
</app-select-file>
