<div class="dialog-content-overlay">
  <button type="button" class="btn-close" (click)="onClose(); false"></button>
  <div class="modal-title mt-4"> Account connection success </div>
  <div class="modal-description">
    Your account has been linked to the email you provided and you can now use our quick login.
  </div>
  <div class="form mx-auto mt-3 mb-4">
    <div class="form-el">
      <button class="button" (click)="onClose(); false">Done</button>
    </div>
  </div>
</div>
