import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

@Injectable()
export class HeaderService {
  constructor(private base: BaseService) {}

  getNotifications() {
    return this.base.get('notification').pipe(map((_) => _.notifications));
  }

  readNotification(id: number) {
    return this.base.get(`notification/saw/${id}`);
  }
}
