import { ChatsListActions } from '../actions';
import { IGroupChatMember } from '../../model';

export interface State {
  chatId: number;
  isLoadingMembers: boolean;
  members: IGroupChatMember[];
}

// TODO: rename selected-chat to something more meaningful

export const initialState: State = {
  chatId: null,
  isLoadingMembers: false,
  members: null
};

export function Reducer(
  state = initialState,
  action: ChatsListActions.Actions
): State {
  switch (action.type) {
    case ChatsListActions.ActionTypes.LOAD_GROUP_CHAT_MEMBERS:
      return {
        ...state,
        chatId: action.payload.chatId,
        isLoadingMembers: true,
        members: null
      };
    case ChatsListActions.ActionTypes.LOAD_GROUP_CHAT_MEMBERS_SUCCESS:
      if (state.chatId !== action.payload.chatId) {
        return state;
      }

      return {
        ...state,
        isLoadingMembers: false,
        members: action.payload.members
      };
    default:
      return state;
  }
}

export const isLoadingGroupChatMembers = (state: State) =>
  state.isLoadingMembers;
export const groupChatMembers = (state: State) => state.members;
