import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  loadContacts,
  loadContactsSuccess,
  addContact,
  addContactSuccess,
  removeContact,
  removeContactSuccess
} from './contacts.actions';
import { ContactsService } from './contacts.service';

@Injectable()
export class ContactsEffects {
  constructor(
    private actions$: Actions,
    private contactsService: ContactsService
  ) {}

  effectForSendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContacts),
      switchMap((_) =>
        this.contactsService.getContacts(_.page, _.searchTerm).pipe(
          switchMap((contacts) => [
            loadContactsSuccess({
              contacts: this.normalizeContacts(contacts)
            })
          ])
        )
      )
    )
  );

  effectForAddContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContact),
      switchMap((_) =>
        this.contactsService
          .addContact(_.id)
          .pipe(map((_) => addContactSuccess({ id: _.id })))
      )
    )
  );

  effectForRemoveContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeContact),
      switchMap((_) =>
        this.contactsService
          .removeContact(_.id)
          .pipe(map((_) => removeContactSuccess({ id: _.id })))
      )
    )
  );

  private normalizeContacts(contacts) {
    return contacts.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
