import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { ClubActions } from './actions';
import { ClubService } from './service';
import { ClubsFacade } from './facade';

@Injectable()
export class ClubStoreEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _clubService: ClubService,
    private readonly _clubsFacade: ClubsFacade
  ) {}

  @Effect()
  loadClub$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.LoadClubAction>(ClubActions.ActionTypes.LOAD_CLUB),
      switchMap((params) =>
        this._clubService.loadClub(params.payload.clubId).pipe(
          switchMap(({ club }) => [
            new ClubActions.LoadClubSuccessAction({ club })
          ]),
          catchError((error: Error) => [
            new ClubActions.LoadClubFailureAction({ error: error.message })
          ])
        )
      )
    )
  );

  @Effect()
  updateClub$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.UpdateClubAction>(ClubActions.ActionTypes.UPDATE_CLUB),
      switchMap(({ payload: { id, club } }) =>
        this._clubService.updateClub(id, club).pipe(
          switchMap((_) => [
            new ClubActions.UpdateClubSuccessAction({ club: _ })
          ]),
          catchError((error: Error) => [
            new ClubActions.UpdateClubFailureAction({ error: error.message })
          ])
        )
      )
    )
  );

  @Effect()
  removeClub$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.RemoveClubAction>(ClubActions.ActionTypes.REMOVE_CLUB),
      switchMap(({ payload: { id } }) =>
        this._clubService.removeClub(id).pipe(
          switchMap((_) => [new ClubActions.RemoveClubSuccessAction({ id })]),
          catchError((error: Error) => [
            new ClubActions.RemoveClubFailureAction({ error: error.message })
          ])
        )
      )
    )
  );

  @Effect({ dispatch: false })
  onRemoveClubSuccess$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.RemoveClubSuccessAction>(
        ClubActions.ActionTypes.REMOVE_CLUB_SUCCESS
      ),
      tap((_) => this._router.navigate(['/']))
    )
  );

  @Effect()
  refreshClub$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.RefreshClubAction>(
        ClubActions.ActionTypes.REFRESH_CLUB
      ),
      switchMap((params) =>
        this._clubService.loadClub(params.payload.clubId).pipe(
          switchMap(({ club }) => [
            new ClubActions.RefreshClubSuccessAction({ club })
          ]),
          catchError((error: Error) => [
            new ClubActions.RefreshClubFailureAction({ error: error.message })
          ])
        )
      )
    )
  );

  @Effect()
  loadMembers$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.LoadClubMembersAction>(
        ClubActions.ActionTypes.LOAD_CLUB_MEMBERS
      ),
      switchMap((params) =>
        this._clubService.loadMembers(params.payload).pipe(
          switchMap(({ members, membersTotalCount }) => [
            new ClubActions.LoadClubMembersSuccessAction({
              members,
              membersTotalCount
            })
          ]),
          catchError((error: Error) => [
            new ClubActions.LoadClubMembersFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadMediaPhotos$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.LoadClubMediaPhotosAction>(
        ClubActions.ActionTypes.LOAD_CLUB_MEDIA_PHOTOS
      ),
      switchMap(({ payload: { clubId, page } }) =>
        this._clubService.loadMediaPhotos(clubId, page).pipe(
          switchMap(({ photos, photosTotalCount }) => [
            new ClubActions.LoadClubMediaPhotosSuccessAction({
              photos,
              photosTotalCount
            })
          ]),
          catchError((error: Error) => [
            new ClubActions.LoadClubMediaPhotosFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadMediaFiles$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.LoadClubMediaFilesAction>(
        ClubActions.ActionTypes.LOAD_CLUB_MEDIA_FILES
      ),
      switchMap(({ payload: { clubId, page } }) =>
        this._clubService.loadMediaFiles(clubId, page).pipe(
          switchMap(({ files, filesTotalCount }) => [
            new ClubActions.LoadClubMediaFilesSuccessAction({
              files,
              filesTotalCount
            })
          ]),
          catchError((error: Error) => [
            new ClubActions.LoadClubMediaFilesFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  mediaUploadPhoto$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubUploadMediaPhotoAction>(
        ClubActions.ActionTypes.CLUB_UPLOAD_MEDIA_PHOTO
      ),
      switchMap(({ payload: { clubId, file } }) =>
        this._clubService.uploadMediaPhoto(clubId, file).pipe(
          switchMap(({ photo }) => [
            new ClubActions.ClubUploadMediaPhotoSuccessAction({
              photo
            })
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubUploadMediaPhotoFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  mediaUploadFile$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubUploadMediaFileAction>(
        ClubActions.ActionTypes.CLUB_UPLOAD_MEDIA_FILE
      ),
      switchMap(({ payload: { clubId, file } }) =>
        this._clubService.uploadMediaFile(clubId, file).pipe(
          switchMap(({ file }) => [
            new ClubActions.ClubUploadMediaFileSuccessAction({
              file
            })
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubUploadMediaFileFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadRequests$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.LoadClubAction>(
        ClubActions.ActionTypes.LOAD_CLUB_REQUESTS
      ),
      switchMap((params) =>
        this._clubService.loadRequests(params.payload.clubId).pipe(
          switchMap(({ requests, requestsTotalCount }) => [
            new ClubActions.LoadClubRequestsSuccessAction({
              requests,
              requestsTotalCount
            })
          ]),
          catchError((error: Error) => [
            new ClubActions.LoadClubRequestsFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  updateMemberType$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubUpdateMemberTypeAction>(
        ClubActions.ActionTypes.CLUB_UPDATE_MEMBER_TYPE
      ),
      switchMap((params) =>
        this._clubService.updateMemberType(params.payload).pipe(
          switchMap((_) => [
            new ClubActions.ClubUpdateMemberTypeSuccessAction(params.payload)
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubUpdateMemberTypeFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  connectMember$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubConnectMemberAction>(
        ClubActions.ActionTypes.CLUB_CONNECT_MEMBER
      ),
      switchMap((params) =>
        this._clubService.connectMember(params.payload).pipe(
          switchMap((_) => [
            new ClubActions.ClubConnectMemberSuccessAction(params.payload)
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubConnectMemberFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  disconnectMember$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubDisconnectMemberAction>(
        ClubActions.ActionTypes.CLUB_DISCONNECT_MEMBER
      ),
      switchMap((params) =>
        this._clubService.disconnectMember(params.payload).pipe(
          switchMap((_) => [
            new ClubActions.ClubDisconnectMemberSuccessAction(params.payload)
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubDisconnectMemberFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  removeMember$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubRemoveMemberAction>(
        ClubActions.ActionTypes.CLUB_REMOVE_MEMBER
      ),
      switchMap((params) =>
        this._clubService.removeMember(params.payload).pipe(
          switchMap((_) => [
            new ClubActions.ClubRemoveMemberSuccessAction(params.payload)
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubRemoveMemberFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  inviteMember$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubInviteMemberAction>(
        ClubActions.ActionTypes.CLUB_INVITE_MEMBER
      ),
      switchMap((params) =>
        this._clubService.inviteMember(params.payload).pipe(
          switchMap((_) => [new ClubActions.ClubInviteMemberSuccessAction()]),
          catchError((error: Error) => [
            new ClubActions.ClubInviteMemberFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  updateClubOnInviteMemberSuccess$ = this._actions$.pipe(
    ofType(ClubActions.ActionTypes.CLUB_INVITE_MEMBER_SUCCESS),
    mergeMap((_) => this._clubsFacade.club$),
    take(1),
    filter((_) => !!_),
    switchMap((_) => [new ClubActions.RefreshClubAction({ clubId: _.id })])
  );

  @Effect()
  joinToClub$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubInviteMemberAction>(
        ClubActions.ActionTypes.JOIN_TO_CLUB
      ),
      switchMap((params) =>
        this._clubService.join(params.payload).pipe(
          switchMap((_) => [new ClubActions.JoinToClubSuccessAction()]),
          catchError((error: Error) => [
            new ClubActions.JoinToClubFailureAction({ error: error.message })
          ])
        )
      )
    )
  );

  @Effect()
  leaveClub$: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.LeaveClubAction>(ClubActions.ActionTypes.LEAVE_CLUB),
      switchMap(({ payload: { clubId } }) =>
        this._clubService.leave({ clubId }).pipe(
          switchMap((_) => [
            new ClubActions.LeaveClubSuccessAction({ clubId })
          ]),
          catchError((error: Error) => [
            new ClubActions.LeaveClubFailureAction({ error: error.message })
          ])
        )
      )
    )
  );

  @Effect()
  acceptRequest: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubAcceptMemberRequestAction>(
        ClubActions.ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST
      ),
      switchMap((params) =>
        this._clubService.acceptRequest(params.payload).pipe(
          switchMap((_) => [
            new ClubActions.ClubAcceptMemberRequestSuccessAction(params.payload)
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubAcceptMemberRequestFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  declineRequest: Observable<Action> = this._actions$.pipe(() =>
    this._actions$.pipe(
      ofType<ClubActions.ClubDeclineMemberRequestAction>(
        ClubActions.ActionTypes.CLUB_DECLINE_MEMBER_REQUEST
      ),
      switchMap((params) =>
        this._clubService.declineRequest(params.payload).pipe(
          switchMap((_) => [
            new ClubActions.ClubDeclineMemberRequestSuccessAction(
              params.payload
            )
          ]),
          catchError((error: Error) => [
            new ClubActions.ClubDeclineMemberRequestFailureAction({
              error: error.message
            })
          ])
        )
      )
    )
  );
}
