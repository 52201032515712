import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appHideUntilLoaded]'
})
export class HideUntilLoadedDirective implements OnInit, OnDestroy {
  @Input() onLoadedOnce$: Observable<boolean>;

  private readonly _componentDestroy$ = new Subject();

  constructor(private readonly hostElement: ElementRef) {}

  ngOnInit() {
    this.hideHostElement();

    this.onLoadedOnce$
      .pipe(
        takeUntil(this._componentDestroy$),
        take(1),
        tap((_) => this.showHostElement())
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  private hideHostElement() {
    // TODO: consider to add this logic
    // this.hostElement.nativeElement.style.visibility = 'hidden';
    this.hostElement.nativeElement.hidden = true;
  }

  private showHostElement() {
    // TODO: consider to add this logic
    // this.hostElement.nativeElement.style.visibility = 'visible';
    this.hostElement.nativeElement.hidden = false;
  }
}
