import { TEmoji } from '../model/type';

export const symbols: TEmoji[] = [
  ['🏧', 'ATM sign'],
  ['🚮', 'litter in bin sign'],
  ['🚰', 'potable water'],
  ['♿', 'wheelchair symbol'],
  ['🚹', 'men’s room'],
  ['🚺', 'women’s room'],
  ['🚻', 'restroom'],
  ['🚼', 'baby symbol'],
  ['🚾', 'water closet'],
  ['🛂', 'passport control'],
  ['🛃', 'customs'],
  ['🛄', 'baggage claim'],
  ['🛅', 'left luggage'],
  ['⚠️', 'warning'],
  ['🚸', 'children crossing'],
  ['⛔', 'no entry'],
  ['🚫', 'prohibited'],
  ['🚳', 'no bicycles'],
  ['🚭', 'no smoking'],
  ['🚯', 'no littering'],
  ['🚱', 'non-potable water'],
  ['🚷', 'no pedestrians'],
  ['📵', 'no mobile phones'],
  ['🔞', 'no one under eighteen'],
  ['☢️', 'radioactive'],
  ['☣️', 'biohazard'],
  ['⬆️', 'up arrow'],
  ['↗️', 'up-right arrow'],
  ['➡️', 'right arrow'],
  ['↘️', 'down-right arrow'],
  ['⬇️', 'down arrow'],
  ['↙️', 'down-left arrow'],
  ['⬅️', 'left arrow'],
  ['↖️', 'up-left arrow'],
  ['↕️', 'up-down arrow'],
  ['↔️', 'left-right arrow'],
  ['↩️', 'right arrow curving left'],
  ['↪️', 'left arrow curving right'],
  ['⤴️', 'right arrow curving up'],
  ['⤵️', 'right arrow curving down'],
  ['🔃', 'clockwise vertical arrows'],
  ['🔄', 'counterclockwise arrows button'],
  ['🔙', 'BACK arrow'],
  ['🔚', 'END arrow'],
  ['🔛', 'ON! arrow'],
  ['🔜', 'SOON arrow'],
  ['🔝', 'TOP arrow'],
  ['🛐', 'place of worship'],
  ['⚛️', 'atom symbol'],
  ['🕉️', 'om'],
  ['✡️', 'star of David'],
  ['☸️', 'wheel of dharma'],
  ['☯️', 'yin yang'],
  ['✝️', 'latin cross'],
  ['☦️', 'orthodox cross'],
  ['☪️', 'star and crescent'],
  ['☮️', 'peace symbol'],
  ['🕎', 'menorah'],
  ['🔯', 'dotted six-pointed star'],
  ['♈', 'Aries'],
  ['♉', 'Taurus'],
  ['♊', 'Gemini'],
  ['♋', 'Cancer'],
  ['♌', 'Leo'],
  ['♍', 'Virgo'],
  ['♎', 'Libra'],
  ['♏', 'Scorpio'],
  ['♐', 'Sagittarius'],
  ['♑', 'Capricorn'],
  ['♒', 'Aquarius'],
  ['♓', 'Pisces'],
  ['⛎', 'Ophiuchus'],
  ['🔀', 'shuffle tracks button'],
  ['🔁', 'repeat button'],
  ['🔂', 'repeat single button'],
  ['▶️', 'play button'],
  ['⏩', 'fast-forward button'],
  ['⏭️', 'next track button'],
  ['⏯️', 'play or pause button'],
  ['◀️', 'reverse button'],
  ['⏪', 'fast reverse button'],
  ['⏮️', 'last track button'],
  ['🔼', 'upwards button'],
  ['⏫', 'fast up button'],
  ['🔽', 'downwards button'],
  ['⏬', 'fast down button'],
  ['⏸️', 'pause button'],
  ['⏹️', 'stop button'],
  ['⏺️', 'record button'],
  ['⏏️', 'eject button'],
  ['🎦', 'cinema'],
  ['🔅', 'dim button'],
  ['🔆', 'bright button'],
  ['📶', 'antenna bars'],
  ['📳', 'vibration mode'],
  ['📴', 'mobile phone off'],
  ['♀️', 'female sign'],
  ['♂️', 'male sign'],
  ['⚧️', 'transgender symbol'],
  ['✖️', 'multiply'],
  ['➕', 'plus'],
  ['➖', 'minus'],
  ['➗', 'divide'],
  ['♾️', 'infinity'],
  ['‼️', 'double exclamation mark'],
  ['⁉️', 'exclamation question mark'],
  ['❓', 'question mark'],
  ['❔', 'white question mark'],
  ['❕', 'white exclamation mark'],
  ['❗', 'exclamation mark'],
  ['〰️', 'wavy dash'],
  ['💱', 'currency exchange'],
  ['💲', 'heavy dollar sign'],
  ['⚕️', 'medical symbol'],
  ['♻️', 'recycling symbol'],
  ['⚜️', 'fleur-de-lis'],
  ['🔱', 'trident emblem'],
  ['📛', 'name badge'],
  ['🔰', 'Japanese symbol for beginner'],
  ['⭕', 'hollow red circle'],
  ['✅', 'check mark button'],
  ['☑️', 'check box with check'],
  ['✔️', 'check mark'],
  ['❌', 'cross mark'],
  ['❎', 'cross mark button'],
  ['➰', 'curly loop'],
  ['➿', 'double curly loop'],
  ['〽️', 'part alternation mark'],
  ['✳️', 'eight-spoked asterisk'],
  ['✴️', 'eight-pointed star'],
  ['❇️', 'sparkle'],
  ['©️', 'copyright'],
  ['®️', 'registered'],
  ['™️', 'trade mark'],
  ['#️⃣', 'keycap: #'],
  ['*️⃣', 'keycap: *'],
  ['0️⃣', 'keycap: 0'],
  ['1️⃣', 'keycap: 1'],
  ['2️⃣', 'keycap: 2'],
  ['3️⃣', 'keycap: 3'],
  ['4️⃣', 'keycap: 4'],
  ['5️⃣', 'keycap: 5'],
  ['6️⃣', 'keycap: 6'],
  ['7️⃣', 'keycap: 7'],
  ['8️⃣', 'keycap: 8'],
  ['9️⃣', 'keycap: 9'],
  ['🔟', 'keycap: 10'],
  ['🔠', 'input latin uppercase'],
  ['🔡', 'input latin lowercase'],
  ['🔢', 'input numbers'],
  ['🔣', 'input symbols'],
  ['🔤', 'input latin letters'],
  ['🅰️', 'A button (blood type)'],
  ['🆎', 'AB button (blood type)'],
  ['🅱️', 'B button (blood type)'],
  ['🆑', 'CL button'],
  ['🆒', 'COOL button'],
  ['🆓', 'FREE button'],
  ['ℹ️', 'information'],
  ['🆔', 'ID button'],
  ['Ⓜ️', 'circled M'],
  ['🆕', 'NEW button'],
  ['🆖', 'NG button'],
  ['🅾️', 'O button (blood type)'],
  ['🆗', 'OK button'],
  ['🅿️', 'P button'],
  ['🆘', 'SOS button'],
  ['🆙', 'UP! button'],
  ['🆚', 'VS button'],
  ['🈁', 'Japanese “here” button'],
  ['🈂️', 'Japanese “service charge” button'],
  ['🈷️', 'Japanese “monthly amount” button'],
  ['🈶', 'Japanese “not free of charge” button'],
  ['🈯', 'Japanese “reserved” button'],
  ['🉐', 'Japanese “bargain” button'],
  ['🈹', 'Japanese “discount” button'],
  ['🈚', 'Japanese “free of charge” button'],
  ['🈲', 'Japanese “prohibited” button'],
  ['🉑', 'Japanese “acceptable” button'],
  ['🈸', 'Japanese “application” button'],
  ['🈴', 'Japanese “passing grade” button'],
  ['🈳', 'Japanese “vacancy” button'],
  ['㊗️', 'Japanese “congratulations” button'],
  ['㊙️', 'Japanese “secret” button'],
  ['🈺', 'Japanese “open for business” button'],
  ['🈵', 'Japanese “no vacancy” button'],
  ['🔴', 'red circle'],
  ['🟠', 'orange circle'],
  ['🟡', 'yellow circle'],
  ['🟢', 'green circle'],
  ['🔵', 'blue circle'],
  ['🟣', 'purple circle'],
  ['🟤', 'brown circle'],
  ['⚫', 'black circle'],
  ['⚪', 'white circle'],
  ['🟥', 'red square'],
  ['🟧', 'orange square'],
  ['🟨', 'yellow square'],
  ['🟩', 'green square'],
  ['🟦', 'blue square'],
  ['🟪', 'purple square'],
  ['🟫', 'brown square'],
  ['⬛', 'black large square'],
  ['⬜', 'white large square'],
  ['◼️', 'black medium square'],
  ['◻️', 'white medium square'],
  ['◾', 'black medium-small square'],
  ['◽', 'white medium-small square'],
  ['▪️', 'black small square'],
  ['▫️', 'white small square'],
  ['🔶', 'large orange diamond'],
  ['🔷', 'large blue diamond'],
  ['🔸', 'small orange diamond'],
  ['🔹', 'small blue diamond'],
  ['🔺', 'red triangle pointed up'],
  ['🔻', 'red triangle pointed down'],
  ['💠', 'diamond with a dot'],
  ['🔘', 'radio button'],
  ['🔳', 'white square button'],
  ['🔲', 'black square button']
];
