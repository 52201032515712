<div class="alerts-wrap">
  <div
    class="alert alert-dismissible"
    [@simpleFadeAnimation]="'in'"
    [ngClass]="alert.type"
    role="alert"
    *ngFor="let alert of alerts$ | async"
  >
    {{alert.message}}
    <button type="button" class="close" (click)="delete(alert.id)" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
