import {
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IPost } from '../../../models/post';
import { IImage, ILocalImage } from '../../../models/image';
import { readBase64 } from '../../../utils/readImage';

@Component({
  selector: 'app-message-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class MessageImagesComponent implements OnInit, OnDestroy {
  @Input() readonly ctx: IMessageImagesCtx;
  @Input() readonly editable?: { images: IImage[] };

  public sbscr: Subscription;
  public images: IImage[] = [];

  constructor(protected cd: ChangeDetectorRef) {}

  public ngOnInit() {
    // TODO: refactor
    // similar logic within parent component - message
    this.sbscr = this.ctx.stream.subscribe((values) => {
      this.images = values;
      this.cd.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.sbscr.unsubscribe();
  }

  public onSelect(image: IImage) {
    if (this.ctx.saved.has(image)) {
      this.ctx.saved.delete(image);
    } else {
      if (this.isCanSelect()) this.ctx.saved.add(image);
    }
  }

  public async onLocalSelect([file]: File[]) {
    const base64 = await readBase64(file);

    this.ctx.local.add({ file, base64 });
    this.cd.markForCheck();
  }

  public onLocalUnselect(image: ILocalImage) {
    this.ctx.local.delete(image);
  }

  public clear() {
    this.ctx.saved.clear();
    this.ctx.local.clear();
  }

  public preLocalSelect(e: Event) {
    if (!this.isCanSelect()) {
      e.stopPropagation();
    }
  }

  protected isCanSelect() {
    const total = this.ctx.saved.size + this.ctx.local.size;
    if (total === 10) {
      alert(`Can't select more than 10 images`);
    } else {
      return true;
    }
  }
}

export interface IMessageImagesCtx {
  stream: Observable<IImage[]>;
  saved: Set<IImage>;
  local: Set<ILocalImage>;
}
