import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConvertNameService } from 'src/app/shared/services/convert-name.service';

import { AppBaseUrlPipe } from '../../../baseurl/pipes';
import { AppClubLinkService } from '../../services';

@Directive({
  selector: 'a[appClub]',
  providers: [AppBaseUrlPipe]
})
export class AppClubLinkDirective implements OnInit {
  @Input('appClub') private readonly _appClub: { id: number; name: string };
  @Input('target') private readonly _target:
    | '_self'
    | '_blank'
    | '_parent'
    | '_top' = '_blank';

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _convertNameService: ConvertNameService,
    private readonly _appClubLinkService: AppClubLinkService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe
  ) {}

  ngOnInit(): void {
    const link = this._appClubLinkService.generateLink(this._appClub);
    const url = this._router.serializeUrl(this._router.createUrlTree(link));

    this._el.nativeElement.setAttribute(
      'href',
      this._appBaseUrlPipe.transform(url)
    );
    this._el.nativeElement.setAttribute('target', this._target);
  }
}
