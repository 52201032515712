import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnDestroy {
  readonly isValid$ = new BehaviorSubject(true);
  readonly rawEmails = new FormControl('', [Validators.required]);

  private readonly componentDestroy$ = new Subject();
  private readonly emailValidator = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  constructor(private dialogRef: MatDialogRef<InviteUserDialogComponent>) {
    this.rawEmails.valueChanges
      .pipe(
        takeUntil(this.componentDestroy$),
        distinctUntilChanged(),
        tap((_) => this.isValid$.next(true))
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  close(): void {
    this.dialogRef.close();
  }

  invite() {
    if (!this.isValidEmails(this.rawEmails.value)) {
      this.isValid$.next(false);

      return;
    }

    this.dialogRef.close(this.rawEmails.value);
  }

  private isValidEmails(rawEmails: string): boolean {
    const emails = rawEmails.split(',');

    const invalidEmails = emails.filter(
      (email) => !this.isValidEmail(email.trim())
    );

    if (invalidEmails.length) {
      return false;
    }

    return true;
  }

  private isValidEmail(email: string): boolean {
    this.emailValidator.setValue(email);
    this.emailValidator.updateValueAndValidity();

    return !this.emailValidator.errors;
  }
}
