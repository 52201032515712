import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { SummerProgram } from 'src/app/core/services/eduniverse/eduniverse.model';
import { RoutingService } from '../../../core/services/routing.service';

@Component({
  selector: 'app-summer-programs-right-bar',
  templateUrl: './summer-programs-right-bar.component.html',
  styleUrls: ['./summer-programs-right-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummerProgramsRightBarComponent {
  @Input() programs: { totalCount: number; items: SummerProgram[] };

  constructor(private routing: RoutingService) {}

  public goToProfile(profile) {
    this.routing.goToProfile(profile);
  }
}
