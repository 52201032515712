import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  CompetitionInfoComponent,
  CompetitionParticipantsComponent,
  CompetitionRequestsComponent,
  CompetitionReviewsComponent,
  CompetitionsComponent
} from './containers';
import { CompetitionConfirmJuryComponent } from './components';

const CompetitionInfoComponentChildren: Routes = [
  // {
  //   path: '',
  //   component: CompetitionParticipantsComponent
  // },
  // {
  //   path: 'requests',
  //   component: CompetitionRequestsComponent
  // },
  // {
  //   path: 'reviews',
  //   component: CompetitionReviewsComponent
  // }
  {
    path: 'confirm',
    component: CompetitionConfirmJuryComponent
  }
];

const routes: Routes = [
  {
    path: '',
    component: CompetitionsComponent
  },
  {
    path: ':id/:name',
    component: CompetitionInfoComponent,
    children: CompetitionInfoComponentChildren
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompetitionsRoutingModule {}
