export const userCheck = {
  id: `USER_CHECK`,
  title: `User Check`,
  svg: `
  <path fill="#F09372"
  d="M12.32 4.41c.2-.2.5-.3.8-.24.4.13.2 1.35.2 1.35-.2.62-.67 1.1-1.28 1.3h-7a2 2 0 01-1.28-1.3s-.2-1.22.21-1.35c.3-.06.59.03.8.24h7.55z" />
<path fill="#285680"
  d="M13.58 19.37c-1.65.4-3.34.61-5.04.63 0 0-8.42 0-8.42-3.51 0-1.16.23-2.45 1.07-3.33a6.1 6.1 0 013.84-1.58h7.02a6.77 6.77 0 012.12.47l-.6 7.32z" />
<path fill="#B0D3F0" d="M19.9 15.86a4.14 4.14 0 11-8.28 0 4.14 4.14 0 018.28 0z" />
<path fill="#FDD7AD"
  d="M12.04 6.57c0 .92.27 1.7-.8 2.63-.22.18-.41.38-.59.6-.55.68-.73 1.23-1.57 1.23H8c-.83 0-1.02-.55-1.57-1.23a4.42 4.42 0 00-.58-.6c-1.07-.92-.8-1.71-.8-2.63 0-.92-.4-1.18-.27-3.81S8.28 0 8.28 0h.53s3.36.13 3.5 2.76c.13 2.63-.27 2.89-.27 3.8z" />
<path fill="#E6E7E8"
  d="M12.05 11.58s0 1.6-2.33 3.88l-.85-1.32-.33-.35a.7.7 0 00.7-.69v-.87c.95-.13 1.9-.35 2.81-.65zM8.21 13.71l-.01.08-.83 1.67c-2.34-2.28-2.34-3.88-2.34-3.88.92.3 1.86.52 2.82.65v.87c0 .26.14.49.36.61z" />
<path fill="#C03A2B"
  d="M9.72 15.46c-.34.33-.73.67-1.18 1.03-.44-.36-.84-.7-1.17-1.03l.83-1.67.01-.08c.1.05.22.08.33.08l-.02-.69 1.2 2.36z" />
<path fill="#F09372"
  d="M12.05 11.58c-.92.3-1.86.52-2.82.65 0-.09-.08-.16-.17-.16H8.02c-.09 0-.16.07-.17.16a16.6 16.6 0 01-2.82-.65 2.01 2.01 0 001.4-1.78c.55.68.74 1.23 1.57 1.23h1.08c.84 0 1.02-.55 1.57-1.23.06.82.61 1.53 1.4 1.78z" />
<path fill="#E64C3C"
  d="M9.23 12.23v.87a.7.7 0 01-1.02.61.69.69 0 01-.36-.6v-.88c0-.09.08-.16.17-.16h1.04c.09 0 .17.07.17.16z" />
<path fill="#24AE5F"
  d="M14.24 18.07l-.9-.89a.69.69 0 11.98-.97l.29.28c.06.07.17.07.24 0l2.35-2.35a.69.69 0 01.98.97l-2.96 2.96a.69.69 0 01-.98 0z" />

  `
};
