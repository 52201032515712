import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';

import { NoticeCountersActions } from './actions';
import { NoticeCounterService } from './service';

@Injectable()
export class NoticeCounterStoreEffects {
  constructor(
    private readonly noticeCounterService: NoticeCounterService,
    private readonly actions$: Actions
  ) {}

  @Effect()
  loadNoticeCounters$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<NoticeCountersActions.LoadNoticeCountersAction>(
        NoticeCountersActions.ActionTypes.LOAD_NOTICE_COUNTERS
      ),
      switchMap((params) =>
        this.noticeCounterService
          .loadCounters()
          .pipe(
            switchMap((_) => [
              new NoticeCountersActions.LoadNoticeCountersSuccessAction(_)
            ])
          )
      )
    )
  );
}
