<div class="block right-block">
  <div class="title d-flex">Summer programs</div>
  <div class="right-block-data">
    <app-loader-2 *ngIf="!programs.items"></app-loader-2>
    <div *ngIf="programs.items" class="members-list">
      <div *ngFor="let program of programs.items | randomElements:3" class="member-item d-flex">
        <a [profile]="program">
          <app-avatar [src]="program.icon" [placeholder]="program | initials" class="small"></app-avatar>
        </a>
        <div class="data">
          <a [profile]="program">
            <span class="name">{{ program.name }}</span>
          </a>
          <div class="position">{{ program.location?.city?.name }}, {{ program.location?.country?.name }}</div>
          <div class="link">Explore </div>
        </div>
      </div>
    </div>
  </div>
</div>
