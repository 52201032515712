import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ICompetition } from '../../../model';

@Component({
  selector: 'app-competition-card',
  templateUrl: './competition-card.component.html',
  styleUrls: ['./competition-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitionCardComponent {
  @Input('competition') private readonly _competition: ICompetition;

  get competition() {
    return this._competition;
  }
}
