import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// TODO: should they be the part of this module ?
import { IPost } from 'src/app/shared/models/post';
import { PostLinkService } from 'src/app/shared/services/links';

import { AppBaseUrlPipe } from '../../../baseurl/pipes';

@Directive({
  selector: 'a[post]',
  providers: [AppBaseUrlPipe]
})
export class PostLinkDirective implements OnInit {
  @Input() post: IPost;
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_blank';

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _postLinkService: PostLinkService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe
  ) {}

  ngOnInit() {
    this._el.nativeElement.setAttribute('href', this.route);
    this._el.nativeElement.setAttribute('target', this.target);
  }

  private get route() {
    const link = this._postLinkService.generatePostLink(this.post);
    const url = this._router.serializeUrl(this._router.createUrlTree(link));

    return this._appBaseUrlPipe.transform(url);
  }
}
