import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import {
  IAppleAuthData,
  IAuthData,
  IGoogleAuthData,
  IRegistrationStudentData
} from '../model';
import { RegistrationActions } from './actions';
import { State } from './reducers';
import { authData, isLoading, isRegistered } from './registration.selectors';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFacade implements OnDestroy {
  readonly isLoading$: Observable<boolean>;
  readonly isRegistered$: Observable<boolean>;
  readonly authData$: Observable<IAuthData>;

  private readonly _destroy$ = new Subject<void>();

  constructor(private readonly _store: Store<State>) {
    this.isLoading$ = this._store.pipe(select(isLoading));
    this.isRegistered$ = this._store.pipe(select(isRegistered));
    this.authData$ = this._store.pipe(select(authData));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  appleAuth(data: IAppleAuthData) {
    this._store.dispatch(
      new RegistrationActions.AppleAuthRegistrationAction(data)
    );
  }

  googleAuth(data: IGoogleAuthData) {
    this._store.dispatch(
      new RegistrationActions.GoogleAuthRegistrationAction(data)
    );
  }

  socialAuth(email: string, type: string) {
    this._store.dispatch(
      new RegistrationActions.PasswordlessAuthRegistrationAction({
        email,
        type
      })
    );
  }

  attachToEmail(
    email: string,
    type: RegistrationFacade.SocialAuthType,
    toEmail: string
  ) {
    this._store.dispatch(
      new RegistrationActions.AttachToEmailRegistrationAction({
        email,
        type,
        toEmail
      })
    );
  }

  studentRegistration(data: IRegistrationStudentData) {
    this._store.dispatch(
      new RegistrationActions.StudentRegistrationAction(data)
    );
  }

  cancelRegistration() {
    this._store.dispatch(new RegistrationActions.RegistrationCancelAction());
  }

  reset() {
    this._store.dispatch(new RegistrationActions.RegistrationResetAction());
  }

  fetchAuthData() {
    this._store.dispatch(new RegistrationActions.AuthDataRegistrationAction());
  }
}

export namespace RegistrationFacade {
  export enum SocialAuthType {
    APPLE = 'apple',
    GOOGLE = 'google'
  }
}
