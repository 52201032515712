import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, merge, of, forkJoin } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  share,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { AbstractControl, FormControl } from '@angular/forms';
import { ajax } from 'rxjs/ajax';
import { IOption } from '../../registration/organization/organization.service';
import { IAutocompleteCtx } from '../../shared/form';
import { getValue } from '../../registration/mappers';

declare var google;

interface LocationData {
  address: string;
  location: string;
  value: string;
  type: 'google' | 'local';
  lat: string;
  lng: string;
  place_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class AutoCompleteService {
  constructor(protected httpClient: HttpClient) {}

  get(value: string): Observable<Array<LocationData>> {
    return forkJoin([
      this?.getLocalMapsData(value),
      this.getGoogleMapsData(value)
    ]).pipe(map((_: any) => _.flat()));
  }

  getAddressForLocation(data: LocationData) {
    if (data.type === 'local') {
      return of(data.address);
    }

    return this.getGoogleMapsPlaceDetails(data);
  }

  getLatLngForLocation(data: LocationData) {
    if (data.type === 'local') {
      return of(data.address);
    }

    return this.getGoogleMapsPlaceLatLng(data);
  }

  public getCountry(control: FormControl): IAutocompleteCtx<IOption> {
    return {
      control,
      placeholder: `Country`,
      getTitle: getValue,
      options: this.getOptions(control, `get/field/country`)
    };
  }

  public getCity(control: FormControl): IAutocompleteCtx<IOption> {
    return {
      control,
      placeholder: `City`,
      getTitle: getValue,
      options: this.getOptions(control, `get/field/city`)
    };
  }

  public getOptions(control: FormControl, ref: string) {
    if (!ref) return of([]); // #Return#

    const basic = (value: string) =>
      ajax.getJSON(`${environment.endpoint}/${ref}?term=${value}`);
    const isOrg = ref.includes(`org`);
    const requester = isOrg ? (value: string) => this.get(value) : basic;

    return control.valueChanges.pipe(
      filter((value) => value?.length >= 2),
      debounceTime(500),
      switchMap((value) => requester(value))
    ) as Observable<IOption[]>;
  }

  public getAutocomplete(
    placeholder: string,
    ref: string,
    hint = ``
  ): IAutocompleteCtx<IOption> {
    const control = new FormControl();
    const options = this.getOptions(control, ref);

    return {
      placeholder,
      hint,
      control,
      getTitle: (object: any) => object?.value,
      getOptionTitle: (object: any) => object?.label ?? object?.value,
      options
    };
  }

  public lockOrg(
    org: AbstractControl,
    address: AbstractControl,
    isPlain?: boolean
  ) {
    org.valueChanges
      .pipe(filter((value) => typeof value === 'object' && value !== null))
      .subscribe(async (value: LocationData) => {
        let addressValue;

        if (value.type === 'google') {
          addressValue = { value: value.location };
        } else {
          addressValue = { value: value.address };
        }

        addressValue = isPlain ? addressValue.value : addressValue;
        if (
          typeof addressValue === 'object' &&
          addressValue.hasOwnProperty('value')
        ) {
          addressValue = addressValue.value;
        }
        address.setValue(addressValue);
      });
  }

  private getGoogleMapsPlaceDetails(data: LocationData): Observable<string> {
    const serviceAutocomplete = new google.maps.places.PlacesService(
      new google.maps.Map(document.createElement('div'))
    );

    return Observable.create((observer) =>
      serviceAutocomplete.getDetails({ placeId: data.address }, (item) => {
        observer.next(item.formatted_address);
        observer.complete();
      })
    );
  }
  private getGoogleMapsPlaceLatLng(data: LocationData): Observable<any> {
    const serviceAutocomplete = new google.maps.places.PlacesService(
      new google.maps.Map(document.createElement('div'))
    );

    return Observable.create((observer) =>
      serviceAutocomplete.getDetails({ placeId: data.address }, (item) => {
        observer.next({
          lat: item.geometry.location.lat(),
          lng: item.geometry.location.lng()
        });
        observer.complete();
      })
    );
  }

  private getGoogleMapsData(value): Observable<LocationData[]> {
    if (!value) {
      return of([]);
    }

    const serviceAutocomplete = new google.maps.places.AutocompleteService();

    return Observable.create((observer) =>
      serviceAutocomplete.getPlacePredictions(
        { input: value, types: ['establishment'] },
        (items) => {
          const google = (items ?? []).map((item) => ({
            address: item.place_id,
            location: item.structured_formatting.secondary_text,
            value: item.structured_formatting.main_text,
            type: 'google',
            get label() {
              return `${this.value}, ${this.location}`;
            }
          }));

          observer.next(google);
          observer.complete();
        }
      )
    );
  }

  private getLocalMapsData(value: string): Observable<LocationData[]> {
    return ajax
      .getJSON(`${environment.endpoint}/autocomplete/org?term=${value}`)
      .pipe(
        map((responseData: any) =>
          responseData.map((item) => ({
            ...item,
            type: 'local'
          }))
        )
      );
  }
}
