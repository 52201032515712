import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy
} from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { LeftNavMenuService } from '../../services';

@Component({
  selector: 'app-header-left-nav-template',
  templateUrl: './header-left-nav-template.component.html',
  styleUrls: ['./header-left-nav-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderLeftNavTemplateComponent implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _leftNavMenuService: LeftNavMenuService,
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this._router.events
      .pipe(
        filter((_) => _ instanceof ActivationStart),
        tap((_) => this._leftNavMenuService.renderLeftNavMenu()),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get uninitialized$() {
    return this._leftNavMenuService.initialized$.pipe(map((_) => !_));
  }

  get isRenderLeftNavMenu$() {
    return this._leftNavMenuService.isRenderLeftNavMenu$;
  }

  onActivate() {
    this._leftNavMenuService.initialized();
    this._cdr.detectChanges();
  }
}
