import { IIcon } from '../../model/icon';

export const earth: IIcon = {
  id: `EARTH`,
  svg: `
  <g clip-path="url(#clip22)">
  <path fill="#4482C3" d="M10 20a10 10 0 100-20 10 10 0 000 20z" />
  <path fill="#4FBA6F"
    d="M7.14 3.58c-.45.74-.08 1.63.23 2.42.13.25.17.55.1.83-.12.22-.3.4-.5.54L5.2 8.71c-.43.3-.8.67-1.08 1.11-.46.9.01 1.94-.19 2.9-.11.55-1.44 2.14-2.12 3A9.98 9.98 0 017.27.42v.01c.77.56.97 1.61.47 2.4-.22.25-.42.5-.6.75zM20 10.02a9.8 9.8 0 01-.24 2.15c-.43.1-.88-.02-1.2-.31-.53-.43-.96-1.07-1.73-.97a1.5 1.5 0 00-1.05.72c-.65 1.05-.31 2.39-.86 3.45-.32.52-.74.98-1.24 1.34l-.01.02a.92.92 0 01-1.48-.84c.12-.95-.13-1.9-.69-2.67-.44-.6-1.11-1.15-1.1-1.9 0-1 1.23-1.62 2.03-1.97.44-.18.81-.49 1.07-.89.71-1.2-.4-2.14-.96-3.15a3.6 3.6 0 01.81-4.4A9.97 9.97 0 0120 10.03zM13.35.61l.03-.02M10.86 19.96a9.95 9.95 0 01-7.1-2.16l1.6-1.16c.22-.17.5-.25.78-.23.6.07 1 .68 1.58.85.45.14.93 0 1.4.02.1 0 .19.03.27.06a.7.7 0 01.22.2c.56.72.68 1.72 1.25 2.42z" />
</g>
<defs>
  <clipPath id="clip22">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>
  `
};
