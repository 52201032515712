import { IIcon } from '../../model/icon';

export const think: IIcon = {
  id: `THINK`,
  svg: `
  <path fill="#E6E7E8" d="M7.95 14.14h4.14a.69.69 0 110 1.38H7.95a.69.69 0 010-1.38z" />
  <path fill="#D1D4D1" d="M7.95 15.52h4.14a.69.69 0 110 1.38H7.95a.69.69 0 110-1.38z" />
  <path fill="#E6E7E8" d="M7.95 16.9h4.14a.69.69 0 110 1.38H7.95a.69.69 0 010-1.38z" />
  <path fill="#A5A5A4" d="M8.3 18.28h3.44v.34c0 .76-.61 1.38-1.38 1.38h-.69c-.76 0-1.38-.62-1.38-1.38v-.34z" />
  <path fill="#F0C419"
    d="M12.43 13.12v1.02H7.61v-1.05c-.01-.68-.37-1.3-.94-1.66a6.2 6.2 0 116.72-.01c-.59.36-.95 1-.96 1.7z" />
  <path fill="#F3D55B"
    d="M16.22 6.2a6.2 6.2 0 01-2.83 5.22c-.59.37-.95 1-.96 1.7v1.02h-2.41V0a6.2 6.2 0 016.2 6.2zM14.74 13.35a.34.34 0 01-.29-.15l-.4-.56a.34.34 0 11.58-.4l.39.57a.34.34 0 01-.28.54zM3.64 2.45a.34.34 0 01-.19-.05l-.58-.38a.34.34 0 01.38-.58l.58.37a.34.34 0 01-.19.64zM16.98 11.03a.34.34 0 01-.19-.06l-.58-.37a.34.34 0 01.37-.58l.58.37a.34.34 0 01-.18.64zM2.56 5.2h-.07l-.68-.12a.34.34 0 11.13-.68l.68.12a.34.34 0 01-.07.68zM18.16 8.03l-.06-.01-.68-.12a.34.34 0 11.12-.68l.68.12a.34.34 0 01-.06.69zM1.93 8.3a.34.34 0 01-.07-.67l.67-.15a.34.34 0 11.15.68L2 8.3h-.08zM17.43 4.94a.34.34 0 01-.07-.68l.67-.14a.34.34 0 11.15.67l-.68.15h-.07zM3.22 11.27a.34.34 0 01-.2-.63l.57-.4a.34.34 0 11.4.58l-.57.39a.34.34 0 01-.2.06zM16.25 2.23a.34.34 0 01-.2-.63l.57-.4a.34.34 0 11.4.58l-.57.39a.34.34 0 01-.2.06zM5.54 13.51a.35.35 0 01-.29-.53l.38-.58a.34.34 0 01.58.37l-.38.58a.34.34 0 01-.29.16z" />
  <path fill="#285680"
    d="M13.81 9.1c0 1.6-3.8 1.6-3.8 1.6s-3.78 0-3.78-1.6c-.04-.56.13-1.1.48-1.53a2.74 2.74 0 011.73-.72s0 .96 1.58 2.24c1.58-1.28 1.58-2.24 1.58-2.24.64.03 1.26.28 1.73.72.34.43.51.97.48 1.52z" />
  <path fill="#E6E7E8"
    d="M11.6 6.85s0 .96-1.58 2.24C8.44 7.81 8.44 6.85 8.44 6.85s.95.74 1.58.74c.63 0 1.58-.74 1.58-.74z" />
  <path fill="#F09372"
    d="M11.6 6.85s-.95.74-1.58.74c-.63 0-1.58-.74-1.58-.74.24-.34.44-.72.59-1.11H11c.15.4.34.77.59 1.11zM11.9 3.31c.12-.1.26-.13.4-.1.2.05.1.59.1.59-.1.28-.34.5-.63.56h-3.5a.92.92 0 01-.64-.56s-.1-.54.1-.6c.15-.02.3.02.4.11h3.78z" />
  <path fill="#FDD7AD"
    d="M11.9 2.59c-.07-1.15-1.75-1.2-1.75-1.2h-.27s-1.68.05-1.74 1.2c-.07 1.15.13 1.26.13 1.66 0 .4-.13.75.4 1.15.54.4.54.8 1.08.8h.54c.54 0 .54-.4 1.07-.8.54-.4.4-.74.4-1.15 0-.4.2-.51.14-1.66z" />

  `
};
