import { ProfileLinkService } from './profile-link/profile-link.service';
import { CompetitionLinkService } from './competition-link/competition-link.service';
import { GroupLinkService } from './group-link/group-link.service';
import { PostLinkService } from './post-link/post-link.service';

// TODO: move these services to the appropriate module realted to the links

export {
  ProfileLinkService,
  CompetitionLinkService,
  GroupLinkService,
  PostLinkService
};

export default [
  ProfileLinkService,
  CompetitionLinkService,
  GroupLinkService,
  PostLinkService
];
