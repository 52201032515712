import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { TokenInterceptor } from './intercepters/auth.intercepter';
import { SharedModule } from './../shared/shared.module';

import { NotificationsEffects } from './services/notifications/notifications.effects';
import * as fromNotifications from './services/notifications/notifications.reducer';

import { DialogsEffects } from './services/dialogs/dialogs.effects';
import * as fromMessages from './services/dialogs/dialogs.reducer';

import { ContactsEffects } from './services/contacts/contacts.effects';
import * as fromContacts from './services/contacts/contacts.reducer';

import { GroupsEffects } from './services/groups/groups.effects';
import * as fromGroups from './services/groups/groups.reducer';

import { GroupEffects } from './services/group/group.effects';
import * as fromGroup from './services/group/group.reducer';

import { EventsEffects } from './services/events/events.effects';
import * as fromEvents from './services/events/events.reducer';

import { DiscussionsEffects } from './services/discussions/discussions.effects';
import * as fromDiscussions from './services/discussions/discussions.reducer';

import { OrganizationsEffects } from './services/organizations/organizations.effects';
import * as fromOrganizations from './services/organizations/organizations.reducer';

import { OrganizationEffects } from './services/organization/organization.effects';
import * as fromOrganization from './services/organization/organization.reducer';

import { SuggestionsEffects } from './services/suggestions/suggestions.effects';
import * as fromSuggestions from './services/suggestions/suggestions.reducer';

import { EduniverseEffects } from './services/eduniverse/eduniverse.effects';
import * as fromEduniverse from './services/eduniverse/eduniverse.reducer';

import { PeopleEffects } from './services/people/people.effects';
import * as fromPeople from './services/people/people.reducer';

import { JobEffects } from './services/job/job.effects';
import * as fromJob from './services/job/job.reducer';

import { NetworksEffects } from './services/network/networks.effects';
import * as fromNetwork from './services/network/networks.reducer';

import { RequestsEffect } from './services/requests/requests.effects';
import * as fromRequests from './services/requests/requests.reducer';

import { TopEffects } from './services/top/top.effects';
import * as fromTop from './services/top/top.reducer';

import { ClubsEffects } from './services/clubs/clubs.effects';
import * as fromClubs from './services/clubs/clubs.reducer';

import { PostsEffects } from './services/posts/posts.effects';

import {
  youSchoolReducer,
  youSchoolFeatureKey
} from '../shared/components/sidebars/your-school/store/your-school.reducer';
import { YourSchoolEffects } from '../shared/components/sidebars/your-school/store/your-school.effects';

import { PostEffects } from 'src/app/shared/ui/post/data/store/effects';
import { ProfileInfoEffects } from 'src/app/pages/home/profile/store/profile.effects';
import { postsFeatureKey } from 'src/app/shared/ui/post/data/store/state';
import { postsReducer } from 'src/app/shared/ui/post/data/store/reducer';
import { savedPostsKey } from 'src/app/shared/stores/posts/saved/posts.saved.actions';
import { savedPostsReducer } from 'src/app/shared/stores/posts/saved/posts.saved.reducer';
import { SavedPostEffects } from 'src/app/shared/stores/posts/saved/posts.saved.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    StoreModule.forFeature(
      fromNotifications.notificationsFeatureKey,
      fromNotifications.reducer
    ),
    StoreModule.forFeature(
      fromMessages.dialogsFeatureKey,
      fromMessages.reducer
    ),
    StoreModule.forFeature(
      fromContacts.contactsFeatureKey,
      fromContacts.reducer
    ),
    StoreModule.forFeature(
      fromSuggestions.suggestionsFeatureKey,
      fromSuggestions.reducer
    ),
    StoreModule.forFeature(
      fromEduniverse.eduniversesFeatureKey,
      fromEduniverse.reducer
    ),
    StoreModule.forFeature(fromGroups.groupsFeatureKey, fromGroups.reducer),
    StoreModule.forFeature(fromGroup.groupFeatureKey, fromGroup.reducer),
    StoreModule.forFeature(fromEvents.eventsFeatureKey, fromEvents.reducer),
    StoreModule.forFeature(
      fromDiscussions.discussionsFeatureKey,
      fromDiscussions.reducer
    ),
    StoreModule.forFeature(
      fromOrganization.organizationFeatureKey,
      fromOrganization.reducer
    ),
    StoreModule.forFeature(
      fromOrganizations.organizationsFeatureKey,
      fromOrganizations.reducer
    ),
    StoreModule.forFeature(fromPeople.peopleFeatureKey, fromPeople.reducer),
    StoreModule.forFeature(fromJob.jobFeatureKey, fromJob.reducer),
    StoreModule.forFeature(fromNetwork.networksFeatureKey, fromNetwork.reducer),
    StoreModule.forFeature(
      fromRequests.requestsFeatureKey,
      fromRequests.reducer
    ),
    StoreModule.forFeature(youSchoolFeatureKey, youSchoolReducer),
    StoreModule.forFeature(fromTop.topFeatureKey, fromTop.reducer),
    StoreModule.forFeature(fromClubs.clubFeatureKey, fromClubs.reducer),

    EffectsModule.forFeature([NotificationsEffects]),
    EffectsModule.forFeature([DialogsEffects]),
    EffectsModule.forFeature([ContactsEffects]),
    EffectsModule.forFeature([GroupsEffects]),
    EffectsModule.forFeature([GroupEffects]),
    EffectsModule.forFeature([DiscussionsEffects]),
    EffectsModule.forFeature([OrganizationEffects]),
    EffectsModule.forFeature([OrganizationsEffects]),
    EffectsModule.forFeature([PeopleEffects]),
    EffectsModule.forFeature([EventsEffects]),
    EffectsModule.forFeature([NetworksEffects]),
    EffectsModule.forFeature([RequestsEffect]),
    EffectsModule.forFeature([JobEffects]),
    EffectsModule.forFeature([SuggestionsEffects]),
    EffectsModule.forFeature([EduniverseEffects]),
    EffectsModule.forFeature([YourSchoolEffects]),
    EffectsModule.forFeature([TopEffects]),
    EffectsModule.forFeature([ClubsEffects]),
    EffectsModule.forFeature([PostsEffects]),

    StoreModule.forFeature(postsFeatureKey, postsReducer),
    StoreModule.forFeature(savedPostsKey, savedPostsReducer),
    EffectsModule.forFeature([
      PostEffects,
      ProfileInfoEffects,
      SavedPostEffects
    ])
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
