import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AutocompleteModule } from 'src/app/shared/modules/autocomplete/autocomplete.module';
import { AppIconsModule } from 'src/app/shared/modules/icons/icons.module';

import {
  AppAutocompleteComponent,
  AppDateComponent,
  AppInputComponent,
  AppTagsComponent
} from './components';

// TODO: this module should migrate to the shared library of the future multiproject application

@NgModule({
  declarations: [
    AppAutocompleteComponent,
    AppDateComponent,
    AppInputComponent,
    AppTagsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    AutocompleteModule,
    AppIconsModule
  ],
  exports: [
    AppAutocompleteComponent,
    AppDateComponent,
    AppInputComponent,
    AppTagsComponent
  ]
})
export class AppFormModule {}
