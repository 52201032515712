import { IIcon } from '../../model/icon';

export const earthNetwork: IIcon = {
  id: `EARTH_NETWORK`,
  svg: `
  <path fill="#4482C3" d="M10 18.62a8.62 8.62 0 100-17.24 8.62 8.62 0 000 17.24z" />
  <path fill="#4FBA6F"
    d="M7.51 4.44c-.38.64-.06 1.4.2 2.09a1 1 0 01.09.71c-.1.2-.25.36-.44.47-.5.4-1 .78-1.51 1.16-.38.25-.7.58-.93.96-.4.77 0 1.68-.16 2.5-.1.48-1.25 1.85-1.84 2.6A8.62 8.62 0 017.62 1.7l.01.01c.66.48.84 1.4.4 2.08-.18.2-.36.42-.52.64zM18.62 10c0 .63-.07 1.25-.2 1.86-.38.08-.77-.02-1.05-.27-.45-.37-.82-.92-1.49-.84-.38.07-.7.3-.9.63-.56.9-.27 2.06-.75 2.97-.27.46-.63.85-1.06 1.16l-.02.02a.79.79 0 01-1.27-.73c.1-.81-.11-1.64-.6-2.3-.38-.52-.96-1-.96-1.64.01-.87 1.07-1.4 1.76-1.7a2 2 0 00.92-.77c.62-1.04-.34-1.85-.82-2.72a3.1 3.1 0 01.7-3.8A8.61 8.61 0 0118.62 10zM12.88 1.88l.02-.02M10.73 18.59a8.59 8.59 0 01-6.13-1.86l1.37-1.01c.2-.15.44-.22.68-.2.52.06.87.59 1.37.74.39.12.8 0 1.2.01a.6.6 0 01.24.06c.07.04.14.1.2.17.47.62.58 1.48 1.07 2.09z" />
  <path fill="#CC4B4C" d="M16.9 7.24a3.1 3.1 0 100-6.2 3.1 3.1 0 000 6.2z" />
  <path fill="#E6E7E8"
    d="M18.62 5.8c0 .4-.13.8-.37 1.12a3 3 0 01-2.7 0 1.83 1.83 0 01.7-2.78c.38.3.92.3 1.28 0a1.8 1.8 0 011.1 1.67z" />
  <path fill="#fff" d="M17.93 3.45c0 .32-.14.61-.4.8-.37.3-.9.3-1.27 0-.25-.19-.4-.48-.4-.8a1.03 1.03 0 012.07 0z" />
  <path fill="#E4C05C" d="M3.1 6.2a3.1 3.1 0 100-6.2 3.1 3.1 0 000 6.2z" />
  <path fill="#E6E7E8"
    d="M4.83 4.77c0 .4-.13.8-.37 1.11a3 3 0 01-2.7 0 1.83 1.83 0 01.7-2.78c.37.31.91.31 1.28 0a1.8 1.8 0 011.09 1.67z" />
  <path fill="#fff" d="M4.14 2.41c0 .32-.15.62-.4.81-.37.3-.9.3-1.27 0-.26-.19-.4-.49-.4-.8a1.03 1.03 0 012.07 0z" />
  <path fill="#CC66A1" d="M3.1 20a3.1 3.1 0 100-6.2 3.1 3.1 0 000 6.2z" />
  <path fill="#E6E7E8"
    d="M4.83 18.56c0 .4-.13.8-.37 1.12-.85.43-1.86.43-2.7 0a1.83 1.83 0 01.7-2.78c.37.3.91.3 1.28 0a1.8 1.8 0 011.09 1.66z" />
  <path fill="#fff" d="M4.14 16.2c0 .32-.15.62-.4.82-.37.3-.9.3-1.27 0-.26-.2-.4-.5-.4-.81a1.03 1.03 0 112.07 0z" />
  `
};
