import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { LeftNavMenuService } from '../../services';

@Component({
  selector: 'app-header-left-nav-right-competitions-short-list-template',
  templateUrl:
    './header-left-nav-right-competitions-short-list-template.component.html',
  styleUrls: [
    './header-left-nav-right-competitions-short-list-template.component.scss'
  ]
})
export class HeaderLeftNavRightCompetitionsShortListTemplateComponent
  implements OnDestroy
{
  @ViewChild('competitionWrap') competitionWrap: ElementRef<HTMLElement>;

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _leftNavMenuService: LeftNavMenuService,
    private readonly _router: Router,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this._router.events
      .pipe(
        filter((_) => _ instanceof ActivationStart),
        tap((_) => this._leftNavMenuService.renderLeftNavMenu()),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get uninitialized$() {
    return this._leftNavMenuService.initialized$.pipe(map((_) => !_));
  }

  get isRenderLeftNavMenu$() {
    return this._leftNavMenuService.isRenderLeftNavMenu$;
  }

  onActivate() {
    this._leftNavMenuService.initialized();
  }

  onToggleCompetition(event: MouseEvent) {
    (event.target as HTMLElement).classList.toggle('toggle');
    this.competitionWrap.nativeElement.classList.toggle(
      'competitions-menu-hide'
    );
  }
}
