import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as ClubMembers from '../reducers/club-members.reducer';
import { State, clubFeatureKey } from '../reducers';

const clubModuleState = createFeatureSelector<State>(clubFeatureKey);
const selectClubMembersState = createSelector(
  clubModuleState,
  (state: State) => state.clubMembers
);

export const isLoadingClubMembers = createSelector(
  selectClubMembersState,
  ClubMembers.isLoadingClubMembers
);
export const getClubMembers = createSelector(
  selectClubMembersState,
  ClubMembers.getClubMembers
);
export const getClubMembersAmountOfPages = createSelector(
  selectClubMembersState,
  ClubMembers.getClubMembersAmountOfPages
);
