import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { IsDefaultImagePipe } from '../../pipes/is-default-image/is-default-image.pipe';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IsDefaultImagePipe]
})
export class AvatarComponent {
  @Input() src?: string;
  @Input() placeholder?: string;

  constructor(private isDefaultImagePipe: IsDefaultImagePipe) {}

  get isDefaultImage() {
    return this.src && this.isDefaultImagePipe.transform(this.src);
  }
}
