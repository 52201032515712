import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Loader } from './loader.model';
import * as LoaderActions from './loader.actions';

export const loadersFeatureKey = 'loaders';

export interface State extends EntityState<Loader> {
  show: boolean;
}

export const adapter: EntityAdapter<Loader> = createEntityAdapter<Loader>();

export const initialState: State = adapter.getInitialState({
  show: false
});

const loaderReducer = createReducer(
  initialState,
  on(LoaderActions.showLoaders, () => ({ show: true })),
  on(LoaderActions.hideLoaders, () => ({ show: false }))
);

export function reducer(state: State | undefined, action: Action) {
  return loaderReducer(state, action);
}

export const selectState = createFeatureSelector<State>(loadersFeatureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectState);

export const loading = createSelector(selectState, (state) => state.show);
