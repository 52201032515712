import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClubStoreEffects } from './effects';
import { ClubService } from './service';
import { ClubsFacade } from './facade';
import { clubFeatureKey, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(clubFeatureKey, reducers),
    EffectsModule.forFeature([ClubStoreEffects])
  ],
  providers: [ClubStoreEffects, ClubService, ClubsFacade]
})
export class ClubsStoreModule {}
