import { TEmoji } from '../model/type';

export const animals: TEmoji[] = [
  ['🐵', 'monkey face'],
  ['🐒', 'monkey'],
  ['🦍', 'gorilla'],
  ['🦧', 'orangutan'],
  ['🐶', 'dog face'],
  ['🐕', 'dog'],
  ['🦮', 'guide dog'],
  ['🐕‍🦺', 'service dog'],
  ['🐩', 'poodle'],
  ['🐺', 'wolf'],
  ['🦊', 'fox'],
  ['🦝', 'raccoon'],
  ['🐱', 'cat face'],
  ['🐈', 'cat'],
  ['🐈‍⬛', 'black cat'],
  ['🦁', 'lion'],
  ['🐯', 'tiger face'],
  ['🐅', 'tiger'],
  ['🐆', 'leopard'],
  ['🐴', 'horse face'],
  ['🐎', 'horse'],
  ['🦄', 'unicorn'],
  ['🦓', 'zebra'],
  ['🦌', 'deer'],
  ['🐮', 'cow face'],
  ['🐂', 'ox'],
  ['🐃', 'water buffalo'],
  ['🐄', 'cow'],
  ['🐷', 'pig face'],
  ['🐖', 'pig'],
  ['🐗', 'boar'],
  ['🐽', 'pig nose'],
  ['🐏', 'ram'],
  ['🐑', 'ewe'],
  ['🐐', 'goat'],
  ['🐪', 'camel'],
  ['🐫', 'two-hump camel'],
  ['🦙', 'llama'],
  ['🦒', 'giraffe'],
  ['🐘', 'elephant'],
  ['🦏', 'rhinoceros'],
  ['🦛', 'hippopotamus'],
  ['🐭', 'mouse face'],
  ['🐁', 'mouse'],
  ['🐀', 'rat'],
  ['🐹', 'hamster'],
  ['🐰', 'rabbit face'],
  ['🐇', 'rabbit'],
  ['🐿️', 'chipmunk'],
  ['🦔', 'hedgehog'],
  ['🦇', 'bat'],
  ['🐻', 'bear'],
  ['🐻‍❄️', 'polar bear'],
  ['🐨', 'koala'],
  ['🐼', 'panda'],
  ['🦥', 'sloth'],
  ['🦦', 'otter'],
  ['🦨', 'skunk'],
  ['🦘', 'kangaroo'],
  ['🦡', 'badger'],
  ['🐾', 'paw prints'],
  ['🦃', 'turkey'],
  ['🐔', 'chicken'],
  ['🐓', 'rooster'],
  ['🐣', 'hatching chick'],
  ['🐤', 'baby chick'],
  ['🐥', 'front-facing baby chick'],
  ['🐦', 'bird'],
  ['🐧', 'penguin'],
  ['🕊️', 'dove'],
  ['🦅', 'eagle'],
  ['🦆', 'duck'],
  ['🦢', 'swan'],
  ['🦉', 'owl'],
  ['🦩', 'flamingo'],
  ['🦚', 'peacock'],
  ['🦜', 'parrot'],
  ['🐸', 'frog'],
  ['🐊', 'crocodile'],
  ['🐢', 'turtle'],
  ['🦎', 'lizard'],
  ['🐍', 'snake'],
  ['🐲', 'dragon face'],
  ['🐉', 'dragon'],
  ['🦕', 'sauropod'],
  ['🦖', 'T-Rex'],
  ['🐳', 'spouting whale'],
  ['🐋', 'whale'],
  ['🐬', 'dolphin'],
  ['🐟', 'fish'],
  ['🐠', 'tropical fish'],
  ['🐡', 'blowfish'],
  ['🦈', 'shark'],
  ['🐙', 'octopus'],
  ['🐚', 'spiral shell'],
  ['🐌', 'snail'],
  ['🦋', 'butterfly'],
  ['🐛', 'bug'],
  ['🐜', 'ant'],
  ['🐝', 'honeybee'],
  ['🐞', 'lady beetle'],
  ['🦗', 'cricket'],
  ['🕷️', 'spider'],
  ['🕸️', 'spider web'],
  ['🦂', 'scorpion'],
  ['🦟', 'mosquito'],
  ['🦠', 'microbe'],
  ['💐', 'bouquet'],
  ['🌸', 'cherry blossom'],
  ['💮', 'white flower'],
  ['🏵️', 'rosette'],
  ['🌹', 'rose'],
  ['🥀', 'wilted flower'],
  ['🌺', 'hibiscus'],
  ['🌻', 'sunflower'],
  ['🌼', 'blossom'],
  ['🌷', 'tulip'],
  ['🌱', 'seedling'],
  ['🌲', 'evergreen tree'],
  ['🌳', 'deciduous tree'],
  ['🌴', 'palm tree'],
  ['🌵', 'cactus'],
  ['🌾', 'sheaf of rice'],
  ['🌿', 'herb'],
  ['☘️', 'shamrock'],
  ['🍀', 'four leaf clover'],
  ['🍁', 'maple leaf'],
  ['🍂', 'fallen leaf'],
  ['🍃', 'leaf fluttering in wind']
];
