import { IIcon } from '../../model/icon';

export const computer: IIcon = {
  id: `COMPUTER`,
  svg: `
  <g clip-path="url(#clip24)">
  <path fill="#95A5A5" d="M63.8 81.67H36.2v15.64h27.6V81.67z" />
  <path fill="#BDC3C7"
    d="M93.1 15.36H6.9a6.9 6.9 0 00-6.9 6.9v56.9a6.9 6.9 0 006.9 6.9h86.2a6.9 6.9 0 006.9-6.9v-56.9a6.9 6.9 0 00-6.9-6.9z" />
  <path fill="#35495E" d="M100 72.26v-50a6.9 6.9 0 00-6.9-6.9H6.9a6.9 6.9 0 00-6.9 6.9v50h100z" />
  <path fill="#BDC3C7"
    d="M24.14 92.95h51.72c.95 0 1.73.77 1.73 1.72v3.45c0 .95-.78 1.73-1.73 1.73H24.14c-.95 0-1.73-.78-1.73-1.73v-3.45c0-.95.78-1.72 1.73-1.72z" />
  <path fill="#65DDB9"
    d="M1.52 55.98l3.5-3.5 9.55 9.55-3.5 3.5a5.17 5.17 0 01-7.32 0L1.52 63.3a5.17 5.17 0 010-7.32z" />
  <path fill="#46B29D"
    d="M5.07 47.65l9.5-9.5 14.32 14.33-9.5 9.5a3.45 3.45 0 01-4.87 0l-9.45-9.45a3.45 3.45 0 010-4.88z" />
  <path fill="#E6E7E8"
    d="M14.57 38.15S32.47 17.86 27.7 5.92l33.42 33.43C49.2 34.57 28.9 52.48 28.9 52.48L14.57 38.15z" />
  <path fill="#65DDB9" d="M19.34 62.03l18.42 10.23 5.46-5.46-14.33-14.32-9.55 9.55z" />
  <path fill="#46B29D"
    d="M65.9 39.35a3.36 3.36 0 01-4.78 0L27.71 5.93A3.39 3.39 0 0130.09.16c.9 0 1.76.36 2.4 1l10.46 10.46v.02l12.14 12.12 10.8 10.8a3.36 3.36 0 010 4.79z" />
  <path fill="#BDC3C7" d="M42.95 11.64v-.02a8.62 8.62 0 1112.14 12.14L42.95 11.64z" />
  <path fill="#E6E7E8"
    d="M79.31 53.3H63.79a1.72 1.72 0 110-3.45h15.52a1.72 1.72 0 110 3.44zM81.03 46.4h-5.17a1.72 1.72 0 110-3.45h5.17a1.72 1.72 0 010 3.45zM93.1 46.4h-5.17a1.72 1.72 0 010-3.45h5.17a1.72 1.72 0 010 3.45zM93.1 53.3h-6.9a1.72 1.72 0 110-3.45h6.9a1.72 1.72 0 010 3.44z" />
  <path fill="#4482C3" d="M67.24 58.47h22.42a3.45 3.45 0 010 6.9H67.24a3.45 3.45 0 110-6.9z" />
</g>
<defs>
  <clipPath id="clip24">
    <path fill="#fff" d="M0 0h100v100H0z" />
  </clipPath>
</defs>
  `
};
