import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-message-post-link-preview',
  templateUrl: './message-post-link-preview.component.html',
  styleUrls: ['./message-post-link-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageLinkPreviewComponent implements OnDestroy {
  @Input() url: string;

  @Output() show = new EventEmitter<void>();
  @Output() hide = new EventEmitter<void>();

  isShow$ = new BehaviorSubject(true);
  isRendered$ = new BehaviorSubject(false);
  isRendering$ = new BehaviorSubject(false);

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly zone: NgZone
  ) {}

  ngOnDestroy() {
    this.isShow$.complete();
    this.isRendered$.complete();
    this.isRendering$.complete();
  }

  onHide() {
    this.isShow$.next(false);
    this.hide.emit();
  }

  onShow() {
    this.isShow$.next(true);
    this.show.emit();
  }

  onStartRender() {
    this.isRendered$.next(false);
    this.isRendering$.next(true);
  }

  onRenderSuccess() {
    this.show.emit();
    this.isRendered$.next(true);
    this.isRendering$.next(false);
  }

  onRenderFailure() {
    this.isRendered$.next(false);
    this.isRendering$.next(false);
  }

  onRenderDestroy() {
    this.isRendering$.next(false);
  }
}
