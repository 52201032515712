import { createFeatureSelector, createSelector } from '@ngrx/store';
import { savedPostsKey } from './posts.saved.actions';
import { ISavedPostsState } from './posts.saved.reducer';

const selectState = createFeatureSelector<ISavedPostsState>(savedPostsKey);

export const selectSavedPost = createSelector(
  selectState,
  (state) => state.posts
);

export const selectSavedPostStatus = createSelector(
  selectState,
  (state) => state.status
);
