import { IIcon } from '../../model/icon';

export const group: IIcon = {
  id: `GROUP`,
  svg: `
  <g clip-path="url(#clip14)">
  <path fill="#F09372"
    d="M7.59 6v.71c.05.88.73 1.58 1.6 1.66l1.39.14c.84.1 1.49.8 1.49 1.66v1.62c0 .34-.28.62-.63.62H.62A.62.62 0 010 11.8v-1.62C0 9.32.64 8.6 1.5 8.5l1.14-.14c.85-.09 1.5-.8 1.5-1.66V6h3.45z" />
  <path fill="#FDD7AD"
    d="M5.86 0h.42c1.12.03 2 .95 2 2.07v.25c.2-.1.15-.12.34-.04.44.2.58.84.32 1.45-.2.48-.33.8-.7.82C8.03 5.73 7.1 6.9 5.85 6.9 4.63 6.9 3.7 5.73 3.5 4.55c-.37-.02-.5-.34-.7-.82-.27-.6-.12-1.26.31-1.45.2-.08.14-.06.35.04v-.25c0-1.12.88-2.04 2-2.07h.41z" />
  <path fill="#285680"
    d="M12.07 10.17v1.62c0 .34-.28.62-.63.62H.62A.62.62 0 010 11.8v-1.62C0 9.32.64 8.6 1.5 8.5l.88-.1c.58.73 1.92 1.25 3.48 1.25 1.58 0 2.93-.53 3.5-1.27l1.22.12c.84.1 1.49.8 1.49 1.66z" />
  <path fill="#F09372"
    d="M15.52 6v.71c.05.88.73 1.58 1.6 1.66l1.39.14c.85.1 1.49.8 1.49 1.66v1.62a.62.62 0 01-.62.62H8.56a.62.62 0 01-.63-.62v-1.62c0-.85.65-1.57 1.5-1.66l1.15-.14c.84-.09 1.49-.8 1.49-1.66V6h3.45z" />
  <path fill="#FDD7AD"
    d="M13.8 0h.41c1.12.03 2 .95 2 2.07v.25c.2-.1.15-.12.34-.04.44.2.58.84.32 1.45-.2.48-.33.8-.7.82-.2 1.18-1.14 2.35-2.38 2.35-1.23 0-2.17-1.17-2.37-2.35-.37-.02-.5-.34-.7-.82-.27-.6-.12-1.26.31-1.45.2-.08.15-.06.35.04v-.25c0-1.12.88-2.04 2-2.07h.41z" />
  <path fill="#CC4B4C"
    d="M20 10.17v1.62a.62.62 0 01-.62.62H8.56a.62.62 0 01-.63-.62v-1.62c0-.85.65-1.57 1.5-1.66l.88-.1c.58.73 1.92 1.25 3.48 1.25 1.58 0 2.93-.53 3.5-1.27l1.22.12c.85.1 1.49.8 1.49 1.66z" />
  <path fill="#F09372"
    d="M11.9 13.38v.1c0 .98.74 1.8 1.71 1.9l.89.16c.97.1 1.7.92 1.7 1.9v1.85a.71.71 0 01-.7.71h-11a.71.71 0 01-.7-.71v-1.85c0-.98.73-1.8 1.7-1.9l.89-.16a1.9 1.9 0 001.7-1.9v-.1h3.81z" />
  <path fill="#2C2F38"
    d="M16.2 17.44v1.85a.71.71 0 01-.7.71h-5.16v-1.72a.7.7 0 00.7-.7v-.34a.7.7 0 00-.68-.69l.35-.6.67 1.64 2.45-2.17.67.12c.97.1 1.7.92 1.7 1.9zM10.34 18.28V20H4.51a.71.71 0 01-.72-.71v-1.85c0-.98.74-1.8 1.71-1.9l.67-.12 2.45 2.17.67-1.63.35.6a.7.7 0 00-.67.68v.35c0 .38.3.68.69.69h.68z" />
  <path fill="#D1D4D1" d="M9.31 15.86l.69 1.38.69-1.38.69 1.73V20H8.62v-2.41l.69-1.73z" />
  <path fill="#E64C3C"
    d="M9.66 16.55h.68c.39 0 .7.31.7.7v.34c0 .38-.31.69-.7.69h-.68a.69.69 0 01-.7-.7v-.34c0-.38.31-.69.7-.69z" />
  <path fill="#C03A2B" d="M11.19 20l-.66-1.75H9.47L8.8 20h2.38z" />
  <path fill="#FDD7AD"
    d="M7.24 11.41a2.76 2.76 0 005.52 0c.57-.25.96-.78 1.03-1.4 0-.96-.58-1.04-1.03-.79v-.94c0-1.15-.93-2.07-2.07-2.07H9.31c-1.14 0-2.07.92-2.07 2.07v.94c-.46-.25-1.03-.16-1.03.78a1.8 1.8 0 001.03 1.41z" />
  <path fill="#AF8066"
    d="M12.76 8.28v.92a.92.92 0 00-.7-.23c-1.29 0-1.37-1.38-1.37-1.38S9.23 8.97 7.93 8.97a.92.92 0 00-.69.23v-.92c0-1.15.93-2.07 2.07-2.07h1.38c1.14 0 2.07.92 2.07 2.07z" />
  <path fill="#E6E7E8"
    d="M13.83 15.42l-2.45 2.17-.69-1.73 1.8-1c.3.3.7.48 1.12.52l.22.04zM9.31 15.86l-.69 1.73-2.45-2.17.22-.04c.42-.04.82-.23 1.13-.53l1.79 1.01z" />
</g>
<defs>
  <clipPath id="clip14">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>
  `
};
