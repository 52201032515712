import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IImage } from '../../models/image';

export interface IPswpImage {
  src: string;
  w: number;
  h: number;
}

export interface IPswpOptions {
  index?: number;
}

declare class PhotoSwipe {
  constructor(
    container: HTMLDivElement,
    ui: number,
    images: IPswpImage[],
    options?: IPswpOptions
  );

  init(): void;
  listen(event: string, handler: Function): void;
}

declare const PhotoSwipeUI_Default: number;

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewerComponent implements OnInit, AfterViewInit {
  @ViewChild(`Container`) readonly pswp: ElementRef<HTMLDivElement>;
  @Input() readonly images: IImage[];
  @Input() readonly index: number;
  @Output() readonly close = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const container = this.pswp.nativeElement;
    const options = {
      index: this.index,
      history: false,
      galleryPIDs: false
    };

    const images: IPswpImage[] = this.images.map(({ url, width, height }) => ({
      src: `${environment.endpoint}/${url}`,
      w: width,
      h: height
    }));

    const gallery = new PhotoSwipe(
      container,
      PhotoSwipeUI_Default,
      images,
      options
    );

    gallery.init();
    gallery.listen('destroy', () => this.close.emit());
  }
}
