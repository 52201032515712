import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDefaultImage'
})
export class IsDefaultImagePipe implements PipeTransform {
  transform(value: string): boolean {
    return value && value.startsWith(`/img/default`);
  }
}
