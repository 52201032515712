import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import moment from 'moment';

import { IPost } from '../../../../../models/post';

@Component({
  selector: 'app-post-discussion-header',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostDiscussionHeaderComponent {
  @Input() readonly post: IPost;

  get createdTime() {
    return moment.utc(this.post.created_at.date).valueOf();
  }
}
