import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Route,
  Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DevEnvGuard implements CanActivate, CanLoad {
  constructor(private readonly router: Router) {}

  canActivate(): Observable<true | UrlTree> {
    return of(!environment.production).pipe(
      map((_) => (_ ? true : this.router.createUrlTree([''])))
    );
  }

  // TODO: Update angular and return UrlTree object
  // refactor to be similar to activate
  async canLoad(route: Route, segments: UrlSegment[]) {
    if (!environment.production) {
      return true;
    }

    await this.router.createUrlTree(['']);

    return false;
  }
}
