import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AutoCompleteService } from 'src/app/core/services/autocomplete.service';
import { CategoriesService } from 'src/app/shared/services/categories.service';

import { ICompetition } from '../../../model';
import { IEditorCtx } from '../../../../../shared/form';

@Component({
  selector: 'app-new-competition',
  templateUrl: './new-competition.component.html',
  styleUrls: ['./new-competition.component.scss']
})
export class NewCompetitionComponent implements OnInit, OnDestroy {
  readonly fields = {
    is_online: new FormControl(false, Validators.required),
    name: new FormControl(null, Validators.required),
    category_id: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    date_start: new FormControl(null, Validators.required),
    date_end: new FormControl(null, Validators.required),
    country: new FormControl(null, Validators.required),
    city: new FormControl(null, Validators.required)
  };

  readonly newCompetition = new FormGroup(this.fields);
  readonly country = this.autoService.getCountry(this.fields.country);
  readonly city = this.autoService.getCity(this.fields.city);

  private readonly componentDestroy$ = new Subject();

  readonly description: IEditorCtx = {
    control: this.fields.description
  };

  get isAlreadyCreated() {
    return this.data?.competition?.id;
  }

  get name() {
    return this.data?.competition?.name;
  }

  get categories() {
    return this.categoriesService.CATEGORIES;
  }

  get is_online() {
    return this.newCompetition.get('is_online').value;
  }

  get minDate(): Date {
    return new Date();
  }

  get maxDate(): Date {
    const cd = new Date();

    cd.setFullYear(cd.getFullYear() + 1);

    return cd;
  }

  constructor(
    private autoService: AutoCompleteService,
    private dialogRef: MatDialogRef<{ competition: ICompetition }>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { competition: ICompetition },
    private categoriesService: CategoriesService
  ) {
    this.newCompetition
      .get('is_online')
      .valueChanges.pipe(
        takeUntil(this.componentDestroy$),
        tap((_) => this.onChangeIsOnline(_))
      )
      .subscribe((_) => _);
  }

  ngOnInit() {
    this.maybyPatchCompetition(this.data?.competition);
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  onSubmit() {
    this.dialogRef.close({ competition: this.generateNewCompetitionObject() });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private generateNewCompetitionObject() {
    const isOnline = this.newCompetition.value.is_online;

    const dateStart = moment(this.newCompetition.value.date_start).format(
      'YYYY-MM-DD'
    );
    const dateEnd = moment(this.newCompetition.value.date_end).format(
      'YYYY-MM-DD'
    );

    return {
      name: this.newCompetition.value.name,
      category_id: this.newCompetition.value.category_id,
      description: this.newCompetition.value.description,
      date_start: dateStart,
      date_end: dateEnd,
      country: isOnline ? null : this.newCompetition.value.country,
      city: isOnline ? null : this.newCompetition.value.city
    };
  }

  private onChangeIsOnline(isOnline: boolean) {
    const validators = isOnline ? [] : [Validators.required];

    this.newCompetition.controls['country'].setValidators(validators);
    this.newCompetition.controls['city'].setValidators(validators);

    this.newCompetition.controls['country'].updateValueAndValidity();
    this.newCompetition.controls['city'].updateValueAndValidity();
  }

  private maybyPatchCompetition(competition: ICompetition) {
    if (!competition) {
      return;
    }

    this.newCompetition.patchValue({
      is_online: !(
        competition.location?.country.name && competition.location?.city.name
      ),
      name: competition.name,
      category_id: competition.category?.id || null,
      description: competition.description,
      date_start: competition.date_start,
      date_end: competition.date_end,
      country: competition.location?.country.name || null,
      city: competition.location?.city.name || null
    });
  }
}
