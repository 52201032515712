import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as ClubMediaFiles from '../reducers/club-media-files.reducer';
import { State, clubFeatureKey } from '../reducers';

const clubModuleState = createFeatureSelector<State>(clubFeatureKey);
const selectClubMediaState = createSelector(
  clubModuleState,
  (state: State) => state.clubMediaFiles
);

export const isLoadingClubMediaFiles = createSelector(
  selectClubMediaState,
  ClubMediaFiles.isLoadingClubMediaFiles
);
export const getClubMediaFiles = createSelector(
  selectClubMediaState,
  ClubMediaFiles.getClubMediaFiles
);
export const getClubMediaFilesAmountOfPages = createSelector(
  selectClubMediaState,
  ClubMediaFiles.getClubMediaFilesAmountOfPages
);
