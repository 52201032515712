import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppIconsComponent } from './components';
import { AppIconsDirective } from './directives';

@NgModule({
  declarations: [AppIconsComponent, AppIconsDirective],
  imports: [CommonModule],
  exports: [AppIconsComponent, AppIconsDirective]
})
export class AppIconsModule {}
