<div class="ceil-article-item discussion-item">
  <div class="ceil-content">
    <div class="ceil-text">
      <div class="ceil-title">
        <a [article]="discussion">{{ discussion.name | textTruncate: 30 }}</a>
      </div>
      <div class="ceil-description">{{ discussion.description | nbspClear | textTruncate: 50 }}</div>
    </div>
    <div class="ceil-explore-link">
      <a [article]="discussion">Read</a>
    </div>
  </div>
</div>
