import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

interface ICompetition {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompetitionLinkService {
  generateCompetitionLink(competition: ICompetition) {
    return [
      environment.routePrefix,
      'competitions',
      competition.id,
      competition.name
    ];
  }
}
