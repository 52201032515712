<footer>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3 mb-4">
        <a [href]="'/' | appBaseUrl" class="logo d-block mb-3 mb-lg-4">
          <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#47979D" d="M0 0h44v44H0z" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M30.36 26.28H25.9v7.32H15.7C10.6 33.6 5.5 32.1 5.5 25.2c0-5.46 3.78-7.96 8.8-7.96h4.46V9.92h.02V9.9h10.17c5.11 0 10.2 1.5 10.2 8.42 0 5.46-3.78 7.96-8.8 7.96Zm-11.6-3.52h-3.44c-1.74 0-2.96.56-2.96 2.5 0 1.69.99 2.52 3.09 2.52h3.32v-5.02Zm10.45-7.04H25.9v5.03h3.44c1.74 0 2.95-.56 2.95-2.5 0-1.7-.98-2.53-3.08-2.53Z"
              fill="#fff"
            />
          </svg>
        </a>
        <app-apple-store-icon class="app-item d-block mb-3"></app-apple-store-icon>
        <app-google-store-icon class="app-item d-block mb-3"></app-google-store-icon>
      </div>
      <div class="col-6 col-lg-2 mb-4">
        <div class="menu-title">About</div>
        <div class="menu-item"><a [href]="'about-us' | appBaseUrl" target="_blank">About us</a></div>
        <div class="menu-item"><a [href]="'careers' | appBaseUrl" target="_blank">Careers</a></div>
        <div class="menu-item"><a href="https://medium.com/@roundpier" target="_blank">RP Blog</a></div>
        <div class="menu-item"><a [href]="'faq' | appBaseUrl" target="_blank">FAQ</a></div>
      </div>
      <div class="col-6 col-lg-2 mb-4">
        <div class="menu-title">Social</div>
        <div class="menu-item"><a href="https://twitter.com/RoundPier" target="_blank">Twitter</a></div>
        <div class="menu-item"><a href="https://www.linkedin.com/company/roundpier" target="_blank">Linkedin</a></div>
        <div class="menu-item"><a href="https://www.instagram.com/roundpier" target="_blank">Instagram</a></div>
        <div class="menu-item"><a href="https://www.tiktok.com/@roundpier" target="_blank">TikTok</a></div>
      </div>
      <div class="col-12 col-lg-3 mb-4">
        <div class="menu-title">Legal</div>
        <div class="menu-item"><a [href]="'privacy-policy' | appBaseUrl" target="_blank">Privacy Policy</a></div>
        <div class="menu-item"
          ><a [href]="'terms-conditions' | appBaseUrl" target="_blank">Terms and Conditions</a></div
        >
        <div class="menu-item"><a [href]="'rules-of-conduct' | appBaseUrl" target="_blank">Rules of Conduct</a></div>
      </div>
      <div class="col-12 col-lg-2 mb-4">
        <div class="menu-title">Customer Help</div>
        <div class="menu-item"><a href="mailto:info@roundpier.com">info@roundpier.com</a></div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <div class="container">RoundPier © 2017 - 2024</div>
  </div>
</footer>
