import { ConvertNameService } from './../../shared/services/convert-name.service';
import { Injectable } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { IDoc } from '../../shared/models/doc';
import { environment } from '../../../environments/environment';
import { ConvertIdService } from './convert-id.service';

export interface IProfileEntity {
  id: string | number;
  type: string;
  name?: string;
}

export interface IArticleEntity {
  id: string | number;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private convertNameService: ConvertNameService,
    private convertIdService: ConvertIdService
  ) {}

  public newsURL(params?: NavigationExtras) {
    return this.router.parseUrl('/news');
  }

  public goToNewsPage(params?: NavigationExtras) {
    this.router.navigate(['/news'], params);
  }

  public goToHomePage(params?: NavigationExtras) {
    return this.router.navigate(['/'], params);
  }

  public goToUnexpectedErrorPage(params?: NavigationExtras) {
    this.router.navigate(['unexpected-error'], params);
  }

  public goTo403(params?: NavigationExtras) {
    this.router.navigate(['403'], params);
  }

  public goTo404(params?: NavigationExtras) {
    this.router.navigate(['404'], params);
  }

  public goToGroupMessages() {
    this.router.navigate(['group-messages']);
  }

  public goToMessages() {
    this.router.navigate(['messages']);
  }

  public goToGroups(params?: NavigationExtras) {
    this.router.navigate(['/groups'], params);
  }

  public goToProfile(profile: IProfileEntity, type?: string) {
    let ref: string;

    if (type) {
      ref = type;
    } else {
      switch (profile.type) {
        case `org`:
          ref = `organization`;
          break;
        default:
          ref = `profile`;
          break;
      }
    }

    this.router.navigate([ref, profile.id]);
  }

  public goToOrgHome(profile: IProfileEntity) {
    this.router.navigate([
      this.convertIdService.convertIdNumberToString(profile.id),
      'new'
    ]);
  }

  public goToProfileOrOrg(type: string, nativationParams?: NavigationExtras) {
    switch (type) {
      case `org`:
        return this.router.navigateByUrl(
          this.generateOrgURL(),
          nativationParams
        );
      default:
        return this.router.navigateByUrl(
          this.generateProfileURL(),
          nativationParams
        );
    }
  }

  private generateProfileURL() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};

    return this.router.createUrlTree(
      ['profile', ...urlTree.toString().split('/')].filter((_) => _),
      { queryParams: this.route.snapshot.queryParams }
    );
  }

  private generateOrgURL() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};

    return this.router.createUrlTree(
      ['organization', ...urlTree.toString().split('/')].filter((_) => _),
      { queryParams: this.route.snapshot.queryParams }
    );
  }

  public goToProfileWithName(profile: IProfileEntity) {
    const idName =
      typeof profile.id === 'string'
        ? profile.id.split(`/`)
        : [
            this.convertIdService.convertIdNumberToString(profile.id),
            this.convertNameService.convertName(profile.name)
          ];

    window.open(
      this.router.serializeUrl(this.router.createUrlTree([...idName])),
      '_blank'
    );
  }

  public getDiscussionLink(id: number | string, name: string) {
    const link = [
      '/discussions',
      id,
      this.convertNameService.convertName(name)
    ];
    return link;
  }

  public getGroupLink(id: number | string, name: string) {
    const link = ['/groups', id, this.convertNameService.convertName(name)];
    return link;
  }

  public getProfileLink(profile: IProfileEntity) {
    const { id, name } = this.getProfileIdName(profile);

    return this.router.createUrlTree([id, name]);
  }

  public getProfileLinkForIdName(id: string, name: string) {
    return this.router.createUrlTree([
      this.convertIdService.convertIdNumberToString(id),
      name
    ]);
  }

  public openDoc(doc: IDoc) {
    window.open(`${environment.endpoint}/${doc.url}`);
  }

  public searchByTopic(topic: string, value: string) {
    this.router.navigate([`search`], {
      queryParams: { q: value }
    });
  }

  public searchOrgMembers(org_id: number | string) {
    return this.router.createUrlTree([`search`], {
      queryParams: { org_id, kind: 'members' }
    });
  }

  public orgSettings() {
    return this.router.createUrlTree(['settings', 'org']);
  }

  private getProfileIdName(profile) {
    const [id, name] =
      typeof profile.id === 'string'
        ? profile.id.split(`/`)
        : [
            this.convertIdService.convertIdNumberToString(profile.id),
            profile.name
          ];

    return { id, name };
  }
}
