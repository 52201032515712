import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as NoticeCounter from './reducers/notice-counter.reducer';
import { State, noticeCounterFeatureKey } from './reducers';

export const noticeCounterModuleState = createFeatureSelector<State>(
  noticeCounterFeatureKey
);

export const selectNoticeCounterState = createSelector(
  noticeCounterModuleState,
  (state: State) => state
);

export const getNetworkRequests = createSelector(
  selectNoticeCounterState,
  NoticeCounter.getNetworkRequests
);

export const getMessages = createSelector(
  selectNoticeCounterState,
  NoticeCounter.getMessages
);

export const getNotifications = createSelector(
  selectNoticeCounterState,
  NoticeCounter.getNotifications
);

export const getWeeklyReadAt = createSelector(
  selectNoticeCounterState,
  NoticeCounter.getWeeklyReadAt
);

export const getAmountOfNewPosts = createSelector(
  selectNoticeCounterState,
  NoticeCounter.getAmountOfNewPosts
);

export const getAmountOfNewPrivatePosts = createSelector(
  selectNoticeCounterState,
  NoticeCounter.getAmountOfNewPrivatePosts
);

export const getAmountOfSavedPosts = createSelector(
  selectNoticeCounterState,
  NoticeCounter.getAmountOfSavedPosts
);

export const isLoaded$ = createSelector(
  selectNoticeCounterState,
  NoticeCounter.isLoaded$
);
