import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ICompetitionTeam } from '../../../model';

export interface Data {
  team: ICompetitionTeam;
}

@Component({
  selector: 'app-competition-review-result',
  templateUrl: './competition-team-view.component.html',
  styleUrls: ['./competition-team-view.component.scss']
})
export class CompetitionTeamViewComponent {
  get team() {
    return this.data.team;
  }
  constructor(
    private dialogRef: MatDialogRef<CompetitionTeamViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  onConfirmTeam(team: ICompetitionTeam) {
    if (confirm(`Confirm team: ${team.name}?`)) {
      console.log(team);
      this.dialogRef.close({ confirm: true });
    }
  }

  onRejectTeam(team: ICompetitionTeam) {
    if (confirm(`Reject team: ${team.name}?`)) {
      console.log(team);
      this.dialogRef.close({ reject: true });
    }
  }
}
