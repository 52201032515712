export async function readBase64(file: File): Promise<string> {
  const reader = new FileReader();
  let _resolve;
  const promise = new Promise<string>((resolve) => (_resolve = resolve));

  reader.onload = (e) => {
    const base64 = e.target.result as string;
    _resolve(base64);
  };

  reader.readAsDataURL(file);

  return promise;
}
