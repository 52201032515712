import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { EditorCounter } from './editor.implementation';
import { EditorComponent } from './editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EditorComponent],
  exports: [EditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      customModules: [
        {
          implementation: EditorCounter,
          path: 'modules/counter'
        }
      ]
    })
  ],
  providers: []
})
export class EditorModule {}
