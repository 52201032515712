<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title *ngIf="!isAlreadyCreated">Create new club, chapter or a group for your school</h1>
  <h1 mat-dialog-title *ngIf="isAlreadyCreated">Edit {{ name }}</h1>
  <div mat-dialog-content>
    <div class="form-wrapper py-0 px-3">
      <form [formGroup]="newGroup" (ngSubmit)="onSubmit()">
        <div class="background-wrapper d-flex mb-4" (click)="fInput.click()">
          <div class="holder">
            <img [src]="loadedImage" class="loadedImage" *ngIf="loadedImage" />
            <img [src]="currentImage | baseUrl" class="loadedImage" *ngIf="currentImage" />
          </div>
          <div class="d-flex flex-column align-items-center justify-content-center m-auto">
            <div class="plus-icon">
              <input #fInput type="file" (change)="onImageChange($event)" accept="image/*" />
              <svg viewBox="0 0 15 14">
                <use href="#plus-white"></use>
              </svg>
            </div>
            <div class="placeholder mt-2">
              <span>Add an image</span>
            </div>
          </div>
        </div>

        <mat-form-field class="w-100">
          <mat-label>Include the name of your school club, group or chapter</mat-label>
          <input
            matInput
            placeholder="Include the name of your school club, group or chapter"
            formControlName="name"
            required
          />
          <mat-hint>i.e. FBLA Rushmore Academy or Science Club Rushmore Academy</mat-hint>
        </mat-form-field>
        <mat-form-field class="w-100" formGroupName="school">
          <mat-label>Associated school</mat-label>
          <input
            type="text"
            placeholder="Associated school"
            aria-label="Number"
            [required]="isRequiredSchoolName"
            matInput
            formControlName="name"
            (keyup)="schoolsKeyUp$.next($event)"
            [matAutocomplete]="auto1"
          />
          <mat-autocomplete #auto1="matAutocomplete">
            <mat-option
              *ngFor="let school of schools$ | async"
              (click)="onSelectSchoolFromList(school)"
              [value]="school.value"
            >
              {{ school.value }}, {{ school.location }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint>i.e. Rushmore Academy (leave blank if the club isn’t part of your school)</mat-hint>
        </mat-form-field>
        <mat-form-field class="w-100" formGroupName="organization">
          <mat-label>Main organization</mat-label>
          <input
            type="text"
            placeholder="Main organization"
            aria-label="Number"
            [required]="isRequiredOrganizationName"
            matInput
            formControlName="name"
            (keyup)="organizationsKeyUp$.next($event)"
            [matAutocomplete]="auto2"
          />
          <mat-autocomplete #auto2="matAutocomplete">
            <mat-option
              *ngFor="let organization of organizations$ | async"
              (click)="onSelectOrganizationFromList(organization)"
              [value]="organization.value"
            >
              {{ organization.value }}, {{ organization.location }}
            </mat-option>
          </mat-autocomplete>
          <mat-hint
            >i.e. Future Business Leaders of America (leave blank if the club isn’t part of any organization)</mat-hint
          >
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Club category</mat-label>
          <mat-select formControlName="category_id" required>
            <mat-option [value]="category.id" *ngFor="let category of categories"> {{ category.title }} </mat-option>
          </mat-select>
          <mat-hint>i.e. Science (choose Other if you don’t see relevant category)</mat-hint>
        </mat-form-field>
        <!-- <div class="two-fields">
          <mat-form-field>
            <mat-label>Age group</mat-label>
            <mat-select formControlName="age_group_id" required>
              <mat-option [value]="age.id" *ngFor="let age of ages">
                {{ age.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Access</mat-label>
            <mat-select formControlName="access" required>
              <mat-option [value]="access.id" *ngFor="let access of accesses">
                {{ access.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div class="three">
          <app-autocomplete [ctx]="country"></app-autocomplete>
          <app-autocomplete [ctx]="city"></app-autocomplete>
          <app-input [ctx]="zip"></app-input>
        </div>
        <!-- <mat-form-field class="w-100">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Address" formControlName="address">
        </mat-form-field> -->
        <!-- <div class="add-item-field-wrapper d-flex">
          <div class="d-flex w-100">
            <mat-form-field class="w-100">
              <mat-label>Enter keywords </mat-label>
              <input matInput placeholder="Enter keywords " [value]="newKeyword" (change)="onChangeKeyword($event.target.value)">
              <mat-hint>i.e. FBLA  Rushmore Academy, Business etc</mat-hint>
            </mat-form-field>
            <button class="add-item-btn d-flex align-items-center justify-content-center" (click)="onAddKeyword(); false">
              <svg viewBox="0 0 15 14">
                <use href="#plus-white" /></svg>
            </button>
          </div>
        </div> -->
        <!-- <div class="additional-wrapper d-flex flex-wrap">
          <div class="additional-item d-flex align-items-center" *ngFor="let keyword of keywords">
            <button class="close" (click)="onRemoveKeyword(keyword)">
              <svg viewBox="0 0 8 8">
                <use href="#close" /></svg>
            </button>
            {{ keyword }}
          </div>
        </div> -->
        <mat-form-field class="w-100">
          <mat-label>Description of your club, chapter or Group</mat-label>
          <textarea
            formControlName="description"
            matInput
            rows="6"
            placeholder="Description of your club, chapter or Group"
            [maxLength]="250"
            required
            #desc
          ></textarea>
          <div class="character-counter">{{desc.textLength}}/{{desc.maxLength}}</div>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>How to get involved</mat-label>
          <input formControlName="link" matInput type="text" placeholder="How to get involved" value="" required />
          <mat-hint>i.e. contact Club admin to join</mat-hint>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="alt-action-btn" *ngIf="isAlreadyCreated" (click)="onDelete(); false" [disabled]="!isAlreadyCreated"
      >Delete club</button
    >
    <button class="main-action-btn" (click)="onSubmit(); false" [disabled]="!newGroup.valid">Save</button>
  </div>
</div>
