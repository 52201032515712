import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap } from 'rxjs/operators';
import { IPost } from '../../../models/post';
import { loadCommentsForPostSuccess } from '../../../ui/post/data/store/actions';
import {
  addSavedPost,
  deleteSavedPost,
  loadSavedPosts
} from './posts.saved.actions';
import { SavedPostsService } from './posts.saved.service';

@Injectable({
  providedIn: 'root'
})
export class SavedPostEffects {
  constructor(private actions: Actions, private service: SavedPostsService) {}

  protected loadSavedPosts = createEffect(() =>
    this.actions.pipe(
      ofType(loadSavedPosts.init),
      switchMap(({ payload: { page } }) =>
        this.service
          .getSavedPosts(page)
          .pipe(
            switchMap((payload) => [
              loadSavedPosts.success({ payload }),
              ...this.getCommentsActions(payload)
            ])
          )
      )
    )
  );

  protected addSavedPost = createEffect(() =>
    this.actions.pipe(
      ofType(addSavedPost.init),
      mergeMap(({ payload }) =>
        this.service
          .addSavedPost(payload)
          .pipe(switchMap(() => [addSavedPost.success({ payload })]))
      )
    )
  );

  protected deleteSavedPost = createEffect(() =>
    this.actions.pipe(
      ofType(deleteSavedPost.init),
      mergeMap(({ payload }) =>
        this.service
          .deleteSavedPost(payload)
          .pipe(switchMap(() => [deleteSavedPost.success({ payload })]))
      )
    )
  );

  protected getCommentsActions(posts: IPost[] = []) {
    return posts.map((post) => {
      return loadCommentsForPostSuccess({
        post: post,
        comments: post.comments.list,
        isFromPost: true
      });
    });
  }
}
