import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ICompetition, ICompetitionTeam } from '../../../model';

@Component({
  selector: 'app-competition-write-post',
  templateUrl: './competition-write-post.component.html',
  styleUrls: ['./competition-write-post.component.scss']
})
export class CompetitionWritePostComponent implements OnInit, OnDestroy {
  readonly ASSETS = CompetitionWritePostComponent.ASSETS;

  readonly images = {
    upload: new Subject<void>(),
    uploaded: new Subject<number[]>()
  };

  readonly docs = {
    upload: new Subject<void>(),
    uploaded: new Subject<number[]>()
  };

  readonly fields = {
    message: new FormControl('', Validators.required)
  };

  get teamName() {
    return this.data?.team?.name;
  }

  newPostForm = new FormGroup(this.fields);
  selectedAsset: CompetitionWritePostComponent.ASSETS = null;

  private readonly componentDestroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<CompetitionWritePostComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { team?: ICompetitionTeam; competition: ICompetition }
  ) {}

  ngOnInit() {
    forkJoin([
      this.docs.uploaded.asObservable(),
      this.images.uploaded.asObservable()
    ])
      .pipe(
        takeUntil(this.componentDestroy$),
        map(([docs, images]) => ({
          competition_id: this.data.competition.id,
          message: this.newPostForm.value.message,
          docs,
          images
        })),
        map((_) => this.dialogRef.close({ ..._ }))
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  onSelectAsset(selectedAsset: CompetitionWritePostComponent.ASSETS) {
    this.selectedAsset =
      this.selectedAsset === selectedAsset ? null : selectedAsset;
  }

  onSend() {
    if (!this.newPostForm.value.message) {
      return;
    }

    this.docs.upload.next();
    this.images.upload.next();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

export namespace CompetitionWritePostComponent {
  export enum ASSETS {
    DOCS,
    IMAGES
  }
}
