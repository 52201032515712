import { IIcon } from '../../model/icon';

export const doc: IIcon = {
  id: `DOC`,
  svg: `
  <g clip-path="url(#clip8)">
  <path d="M16.3 2.7V1.2C16.3.5 15.7 0 15 0H2.5C2 0 1.4.5 1.4 1.2V16c0 .6.5 1.2 1.1 1.2h1.6L16.3 2.7z"
    fill="#BDC3C7" />
  <path
    d="M10.8 2.7h6.7c.6 0 1.1.6 1.1 1.2v15c0 .6-.5 1.1-1.1 1.1H5.3c-.7 0-1.2-.5-1.2-1.2V4c0-.6.5-1.2 1.2-1.2h1.2"
    fill="#ECF0F1" />
  <path d="M17 18.8h-1.5a.4.4 0 110-.8h1.2V17a.4.4 0 01.8 0v1.5c0 .2-.2.4-.4.4z" fill="#95A5A5" />
  <path
    d="M16.3 8.2H6.5a.4.4 0 010-.7h9.8a.4.4 0 110 .7zM16.3 10.6H6.5a.4.4 0 010-.8h9.8a.4.4 0 110 .8zM16.3 13H6.5a.4.4 0 010-.8h9.8a.4.4 0 010 .7zM11.6 15.3H6.5a.4.4 0 010-.8h5a.4.4 0 110 .8z"
    fill="#BDC3C7" />
  <path d="M15.3 20a4.1 4.1 0 100-8.3 4.1 4.1 0 000 8.3z" fill="#71C386" />
  <path d="M17.4 15.5h-1.7v-1.7a.3.3 0 00-.7 0v1.7h-1.7a.3.3 0 000 .7H15V18a.3.3 0 00.7 0v-1.7h1.7a.3.3 0 000-.7z"
    fill="#fff" />
</g>
<defs>
  <clipPath id="clip8">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>`
};
