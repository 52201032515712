import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ICompetition } from 'src/app/modules/competitions/model';
import { CompetitionLinkService } from 'src/app/shared/services/links';

import { AppBaseUrlPipe } from '../../../baseurl/pipes';

@Directive({
  selector: 'a[competition]',
  providers: [AppBaseUrlPipe]
})
export class CompetitionLinkDirective implements OnInit {
  @Input() competition: ICompetition;
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_blank';

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _competitionLinkService: CompetitionLinkService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe
  ) {}

  ngOnInit() {
    this._el.nativeElement.setAttribute('href', this.route);
    this._el.nativeElement.setAttribute('target', this.target);
  }

  private get route() {
    const link = this._competitionLinkService.generateCompetitionLink(
      this.competition
    );
    const url = this._router.serializeUrl(this._router.createUrlTree(link));

    return this._appBaseUrlPipe.transform(url);
  }
}
