<div class="ceil-item">
  <div class="ceil-logo-wrapper">
    <a [profile]="profile" class="ava">
      <app-avatar [src]="profile.icon" [placeholder]="profile | initials"></app-avatar>
    </a>
  </div>

  <div class="ceil-content">
    <div class="ceil-text">
      <a [profile]="profile">
        <div class="ceil-title">{{ profile.name | textTruncate: 30 }}</div>
      </a>
      <div class="ceil-description">{{ profile.type_name | textTruncate: 50 }}</div>
    </div>
    <div class="ceil-explore-link">
      <a *ngIf="!profile.is_friend && !profile.friend_request; else disconnectBlock" (click)="onConnect(profile); false"
        >Connect +</a
      >
      <ng-template #disconnectBlock>
        <a (click)="onDisconnect(profile); false">Disconnect</a>
      </ng-template>
    </div>
  </div>
</div>
