import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, filter, map, scan, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import {
  ICompetitionGroup,
  ICompetitionMember,
  INewCompetitionTeam
} from '../../../model';
import { AuthService } from '../../../../../core/services/auth.service';
import { CompetitionsFacade } from '../../../store';

@Component({
  selector: 'app-competition-choose-club-dialog',
  templateUrl: './competition-choose-club-dialog.component.html',
  styleUrls: ['./competition-choose-club-dialog.component.scss']
})
export class CompetitionChooseClubDialogComponent implements OnDestroy {
  readonly fields = {
    name: new FormControl('', Validators.required),
    groupId: new FormControl(null, Validators.required)
  };

  readonly team = new FormGroup(this.fields);
  private _selectedMembers: Set<number> = new Set<number>();
  private _membersPage: number;
  private _filter$: BehaviorSubject<string> = new BehaviorSubject('');
  private _destroy$ = new Subject<void>();
  public groupMembers$: Observable<ICompetitionMember[]>;

  get isLoadingMembers$() {
    return this.competitionFacade.competitionGroupsMembersIsLoading$.pipe(
      map((_) => !_)
    );
  }

  get clubs() {
    return this.data.clubs;
  }

  get isClubSelected() {
    return !!this.team.value.groupId;
  }

  constructor(
    private dialogRef: MatDialogRef<
      CompetitionChooseClubDialogComponent,
      INewCompetitionTeam
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: { clubs: ICompetitionGroup[]; competitionId: number },
    private readonly competitionFacade: CompetitionsFacade,
    private readonly auth: AuthService
  ) {
    this._filter$
      .pipe(
        takeUntil(this._destroy$),
        filter(() => !!this.team.value.groupId),
        debounceTime(300)
      )
      .subscribe((term) => this.resetMembers(this.team.value.groupId, term));
  }

  onSubmit() {
    const users = [...this._selectedMembers];

    this.dialogRef.close({
      ...this.team.value,
      users,
      admins: [this.auth.userID()]
    });
  }

  onFilterChange(e: Event) {
    const { value } = e.target as HTMLInputElement;
    this._filter$.next(value);
  }

  onScrolled() {
    this._membersPage++;
    this.competitionFacade.findMembers({
      competitionId: this.data.competitionId,
      groupId: this.team.value.groupId,
      page: this._membersPage,
      term: this._filter$.value
    });
  }

  isSelectedMember(id: number) {
    return this._selectedMembers.has(id);
  }

  onUnSelectMember(id: number) {
    this._selectedMembers.delete(id);
  }

  onSelectMember(id: number) {
    this._selectedMembers.add(id);
  }

  onChooseClub(groupId: number) {
    this._selectedMembers.clear();
    this.resetMembers(groupId, this._filter$.value);
  }

  private resetMembers(groupId: number, term?: string) {
    this.groupMembers$ = this.competitionFacade.competitionGroupsMembers$.pipe(
      scan((acc, value) => [...acc, ...value], [])
    );

    this.competitionFacade.findMembers({
      competitionId: this.data.competitionId,
      groupId,
      page: (this._membersPage = 1),
      term
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
