import { Action } from '@ngrx/store';

import {
  INewArticle,
  INewProject,
  IProject,
  IDeleteProject,
  IArticle,
  INewCompetitionProject,
  ICompetitionProject
} from '../../model';

export enum ActionTypes {
  CREATE_PROJECT = '[Projects] Create New Project',
  CREATE_PROJECT_FAILURE = '[Projects] Create New Project Failure',
  CREATE_PROJECT_SUCCESS = '[Projects] Create New Project Success',

  EDIT_PROJECT = '[Projects] Edit New Project',
  EDIT_PROJECT_FAILURE = '[Projects] Edit New Project Failure',
  EDIT_PROJECT_SUCCESS = '[Projects] Edit New Project Success',

  DELETE_PROJECT = '[Projects] Delete Project',
  DELETE_PROJECT_FAILURE = '[Projects] Delete Project Failure',
  DELETE_PROJECT_SUCCESS = '[Projects] Delete Project Success',

  CREATE_COMPETITION_PROJECT = '[Projects] Create New Competition Project',
  CREATE_COMPETITION_PROJECT_FAILURE = '[Projects] Create New Competition Project Failure',
  CREATE_COMPETITION_PROJECT_SUCCESS = '[Projects] Create New Competition Project Success',

  UPDATE_COMPETITION_PROJECT = '[Projects] Update Competition Project',
  UPDATE_COMPETITION_PROJECT_FAILURE = '[Projects] Update Competition Project Failure',
  UPDATE_COMPETITION_PROJECT_SUCCESS = '[Projects] Update Competition Project Success',

  CREATE_PROJECT_ARTICLE = '[Projects] Create New Project Article',
  CREATE_PROJECT_ARTICLE_FAILURE = '[Projects] Create New Project Article Failure',
  CREATE_PROJECT_ARTICLE_SUCCESS = '[Projects] Create New Project Article Success'
}

export class CreateProjectAction implements Action {
  readonly type = ActionTypes.CREATE_PROJECT;
  constructor(public payload: { project: INewProject }) {}
}

export class CreateProjectFailureAction implements Action {
  readonly type = ActionTypes.CREATE_PROJECT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateProjectSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_PROJECT_SUCCESS;
  constructor(public payload: { project: IProject }) {}
}

export class EditProjectAction implements Action {
  readonly type = ActionTypes.EDIT_PROJECT;
  constructor(public payload: { project: IProject }) {}
}

export class EditProjectFailureAction implements Action {
  readonly type = ActionTypes.EDIT_PROJECT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class EditProjectSuccessAction implements Action {
  readonly type = ActionTypes.EDIT_PROJECT_SUCCESS;
  constructor(public payload: { project: IProject }) {}
}

export class DeleteProjectAction implements Action {
  readonly type = ActionTypes.DELETE_PROJECT;
  constructor(public payload: IDeleteProject) {}
}

export class DeleteProjectFailureAction implements Action {
  readonly type = ActionTypes.DELETE_PROJECT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteProjectSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_PROJECT_SUCCESS;
  constructor(public payload: IDeleteProject) {}
}

export class CreateCompetitionProjectAction implements Action {
  readonly type = ActionTypes.CREATE_COMPETITION_PROJECT;
  constructor(public payload: { project: INewCompetitionProject }) {}
}

export class CreateCompetitionProjectFailureAction implements Action {
  readonly type = ActionTypes.CREATE_COMPETITION_PROJECT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateCompetitionProjectSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_COMPETITION_PROJECT_SUCCESS;
  constructor(public payload: { project: ICompetitionProject }) {}
}

export class UpdateCompetitionProjectAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_PROJECT;
  constructor(public payload: { project: INewCompetitionProject }) {}
}

export class UpdateCompetitionProjectFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_PROJECT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateCompetitionProjectSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_PROJECT_SUCCESS;
  constructor(public payload: { project: ICompetitionProject }) {}
}

export class CreateProjectArticleAction implements Action {
  readonly type = ActionTypes.CREATE_PROJECT_ARTICLE;
  constructor(public payload: { article: INewArticle }) {}
}

export class CreateProjectArticleFailureAction implements Action {
  readonly type = ActionTypes.CREATE_PROJECT_ARTICLE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateProjectArticleSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_PROJECT_ARTICLE_SUCCESS;
  constructor(public payload: { article: IArticle }) {}
}

export type Actions =
  | CreateProjectAction
  | CreateProjectFailureAction
  | CreateProjectSuccessAction
  | EditProjectAction
  | EditProjectFailureAction
  | EditProjectSuccessAction
  | DeleteProjectAction
  | DeleteProjectFailureAction
  | DeleteProjectSuccessAction
  | CreateCompetitionProjectAction
  | CreateCompetitionProjectFailureAction
  | CreateCompetitionProjectSuccessAction
  | UpdateCompetitionProjectAction
  | UpdateCompetitionProjectFailureAction
  | UpdateCompetitionProjectSuccessAction
  | CreateProjectArticleAction
  | CreateProjectArticleFailureAction
  | CreateProjectArticleSuccessAction;
