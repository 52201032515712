import { IIcon } from '../../model/icon';

export const calendar: IIcon = {
  id: `CALENDAR`,
  svg: `
  <path fill="#ECF0F1" d="M17.93 4.83v4.34l-9.05 8.42h-7.5C.62 17.59 0 16.97 0 16.2V4.83h17.93z" />
  <path fill="#95A5A5"
    d="M3.1 6.9H1.72a.34.34 0 00-.34.34v1.04c0 .19.15.34.34.34H3.1c.2 0 .35-.15.35-.34V7.24a.34.34 0 00-.35-.34zM6.9 6.9H5.52a.34.34 0 00-.35.34v1.04c0 .19.16.34.35.34H6.9c.19 0 .34-.15.34-.34V7.24a.34.34 0 00-.34-.34zM10.69 6.9H9.31a.34.34 0 00-.34.34v1.04c0 .19.15.34.34.34h1.38c.19 0 .34-.15.34-.34V7.24a.34.34 0 00-.34-.34zM6.9 10.34H5.52a.34.34 0 00-.35.35v1.03c0 .2.16.35.35.35H6.9c.19 0 .34-.16.34-.35V10.7c0-.2-.15-.35-.34-.35zM6.9 13.8H5.52a.34.34 0 00-.35.34v1.03c0 .2.16.35.35.35H6.9c.19 0 .34-.16.34-.35v-1.03a.34.34 0 00-.34-.35zM3.1 10.34H1.72a.34.34 0 00-.34.35v1.03c0 .2.15.35.34.35H3.1c.2 0 .35-.16.35-.35V10.7c0-.2-.16-.35-.35-.35zM3.1 13.8H1.72a.34.34 0 00-.34.34v1.03c0 .2.15.35.34.35H3.1c.2 0 .35-.16.35-.35v-1.03a.34.34 0 00-.35-.35z" />
  <path fill="#3B97D3" d="M13.8 20a6.2 6.2 0 100-12.41 6.2 6.2 0 000 12.41z" />
  <path fill="#2980BA" d="M13.8 19.31a5.52 5.52 0 100-11.03 5.52 5.52 0 000 11.03z" />
  <path fill="#2FA8CC" d="M18.62 13.8a4.82 4.82 0 11-9.64-.02 4.82 4.82 0 019.64.01z" />
  <path fill="#67B9CC"
    d="M15.86 11.38a4.83 4.83 0 01-6.39 4.57 4.83 4.83 0 015.88-6.72l.03.04c.32.66.48 1.38.48 2.11z" />
  <path fill="#285680"
    d="M14.14 8.98v.68a.34.34 0 01-.7 0v-.68a3.8 3.8 0 01.7 0zM11.9 10.5a.34.34 0 01-.48-.12l-.33-.58c.19-.13.39-.25.6-.35l.33.59c.1.16.04.37-.12.47zM10.5 11.9a.34.34 0 01-.46.12l-.6-.34.36-.6.58.34a.34.34 0 01.13.48zM10 13.8c0 .18-.15.34-.34.34h-.68a3.79 3.79 0 010-.7h.68c.19 0 .34.16.34.35zM10.38 16.16l-.58.34c-.13-.2-.25-.39-.35-.6l.59-.34a.35.35 0 11.34.6zM12.02 17.55l-.34.59c-.2-.1-.4-.22-.6-.35l.34-.59a.35.35 0 11.6.35zM14.14 17.93v.68a3.8 3.8 0 01-.7 0v-.68a.34.34 0 11.7 0zM16.5 17.79c-.2.13-.39.25-.6.35l-.34-.6a.35.35 0 01.6-.34l.34.59zM18.14 15.9c-.1.21-.22.4-.35.6l-.59-.34a.35.35 0 01.35-.6l.59.34zM18.62 13.8l-.01.34h-.68a.34.34 0 110-.7h.68l.01.35zM18.14 11.68l-.6.34a.35.35 0 01-.16.05.34.34 0 01-.18-.65l.59-.33c.13.19.24.39.35.6zM16.5 9.8l-.34.58a.34.34 0 01-.47.13.34.34 0 01-.13-.47l.34-.6c.21.11.4.23.6.36z" />
  <path fill="#BDC3C7"
    d="M15.17 15.52a.35.35 0 01-.24-.1l-1.38-1.38a.34.34 0 01-.07-.38l1.03-2.42a.34.34 0 01.63.28l-.94 2.2 1.22 1.2a.34.34 0 01-.25.6z" />
  <path fill="#E64C3C" d="M17.93 2.41v3.1H0v-3.1c0-.76.62-1.38 1.38-1.38h15.17c.76 0 1.38.62 1.38 1.38z" />
  <path fill="#C03A2B"
    d="M14.14 1.03c.61.24 1.02.83 1.03 1.49a1.73 1.73 0 01-3.45 0c.02-.66.42-1.25 1.04-1.49h1.38zM5.17 1.03c.62.24 1.02.83 1.04 1.49-.03.92-.8 1.65-1.73 1.62-.92.03-1.7-.7-1.72-1.62.01-.66.42-1.25 1.03-1.49h1.38z" />
  <path fill="#95A5A5"
    d="M14.14.69a.69.69 0 10-1.38 0v1.38a.69.69 0 001.38 0V.69zM5.17.69a.69.69 0 00-1.38 0v1.38a.69.69 0 001.38 0V.69z" />
  `
};
