import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, clubFeatureKey } from '../reducers';
import * as ClubInfo from '../reducers/club-info.reducer';

const clubModuleState = createFeatureSelector<State>(clubFeatureKey);
const selectClubState = createSelector(
  clubModuleState,
  (state: State) => state.clubInfo
);

export const isLoadingClubInfo = createSelector(
  selectClubState,
  ClubInfo.isLoadingClubInfo
);
export const getClubInfo = createSelector(
  selectClubState,
  ClubInfo.getClubInfo
);
