import { Action, createReducer, on, createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { InfoMessage } from './info-message.model';
import * as InfoMessageActions from './info-message.actions';

export const infoMessagesFeatureKey = 'infoMessages';

export interface State extends EntityState<InfoMessage> {}

export const adapter: EntityAdapter<InfoMessage> =
  createEntityAdapter<InfoMessage>();

export const initialState: State = adapter.getInitialState({});

export let index = 1;

const infoMessageReducer = createReducer(
  initialState,
  on(InfoMessageActions.addInfoMessage, (state, action) =>
    adapter.addOne({ ...action.infoMessage, id: index++ }, state)
  ),
  on(InfoMessageActions.deleteInfoMessage, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(InfoMessageActions.loadInfoMessages, (state, action) =>
    adapter.addAll(action.infoMessages, state)
  ),
  on(InfoMessageActions.clearInfoMessages, (state) => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
  return infoMessageReducer(state, action);
}

export const selectState = createFeatureSelector<State>(infoMessagesFeatureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectState);
