<ng-select
  [items]="list$ | async"
  bindLabel="value"
  bindValue="id"
  [multiple]="multiple"
  [clearable]="clearable"
  [(ngModel)]="selectedItems"
  (change)="onSelect()"
  [virtualScroll]="true"
  [placeholder]="selectPlaceholder"
>
</ng-select>
