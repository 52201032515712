import { Socket as NgxSocket } from 'ngx-socket-io';

import { environment } from 'src/environments/environment';

export class Socket extends NgxSocket {
  constructor(token: string, userID: string) {
    const config = {
      url: `${environment.socketEndpoint}:${environment.socketPort}`,
      // url: 'http://localhost:3001',
      options: { autoConnect: false, query: `token=${token}&user_id=${userID}` }
    };

    super(config);
  }
}
