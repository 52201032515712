import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { animationFrameScheduler, Subject } from 'rxjs';
import { filter, map, observeOn, takeUntil, tap } from 'rxjs/operators';

import { IConfig } from '../../interfaces';
import { CONFIG } from '../../fragment-focus.token';

@Directive({
  selector: '[appFragmentFocus]'
})
export class AppFragmentFocusDirective
  implements AfterViewInit, OnInit, OnDestroy
{
  private readonly fragment$ = new Subject<string>();
  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @Inject(CONFIG) private readonly _config: null | IConfig,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.fragment$
      .pipe(
        tap((_) => this.onFragment(_)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.activatedRoute.fragment
      .pipe(
        filter((_) => !!_),
        observeOn(animationFrameScheduler),
        tap((_) => this.fragment$.next(_)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.router.events
      .pipe(
        filter((_): _ is NavigationEnd => _ instanceof NavigationEnd),
        observeOn(animationFrameScheduler),
        map((_) => this.router.parseUrl(_.url).fragment),
        tap((_) => this.fragment$.next(_)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onFragment(fragment: string) {
    const anchor = this.document.querySelector<HTMLAnchorElement>(
      '#' + fragment
    );
    if (!anchor) {
      return;
    }

    const { top } = anchor.getBoundingClientRect();

    window.scrollTo({ behavior: 'smooth', top: top - this._config.top });
  }
}
