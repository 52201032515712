import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  public readonly CATEGORIES = [
    { id: 1, title: 'STEM' },
    { id: 2, title: 'Business' },
    { id: 3, title: 'Community Service' },
    { id: 4, title: 'Humanities' },
    { id: 5, title: 'Social Sciences' },
    { id: 6, title: 'Other' }
  ];
}
