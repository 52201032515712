import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { IDoc } from '../../models/doc';

export interface IDocsState extends EntityState<IDoc> {}

export const docsKey = `docs`;

export const docsAdapter = createEntityAdapter<IDoc>({
  selectId: ({ id }) => id,
  sortComparer: (a, b) => a.id - b.id
});

export const docsInitialState = docsAdapter.getInitialState({});
