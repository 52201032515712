import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[appReadMore]'
})
export class ReadMoreDirective implements AfterViewInit {
  public fullText: string = null;
  public showTag = document.createElement('span');
  public hideTag = document.createElement('span');

  @HostListener('click', ['$event']) onClick($event) {
    this.togglePost($event);
  }

  constructor(private hostRef: ElementRef<HTMLDivElement>) {}

  ngAfterViewInit(): void {
    const host = this.hostRef.nativeElement;
    const isLong = host.innerText.length > 300;
    const isNotConvered = !host.classList.contains('covered');
    const tag = document.createElement('span');

    if (isLong && isNotConvered) {
      this.showTag.classList.add('read-more');
      this.showTag.innerText = 'see more';
      this.hideTag.classList.add('hide-more');
      this.hideTag.innerText = 'hide more';

      this.fullText = host.innerHTML;

      host.innerHTML =
        this.fullText.substring(0, 300).replace(/\s$/g, '') + '...';
      host.classList.add('covered');
      tag.classList.add('read-more');
      tag.innerText = 'see more';
      host.appendChild(tag);
    }
  }

  private togglePost($event) {
    const host = this.hostRef.nativeElement;
    const isReadMore = $event.target.className === 'read-more';
    const isHideMore = $event.target.className === 'hide-more';
    const isConvered = host.classList.contains('covered');

    if (isConvered && isReadMore) {
      host.classList.remove('covered');
      host.querySelector('.read-more').remove();
      host.innerHTML = this.fullText;
      // LinkPreviewDirective.openedEl.next(this.hostRef);
      host.appendChild(this.hideTag);
    } else if (isHideMore) {
      host.innerHTML =
        host.innerHTML.substring(0, 300).replace(/\s$/g, '') + '...';
      host.classList.add('covered');
      host.appendChild(this.showTag);
    }
  }
}
