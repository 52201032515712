<div class="dialog-content-overlay">
  <button class="btn-close" (click)="closeDialog(false); false"></button>
  <h1 mat-dialog-title>{{data.customTitle? data.customTitle : 'New message'}}</h1>
  <div mat-dialog-content class="mb-4">
    <div class="d-flex flex-wrap">
      <div class="author d-flex align-items-center">
        <app-avatar [src]="data.contact.icon" [placeholder]="data.contact | initials" class="little right"></app-avatar>
        <div>
          <div class="name d-flex align-items-center"> {{ data.contact.name }} </div>
          <div class="status"> {{ data.contact.online_str }} </div>
        </div>
      </div>
      <a
        [routerLink]="['messages']"
        [queryParams]="{id: data.receiverID}"
        (click)="closeDialog(false)"
        *ngIf="data.receiverID"
        class="go-chat-link mt-auto ml-auto"
        >Go to dialogue with {{ data.contact.name }}</a
      >
    </div>

    <div class="message-wrapper">
      <!-- <input name="message" placeholder="Message" contenteditable #Text (input)="onTextChange()"
        (keydown)="onKeyDown($event)" /> -->
      <textarea
        name="message"
        placeholder="Message"
        (change)="onTextChange()"
        (keydown)="onKeyDown($event)"
        #Text
      ></textarea>

      <div class="d-flex">
        <div class="btns d-flex align-items-center ml-auto">
          <button (click)="onSelectAsset('DOCS'); false">
            <svg viewBox="0 0 20 20">
              <use href="#files" />
            </svg>
          </button>
          <button (click)="onSelectAsset('IMAGES'); false">
            <svg viewBox="0 0 20 20">
              <use href="#photo" />
            </svg>
          </button>
          <button (click)="onSelectAsset('EMOJIS'); false">
            <svg viewBox="0 0 20 20">
              <use href="#smile" />
            </svg>
          </button>
        </div>
      </div>
      <section class="assets" [ngSwitch]="asset">
        <app-message-docs *ngSwitchCase="'DOCS'" [ctx]="docs" [editable]="editable" class="asset"></app-message-docs>
        <app-message-images *ngSwitchCase="'IMAGES'" [ctx]="images" [editable]="editable" class="asset">
        </app-message-images>
        <app-emoji *ngSwitchCase="'EMOJIS'" class="asset" (select)="onEmojiSelection($event)"></app-emoji>
      </section>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="closeDialog(true); false">Send</button>
  </div>

  <!-- <app-message [ctx]="{}" class="message"></app-message> -->
</div>
