import { createAction, props } from '@ngrx/store';

import { Request, Group, User } from './group.model';

export const resetGroupInfo = createAction('[Groups/API] Reset Group Info');

export const loadGroupInfo = createAction(
  '[Groups/API] Load Group Info',
  props<{ id: number }>()
);

export const loadGroupInfoSuccess = createAction(
  '[Groups/API] Load Group Info Success',
  props<{ group: Group }>()
);

export const resetGroupRequests = createAction(
  '[Groups/API] Reset Group Requests'
);

export const loadGroupRequests = createAction(
  '[Groups/API] Load Group Requests',
  props<{ id: number; page: number; searchTerm: string }>()
);

export const loadGroupRequestsSuccess = createAction(
  '[Groups/API] Load Group Requests Success',
  props<{ requests: { [id: number]: Request }; count: number }>()
);

export const resetGroupMembers = createAction(
  '[Groups/API] Reset Group Members'
);

export const loadGroupMembers = createAction(
  '[Groups/API] Load Group Members',
  props<{ id: number; page: number; searchTerm: string }>()
);

export const loadGroupMembersSuccess = createAction(
  '[Groups/API] Load Group Members Success',
  props<{ members: { [id: number]: User }; count: number }>()
);

export const subscribeGroup = createAction(
  '[Groups/API] Group Subscribe',
  props<{ group_id: number; user_id: number; text?: string }>()
);

export const subscribeGroupSuccess = createAction(
  '[Groups/API] Group Subscribe Success',
  props<{ group_id: number; user_id: number }>()
);

export const unsubscribeGroup = createAction(
  '[Groups/API] Group Un-Subscribe',
  props<{ group_id: number; user_id: number }>()
);

export const unsubscribeGroupSuccess = createAction(
  '[Groups/API] Group Un-Subscribe Success',
  props<{ group_id: number; user_id: number }>()
);

export const unrequestGroup = createAction(
  '[Groups/API] Group Un-Request',
  props<{ group_id: number; user_id: number }>()
);

export const unrequestGroupSuccess = createAction(
  '[Groups/API] Group Un-Request Success',
  props<{ group_id: number; user_id: number }>()
);

export const accessGroup = createAction(
  '[Groups/API] Access Group',
  props<{ group_id: number; user: User }>()
);

export const accessGroupSuccess = createAction(
  '[Groups/API] Access Group Success',
  props<{ group_id: number; user: User }>()
);

export const unaccessGroup = createAction(
  '[Groups/API] Unaccess Group',
  props<{ group_id: number; user: User }>()
);

export const unaccessGroupSuccess = createAction(
  '[Groups/API] Unaccess Group Success',
  props<{ group_id: number; user: User }>()
);

export const clearGroup = createAction('[Groups/API] Clear Group');

export const updateGroupInfo = createAction(
  '[Groups/API] Update Group Info',
  props<{ id: number; group: any }>()
);

export const updateGroupInfoSuccess = createAction(
  '[Groups/API] Update Group Info Success',
  props<{ group: any }>()
);

export const removeGroup = createAction(
  '[Groups/API] Remove Group',
  props<{ id: number }>()
);

export const removeGroupSuccess = createAction(
  '[Groups/API] Remove Group Success',
  props<{ id: number }>()
);
