import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { NoticeCounterFacade } from 'src/app/root-store';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMenuComponent {
  @Input() user: any;

  @Output() onLogout: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly noticeCounterFacade: NoticeCounterFacade) {}

  get amountOfNetworkRequests$() {
    return this.noticeCounterFacade.networkRequests$;
  }

  logout() {
    this.onLogout.emit();
  }
}
