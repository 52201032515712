import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';

import {
  IChannelDetails,
  IChannelMember,
  IGroupChatMember
} from '../../../model';

@Component({
  selector: 'app-chat-edit-group-chat-channel-dialog',
  templateUrl: './edit-group-chat-channel-dialog.component.html',
  styleUrls: ['./edit-group-chat-channel-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGroupChatChannelDialogComponent {
  private _selectedParticipants = new Map<number, boolean>();

  readonly fields = {
    name: new FormControl(null, Validators.required)
  };

  readonly groupChatChannelForm = new FormGroup(this.fields);

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<EditGroupChatChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: EditGroupChatChannelDialogComponent.Data
  ) {}

  ngOnInit() {
    this.data.channel$
      .pipe(
        filter((_) => !!_),
        take(1),
        tap((_) => this.groupChatChannelForm.patchValue({ name: _.title }))
      )
      .subscribe((_) => _);

    this.data.channelMembers$
      .pipe(
        filter((_) => !!_),
        take(1),
        tap((_) =>
          _?.forEach((_) => this._selectedParticipants.set(_.id, true))
        )
      )
      .subscribe((_) => _);
  }

  get participants$() {
    return this.data.chatParticipants$.pipe(
      filter((_) => !!_),
      map((_) => _.filter((_) => !this.authService.isMyId(_.id)))
    );
  }

  get isLoadingChatParticipants$() {
    return this.data.isLoadingChatParticipants$;
  }

  isSelectedContact(id: number) {
    return this._selectedParticipants.get(id);
  }

  onSelectContact(id: number) {
    this._selectedParticipants.set(id, true);
  }

  onUnSelectContact(id: number) {
    this._selectedParticipants.set(id, false);
  }

  onSave() {
    const membersToAdd = [...this._selectedParticipants.entries()]
      .filter((_) => _[1])
      .map((_) => _[0]);

    const membersToRemove = [...this._selectedParticipants.entries()]
      .filter((_) => !_[1])
      .map((_) => _[0]);

    this.dialogRef.close({
      title: this.groupChatChannelForm.value.name,
      membersToAdd,
      membersToRemove
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}

export namespace EditGroupChatChannelDialogComponent {
  export interface Data {
    chatParticipants$: Observable<IGroupChatMember[]>;
    isLoadingChatParticipants$: Observable<boolean>;
    channelMembers$: Observable<IChannelMember[]>;
    channel$: Observable<IChannelDetails>;
  }
}
