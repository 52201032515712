<input
  type="file"
  [accept]="types"
  [multiple]="count === 'MANY'"
  class="file-select"
  (change)="onChange($event.target.files)"
  #Selector
/>

<button class="button" [disabled]="disabled" (click)="onSelect()">
  <ng-content></ng-content>
</button>
