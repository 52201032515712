import { Component, Input } from '@angular/core';
import { IMessage } from 'src/app/modules/chat/model';

@Component({
  selector: 'app-chat-channel-last-message',
  templateUrl: './channel-last-message.component.html',
  styleUrls: ['./channel-last-message.component.scss']
})
export class ChannelLastMessage {
  @Input() message: IMessage;
}
