import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConvertNameService } from 'src/app/shared/services/convert-name.service';
import { GroupLinkService } from 'src/app/shared/services/links';

import { AppBaseUrlPipe } from '../../../baseurl/pipes';

@Directive({
  selector: 'a[group]',
  providers: [AppBaseUrlPipe]
})
export class GroupLinkDirective implements OnInit {
  @Input() group: { id: number; name: string };
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_blank';

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _convertNameService: ConvertNameService,
    private readonly _groupLinkService: GroupLinkService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe
  ) {}

  ngOnInit(): void {
    this._el.nativeElement.setAttribute('href', this.route);
    this._el.nativeElement.setAttribute('target', this.target);
  }

  private get route() {
    const link = this._groupLinkService.generateGroupLink({
      id: this.group.id,
      name: this._convertNameService.convertName(this.group.name)
    });

    const url = this._router.serializeUrl(this._router.createUrlTree(link));

    return this._appBaseUrlPipe.transform(url);
  }
}
