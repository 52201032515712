import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DogToLinkPipe,
  LinksPipe,
  Nl2brPipe,
  SafeUserInputPipe,
  SanitizeAttributesPipe
} from './pipes';

@NgModule({
  declarations: [
    DogToLinkPipe,
    LinksPipe,
    Nl2brPipe,
    SanitizeAttributesPipe,
    SafeUserInputPipe
  ],
  imports: [CommonModule],
  exports: [
    DogToLinkPipe,
    LinksPipe,
    Nl2brPipe,
    SanitizeAttributesPipe,
    SafeUserInputPipe
  ],
  providers: [DogToLinkPipe, LinksPipe, Nl2brPipe, SanitizeAttributesPipe]
})
export class AppSafeUserInputModule {}
