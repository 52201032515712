import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-profile-badge',
  templateUrl: './profile-badge.component.html',
  styleUrls: ['./profile-badge.component.scss']
})
export class ProfileBadgeComponent {
  @Input() profile: any;

  @Output() connect = new EventEmitter<number>();
  @Output() disconnect = new EventEmitter<number>();

  onConnect(profile) {
    this.connect.emit(profile.id);
  }

  onDisconnect(profile) {
    this.disconnect.emit(profile.id);
  }
}
