import { Action } from '@ngrx/store';

import { IChannel, IEditChannel, INewChannel } from '../../model';

export enum ActionTypes {
  NEW_CHANNEL = '[Channel] New Channel',
  NEW_CHANNEL_FAILURE = '[Channel] New Channel Failure',
  NEW_CHANNEL_SUCCESS = '[Channel] New Channel Success',

  READ_CHANNEL = '[Channel] Read Channel',
  READ_CHANNEL_FAILURE = '[Channel] Read Channel Failure',
  READ_CHANNEL_SUCCESS = '[Channel] Read Channel Success',

  LEAVE_CHANNEL = '[Channel] Leave Channel',
  LEAVE_CHANNEL_FAILURE = '[Channel] Leave Channel Failure',
  LEAVE_CHANNEL_SUCCESS = '[Channel] Leave Channel Success',
  // the separate event in order to distinguish own user leave
  // from the another user
  ME_LEAVE_CHANNEL_SUCCESS = '[Channel] Me Leave Channel Success',

  EDIT_CHANNEL = '[Channel] Edit Channel',
  EDIT_CHANNEL_FAILURE = '[Channel] Edit Channel Failure',
  EDIT_CHANNEL_SUCCESS = '[Channel] Edit Channel Success',

  DELETE_CHANNEL = '[Channel] Delete Channel',
  DELETE_CHANNEL_FAILURE = '[Channel] Delete Channel Failure',
  DELETE_CHANNEL_SUCCESS = '[Channel] Delete Channel Success'
}

export class ReadChannelAction implements Action {
  readonly type = ActionTypes.READ_CHANNEL;
  constructor(public payload: { chatId: number; channelId: number }) {}
}

export class ReadChannelSuccessAction implements Action {
  readonly type = ActionTypes.READ_CHANNEL_SUCCESS;
  constructor(public payload: { chatId: number; channelId: number }) {}
}

export class ReadChannelFailureAction implements Action {
  readonly type = ActionTypes.READ_CHANNEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class NewChannelAction implements Action {
  readonly type = ActionTypes.NEW_CHANNEL;
  constructor(public payload: { channel: INewChannel }) {}
}

export class NewChannelSuccessAction implements Action {
  readonly type = ActionTypes.NEW_CHANNEL_SUCCESS;
  constructor(public payload: { channel: IChannel }) {}
}

export class NewChannelFailureAction implements Action {
  readonly type = ActionTypes.NEW_CHANNEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteChannelAction implements Action {
  readonly type = ActionTypes.DELETE_CHANNEL;
  constructor(public payload: { chatId: number; channelId: number }) {}
}

export class DeleteChannelSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_CHANNEL_SUCCESS;
  constructor(public payload: { channel: IChannel }) {}
}

export class DeleteChannelFailureAction implements Action {
  readonly type = ActionTypes.DELETE_CHANNEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class EditChannelAction implements Action {
  readonly type = ActionTypes.EDIT_CHANNEL;
  constructor(public payload: { channel: IEditChannel }) {}
}

export class EditChannelSuccessAction implements Action {
  readonly type = ActionTypes.EDIT_CHANNEL_SUCCESS;
  constructor(public payload: { channel: IChannel }) {}
}

export class EditChannelFailureAction implements Action {
  readonly type = ActionTypes.EDIT_CHANNEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LeaveChannelAction implements Action {
  readonly type = ActionTypes.LEAVE_CHANNEL;
  constructor(public payload: { chatId: number; channelId: number }) {}
}

export class LeaveChannelSuccessAction implements Action {
  readonly type = ActionTypes.LEAVE_CHANNEL_SUCCESS;
  constructor(public payload: { channel: IChannel; userId: number }) {}
}

export class MeLeaveChannelSuccessAction implements Action {
  readonly type = ActionTypes.ME_LEAVE_CHANNEL_SUCCESS;
  constructor(public payload: { channel: IChannel; userId: number }) {}
}

export class LeaveChannelFailureAction implements Action {
  readonly type = ActionTypes.LEAVE_CHANNEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | ReadChannelAction
  | ReadChannelSuccessAction
  | ReadChannelFailureAction
  | NewChannelAction
  | NewChannelSuccessAction
  | NewChannelFailureAction
  | DeleteChannelAction
  | DeleteChannelSuccessAction
  | DeleteChannelFailureAction
  | EditChannelAction
  | EditChannelSuccessAction
  | EditChannelFailureAction
  | LeaveChannelAction
  | LeaveChannelSuccessAction
  | MeLeaveChannelSuccessAction
  | LeaveChannelFailureAction;
