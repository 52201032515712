import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clearAgeGroup'
})
export class ClearAgeGroupPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\syears$/g, '');
  }
}
