import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { switchMap, filter, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { FBQService } from 'src/app/shared/services/fbq.service';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  AppleAuthService,
  GoogleAuthService
} from 'src/app/modules/registration/shared/services';
import { RegistrationFacade } from 'src/app/modules/registration/store';
import { PARRENT_MARK } from 'src/app/registration/mappers';
import {
  LS_EDUCATOR,
  LS_ORGANIZATION,
  LS_STUDENT
} from 'src/app/core/constants/localStorage';
import { LocalStorage } from 'src/app/core/storage/interfaces/local-storage.interface';

@Component({
  selector: 'app-sign-up-dialog',
  templateUrl: './sign-up-dialog.component.html',
  styleUrls: ['./sign-up-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSignUpDialogComponent implements OnInit, OnDestroy {
  public isTick = false;
  readonly types = {
    Parent: 'parent',
    Student: 'student',
    Educator: 'teacher',
    Organization: 'org'
  };

  readonly fields = {
    email: new FormControl(null, [Validators.required, Validators.email]),
    type: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(6)
    ]),
    password_repeat: new FormControl(null, [Validators.required]),
    terms: new FormControl(false, [Validators.required])
  };

  readonly form = new FormGroup(this.fields, [
    () => this.checkPasswords(),
    () => this.checkTerms()
  ]);

  public error = ``;

  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: AppSignUpDialogComponent.IData,
    private readonly _router: Router,
    private readonly _dialogRef: MatDialogRef<AppSignUpDialogComponent>,
    private readonly _localStorage: LocalStorage,
    private readonly _authService: AuthService,
    private readonly _fbqService: FBQService,
    private readonly _appleAuthService: AppleAuthService,
    private readonly _googleAuthService: GoogleAuthService,
    private readonly _registrationFacade: RegistrationFacade
  ) {}

  ngOnInit(): void {
    if (this._data) {
      this.fields.type.setValue(this._data.accountType);
    }

    this._fbqService.trackCompleteRegistration({
      name: FBQService.CompleteRegistrationContent.EMAIL_MODAL
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onSubmit() {
    const form = this.form.getRawValue();
    this._authService.clearCache();
    this._authService
      .registration(form)
      .pipe(
        switchMap((res: any) => {
          if (res.status === `error`) {
            this.error = res.message.text;
            this.isTick = !this.isTick;
            return new Observable();
          }

          return this._authService.authToken({
            email: form.email,
            password: form.password
          });
        })
      )
      .subscribe(
        () => {
          let ref: string = form.type;

          if (ref === 'teacher') ref = LS_EDUCATOR;
          if (ref === 'org') ref = LS_ORGANIZATION;
          if (ref === 'parent') {
            this._localStorage.setItem(PARRENT_MARK, `true`);
            ref = LS_STUDENT;
          }

          this._localStorage.setItem(ref, `null`);
          this._router.navigate([`registration`, ref]);
          this._dialogRef.close();
        },
        (err) => {
          alert(err.message);
        }
      );
  }

  onAppleSignin() {
    this._appleAuthService
      .login()
      .pipe(
        filter((_) => !!_),
        tap((_) => this._registrationFacade.appleAuth(_)),
        tap((_) => this._dialogRef.close())
      )
      .subscribe();
  }

  onGoogleSignin() {
    this._googleAuthService
      .login()
      .pipe(
        filter((_) => !!_),
        tap((_) => this._registrationFacade.googleAuth(_)),
        tap((_) => this._dialogRef.close())
      )
      .subscribe();
  }

  onClose() {
    this._dialogRef.close();
  }

  protected checkPasswords() {
    const pass = this.fields.password;
    const pass_repeat = this.fields.password_repeat;
    const isNotEqual = pass.value !== pass_repeat.value;
    const isTouched = pass.dirty && pass_repeat.dirty;

    if (isTouched && isNotEqual) {
      return { notEqual: true };
    }

    return {};
  }

  checkTerms() {
    if (!this.fields.terms.value) {
      return { notConfirmed: true };
    }
    return {};
  }
}

export namespace AppSignUpDialogComponent {
  export interface IData {
    accountType: 'parent' | 'student' | 'teacher' | 'org';
  }
}
