import { Action } from '@ngrx/store';

import {
  IAppleAuthData,
  IAttachToEmailData,
  IAuthData,
  IAuthSuccessData,
  IGoogleAuthData,
  IPasswordlessAuthData,
  IRegistrationStudentData
} from '../../model';

export enum ActionTypes {
  REGISTRATION_RESET = '[Registration] Reset',
  REGISTRATION_CANCEL = '[Registration] Cancel',

  APPLE_AUTH = '[Registration] Apple Auth',
  APPLE_AUTH_SUCCESS = '[Registration] Apple Auth Success',
  APPLE_AUTH_FAILURE = '[Registration] Apple Auth Failure',

  GOOGLE_AUTH = '[Registration] Google Auth',
  GOOGLE_AUTH_SUCCESS = '[Registration] Google Auth Success',
  GOOGLE_AUTH_FAILURE = '[Registration] Google Auth Failure',

  PASSWORDLESS_AUTH = '[Registration] Passwordless Registration',
  PASSWORDLESS_AUTH_SUCCESS = '[Registration] Passwordless Registration Success',
  PASSWORDLESS_AUTH_FAILURE = '[Registration] Passwordless Registration Failure',

  ATTACH_TO_EMAIL = '[Registration] Attach To Email',
  ATTACH_TO_EMAIL_SUCCESS = '[Registration] Attach To Email Success',
  ATTACH_TO_EMAIL_FAILURE = '[Registration] Attach To Email Failure',

  AUTH_DATA = '[Registration] Auth Data',
  AUTH_DATA_SUCCESS = '[Registration] Auth Data Success',
  AUTH_DATA_FAILURE = '[Registration] Auth Data Failure',

  STUDENT_REGISTRATION = '[Registration] Student Registration',
  STUDENT_REGISTRATION_SUCCESS = '[Registration] Student Registration Success',
  STUDENT_REGISTRATION_FAILURE = '[Registration] Student Registration Failure'
}

export class RegistrationResetAction implements Action {
  readonly type = ActionTypes.REGISTRATION_RESET;
}

export class RegistrationCancelAction implements Action {
  readonly type = ActionTypes.REGISTRATION_CANCEL;
}

export class AppleAuthRegistrationAction implements Action {
  readonly type = ActionTypes.APPLE_AUTH;
  constructor(public payload: IAppleAuthData) {}
}

export class AppleAuthRegistrationSuccessAction implements Action {
  readonly type = ActionTypes.APPLE_AUTH_SUCCESS;
  constructor(public payload: IAuthSuccessData) {}
}

export class AppleAuthRegistrationFailureAction implements Action {
  readonly type = ActionTypes.APPLE_AUTH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class GoogleAuthRegistrationAction implements Action {
  readonly type = ActionTypes.GOOGLE_AUTH;
  constructor(public payload: IGoogleAuthData) {}
}

export class GoogleAuthRegistrationSuccessAction implements Action {
  readonly type = ActionTypes.GOOGLE_AUTH_SUCCESS;
  constructor(public payload: IAuthSuccessData) {}
}

export class GoogleAuthRegistrationFailureAction implements Action {
  readonly type = ActionTypes.GOOGLE_AUTH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class PasswordlessAuthRegistrationAction implements Action {
  readonly type = ActionTypes.PASSWORDLESS_AUTH;
  constructor(public payload: IPasswordlessAuthData) {}
}

export class PasswordlessAuthRegistrationSuccessAction implements Action {
  readonly type = ActionTypes.PASSWORDLESS_AUTH_SUCCESS;
  constructor(public payload: IAuthSuccessData) {}
}

export class PasswordlessAuthRegistrationFailureAction implements Action {
  readonly type = ActionTypes.PASSWORDLESS_AUTH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AttachToEmailRegistrationAction implements Action {
  readonly type = ActionTypes.ATTACH_TO_EMAIL;
  constructor(public payload: IAttachToEmailData) {}
}

export class AttachToEmailRegistrationSuccessAction implements Action {
  readonly type = ActionTypes.ATTACH_TO_EMAIL_SUCCESS;
}

export class AttachToEmailRegistrationFailureAction implements Action {
  readonly type = ActionTypes.ATTACH_TO_EMAIL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AuthDataRegistrationAction implements Action {
  readonly type = ActionTypes.AUTH_DATA;
}

export class AuthDataRegistrationSuccessAction implements Action {
  readonly type = ActionTypes.AUTH_DATA_SUCCESS;
  constructor(public payload: null | IAuthData) {}
}

export class AuthDataRegistrationFailureAction implements Action {
  readonly type = ActionTypes.AUTH_DATA_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class StudentRegistrationAction implements Action {
  readonly type = ActionTypes.STUDENT_REGISTRATION;
  constructor(public payload: IRegistrationStudentData) {}
}

export class StudentRegistrationSuccessAction implements Action {
  readonly type = ActionTypes.STUDENT_REGISTRATION_SUCCESS;
}

export class StudentRegistrationFailureAction implements Action {
  readonly type = ActionTypes.STUDENT_REGISTRATION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | RegistrationResetAction
  | RegistrationCancelAction
  | AppleAuthRegistrationAction
  | AppleAuthRegistrationSuccessAction
  | AppleAuthRegistrationFailureAction
  | GoogleAuthRegistrationAction
  | GoogleAuthRegistrationSuccessAction
  | GoogleAuthRegistrationFailureAction
  | PasswordlessAuthRegistrationAction
  | PasswordlessAuthRegistrationSuccessAction
  | PasswordlessAuthRegistrationFailureAction
  | AuthDataRegistrationAction
  | AuthDataRegistrationSuccessAction
  | AuthDataRegistrationFailureAction
  | StudentRegistrationAction
  | StudentRegistrationSuccessAction
  | StudentRegistrationFailureAction;
