import { IIcon } from '../../model/icon';

export const mail: IIcon = {
  id: `MAIL`,
  svg: `
  <g clip-path="url(#clip2)">
  <path
    d="M28.3 1.6c-1-1-2.5-1.6-4-1.6H5.7A5.7 5.7 0 000 5.7V19a5.7 5.7 0 005.7 5.7h14.5c.2 0 .3 0 .4.2l4.4 4.8a.5.5 0 00.9-.4l-.7-4.2c0-.2.1-.5.4-.5A5.8 5.8 0 0030 19V5.7c0-1.5-.6-3-1.7-4z"
    fill="#F29C1F" />
  <path d="M30 5.7V19c0 1.6-.6 3-1.7 4L17 12.5 28.3 1.6c1 1.1 1.7 2.6 1.7 4z" fill="#F0C419" />
  <path
    d="M28.3 1.6L17.1 12.4l-.3.3c-1 1-2.6 1-3.6 0l-.3-.3-9.7-9.3-1.5-1.5c1-1 2.5-1.6 4-1.6h18.6c1.5 0 3 .6 4 1.6z"
    fill="#F3D55B" />
  <path d="M12.9 12.4L1.7 23.2c-1-1-1.7-2.5-1.7-4V5.6c0-1.5.6-3 1.7-4L3.2 3l9.7 9.3z" fill="#F0C419" />
  <path d="M14.5 2.6H7.2a.5.5 0 110-1h7.3a.5.5 0 010 1zM5.2 2.6a.5.5 0 100-1 .5.5 0 000 1z" fill="#fff" />
</g>
<defs>
  <clipPath id="clip2">
    <path fill="#fff" d="M0 0h30v30H0z" />
  </clipPath>
</defs>
  `
};
