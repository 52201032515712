import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CompetitionStoreEffects } from './effects';
import { CompetitionsService } from './service';
import { CompetitionsFacade } from './facade';
import { competitionsFeatureKey, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(competitionsFeatureKey, reducers),
    EffectsModule.forFeature([CompetitionStoreEffects])
  ],
  providers: [CompetitionStoreEffects, CompetitionsService, CompetitionsFacade]
})
export class CompetitionsStoreModule {}
