import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

// TODO: move page logic to this component, listen to router query params and do pagination stuff
// TODO: consider to use this component over app-paginator wrapper

@Component({
  selector: 'app-page-counter',
  templateUrl: './page-counter.component.html',
  styleUrls: ['./page-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageCounterComponent {
  @Input() currentPage: number;
  @Input() amountOfPages: number;

  @Output() setPage = new EventEmitter<number>();

  constructor() {}

  onChangePageNumber(page) {
    this.currentPage = this.convertPageToNumber(page);
  }

  onSetPage() {
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }

    this.setPage.emit(
      this.amountOfPages < this.currentPage
        ? this.amountOfPages
        : this.currentPage
    );
  }

  onNextPage() {
    this.currentPage =
      this.amountOfPages < this.currentPage + 1
        ? this.amountOfPages
        : this.currentPage + 1;

    this.onSetPage();
  }

  onPrevPage() {
    this.currentPage = this.currentPage - 1 < 1 ? 1 : this.currentPage - 1;

    this.onSetPage();
  }

  private convertPageToNumber(page: any) {
    const nPage = Number(page);

    if (Number.isSafeInteger(nPage)) {
      return nPage;
    }

    return 1;
  }
}
