import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { IMessage } from 'src/app/shared/components/message/message.component';
import { IPostForm } from 'src/app/shared/models/post';
import { AuthService } from '../auth.service';
import { BaseService } from '../base.service';
import { Club, File, Photo } from './club.model';
import { Observable } from 'rxjs';
import { APIService } from '../../../shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ClubsService {
  constructor(
    private base: BaseService,
    private auth: AuthService,
    private readonly apiService: APIService
  ) {}

  getClubs(page: number, categoryID: number) {
    const urlParams = new URLSearchParams({
      page: page ? page + '' : undefined,
      category_id: categoryID ? categoryID + '' : undefined
    });

    return this.apiService
      .get<{ clubs: Club; count: number }>(`clubs?${urlParams}`)
      .pipe(map((_) => ({ groups: _.clubs, count: _.count })));
  }

  sendInvitation(id: number, email: string) {
    return this.base.post(`group/invite/${id}`, { email });
  }

  photos(id: number, page: number) {
    const urlParams = new URLSearchParams({
      page: page + ''
    });

    const url = this.auth.isFullyAuthorized
      ? `group/fotos/${id}?${urlParams}`
      : `guest/club-fotos/${id}?${urlParams}`;

    return this.base
      .get(url)
      .pipe(map((res) => ({ count: res.count, photos: res.fotos })));
  }

  files(id: number, page: number) {
    const url = this.auth.isFullyAuthorized
      ? `group/files/${id}`
      : `guest/club-files/${id}`;

    return this.base
      .get(url)
      .pipe(map((res) => ({ count: res.count, files: res.files })));
  }

  addPhotos(id: number, photos: Photo[]) {
    return this.base
      .post(`group/add-foto/${id}`, {
        foto: photos.map((photo) => photo.id)
      })
      .pipe(map((res) => ({ count: res.count, photos: res.fotos })));
  }

  addFiles(id: number, files: File[]) {
    return this.base
      .post(`group/add-file/${id}`, {
        file: files.map((file) => file.id)
      })
      .pipe(map((res) => ({ count: res.count, files: res.files })));
  }

  removePhoto(id: number, photoID: number) {
    return this.base.post(`group/del-foto/${id}`, {
      foto: [photoID]
    });
  }

  removeFile(id: number, fileID: number) {
    return this.base.post(`group/del-file/${id}`, {
      file: [fileID]
    });
  }

  loadResources(id: number, page: number) {
    const urlParams = new URLSearchParams({
      page: page ? page + '' : undefined
    });

    const url = this.auth.isFullyAuthorized
      ? `resource/${id}?${urlParams}`
      : `guest/club-resources/${id}?${urlParams}`;

    return this.base
      .get(url)
      .pipe(map((res) => ({ resources: res.resources })));
  }

  postResource(id: number, message: IMessage) {
    // dirty hack to handle empty array within form fields request
    // hopefully would be adjusted to normal json objects one day...
    const links =
      message.linksToPreview?.length > 0 ? message.linksToPreview : [''];

    return this.base
      .post(`resource/${id}`, {
        description: message.text,
        links,
        foto: message.images,
        file: message.docs
      })
      .pipe(map((res) => ({ post: res.resource })));
  }

  likeResource(id) {
    return this.base
      .get(`post/like/${id}`)
      .pipe(map((response: any) => response.like));
  }

  updateResource(resourceID: number, post: IPostForm) {
    return this.base
      .post(`post/save/${resourceID}`, { ...post, share: resourceID })
      .pipe(
        tap((_) => console.log('res 2: ', _)),
        map((response: any) => ({ post: response.post }))
      );
  }

  complainToResource(resourceID: number) {
    return this.base
      .get(`post/complain/${resourceID}`)
      .pipe(map((response: any) => response));
  }

  deleteResource(resourceID: number) {
    return this.base.get(`post/del/${resourceID}`);
  }

  saveResource(resourceID: number) {
    return this.base.get(`saved-posts/add/${resourceID}`);
  }

  unsaveResource(resourceID: number) {
    return this.base.get(`saved-posts/del/${resourceID}`);
  }

  searchClubs(params): Observable<{ count: number; clubs: Club[] }> {
    return this.search('clubs', params).pipe(
      map((_) => ({ count: _.count, clubs: _.clubs }))
    );
  }

  private search(kind, params) {
    console.log('kinds: ', kind, params);

    const requestParams = { ...params };
    const { q, page } = requestParams;

    delete requestParams['q'];

    const urlParams = new URLSearchParams({
      ...params,
      text: q ? q : '',
      page
    });

    return this.base.get(`search/${kind}?${urlParams}`);
  }
}
