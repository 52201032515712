import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import { Club } from './club.model';
import * as ClubsActions from './clubs.actions';

export const clubFeatureKey = 'clubs';

export interface State {
  clubs: { [id: string]: Club };
  selectedClubID: null | number;
  count: number;
}

export const initialState: State = {
  clubs: null,
  selectedClubID: null,
  count: null
};

export const reducer = createReducer(
  initialState,
  on(ClubsActions.selectClub, (state, action): State => {
    const clubs = state.clubs || {};

    const currentClub = clubs[action.club.id] ? clubs[action.club.id] : {};

    return {
      ...state,
      clubs: {
        ...clubs,
        [action.club.id]: {
          resources_status: 'INITIAL',
          ...action.club,
          ...currentClub
        }
      },
      selectedClubID: action.club.id
    };
  }),
  on(ClubsActions.unselectClub, (state, action): State => {
    return {
      ...state,
      clubs: null,
      selectedClubID: null
    };
  }),
  on(ClubsActions.loadClubPhotosSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const newPhotos = {
      ...(state.clubs[action.id].photos
        ? { ...state.clubs[action.id].photos }
        : {}),
      ...action.photos
    };

    const club = {
      ...state.clubs[action.id],
      photos: newPhotos,
      countPhotos: action.count
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.loadClubFilesSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const club = {
      ...state.clubs[action.id],
      files: action.files,
      countFiles: action.count
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.loadResources, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const club = {
      ...state.clubs[action.id],
      resources_status: 'LOADING'
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.loadResourcesSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const currentResources = state.clubs[action.id].resources
      ? { ...state.clubs[action.id].resources }
      : {};

    const club = {
      ...state.clubs[action.id],
      resources: {
        ...currentResources,
        ...action.resources
      }
    };

    club.resources_status =
      Object.keys(club.resources).length > 0 ? 'READY' : 'EMPTY';

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(
    ClubsActions.loadResourcesSuccessNoPermissionsError,
    (state, action): State => {
      if (!state.clubs || !state.clubs[action.id]) {
        return state;
      }

      const club = {
        resources: null,
        resources_status: 'NO_PERMISSIONS'
      };

      return {
        ...state,
        clubs: {
          ...state.clubs,
          [action.id]: club
        }
      };
    }
  ),
  on(ClubsActions.postResourceSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const currentResources = state.clubs[action.id].resources
      ? state.clubs[action.id].resources
      : {};

    const club = {
      ...state.clubs[action.id],
      resources: {
        ...currentResources,
        [action.post.id]: action.post
      }
    };

    club.resources_status =
      Object.keys(club.resources).length > 0 ? 'READY' : 'EMPTY';

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.likeResourceSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const currentResources = state.clubs[action.id].resources
      ? { ...state.clubs[action.id].resources }
      : {};
    const resource = currentResources[action.resourceID]
      ? { ...currentResources[action.resourceID] }
      : {};

    resource.likes = { count: action.amountOfLikes };

    const club = {
      ...state.clubs[action.id],
      resources: {
        ...currentResources,
        [action.resourceID]: resource
      }
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.saveResourceSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const currentResources = state.clubs[action.id].resources
      ? { ...state.clubs[action.id].resources }
      : {};
    const resource = currentResources[action.resourceID]
      ? { ...currentResources[action.resourceID] }
      : {};

    resource.is_saved = true;

    const club = {
      ...state.clubs[action.id],
      resources: {
        ...currentResources,
        [action.resourceID]: resource
      }
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.unsaveResourceSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const currentResources = state.clubs[action.id].resources
      ? { ...state.clubs[action.id].resources }
      : {};
    const resource = currentResources[action.resourceID]
      ? { ...currentResources[action.resourceID] }
      : {};

    resource.is_saved = false;

    const club = {
      ...state.clubs[action.id],
      resources: {
        ...currentResources,
        [action.resourceID]: resource
      }
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.updateResourceSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const currentResources = state.clubs[action.id].resources
      ? { ...state.clubs[action.id].resources }
      : {};

    const club = {
      ...state.clubs[action.id],
      resources: {
        ...currentResources,
        [action.resourceID]: action.post
      }
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.deleteResourceSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const currentResources = state.clubs[action.id].resources
      ? { ...state.clubs[action.id].resources }
      : {};
    delete currentResources[action.resourceID];

    const club = {
      ...state.clubs[action.id],
      resources: {
        ...currentResources
      }
    };

    club.resources_status =
      Object.keys(club.resources).length > 0 ? 'READY' : 'EMPTY';

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.attachPhotosSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const club = {
      ...state.clubs[action.id],
      photos: {
        ...(state.clubs[action.id].photos || {}),
        ...action.photos
      }
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.removePhotoSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const photos = state.clubs[action.id]?.photos || {};

    const club = {
      ...state.clubs[action.id],
      photos: {
        ...photos
      }
    };

    delete club.photos[action.photoID];

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.attachFilesSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const club = {
      ...state.clubs[action.id],
      files: {
        ...(state.clubs[action.id].files || {}),
        ...action.files
      }
    };

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.removeFilesSuccess, (state, action): State => {
    if (!state.clubs || !state.clubs[action.id]) {
      return state;
    }

    const files = state.clubs[action.id]?.files || {};

    const club = {
      ...state.clubs[action.id],
      files: {
        ...files
      }
    };

    delete club.files[action.fileID];

    return {
      ...state,
      clubs: {
        ...state.clubs,
        [action.id]: club
      }
    };
  }),
  on(ClubsActions.searchClubsSuccess, (state, action): State => {
    return {
      ...state,
      count: action.count,
      clubs: action.clubs
    };
  })
);

export const selectState = createFeatureSelector<State>(clubFeatureKey);

export const selectedClub = () =>
  createSelector(selectState, (state: State) => {
    if (
      !state ||
      !state.clubs ||
      !state.selectedClubID ||
      !state.clubs[state.selectedClubID]
    ) {
      return null;
    }

    return state.clubs[state.selectedClubID];
  });

export const selectClubs = () =>
  createSelector(selectState, (state: State) => {
    if (!state.clubs) {
      return { items: null, count: state.count };
    }

    return { items: Object.values(state.clubs), count: state.count };
  });
