import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomElements'
})
export class RandomElementsPipe implements PipeTransform {
  transform(value: any[], amountOfItems = 3): unknown {
    if (!value) {
      return value;
    }

    return this.shuffle(value).slice(0, amountOfItems);
  }

  private shuffle(arr) {
    const newArr = [...arr];

    newArr.sort(() => Math.random() - 0.5);

    return newArr;
  }
}
