import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';
import { IComment } from '../../../models/comment';
import { IMessage, IMessageCtx } from '../../message/message.component';
import { RoutingService } from '../../../../core/services/routing.service';
import { AuthService } from '../../../../core/services/auth.service';
import { map } from 'rxjs/operators';
import { IDoc } from '../../../models/doc';
import { getUserIdFromLink } from '../../../utils/getUserIdFromLink';
import { Observable, Subscription } from 'rxjs';
import { ICommentsInfo } from '../../posts/item/post.component';
import { ICommentsState } from '../../../ui/post/data/model/state';
import { comment } from '../../../icons/groups/svg/comment';

// ==================================================
//                   Model
// ==================================================
export interface ICommentCtx {
  delete(comment: IComment): void;
  like(comment: IComment): void;
  reply(comment: IComment, message: IMessage): void;
  replies: {
    get(comment: IComment): Observable<IComment[]>;
  };
}
// ==================================================
//                   Component
// ==================================================
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentComponent implements OnInit, OnDestroy {
  @Input() readonly comment: IComment;
  @Input() readonly ctx: ICommentCtx;

  public replies: IComment[] = null;
  public sbscr: Subscription;

  public imageIndex: number = null;
  public isShowReply: boolean;
  public isShowReplies: boolean;
  public isAuthor = this.auth.user$.pipe(
    map((user) => user?.id === this.comment.user.id)
  );

  readonly replyCtx: IMessageCtx = {
    placeholder: `Write comment`,
    submit: (message) => {
      this.ctx.reply(this.comment, message);
      this.isShowReply = false;
    },
    isSupportTags: true,
    isSupportLinkPreview: true
  };

  constructor(
    private auth: AuthService,
    readonly routing: RoutingService,
    readonly detector: ChangeDetectorRef,
    readonly hostRef: ElementRef
  ) {}

  get previews() {
    return (
      this.comment.data
        .filter((_) => _.status)
        // TODO: refactor, same logic in several places
        // move to the store level (default url)
        .map((_) => ({ url: _.url, ..._.data }))
    );
  }

  ngOnInit() {
    this.sbscr = this.ctx.replies.get(this.comment).subscribe((values) => {
      if (values.length === 0) {
        this.isShowReplies = false;
      } else if (this.replies !== null) {
        this.isShowReplies = true;
      }

      this.replies = values;
      this.detector.markForCheck();
    });
  }

  ngOnDestroy() {
    this.sbscr.unsubscribe();
  }

  public toggleImageFullscreen(index: number) {
    this.imageIndex = index;
  }

  public clickDescription(event: Event) {
    // TODO: refactor
    // what the fuck ?
    const profile = getUserIdFromLink(event, this.comment.raw_text);

    if (profile) {
      this.routing.goToProfileWithName(profile);
    }
  }

  public openDoc(doc: IDoc) {
    this.routing.openDoc(doc);
  }

  public goToProfile() {
    this.routing.goToProfile(this.comment.user);
  }

  public toggleReply() {
    this.isShowReply = !this.isShowReply;
  }

  public delete() {
    this.ctx.delete(this.comment);
  }

  public like() {
    this.ctx.like(this.comment);
  }

  public toggleReplies() {
    this.isShowReplies = !this.isShowReplies;

    setTimeout(() => {
      this.hostRef.nativeElement.scrollIntoView({
        behavior: `smooth`
      });
    });
  }
}
