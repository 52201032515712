import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isOrg'
})
export class IsOrgPipe implements PipeTransform {
  transform(value: string): unknown {
    if (value === 'org') {
      return true;
    }

    return false;
  }
}
