import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

import { IChannelMember, IGroupChatMember } from '../../../model';

@Component({
  selector: 'app-chat-add-participants-to-group-chat-channel-dialog',
  templateUrl: './add-participants-to-group-chat-channel-dialog.component.html',
  styleUrls: ['./add-participants-to-group-chat-channel-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddParticipantsToGroupChatChannelDialogComponent
  implements OnInit
{
  private _selectedParticipants = new Map<number, boolean>();

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<AddParticipantsToGroupChatChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: AddParticipantsToGroupChatChannelDialogComponent.Data
  ) {}

  ngOnInit() {
    this.data.channelMembers$
      .pipe(
        filter((_) => !!_),
        take(1),
        tap((_) =>
          _?.forEach((_) => this._selectedParticipants.set(_.id, true))
        )
      )
      .subscribe((_) => _);
  }

  get isLoading$() {
    return combineLatest([
      this.data.chatMembers$,
      this.data.channelMembers$
    ]).pipe(map((_) => !(_[0] && _[1])));
  }

  get chatTitle$() {
    return this.data.chatTitle$;
  }

  get channelTitle$() {
    return this.data.channelTitle$;
  }

  get chatMembers$() {
    return this.data.chatMembers$.pipe(
      filter((_) => !!_),
      map((_) => _.filter((_) => !this.authService.isMyId(_.id)))
    );
  }

  get channelMembers$() {
    return this.data.channelMembers$.pipe(
      filter((_) => !!_),
      map((_) => _.filter((_) => !this.authService.isMyId(_.id)))
    );
  }

  isSelectedContact(id: number) {
    return this._selectedParticipants.get(id);
  }

  onSelectContact(id: number) {
    this._selectedParticipants.set(id, true);
  }

  onUnSelectContact(id: number) {
    this._selectedParticipants.set(id, false);
  }

  onSave() {
    const membersToAdd = [...this._selectedParticipants.entries()]
      .filter((_) => _[1])
      .map((_) => _[0]);

    const membersToRemove = [...this._selectedParticipants.entries()]
      .filter((_) => !_[1])
      .map((_) => _[0]);

    this.dialogRef.close({
      membersToAdd,
      membersToRemove
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}

export namespace AddParticipantsToGroupChatChannelDialogComponent {
  export interface Data {
    chatTitle$: Observable<string>;
    channelTitle$: Observable<string>;
    chatMembers$: Observable<IGroupChatMember[]>;
    channelMembers$: Observable<IChannelMember[]>;
  }
}
