import { createAction, props } from '@ngrx/store';

import { Notification } from './notification.model';

export const loadNotifications = createAction(
  '[Notifications/API] Load Notifications',
  props<{ page: number }>()
);

export const loadNotificationsSuccess = createAction(
  '[Notifications/API] Load Notifications Success',
  props<{ notifications: Notification[] }>()
);

export const resetNextPageToLoad = createAction(
  '[Notifications/API] Reset Next Page To Load'
);

export const readNotification = createAction(
  '[Notifications/API] Read Notification',
  props<{ id: number }>()
);

export const readNotificationSuccess = createAction(
  '[Notifications/API] Read Notification Success',
  props<{ id: number }>()
);

export const readAllNotifications = createAction(
  '[Notifications/API] Read All Notifications'
);

export const readAllNotificationsSuccess = createAction(
  '[Notifications/API] Read All Notifications Success'
);
