import { createAction, props } from '@ngrx/store';

import { People } from './people.model';

export const searchPeople = createAction(
  '[Search/API] Search People',
  props<{ searchParams: {} }>()
);

export const searchPeopleSuccess = createAction(
  '[Search/API] Search People Success',
  props<{ count: number; people: { [id: string]: People } }>()
);
