import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import moment from 'moment';

import {
  loadNotifications,
  loadNotificationsSuccess,
  readNotification,
  readNotificationSuccess,
  readAllNotifications,
  readAllNotificationsSuccess
} from './notifications.actions';
import { NotificationService } from './notifications.service';
import { Notification } from './notification.model';

@Injectable()
export class NotificationsEffects {
  constructor(
    private actions$: Actions,
    private service: NotificationService
  ) {}

  effectForLoadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotifications),
      switchMap((params) =>
        this.service.getList(params.page).pipe(
          switchMap((data) => [
            loadNotificationsSuccess({
              notifications: data.notifications.map(
                this.mapNotification.bind(this)
              )
            })
          ])
        )
      )
    )
  );

  effectForReadNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(readNotification),
      switchMap((params) =>
        this.service
          .readNotification(params.id)
          .pipe(switchMap((_) => [readNotificationSuccess({ id: params.id })]))
      )
    )
  );

  effectForReadAllNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(readAllNotifications),
      switchMap((_) =>
        this.service
          .readAll()
          .pipe(switchMap((_) => [readAllNotificationsSuccess()]))
      )
    )
  );

  private mapNotification(notification): Notification {
    return {
      ...notification,
      created_at: moment.utc(notification.created_at.date).valueOf()
    };
  }
}
