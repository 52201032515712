import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { PostsFacade } from 'src/app/modules/posts/store';

import { ICommentForm } from '../../../models/comment';
import { IPost } from '../../../models/post';
import { SavedPostsFacade } from '../../../stores/posts/saved/posts.saved.facade';
import {
  addCommentToPost,
  complainePost,
  deleteComment,
  deletePost,
  likeComment,
  likePost,
  loadCommentsForPost,
  loadPostsById,
  replyComment,
  sharePost,
  updatePost
} from '../../../ui/post/data/store/actions';
import {
  selectComments,
  selectCommentsCount,
  selectCommentsStatus,
  selectPostView,
  selectReplies
} from '../../../ui/post/data/store/selector';
import { IMessage } from '../../message/message.component';
import { IPostCtx } from '../item/post.component';

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostDetailsComponent implements OnInit {
  public post: Observable<IPost>;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private saved: SavedPostsFacade,
    private postFacade: PostsFacade
  ) {}

  ngOnInit(): void {
    this.post = this.route.params.pipe(
      map((params) => params['postId']),
      filter((id) => id !== undefined),
      tap((id) => this.store.dispatch(loadPostsById({ id }))),
      switchMap((id) => this.store.select(selectPostView()))
    );
  }

  readonly ctx: IPostCtx = {
    delete: ({ id }) => this.store.dispatch(deletePost({ id })),
    like: ({ id }) => this.store.dispatch(likePost({ id })),
    complain: ({ id }) => this.store.dispatch(complainePost({ id })),
    share: ({ id }, message) => {
      this.postFacade.sharePost(id, message, true);
    },
    update: async (post, message) => {
      this.postFacade.updatePost(post.id, message);
    },
    save: (post) => {
      this.saved.toggleSave(post);
    },
    comments: {
      add: async (post, message) => {
        const form = await this.getCommentForm(post, message);
        this.store.dispatch(addCommentToPost({ comment: form }));
      },
      delete: (post, comment) => {
        this.store.dispatch(deleteComment({ comment }));
      },
      like: (post, comment) => this.store.dispatch(likeComment({ comment })),
      reply: async (post, comment, message) => {
        const form = await this.getCommentForm(post, message);
        form['comment_id'] = comment.id;
        this.store.dispatch(replyComment({ comment: form }));
      },
      get: (post) => {
        return {
          values: this.store.select(selectComments(post.id)),
          status: this.store.select(selectCommentsStatus(post.id)),
          count: this.store.select(selectCommentsCount(post.id))
        };
      },
      paginate: (post, comments, page) => {
        this.store.dispatch(
          loadCommentsForPost({ post, offset: comments.length })
        );

        console.log(post, comments, page);
      },
      replies: {
        get: (_, comment) => {
          return this.store.select(selectReplies(comment.id));
        }
      }
    }
  };

  protected async getCommentForm(
    post: IPost,
    message: IMessage
  ): Promise<ICommentForm> {
    return {
      post_id: post.id,
      text: message.text,
      foto: message.images,
      file: message.docs,
      links: message.linksToPreview
    };
  }
}
