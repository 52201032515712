import { NewGroupChatDialogComponent } from './new-group-chat/new-group-chat-dialog.component';
import { EditGroupChatDialogComponent } from './edit-group-chat/edit-group-chat-dialog.component';
import { NewGroupChatChannelDialogComponent } from './new-group-chat-channel/new-group-chat-channel-dialog.component';
import { EditGroupChatChannelDialogComponent } from './edit-group-chat-channel/edit-group-chat-channel-dialog.component';
import { AddParticipantsToGroupChatChannelDialogComponent } from './add-participants-to-group-chat-channel/add-participants-to-group-chat-channel-dialog.component';

export {
  NewGroupChatDialogComponent,
  EditGroupChatDialogComponent,
  NewGroupChatChannelDialogComponent,
  EditGroupChatChannelDialogComponent,
  AddParticipantsToGroupChatChannelDialogComponent
};

export default [
  NewGroupChatDialogComponent,
  EditGroupChatDialogComponent,
  NewGroupChatChannelDialogComponent,
  EditGroupChatChannelDialogComponent,
  AddParticipantsToGroupChatChannelDialogComponent
];
