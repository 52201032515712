<div class="nav-sub-wrap">
  <a
    *appHideForGuest
    [appRouterLink]="['/news']"
    appRouterLinkActive="active"
    appRouterLinkMatcher="^/news"
    amplitude="/left-nav-menu/community"
    class="nav-item"
  >
    <div class="nav-item-icon">
      <svg viewBox="0 0 90 90">
        <circle cx="18.5" cy="11.5" r="8.5" fill="#FFCA28" />
        <circle cx="72.5" cy="11.5" r="8.5" fill="#FFCA28" />
        <circle cx="44.5" cy="61.5" r="8.5" fill="#FFCA28" />
        <path
          d="m89.75 36.51-1.87-9.36a7.1 7.1 0 0 0-6.76-5.56h-4.05A11.44 11.44 0 0 0 71.87 0a11.43 11.43 0 0 0-5.2 21.6H62.6a7.1 7.1 0 0 0-6.76 5.55l-.43 2.19H34.58l-.43-2.19a7.1 7.1 0 0 0-6.76-5.56h-4.05A11.44 11.44 0 0 0 18.14 0a11.43 11.43 0 0 0-5.21 21.6H8.88a7.1 7.1 0 0 0-6.76 5.55L.25 36.51a2.27 2.27 0 0 0 2.23 2.7h11.2l16.53 36.03c-.6.8-1.03 1.7-1.22 2.7l-1.87 9.35A2.27 2.27 0 0 0 29.34 90h31.32a2.26 2.26 0 0 0 2.22-2.7L61 77.92c-.2-.98-.62-1.9-1.22-2.7L76.3 39.23h11.22a2.26 2.26 0 0 0 2.22-2.7ZM71.87 4.53a6.9 6.9 0 0 1 0 13.8 6.9 6.9 0 0 1 0-13.8Zm0 18.33c1.26 0 2.47-.2 3.6-.59l-3.6 3.62-3.6-3.62c1.13.38 2.34.6 3.6.6ZM18.13 4.53a6.9 6.9 0 0 1 0 13.8 6.9 6.9 0 0 1 0-13.8Zm0 18.33c1.26 0 2.47-.2 3.6-.59l-3.6 3.62-3.6-3.62c1.13.38 2.34.6 3.6.6ZM6.56 28.04c.2-1 1.3-1.92 2.32-1.92h3.1l4.55 4.57a2.26 2.26 0 0 0 3.2 0l4.56-4.57h3.1c1.01 0 2.12.92 2.32 1.92l1.32 6.65H5.23l1.33-6.65ZM32.1 85.47l1.33-6.65c.2-1 1.3-1.92 2.32-1.92h3.1l4.55 4.58a2.26 2.26 0 0 0 3.2 0l4.56-4.58h3.1c1 0 2.11.92 2.31 1.92l1.33 6.65H32.1ZM45 69.12a6.9 6.9 0 0 1 0-13.81 6.9 6.9 0 0 1 0 13.8Zm0 4.52c1.26 0 2.47-.2 3.6-.58L45 76.67l-3.6-3.61c1.13.37 2.34.58 3.6.58Zm11-1.02a6.43 6.43 0 0 0-1.75-.24H50.2a11.44 11.44 0 0 0-5.2-21.6 11.43 11.43 0 0 0-5.2 21.6h-4.05c-.6 0-1.19.08-1.76.24l-15.32-33.4h15.12a2.27 2.27 0 0 0 2.22-2.7l-.52-2.66h19.03l-.53 2.65a2.26 2.26 0 0 0 2.22 2.7h15.12L56.01 72.63Zm2.97-37.93 1.32-6.65c.2-1 1.31-1.92 2.32-1.92h3.1l4.55 4.58a2.26 2.26 0 0 0 3.21 0l4.56-4.58h3.09c1.01 0 2.12.91 2.32 1.92l1.33 6.65h-25.8Z"
          fill="#000"
        />
      </svg>
    </div>
    <div class="nav-item-title">Community</div>
  </a>
  <a
    [appRouterLink]="['/eduniverse']"
    appRouterLinkActive="active"
    appRouterLinkMatcher="^/eduniverse"
    amplitude="/left-nav-menu/eduniverse"
    class="nav-item"
  >
    <div class="nav-item-icon">
      <svg viewBox="0 0 90 90">
        <g clip-path="url(#clip-eduniverse)">
          <path fill="#FFCA28" d="M21 6h41v29H21z" />
          <path
            d="M89.1 57.97 77.22 46.08a15.74 15.74 0 0 0-9.99-4.14H36.06a7.65 7.65 0 0 0-7.64 7.64v.92c0 4.21 3.43 6.64 7.64 6.64h10.21l-2.63 3.63a7.36 7.36 0 0 1-5.18 1.73l-28.22-3.75c-3.45-.5-6.6-.32-8.65 1.74a5.15 5.15 0 0 0-1.5 4.66c.38 1.98 1.9 3.67 4.15 4.63L31.1 81.3a18.84 18.84 0 0 0 10.6.85L53 79.33a66.4 66.4 0 0 0 9.13-3.14l2.98-1.33c1.1-.48 2.98-.1 3.79.8L79.65 87.4a3.05 3.05 0 0 0 4.32.2c1.24-1.15 1.33-2.08.19-3.33L73.4 72.53a9.93 9.93 0 0 0-10.78-2.25l-2.98 1.32a61.17 61.17 0 0 1-8.14 2.8l-12.27 2.82c-1.8.45-5 .2-6.72-.54L4.87 65.26s.76-2.46 4.5-2.46l27.23 4.75c3.45.5 7.9-1 10.37-3.46l3.6-3.6c2.5-2.51 1.96-4.77 1.6-5.62-.35-.86-1.56-2.84-5.1-2.84h-11c-1.07 0-1.53-1.62-1.53-2.45 0-.82.46-2.52 1.52-2.52h31.17c1.7 0 5.46 1.14 6.67 2.34L85.78 61.3c1.2 1.2 2.13 2.2 3.32 1a3.06 3.06 0 0 0 0-4.32ZM6.27 7.72h2.99v17.11c0 1.69.36 3.06 2.05 3.06a3.06 3.06 0 0 0 3.06-3.06V7.72h3.97v27.2a3.06 3.06 0 0 0 3.06 3.05h40.33a3.06 3.06 0 0 0 3.06-3.05V7.72h12.07c1.69 0 3.06-1.03 3.06-2.72 0-1.69-1.37-2.4-3.06-2.4H6.27c-1.69 0-3.06.71-3.06 2.4s1.37 2.72 3.06 2.72Zm53.4 25.14H23.46V7.72h36.23v25.14Z"
            fill="#000"
          />
        </g>
        <defs>
          <clipPath id="clip-eduniverse"><path fill="#fff" d="M0 0h90v90H0z" /></clipPath>
        </defs>
      </svg>
    </div>
    <div class="nav-item-title">Eduniverse</div>
  </a>
  <!-- TODO: remove 'clubs' -->
  <a
    [appRouterLink]="['/groups', 'clubs']"
    appRouterLinkActive="active"
    appRouterLinkMatcher="^/competitions|clubs|groups"
    amplitude="/left-nav-menu/groups"
    class="nav-item"
  >
    <div class="nav-item-icon">
      <svg viewBox="0 0 90 90">
        <path
          d="M24.47 56h41.06c.4 0 .77.06 1.04.16.28.1.43.23.43.37V88H23V56.53c0-.14.15-.27.43-.37.27-.1.65-.16 1.04-.16v0ZM2 69a4 4 0 0 1 4-4h17v23H6a4 4 0 0 1-4-4V69Z"
          stroke="#000"
          fill="transparent"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M88 74a4 4 0 0 0-4-4H67v18h17a4 4 0 0 0 4-4V74Z"
          stroke="#000"
          fill="transparent"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.5 14c-.4-.4-2.17-4.5-3-6.5L19 6l5.5.5H31L32 5l4-3 13-.5 6.5 2L59 6l12 .5-1 3-1.5 4.5-4 6-5.5 2.5-3.5 2L51 29l-6 1-5.5-1.5C38 27 35 23.9 35 23.5c0-.4-1.67-.17-2.5 0-2.33-1.17-7-3.6-7-4 0-.5-3.5-5-4-5.5Z"
          fill="#FFCA28"
        />
        <path
          d="M71 4H59l-.12.02A14.42 14.42 0 0 0 56.04.45 2 2 0 0 0 54.77 0H35.23c-.47 0-.91.16-1.27.45-.1.09-1.54 1.33-2.84 3.58L31 4H19a2 2 0 0 0-1.99 2.22c.68 6.12 5.14 17.88 17.03 19.55a49.02 49.02 0 0 0 4.62 5.6c.1.1.23.16.34.24v4.4h-4a2 2 0 0 0-2 2v6h-4a2 2 0 0 0-2 2v8c0 1.1.9 2 2 2h32a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-4v-6a2 2 0 0 0-2-2h-4v-4.4c.11-.08.24-.13.34-.23a49.27 49.27 0 0 0 4.62-5.6C67.86 24.1 72.3 12.33 72.99 6.21A2 2 0 0 0 71 4ZM21.4 8h8.12c-.23.9-.42 1.86-.49 2.93a20.3 20.3 0 0 0 2.14 9.98C24.7 18.3 22.26 11.55 21.4 8ZM59 52H31v-4h28v4Zm-6-8H37v-4h16v4Zm-10-8v-4h4v4h-4Zm6.02-8h-8.04c-5.57-6.04-8.24-11.67-7.96-16.74.21-3.85 2.11-6.3 3-7.26h17.95c.9.96 2.8 3.42 3.01 7.26.28 5.07-2.4 10.7-7.96 16.74Zm9.82-7.1a20.27 20.27 0 0 0 2.13-9.98A15.65 15.65 0 0 0 60.49 8h8.09c-.87 3.53-3.36 10.27-9.74 12.9Z"
          fill="#000"
        />
      </svg>
    </div>
    <div class="nav-item-title">Clubs & Competitions</div>
  </a>
  <a [href]="collegesURL" target="_blank" [amplitude]="'/left-nav-menu/colleges'" class="nav-item">
    <div class="nav-item-icon">
      <svg viewBox="0 0 90 90">
        <path
          d="M87.19 19.69H2.8v63.5a4 4 0 0 0 4 4H83.2a4 4 0 0 0 4-4v-63.5Z"
          stroke="#000"
          fill="transparent"
          stroke-width="5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M87.19 23.69a4 4 0 0 0-4-4H6.8a4 4 0 0 0-4 4v12.87c0 6.22 5.04 11.25 11.25 11.25h61.88c6.21 0 11.25-5.03 11.25-11.25V23.7Z"
          stroke="#000"
          fill="transparent"
          stroke-width="5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M27 20V8.67C27 5.54 29.01 3 31.5 3h27C60.99 3 63 5.54 63 8.67V20"
          stroke="#000"
          fill="transparent"
          stroke-width="5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M45 60c-4.42 0-8-2.69-8-6V42c0-3.31 3.58-6 8-6s8 2.69 8 6v12c0 3.31-3.58 6-8 6Z"
          fill="#FFCA28"
          stroke="#000"
          stroke-width="5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M50.63 47.81H39.37"
          stroke="#000"
          fill="transparent"
          stroke-width="5"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div class="nav-item-title">College & Career</div>
  </a>
</div>
