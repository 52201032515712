<div class="dialog-content-overlay">
  <button class="btn-close" (click)="closeDialog(false)"></button>
  <h1 mat-dialog-title>{{data.customTitle? data.customTitle : 'New message'}}</h1>
  <div mat-dialog-content class="mb-4">
    <div class="d-flex flex-wrap">
      <div class="author d-flex align-items-center">
        <app-avatar [src]="data.contact.icon" [placeholder]="data.contact | initials" class="little right"></app-avatar>
        <div>
          <div class="name d-flex align-items-center"> {{ data.contact.name | textTruncate:84 }} </div>
          <div class="status"> {{ data.contact.online_str }} </div>
        </div>
      </div>
      <a
        [routerLink]="['messages']"
        [queryParams]="{id: data.receiverID}"
        (click)="closeDialog(false)"
        *ngIf="data.receiverID"
        class="go-chat-link mt-auto ml-auto"
        >Go to dialogue with {{ data.contact.name | textTruncate:24 }}</a
      >
    </div>

    <div class="message-wrapper">
      <textarea
        name="message"
        [placeholder]="data.customPlaceholder? data.customPlaceholder : 'Message'"
        [(ngModel)]="message"
        name="message"
      ></textarea>

      <!-- <div class="d-flex">
        <div class="btns d-flex align-items-center ml-auto">
          <button>
            <svg viewBox="0 0 20 20">
              <use href="#files"></use>
            </svg>
          </button>
          <button>
            <svg viewBox="0 0 20 20">
              <use href="#photo"></use>
            </svg>
          </button>
          <button>
            <svg viewBox="0 0 20 20">
              <use href="#smile"></use>
            </svg>
          </button>
        </div>
      </div> -->
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="closeDialog(true); false">Send</button>
  </div>
</div>
