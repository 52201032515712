<div class="block right-block">
  <div class="title d-flex">Jobs & Volunteering</div>
  <div class="right-block-data">
    <app-loader-2 *ngIf="!jobs.items"></app-loader-2>
    <div *ngIf="jobs.items" class="members-list">
      <div *ngFor="let job of jobs.items | randomElements:3" class="member-item d-flex">
        <a [job]="job">
          <app-avatar [src]="job.user.icon" [placeholder]="job.user | initials" class="ava"></app-avatar>
        </a>
        <div class="data">
          <div class="name">{{ job.organization }}</div>
          <div class="link">
            <a [job]="job"> {{ job.name }} </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
