export type TStatus =
  | `INITIAL`
  | `READY`
  | `EMPTY`
  | `FULL`
  | `LOADING`
  | `MERGING`;

export function getStatus(values: unknown[], expectedSize: number): TStatus {
  const len = values && values.length;

  if (len === 0) return `EMPTY`;
  if (len < expectedSize) return `FULL`;
  if (len >= expectedSize) return `READY`;
}

// TODO: refactor
export function getStatus2(size: number): TStatus {
  if (size === 0) {
    return `EMPTY`;
  }

  return `READY`;
}

export function isStatus(
  status: TStatus,
  is: `IDLE` | `ACTIVE` | `MERGABLE`
): boolean {
  const isInitial = status === `INITIAL`;
  const isReady = status === `READY`;
  const isEmpty = status === `EMPTY`;
  const isFull = status === `FULL`;
  const isLoading = status === `LOADING`;
  const isMerging = status === `MERGING`;

  const isIdle = isInitial || isReady || isEmpty || isFull;
  const isActive = isLoading || isMerging;
  const isMergable = isReady;

  switch (is) {
    case `IDLE`:
      return isIdle;
    case `ACTIVE`:
      return isActive;
    case `MERGABLE`:
      return isMergable;
  }
}
