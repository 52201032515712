import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import { NotEmptyURLSearchParams } from 'src/app/shared/types/not-empty-url-search-params.type';

import { Group } from './group.model';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  constructor(private base: BaseService) {}

  getAll(page: number) {
    return this.base
      .get(`group?page=${page}`)
      .pipe(map((_) => ({ count: _.count, groups: _.groups })));
  }

  searchGroups(params): Observable<{ count: number; groups: Group[] }> {
    return this.search(`groups`, params).pipe(
      map((_) => ({ count: _.count, groups: _.groups }))
    );
  }

  getSubscribedGroups(page: number) {
    return this.base
      .get(`group/subscribed?page=${page}`)
      .pipe(map((_) => ({ count: _.count, groups: _.groups })));
  }

  getSubscribedClubs(page: number) {
    const urlParams = new NotEmptyURLSearchParams({
      page: page ? page + '' : undefined
    });

    return this.base
      .get(`group/subscribed-clubs?${urlParams}`)
      .pipe(map((_) => ({ count: _.count, groups: _.groups })));
  }

  getRecentClubs(page: number, weeklyStartFrom: number) {
    const urlParams = new NotEmptyURLSearchParams({
      page: page ? page + '' : undefined,
      start_from: weeklyStartFrom ? weeklyStartFrom + '' : undefined
    });

    return this.base
      .get(`weekly/clubs?${urlParams}`)
      .pipe(map((_) => ({ count: _.count, groups: _.clubs })));
  }

  getOrg(page: number, categoryID: number) {
    const urlParams = new URLSearchParams({
      page: page ? page + '' : undefined,
      category_id: categoryID ? categoryID + '' : undefined
    });

    return this.base
      .get(`search/organizations?${urlParams}`)
      .pipe(map((_) => ({ count: _.count, organizations: _.organizations })));
  }

  getPrivate(page: number) {
    return this.base
      .get(`group/private?page=${page}`)
      .pipe(map((_) => ({ count: _.count, groups: _.groups })));
  }

  getSubscribedPrivate(page: number) {
    return this.base
      .get(`group/subscribed-private?page=${page}`)
      .pipe(map((_) => ({ count: _.count, groups: _.groups })));
  }

  getMyGroups(page: number) {
    return this.base
      .get(`group/my?page=${page}`)
      .pipe(map((_) => ({ count: _.count, groups: _.groups })));
  }

  subscribeGroup(id) {
    return this.base
      .allGet(`group/subscribe/${id}`)
      .pipe(map(({ status }) => status === 'ok'));
  }

  unsubscribeGroup(id) {
    return this.base.get(`group/unsubscribe/${id}`);
  }

  getGroupInfo(id) {
    return this.base.get(`group/view/${id}`).pipe(map((res) => res.group));
  }

  getGroupNetwork(id) {
    return this.base.get(`group/network/${id}`).pipe(map((res) => res.users));
  }

  createGroup(data: {
    organization?: {
      name: string;
      address: string;
    };
    school?: {
      name: string;
      address: string;
    };
    [key: string]: any;
  }): Observable<{ group: Group }> {
    const group = {
      ...data,
      org: data?.organization?.name
        ? {
            name: data.organization.name,
            address: { name: data.organization.address }
          }
        : null,
      body: data?.school?.name
        ? { name: data.school.name, address: { name: data.school.address } }
        : null
    };
    delete group.organization;
    delete group.school;
    return this.base
      .post('group/edit/0', group)
      .pipe(map((res) => ({ group: res.group })));
  }

  groupRequests(id) {
    return this.base
      .get(`group/requests/${id}`)
      .pipe(map((res) => ({ requests: res.users })));
  }

  unrequestGroup(group: number, id: number) {
    return this.base.get(`group/unrequest/${group}?user_id=${id}`);
  }

  updateUserToModerator(groupId: number, userId: number) {
    const moderatorAccessId = 1;

    const params = new NotEmptyURLSearchParams({
      user_id: userId ? userId + '' : null,
      access: moderatorAccessId + ''
    });

    return this.base
      .get(`group/set-moderator/${groupId}?${params}`)
      .pipe(map((_) => ({ groupId, userId })));
  }

  updateUserToMember(groupId: number, userId: number) {
    const memberAccessId = 0;

    const params = new NotEmptyURLSearchParams({
      user_id: userId ? userId + '' : null,
      access: memberAccessId + ''
    });

    return this.base
      .get(`group/set-moderator/${groupId}?${params}`)
      .pipe(map((_) => ({ groupId, userId })));
  }

  private search(kind, params) {
    const requestParams = { ...params };
    const { q, page } = requestParams;

    delete requestParams['q'];

    const urlParams = new URLSearchParams({
      ...params,
      text: q ? q : '',
      page
    });

    return this.base.get(`search/${kind}?${urlParams}`);
  }
}
