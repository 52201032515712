import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { IPost } from '../../../models/post';
import {
  addSavedPost,
  clearSavedPosts,
  deleteSavedPost,
  loadSavedPosts
} from './posts.saved.actions';
import { selectSavedPost, selectSavedPostStatus } from './posts.saved.selector';

@Injectable({
  providedIn: `root`
})
export class SavedPostsFacade {
  protected _page = 0;

  readonly posts = this.store.select(selectSavedPost);
  readonly status = this.store.select(selectSavedPostStatus);

  constructor(private store: Store) {}
  // ---------------------------------------------
  //                Api
  // ---------------------------------------------
  public init() {
    this._page = 0;
    this.store.dispatch(clearSavedPosts());
    this.loadMore();
  }

  public toggleSave(post: IPost) {
    if (post.is_saved) {
      this.store.dispatch(deleteSavedPost.init({ payload: post }));
    } else {
      this.store.dispatch(addSavedPost.init({ payload: post }));
    }
  }

  public async loadMore() {
    const page = (this._page += 1);
    const status = await this.status.pipe(take(1)).toPromise();
    const isCanLoad = status !== `FULL`;

    if (isCanLoad) {
      this.store.dispatch(loadSavedPosts.init({ payload: { page } }));
    }
  }
  // ---------------------------------------------
  //                Internal
  // ---------------------------------------------
}
