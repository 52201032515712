import { IIcon } from '../../model/icon';

export const groups: IIcon = {
  id: `GROUPS`,
  svg: `
  <g clip-path="url(#clip3)">
  <path fill="#FDD7AD" d="M7.24 2.59a2.59 2.59 0 11-5.17 0 2.59 2.59 0 015.17 0z" />
  <path fill="#E64C3C"
    d="M9.31 8.28v3.1c0 .57-.46 1.03-1.03 1.03-.57 0-1.03-.46-1.04-1.03v2.07H2.07v-2.07c0 .57-.46 1.03-1.04 1.03-.27 0-.53-.1-.72-.3-.2-.2-.31-.46-.31-.73v-3.1c0-.86.7-1.56 1.55-1.56h6.2c.87 0 1.56.7 1.56 1.56z" />
  <path fill="#C03A2B"
    d="M7.24 11.9a.52.52 0 01-.52-.52V8.79a.52.52 0 011.04 0v2.59c0 .28-.23.52-.52.52zM2.07 11.9a.52.52 0 01-.52-.52V8.79a.52.52 0 111.04 0v2.59c0 .28-.24.52-.52.52z" />
  <path fill="#C03A2B" d="M7.24 11.9a.52.52 0 01-.52-.52v-1.04a.52.52 0 011.04 0v1.04c0 .28-.23.52-.52.52z" />
  <path fill="#35495E" d="M7.24 13.45v4.91a1.3 1.3 0 11-2.58 0 1.3 1.3 0 01-2.6 0v-4.91h5.18z" />
  <path fill="#C03A2B" d="M2.07 11.9a.52.52 0 01-.52-.52v-1.04a.52.52 0 111.04 0v1.04c0 .28-.24.52-.52.52z" />
  <path fill="#FDD7AD" d="M16.55 2.59a2.59 2.59 0 11-5.17 0 2.59 2.59 0 015.17 0z" />
  <path fill="#E64C3C"
    d="M18.62 8.28v3.1c0 .57-.46 1.03-1.03 1.03-.57 0-1.03-.46-1.04-1.03v2.07h-5.17v-2.07c0 .57-.46 1.03-1.04 1.03-.56 0-1.03-.46-1.03-1.03v-3.1c0-.86.7-1.56 1.55-1.56h6.2c.87 0 1.56.7 1.56 1.56z" />
  <path fill="#C03A2B"
    d="M16.55 11.9a.52.52 0 01-.52-.52V8.79a.52.52 0 011.04 0v2.59c0 .28-.23.52-.52.52zM11.38 11.9a.52.52 0 01-.52-.52V8.79a.52.52 0 011.04 0v2.59c0 .28-.24.52-.52.52z" />
  <path fill="#C03A2B" d="M16.55 11.9a.52.52 0 01-.52-.52v-1.04a.52.52 0 011.04 0v1.04c0 .28-.23.52-.52.52z" />
  <path fill="#35495E" d="M16.55 13.45v4.91a1.3 1.3 0 01-2.58 0 1.3 1.3 0 11-2.6 0v-4.91h5.18z" />
  <path fill="#FDD7AD" d="M11.9 5.69a2.59 2.59 0 11-5.18 0 2.59 2.59 0 015.18 0z" />
  <path fill="#4FBA6F"
    d="M13.97 10.86v3.1c0 .58-.47 1.04-1.04 1.04-.57 0-1.03-.46-1.03-1.03v2.06H6.72v-2.06c0 .57-.46 1.03-1.03 1.03-.57 0-1.03-.46-1.03-1.03v-3.1c0-.86.69-1.56 1.55-1.56h6.2c.86 0 1.56.7 1.56 1.55z" />
  <path fill="#24AE5F"
    d="M11.9 14.48a.52.52 0 01-.52-.51v-2.6a.52.52 0 011.03 0v2.6c0 .28-.23.51-.51.51zM6.72 14.48a.52.52 0 01-.51-.51v-2.6a.52.52 0 011.03 0v2.6c0 .28-.23.51-.52.51z" />
  <path fill="#24AE5F" d="M11.9 14.48a.52.52 0 01-.52-.51v-1.04a.52.52 0 111.03 0v1.04c0 .28-.23.51-.51.51z" />
  <path fill="#285680" d="M11.9 16.03v4.92a1.3 1.3 0 01-2.59 0 1.3 1.3 0 01-2.59 0v-4.92h5.18z" />
  <path fill="#2C3E50" d="M9.31 21.47a.52.52 0 01-.52-.52v-2.33a.52.52 0 111.04 0v2.33c0 .28-.23.52-.52.52z" />
  <path fill="#24AE5F" d="M6.72 14.48a.52.52 0 01-.51-.51v-1.04a.52.52 0 111.03 0v1.04c0 .28-.23.51-.52.51z" />
  <path fill="#2C3E50"
    d="M4.66 18.88a.52.52 0 01-.52-.52v-2.33a.52.52 0 011.03 0v2.33c0 .29-.23.52-.51.52zM13.97 18.88a.52.52 0 01-.52-.52v-2.33a.52.52 0 011.03 0v2.33c0 .29-.23.52-.51.52z" />
  <path fill="#B0D3F0" d="M30 22.24a7.76 7.76 0 11-15.52 0 7.76 7.76 0 0115.52 0z" />
  <path fill="#4FBA6F"
    d="M26.9 22.24c0 .86-.7 1.55-1.56 1.55h-1.29a.26.26 0 00-.26.26v1.3a1.55 1.55 0 01-3.1 0v-1.3a.26.26 0 00-.26-.26h-1.3a1.55 1.55 0 010-3.1h1.3c.14 0 .26-.12.26-.26v-1.3a1.55 1.55 0 113.1 0v1.3c0 .14.12.26.26.26h1.3c.85 0 1.55.7 1.55 1.55z" />
</g>
<defs>
  <clipPath id="clip3">
    <path fill="#fff" d="M0 0h30v30H0z" />
  </clipPath>
</defs>`
};
