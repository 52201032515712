import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteComponent<T> {
  @Input() readonly ctx: IAutocompleteCtx<T>;

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  protected _destroyer = new Subject<never>();

  ngAfterViewInit() {
    if (this.ctx.isCanSelectOnlyOption) {
      this.clearOnNotOption();
    }
  }

  ngOnDestroy() {
    this._destroyer.next();
  }

  onSelect(event: MatAutocompleteSelectedEvent): void {
    this.ctx.control.setValue(event.option.value);
    this.ctx.onSelect && this.ctx.onSelect(event.option.value);
  }

  getInputTitle = (option: T) => {
    if (_.isString(option)) {
      return option;
    }

    return this.ctx && this.ctx.getTitle(option);
  };

  getOptionTitle(option: T) {
    const f = this.ctx.getOptionTitle ?? this.ctx.getTitle;
    return f.call(this, option);
  }

  protected clearOnNotOption() {
    this.trigger.panelClosingActions
      .pipe(takeUntil(this._destroyer))
      .subscribe((e) => {
        if (!e || !e.source) this.ctx.control.setValue(null);
      });
  }
}

// TOOD: this should be in namespace

export interface IAutocompleteCtx<T> {
  readonly options: Observable<T[]>;
  readonly getTitle: (option: T) => string;
  readonly getOptionTitle?: (option: T) => string;
  readonly placeholder: string;
  readonly control: AbstractControl;
  readonly hint?: string;
  readonly isCanSelectOnlyOption?: boolean;
  readonly onSelect?: (option: T) => void;
}
