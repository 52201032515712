import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorComponent implements OnInit, AfterViewInit {
  @Input() readonly ctx: IEditorCtx;
  @Input() readonly scrollingContainer: Node = null;

  @ViewChild(QuillEditorComponent) readonly editor: QuillEditorComponent;

  readonly modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'image', 'video']
    ]
  };

  constructor(private readonly eleRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {}

  get scrollingContainerNode() {
    return this.scrollingContainer;
  }
}

export interface IEditorCtx {
  readonly control: AbstractControl;
}
