import { Pipe, PipeTransform } from '@angular/core';
import { IPost } from '../models/post';
import { ConvertNameService } from '../services/convert-name.service';

@Pipe({
  name: 'postURLText'
})
export class PostURLTextPipe implements PipeTransform {
  constructor(private convertNameService: ConvertNameService) {}

  transform(post: IPost): string {
    const deletedTag = post.tags.reduce<string>(
      (acc: string, tag: any): string => {
        return acc.replace(tag.string, tag.text);
      },
      post.raw_text
    );

    return this.convertNameService.convertName(deletedTag);
  }
}
