import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ClubActions } from '../actions';
import { IClubMember } from '../../model';

export const featureAdapter: EntityAdapter<IClubMember> =
  createEntityAdapter<IClubMember>({
    selectId: (model) => model.id,
    sortComparer: (a: IClubMember, b: IClubMember): number => b.id - a.id
  });

export interface State extends EntityState<IClubMember> {
  isLoading: boolean;
  membersTotalCount: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  membersTotalCount: 0
});

export function Reducer(
  state = initialState,
  action: ClubActions.Actions
): State {
  switch (action.type) {
    case ClubActions.ActionTypes.LOAD_CLUB_MEMBERS:
      return {
        ...initialState,
        isLoading: true
      };
    case ClubActions.ActionTypes.LOAD_CLUB_MEMBERS_SUCCESS:
      return featureAdapter.addMany(action.payload.members, {
        ...state,
        isLoading: false,
        membersTotalCount: action.payload.membersTotalCount
      });
    case ClubActions.ActionTypes.CLUB_UPDATE_MEMBER_TYPE_SUCCESS: {
      const { type, memberId } = action.payload;

      return featureAdapter.updateOne(
        {
          id: memberId,
          changes: {
            ...state.entities[memberId],
            is_moderator: type === 'moderator'
          }
        },
        state
      );
    }
    case ClubActions.ActionTypes.CLUB_CONNECT_MEMBER_SUCCESS: {
      const { memberId } = action.payload;

      return featureAdapter.updateOne(
        {
          id: memberId,
          changes: { ...state.entities[memberId], friend_request: true }
        },
        state
      );
    }
    case ClubActions.ActionTypes.CLUB_DISCONNECT_MEMBER_SUCCESS: {
      const { memberId } = action.payload;

      return featureAdapter.updateOne(
        {
          id: memberId,
          changes: {
            ...state.entities[memberId],
            friend_request: false,
            is_friend: false
          }
        },
        state
      );
    }
    case ClubActions.ActionTypes.CLUB_REMOVE_MEMBER_SUCCESS: {
      const { memberId } = action.payload;

      return featureAdapter.removeOne(memberId, state);
    }
    default:
      return state;
  }
}

export const isLoadingClubMembers = (state: State) => state.isLoading;
export const { selectAll: getClubMembers } = featureAdapter.getSelectors();
export const getClubMembersAmountOfPages = (state: State) =>
  Math.ceil(state.membersTotalCount / 20);
