import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'imagesUrl'
})
export class ImagesUrlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return ''; // #Return#

    const images = value.replace(
      /\/files\/img/g,
      `${environment.endpoint}/files/img`
    );

    return images;
  }
}
