import { ElementRef, Directive, Input, OnChanges } from '@angular/core';

import { IconsService } from '../../services';

@Directive({
  selector: '[icon]',
  providers: [IconsService]
})
export class AppIconsDirective implements OnChanges {
  @Input('icon') private marker: string;

  constructor(
    readonly hostRef: ElementRef<HTMLElement>,
    readonly service: IconsService
  ) {}

  ngOnChanges() {
    const [iconId, size] = this.marker.split(`:`);
    const sizes = (size ?? ``).split(`x`);

    const host = this.hostRef.nativeElement;
    const icon = this.service.get(iconId);

    const width = sizes[0];
    const height = sizes[1] ?? width;
    const sizeStr = width ? ` width="${width}px" height="${height}px"` : ``;

    host.classList.add(`icon`, iconId);
    host.innerHTML = `<svg ${sizeStr}><use href="#${iconId}"></use></svg>`;
  }
}
