import { createAction, props } from '@ngrx/store';

import { Organization } from './organization.model';

export const searchOrganizations = createAction(
  '[Organizations/API] Search',
  props<{ searchParams: {} }>()
);

export const searchOrganizationsSuccess = createAction(
  '[Organizations/API] Search Success',
  props<{ count: number; organizations: { [id: string]: Organization } }>()
);

export const orgSubscribeTo = createAction(
  '[Organizations/API] Subscribe',
  props<{ id: number }>()
);

export const orgSubscribeToSuccess = createAction(
  '[Organizations/API] Subscribe Success',
  props<{ id: number }>()
);

export const orgUnSubscribeFrom = createAction(
  '[Organizations/API] UnSubscribe',
  props<{ id: number }>()
);

export const orgUnSubscribeFromSuccess = createAction(
  '[Organizations/API] UnSubscribe Success',
  props<{ id: number }>()
);

export const loadOrganization = createAction(
  '[Organizations/API] Load Org',
  props<{ id: number }>()
);

export const loadOrganizationSuccess = createAction(
  '[Organizations/API] Load Org Success',
  props<{ organization: Organization }>()
);

export const resetOrganization = createAction('[Organizations/API] Reset Org');
