import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'app-rp-programs',
  templateUrl: './rp-programs.component.html',
  styleUrls: ['./rp-programs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpProgramsComponent implements OnInit {
  @Input()
  user: any;

  constructor() {}

  ngOnInit(): void {}
}
