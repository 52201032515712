import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { Dsn, Event } from '@sentry/types';
import { take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';
import { AuthService } from '../core/services/auth.service';

import { MemoryStorage } from '../core/storage/interfaces/memory-storage.interface';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(private readonly _injector: Injector) {
    Sentry.setTag('type', 'frontend');
    Sentry.init({
      dsn: environment.sentryDsn,
      environment: environment.production ? 'production' : 'development',
      release: VERSION.raw
    });

    if (!environment.local) {
      Sentry.addGlobalEventProcessor(this.globalEventProcessorForStore);
    }
  }

  handleError(error: Error): void {
    try {
      this.handleErrorContext();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      Sentry.captureException(error);
    }
  }

  globalEventProcessorForStore = async (event: Event) => {
    try {
      const state = await this.getStoreState();

      const client = Sentry.getCurrentHub().getClient();
      const endpoint = this.attachmentUrlFromDsn(
        client.getDsn(),
        event.event_id
      );

      const formData = new FormData();
      formData.append(
        'my-attachment',
        new Blob([JSON.stringify(state)], {
          type: 'application/json'
        }),
        'state.json'
      );

      fetch(endpoint, {
        method: 'POST',
        body: formData
      }).catch((_) => _);

      return event;
    } catch (_) {
      return null;
    }
  };

  private handleErrorContext(): void {
    const authService = this._injector.get(AuthService);

    Sentry.setUser(
      authService.user$.value
        ? {
            ...authService.user$.value,
            id: authService.user$.value.id + '',
            token: authService.token$.value
          }
        : null
    );

    Sentry.setContext('Browser', {
      webSocketSupport: 'WebSocket' in window || 'MozWebSocket' in window
    });

    Sentry.setContext('Storage and Cookie', {
      localStorage: localStorage ? JSON.stringify(localStorage) : localStorage,
      memoryStorage: JSON.stringify(this._injector.get(MemoryStorage)),
      cookie: document.cookie
    });
  }

  private async getStoreState() {
    return await this._injector.get(Store).pipe(take(1)).toPromise<object>();
  }

  private attachmentUrlFromDsn(dsn: Dsn, eventId: string) {
    const { host, path, projectId, port, protocol, user } = dsn;

    return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
      path !== '' ? `/${path}` : ''
    }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=6&sentry_client=custom-javascript`;
  }
}
