import { Injectable } from '@angular/core';
import { omitBy, isEmpty } from 'lodash';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';
import { APIService } from 'src/app/shared/services/api.service';

import {
  ICompetition,
  ICompetitionJury,
  ICompetitionNews,
  ICompetitionParticipant,
  ICompetitionResult,
  ICompetitionTeam,
  INewCompetitionTeam,
  ICompetitionMember,
  ICompetitionGroup,
  ICompetitionReview
} from '../model';

@Injectable()
export class CompetitionsService {
  constructor(
    private readonly _baseService: BaseService,
    private readonly _authService: AuthService,
    private readonly _apiService: APIService
  ) {}

  loadCurrentCompetitions(page: number) {
    const params = {
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._apiService
      .get<{ competitions: ICompetition[]; count: number }>(
        `competitions/current?${urlParams}`
      )
      .pipe(
        map((_) => ({ competitions: _.competitions, totalAmount: _.count }))
      );
  }

  loadUpcomingCompetitions(page: number) {
    const params = {
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._apiService
      .get<{ competitions: ICompetition[]; count: number }>(
        `competitions/upcoming?${urlParams}`
      )
      .pipe(
        map((_) => ({ competitions: _.competitions, totalAmount: _.count }))
      );
  }

  loadMyCompetitions(page: number) {
    const params = {
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._apiService
      .get<{ competitions: ICompetition[]; count: number }>(
        `competitions/my?${urlParams}`
      )
      .pipe(
        map((_) => ({ competitions: _.competitions, totalAmount: _.count }))
      );
  }

  loadPreviousCompetitions(page: number) {
    const params = {
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._apiService
      .get<{ competitions: ICompetition[]; count: number }>(
        `competitions/previous?${urlParams}`
      )
      .pipe(
        map((_) => ({ competitions: _.competitions, totalAmount: _.count }))
      );
  }

  loadCompetitionsShortList() {
    return this._apiService
      .get<{
        current: ICompetition;
        upcoming: ICompetition;
        previous: ICompetition;
      }>('competitions/short-list')
      .pipe(
        map((_) => ({
          current: _.current,
          upcoming: _.upcoming,
          previous: _.previous
        }))
      );
  }

  loadCompetition(id: number) {
    return this._apiService
      .get<{ competition: ICompetition }>(`competitions/${id}`)
      .pipe(map((_) => _.competition));
  }

  loadCompetitionParticipants(id: number, page: number) {
    const params = {
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._apiService
      .get<{ groups: ICompetitionParticipant[]; count: number }>(
        `competitions/${id}?${urlParams}`
      )
      .pipe(map((_) => ({ participants: _.groups, totalAmount: _.count })));
  }

  loadCompetitionRequests(id: number, page: number) {
    const params = {
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService
      .get(`competition/invitation/${id}?${urlParams}`)
      .pipe(map((_) => ({ requests: _.groups, totalAmount: _.count })));
  }

  loadCompetitionReviews(id: number, page: number) {
    const params = {
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return (
      this._baseService
        // .get(`result/${id}?${urlParams}`) // old version
        .get(`competition/results/${id}?${urlParams}`)
        .pipe(map((_) => ({ reviews: _.results, totalAmount: _.count })))
    );
  }

  loadCompetitionJurySubmissions(
    token: string,
    juryId: number,
    competitionId: number,
    page: number
  ) {
    const params = {
      token,
      jury_id: juryId + '',
      competition_id: competitionId + '',
      page: page + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return (
      this._baseService
        // .get(`result/${id}?${urlParams}`) // old version
        .get(`for-juri/results?${urlParams}`)
        .pipe(map((_) => ({ reviews: _.results, totalAmount: _.count })))
    );
  }

  createCompetition(competition: object) {
    return this._baseService.post('competition/edit/0', {
      ...competition
    });
  }

  updateCompetition(id: number, competition: object) {
    return this._baseService
      .post(`competition/edit/${id}`, {
        ...competition
      })
      .pipe(map((_) => ({ competition: _.competition })));
  }

  joinCompetition(id: number, groupID: number) {
    const params = {
      group_id: groupID + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService.get(`competition/invite/${id}?${urlParams}`);
  }

  leaveCompetition(id: number, groupID: number) {
    const params = {
      group_id: groupID + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService.get(`competition/deny-group/${id}?${urlParams}`);
  }

  acceptCompetitionRequest(id: number, groupID: number) {
    const params = {
      group_id: groupID + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService.get(`competition/accept-group/${id}?${urlParams}`);
  }

  declineCompetitionRequest(id: number, groupID: number) {
    const params = {
      group_id: groupID + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService.get(`competition/deny-group/${id}?${urlParams}`);
  }

  removeCompetitionParticipant(id: number, groupID: number) {
    const params = {
      group_id: groupID + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService.get(`competition/deny-group/${id}?${urlParams}`);
  }

  submitNews(id: number, news: ICompetitionNews) {
    // return this._baseService
    //   .post(`competition/post/${id}`, {
    //     description: news.message,
    //     file: news.docs,
    //     foto: news.images
    //   })
    //   .pipe(map((_) => ({ competition: _.competition })));

    return this._baseService
      .post(`team/message/${id ? id : 0}`, {
        competition_id: news.competition_id,
        text: news.message,
        file: news.docs,
        foto: news.images
      })
      .pipe(map((_) => ({ competition: _.competition })));
  }

  submitResult(
    result: ICompetitionResult
  ): Observable<{ result: ICompetitionResult }> {
    return this._baseService
      .post(`competition/result/${result.projectID}`, {
        description: result.message,
        file: result.docs,
        foto: result.images
      })
      .pipe(map((_) => ({ result: _.result })));
  }

  createTeam(
    team: INewCompetitionTeam
  ): Observable<{ team: ICompetitionTeam }> {
    return this._baseService
      .post(`team/edit/0`, { ...team })
      .pipe(map((_) => ({ team: _.team })));
  }

  addTeamToCompetitions({
    competitionId,
    teamID
  }: {
    competitionId: number;
    teamID: number;
  }) {
    const params = {
      team_id: teamID + ''
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService.get(
      `competition/team-add/${competitionId}?${urlParams}`
    );
  }

  confirmCompetitionTeam({
    team
  }: {
    team: ICompetitionTeam;
  }): Observable<{ team: ICompetitionTeam }> {
    return this._baseService
      .get(`competition/team/confirm/${team.id}`)
      .pipe(map((_) => ({ team: _.team })));
  }

  rejectCompetitionTeam({
    team
  }: {
    team: ICompetitionTeam;
  }): Observable<{ team: ICompetitionTeam }> {
    return this._baseService
      .get(`competition/team/reject/${team.id}`)
      .pipe(map((_) => ({ team: _.team })));
  }

  removeCompetitionTeam({
    team
  }: {
    team: ICompetitionTeam;
  }): Observable<{ team: ICompetitionTeam }> {
    return this._baseService
      .get(`competition/team/remove/${team.id}`)
      .pipe(map((_) => ({ team: _.team })));
  }

  loadCompetitionTeams({
    competitionId,
    page,
    term
  }: {
    competitionId: number;
    page?: number;
    term?: string;
  }): Observable<{ teams: ICompetitionTeam[]; teamsTotalAmount: number }> {
    const params: { page?: string; term?: string } = {};

    if (page) {
      params.page = page + '';
    }

    if (term) {
      params.term = term + '';
    }

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._apiService
      .get<{ teams: ICompetitionTeam[]; count: number }>(
        `competitions/teams/${competitionId}?${urlParams}`
      )
      .pipe(map((_) => ({ teams: _.teams, teamsTotalAmount: _.count })));
  }

  findMembers({
    competitionId,
    groupId,
    page,
    term
  }: {
    competitionId: number;
    groupId: number;
    page: number;
    term?: string;
  }): Observable<{
    members: ICompetitionMember[];
    membersTotalAmount: number;
  }> {
    const params = {
      group_id: groupId + '',
      page: page + '',
      term
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService
      .get(`competition/find-members/${competitionId}?${urlParams}`)
      .pipe(map((_) => ({ members: _.members, membersTotalAmount: _.count })));
  }

  getMyGroups(): Observable<{ groups: ICompetitionGroup[] }> {
    return this._baseService
      .get(`competition/my-groups`)
      .pipe(map((_) => ({ groups: _.groups })));
  }

  getProjectsAllList({
    competitionId
  }: {
    competitionId: number;
  }): Observable<[{ id: number; title: string }]> {
    const params = {
      all: '1'
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService
      .get(`competition/projects/${competitionId}?${urlParams}`)
      .pipe(map((_) => _.projects));
  }

  addJury({
    jury
  }: {
    jury: ICompetitionJury;
  }): Observable<{ jury: ICompetitionJury }> {
    return this._baseService
      .post(`jury/add`, { ...jury })
      .pipe(map((_) => ({ jury: _.jury })));
  }

  editJury({
    jury
  }: {
    jury: ICompetitionJury;
  }): Observable<{ jury: ICompetitionJury }> {
    return this._baseService
      .post(`jury/add-projects/${jury.id}`, { projects_id: jury.projects_id })
      .pipe(map((_) => ({ jury: _.jury })));
  }

  notifyJury(
    jury: ICompetitionJury,
    competition: ICompetition
  ): Observable<{ jury: ICompetitionJury }> {
    return this._baseService
      .post(`jury/submissions-notify/${jury.id}`, {
        competition_id: competition.id
      })
      .pipe(map((_) => ({ jury: _.jury })));
  }

  loadJuri({
    competitionId
  }: {
    competitionId: number;
  }): Observable<{ jury: ICompetitionJury[] }> {
    return this._baseService
      .get(`competition/jury/${competitionId}`)
      .pipe(map((_) => ({ jury: _.jury })));
  }

  deleteJury({ juriId }: { juriId: number }) {
    return this._baseService.post(`jury/del`, { jury_id: juriId });
  }

  confirmJury({
    juryId,
    token
  }: {
    juryId: number;
    token: string;
  }): Observable<{ projects: { title: string }[] }> {
    const params = {
      jury_id: juryId,
      token
    };

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this._baseService
      .get(`confirming/jury?${urlParams}`)
      .pipe(map((_) => ({ projects: _.projects })));
  }

  updateSubmission({
    result: { id, note, status, jury_id, rating }
  }: {
    result: ICompetitionReview;
  }): Observable<{ result: ICompetitionReview }> {
    const params = {
      note,
      status,
      jury_id,
      rating
    };

    return this._baseService
      .post(`competition/result-update/${id}`, params)
      .pipe(map((_) => ({ result: _.result })));
  }

  rateSubmission({
    result: {
      id,
      note,
      rating,
      project: { id: project_id }
    },
    jury: { id: jury_id, token }
  }: {
    result: ICompetitionReview;
    jury: ICompetitionJury;
  }): Observable<{ result: ICompetitionReview }> {
    const params = {
      note,
      rating
    };

    const query = {
      jury_id: jury_id + '',
      project_id: project_id + '',
      token
    };

    const urlParams = new URLSearchParams({
      ...omitBy(query, isEmpty)
    });

    console.log(query);

    return this._baseService
      .post(`for-juri/result/${id}?${urlParams}`, params)
      .pipe(map((_) => ({ result: _.result })));
  }

  checkJuryToken(token: string): Observable<{ jury: ICompetitionJury }> {
    return this._baseService
      .get(`for-juri/check-token/${token}`)
      .pipe(map((_) => ({ jury: _.jury })));
  }
}
