<div class="dialog-content-overlay">
  <button type="button" class="btn-close" (click)="close(); false"></button>
  <div class="modal-title mt-4"> Forgot Password </div>
  <div class="modal-description"> Enter your email address to reset your password. </div>
  <form class="form mx-auto mt-3 mb-4" [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="form-el">
      <input type="email" name="email" formControlName="email" placeholder="Email" />
    </div>
    <div class="d-sm-flex justify-content-between">
      <div class="form-el">
        <div class="form-error" *ngIf="error$ | async as error"> {{ error }} </div>
      </div>
      <div class="form-el mt-2 d-flex">
        <a (click)="openLoginDialog(); false" class="d-block ml-auto text-right"> Back to log in </a>
      </div>
    </div>
    <div class="form-el">
      <button class="button button-big">Reset password</button>
    </div>
  </form>
</div>
