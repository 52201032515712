<section class="adder" [class.invalid-wrapp]="ctx.control.touched && ctx.control.invalid">
  <mat-form-field class="input">
    <mat-label>{{ ctx.placeholder }}</mat-label>
    <input
      matInput
      [placeholder]="ctx.placeholder"
      [(ngModel)]="input"
      name="inputText"
      #inputText="ngModel"
      (focusout)="ctx.control.markAsTouched()"
      (focusin)="ctx.control.markAsUntouched()"
    />
    <mat-hint *ngIf="ctx.hint">i.e. {{ ctx.hint }}</mat-hint>
  </mat-form-field>

  <button class="add" (click)="add()" [icon]="'PLUS_WHITE:14'" [disabled]="!input">
    <!-- <svg viewBox="0 0 15 14">
      <use href="#plus-white"></use>
    </svg> -->
  </button>
</section>

<section class="tags">
  <div *ngFor="let tag of ctx.control.controls; index as i" class="tag">
    <button class="delete" (click)="delete(i)" [icon]="'CLOSE'">
      <!-- <svg viewBox="0 0 8 8">
        <use href="#close"></use>
      </svg> -->
    </button>
    <span>{{ tag.value }}</span>
  </div>
</section>
