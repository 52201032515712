<app-loader-three-dots *ngIf="isRendering$ | async; else rendered"></app-loader-three-dots>

<ng-template #rendered>
  <div *ngIf="isRendered$ | async">
    <div class="preview-controls" [ngSwitch]="isShow$ | async">
      <span *ngSwitchCase="true" (click)="onHide(); false">[don't show preview]</span>
      <span *ngSwitchDefault (click)="onShow(); false">[show preview]</span>
    </div>
  </div>
</ng-template>

<ng-container [ngSwitch]="isShow$ | async">
  <app-post-link-preview
    *ngSwitchCase="true"
    [url]="url"
    (render)="onStartRender()"
    (renderSuccess)="onRenderSuccess()"
    (renderFailure)="onRenderFailure()"
    (destroy)="onRenderDestroy()"
  >
  </app-post-link-preview>
  <div *ngSwitchDefault></div>
</ng-container>
