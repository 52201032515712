import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import {
  AccountConnectedSuccessDialogComponent,
  AccountConnectedErrorDialogComponent
} from './components';

@NgModule({
  declarations: [
    AccountConnectedSuccessDialogComponent,
    AccountConnectedErrorDialogComponent
  ],
  imports: [CommonModule, MatDialogModule],
  exports: [
    AccountConnectedSuccessDialogComponent,
    AccountConnectedErrorDialogComponent
  ]
})
export class AppAccountConnectedDialogModule {}
