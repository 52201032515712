import { Injectable } from '@angular/core';
import { of, zip, forkJoin } from 'rxjs';
import { switchMap, map, tap, mergeAll, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  loadRequests,
  loadRequestsSuccess,
  acceptRequest,
  acceptRequestSuccess,
  rejectRequest,
  rejectRequestSuccess
} from './requests.actions';
import { RequestsService } from './requests.service';

@Injectable()
export class RequestsEffect {
  constructor(
    private actions$: Actions,
    private requestsService: RequestsService
  ) {}

  effectForLoadRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRequests),
      switchMap((params) => this.loadRequests(params))
    )
  );

  effectForAccept$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptRequest),
      switchMap((params) =>
        forkJoin({
          params: of(params),
          accept: this.requestsService.acceptRequest(params.id)
        })
      ),
      switchMap(({ accept: { id } }) => [acceptRequestSuccess({ id })])
    )
  );

  effectForReject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectRequest),
      mergeMap((params) =>
        forkJoin({
          params: of(params),
          accept: this.requestsService.rejectRequest(params.id)
        })
      ),
      switchMap(({ accept: { id } }) => [rejectRequestSuccess({ id })])
    )
  );

  private loadRequests = (params: { search: string; page: number }) => {
    return this.requestsService
      .getRequests(params.search, params.page)
      .pipe(
        switchMap(({ count, requests }) => [
          loadRequestsSuccess({ count, requests: this.normalize(requests) })
        ])
      );
  };

  private normalize(requests) {
    return requests.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
