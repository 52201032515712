import { CompetitionChooseClubDialogComponent } from './competition-choose-club-dialog/competition-choose-club-dialog.component';
import { NewCompetitionComponent } from './new-competition/new-competition.component';
import { CompetitionWritePostComponent } from './competition-write-post/competition-write-post.component';
import { CompetitionSubmitResultComponent } from './competition-submit-result/competition-submit-result.component';
import { CompetitionReviewResultComponent } from './competition-review-result/competition-review-result.component';
import { CompetitionCreateProjectDialogComponent } from './competition-create-project-dialog/competition-create-project-dialog.component';
import { CompetitionUpsertJuryComponent } from './competition-upsert-jury/competition-upsert-jury.component';
import { CompetitionJuryRateSubmissionDialogComponent } from './competition-jury-rate-submission/competition-jury-rate-submission-dialog.component';
import { CompetitionProjectViewComponent } from './competition-project-view/competition-project-view.component';
import { CompetitionTeamViewComponent } from './competition-team-view/competition-team-view.component';

export {
  CompetitionChooseClubDialogComponent,
  NewCompetitionComponent,
  CompetitionWritePostComponent,
  CompetitionSubmitResultComponent,
  CompetitionReviewResultComponent,
  CompetitionCreateProjectDialogComponent,
  CompetitionUpsertJuryComponent,
  CompetitionJuryRateSubmissionDialogComponent,
  CompetitionProjectViewComponent,
  CompetitionTeamViewComponent
};

export default [
  CompetitionChooseClubDialogComponent,
  NewCompetitionComponent,
  CompetitionWritePostComponent,
  CompetitionSubmitResultComponent,
  CompetitionReviewResultComponent,
  CompetitionCreateProjectDialogComponent,
  CompetitionUpsertJuryComponent,
  CompetitionJuryRateSubmissionDialogComponent,
  CompetitionProjectViewComponent,
  CompetitionTeamViewComponent
];
