<app-loader-three-dots
  class="loader"
  [style.visibility]="(isLoading$ | async) ? 'visible' : 'hidden'"
></app-loader-three-dots>

<!-- <div class="chat-theme d-flex align-items-center">
        <div class="pinned d-flex">
            Pinned Post
            <svg viewBox="0 0 20 20">
                <use href="#pin" />
            </svg>
        </div>
        <div>
            <div class="name">Greg Zavorotniy</div>
            <div class="desc">
                RoundPier is launching its RoundPier Student Coalition...
            </div>
        </div>
        <button class="ml-auto my-auto">
            <svg viewBox="0 0 20 20">
                <use href="#close-dark-green" />
            </svg>
        </button>
    </div> -->

<div
  class="messages-list"
  #messageContainer
  infiniteScroll
  [scrollWindow]="false"
  [immediateCheck]="true"
  [infiniteScrollUpDistance]="2"
  [infiniteScrollThrottle]="100"
  (scrolledUp)="onScrolled()"
>
  <app-chat-messages-box-chunk
    [messages]="chunk.messages"
    (load)="onLoadChunk(chunk.page)"
    (loaded)="onLoadedChunk(chunk.page)"
    appHideUntilLoaded
    [onLoadedOnce$]="isChunkLoaded$(chunk.page)"
    *ngFor="let chunk of messagesChunks$ | async; trackBy: chunkTrackBy"
  ></app-chat-messages-box-chunk>
</div>
