import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import { APIService } from 'src/app/shared/services/api.service';

import { Organization } from './organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  constructor(
    private base: BaseService,
    private readonly apiService: APIService
  ) {}

  getOrganizations(page: number, categoryID: number) {
    const urlParams = new URLSearchParams({
      page: page ? page + '' : undefined,
      category_id: categoryID ? categoryID + '' : undefined
    });

    return this.apiService
      .get<{ organizations: Organization[]; count: number }>(
        `organizations?${urlParams}`
      )
      .pipe(map((_) => ({ organizations: _.organizations, count: _.count })));
  }

  subscribe(id: number): Observable<{ id: number }> {
    return this.base.get(`network/add/${id}`).pipe(map((res) => ({ id })));
  }

  unsubscribe(id: number): Observable<{ id: number }> {
    return this.base.get(`network/del/${id}`).pipe(map((res) => ({ id })));
  }

  searchOrganizations(
    params
  ): Observable<{ count: number; organizations: Organization[] }> {
    return this.search('organizations', params).pipe(
      map((_) => ({ count: _.count, organizations: _.organizations }))
    );
  }

  getOrgInfo(id) {
    return this.base
      .get('user/view/' + id)
      .pipe(map((response: any) => ({ organization: response.user })));
  }

  private search(kind, params) {
    const requestParams = { ...params };
    const { q, page } = requestParams;

    delete requestParams['q'];

    const urlParams = new URLSearchParams({
      ...params,
      text: q ? q : '',
      page
    });

    return this.base.get(`search/${kind}?${urlParams}`);
  }
}
