import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppleAuthService } from './services/apple-auth/apple-auth.service';
import { GoogleAuthService } from './services/google-auth/google-auth.service';

@NgModule({
  imports: [CommonModule],
  providers: [AppleAuthService, GoogleAuthService]
})
export class RegistrationSharedModule {}
