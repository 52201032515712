import { Action } from '@ngrx/store';

import { IMessage, INewMessage } from '../../model';

export enum ActionTypes {
  NEW_MESSAGE = '[Chat] New Message',
  NEW_MESSAGE_FAILURE = '[Chat] New Message Failure',
  NEW_MESSAGE_SUCCESS = '[Chat] New Message Success',

  NEW_MESSAGE_FOR_CHANNEL_FAILURE = '[Chat] New Message For Channel Failure',
  NEW_MESSAGE_FOR_CHANNEL_SUCCESS = '[Chat] New Message For Channel Success',

  LOAG_MESSAGES = '[Chat] Load Messages',
  LOAD_MESSAGES_FAILURE = '[Chat] Load Messages Failure',
  LOAD_MESSAGES_SUCCESS = '[Chat] Load Messages Success',
  CLEAN_MESSAGES = '[Chat] Clean Messages'
}

export class NewMessageAction implements Action {
  readonly type = ActionTypes.NEW_MESSAGE;
  constructor(public payload: { message: INewMessage }) {}
}

export class NewMessageSuccessAction implements Action {
  readonly type = ActionTypes.NEW_MESSAGE_SUCCESS;
  constructor(public payload: { message: IMessage }) {}
}

export class NewMessageFailureAction implements Action {
  readonly type = ActionTypes.NEW_MESSAGE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class NewMessageForChannelSuccessAction implements Action {
  readonly type = ActionTypes.NEW_MESSAGE_FOR_CHANNEL_SUCCESS;
  constructor(public payload: { message: IMessage }) {}
}

export class NewMessageForChannelFailureAction implements Action {
  readonly type = ActionTypes.NEW_MESSAGE_FOR_CHANNEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadMessagesAction implements Action {
  readonly type = ActionTypes.LOAG_MESSAGES;
  constructor(public payload: { channelId: number; page: number }) {}
}

export class LoadMessagesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MESSAGES_SUCCESS;
  constructor(
    public payload: {
      page: number;
      messages: IMessage[];
      nextPageToLoad: number;
    }
  ) {}
}

export class LoadMessagesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MESSAGES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CleanMessagesAction implements Action {
  readonly type = ActionTypes.CLEAN_MESSAGES;
}

export type Actions =
  | NewMessageAction
  | NewMessageSuccessAction
  | NewMessageFailureAction
  | NewMessageForChannelSuccessAction
  | NewMessageForChannelFailureAction
  | LoadMessagesAction
  | LoadMessagesSuccessAction
  | LoadMessagesFailureAction
  | CleanMessagesAction;
