import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { YourSchoolService } from './store/your-school.service';
import { IYourSchool } from './store/your-school.model';
import { AuthService } from '../../../../core/services/auth.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-your-school',
  templateUrl: './your-school.component.html',
  styleUrls: ['./your-school.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YourSchoolComponent implements OnInit {
  @Input() readonly school: IYourSchool;

  @Output() readonly connect = new EventEmitter<number>();
  @Output() readonly disconnect = new EventEmitter<number>();

  readonly text = this.auth.user$.pipe(
    filter((user) => !!user),
    map((user) => {
      return user.type === 'teacher' ? `Your organization` : `Your school`;
    })
  );

  constructor(
    readonly service: YourSchoolService,
    readonly auth: AuthService
  ) {}

  ngOnInit(): void {}

  public onProfileConnect(id: number) {
    this.connect.emit(id);
  }

  public onProfileDisconnect(id: number) {
    this.disconnect.emit(id);
  }
}
