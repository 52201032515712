import { IIcon } from '../../model/icon';

export const call: IIcon = {
  id: `CALL`,
  svg: `
  <g clip-path="url(#clip23)">
  <path fill="#F0C419"
    d="M19.67 3.73c-.19-.18-.44-.28-.7-.28H1.37l2.27 12.28.15.82h15.18c.57 0 1.03-.46 1.03-1.03V4.48c0-.28-.12-.56-.33-.75z" />
  <path fill="#F29C1F"
    d="M19.68 16.27c-.2.18-.45.28-.71.28H3.79l-.15-.82 6.1-5.85.95.88c.4.37 1.01.37 1.4 0l.94-.87 6.65 6.38z" />
  <path fill="#F3D55B"
    d="M19.67 3.73l-6.64 6.16-.93.87c-.4.37-1.01.37-1.41 0l-.95-.88-6.95-6.43h16.18c.26 0 .51.1.7.28z" />
  <path fill="#3B97D3"
    d="M10.45 19.45l-.46.13-.66.2-.64.19a.83.83 0 01-.42 0l-2.79-.64a.8.8 0 01-.39-.21c-.65-.64-2.95-3.1-4.25-7.41-1.3-4.3-.75-7.62-.56-8.5.03-.15.1-.28.21-.4L2.47.78a.8.8 0 01.36-.22l.64-.18.66-.2.45-.14A.82.82 0 015.6.58l1.18 3.88a.8.8 0 01-.56 1l-.45.14-.66.2-1.82.53a.4.4 0 00-.27.28 8.12 8.12 0 00.17 4.4 8.24 8.24 0 002.3 3.78c.1.1.25.13.39.09l1.81-.54.66-.2.46-.13a.82.82 0 011.02.55L11 18.44a.8.8 0 01-.55 1z" />
  <path fill="#F9EAB0" d="M14.14 5.17H8.62a.34.34 0 110-.69h5.52a.34.34 0 110 .7z" />
  <path fill="#F3D55B" d="M18.62 12.76a.34.34 0 01-.34-.35V7.6a.34.34 0 01.69 0v4.82c0 .2-.16.35-.35.35z" />
  <path fill="#2980BA"
    d="M8.36 15.35a.34.34 0 01-.43-.23l-.24-.77.66-.2.24.77a.34.34 0 01-.23.43zM10 19.58l-.67.2-.94-3.1a.35.35 0 01.67-.2l.93 3.1zM5.77 5.6l-.66.2-.23-.78a.34.34 0 01.65-.2l.24.78zM4.83 3.7a.34.34 0 01-.43-.23L3.48.37l.66-.2.94 3.1a.34.34 0 01-.24.43z" />
</g>
<defs>
  <clipPath id="clip23">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>
  `
};
