import { addInfoMessage } from 'src/app/pages/components/info-message/info-message.actions';
import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  createJob,
  createJobSuccess,
  editJob,
  editJobSuccess,
  deleteJob,
  deleteJobSuccess,
  getMembers,
  getMembersSuccess,
  getOrgMembers,
  getOrgMembersSuccess,
  loadOrganization,
  loadOrganizationSuccess,
  loadOrgChapters,
  loadOrgChaptersSuccess,
  loadOrgJobs,
  loadOrgJobsSuccess,
  loadOrgReviews,
  loadOrgReviewsSuccess,
  addReview,
  addReviewSuccess,
  inviteToProject
} from './organization.actions';
import { Error403Component } from 'src/app/pages/error-403/error-403.component';
import { RoutingService } from 'src/app/core/services/routing.service';
import { OrganizationService } from 'src/app/core/services/organization/organization.service';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private routing: RoutingService
  ) {}

  effectLoadReviewsOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrgReviews),
      switchMap((params) =>
        this.organizationService.loadReviews(params.id).pipe(
          switchMap(({ reviews }) => [loadOrgReviewsSuccess({ reviews })]),
          catchError((_) =>
            this.handleOrganizationError(_.error, { id: params.id })
          )
        )
      )
    )
  );

  effectddReviewOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(addReview),
      switchMap((params) =>
        this.organizationService
          .addReview(params.obj_id, params.text, params.is_anonymous)
          .pipe(
            switchMap(({ review }) => [addReviewSuccess({ review })]),
            catchError((_) =>
              this.handleOrganizationError(_.error, { id: params.obj_id })
            )
          )
      )
    )
  );

  effectLoadOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganization),
      switchMap((params) =>
        this.organizationService.loadOrganization(params.id).pipe(
          switchMap(({ organization }) => [
            loadOrganizationSuccess({ organization })
          ]),
          catchError((_) =>
            this.handleOrganizationError(_.error, { id: params.id })
          )
        )
      )
    )
  );

  effectLoadMembersOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(getMembers),
      switchMap((params) =>
        this.organizationService.getMembers(params.id, params.page).pipe(
          switchMap(({ members, count }) => [
            getMembersSuccess({ members: this.normalize(members), count })
          ]),
          catchError((_) =>
            this.handleOrganizationError(_.error, { id: params.id })
          )
        )
      )
    )
  );

  effectLoadOrgMembersOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrgMembers),
      switchMap((params) =>
        this.organizationService
          .loadMembers(params.id, params.page, params.searchTerm)
          .pipe(
            switchMap(({ members, count }) => [
              getOrgMembersSuccess({ members: this.normalize(members), count })
            ]),
            catchError((_) =>
              this.handleOrganizationError(_.error, { id: params.id })
            )
          )
      )
    )
  );

  effectLoadOrgChaptersOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrgChapters),
      switchMap((params) =>
        this.organizationService
          .loadChapters(params.id, params.page, params.searchTerm)
          .pipe(
            switchMap(({ chapters, count }) => [
              loadOrgChaptersSuccess({
                chapters: this.normalize(chapters),
                count
              })
            ]),
            catchError((_) =>
              this.handleOrganizationError(_.error, { id: params.id })
            )
          )
      )
    )
  );

  effectCreateJobOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(createJob),
      switchMap((params) =>
        this.organizationService.createJob(params.job).pipe(
          switchMap(({ job }) => [
            createJobSuccess({ job: this.normalize([job])[0] })
          ]),
          catchError((_) => this.handleOrganizationError(_.error, {}))
        )
      )
    )
  );

  effectEditJobOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(editJob),
      switchMap((params) =>
        this.organizationService.editJob(params.job).pipe(
          switchMap(({ job }) => [
            editJobSuccess({ job: this.normalize([job])[0] })
          ]),
          catchError((_) => this.handleOrganizationError(_.error, {}))
        )
      )
    )
  );

  effectDeleteJobOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteJob),
      switchMap((params) =>
        this.organizationService.deleteJob(params.job).pipe(
          switchMap(({ message }) => [deleteJobSuccess({ job: params.job })]),
          catchError((_) => this.handleOrganizationError(_.error, {}))
        )
      )
    )
  );

  effectLoadOrgJobsOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrgJobs),
      switchMap((params) =>
        this.organizationService.loadingJobs(params.id, params.page).pipe(
          switchMap(({ jobs, count }) => [
            loadOrgJobsSuccess({
              jobs: this.normalize(jobs),
              count,
              page: params.page
            })
          ]),
          catchError((_) =>
            this.handleOrganizationError(_.error, { id: params.id })
          )
        )
      )
    )
  );

  effectInviteToProject = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteToProject),
      switchMap((params) =>
        this.organizationService
          .inviteToProject(params.email, params.orgId)
          .pipe(
            map((res) =>
              addInfoMessage({
                infoMessage: {
                  type: 'alert-success',
                  message: res?.text || 'Message sent'
                }
              })
            ),
            catchError((err) =>
              of(
                addInfoMessage({
                  infoMessage: {
                    type: 'alert-danger',
                    message: err?.text || 'Message not sent'
                  }
                })
              )
            )
          )
      )
    )
  );

  private normalize(items: Array<{ id: number }>) {
    return items.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }

  private handleOrganizationError(error, params) {
    console.log('handleOrganizationError: ', error);

    switch (error?.code) {
      case 403:
        this.routing.goTo403({
          skipLocationChange: true,
          state: { type: Error403Component.Type.Organization, ...params }
        });
        break;
      default:
        this.routing.goTo404({ skipLocationChange: true });
    }

    return of(getMembersSuccess({ members: [], count: 0 }));
  }
}
