import { Validators, FormControl, FormBuilder } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  FORGOT_PASSWORD_CHECK_EMAIL_DIALOG,
  SIGN_IN_DIALOG
} from '../../sign-in-dialog.token';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppForgotPasswordDialogComponent {
  readonly fields = {
    email: new FormControl(null, [Validators.required, Validators.email])
  };

  readonly form = this._fb.group(this.fields);

  private readonly _error$ = new BehaviorSubject<null | string>(null);

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _matDialog: MatDialog,
    private readonly _matDialogRef: MatDialogRef<AppForgotPasswordDialogComponent>,
    @Inject(FORGOT_PASSWORD_CHECK_EMAIL_DIALOG)
    private readonly AppForgotPasswordCheckEmailDialogComponent,
    @Inject(SIGN_IN_DIALOG) private readonly AppSignInDialogComponent
  ) {}

  get error$() {
    return this._error$;
  }

  close() {
    this._matDialogRef.close();
  }

  openLoginDialog() {
    this._matDialogRef.close();
    this._matDialog.open(this.AppSignInDialogComponent);
  }

  async submitForm() {
    const email: string = this.fields.email.value;
    const url = `${environment.endpoint}${environment.prefix}auth/recovery`;
    const body = new FormData();

    body.append(`email`, email);
    this.error$.next(null);

    try {
      // TODO: use api service
      const responce = await fetch(url, { method: `POST`, body });
      const json = await responce.json();
      if (!json) {
        return;
      }

      const message = json.message;

      if (message?.type === 'info') {
        this._matDialogRef.close();
        this._matDialog.open(this.AppForgotPasswordCheckEmailDialogComponent);

        return;
      }

      this.error$.next(message.text || 'Internal error');
    } catch (error) {
      this.error$.next('Internal error');
    }
  }
}
