<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title>Edit channel</h1>
  <div mat-dialog-content>
    <div class="form-wrapper p-0">
      <form [formGroup]="groupChatForm" (ngSubmit)="onSave()">
        <mat-form-field class="w-100 mb-3">
          <mat-label>Channel name</mat-label>
          <input matInput formControlName="name" placeholder="Channel name" required />
        </mat-form-field>

        <div class="members-wrapper mb-3">
          <div class="members-header d-flex align-items-center">
            <div class="icon">
              <svg viewBox="0 0 20 20">
                <use href="#group" />
              </svg>
            </div>
            Add members to channel

            <!-- <div class="members-action-wrapper d-flex ml-auto">
              <div class="members-action members-action-select-all">Select all</div>
              <div class="members-action members-action-delete-all">Delete all</div>
            </div> -->
          </div>

          <mat-form-field class="w-100">
            <mat-label>Filter by name</mat-label>
            <input matInput placeholder="Member name" (input)="onFilterChange($event)" autocomplete="off" />
          </mat-form-field>

          <div class="w-100 text-center" appShowUntilLoaded [onLoaded$]="isLoadedContacts$">
            <app-loader-three-dots></app-loader-three-dots>
          </div>

          <div
            class="members-list"
            infiniteScroll
            [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScrolled()"
          >
            <div class="members-item d-flex align-items-center" *ngFor="let contact of contacts$ | async">
              <div class="author d-flex align-items-center">
                <div class="avatar rounded-circle">
                  <app-avatar [src]="contact.icon" [placeholder]="contact | initials" class="img-fluid"> </app-avatar>
                </div>
                <div>
                  <div class="name d-flex align-items-center"> {{ contact.name }} </div>
                  <div class="status"> {{ contact.type_name }} </div>
                </div>
              </div>
              <div class="actions">
                <div
                  class="action action-del"
                  *ngIf="isSelectedContact(contact.id); else add"
                  (click)="onUnSelectContact(contact.id); false"
                >
                  Delete
                </div>
                <ng-template #add>
                  <div class="action action-add" (click)="onSelectContact(contact.id); false"> Add + </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" [disabled]="groupChatForm.invalid" (click)="onSave(); false">Save</button>
  </div>
</div>
