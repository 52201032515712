<main class="error_page">
  <div class="page error_403">
    <div class="links">
      <span *ngIf="isUser"
        >User’s<br />
        profile<br />
        is private.</span
      >
      <span *ngIf="isOrganization"
        >Organization's<br />
        profile<br />
        is private.</span
      >
      <a class="underline ajax" (click)="onConnect(); false">Connect</a>
    </div>
    <div class="text">
      <div class="small"
        >Connect <br />
        to see the full<br />
        profile.</div
      >
    </div>
  </div>
</main>
