import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { filter, first, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { CompetitionsService } from '../../store/service';
import { CompetitionsFacade } from '../../store';

@Component({
  selector: 'app-competition-confirm-jury',
  templateUrl: './competition-confirm-jury.component.html',
  styleUrls: ['./competition-confirm-jury.component.scss']
})
export class CompetitionConfirmJuryComponent
  implements AfterViewInit, OnDestroy
{
  private readonly _destroy$: Subject<void> = new Subject<void>();
  public projects$: BehaviorSubject<{ title: string }[]> = new BehaviorSubject<
    { title: string }[]
  >(null);

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _competitionService: CompetitionsService,
    private readonly _competitionsFacade: CompetitionsFacade
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get competitionJuryProjects$() {
    return this._route.queryParams.pipe(
      map((_) => {
        if (_['project_id[]'] && Array.isArray(_['project_id[]'])) {
          return _['project_id[]'];
        } else {
          return [_['project_id[]']];
        }
      }),
      mergeMap((ids) =>
        this._competitionsFacade.competitionProjects$.pipe(
          map((projects) =>
            projects.filter((project) => ids.includes(project.id.toString()))
          )
        )
      )
    );
  }

  get competition$() {
    return this._competitionsFacade.competition$;
  }

  ngAfterViewInit(): void {
    this._route.queryParams
      .pipe(
        takeUntil(this._destroy$),
        filter((_) => _.jury && _.token),
        mergeMap(({ jury, token }: { jury: number; token: string }) =>
          this._competitionService.confirmJury({ juryId: jury, token })
        ),
        first()
      )
      .subscribe((_) => this.projects$.next(_.projects));
  }
}
