import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';
import { APIService } from 'src/app/shared/services/api.service';

import {
  IAppleAuthData,
  IAttachToEmailData,
  IAuthData,
  IAuthSuccessData,
  IGoogleAuthData,
  IPasswordlessAuthData,
  IRegistrationStudentData
} from '../model';

@Injectable()
export class RegistrationService {
  constructor(
    private readonly _baseService: BaseService,
    private readonly _apiService: APIService
  ) {}

  signup(data: IRegistrationStudentData) {
    // NOTE: used BaseService here in order to pass file as File type using multipart upload
    // TODO: in a future, refactor that to upload file in separate request and pass only an id to the registration-data request
    return this._baseService.post('guest/registration-data', {
      file: data.file,
      name: data.firstName,
      lastname: data.lastName,
      born: this.formatDate(data.born),
      country: data.country,
      city: data.city,
      currentschool: {
        name: data.school.name,
        address: {
          name: data.school.address
        }
      },
      interest: data.interests
    });
  }

  appleAuth(data: IAppleAuthData): Observable<IAuthSuccessData> {
    return this._apiService.post<IAuthSuccessData>('auth/apple/web', data);
  }

  googleAuth(data: IGoogleAuthData): Observable<IAuthSuccessData> {
    return this._apiService.post<IAuthSuccessData>('auth/google/web', data);
  }

  passwordlessAuth(data: IPasswordlessAuthData): Observable<IAuthSuccessData> {
    return this._apiService.post<IAuthSuccessData>('auth/passwordless', data);
  }

  attachEmail(data: IAttachToEmailData): Observable<IAuthSuccessData> {
    return this._apiService.post<IAuthSuccessData>('auth/attach', data);
  }

  authData(): Observable<null | IAuthData> {
    return this._apiService
      .get<{
        email: string;
        first_name: null | string;
        last_name: null | string;
      }>('auth/data')
      .pipe(
        map((_) =>
          _
            ? {
                email: _.email,
                firstName: _.first_name,
                lastName: _.last_name
              }
            : null
        )
      );
  }

  private formatDate(date: Date) {
    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('-');
  }
}
