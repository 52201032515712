import { switchMap, delay, tap, map, filter } from 'rxjs/operators';
import { addInfoMessage, deleteInfoMessage } from './info-message.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromStore from './info-message.reducer';
import { Store, select } from '@ngrx/store';

@Injectable()
export class InfoMessageEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromStore.State>
  ) {}

  effectForDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addInfoMessage),
      switchMap(() =>
        this.store.pipe(select(fromStore.selectAll)).pipe(
          delay(2500),
          filter((list) => !!list.length),
          map((list) => deleteInfoMessage({ id: list[0].id }))
        )
      )
    )
  );
}
