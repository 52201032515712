import { IIcon } from '../../model/icon';

export const subLink: IIcon = {
  id: `SUB_LINK`,
  svg: `
  <path fill="#2FA8CC" d="M13.7 6.35a6.2 6.2 0 11-12.4 0 6.2 6.2 0 0112.4 0z" />
  <path fill="#71C285"
    d="M9.83 10.5h-.2a2.2 2.2 0 00-2 1.28h-.25a2.2 2.2 0 00-2-1.29H3.61c.12-.1.24-.22.35-.35.2-.25.2-.5-.06-.7a3.04 3.04 0 01-.65-.47c-.44-.52-.52-1.19-.73-1.81-.1-.32-.18-.63-.47-.82-.14-.1-.3-.17-.45-.27a.65.65 0 01-.28-.41A6.2 6.2 0 015.06.64c.47.17.98.22 1.46.4.13.03.26.1.36.2.13.16.17.37.1.56a.85.85 0 01-.44.42c-.3.15-.6.32-.9.45-.26.07-.49.24-.64.47a.68.68 0 00.06.78c.18.15.4.22.63.2h.98c.6.01 1.2.08 1.79.21.18.04.35.13.48.27.09.13.11.3.06.46-.09.29-.35.38-.6.48-.38.16-.76.3-1.13.47-.33.17-.63.38-.9.63-.18.17-.4.31-.62.41-.27.06-.54.1-.81.1-.33.03-.83 0-1 .35a.8.8 0 00-.05.54c.13.4.44.7.84.81.2.08.4.13.6.2.25.07.48.18.72.29.22.12.5.11.71-.03.2-.14.35-.36.57-.47.28-.09.59-.07.85.06.41.1.8.3 1.11.57.23.3.41.65.54 1.01zM13.7 6.35c0 1.53-.55 3-1.58 4.14h-.85c-1.42-2.27.42-3.66 1.14-4.14.08-.05.17-.1.26-.13.33-.1.69-.13 1.03-.07v.2z" />
  <path fill="#67B9CC"
    d="M11.18 2.97a.26.26 0 01-.19-.07c-.2-.2-.41-.39-.65-.55a.26.26 0 01.3-.42c.26.18.5.38.72.6a.26.26 0 01-.18.44zM12.53 5.41a.26.26 0 01-.25-.2 4.78 4.78 0 00-.73-1.62.26.26 0 01.43-.3c.38.55.65 1.16.8 1.8a.26.26 0 01-.25.32z" />
  <path fill="#41767F"
    d="M7.38 13.6a2.23 2.23 0 01-2 1.29H2.2a2.2 2.2 0 110-4.4h3.17c.87 0 1.65.5 2 1.3l-2.44.25H2.2a.65.65 0 000 1.3h2.73l2.45.25z" />
  <path fill="#71C285"
    d="M9.3 2.72a.45.45 0 01-.22.42c-.33.17-.73-.17-.95-.38a.6.6 0 01-.18-.23.48.48 0 01.04-.38.85.85 0 01.15-.21.5.5 0 01.54-.15c.09.06.17.13.22.22l.21.3c.1.12.17.26.2.41z" />
  <path fill="#325C60"
    d="M5.43 10.5H2.07C.93 10.5 0 11.41 0 12.55v.26c0 1.14.93 2.07 2.07 2.07h3.36c1.14 0 2.07-.93 2.07-2.07v-.26c0-1.14-.93-2.07-2.07-2.07zm-.13 2.84H2.2a.65.65 0 110-1.3h3.1a.65.65 0 010 1.3z" />
  <path fill="#41767F"
    d="M5.43 11H2.07c-.86 0-1.55.7-1.55 1.56v.26c0 .86.7 1.55 1.55 1.55h3.36c.86 0 1.55-.7 1.55-1.55v-.26c0-.86-.7-1.55-1.55-1.55zm-.13 2.34H2.2a.65.65 0 010-1.3h3.1a.65.65 0 110 1.3z" />
  <path fill="#325C60"
    d="M12.93 10.5H9.57c-1.14 0-2.07.92-2.07 2.06v.26c0 1.14.93 2.07 2.07 2.07h3.36c1.14 0 2.07-.93 2.07-2.07v-.26c0-1.14-.93-2.07-2.07-2.07zm-.13 2.84H9.7a.65.65 0 110-1.3h3.1a.65.65 0 110 1.3z" />
  <path fill="#41767F"
    d="M12.93 11H9.57c-.86 0-1.55.7-1.55 1.56v.26c0 .86.7 1.55 1.55 1.55h3.36c.86 0 1.55-.7 1.55-1.55v-.26c0-.86-.7-1.55-1.55-1.55zm-.13 2.34H9.7a.65.65 0 010-1.3h3.1a.65.65 0 110 1.3z" />
  <path fill="#31595F"
    d="M5.3 11.78H2.2c-.5 0-.9.41-.9.91s.4.9.9.9h3.1a.91.91 0 00.64-1.54.9.9 0 00-.64-.27zm0 1.56H2.2a.65.65 0 110-1.3h3.1a.65.65 0 110 1.3z" />
  <path fill="#2C3E50"
    d="M10.34 12.69a.9.9 0 01-.9.9H5.56a.9.9 0 01-.64-1.54.9.9 0 01.64-.27h3.88c.5 0 .9.41.9.91z" />
  <path fill="#35495E"
    d="M9.57 12.69c0 .23-.06.45-.2.64a.58.58 0 01-.46.26H6.1a.58.58 0 01-.46-.25c-.13-.2-.2-.42-.2-.65 0-.23.06-.45.2-.64.1-.16.27-.26.46-.27H8.9c.19.01.36.1.46.26.13.2.2.42.2.65z" />

  `
};
