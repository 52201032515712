<header class="no-auth">
  <div class="container">
    <div class="header-wrapper d-flex align-items-center">
      <app-logo [appRouterLink]="['/']"></app-logo>
      <div class="app-links d-flex ml-4 ml-sm-5">
        <app-apple-store-icon class="app-logo"></app-apple-store-icon>
        <app-google-store-icon class="app-logo ml-2"></app-google-store-icon>
      </div>
      <div class="d-none d-md-flex ml-auto">
        <button class="btn-header" (click)="onSignin(); false">Log In</button>
        <a [appRouterLink]="['/']" class="btn-header">Join Now</a>
      </div>
    </div>
  </div>
</header>
