import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import * as ContactsActions from 'src/app/core/services/contacts/contacts.actions';
import * as GroupsActions from 'src/app/core/services/groups/groups.actions';
import * as GroupActions from './group.actions';
import { Group, Request, User } from './group.model';

export const groupFeatureKey = 'group';

export interface State {
  info: Group;

  requests: { [id: number]: Request };
  requestsCount: number;
  requestsAmountOfPages: number;
  loadRequests: boolean;

  members: { [id: number]: User };
  membersCount: number;
  membersAmountOfPages: number;
  membersNextPageToLoad: number;
  loadMembers: boolean;
}

export const initialState: State = {
  info: null,

  requests: null,
  requestsCount: null,
  requestsAmountOfPages: null,
  loadRequests: false,

  members: null,
  membersCount: null,
  membersAmountOfPages: null,
  membersNextPageToLoad: 1,
  loadMembers: false
};

const AMOUNT_OF_USERS_ON_ONE_PAGE = 20;
const AMOUNT_OF_REQUESTS_ON_ONE_PAGE = 20;

export const reducer = createReducer(
  initialState,
  on(GroupActions.loadGroupInfoSuccess, (state, action): State => {
    return {
      ...state,
      info: action.group
    };
  }),
  on(GroupActions.updateGroupInfoSuccess, (state, action): State => {
    return {
      ...state,
      info: action.group
    };
  }),
  on(GroupActions.removeGroupSuccess, (state, action): State => {
    return {
      ...state,
      info: {
        ...state.info,
        removed: true
      }
    };
  }),
  on(GroupActions.resetGroupRequests, (state, action): State => {
    return {
      ...state,
      requests: null,
      loadRequests: false
    };
  }),
  on(GroupActions.loadGroupRequests, (state, action): State => {
    return {
      ...state,
      requests: null,
      loadRequests: true
    };
  }),
  on(GroupActions.loadGroupRequestsSuccess, (state, action): State => {
    return {
      ...state,
      requests: action.requests,
      requestsCount: action.count,
      requestsAmountOfPages: Math.ceil(
        action.count / AMOUNT_OF_REQUESTS_ON_ONE_PAGE
      ),
      loadRequests: false
    };
  }),
  on(GroupActions.unrequestGroupSuccess, (state, action): State => {
    const requests = state.requests ? { ...state.requests } : state.requests;
    if (requests) {
      delete requests[action.user_id];
    }

    const info = state.info
      ? {
          ...state.info,
          is_requested: false,
          is_subscriber: false
        }
      : null;

    return {
      ...state,
      info,
      requests
    };
  }),
  on(GroupActions.accessGroupSuccess, (state, action): State => {
    const requests = { ...state.requests };
    const acceptedRequest = { ...requests[action.user.id] };

    delete requests[action.user.id];

    return {
      ...state,
      info: {
        ...state.info,
        members: state.info.members + 1
      },
      requests,
      requestsCount: state.info.requests_count - 1,
      members: {
        ...state.members,
        [acceptedRequest.id]: { ...action.user, ...acceptedRequest }
      }
    };
  }),
  on(GroupActions.unaccessGroupSuccess, (state, action): State => {
    const requests = { ...state.requests };

    delete requests[action.user.id];

    // TODO: update requestsAmountOfPages

    return {
      ...state,
      info: {
        ...state.info,
        requests_count: state.info.requests_count - 1
      },
      requests,
      requestsCount: state.requestsCount - 1
    };
  }),
  on(GroupActions.loadGroupMembers, (state, action): State => {
    return {
      ...state,
      loadMembers: true
    };
  }),
  on(GroupActions.loadGroupMembersSuccess, (state, action): State => {
    const members = state?.members || [];

    return {
      ...state,
      loadMembers: false,
      membersAmountOfPages: Math.ceil(
        action.count / AMOUNT_OF_USERS_ON_ONE_PAGE
      ),
      membersNextPageToLoad: state.membersNextPageToLoad + 1,
      members: {
        ...members,
        ...action.members
      }
    };
  }),
  on(GroupActions.unsubscribeGroupSuccess, (state, action): State => {
    const members = { ...state.members };
    delete members[action.user_id];

    const info = state.info
      ? {
          ...state.info,
          members: state.info.members - 1,
          access_posted: false,
          is_requested: false,
          // TODO: for admin user it should be false
          // fix it on the backend side
          is_subscriber: false
        }
      : null;

    return {
      ...state,
      info,
      loadMembers: false,
      members
    };
  }),
  on(GroupActions.subscribeGroupSuccess, (state, action): State => {
    const isPrivate = state.info.access === 3;
    const isClub = !!state.info.org;

    const info = state.info
      ? {
          ...state.info,
          // TODO (refactor):
          // Requests in club should be approved by the admin
          // so dont increase amount of members right away
          access_posted: isPrivate ? state.info.access_posted : true,
          members:
            isPrivate || isClub ? state.info.members : state.info.members + 1,
          is_requested: isPrivate || isClub ? true : false,
          is_subscriber: isPrivate || isClub ? false : true
        }
      : null;

    return {
      ...state,
      info
    };
  }),
  on(ContactsActions.addContactSuccess, (state, action): State => {
    if (!state.members || !state.members[action.id]) {
      return state;
    }

    return {
      ...state,
      members: {
        ...state.members,
        [action.id]: {
          ...state.members[action.id],
          is_friend: true
        }
      }
    };
  }),
  on(ContactsActions.removeContactSuccess, (state, action): State => {
    if (!state.members || !state.members[action.id]) {
      return state;
    }

    return {
      ...state,
      members: {
        ...state.members,
        [action.id]: {
          ...state.members[action.id],
          is_friend: false
        }
      }
    };
  }),
  on(GroupsActions.updateUserToModeratorSuccess, (state, action): State => {
    if (state.info.id !== action.groupId) {
      return state;
    }

    if (!state.members[action.userId]) {
      return state;
    }

    return {
      ...state,
      members: {
        ...state.members,
        [action.userId]: {
          ...state.members[action.userId],
          is_moderator: true
        }
      }
    };
  }),
  on(GroupsActions.updateUserToMemberSuccess, (state, action): State => {
    if (state.info.id !== action.groupId) {
      return state;
    }

    if (!state.members[action.userId]) {
      return state;
    }

    return {
      ...state,
      members: {
        ...state.members,
        [action.userId]: {
          ...state.members[action.userId],
          is_moderator: false
        }
      }
    };
  }),
  on(GroupActions.clearGroup, () => ({ ...initialState }))
);

export const selectState = createFeatureSelector<State>(groupFeatureKey);

export const selectGroupRequests = () =>
  createSelector(selectState, (state: State) => {
    if (!state.requests) {
      return {
        requests: null,
        loadRequests: state.loadRequests,
        count: state.requestsCount,
        amountOfPages: 0
      };
    }

    return {
      requests: Object.values(state.requests),
      loadRequests: state.loadRequests,
      count: state.requestsCount,
      amountOfPages: state.requestsAmountOfPages
    };
  });

export const selectGroupMembers = () =>
  createSelector(selectState, (state: State) => {
    if (!state.members) {
      return {
        members: null,
        loadMembers: state.loadMembers,
        count: state.membersCount,
        amountOfPages: 0,
        nextPageToLoad: state.membersNextPageToLoad
      };
    }

    return {
      members: Object.values(state.members),
      loadMembers: state.loadMembers,
      count: state.membersCount,
      amountOfPages: state.membersAmountOfPages,
      nextPageToLoad: state.membersNextPageToLoad
    };
  });

export const selectGroupInfo = () =>
  createSelector(selectState, (state: State) => state.info);
