import { IIcon } from '../../model/icon';

export const subInvite: IIcon = {
  id: `SUB_INVITE`,
  svg: `
  <path fill="#8697CB"
  d="M13.62 11.26l-2.43-1.22a.93.93 0 01-.51-.82v-.96a5.58 5.58 0 00.98-1.82c.31-.09.54-.38.54-.72V4.7c0-.22-.1-.42-.25-.56V2.67s.3-2.29-2.8-2.29c-3.1 0-2.8 2.29-2.8 2.29v1.47a.76.76 0 00-.25.56v1.02c0 .27.14.5.35.64.26 1.1.92 1.9.92 1.9v.93c0 .34-.18.65-.48.82l-2.27 1.23a2.04 2.04 0 00-1.06 1.8v.82h11.19v-.78c0-.77-.44-1.48-1.13-1.82z" />
<path fill="#556080"
  d="M4.62 11.24l1.51-.82.02-.01.74-.4c.3-.17.48-.48.48-.82v-.93s-.27-.32-.53-.84a5.16 5.16 0 01-.1-.22l-.01-.03a4.71 4.71 0 01-.1-.24v-.01a4.77 4.77 0 01-.09-.23c0-.02 0-.05-.02-.07a4.26 4.26 0 01-.07-.26.76.76 0 01-.35-.64V4.7c0-.22.1-.42.26-.56V2.7a2.8 2.8 0 00-1.78-.54c-2.44 0-2.55 2.03-2.55 2.03v1.27c-.13.13-.25.3-.25.5v.87c0 .23.12.43.3.55.22.96.96 1.65.96 1.65v.8a.8.8 0 01-.42.7L.92 11.6c-.57.3-.92.9-.92 1.54v.72h3.56v-.83c0-.74.4-1.43 1.06-1.79z" />
<path fill="#71C386" d="M11.95 14.62a3.05 3.05 0 100-6.1 3.05 3.05 0 000 6.1z" />
<path fill="#fff"
  d="M13.47 11.31H12.2v-1.27a.25.25 0 00-.5 0v1.27h-1.28a.25.25 0 000 .51h1.27v1.27a.25.25 0 00.51 0v-1.27h1.27a.25.25 0 000-.5z" />

  `
};
