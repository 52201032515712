import { IIcon } from '../../model/icon';

export const send: IIcon = {
  id: `SEND`,
  svg: `
  <g clip-path="url(#clip9)">
  <path d="M23.7.1L.2 13.8a.4.4 0 000 .8l6.7 3L8.6 25l5.2-3.9 6 3.4a.4.4 0 00.7-.3L24.2 5l.8-4a.9.9 0 00-1.3-.9z"
    fill="#9FC9D3" />
  <path d="M22.3 4.6l-.1.1-10.6 15.1-3 5.2L7 17.7 21.6 4.2a.4.4 0 01.7.4z" fill="#069278" />
  <path
    d="M25 1l-.8 4-3.7 19.2a.4.4 0 01-.7.3l-6-3.4-2.2-1.3 10.6-15a.4.4 0 000-.2.4.4 0 00-.6-.4L6.9 17.7.2 14.6a.4.4 0 010-.8L23.7.1a.9.9 0 011.3 1z"
    fill="#00AB8D" />
  <path d="M13.8 21v.1L8.6 25l3-5.2 2.2 1.3z" fill="#3B97D3" />
</g>
<defs>
  <clipPath id="clip9">
    <path fill="#fff" d="M0 0h25v25H0z" />
  </clipPath>
</defs>`
};
