<div class="dialog-content-overlay">
  <button class="btn-close" (click)="closeDialog(); false"></button>
  <h1 mat-dialog-title>Review submission</h1>

  <!--  {{review | json}}-->

  <div mat-dialog-content class="competition-submission-review mb-4">
    <div class="form-wrapper py-0 px-3">
      <form [formGroup]="resultForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <h2 class="mb-1">Team</h2>
          <div>{{review.team?.name}}</div>
        </div>
        <div class="mb-3">
          <h2 class="mb-1">Project</h2>
          <div>{{review.project?.title}}</div>
        </div>
        <div class="mb-3">
          <h2 class="mb-1">Description</h2>
          <div>{{review.description}}</div>
        </div>

        <ng-template [ngIf]="review.fotos?.length">
          <div class="mb-3">
            <h2 class="mb-1">Photos</h2>
            <a
              [href]="photo.url | baseUrl"
              target="_blank"
              class="competition-submission-review-file d-flex"
              *ngFor="let photo of review.fotos"
            >
              <div class="competition-submission-review-file-icon">
                <svg viewBox="0 0 11 13">
                  <use href="#file" />
                </svg>
              </div>
              {{ photo | mimeToExtension }}
            </a>
          </div>
        </ng-template>

        <ng-template [ngIf]="review.docs?.length">
          <div class="mb-3">
            <h2 class="mb-1">Files</h2>
            <a
              [href]="doc.url | baseUrl"
              target="_blank"
              class="competition-submission-review-file d-flex"
              *ngFor="let doc of review.docs"
            >
              <div class="competition-submission-review-file-icon">
                <svg viewBox="0 0 11 13">
                  <use href="#file" />
                </svg>
              </div>
              {{ doc | mimeToExtension }}
            </a>
          </div>
        </ng-template>

        <div class="mb-3">
          <h2 class="mb-1">Status</h2>
          <div>{{review.status | submissionStatus}}</div>
        </div>
        <div>
          <h2 class="mb-1">Jury</h2>
          <div class="mb-3" *ngIf="review.status < 2;else justJury">
            <mat-form-field class="w-100">
              <mat-label>Jury</mat-label>
              <mat-select formControlName="jury_id" required>
                <mat-option *ngFor="let jury of availableJury$ | async" [value]="jury.id"> {{jury.name}} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-template #justJury>
            <div *ngIf="(findJuryName$(review.jury_id) | async) as jury">{{jury.name}}</div>
          </ng-template>
        </div>
        <div class="mb-3">
          <h2 class="mb-1">Score</h2>
          <div>{{review.rating}}</div>
        </div>
        <div class="mb-3">
          <h2 class="mb-1">Jury note</h2>
          <mat-form-field class="w-100" *ngIf="review.status === 2;else justNote">
            <mat-label>Jury note</mat-label>
            <textarea formControlName="note" matInput placeholder="Write review"></textarea>
          </mat-form-field>
          <ng-template #justNote>
            <div>{{review.note}}</div>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSubmit(1); false;" [disabled]="!resultForm.valid">Update</button>
    <button class="alt-action-btn" (click)="onSubmit(4); false;" [disabled]="review.status < 2">Resend to team</button>
  </div>
</div>
