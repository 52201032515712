import * as Immutable from 'immutable';

import { PostState } from '../model/state';

export const postsFeatureKey = 'posts';

export const initialState: PostState = {
  posts: Immutable.OrderedMap(),
  comments: {},
  replies: {},
  postView: [],
  status: `INITIAL`,
  nextPage: 0
};
