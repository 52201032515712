import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { Contact } from 'src/app/core/services/contacts/contact.model';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { IGroupChatMember } from '../../../model';

@Component({
  selector: 'app-chat-new-group-chat-dialog',
  templateUrl: './edit-group-chat-dialog.component.html',
  styleUrls: ['./edit-group-chat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditGroupChatDialogComponent implements OnInit, OnDestroy {
  @Output() loadMoreContacts = new EventEmitter<void>();
  @Output() filter = new EventEmitter<string>();

  _selectedParticipants = new Map<number, boolean>();
  private _componentDestroy$ = new Subject();

  readonly fields = {
    name: new FormControl(null, Validators.required)
  };

  readonly groupChatForm = new FormGroup(this.fields);

  constructor(
    private dialogRef: MatDialogRef<EditGroupChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditGroupChatDialogComponent.Data
  ) {}

  ngOnInit() {
    this.data.chatTitle$
      .pipe(
        takeUntil(this._componentDestroy$),
        filter((_) => !!_),
        take(1),
        tap((_) => this.groupChatForm.patchValue({ name: _ }))
      )
      .subscribe((_) => _);

    this.data.chatParticipants$
      .pipe(
        takeUntil(this._componentDestroy$),
        filter((_) => !!_),
        take(1),
        tap((_) =>
          _?.forEach((_) => this._selectedParticipants.set(_.id, true))
        )
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  get chatParticipants$() {
    return this.data.chatParticipants$;
  }

  get contacts$() {
    return this.data.contacts$;
  }

  get isLoadedContacts$() {
    return this.data.isLoadingContacts$.pipe(map((_) => !_));
  }

  isSelectedContact(id: number) {
    return this._selectedParticipants.get(id) || false;
  }

  onSelectContact(id: number) {
    this._selectedParticipants.set(id, true);
  }

  onUnSelectContact(id: number) {
    this._selectedParticipants.set(id, false);
  }

  onSave() {
    const membersToAdd = [...this._selectedParticipants.entries()]
      .filter((_) => _[1])
      .map((_) => _[0]);

    const membersToRemove = [...this._selectedParticipants.entries()]
      .filter((_) => !_[1])
      .map((_) => _[0]);

    this.dialogRef.close({
      title: this.groupChatForm.value.name,
      membersToAdd,
      membersToRemove
    });
  }

  onScrolled() {
    this.loadMoreContacts.emit();
  }

  onFilterChange(event: Event) {
    const { value } = event.target as HTMLInputElement;

    this.filter.emit(value);
  }

  close(): void {
    this.dialogRef.close();
  }
}

export namespace EditGroupChatDialogComponent {
  export interface Data {
    contacts$: Observable<Contact[]>;
    isLoadingContacts$: Observable<boolean>;
    chatTitle$: Observable<string>;
    chatParticipants$: Observable<IGroupChatMember[]>;
    isLoadingChatParticipants$: Observable<boolean>;
  }
}
