import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  forwardRef,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.service';
import { BaseSelectComponent } from '../base-select/base-select.component';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true
    }
  ]
})
export class CustomSelectComponent
  extends BaseSelectComponent
  implements OnInit
{
  @Input() field: string;
  list$: Observable<any>;

  constructor(private service: BaseService) {
    super();
  }

  ngOnInit(): void {
    this.list$ = this.service.getFields(this.field);
  }
}
