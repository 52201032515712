import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-link',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchLinkComponent {
  @Input() readonly topic: string;
  @Input() readonly value: string;

  public route = [environment.routePrefix, `search`];
  public params: Record<string, string>;

  constructor() {}

  ngOnChanges(): void {
    this.params =
      this.topic === 'org'
        ? {
            org_id: this.value,
            kind: 'members'
          }
        : { q: this.value };
  }
}
