import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { omitBy, isEmpty } from 'lodash';

import { BaseService } from 'src/app/core/services/base.service';
import { NotEmptyURLSearchParams } from 'src/app/shared/types/not-empty-url-search-params.type';
import { APIService } from 'src/app/shared/services/api.service';

import {
  INewArticle,
  INewProject,
  IProject,
  IArticle,
  INewCompetitionProject,
  ICompetitionProject
} from '../model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  constructor(
    private base: BaseService,
    private readonly apiService: APIService
  ) {}

  list(p: {
    page: number;
    count: number;
    categoryId: number;
  }): Observable<{ projects: Array<IProject>; totalAmount: number }> {
    const params = new NotEmptyURLSearchParams({
      page: p.page ? p.page + '' : undefined,
      count_of_page: p.count ? p.count + '' : undefined,
      category_id: p.categoryId ? p.categoryId + '' : undefined
    });

    return this.apiService
      .get<{ projects: IProject[]; count: number }>(`projects?${params}`)
      .pipe(map((_) => ({ projects: _.projects, totalAmount: _.count })));
  }

  create(project: INewProject): Observable<{ project: IProject }> {
    return this.base
      .post('project/add/0?', project)
      .pipe(map((_) => ({ project: _.project })));
  }

  edit(project: IProject): Observable<{ project: IProject }> {
    return this.base
      .post(`project/add/${project.id}?`, project)
      .pipe(map((_) => ({ project: _.project })));
  }

  delete(id: number): Observable<{ id: number }> {
    return this.base.get(`project/del/${id}`).pipe(map((_) => ({ id })));
  }

  createCompetitionProject(
    project: INewCompetitionProject
  ): Observable<{ project: ICompetitionProject }> {
    return this.base
      .post(`competition/project/${project.competition_id}`, project)
      .pipe(map((_) => ({ project: _.project })));
  }

  listCompetitionsProjects(
    competitionId: number,
    page?: number,
    term?: string
  ): Observable<{ projects: ICompetitionProject[]; totalAmount: number }> {
    const params: { page?: string; term?: string } = {};

    if (page) {
      params.page = page + '';
    }

    if (term) {
      params.term = term + '';
    }

    const urlParams = new URLSearchParams({
      ...omitBy(params, isEmpty)
    });

    return this.apiService
      .get<{ projects: ICompetitionProject[]; count: number }>(
        `competitions/projects/${competitionId}?${urlParams}`
      )
      .pipe(map((_) => ({ projects: _.projects, totalAmount: _.count })));
  }

  createArticle(article: INewArticle): Observable<{ article: IArticle }> {
    const specialPrivateCategoryOfDiscussion = 10;

    return this.base
      .post('discussion/edit/0?', {
        ...article,
        category_id: specialPrivateCategoryOfDiscussion
      })
      .pipe(map((_) => ({ article: _.discussion })));
  }
}
