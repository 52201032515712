<div class="dialog-content-overlay">
  <button class="btn-close" (click)="closeDialog(); false"></button>
  <h1 mat-dialog-title>Team: {{team.name}}</h1>

  <div mat-dialog-content class="competition-submission-review mb-4">
    <div class="mb-3">
      <h2 class="mb-1">Admin</h2>
      <div>
        <a [profile]="team.admin" class="author d-flex align-items-center">
          <div class="avatar rounded-circle">
            <app-avatar [src]="team.admin.icon" [placeholder]="team.admin | initials" class="img-fluid"> </app-avatar>
          </div>
          <div>
            <div class="name d-flex align-items-center"> {{ team.admin.name }} </div>
          </div>
        </a>
      </div>
      <h2 class="mb-1 mt-2">Members</h2>
      <div *ngFor="let user of team.users" class="mb-1">
        <a [profile]="user" class="author d-flex align-items-center">
          <div class="avatar rounded-circle">
            <app-avatar [src]="user.icon" [placeholder]="user | initials" class="img-fluid"> </app-avatar>
          </div>
          <div>
            <div class="name d-flex align-items-center"> {{ user.name }} </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div mat-dialog-actions *ngIf="team.status === 0">
    <button class="main-action-btn" (click)="onConfirmTeam(team); false;">Confirm</button>
    <button class="alt-action-btn" (click)="onRejectTeam(team); false;">Reject</button>
  </div>
</div>
