import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ICompetition } from '../../../../model';

@Component({
  selector: 'app-competitions-short-list-cmp',
  templateUrl: './competitions-short-list.component.html',
  styleUrls: ['./competitions-short-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitionsShortListComponent {
  @Input('current') private readonly _current?: ICompetition;
  @Input('upcoming') private readonly _upcoming?: ICompetition;
  @Input('previous') private readonly _previous?: ICompetition;

  get current() {
    return this._current;
  }

  get upcoming() {
    return this._upcoming;
  }

  get previous() {
    return this._previous;
  }
}
