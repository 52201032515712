import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  tap
} from 'rxjs/operators';
import lodash from 'lodash';

import { CompetitionsFacade } from 'src/app/modules/competitions/store';

@Component({
  selector: 'app-competition-participants',
  templateUrl: './competition-participants.component.html',
  styleUrls: ['./competition-participants.component.scss']
})
export class CompetitionParticipantsComponent implements OnInit, OnDestroy {
  static readonly AMOUNT_OF_PARTICIPANTS_PER_PAGE = 20;

  @Input() isAdminView: boolean;

  private readonly id$ = new BehaviorSubject(null);
  private readonly page$ = new BehaviorSubject(null);
  private readonly componentDestroy$ = new Subject();

  get currentPage$() {
    return this.page$;
  }

  get participantsTotalAmountOfPages$() {
    return this.competitionsFacade.participantsTotalAmount$.pipe(
      takeUntil(this.componentDestroy$),
      map((_) =>
        Math.ceil(
          _ / CompetitionParticipantsComponent.AMOUNT_OF_PARTICIPANTS_PER_PAGE
        )
      )
    );
  }

  get participants$() {
    return this.competitionsFacade.participants$;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly competitionsFacade: CompetitionsFacade
  ) {}

  ngOnInit(): void {
    this.route.parent.params
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_.id),
        tap((_) => this.id$.next(_.id))
      )
      .subscribe((_) => _);

    this.route.queryParams
      .pipe(
        takeUntil(this.componentDestroy$),
        map((_) => ({
          page: Number.isSafeInteger(Number(_.page))
            ? Number(_.page)
            : undefined
        })),
        tap((_) => this.page$.next(_.page || 1))
      )
      .subscribe((_) => _);

    combineLatest([this.id$, this.page$])
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_[0] && !!_[1]),
        distinctUntilChanged((prev, curr) => lodash.isEqual(prev, curr)),
        tap((_) =>
          this.competitionsFacade.loadCompetitionParticipants({
            id: _[0],
            page: _[1]
          })
        )
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  onRemove(id: number) {
    if (!this.id$.value) {
      return;
    }

    this.competitionsFacade.removeCompetitionParticipant({
      id: this.id$.value,
      groupID: id
    });
  }
}
