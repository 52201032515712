import { IIcon } from '../../model/icon';

export const pencil: IIcon = {
  id: `PENCIL`,
  svg: `
  <path fill="#FDD7AD" d="M19.31 2.41c0 .26-.6.52-.48.74l-1.93.64-.7-1.38.7-1.38 1.93.65c-.11.22.48.47.48.73z" />
  <path fill="#BDC3C7" d="M5.17 1.03h-3.8V3.8h3.8V1.03z" />
  <path fill="#D75E72" d="M2.76 1.03V3.8H.69A.7.7 0 010 3.1V1.72c0-.38.3-.68.69-.69h2.07z" />
  <path fill="#F0C419" d="M16.9 1.03H4.14V3.8H16.9V1.03z" />
  <path fill="#2C2F38"
    d="M20 2.41c0 .21-.13.4-.33.46l-.84.28a1.6 1.6 0 01-.2-.74c0-.25.08-.5.2-.73l.84.28c.2.06.33.25.33.45z" />
  <path fill="#FDD7AD" d="M8.62 2.76l-2.07.02.25-.02h1.82z" />
  <path fill="#FDD7AD"
    d="M14.83 1.38V6.2c0 .11 0 .22-.02.34a4.78 4.78 0 01-.97 2.5l-1.3 1.75c-.08.15-.13.32-.13.5v3.53c0 .19-.15.34-.34.34H7.24a.34.34 0 01-.34-.34v-4.37c0-.16-.04-.32-.11-.46l-2.1-3.38a1.03 1.03 0 01-.03-.84l.85-2.16c.1-.25.28-.47.51-.62.16-.11.34-.19.53-.22l2.07-.02H6.8c-.09 0-.17 0-.25.02v-1.4a1.03 1.03 0 012.07 0 1.03 1.03 0 112.07 0 1.03 1.03 0 112.07 0 1.03 1.03 0 012.07 0z" />
  <path fill="#FDD7AD" d="M8.62 2.76l-2.07.02.25-.02h1.82z" />
  <path fill="#CBB292"
    d="M13.8 5.17c-.77 0-1.39-.62-1.39-1.38V1.38a1.38 1.38 0 012.76 0v2.41c0 .76-.62 1.38-1.38 1.38zm0-4.48c-.39 0-.7.3-.7.69v2.41a.69.69 0 101.38 0V1.38c0-.38-.3-.69-.69-.69z" />
  <path fill="#CBB292"
    d="M11.72 5.17c-.76 0-1.38-.62-1.38-1.38V1.38a1.38 1.38 0 012.76 0v2.41c0 .76-.61 1.38-1.38 1.38zm0-4.48c-.38 0-.69.3-.69.69v2.41a.69.69 0 001.38 0V1.38c0-.38-.3-.69-.69-.69z" />
  <path fill="#CBB292"
    d="M10.69 4.14a.34.34 0 01-.35-.35V1.38a.69.69 0 10-1.37 0v1.38a.34.34 0 11-.7 0V1.38a1.38 1.38 0 012.76 0v2.41c0 .2-.15.35-.34.35z" />
  <path fill="#CBB292"
    d="M6.55 3.13a.34.34 0 01-.34-.35v-1.4a1.38 1.38 0 012.76 0v1.38a.34.34 0 11-.7 0V1.38a.69.69 0 00-1.37 0v1.4c0 .2-.16.35-.35.35z" />
  <path fill="#CBB292"
    d="M12.07 15.52H7.24a.69.69 0 01-.69-.7v-4.36a.7.7 0 00-.07-.3L4.4 6.8a1.4 1.4 0 01-.06-1.15l.85-2.15c.26-.66.9-1.09 1.6-1.09H10A1.04 1.04 0 0111 3.61a2.14 2.14 0 01-2.1 1.9c.83.59 1.79 1.6 1.79 3.11a.34.34 0 11-.69 0c0-2.14-2.51-3.12-2.54-3.13a.34.34 0 01.13-.66h1.03c.98 0 1.56-.45 1.7-1.33a.33.33 0 00-.07-.27.35.35 0 00-.27-.13H6.8c-.43 0-.8.26-.96.65L4.98 5.9a.69.69 0 00.02.57l2.08 3.35c.1.2.16.42.16.64v4.37h4.83v-3.54a1.38 1.38 0 01.19-.7l1.3-1.75a4.46 4.46 0 00.92-2.63V3.45a.34.34 0 01.7 0V6.2l-.02.36c-.09.98-.45 1.9-1.04 2.69l-1.29 1.73a.7.7 0 00-.07.3v3.54c0 .38-.31.69-.7.69z" />

  `
};
