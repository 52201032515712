<div class="theme-block p-3 mb-3" *ngIf="(competition$ | async) as competition">
  <ng-container *ngIf="(projects$ | async) as projects; else loading">
    <h3>Thank you for joining RoundPier judging panel</h3>

    <div class="competition-projects-wrap">
      <div class="competition-projects-item" *ngFor="let project of competitionJuryProjects$ | async">
        <div class="competition-projects-item-top">
          <div class="competition-projects-item-icon">
            <img
              src="{{project.icon | appBaseUrl:'assets'}}"
              [alt]="project.title"
              *ngIf="project.icon_id; else defaultIcon"
            />
          </div>
        </div>
        <div class="competition-projects-item-data">
          <div class="competition-projects-item-title">{{project.title}}</div>
          <div class="competition-projects-item-deadline">
            <span>Deadline: </span>
            {{project.end_at}}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>

<ng-template #defaultIcon>
  <svg viewBox="0 0 66 66">
    <use href="#project" />
  </svg>
</ng-template>
