import { createAction, props } from '@ngrx/store';

export const showLoaders = createAction(
  '[Loader/API] Show Loaders',
  props<{ show: boolean }>()
);

export const hideLoaders = createAction(
  '[Loader/API] Hide Loaders',
  props<{ show: boolean }>()
);
