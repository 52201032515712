import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface Contact {
  id: number;
  name: string;
  online_str: string;
  icon: string;
  // TODO: remove this stuff
  // just id, name, online_str and icon
  // related to app-avatar TODOs
  teacher?: {
    lastname: string;
  };
  student?: {
    lastname: string;
  };
}

@Component({
  selector: 'app-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
  styleUrls: ['./new-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewMessageDialogComponent implements OnInit {
  message: string = '';

  constructor(
    public dialogRef: MatDialogRef<NewMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contact: Contact;
      receiverID: string;
      customTitle?: string;
      customPlaceholder?: string;
    }
  ) {}

  closeDialog(isSend): void {
    this.dialogRef.close({
      contact: this.data.contact.id,
      message: this.message.trim(),
      isSend
    });
  }

  ngOnInit(): void {}
}
