import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[appHideForAuthorized]'
})
export class AppHideForAuthorizedDirective implements OnInit, OnDestroy {
  private _destroy$ = new Subject();

  constructor(
    private readonly _templateRef: TemplateRef<any>,
    private readonly _viewContainer: ViewContainerRef,
    // TODO: it should accept this module as a dependency provider
    private readonly _authService: AuthService
  ) {}

  ngOnInit() {
    this._authService.isFullyAuthorized$
      .pipe(
        distinctUntilChanged(),
        tap(this.process),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private process = (isFullyAuthorized: boolean) => {
    if (isFullyAuthorized) {
      return this._viewContainer.clear();
    }

    // https://github.com/angular/angular/issues/12243
    return this._viewContainer
      .createEmbeddedView(this._templateRef)
      .detectChanges();
  };
}
