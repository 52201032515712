import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { IDoc } from 'src/app/shared/models/doc';
import { DocsFacade } from 'src/app/shared/stores/docs/docs.facade';

interface IContext {
  upload: Subject<void>;
  uploaded: Subject<number[]>;
}

@Component({
  selector: 'app-select-docs',
  templateUrl: './select-docs.component.html',
  styleUrls: ['./select-docs.component.scss']
})
export class SelectDocsComponent implements OnInit, OnDestroy {
  @Input() ctx: IContext;

  private readonly _uploadInProgress$ = new BehaviorSubject(false);
  private readonly _componentDestroy$ = new Subject();

  readonly docs = {
    stream: this.docsFacade.all,
    saved: new Set<IDoc>(),
    local: new Set<File>()
  };

  constructor(public docsFacade: DocsFacade) {}

  ngOnInit() {
    this.handleUploadEvent();
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  private handleUploadEvent() {
    if (!this.ctx.upload) {
      return;
    }

    this.ctx.upload
      .pipe(
        filter((_) => !this._uploadInProgress$.value),
        tap((_) => this._uploadInProgress$.next(true)),
        mergeMap((_) => this.handleUpload()),
        tap((_) => this._uploadInProgress$.next(false)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  private async handleUpload() {
    const savedDocs = [...this.docs.saved].map(({ id }) => id);
    const _docs = [...this.docs.local];

    this.docs.saved.clear();
    this.docs.local.clear();

    // TODO: handle error case
    const docs = await this.docsFacade.add(_docs);

    const addedDocs = docs.map(({ id }) => id);

    this.ctx.uploaded.next([...addedDocs, ...savedDocs]);
    this.ctx.uploaded.complete();
  }
}
