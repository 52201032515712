import { ActionReducerMap } from '@ngrx/store';

import {
  State as RegistrationReducerState,
  Reducer as RegistrationReducer
} from './registration.reducer';

export interface State {
  registration: RegistrationReducerState;
}

export const registrationFeatureKey = 'registration';

export const reducers: ActionReducerMap<State> = {
  registration: RegistrationReducer
};
