import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';

import _ from 'lodash';

import { Notification } from 'src/app/core/services/notifications/notification.model';
import { RoutingService } from 'src/app/core/services/routing.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  @Input() notifications: Notification[];

  @Output() readAll = new EventEmitter<void>();
  @Output() readNotification = new EventEmitter<number>();
  @Output() loadMore = new EventEmitter<void>();

  @ViewChild('notificationsContainer')
  private notificationsContainer: ElementRef;

  tryLoadMoreDebounce = _.debounce(this.tryLoadMore.bind(this), 100);

  constructor(private routingService: RoutingService, private router: Router) {}

  profileLink(profile) {
    return this.routingService.getProfileLink(profile);
  }

  onNotificationsScroll() {
    const { scrollTop, offsetHeight, scrollHeight } =
      this.notificationsContainer.nativeElement;

    const height = scrollTop + offsetHeight;

    if ((height * 100) / scrollHeight > 75) {
      this.tryLoadMoreDebounce();
    }
  }

  private tryLoadMore() {
    this.loadMore.emit();
  }
}
