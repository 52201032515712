import { Action } from '@ngrx/store';

import {
  ICompetition,
  ICompetitionResult,
  ICompetitionNews,
  ICompetitionReview,
  ICompetitionRequest,
  ICompetitionParticipant,
  ICompetitionTeam,
  INewCompetitionTeam,
  ICompetitionMember,
  ICompetitionGroup,
  ICompetitionJury
} from '../../model';

export enum ActionTypes {
  LOAD_COMPETITION = '[Competition] Load Competition',
  LOAD_COMPETITION_FAILURE = '[Competition] Load Competition Failure',
  LOAD_COMPETITION_SUCCESS = '[Competition] Load Competition Success',

  JOIN_COMPETITION = '[Competition] Join Competition',
  JOIN_COMPETITION_FAILURE = '[Competition] Join Competition Failure',
  JOIN_COMPETITION_SUCCESS = '[Competition] Join Competition Success',

  LEAVE_COMPETITION = '[Competition] Leave Competition',
  LEAVE_COMPETITION_FAILURE = '[Competition] Leave Competition Failure',
  LEAVE_COMPETITION_SUCCESS = '[Competition] Leave Competition Success',

  CREATE_COMPETITION = '[Competition] Create Competition',
  CREATE_COMPETITION_FAILURE = '[Competition] Create Competition Failure',
  CREATE_COMPETITION_SUCCESS = '[Competition] Create Competition Success',

  UPDATE_COMPETITION = '[Competition] Update Competition',
  UPDATE_COMPETITION_FAILURE = '[Competition] Update Competition Failure',
  UPDATE_COMPETITION_SUCCESS = '[Competition] Update Competition Success',

  LOAD_COMPETITION_PARTICIPANTS = '[Competition] Load Competition Participants',
  LOAD_COMPETITION_PARTICIPANTS_FAILURE = '[Competition] Load Competition Participants Failure',
  LOAD_COMPETITION_PARTICIPANTS_SUCCESS = '[Competition] Load Competition Participants Success',

  LOAD_COMPETITION_REQUESTS = '[Competition] Load Competition Requests',
  LOAD_COMPETITION_REQUESTS_FAILURE = '[Competition] Load Competition Requests Failure',
  LOAD_COMPETITION_REQUESTS_SUCCESS = '[Competition] Load Competition Requests Success',

  ACCEPT_COMPETITION_REQUEST = '[Competition] Accept Competition Request',
  ACCEPT_COMPETITION_REQUEST_FAILURE = '[Competition] Accept Competition Request Failure',
  ACCEPT_COMPETITION_REQUEST_SUCCESS = '[Competition] Accept Competition Request Success',

  DECLINE_COMPETITION_REQUEST = '[Competition] Decline Competition Request',
  DECLINE_COMPETITION_REQUEST_FAILURE = '[Competition] Decline Competition Request Failure',
  DECLINE_COMPETITION_REQUEST_SUCCESS = '[Competition] Decline Competition Request Success',

  REMOVE_COMPETITION_PARTICIPANT = '[Competition] Remove Competition Participant',
  REMOVE_COMPETITION_PARTICIPANT_FAILURE = '[Competition] Remove Competition Participant Failure',
  REMOVE_COMPETITION_PARTICIPANT_SUCCESS = '[Competition] Remove Competition Participant Success',

  SUBMIT_COMPETITION_NEW = '[Competition] Submit Competition News',
  SUBMIT_COMPETITION_NEW_FAILURE = '[Competition] Submit Competition News Failure',
  SUBMIT_COMPETITION_NEW_SUCCESS = '[Competition] Submit Competition News Success',

  SUBMIT_COMPETITION_RESULT = '[Competition] Submit Competition Result',
  SUBMIT_COMPETITION_RESULT_FAILURE = '[Competition] Submit Competition Result Failure',
  SUBMIT_COMPETITION_RESULT_SUCCESS = '[Competition] Submit Competition Result Success',

  UPDATE_COMPETITION_RESULT = '[Competition] Update Competition Result',
  UPDATE_COMPETITION_RESULT_FAILURE = '[Competition] Update Competition Result Failure',
  UPDATE_COMPETITION_RESULT_SUCCESS = '[Competition] Update Competition Result Success',

  RATE_COMPETITION_RESULT = '[Competition] Rate Competition Result',
  RATE_COMPETITION_RESULT_FAILURE = '[Competition] Rate Competition Result Failure',
  RATE_COMPETITION_RESULT_SUCCESS = '[Competition] Rate Competition Result Success',

  LOAD_COMPETITION_REVIEWS = '[Competition] Load Competition Reviews',
  LOAD_COMPETITION_REVIEWS_FAILURE = '[Competition] Load Competition Reviews Failure',
  LOAD_COMPETITION_REVIEWS_SUCCESS = '[Competition] Load Competition Reviews Success',

  LOAD_COMPETITION_JURY_SUBMISSIONS = '[Competition] Load Jury Competition Submissions',
  LOAD_COMPETITION_JURY_SUBMISSIONS_FAILURE = '[Competition] Load Jury Competition Submissions Failure',
  LOAD_COMPETITION_JURY_SUBMISSIONS_SUCCESS = '[Competition] Load Jury Competition Submissions Success',

  ADD_COMPETITION_TEAM = '[Competition] Add Competition Team',
  ADD_COMPETITION_TEAM_FAILURE = '[Competition] Add Competition Team Failure',
  ADD_COMPETITION_TEAM_SUCCESS = '[Competition] Add Competition Team Success',

  CONFIRM_COMPETITION_TEAM = '[Competition] Confirm Competition Team',
  CONFIRM_COMPETITION_TEAM_FAILURE = '[Competition] Confirm Competition Team Failure',
  CONFIRM_COMPETITION_TEAM_SUCCESS = '[Competition] Confirm Competition Team Success',

  REJECT_COMPETITION_TEAM = '[Competition] Reject Competition Team',
  REJECT_COMPETITION_TEAM_FAILURE = '[Competition] Reject Competition Team Failure',
  REJECT_COMPETITION_TEAM_SUCCESS = '[Competition] Reject Competition Team Success',

  REMOVE_COMPETITION_TEAM = '[Competition] Remove Competition Team',
  REMOVE_COMPETITION_TEAM_FAILURE = '[Competition] Remove Competition Team Failure',
  REMOVE_COMPETITION_TEAM_SUCCESS = '[Competition] Remove Competition Team Success',

  LOAD_COMPETITION_TEAMS = '[Competition] Load Competition Teams',
  LOAD_COMPETITION_TEAMS_FAILURE = '[Competition] Load Competition Teams Failure',
  LOAD_COMPETITION_TEAMS_SUCCESS = '[Competition] Load Competition Teams Success',

  FIND_MEMBERS = '[Competition] Find Members',
  FIND_MEMBERS_FAILURE = '[Competition] Find Members Failure',
  FIND_MEMBERS_SUCCESS = '[Competition] Find Members Success',

  LOAD_MY_GROUPS = '[Competition] Load My Groups',
  LOAD_MY_GROUPS_FAILURE = '[Competition] Load My Groups Failure',
  LOAD_MY_GROUPS_SUCCESS = '[Competition] Load My Groups Success',

  LOAD_JURY = '[Competition] Load Jury',
  LOAD_JURY_FAILURE = '[Competition] Load Jury Failure',
  LOAD_JURY_SUCCESS = '[Competition] Load Jury Success',

  ADD_JURY = '[Competition] Add Jury',
  ADD_JURY_FAILURE = '[Competition] Add Jury Failure',
  ADD_JURY_SUCCESS = '[Competition] Add Jury Success',

  EDIT_JURY = '[Competition] Edit Jury',
  EDIT_JURY_FAILURE = '[Competition] Edit Jury Failure',
  EDIT_JURY_SUCCESS = '[Competition] Edit Jury Success',

  DELETE_JURY = '[Competition] Delete Jury',
  DELETE_JURY_FAILURE = '[Competition] Delete Jury Failure',
  DELETE_JURY_SUCCESS = '[Competition] Delete Jury Success'
}

export class LoadCompetitionAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION;
  constructor(public payload: { id: number }) {}
}

export class LoadCompetitionSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_SUCCESS;
  constructor(public payload: { competition: ICompetition }) {}
}

export class LoadCompetitionFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class JoinCompetitionAction implements Action {
  readonly type = ActionTypes.JOIN_COMPETITION;
  constructor(public payload: { id: number; groupID: number }) {}
}

export class JoinCompetitionSuccessAction implements Action {
  readonly type = ActionTypes.JOIN_COMPETITION_SUCCESS;
  constructor(public payload: {}) {}
}

export class JoinCompetitionFailureAction implements Action {
  readonly type = ActionTypes.JOIN_COMPETITION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LeaveCompetitionAction implements Action {
  readonly type = ActionTypes.LEAVE_COMPETITION;
  constructor(public payload: { id: number; groupID: number }) {}
}

export class LeaveCompetitionSuccessAction implements Action {
  readonly type = ActionTypes.LEAVE_COMPETITION_SUCCESS;
  constructor(public payload: {}) {}
}

export class LeaveCompetitionFailureAction implements Action {
  readonly type = ActionTypes.LEAVE_COMPETITION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateCompetitionAction implements Action {
  readonly type = ActionTypes.CREATE_COMPETITION;
  constructor(public payload: { competition: object }) {}
}

export class CreateCompetitionSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_COMPETITION_SUCCESS;
  constructor(public payload: { competition: ICompetition }) {}
}

export class CreateCompetitionFailureAction implements Action {
  readonly type = ActionTypes.CREATE_COMPETITION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateCompetitionAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION;
  constructor(public payload: { id: number; competition: object }) {}
}

export class UpdateCompetitionSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_SUCCESS;
  constructor(public payload: { competition: ICompetition }) {}
}

export class UpdateCompetitionFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCompetitionParticipantsAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_PARTICIPANTS;
  constructor(public payload: { id: number; page: number }) {}
}

export class LoadCompetitionParticipantsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_PARTICIPANTS_SUCCESS;
  constructor(
    public payload: {
      participants: ICompetitionParticipant[];
      totalAmount: number;
    }
  ) {}
}

export class LoadCompetitionParticipantsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_PARTICIPANTS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCompetitionRequestsAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_REQUESTS;
  constructor(public payload: { id: number; page: number }) {}
}

export class LoadCompetitionRequestsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_REQUESTS_SUCCESS;
  constructor(
    public payload: { requests: ICompetitionRequest[]; totalAmount: number }
  ) {}
}

export class LoadCompetitionRequestsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_REQUESTS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AcceptCompetitionRequestAction implements Action {
  readonly type = ActionTypes.ACCEPT_COMPETITION_REQUEST;
  constructor(public payload: { id: number; groupID: number }) {}
}

export class AcceptCompetitionRequestSuccessAction implements Action {
  readonly type = ActionTypes.ACCEPT_COMPETITION_REQUEST_SUCCESS;
  constructor(public payload: { groupID: number }) {}
}

export class AcceptCompetitionRequestFailureAction implements Action {
  readonly type = ActionTypes.ACCEPT_COMPETITION_REQUEST_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeclineCompetitionRequestAction implements Action {
  readonly type = ActionTypes.DECLINE_COMPETITION_REQUEST;
  constructor(public payload: { id: number; groupID: number }) {}
}

export class DeclineCompetitionRequestSuccessAction implements Action {
  readonly type = ActionTypes.DECLINE_COMPETITION_REQUEST_SUCCESS;
  constructor(public payload: { groupID: number }) {}
}

export class DeclineCompetitionRequestFailureAction implements Action {
  readonly type = ActionTypes.DECLINE_COMPETITION_REQUEST_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class RemoveCompetitionParticipantAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPETITION_PARTICIPANT;
  constructor(public payload: { id: number; groupID: number }) {}
}

export class RemoveCompetitionParticipantSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPETITION_PARTICIPANT_SUCCESS;
  constructor(public payload: { groupID: number }) {}
}

export class RemoveCompetitionParticipantFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPETITION_PARTICIPANT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SubmitCompetitionNewsAction implements Action {
  readonly type = ActionTypes.SUBMIT_COMPETITION_NEW;
  constructor(public payload: { id?: number; message: ICompetitionNews }) {}
}

export class SubmitCompetitionNewsSuccessAction implements Action {
  readonly type = ActionTypes.SUBMIT_COMPETITION_NEW_SUCCESS;
  constructor(public payload: {}) {}
}

export class SubmitCompetitionNewsFailureAction implements Action {
  readonly type = ActionTypes.SUBMIT_COMPETITION_NEW_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SubmitCompetitionResultAction implements Action {
  readonly type = ActionTypes.SUBMIT_COMPETITION_RESULT;
  constructor(public payload: { result: ICompetitionResult }) {}
}

export class SubmitCompetitionResultSuccessAction implements Action {
  readonly type = ActionTypes.SUBMIT_COMPETITION_RESULT_SUCCESS;
  // constructor(public payload: { result: ICompetitionReview }) {}
  constructor(public payload: { result: ICompetitionResult }) {}
}

export class SubmitCompetitionResultFailureAction implements Action {
  readonly type = ActionTypes.SUBMIT_COMPETITION_RESULT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateCompetitionResultAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_RESULT;
  constructor(public payload: { submission: ICompetitionReview }) {}
}

export class UpdateCompetitionResultSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_RESULT_SUCCESS;
  constructor(public payload: { submission: ICompetitionReview }) {}
}

export class UpdateCompetitionResultFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_COMPETITION_RESULT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class RateCompetitionResultAction implements Action {
  readonly type = ActionTypes.RATE_COMPETITION_RESULT;
  constructor(
    public payload: { submission: ICompetitionReview; jury: ICompetitionJury }
  ) {}
}

export class RateCompetitionResultSuccessAction implements Action {
  readonly type = ActionTypes.RATE_COMPETITION_RESULT_SUCCESS;
  constructor(public payload: { submission: ICompetitionReview }) {}
}

export class RateCompetitionResultFailureAction implements Action {
  readonly type = ActionTypes.RATE_COMPETITION_RESULT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCompetitionReviewsAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_REVIEWS;
  constructor(public payload: { id: number; page: number }) {}
}

export class LoadCompetitionReviewsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_REVIEWS_SUCCESS;
  constructor(
    public payload: { reviews: ICompetitionReview[]; totalAmount: number }
  ) {}
}

export class LoadCompetitionReviewsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_REVIEWS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCompetitionJurySubmissionsAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_JURY_SUBMISSIONS;
  constructor(
    public payload: {
      token: string;
      juryId: number;
      competitionId: number;
      page: number;
    }
  ) {}
}

export class LoadCompetitionJurySubmissionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_JURY_SUBMISSIONS_SUCCESS;
  constructor(
    public payload: { reviews: ICompetitionReview[]; totalAmount: number }
  ) {}
}

export class LoadCompetitionJurySubmissionsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_JURY_SUBMISSIONS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AddCompetitionTeamAction implements Action {
  readonly type = ActionTypes.ADD_COMPETITION_TEAM;
  constructor(public payload: { team: INewCompetitionTeam }) {}
}

export class AddCompetitionTeamFailureAction implements Action {
  readonly type = ActionTypes.ADD_COMPETITION_TEAM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AddCompetitionTeamSuccessAction implements Action {
  readonly type = ActionTypes.ADD_COMPETITION_TEAM_SUCCESS;
  constructor(
    public payload: { team: ICompetitionTeam; competitionId: number }
  ) {}
}

export class ConfirmCompetitionTeamAction implements Action {
  readonly type = ActionTypes.CONFIRM_COMPETITION_TEAM;
  constructor(public payload: { team: ICompetitionTeam }) {}
}

export class ConfirmCompetitionTeamFailureAction implements Action {
  readonly type = ActionTypes.CONFIRM_COMPETITION_TEAM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ConfirmCompetitionTeamSuccessAction implements Action {
  readonly type = ActionTypes.CONFIRM_COMPETITION_TEAM_SUCCESS;
  constructor(public payload: { team: ICompetitionTeam }) {}
}

export class RejectCompetitionTeamAction implements Action {
  readonly type = ActionTypes.REJECT_COMPETITION_TEAM;
  constructor(public payload: { team: ICompetitionTeam }) {}
}

export class RejectCompetitionTeamFailureAction implements Action {
  readonly type = ActionTypes.REJECT_COMPETITION_TEAM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class RejectCompetitionTeamSuccessAction implements Action {
  readonly type = ActionTypes.REJECT_COMPETITION_TEAM_SUCCESS;
  constructor(public payload: { team: ICompetitionTeam }) {}
}

export class RemoveCompetitionTeamAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPETITION_TEAM;
  constructor(public payload: { team: ICompetitionTeam }) {}
}

export class RemoveCompetitionTeamFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPETITION_TEAM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class RemoveCompetitionTeamSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_COMPETITION_TEAM_SUCCESS;
  constructor(public payload: { team: ICompetitionTeam }) {}
}

export class LoadCompetitionTeamsAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_TEAMS;
  constructor(
    public payload: { competitionId: number; page?: number; term?: string }
  ) {}
}

export class LoadCompetitionTeamsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_TEAMS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCompetitionTeamsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITION_TEAMS_SUCCESS;
  constructor(
    public payload: { teams: ICompetitionTeam[]; teamsTotalAmount: number }
  ) {}
}

export class FindMembersAction implements Action {
  readonly type = ActionTypes.FIND_MEMBERS;
  constructor(
    public payload: {
      competitionId: number;
      groupId: number;
      page: number;
      term?: string;
    }
  ) {}
}

export class FindMembersFailureAction implements Action {
  readonly type = ActionTypes.FIND_MEMBERS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class FindMembersSuccessAction implements Action {
  readonly type = ActionTypes.FIND_MEMBERS_SUCCESS;
  constructor(
    public payload: {
      members: ICompetitionMember[];
      membersTotalAmount: number;
    }
  ) {}
}

export class LoadMyGroupsAction implements Action {
  readonly type = ActionTypes.LOAD_MY_GROUPS;
  constructor() {}
}

export class LoadMyGroupsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MY_GROUPS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadMyGroupsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MY_GROUPS_SUCCESS;
  constructor(public payload: { groups: ICompetitionGroup[] }) {}
}

export class LoadJuryAction implements Action {
  readonly type = ActionTypes.LOAD_JURY;
  constructor(public payload: { competitionId: number }) {}
}

export class LoadJuryFailureAction implements Action {
  readonly type = ActionTypes.LOAD_JURY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadJurySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_JURY_SUCCESS;
  constructor(public payload: { jury: ICompetitionJury[] }) {}
}

export class AddJuryAction implements Action {
  readonly type = ActionTypes.ADD_JURY;
  constructor(public payload: { jury: ICompetitionJury }) {}
}

export class AddJuryFailureAction implements Action {
  readonly type = ActionTypes.ADD_JURY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AddJurySuccessAction implements Action {
  readonly type = ActionTypes.ADD_JURY_SUCCESS;
  constructor(public payload: { jury: ICompetitionJury }) {}
}

export class EditJuryAction implements Action {
  readonly type = ActionTypes.EDIT_JURY;
  constructor(public payload: { jury: ICompetitionJury }) {}
}

export class EditJuryFailureAction implements Action {
  readonly type = ActionTypes.EDIT_JURY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class EditJurySuccessAction implements Action {
  readonly type = ActionTypes.EDIT_JURY_SUCCESS;
  constructor(public payload: { jury: ICompetitionJury }) {}
}

export class DeleteJuryAction implements Action {
  readonly type = ActionTypes.DELETE_JURY;
  constructor(public payload: { jury: ICompetitionJury }) {}
}

export class DeleteJuryFailureAction implements Action {
  readonly type = ActionTypes.DELETE_JURY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteJurySuccessAction implements Action {
  readonly type = ActionTypes.DELETE_JURY_SUCCESS;
  constructor(public payload: { jury: ICompetitionJury }) {}
}

export type Actions =
  | LoadCompetitionAction
  | LoadCompetitionSuccessAction
  | LoadCompetitionFailureAction
  | JoinCompetitionAction
  | JoinCompetitionSuccessAction
  | JoinCompetitionFailureAction
  | LeaveCompetitionAction
  | LeaveCompetitionSuccessAction
  | LeaveCompetitionFailureAction
  | CreateCompetitionAction
  | CreateCompetitionSuccessAction
  | CreateCompetitionFailureAction
  | UpdateCompetitionAction
  | UpdateCompetitionSuccessAction
  | UpdateCompetitionFailureAction
  | LoadCompetitionParticipantsAction
  | LoadCompetitionParticipantsSuccessAction
  | LoadCompetitionParticipantsFailureAction
  | LoadCompetitionRequestsAction
  | LoadCompetitionRequestsSuccessAction
  | LoadCompetitionRequestsFailureAction
  | AcceptCompetitionRequestAction
  | AcceptCompetitionRequestSuccessAction
  | AcceptCompetitionRequestFailureAction
  | DeclineCompetitionRequestAction
  | DeclineCompetitionRequestSuccessAction
  | DeclineCompetitionRequestFailureAction
  | RemoveCompetitionParticipantAction
  | RemoveCompetitionParticipantSuccessAction
  | RemoveCompetitionParticipantFailureAction
  | SubmitCompetitionNewsAction
  | SubmitCompetitionNewsSuccessAction
  | SubmitCompetitionNewsFailureAction
  | SubmitCompetitionResultAction
  | SubmitCompetitionResultSuccessAction
  | SubmitCompetitionResultFailureAction
  | UpdateCompetitionResultAction
  | UpdateCompetitionResultSuccessAction
  | UpdateCompetitionResultFailureAction
  | RateCompetitionResultAction
  | RateCompetitionResultSuccessAction
  | RateCompetitionResultFailureAction
  | LoadCompetitionReviewsAction
  | LoadCompetitionReviewsSuccessAction
  | LoadCompetitionReviewsFailureAction
  | AddCompetitionTeamAction
  | AddCompetitionTeamFailureAction
  | AddCompetitionTeamSuccessAction
  | ConfirmCompetitionTeamAction
  | ConfirmCompetitionTeamFailureAction
  | ConfirmCompetitionTeamSuccessAction
  | RejectCompetitionTeamAction
  | RejectCompetitionTeamFailureAction
  | RejectCompetitionTeamSuccessAction
  | RemoveCompetitionTeamAction
  | RemoveCompetitionTeamFailureAction
  | RemoveCompetitionTeamSuccessAction
  | LoadCompetitionTeamsAction
  | LoadCompetitionTeamsFailureAction
  | LoadCompetitionTeamsSuccessAction
  | FindMembersAction
  | FindMembersFailureAction
  | FindMembersSuccessAction
  | LoadMyGroupsAction
  | LoadMyGroupsFailureAction
  | LoadMyGroupsSuccessAction
  | LoadJuryAction
  | LoadJuryFailureAction
  | LoadJurySuccessAction
  | AddJuryAction
  | AddJuryFailureAction
  | AddJurySuccessAction
  | EditJuryAction
  | EditJuryFailureAction
  | EditJurySuccessAction
  | DeleteJuryAction
  | DeleteJuryFailureAction
  | DeleteJurySuccessAction;
