import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-your-journey-dialog',
  templateUrl: './start-your-journey-dialog.component.html',
  styleUrls: [
    './start-your-journey-dialog.component.scss',
    '../../style/hp/style.scss',
    '../../style/hp/responsive.scss'
  ]
})
export class StartYourJourneyDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
