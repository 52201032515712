<div class="message-item d-flex" *ngIf="message && !(message.ownerId | isCurrentUser | async)">
  <div class="avatar">
    <app-avatar [src]="message.ownerImage" [placeholder]="{ name: message.ownerName } | initials" class="img-fluid">
    </app-avatar>
  </div>
  <div class="data d-flex flex-column align-items-start">
    <div class="top d-flex align-items-center">
      <div class="name">{{ message.ownerName }}</div>
      <div class="date">{{ message.updatedAt * 1000 | date:'HH:mm' }}</div>
    </div>
    <app-message-text
      class="message-box"
      *ngIf="message.content"
      [text]="message.content | safeUserInput"
      (loaded)="onMessageTextLoaded()"
    >
    </app-message-text>

    <app-message-photos [photos]="message.photos" (loaded)="onPhotosLoaded()" class="w-100"> </app-message-photos>

    <app-message-doc *ngFor="let file of message.files" [doc]="file" (click)="openFile(file)" (load)="onDocRendered()">
    </app-message-doc>
  </div>
</div>

<div class="message-item my-message d-flex" *ngIf="message && message.ownerId | isCurrentUser | async">
  <div class="data d-flex flex-column align-items-end">
    <div class="top d-flex align-items-center">
      <div class="date">{{ message.updatedAt * 1000 | date:'HH:mm' }}</div>
    </div>
    <app-message-text
      class="message-box"
      *ngIf="message.content"
      [text]="message.content | safeUserInput"
      (loaded)="onMessageTextLoaded()"
    >
    </app-message-text>

    <app-message-photos [photos]="message.photos" (loaded)="onPhotosLoaded()" class="w-100"> </app-message-photos>

    <app-message-doc *ngFor="let file of message.files" [doc]="file" (click)="openFile(file)" (load)="onDocRendered()">
    </app-message-doc>
  </div>
</div>
