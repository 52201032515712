import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as ClubRequests from '../reducers/club-requests.reducer';
import { State, clubFeatureKey } from '../reducers';

const clubModuleState = createFeatureSelector<State>(clubFeatureKey);
const selectClubRequestsState = createSelector(
  clubModuleState,
  (state: State) => state.clubRequests
);

export const isLoadingClubRequests = createSelector(
  selectClubRequestsState,
  ClubRequests.isLoadingClubRequests
);
export const getClubRequests = createSelector(
  selectClubRequestsState,
  ClubRequests.getClubRequests
);
export const getClubRequestsAmountOfPages = createSelector(
  selectClubRequestsState,
  ClubRequests.getClubRequestsAmountOfPages
);
