import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as ChatsList from './reducers/chats-list.reducer';
import * as ChatMessagesChunks from './reducers/chat-messages-chunks.reducer';
import * as Channel from './reducers/selected-channel.reducer';
import * as Chat from './reducers/selected-chat.reducer';
import { State, chatFeatureKey } from './reducers';

export const chatModuleState = createFeatureSelector<State>(chatFeatureKey);

/**
 * Group Chats
 */

export const selectChatsListState = createSelector(
  chatModuleState,
  (state: State) => state.chats
);

export const getGroupChatsList = createSelector(
  selectChatsListState,
  ChatsList.selectGroupChats
);

export const selectSelectedChatState = createSelector(
  chatModuleState,
  (state: State) => state.selectedChat
);

export const isLoadingGroupChats = createSelector(
  selectChatsListState,
  ChatsList.isLoading
);

export const groupChatMembers = createSelector(
  selectSelectedChatState,
  Chat.groupChatMembers
);

export const isLoadingGroupChatMembers = createSelector(
  selectSelectedChatState,
  Chat.isLoadingGroupChatMembers
);

export const getAmountOfUnreadChannels = createSelector(
  selectChatsListState,
  ChatsList.getAmountOfUnreadChannels
);

/**
 * Channels
 */

export const selectSelectedChannelState = createSelector(
  chatModuleState,
  (state: State) => state.selectedChannel
);

export const getSelectedChannel = createSelector(
  selectSelectedChannelState,
  Channel.getChannel
);

export const getChannelMembers = createSelector(
  selectSelectedChannelState,
  Channel.getChannelMembers
);

/**
 * Messages
 */

export const selectChatMessagesState = createSelector(
  chatModuleState,
  (state: State) => state.chatMessages
);

export const selectChatMessagesChunksState = createSelector(
  chatModuleState,
  (state: State) => state.chatMessagesChunks
);

export const isLoadedMessages = createSelector(
  selectChatMessagesChunksState,
  ChatMessagesChunks.isLoadedMessages
);

export const isLoadingMessages = createSelector(
  selectChatMessagesChunksState,
  ChatMessagesChunks.isLoadingMessages
);

export const nextMessagesPage = createSelector(
  selectChatMessagesChunksState,
  ChatMessagesChunks.nextMessagesPageToLoad
);

export const getMessagesChunks = createSelector(
  selectChatMessagesChunksState,
  ChatMessagesChunks.getMessagesChunks
);
