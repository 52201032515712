import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent {
  @Input() prefix: string = '';
  @Input() isShowLogo: boolean = false;
  @Input() city?: string;
  @Input() country?: string;
  @Input() default: string = '';

  TYPES = LocationComponent.TYPES;

  get type() {
    if (this.city && this.country) {
      return this.TYPES.CITY_COUNTRY;
    } else if (this.city) {
      return this.TYPES.CITY;
    } else if (this.country) {
      return this.TYPES.COUNTRY;
    } else {
      return this.TYPES.EMPTY;
    }
  }
}

export namespace LocationComponent {
  export enum TYPES {
    EMPTY,
    CITY_COUNTRY,
    CITY,
    COUNTRY
  }
}
