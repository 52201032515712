import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { IMessage } from '../../../model';

@Component({
  selector: 'app-chat-messages-box-chunk',
  templateUrl: './chat-messages-box-chunk.component.html',
  styleUrls: ['./chat-messages-box-chunk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessagesBoxChunk implements OnInit, OnDestroy {
  private readonly _messages$ = new BehaviorSubject<IMessage[]>(null);
  private readonly _amountOfRenderedMessages$ = new BehaviorSubject(0);
  private readonly _componentDestroy$ = new Subject();

  @Input() set messages(value: IMessage[]) {
    this._messages$.next(value);
  }

  @Output() load = new EventEmitter();
  @Output() loaded = new EventEmitter();

  ngOnInit() {
    this._messages$
      .pipe(
        takeUntil(this._componentDestroy$),
        tap((_) => this.load.emit())
      )
      .subscribe((_) => _);

    this._amountOfRenderedMessages$
      .pipe(
        takeUntil(this._componentDestroy$),
        filter((_) => _ === this._messages$.value?.length),
        tap((_) => this.loaded.emit())
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  get messages$() {
    return this._messages$;
  }

  get isLoading$() {
    return this._amountOfRenderedMessages$.pipe(
      map((_) => _ < this._messages$.value.length)
    );
  }

  messageTrackBy(_index: number, message: { id: number }) {
    return message.id;
  }

  onMessageRendered(_message: IMessage) {
    this._amountOfRenderedMessages$.next(
      this._amountOfRenderedMessages$.value + 1
    );
  }

  isShowMessageDate(prevMessage?: IMessage, currMessage?: IMessage) {
    if (!prevMessage) {
      return true;
    }

    if (!currMessage) {
      return true;
    }

    const prevMessageCreatedAt = new Date(
      prevMessage.createdAt * 1000 || null
    ).setHours(0, 0, 0, 0);
    const currMessageCreatedAt = new Date(
      currMessage.createdAt * 1000 || null
    ).setHours(0, 0, 0, 0);

    return prevMessageCreatedAt !== currMessageCreatedAt;
  }
}
