import { IIcon } from '../../model/icon';

export const message: IIcon = {
  id: `MESSAGE`,
  svg: `  
  <path fill="#F29C1F"
  d="M17.03 5.98a3.42 3.42 0 00-2.4-.98H3.43A3.42 3.42 0 000 8.42v8.1a3.42 3.42 0 003.42 3.42c6.45 0 10.81.19 11.96-.1a3.5 3.5 0 002.67-3.32v-8.1c0-.91-.37-1.8-1.02-2.44z" />
<path fill="#F0C419" d="M18.05 8.42v8.1c0 .91-.38 1.79-1.04 2.42l-6.71-6.47 6.73-6.49a3.42 3.42 0 011.02 2.44z" />
<path fill="#F3D55B"
  d="M17.03 5.98l-6.73 6.49-.2.19c-.6.58-1.55.58-2.15 0l-.2-.19-5.83-5.62-.9-.87c.64-.63 1.5-.98 2.4-.98h11.2c.9 0 1.77.35 2.41.98z" />
<path fill="#F0C419"
  d="M7.75 12.47l-6.73 6.49A3.41 3.41 0 010 16.52v-8.1c0-.91.37-1.8 1.02-2.44l.9.87 5.83 5.62z" />
<path fill="#fff"
  d="M8.71 6.56H4.36a.31.31 0 110-.63H8.7a.31.31 0 010 .63zM3.11 6.56a.31.31 0 100-.63.31.31 0 000 .63z" />
<path fill="#D25627" d="M15.88 3.96l-6.2 6.2-.12.04a.33.33 0 01-.06-.17.7.7 0 00-1.18-.46l6.58-6.58.74.73.24.24z" />
<path fill="#fff"
  d="M18.56 1.28a.34.34 0 10-.49.49.34.34 0 00.5-.5zM17.1 2.25l-.49.5a.34.34 0 10.49.48l.49-.49a.34.34 0 10-.5-.49z" />
<path fill="#35495E" d="M8.75 12.22l-1.25.58a.34.34 0 01-.46-.46l.58-1.25a1.76 1.76 0 011.13 1.13z" />
<path fill="#F9EAB0"
  d="M10.27 11.52l-1.52.7a1.76 1.76 0 00-1.13-1.13l.7-1.52a.7.7 0 011.18.46c0 .06.02.12.06.17.05.09.15.14.25.14a.68.68 0 01.66.7c0 .17-.07.35-.2.48z" />
<path fill="#95A5A5" d="M17.83 3.96l-.98.98-.24-.25-.73-.73-.24-.24-.74-.73.98-.98 1.95 1.95z" />
<path fill="#802D40"
  d="M10.27 11.52a.69.69 0 00-.46-1.18c-.1 0-.2-.05-.25-.14l.12-.04 6.2-6.2.73.73.24.25-6.58 6.58z" />
<path fill="#FF5364"
  d="M19.54 2.25l-1.71 1.71-1.95-1.95 1.7-1.7C18-.1 18.64-.1 19.05.3l.49.48c.4.4.4 1.06 0 1.46z" />
`
};
