import { TEmoji } from '../model/type';

export const food: TEmoji[] = [
  ['🍇', 'grapes'],
  ['🍈', 'melon'],
  ['🍉', 'watermelon'],
  ['🍊', 'tangerine'],
  ['🍋', 'lemon'],
  ['🍌', 'banana'],
  ['🍍', 'pineapple'],
  ['🥭', 'mango'],
  ['🍎', 'red apple'],
  ['🍏', 'green apple'],
  ['🍐', 'pear'],
  ['🍑', 'peach'],
  ['🍒', 'cherries'],
  ['🍓', 'strawberry'],
  ['🥝', 'kiwi fruit'],
  ['🍅', 'tomato'],
  ['🥥', 'coconut'],
  ['🥑', 'avocado'],
  ['🍆', 'eggplant'],
  ['🥔', 'potato'],
  ['🥕', 'carrot'],
  ['🌽', 'ear of corn'],
  ['🌶️', 'hot pepper'],
  ['🥒', 'cucumber'],
  ['🥬', 'leafy green'],
  ['🥦', 'broccoli'],
  ['🧄', 'garlic'],
  ['🧅', 'onion'],
  ['🍄', 'mushroom'],
  ['🥜', 'peanuts'],
  ['🌰', 'chestnut'],
  ['🍞', 'bread'],
  ['🥐', 'croissant'],
  ['🥖', 'baguette bread'],
  ['🥨', 'pretzel'],
  ['🥯', 'bagel'],
  ['🥞', 'pancakes'],
  ['🧇', 'waffle'],
  ['🧀', 'cheese wedge'],
  ['🍖', 'meat on bone'],
  ['🍗', 'poultry leg'],
  ['🥩', 'cut of meat'],
  ['🥓', 'bacon'],
  ['🍔', 'hamburger'],
  ['🍟', 'french fries'],
  ['🍕', 'pizza'],
  ['🌭', 'hot dog'],
  ['🥪', 'sandwich'],
  ['🌮', 'taco'],
  ['🌯', 'burrito'],
  ['🥙', 'stuffed flatbread'],
  ['🧆', 'falafel'],
  ['🥚', 'egg'],
  ['🍳', 'cooking'],
  ['🥘', 'shallow pan of food'],
  ['🍲', 'pot of food'],
  ['🥣', 'bowl with spoon'],
  ['🥗', 'green salad'],
  ['🍿', 'popcorn'],
  ['🧈', 'butter'],
  ['🧂', 'salt'],
  ['🥫', 'canned food'],
  ['🍱', 'bento box'],
  ['🍘', 'rice cracker'],
  ['🍙', 'rice ball'],
  ['🍚', 'cooked rice'],
  ['🍛', 'curry rice'],
  ['🍜', 'steaming bowl'],
  ['🍝', 'spaghetti'],
  ['🍠', 'roasted sweet potato'],
  ['🍢', 'oden'],
  ['🍣', 'sushi'],
  ['🍤', 'fried shrimp'],
  ['🍥', 'fish cake with swirl'],
  ['🥮', 'moon cake'],
  ['🍡', 'dango'],
  ['🥟', 'dumpling'],
  ['🥠', 'fortune cookie'],
  ['🥡', 'takeout box'],
  ['🦀', 'crab'],
  ['🦞', 'lobster'],
  ['🦐', 'shrimp'],
  ['🦑', 'squid'],
  ['🦪', 'oyster'],
  ['🍦', 'soft ice cream'],
  ['🍧', 'shaved ice'],
  ['🍨', 'ice cream'],
  ['🍩', 'doughnut'],
  ['🍪', 'cookie'],
  ['🎂', 'birthday cake'],
  ['🍰', 'shortcake'],
  ['🧁', 'cupcake'],
  ['🥧', 'pie'],
  ['🍫', 'chocolate bar'],
  ['🍬', 'candy'],
  ['🍭', 'lollipop'],
  ['🍮', 'custard'],
  ['🍯', 'honey pot'],
  ['🍼', 'baby bottle'],
  ['🥛', 'glass of milk'],
  ['☕', 'hot beverage'],
  ['🍵', 'teacup without handle'],
  ['🍶', 'sake'],
  ['🍾', 'bottle with popping cork'],
  ['🍷', 'wine glass'],
  ['🍸', 'cocktail glass'],
  ['🍹', 'tropical drink'],
  ['🍺', 'beer mug'],
  ['🍻', 'clinking beer mugs'],
  ['🥂', 'clinking glasses'],
  ['🥃', 'tumbler glass'],
  ['🥤', 'cup with straw'],
  ['🧃', 'beverage box'],
  ['🧉', 'mate'],
  ['🧊', 'ice'],
  ['🥢', 'chopsticks'],
  ['🍽️', 'fork and knife with plate'],
  ['🍴', 'fork and knife'],
  ['🥄', 'spoon'],
  ['🔪', 'kitchen knife'],
  ['🏺', 'amphora']
];
