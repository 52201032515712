import { Component, OnDestroy, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-form-control',
  template: ``,
  styles: []
})
export class BaseFormControlComponent
  implements ControlValueAccessor, OnDestroy
{
  @Input() multiple = true;
  @Input() clearable = true;
  @Input() selectPlaceholder = 'Choose ...';
  itemsArray: any[];
  selectedItems: any[];
  destroy$: Subject<boolean> = new Subject<boolean>();

  private propagateChange: any = () => {};
  private propagateTouch: any = () => {};

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelect() {
    this.propagateChange(this.selectedItems);
    this.propagateTouch(this.selectedItems);
  }

  writeValue(value: any[]): void {
    this.selectedItems = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
}
