import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import * as ContactsActions from 'src/app/core/services/contacts/contacts.actions';
import * as PeopleActions from './people.actions';
import { People } from './people.model';

export const peopleFeatureKey = 'people';

export interface State {
  people: { [key: number]: People };
  count: number;
}

export const initialState: State = {
  people: null,
  count: null
};

export const reducer = createReducer(
  initialState,
  on(PeopleActions.searchPeople, (state, action): State => {
    return {
      ...state,
      people: null,
      count: null
    };
  }),
  on(PeopleActions.searchPeopleSuccess, (state, action): State => {
    return {
      ...state,
      people: action.people,
      count: action.count
    };
  }),
  on(ContactsActions.addContactSuccess, (state, action): State => {
    if (!state.people || !state.people[action.id]) {
      return state;
    }

    return {
      ...state,
      people: {
        ...state.people,
        [action.id]: {
          ...state.people[action.id],
          friend_request: true
        }
      }
    };
  }),
  on(ContactsActions.removeContactSuccess, (state, action): State => {
    if (!state.people || !state.people[action.id]) {
      return state;
    }

    return {
      ...state,
      people: {
        ...state.people,
        [action.id]: {
          ...state.people[action.id],
          friend_request: false,
          is_friend: false
        }
      }
    };
  })
);

export const selectState = createFeatureSelector<State>(peopleFeatureKey);

export const selectPeople = () =>
  createSelector(selectState, (state: State) => {
    if (!state.people) {
      return { items: null, count: state.count };
    }

    return { items: Object.values(state.people), count: state.count };
  });
