import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ConvertNameService } from 'src/app/shared/services/convert-name.service';

interface IGroup {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class GroupLinkService {
  constructor(private convertNameService: ConvertNameService) {}

  generateGroupLink(group: IGroup) {
    const name = this.convertNameService.convertName(group.name);

    return [environment.routePrefix, 'groups', group.id, name];
  }
}
