import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

type IAppleID = any;

export interface IAppleAuthData {
  token: string;
  user: null | {
    firstName: string;
    lastName: string;
  };
}

@Injectable()
export class AppleAuthService {
  login(): Observable<IAppleAuthData> {
    // @ts-ignore
    return from(this.processLogin(AppleID));
  }

  private async processLogin(
    AppleID: IAppleID
  ): Promise<null | IAppleAuthData> {
    AppleID.auth.init({
      clientId: environment.auth.appleClientID,
      scope: 'name email',
      redirectURI: environment.auth.redirectURL,
      state: 'origin:web',
      usePopup: true
    });

    try {
      const { authorization, user } = await AppleID.auth.signIn();

      return {
        token: authorization.id_token,
        user: user?.name
          ? {
              firstName: user.name.firstName,
              lastName: user.name.lastName
            }
          : null
      };
    } catch (error) {
      if (error.error === 'popup_closed_by_user') {
        return null;
      }

      if (error.error === 'user_cancelled_authorize') {
        return null;
      }

      if (error.error === 'user_trigger_new_signin_flow') {
        return null;
      }

      throw error;
    }
  }
}
