<ng-container *ngIf="!isBrowser">
  <app-global-loader></app-global-loader>
</ng-container>

<ng-container *ngIf="isBrowser">
  <ng-container *ngIf="isLoaded$ | async; else load">
    <router-outlet></router-outlet>
  </ng-container>

  <ng-template #load>
    <app-global-loader></app-global-loader>
  </ng-template>

  <app-icons></app-icons>
  <app-info-message></app-info-message>
</ng-container>
