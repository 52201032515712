import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { concatMap, switchMap } from 'rxjs/operators';

import { ProjectActions, ProjectsActions } from './actions';
import { ProjectsService } from './service';
import { ImagesService } from '../../../shared/stores/images/images.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsStoreEffects {
  constructor(
    private readonly projectsService: ProjectsService,
    private imagesService: ImagesService,
    private readonly actions$: Actions
  ) {}

  @Effect()
  createProject$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ProjectActions.CreateProjectAction>(
        ProjectActions.ActionTypes.CREATE_PROJECT
      ),
      switchMap((params) =>
        this.projectsService
          .create(params.payload.project)
          .pipe(
            switchMap(({ project }) => [
              new ProjectActions.CreateProjectSuccessAction({ project })
            ])
          )
      )
    )
  );

  @Effect()
  editProject$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ProjectActions.EditProjectAction>(
        ProjectActions.ActionTypes.EDIT_PROJECT
      ),
      switchMap((params) =>
        this.projectsService
          .edit(params.payload.project)
          .pipe(
            switchMap(({ project }) => [
              new ProjectActions.EditProjectSuccessAction({ project })
            ])
          )
      )
    )
  );

  @Effect()
  deleteProject$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ProjectActions.DeleteProjectAction>(
        ProjectActions.ActionTypes.DELETE_PROJECT
      ),
      switchMap((params) =>
        this.projectsService
          .delete(params.payload.id)
          .pipe(
            switchMap(({ id }) => [
              new ProjectActions.DeleteProjectSuccessAction({ id })
            ])
          )
      )
    )
  );

  @Effect()
  listProjects$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ProjectsActions.ListProjectsAction>(
        ProjectsActions.ActionTypes.LIST_PROJECTS
      ),
      switchMap((params) =>
        this.projectsService.list(params.payload).pipe(
          switchMap(({ projects, totalAmount }) => [
            new ProjectsActions.ListProjectsSuccessAction({
              projects,
              totalAmount
            })
          ])
        )
      )
    )
  );

  @Effect()
  createCompetitionProject$: Observable<Action> = this.actions$.pipe((_) =>
    this.actions$.pipe(
      ofType<ProjectActions.CreateCompetitionProjectAction>(
        ProjectActions.ActionTypes.CREATE_COMPETITION_PROJECT
      ),
      concatMap((params) =>
        forkJoin([
          of(params),
          params.payload.project.image
            ? this.imagesService.add([params.payload.project.image as File])
            : of([])
        ])
      ),
      switchMap((params) => {
        const iconId = params[1][0]?.id ? params[1][0]?.id : null;

        return this.projectsService
          .createCompetitionProject({
            ...params[0].payload.project,
            icon_id: iconId
          })
          .pipe(
            switchMap(({ project }) => [
              new ProjectActions.CreateCompetitionProjectSuccessAction({
                project
              })
            ])
          );
      })
    )
  );

  @Effect()
  updateCompetitionProject$: Observable<Action> = this.actions$.pipe((_) =>
    this.actions$.pipe(
      ofType<ProjectActions.UpdateCompetitionProjectAction>(
        ProjectActions.ActionTypes.UPDATE_COMPETITION_PROJECT
      ),
      concatMap((params) =>
        forkJoin([
          of(params),
          params.payload.project.image
            ? this.imagesService.add([params.payload.project.image as File])
            : of([])
        ])
      ),
      switchMap((params) => {
        const iconId = params[1][0]?.id ? params[1][0]?.id : null;

        return this.projectsService
          .createCompetitionProject({
            ...params[0].payload.project,
            icon_id: iconId
          })
          .pipe(
            switchMap(({ project }) => [
              new ProjectActions.UpdateCompetitionProjectSuccessAction({
                project
              })
            ])
          );
      })
    )
  );

  @Effect()
  listCompetitionProjects$: Observable<Action> = this.actions$.pipe(
    ofType<ProjectsActions.ListCompetitionProjectsAction>(
      ProjectsActions.ActionTypes.LIST_COMPETITION_PROJECTS
    ),
    switchMap((params) =>
      this.projectsService
        .listCompetitionsProjects(
          params.payload.competitionId,
          params.payload.page,
          params.payload.term
        )
        .pipe(
          switchMap(({ projects, totalAmount }) => [
            new ProjectsActions.ListCompetitionProjectsSuccessAction({
              projects,
              totalAmount
            })
          ])
        )
    )
  );

  @Effect()
  createProjectArticle$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ProjectActions.CreateProjectArticleAction>(
        ProjectActions.ActionTypes.CREATE_PROJECT_ARTICLE
      ),
      switchMap((params) =>
        this.projectsService
          .createArticle(params.payload.article)
          .pipe(
            switchMap(({ article }) => [
              new ProjectActions.CreateProjectArticleSuccessAction({ article })
            ])
          )
      )
    )
  );
}
