import { TEmoji } from '../model/type';

export const activities: TEmoji[] = [
  ['🎃', 'jack-o-lantern'],
  ['🎄', 'Christmas tree'],
  ['🎆', 'fireworks'],
  ['🎇', 'sparkler'],
  ['🧨', 'firecracker'],
  ['✨', 'sparkles'],
  ['🎈', 'balloon'],
  ['🎉', 'party popper'],
  ['🎊', 'confetti ball'],
  ['🎋', 'tanabata tree'],
  ['🎍', 'pine decoration'],
  ['🎎', 'Japanese dolls'],
  ['🎏', 'carp streamer'],
  ['🎐', 'wind chime'],
  ['🎑', 'moon viewing ceremony'],
  ['🧧', 'red envelope'],
  ['🎀', 'ribbon'],
  ['🎁', 'wrapped gift'],
  ['🎗️', 'reminder ribbon'],
  ['🎟️', 'admission tickets'],
  ['🎫', 'ticket'],
  ['🎖️', 'military medal'],
  ['🏆', 'trophy'],
  ['🏅', 'sports medal'],
  ['🥇', '1st place medal'],
  ['🥈', '2nd place medal'],
  ['🥉', '3rd place medal'],
  ['⚽', 'soccer ball'],
  ['⚾', 'baseball'],
  ['🥎', 'softball'],
  ['🏀', 'basketball'],
  ['🏐', 'volleyball'],
  ['🏈', 'american football'],
  ['🏉', 'rugby football'],
  ['🎾', 'tennis'],
  ['🥏', 'flying disc'],
  ['🎳', 'bowling'],
  ['🏏', 'cricket game'],
  ['🏑', 'field hockey'],
  ['🏒', 'ice hockey'],
  ['🥍', 'lacrosse'],
  ['🏓', 'ping pong'],
  ['🏸', 'badminton'],
  ['🥊', 'boxing glove'],
  ['🥋', 'martial arts uniform'],
  ['🥅', 'goal net'],
  ['⛳', 'flag in hole'],
  ['⛸️', 'ice skate'],
  ['🎣', 'fishing pole'],
  ['🤿', 'diving mask'],
  ['🎽', 'running shirt'],
  ['🎿', 'skis'],
  ['🛷', 'sled'],
  ['🥌', 'curling stone'],
  ['🎯', 'direct hit'],
  ['🪀', 'yo-yo'],
  ['🪁', 'kite'],
  ['🎱', 'pool 8 ball'],
  ['🔮', 'crystal ball'],
  ['🧿', 'nazar amulet'],
  ['🎮', 'video game'],
  ['🕹️', 'joystick'],
  ['🎰', 'slot machine'],
  ['🎲', 'game die'],
  ['🧩', 'puzzle piece'],
  ['🧸', 'teddy bear'],
  ['♠️', 'spade suit'],
  ['♥️', 'heart suit'],
  ['♦️', 'diamond suit'],
  ['♣️', 'club suit'],
  ['♟️', 'chess pawn'],
  ['🃏', 'joker'],
  ['🀄', 'mahjong red dragon'],
  ['🎴', 'flower playing cards'],
  ['🎭', 'performing arts'],
  ['🖼️', 'framed picture'],
  ['🎨', 'artist palette'],
  ['🧵', 'thread'],
  ['🧶', 'yarn']
];
