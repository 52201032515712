import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ConvertNameService } from 'src/app/shared/services/convert-name.service';

export interface IClub {
  id: number;
  name: string;
}

@Injectable()
export class AppClubLinkService {
  constructor(private readonly convertNameService: ConvertNameService) {}

  generateLink(club: IClub) {
    const name = this.convertNameService.convertName(club.name);

    return [environment.routePrefix, 'clubs', club.id, name];
  }
}
