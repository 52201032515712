import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  @Input() inverse?: boolean;

  get color() {
    return this.inverse ? '#47979d' : '#fff';
  }

  constructor() {}

  ngOnInit(): void {}
}
