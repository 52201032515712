import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-group-badge',
  templateUrl: './group-badge.component.html',
  styleUrls: ['./group-badge.component.scss']
})
export class GroupBadgeComponent {
  @Input() group: any;
}
