import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-eye-sign',
  templateUrl: './post-eye-sign.component.html',
  styleUrls: ['./post-eye-sign.component.scss']
})
export class PostEyeSignComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
