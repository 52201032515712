import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { matcher } from 'src/app/modules/front/front-routing.module';

import {
  AuthorizedGuard,
  DevEnvGuard,
  NotAuthorizedGuard
} from './shared/guards';
import { UnknownComponent } from './unknown/unknown.component';
import {
  HeaderTemplateComponent,
  HeaderLeftNavTemplateComponent,
  HeaderLeftNavRightCompetitionsShortListTemplateComponent
} from './templates/components';

export const routes: Routes = [
  {
    matcher,
    loadChildren: async () =>
      import('src/app/modules/front/front.module').then((m) => m.FrontModule),
    canActivate: [NotAuthorizedGuard],
    canLoad: [NotAuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'old',
    loadChildren: async () =>
      import('./public/public.module').then((m) => m.PublicModule)
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationModule
      )
  },
  {
    path: 'home',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/modules/home/home.module').then(
            (m) => m.HomeLandingModule
          )
      }
    ],
    canActivate: [AuthorizedGuard],
    canLoad: [AuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'html-css',
    loadChildren: () =>
      import('./pages/html-css/html-css.module').then((m) => m.HtmlCssModule),
    canActivate: [DevEnvGuard],
    canLoad: [DevEnvGuard]
  },
  {
    path: 'messages',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/messages/messages.module').then(
            (m) => m.MessagesModule
          )
      }
    ],
    canActivate: [AuthorizedGuard],
    canLoad: [AuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'group-messages',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/modules/chat/chat.module').then((m) => m.ChatModule)
      }
    ],
    canActivate: [AuthorizedGuard],
    canLoad: [AuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'eduniverse',
    component: HeaderLeftNavTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/eduniverse/eduniverse.module').then(
            (m) => m.EduniverseModule
          )
      }
    ]
  },
  {
    path: 'search',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/search/search.module').then((m) => m.SearchModule)
      }
    ],
    canActivate: [AuthorizedGuard],
    canLoad: [AuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'settings',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (m) => m.SettingsModule
          )
      }
    ],
    canActivate: [AuthorizedGuard],
    canLoad: [AuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'network',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/network/network.module').then((m) => m.NetworkModule)
      }
    ],
    canActivate: [AuthorizedGuard],
    canLoad: [AuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'news',
    component: HeaderLeftNavTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule)
      }
    ],
    canActivate: [AuthorizedGuard],
    canLoad: [AuthorizedGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'groups',
    component: HeaderLeftNavTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/groups/groups.module').then((m) => m.GroupsModule)
      }
    ]
  },
  {
    path: 'competitions',
    component: HeaderLeftNavTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/competitions/competitions.module').then(
            (m) => m.CompetitionsModule
          )
      }
    ]
  },
  {
    path: 'clubs',
    component: HeaderLeftNavRightCompetitionsShortListTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/clubs/clubs.module').then((m) => m.ClubsModule)
      }
    ]
  },
  {
    path: 'discussions',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/discussion/discussion.module').then(
            (m) => m.DiscussionModule
          )
      }
    ]
  },
  {
    path: 'post',
    component: HeaderLeftNavTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule)
      }
    ]
  },
  {
    path: 'profile',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule)
      }
    ]
  },
  {
    path: 'organization',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/organization/organization.module').then(
            (m) => m.OrganizationModule
          )
      }
    ]
  },
  {
    path: 'high_school_job_internship_volunteering',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/job/job.module').then((m) => m.JobModule)
      }
    ]
  },
  {
    path: ':id/:name',
    component: HeaderTemplateComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/profile-or-org/profile-or-org.module').then(
            (m) => m.ProfileOrOrgModule
          )
      }
    ]
  },
  {
    path: '403',
    loadChildren: () =>
      import('./pages/error-403/error-403.module').then((m) => m.Error403Module)
  },
  {
    path: 'unexpected-error',
    loadChildren: () =>
      import('./pages/unexpected-error/unexpected-error.module').then(
        (m) => m.UnexpectedErrorModule
      )
  },
  {
    path: '404',
    component: UnknownComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
