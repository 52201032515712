import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NoticeCounterStoreEffects } from './effects';
import { NoticeCounterService } from './service';
import { NoticeCounterFacade } from './facade';
import { noticeCounterFeatureKey, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(noticeCounterFeatureKey, reducers),
    EffectsModule.forFeature([NoticeCounterStoreEffects])
  ],
  providers: [
    NoticeCounterStoreEffects,
    NoticeCounterService,
    NoticeCounterFacade
  ]
})
export class NoticeCounterStoreModule {}
