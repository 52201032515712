import { createAction, props } from '@ngrx/store';
import { NotAllowedCheck } from '@ngrx/store/src/models';

export interface IActionError {
  code: number;
  message: string;
}

export function getAction<I extends object, O extends object>(
  api: string,
  message: string
) {
  return {
    init: createAction(`[${api}/API] ${message} INIT`, props<{ payload: I }>()),
    success: createAction(
      `[${api}/API] ${message} SUCCESS`,
      props<{ payload: O }>()
    ),
    error: createAction(`[${api}/API] ${message} ERROR`, props<IActionError>())
  };
}
