import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

import { CompetitionsFacade } from '../../../../store';

@Component({
  selector: 'app-competitions-short-list',
  templateUrl: './competitions-short-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitionsShortListComponent implements OnInit {
  constructor(private readonly competitionsFacade: CompetitionsFacade) {}

  ngOnInit() {
    this.competitionsFacade.loadCompetitionsShortList();
  }

  get isLoading$() {
    return this.competitionsFacade.isLoadingCompetitionsShortList$;
  }

  get current$() {
    return this.competitionsFacade.competitionsShortList$.pipe(
      map((_) => _.current)
    );
  }

  get upcoming$() {
    return this.competitionsFacade.competitionsShortList$.pipe(
      map((_) => _.upcoming)
    );
  }

  get previous$() {
    return this.competitionsFacade.competitionsShortList$.pipe(
      map((_) => _.previous)
    );
  }
}
