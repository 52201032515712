import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, competitionsFeatureKey } from '../reducers';
import * as Competition from '../reducers/competition.reducer';

const competitionsModuleState = createFeatureSelector<State>(
  competitionsFeatureKey
);

const selectCompetitionsState = createSelector(
  competitionsModuleState,
  (state: State) => state.competition
);

export const getSelectedCompetition = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetition
);

export const isUpdatingSelectedCompetition = createSelector(
  selectCompetitionsState,
  Competition.isUpdatingSelectedCompetition
);

export const getSelectedCompetitionRequests = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionRequests
);

export const getSelectedCompetitionRequestsTotalAmount = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionRequestsTotalAmount
);

export const getSelectedCompetitionReviews = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionReviews
);

export const getSelectedCompetitionReviewsTotalAmount = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionReviewsTotalAmount
);

export const getSelectedCompetitionParticipants = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionParticipants
);

export const getSelectedCompetitionParticipantsTotalAmount = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionParticipantsTotalAmount
);

export const getSelectedCompetitionTeams = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionTeams
);

export const getSelectedCompetitionTeamsTotalAmount = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionTeamsTotalAmount
);

export const getSelectedCompetitionTeamsPagesAmount = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionTeamsPagesAmount
);

export const getSelectedCompetitionTeamsIsLoading = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionTeamsIsLoading
);

export const getSelectedCompetitionGroupsMembers = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionGroupsMembers
);

export const getSelectedCompetitionGroupsMembersTotalAmount = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionGroupsMembersTotalAmount
);

export const getSelectedCompetitionGroupsMembersIsLoading = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionGroupsMembersIsLoading
);

export const getSelectedCompetitionMyGroups = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionMyGroups
);

export const getSelectedCompetitionJury = createSelector(
  selectCompetitionsState,
  Competition.getSelectedCompetitionJury
);
