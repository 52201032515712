import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmplitudeDirective } from './directives';
import { AmplitudeService } from './services';
import { CONFIG } from './amplitude.token';

interface IConfig {
  apiKey: string;
}

@NgModule({
  declarations: [AmplitudeDirective],
  exports: [AmplitudeDirective],
  imports: [CommonModule]
})
export class AmplitudeModule {
  static forRoot(config: IConfig): ModuleWithProviders<AmplitudeModule> {
    return {
      ngModule: AmplitudeModule,
      providers: [
        {
          provide: CONFIG,
          useValue: config
        }
      ]
    };
  }
}
