<div class="dialog-content-overlay">
  <button class="btn-close" (click)="closeDialog(); false"></button>
  <h1 mat-dialog-title>Project: {{project.title}}</h1>

  <div mat-dialog-content class="competition-submission-review mb-4">
    <div class="mb-3">
      <h2 class="mb-1">Start date</h2>
      <div>{{project.start_at}}</div>
    </div>
    <div class="mb-3">
      <h2 class="mb-1">End date</h2>
      <div>{{project.end_at}}</div>
    </div>
    <div class="mb-3">
      <h2 class="mb-1">Description</h2>
      <div [innerHTML]="project.description"></div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onParticipate(); false;">Participate</button>
  </div>
</div>
