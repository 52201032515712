import { Action } from '@ngrx/store';

export enum ActionTypes {
  READ_NEWS_SUCCESS = '[Posts] Read News Success',
  READ_PRIVATE_NEWS_SUCCESS = '[Posts] Read Private News Success'
}

export class ReadNewsSuccessAction implements Action {
  readonly type = ActionTypes.READ_NEWS_SUCCESS;
}

export class ReadPrivateNewsSuccessAction implements Action {
  readonly type = ActionTypes.READ_PRIVATE_NEWS_SUCCESS;
}

export type Actions = ReadNewsSuccessAction | ReadPrivateNewsSuccessAction;
