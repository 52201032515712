<section class="docs">
  <app-message-doc *ngFor="let doc of ctx.local " [doc]="doc" class="selected" (click)="onLocalUnselect(doc)"
    >></app-message-doc
  >
  <app-message-doc
    *ngFor="let doc of docs "
    [doc]="doc"
    [class.selected]="ctx.saved.has(doc)"
    (click)="onSelectToggle(doc)"
  ></app-message-doc>
</section>

<app-select-file class="selector" [types]="'.pdf'" (select)="onLocalSelect($event)">
  <div class="icon-wrapper" (click)="preLocalSelect($event); false">
    <div [icon]="'PLUS:15'"></div>
  </div>
</app-select-file>
