import { createAction, props } from '@ngrx/store';

import { Job } from './job.model';

export const loadJob = createAction('[Job/API] Load', props<{ id: string }>());

export const loadJobSuccess = createAction(
  '[Job/API] Load Success',
  props<{ job: Job }>()
);
