import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import {
  loadSummerProgramSuccess,
  loadSummerProgram,
  loadAfterSchool,
  loadAfterSchoolSuccess,
  loadJobs,
  loadJobsSuccess,
  loadArticles,
  loadArticlesSuccess,
  loadStudentOrganizations,
  loadStudentOrganizationsSuccess,
  loadCollegeQuestions,
  loadCollegeQuestionsSuccess,
  loadActivities,
  loadActivitiesSuccess
} from './eduniverse.actions';
import { EduniverseService } from './eduniverse.service';

@Injectable()
export class EduniverseEffects {
  constructor(
    private actions$: Actions,
    private service: EduniverseService,
    private router: Router
  ) {}

  effectForloadSummerProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummerProgram),
      switchMap((params) =>
        this.service.getList('summercamps', params).pipe(
          switchMap((data) => [
            loadSummerProgramSuccess({
              totalCount: data.count,
              summercamps: data.summercamps
            })
          ])
        )
      )
    )
  );

  effectForloadAfterSchool$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAfterSchool),
      switchMap((params) =>
        this.service.getList('after-school', params).pipe(
          switchMap((data) => [
            loadAfterSchoolSuccess({
              totalCount: data.count,
              after_school: data.afterSchools
            })
          ])
        )
      )
    )
  );

  effectForloadActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActivities),
      switchMap((params) =>
        this.service.getList('activities', params).pipe(
          switchMap((data) => [
            loadActivitiesSuccess({
              totalCount: data.count,
              activities: data.activities
            })
          ])
        )
      )
    )
  );

  effectForloadStudentOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStudentOrganizations),
      switchMap((params) =>
        this.service.getList('student-orgs', params).pipe(
          switchMap((data) => [
            loadStudentOrganizationsSuccess({
              totalCount: data.count,
              organizations: data.studentOrgs
            })
          ])
        )
      )
    )
  );

  effectForloadJobs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadJobs),
      switchMap((params) =>
        this.service
          .getList('jobs', params)
          .pipe(
            switchMap((data) => [
              loadJobsSuccess({ jobs: data.jobs, totalCount: data.count })
            ])
          )
      )
    )
  );

  effectForloadArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadArticles),
      switchMap((params) =>
        this.service.getList('discussions', params).pipe(
          switchMap((data) => [
            loadArticlesSuccess({
              totalCount: data.count,
              articles: data.discussions
            })
          ])
        )
      )
    )
  );

  effectForloadCollegeQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCollegeQuestions),
      switchMap((params) =>
        this.service.getList('college-questions', params).pipe(
          switchMap((data) => [
            loadCollegeQuestionsSuccess({
              totalCount: data.count,
              questions: data.discussions
            })
          ])
        )
      )
    )
  );
}
