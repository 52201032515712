import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Injectable
} from '@angular/core';
import { BaseService } from '../../../../../core/services/base.service';
import { Observable } from 'rxjs';
import { IYourSchool } from './your-school.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: `root`
})
export class YourSchoolService {
  constructor(private base: BaseService) {}

  public load(): Observable<IYourSchool> {
    return this.base
      .get(`eduniverse/your-school`)
      .pipe(map((response) => response['your_school']));
  }
}
