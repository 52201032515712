import { Action } from '@ngrx/store';

import { IWeekly } from '../../model';

export enum ActionTypes {
  LOAD_WEEKLY = '[Weekly] Load Weekly',
  LOAD_WEEKLY_FAILURE = '[Weekly] Load Weekly Failure',
  LOAD_WEEKLY_SUCCESS = '[Weekly] Load Weekly Success',

  READ_WEEKLY = '[Weekly] Read Weekly',
  READ_WEEKLY_SUCCESS = '[Weekly] Read Weekly Success'
}

export class LoadWeeklyAction implements Action {
  readonly type = ActionTypes.LOAD_WEEKLY;
}

export class LoadWeeklySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_WEEKLY_SUCCESS;
  constructor(public payload: IWeekly) {}
}

export class LoadWeeklyFailureAction implements Action {
  readonly type = ActionTypes.LOAD_WEEKLY_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ReadWeeklyAction implements Action {
  readonly type = ActionTypes.READ_WEEKLY;
}

export class ReadWeeklySuccessAction implements Action {
  readonly type = ActionTypes.READ_WEEKLY_SUCCESS;
}

export type Actions =
  | LoadWeeklyAction
  | LoadWeeklySuccessAction
  | LoadWeeklyFailureAction
  | ReadWeeklyAction
  | ReadWeeklySuccessAction;
