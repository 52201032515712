<div class="author d-flex align-items-center">
  <a [profile]="post.user">
    <app-avatar [src]="post.user.icon" [placeholder]="post.user | initials" class="medium right"> </app-avatar>
  </a>
  <div>
    <div *ngIf="ctx.pin && post.pinned" class="pinned"> Pinned Post </div>
    <div class="d-flex align-items-center">
      <a class="name" [profile]="post.user">{{ post.user.name }}</a>
      <svg viewBox="0 0 20 20" *ngIf="post.user?.is_creditable" class="verify-icon">
        <use href="#verified" />
      </svg>
    </div>
    <div class="position"> {{ post.sponsored ? 'Sponsored' : post.user.type_name}} </div>
  </div>
</div>

<span class="create_time_ago ml-auto">{{ createdTime | timeAgo }}</span>
