import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule as NGXImageCropperModule } from 'ngx-image-cropper';

import { ImageCropperModalComponentDialog } from './components';

@NgModule({
  declarations: [ImageCropperModalComponentDialog],
  imports: [CommonModule, NGXImageCropperModule],
  exports: [ImageCropperModalComponentDialog]
})
export class ImageCropperModule {}
