<header *ngIf="userIslogged$ | async; else guestHeader" [class.show-menu]="showProfileMenu">
  <div class="container">
    <div class="header-wrapper d-flex align-items-center">
      <app-logo [appRouterLink]="['/']" [amplitude]="'/header/logo'" class="d-none mr-3 d-lg-block"></app-logo>

      <div class="search d-flex align-items-center order-1 order-lg-0">
        <input
          type="text"
          name="searchTerm"
          [(ngModel)]="searchTerm"
          placeholder="Search for programs, interests etc."
          (keyup.enter)="onSearch()"
        />
        <button (click)="onSearch()">
          <svg viewBox="0 0 18 18">
            <use href="#search" />
          </svg>
        </button>
      </div>
      <div class="menu d-flex order-2">
        <a
          [href]="'/home' | appBaseUrl"
          [amplitude]="'/header/home'"
          class="menu-item d-lg-flex flex-column align-items-center d-none"
        >
          <div class="icon">
            <svg viewBox="0 0 22 22">
              <use href="#school" />
            </svg>
          </div>
          <div class="d-none d-lg-block">Home</div>
        </a>
        <!-- <app-link [route]="['eduniverse']"
          class="menu-item d-lg-flex flex-column align-items-center d-none">
          <div class="icon">
            <svg viewBox="0 0 22 22">
              <use href="#planet" />
            </svg>
          </div>
          <div class="d-none d-lg-block">Eduniverse</div>
        </app-link> -->
        <app-link
          [route]="['messages']"
          [amplitude]="'/header/messaging'"
          class="menu-item d-lg-flex flex-column align-items-center d-none"
        >
          <div class="icon">
            <svg viewBox="0 0 22 22">
              <use href="#mail" />
            </svg>
            <span
              [matBadge]="amountOfUnreadMessages$ | async"
              *ngIf="amountOfUnreadMessages$ | async"
              matBadgeSize="medium"
            >
            </span>
          </div>
          <div class="d-none d-lg-block">Messaging</div>
        </app-link>
        <!-- <app-link [route]="['groups']" class="menu-item d-lg-flex flex-column align-items-center d-none">
          <div class="icon">
            <svg viewBox="0 0 22 22">
              <use href="#groups" />
            </svg>
          </div>
          <div class="d-none d-lg-block">Clubs & Competitions</div>
        </app-link> -->
        <div class="menu-item d-lg-flex flex-column align-items-center d-none">
          <button
            [amplitude]="'/header/notifications'"
            (click)="onToggleNotifications(); $event.stopPropagation(); (false)"
            class="d-lg-flex flex-column align-items-center d-none"
          >
            <div class="icon">
              <svg viewBox="0 0 22 22">
                <use href="#bell" />
              </svg>
              <span
                [matBadge]="amountOfNewNotifications$ | async"
                *ngIf="amountOfNewNotifications$ | async"
                matBadgeSize="medium"
              >
              </span>
            </div>
            <div class="d-none d-lg-block">Notifications</div>
          </button>
          <app-notification
            *ngIf="showNotifications$ | async"
            [notifications]="notifications$ | async"
            (loadMore)="onLoadMoreNotifications()"
          ></app-notification>
        </div>
      </div>
      <div
        class="profile d-flex flex-column align-items-center order-0 order-lg-3"
        (click)="showProfileMenu = !showProfileMenu"
      >
        <ng-container *ngIf="(user$ | async) as user">
          <app-avatar [src]="user.icon" [placeholder]="user | initials" class="ava-link d-lg-none"></app-avatar>
          <div class="d-none d-lg-block">
            <app-avatar [src]="user.icon" [placeholder]="user | initials" class="ava-link"></app-avatar>
          </div>
          <div class="d-none d-lg-block">Profile</div>
          <app-profile-menu [user]="user" (onLogout)="logout()"></app-profile-menu>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="(user$ | async) as user">
      <app-profile-menu [user]="user" (onLogout)="logout()"></app-profile-menu>
    </ng-container>
  </div>
</header>
<section class="mobile-menu d-lg-none">
  <div class="container d-flex align-items-center justify-content-between">
    <app-link [route]="['/']" class="menu-item d-flex flex-column align-items-center">
      <div class="icon">
        <svg viewBox="0 0 22 22">
          <use href="#school" />
        </svg>
      </div>
      <div>Home</div>
    </app-link>
    <!-- <app-link [route]="['eduniverse']"
      class="menu-item d-flex flex-column align-items-center">
      <div class="icon">
        <svg viewBox="0 0 22 22">
          <use href="#planet" />
        </svg>

      </div>
      <div>Eduniverse</div>
    </app-link> -->
    <app-link [route]="['messages']" class="menu-item d-flex flex-column align-items-center">
      <div class="icon">
        <svg viewBox="0 0 22 22">
          <use href="#mail" />
        </svg>
        <span
          [matBadge]="amountOfUnreadMessages$ | async"
          *ngIf="amountOfUnreadMessages$ | async"
          matBadgeSize="medium"
        >
        </span>
      </div>
      <div>Messaging</div>
    </app-link>
    <!-- <app-link [route]="['groups']" class="menu-item d-flex flex-column align-items-center">
      <div class="icon">
        <svg viewBox="0 0 22 22">
          <use href="#groups" />
        </svg>
      </div>
      <div>Clubs & Competitions</div>
    </app-link> -->
    <div
      class="menu-item d-flex flex-column align-items-center"
      (click)="onToggleNotifications(); $event.stopPropagation(); (false)"
    >
      <div class="icon">
        <svg viewBox="0 0 22 22">
          <use href="#bell" />
        </svg>
        <span
          [matBadge]="amountOfNewNotifications$ | async"
          *ngIf="amountOfNewNotifications$ | async"
          matBadgeSize="medium"
        >
        </span>
      </div>
      <div>Notifications</div>
      <app-notification
        *ngIf="showNotifications$ | async"
        [notifications]="notifications$ | async"
        (loadMore)="onLoadMoreNotifications()"
      ></app-notification>
    </div>
  </div>
</section>

<ng-template #guestHeader>
  <app-no-auth-header></app-no-auth-header>
</ng-template>
