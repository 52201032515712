import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ICompetitionProject, IProject } from '../../model';

import { ProjectActions, ProjectsActions } from '../actions';

export const featureAdapter: EntityAdapter<IProject | ICompetitionProject> =
  createEntityAdapter<IProject>({
    selectId: (model) => model.id,
    sortComparer: (a: IProject, b: IProject): number => b.id - a.id
  });

export interface State extends EntityState<IProject | ICompetitionProject> {
  totalAmount: number;
  isLoading?: boolean;
  error?: Error;
}

export const initialState: State = featureAdapter.getInitialState({
  totalAmount: null,
  isLoading: false,
  error: null
});

export function Reducer(
  state = initialState,
  action: ProjectActions.Actions | ProjectsActions.Actions
): State {
  switch (action.type) {
    case ProjectActions.ActionTypes.CREATE_PROJECT:
      return {
        ...state,
        isLoading: true
      };
    case ProjectActions.ActionTypes.CREATE_PROJECT_SUCCESS:
      return featureAdapter.addOne(action.payload.project, {
        ...state,
        totalAmount: state.totalAmount + 1,
        isLoading: false
      });
    case ProjectActions.ActionTypes.CREATE_COMPETITION_PROJECT:
      return {
        ...state,
        isLoading: true
      };
    case ProjectActions.ActionTypes.CREATE_COMPETITION_PROJECT_SUCCESS:
      return featureAdapter.addOne(action.payload.project, {
        ...state,
        totalAmount: state.totalAmount + 1,
        isLoading: false
      });
    case ProjectActions.ActionTypes.UPDATE_COMPETITION_PROJECT:
      return {
        ...state,
        isLoading: true
      };
    case ProjectActions.ActionTypes.UPDATE_COMPETITION_PROJECT_SUCCESS:
      return featureAdapter.upsertOne(action.payload.project, {
        ...state,
        totalAmount: state.totalAmount + 1,
        isLoading: false
      });
    case ProjectActions.ActionTypes.EDIT_PROJECT:
      return {
        ...state,
        isLoading: true
      };
    case ProjectActions.ActionTypes.EDIT_PROJECT_SUCCESS:
      return featureAdapter.updateOne(
        {
          id: action.payload.project.id,
          changes: action.payload.project
        },
        { ...state, isLoading: false }
      );
    case ProjectActions.ActionTypes.DELETE_PROJECT:
      return {
        ...state,
        isLoading: true
      };
    case ProjectActions.ActionTypes.DELETE_PROJECT_SUCCESS:
      const totalAmount = state.totalAmount - 1;

      return featureAdapter.removeOne(action.payload.id, {
        ...state,
        totalAmount: totalAmount < 0 ? 0 : totalAmount,
        isLoading: false
      });
    case ProjectsActions.ActionTypes.LIST_PROJECTS:
      return {
        ...state,
        isLoading: true
      };
    case ProjectsActions.ActionTypes.LIST_PROJECTS_SUCCESS:
      return featureAdapter.setAll(action.payload.projects, {
        ...state,
        totalAmount: action.payload.totalAmount,
        isLoading: false
      });
    case ProjectsActions.ActionTypes.LIST_COMPETITION_PROJECTS:
      return {
        ...state,
        isLoading: true
      };
    case ProjectsActions.ActionTypes.LIST_COMPETITION_PROJECTS_SUCCESS:
      return featureAdapter.setAll(action.payload.projects, {
        ...state,
        totalAmount: action.payload.totalAmount,
        isLoading: false
      });
    case ProjectsActions.ActionTypes.CLEAN_PROJECTS:
      return initialState;
    default:
      return state;
  }
}

const competitionProjectsPerPage = 16;

export const selectAllProjects = (count: number) => (state: State) =>
  featureAdapter.getSelectors().selectAll(state).slice(0, count);
export const isLoading = (state: State) => state.isLoading;
export const getProjectsTotalAmount = (state: State) => state.totalAmount;
export const getCompetitionProjects = (state: State) =>
  featureAdapter
    .getSelectors()
    .selectAll(state)
    .filter((project) => project.type_id === 3) as ICompetitionProject[];
export const getCompetitionProjectsPagesAmount = (state: State) =>
  Math.ceil(state.totalAmount / competitionProjectsPerPage);
