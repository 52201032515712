<div class="dialog-content-overlay">
  <h1 mat-dialog-title>Create new article</h1>

  <div mat-dialog-content #container>
    <section class="form">
      <app-input [ctx]="title"></app-input>
    </section>

    <app-editor [ctx]="text" class="editor" [scrollingContainer]="container"></app-editor>
  </div>

  <mat-dialog-actions>
    <button class="mx-auto app-action-btn" (click)="save(); false" [disabled]="articleForm.invalid">Publish</button>
  </mat-dialog-actions>
</div>
