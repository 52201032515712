import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppBaseURLModule } from 'src/app/shared/modules/baseurl/baseurl.module';

import Components from './components';
import Pipes from './pipes';

@NgModule({
  declarations: [...Components, ...Pipes],
  imports: [CommonModule, AppBaseURLModule],
  exports: [...Components, ...Pipes]
})
export class AppAvatarModule {}
