import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { hideLoaders } from 'src/app/pages/components/loader/loader.actions';
import * as fromStore from 'src/app/pages/components/loader/loader.reducer';
import { userLogout } from 'src/app/core/services/user/user.actions';
import { TokenService } from 'src/app/shared/services/token.service';

import { BaseService } from './base.service';
import { clearOrganization } from './organization/organization.actions';
import { TUser } from '../../shared/models/user/user';
import { GUEST } from '../constants/guest';

export interface IUserForm {
  email: string;
  password: string;
  remember?: boolean;
}

export interface IToken {
  key: string;
  user: {
    status: number;
    type: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  token$ = new BehaviorSubject<null | string>(null);
  user$ = new BehaviorSubject<TUser>(null);
  userPhoto$: Observable<string> = this.user$.asObservable().pipe(
    filter((val) => !!val),
    map((user) => user.icon),
    shareReplay(1)
  );
  userId$: Observable<number> = this.user$.asObservable().pipe(
    filter((val) => !!val),
    map((user) => user.id)
  );
  userType$: Observable<string> = this.user$.asObservable().pipe(
    filter((val) => !!val),
    map((user) => user.type)
  );

  private readonly _type$ = new BehaviorSubject<null | string>(null);
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
    private router: Router,
    private store: Store<fromStore.State>,
    private tokenService: TokenService
  ) {
    this.tokenService.token$
      .pipe(
        tap((_) => this.token$.next(_)),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get type$() {
    return this._type$;
  }

  get status$() {
    return this.tokenService.status$;
  }

  get isLoaded$() {
    return combineLatest([
      this.tokenService.token$,
      this.tokenService.status$
    ]).pipe(
      map((_) => !_[0] || (_[0] && _[1] !== TokenService.TokenStatus.UNDEFINED))
    );
  }

  get isNotAuthorized$() {
    return this.tokenService.token$.pipe(map((_) => !_));
  }

  get isPartialAuthorized$() {
    return combineLatest([
      this.tokenService.token$,
      this.tokenService.status$
    ]).pipe(
      map((_) => !!_[0] && _[1] === TokenService.TokenStatus.PARTIAL_SIGNUP)
    );
  }

  get isFullyAuthorized() {
    return (
      !!this.tokenService.token &&
      this.tokenService.status === TokenService.TokenStatus.FULL_SIGNUP
    );
  }

  get isFullyAuthorized$(): Observable<boolean> {
    return combineLatest([
      this.tokenService.token$,
      this.tokenService.status$
    ]).pipe(
      map((_) => !!_[0] && _[1] === TokenService.TokenStatus.FULL_SIGNUP)
    );
  }

  public login(user: IUserForm) {
    return this.authToken(user);
  }

  public tokenLogin() {
    const url = `${environment.endpoint}${environment.prefix}user`;

    return this.http.get(url).pipe(
      map((res: any) => {
        if (res.code === 406) {
          // incomplete registration
          const {
            response: { user }
          } = res;

          this.setUserType(user.type);
          this.tokenService.setTokenStatus(
            TokenService.TokenStatus.PARTIAL_SIGNUP
          );

          return null;
        } else if (res.code === 200) {
          const {
            response: { user }
          } = res;

          this.setUserType(user.type);
          this.tokenService.setTokenStatus(
            TokenService.TokenStatus.FULL_SIGNUP
          );

          this.user$.next(user);

          return user;
        }

        this.setUserType(null);
        this.tokenService.setTokenStatus(TokenService.TokenStatus.UNDEFINED);

        return null;
      })
    );
  }

  public authToken(user: IUserForm) {
    return this.baseService.post('auth/token', user).pipe(
      map((data: IToken) => {
        this.setUserType(data.user.type);
        this.setToken(data.key, data.user.status, user.remember);
      })
    );
  }

  registration(user) {
    const ref = `${environment.endpoint}${environment.prefix}guest/registration`;

    return this.http.post(ref, user);
  }

  logout() {
    if (!this.token$.value) {
      return;
    }

    this.tokenService.removeToken();
    this.user$.next(GUEST);

    this.clearCache();

    this.store.dispatch(hideLoaders({ show: false }));
    this.store.dispatch(userLogout());
    this.store.dispatch(clearOrganization());
  }

  getToken() {
    return this.token$.getValue();
  }

  isUserAuthorized() {
    return !!this.getToken();
  }

  userID(): number {
    return this.user$.value?.id;
  }

  userType(): string {
    return this.user$.value?.type;
  }

  isOrgType$(): Observable<boolean> {
    return this.user$.pipe(
      filter((_) => !!_),
      map((_) => _.type === 'org')
    );
  }

  clearCache() {
    this.tokenService.removeToken();
  }

  isMyId(id) {
    return this.userID() == id;
  }

  setToken(token: string, status: number, isRememberMe: boolean) {
    if (status === 2) {
      this.tokenService.setToken(
        token,
        TokenService.TokenStatus.FULL_SIGNUP,
        isRememberMe
      );
    } else if (status === 0) {
      this.tokenService.setToken(
        token,
        TokenService.TokenStatus.PARTIAL_SIGNUP,
        isRememberMe
      );
    } else {
      this.tokenService.setToken(
        token,
        TokenService.TokenStatus.UNDEFINED,
        isRememberMe
      );
    }
  }

  setUserType(type: string) {
    this._type$.next(type);
  }
}

export namespace AuthService {
  export enum Error {
    ACCESS_DENIED = 1000,
    INVALID_USER = 1001
  }
}
