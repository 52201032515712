import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  loadEvents,
  loadEventsSuccess,
  searchEvents,
  searchEventsSuccess
} from './events.actions';
import { EventsService } from './events.service';

@Injectable()
export class EventsEffects {
  constructor(
    private actions$: Actions,
    private eventsService: EventsService
  ) {}

  effectForLoadEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadEvents),
      switchMap((params) =>
        this.eventsService
          .loadEvents(params.id, params.page)
          .pipe(
            switchMap(({ count, events }) => [
              loadEventsSuccess({ count, events: this.normalize(events) })
            ])
          )
      )
    )
  );

  effectForSearchGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchEvents),
      switchMap((params) =>
        this.eventsService
          .searchEvents(params.searchParams)
          .pipe(
            switchMap(({ count, events }) => [
              searchEventsSuccess({ count, events: this.normalize(events) })
            ])
          )
      )
    )
  );

  // effectForGroupSubscribe$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(subscribeTo),
  //     switchMap(({ id }) => this.groupsService.subscribeGroup(id).pipe(
  //       switchMap(success => success ? [subscribeToSuccess({ id })] : [])
  //     ))
  //   )
  // );

  // effectForGroupUnsubscribe$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(unsubscribeFrom),
  //     switchMap(({ id }) => this.groupsService.unsubscribeGroup(id).pipe(
  //       switchMap(success => success ? [unsubscribeFromSuccess({ id })] : [])
  //     ))
  //   )
  // );

  private normalize(events) {
    return events.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
