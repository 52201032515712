import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';

import { RegistrationActions } from './actions';
import { RegistrationService } from './registration.service';

@Injectable()
export class RegistrationStoreEffects {
  constructor(
    private readonly registrationService: RegistrationService,
    private readonly actions$: Actions
  ) {}

  @Effect()
  studentRegistration$: Observable<Action> = this.actions$.pipe(
    ofType<RegistrationActions.StudentRegistrationAction>(
      RegistrationActions.ActionTypes.STUDENT_REGISTRATION
    ),
    switchMap((params) =>
      this.registrationService
        .signup(params.payload)
        .pipe(
          switchMap((_) => [
            new RegistrationActions.StudentRegistrationSuccessAction()
          ])
        )
    ),
    catchError((_) => {
      return [
        new RegistrationActions.StudentRegistrationFailureAction(_.message)
      ];
    })
  );

  @Effect()
  appleAuth$: Observable<Action> = this.actions$.pipe(
    ofType<RegistrationActions.AppleAuthRegistrationAction>(
      RegistrationActions.ActionTypes.APPLE_AUTH
    ),
    switchMap((params) =>
      this.registrationService
        .appleAuth(params.payload)
        .pipe(
          switchMap((_) => [
            new RegistrationActions.AppleAuthRegistrationSuccessAction(_)
          ])
        )
    ),
    catchError((_) => {
      return [
        new RegistrationActions.AppleAuthRegistrationFailureAction(_.message)
      ];
    })
  );

  @Effect()
  googleAuth$: Observable<Action> = this.actions$.pipe(
    ofType<RegistrationActions.GoogleAuthRegistrationAction>(
      RegistrationActions.ActionTypes.GOOGLE_AUTH
    ),
    switchMap((params) =>
      this.registrationService
        .googleAuth(params.payload)
        .pipe(
          switchMap((_) => [
            new RegistrationActions.GoogleAuthRegistrationSuccessAction(_)
          ])
        )
    ),
    catchError((_) => {
      return [
        new RegistrationActions.GoogleAuthRegistrationFailureAction(_.message)
      ];
    })
  );

  @Effect()
  passwordlessAuth$: Observable<Action> = this.actions$.pipe(
    ofType<RegistrationActions.PasswordlessAuthRegistrationAction>(
      RegistrationActions.ActionTypes.PASSWORDLESS_AUTH
    ),
    switchMap((params) =>
      this.registrationService
        .passwordlessAuth(params.payload)
        .pipe(
          switchMap((_) => [
            new RegistrationActions.PasswordlessAuthRegistrationSuccessAction(_)
          ])
        )
    ),
    catchError((_) => {
      return [
        new RegistrationActions.PasswordlessAuthRegistrationFailureAction(
          _.message
        )
      ];
    })
  );

  @Effect()
  attachToEmail$: Observable<Action> = this.actions$.pipe(
    ofType<RegistrationActions.AttachToEmailRegistrationAction>(
      RegistrationActions.ActionTypes.ATTACH_TO_EMAIL
    ),
    switchMap((params) =>
      this.registrationService
        .attachEmail(params.payload)
        .pipe(
          switchMap((_) => [
            new RegistrationActions.AttachToEmailRegistrationSuccessAction()
          ])
        )
    ),
    catchError((_) => {
      return [
        new RegistrationActions.AttachToEmailRegistrationFailureAction(
          _.message
        )
      ];
    })
  );

  @Effect()
  authData$: Observable<Action> = this.actions$.pipe(
    ofType<RegistrationActions.AuthDataRegistrationAction>(
      RegistrationActions.ActionTypes.AUTH_DATA
    ),
    switchMap((_) =>
      this.registrationService
        .authData()
        .pipe(
          switchMap((_) => [
            new RegistrationActions.AuthDataRegistrationSuccessAction(_)
          ])
        )
    ),
    catchError((_) => {
      return [
        new RegistrationActions.AuthDataRegistrationFailureAction(_.message)
      ];
    })
  );
}
