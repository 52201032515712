import { Pipe, PipeTransform } from '@angular/core';
import { ICompetitionTeam } from '../../model';

@Pipe({
  name: 'teamMembers'
})
export class TeamMembersPipe implements PipeTransform {
  transform(team: ICompetitionTeam): string {
    const members = team.counts.admins + team.counts.users;
    return `${members} ${members > 1 ? 'members' : 'member'}`;
  }
}
