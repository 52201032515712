import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  searchOrganizations,
  searchOrganizationsSuccess,
  orgSubscribeTo,
  orgSubscribeToSuccess,
  orgUnSubscribeFrom,
  orgUnSubscribeFromSuccess,
  loadOrganization,
  loadOrganizationSuccess
} from './organizations.actions';
import { OrganizationsService } from './organizations.service';
import { Error403Component } from 'src/app/pages/error-403/error-403.component';
import { RoutingService } from 'src/app/core/services/routing.service';

@Injectable()
export class OrganizationsEffects {
  constructor(
    private actions$: Actions,
    private organizationsService: OrganizationsService,
    private routing: RoutingService
  ) {}

  effectForLoadOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganization),
      switchMap((params) =>
        this.organizationsService.getOrgInfo(params.id).pipe(
          switchMap(({ organization }) => [
            loadOrganizationSuccess({ organization })
          ]),
          catchError((_) =>
            this.handleOrganizationError(_.error, { id: params.id })
          )
        )
      )
    )
  );

  effectForSearchOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchOrganizations),
      switchMap((params) =>
        this.organizationsService.searchOrganizations(params.searchParams).pipe(
          switchMap(({ count, organizations }) => [
            searchOrganizationsSuccess({
              count,
              organizations: this.normalize(organizations)
            })
          ])
        )
      )
    )
  );

  effectForSubscribeToOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orgSubscribeTo),
      switchMap((params) =>
        this.organizationsService
          .subscribe(params.id)
          .pipe(switchMap(({ id }) => [orgSubscribeToSuccess({ id })]))
      )
    )
  );

  effectForUnSubscribeFromOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orgUnSubscribeFrom),
      switchMap((params) =>
        this.organizationsService
          .unsubscribe(params.id)
          .pipe(switchMap(({ id }) => [orgUnSubscribeFromSuccess({ id })]))
      )
    )
  );

  private normalize(organizations) {
    return organizations.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }

  private handleOrganizationError(error, params) {
    console.log('handleOrganizationError: ', error);

    switch (error.code) {
      case 403:
        this.routing.goTo403({
          skipLocationChange: true,
          state: { type: Error403Component.Type.Organization, ...params }
        });
        break;
      default:
        // TODO: redo to goTo400
        this.routing.goTo403({
          skipLocationChange: true,
          state: { type: Error403Component.Type.Organization, ...params }
        });
    }

    return of(loadOrganizationSuccess({ organization: null }));
  }
}
