import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-403-connect-dialog',
  templateUrl: './403-connect-dialog.component.html',
  styleUrls: ['./403-connect-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectDialog403Component implements OnInit {
  constructor(private dialogRef: MatDialogRef<ConnectDialog403Component>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  onSend(text) {
    this.dialogRef.close({ text });
  }
}
