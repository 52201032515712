import { IIcon } from '../../model/icon';

export const pdf: IIcon = {
  id: `PDF`,
  svg: `
    <rect width="24" height="30" rx="2" fill="#47979D"/>
    <path d="M4.7627 17.2168V20H3.8252V12.8906H6.44727C7.22526 12.8906 7.83398 13.0892 8.27344 13.4863C8.71615 13.8835 8.9375 14.4092 8.9375 15.0635C8.9375 15.7536 8.72103 16.2858 8.28809 16.6602C7.8584 17.0312 7.24154 17.2168 6.4375 17.2168H4.7627ZM4.7627 16.4502H6.44727C6.94857 16.4502 7.33268 16.333 7.59961 16.0986C7.86654 15.861 8 15.5192 8 15.0732C8 14.6501 7.86654 14.3115 7.59961 14.0576C7.33268 13.8037 6.96647 13.6719 6.50098 13.6621H4.7627V16.4502ZM10.1338 20V12.8906H12.1406C12.7591 12.8906 13.306 13.0273 13.7812 13.3008C14.2565 13.5742 14.6227 13.9632 14.8799 14.4678C15.1403 14.9723 15.2721 15.5518 15.2754 16.2061V16.6602C15.2754 17.3307 15.1452 17.9183 14.8848 18.4229C14.6276 18.9274 14.2581 19.3148 13.7764 19.585C13.2979 19.8551 12.7396 19.9935 12.1016 20H10.1338ZM11.0713 13.6621V19.2334H12.0576C12.7803 19.2334 13.3418 19.0088 13.7422 18.5596C14.1458 18.1104 14.3477 17.4707 14.3477 16.6406V16.2256C14.3477 15.4183 14.1572 14.7917 13.7764 14.3457C13.3988 13.8965 12.8617 13.6686 12.165 13.6621H11.0713ZM20.6172 16.8604H17.6338V20H16.6963V12.8906H21.1006V13.6621H17.6338V16.0938H20.6172V16.8604Z" fill="white"/>
  
  `
};
