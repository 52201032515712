import { Injectable } from '@angular/core';
import { DelegatedEventTarget } from 'src/app/core/shared/DelegatedEventTarget';
import { AuthService } from '../auth.service';
import { TUser } from '../../../shared/models/user/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { countNewDialogs, newMessage } from '../dialogs/dialogs.actions';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject, Subject, of } from 'rxjs';

import { Socket } from './socket';

@Injectable({
  providedIn: 'root'
})
export class SocketService extends DelegatedEventTarget {
  protected _socket: Socket = null;

  public isConnected$ = new BehaviorSubject(false);

  constructor(
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private store: Store
  ) {
    super();
  }

  public setup(user) {
    this.init(user);
  }

  // ---------------------------------------------
  //                Api
  // ---------------------------------------------
  public getDialog(id: number, page: number) {
    console.log(
      '[SocketService][getDialog]: ',
      id,
      page,
      this._socket.ioSocket.connected,
      this.isConnected$.value
    );

    if (!id || !page) {
      return;
    }

    this._socket.emit('get dialog', { id, page });
  }

  public sendNewMessage(to, text, foto = [], file = []) {
    if (!to) {
      return;
    }

    this._socket.emit('new message send', { to, text, foto, file });
  }

  public sendMessage(to, text, foto = [], file = []) {
    if (!to) {
      return;
    }

    this._socket.emit('message send', { to, text, foto, file });
  }

  public readed(dialog_id) {
    if (!dialog_id || !this._socket) {
      return;
    }

    this._socket.emit('dialog readed', { dialog_id });
  }
  // ---------------------------------------------
  //                Init
  // ---------------------------------------------
  protected init(user: any) {
    if (!user) {
      this._socket?.disconnect();
      this._socket = null;

      return;
    }

    const id = user.id;
    const token = this.auth.getToken();

    this.connect(id, token);

    this.addEventListener('internal-error', (event) =>
      this.socketInternalError(event)
    );
    this.addEventListener('count-new-dialogs', (event) =>
      this.onCountNewDialogs(event)
    );
    this.addEventListener('message-added', (event) =>
      this.onMessageAdded(event)
    );
  }
  // ---------------------------------------------
  //                Connect
  // ---------------------------------------------
  protected connect(userID: number, token: string) {
    const socket = (this._socket = new Socket(token, userID + ``));

    socket.on('error', (error) => {
      console.log('socket error: ', error);
    });

    socket.on('log in', () => this.isConnected$.next(true));

    socket.on('disconnect', () => this.isConnected$.next(false));

    socket
      .fromEvent('messages list')
      .subscribe((_) =>
        super.dispatchEvent(new CustomEvent('messages-list', { detail: _ }))
      );
    socket
      .fromEvent('count new dialogs')
      .subscribe((_) =>
        super.dispatchEvent(new CustomEvent('count-new-dialogs', { detail: _ }))
      );
    socket
      .fromEvent('message added')
      .subscribe((_) =>
        super.dispatchEvent(new CustomEvent('message-added', { detail: _ }))
      );
    socket
      .fromEvent('incorrect-dialog')
      .subscribe((_) =>
        super.dispatchEvent(new CustomEvent('incorrect-dialog', { detail: _ }))
      );
    socket
      .fromEvent('internal-error')
      .subscribe((_) =>
        super.dispatchEvent(new CustomEvent('internal-error', { detail: _ }))
      );

    socket.connect();
  }

  protected socketInternalError({ detail: { code, message } }: CustomEvent) {
    if (this.isAuthErrorCode(code)) {
      this._socket.disconnect();
      this.auth.logout();
    }

    console.error('Socket internal error: ', { code, message });

    this.snackBar.open(`Socket internal error: ${message}`, 'OK', {
      duration: 10 * 1000
    });
  }

  protected isAuthErrorCode(code) {
    return [
      AuthService.Error.ACCESS_DENIED,
      AuthService.Error.INVALID_USER
    ].includes(code);
  }

  protected userAuthentificated() {
    return this.auth.user$
      .pipe(
        filter((user) => !!user),
        take(1)
      )
      .toPromise();
  }
  // ---------------------------------------------
  //                Events
  // ---------------------------------------------
  protected onCountNewDialogs({ detail: { count } }: CustomEvent) {
    this.store.dispatch(countNewDialogs({ count }));
  }

  protected onMessageAdded({ detail: { dialog, message } }: CustomEvent) {
    console.log('[AppComponent] onMessageAdded', dialog, message);

    this.store.dispatch(newMessage({ dialog, message }));
  }

  protected onUserChange(user: TUser) {
    console.log('[SocketService][onUserChange]: ', user);

    if (!user) {
      if (this._socket !== null) {
        this._socket.disconnect();
        this._socket = null;
      }

      return; // #Return#
    }

    if (this._socket) {
      return; // #Return#
    }

    const id = user.id;
    const token = this.auth.getToken();

    this.connect(id, token);
  }
}
