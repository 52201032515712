import { IIcon } from '../../model/icon';

export const joinGroup: IIcon = {
  id: ``,
  svg: `
  <path fill="#00AB8D"
  d="M10.83 4.86a2.37 2.37 0 10-2.04 0h2.04zM7.1 5.61a.34.34 0 01-.25-.1A4.06 4.06 0 016.68.12a.34.34 0 11.52.43 3.38 3.38 0 00.15 4.48.34.34 0 01-.25.57zM13.13 4.75a.34.34 0 01-.3-.5c.61-1.2.46-2.65-.4-3.69a.34.34 0 11.52-.43 4.05 4.05 0 01.48 4.44.34.34 0 01-.3.18z" />
<path fill="#FDD7AD"
  d="M16.93 9.55v5.37s0 1.82-1.16 2.88c-.13.13-.2.3-.2.48v1.04c0 .38-.3.68-.68.68H9.47a.68.68 0 01-.68-.68v-.12c0-.55-.21-1.07-.6-1.45a9.38 9.38 0 01-2.1-2.83c-.68-1.7-2.04-4.07-2.72-4.75-.68-.68 0-1.35.68-1.35s2.03 1.35 2.71 2.37c.22.33.47.63.75.9a.67.67 0 001.1-.29c.13-.42.2-.85.18-1.29v-7.8a1 1 0 01.68-.95 1.02 1.02 0 011.36.96v4.06a1 1 0 01.68-.95 1.02 1.02 0 011.36.96V7.8a1.01 1.01 0 012.02 0v1.7A1.01 1.01 0 0116 8.47c.54.06.95.52.94 1.07z" />
<path fill="#CBB292"
  d="M9.81 1.7a.94.94 0 00-.34.06 1 1 0 00-.68.96v7.8c.01.43-.05.86-.18 1.28a.69.69 0 01-.3.38.67.67 0 00.98-.38c.13-.42.2-.85.18-1.29v-7.8a1 1 0 01.68-.95.94.94 0 00-.34-.06zM11.5 5.83a1 1 0 00-.67.95v3.05a.34.34 0 10.68 0V6.78a1 1 0 01.67-.95.97.97 0 00-.67 0zM13.54 6.84a1.01 1.01 0 00-.68.96v2.03a.34.34 0 00.68 0V7.8a1 1 0 01.68-.96.97.97 0 00-.68 0zM15.91 8.48a.94.94 0 00-.34.06 1.01 1.01 0 00-.68.96v.67a.34.34 0 10.68 0V9.5a1 1 0 01.68-.95.94.94 0 00-.34-.06z" />

  `
};
