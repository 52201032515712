<div class="icon">
  <a [group]="participant">
    <app-avatar [src]="participant.icon" [placeholder]="participant | initials" class="img-fluid"></app-avatar>
  </a>
</div>
<div class="ceil-content">
  <div class="ceil-text">
    <div class="ceil-title">
      <a [group]="participant">{{ participant.name }}</a>
    </div>
    <div class="ceil-sub-title">
      <ng-container *ngIf="participant.category?.name">
        {{ participant.category?.name }} • {{ participant.members }} members
      </ng-container>
      <ng-container *ngIf="!participant.category?.name"> {{ participant.members }} members </ng-container>
    </div>
    <div class="ceil-description"> {{ participant.description }} </div>
    <div class="ceil-actions">
      <a [group]="participant">Explore</a>
    </div>
    <div class="business-block-item-admin-action" *ngIf="isAdminView">
      <ng-container *ngIf="showAccept"> <span (click)="onAccept(); false">Accept</span> / </ng-container>
      <span (click)="onDecline(); false">Discard</span>
    </div>
  </div>
</div>
