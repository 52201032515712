<svg viewBox="0 0 8 8" class="close" (click)="close()">
  <use href="#close-black" />
</svg>
<div mat-dialog-content>
  <h3 class="title">Download RoundPier App</h3>
  <div class="app-links d-flex">
    <a href="https://itunes.apple.com/us/app/roundpier/id1400305303?ls=1&amp;mt=8" target="_blank" class="app-logo">
      <img src="/assets/img/app-store-logo.png" alt="App Store" class="img-fluid" />
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.roundpier.roundpier" target="_blank" class="app-logo">
      <img src="/assets/img/googleplay.png" alt="Google Play" class="img-fluid" />
    </a>
  </div>
  <button (click)="close()">Later</button>
</div>
