import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppReturnerComponent } from './components/returner/returner.component';

@NgModule({
  declarations: [AppReturnerComponent],
  imports: [CommonModule],
  exports: [AppReturnerComponent]
})
export class AppReturnerModule {}
