import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  State as ContactsState,
  selectContacts,
  isLoadingContacts,
  contactsNextPageToLoad
} from 'src/app/core/services/contacts/contacts.reducer';
import {
  clearState,
  loadContacts
} from 'src/app/core/services/contacts/contacts.actions';
import { Observable } from 'rxjs';
import { Contact } from './contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactsFacade {
  public readonly contacts$: Observable<Contact[]>;
  public readonly isLoadingContacts$: Observable<boolean>;
  public readonly nextPageToLoad$: Observable<null | number>;

  constructor(private store: Store<ContactsState>) {
    this.contacts$ = this.store.pipe(select(selectContacts()));
    this.isLoadingContacts$ = this.store.pipe(select(isLoadingContacts()));
    this.nextPageToLoad$ = this.store.pipe(select(contactsNextPageToLoad()));
  }

  loadContacts(page = 1, searchTerm = '') {
    this.store.dispatch(loadContacts({ page, searchTerm }));
  }

  clear() {
    this.store.dispatch(clearState({}));
  }
}
