import { createAction, props } from '@ngrx/store';
import { IDiscussion } from '../../../shared/models/discussion';

export const searchDiscussions = createAction(
  '[Discussions/API] Search',
  props<{ searchParams: {} }>()
);

export const searchDiscussionsSuccess = createAction(
  '[Discussions/API] Search Success',
  props<{ count: number; discussions: { [id: string]: IDiscussion } }>()
);
