<div class="comment d-flex">
  <a [profile]="comment.user">
    <app-avatar [src]="comment.user.icon" [placeholder]="comment.user | initials" class="ava"> </app-avatar>
  </a>

  <div class="comment-data">
    <div class="comment-content">
      <div class="comment-content-data">
        <div>
          <a class="name" [profile]="comment.user">{{ comment.user.name }}</a>
          <svg viewBox="0 0 20 20" *ngIf="comment.user?.is_creditable" class="verify-icon">
            <use href="#verified" />
          </svg>
        </div>
        <div class="position">{{comment.user.type_name}}</div>
        <div class="content">
          <div [innerHTML]="comment.raw_text | safeUserInput:{tags: comment.tags}" appReadMore></div>
          <app-post-link-preview-view [data]="preview" *ngFor="let preview of previews"></app-post-link-preview-view>
          <img
            *ngIf="comment.fotos?.length === 1; else imageWrapper"
            [src]="comment.fotos[0].url | baseUrl"
            class="img-fluid post-image"
            (click)="toggleImageFullscreen(0)"
          />

          <!--          <img *ngFor="let photo of comment.fotos" [src]="photo.url | baseUrl" class="img-fluid">-->
        </div>
        <div>
          <app-message-doc *ngFor="let doc of comment.docs" [doc]="doc" (click)="openDoc(doc)"></app-message-doc>
        </div>

        <div class="comment-actions d-flex">
          <div class="action d-flex align-items-center">
            <div class="count">{{comment.count_like}}</div>
            <div class="icon" (click)="like()">
              <svg viewBox="0 0 20 20">
                <use [attr.href]="comment.count_like? '#like' : '#like-wb'" />
              </svg>
            </div>
          </div>
          <div class="action d-flex align-items-center">
            <div class="count">{{replies.length}}</div>
            <div class="icon">
              <svg viewBox="0 0 20 20">
                <use [attr.href]="replies.length? '#comment' : '#comment-wb'" />
              </svg>
            </div>
          </div>
          <button
            type="button"
            *ngIf="!comment.is_reply"
            (click)="toggleReply()"
            class="action d-flex align-items-center"
            >Reply</button
          >
          <button
            type="button"
            *ngIf="(isAuthor | async) && replies.length === 0"
            (click)="delete()"
            class="action d-flex align-items-center"
            >Delete</button
          >
        </div>
      </div>

      <div *appHideForGuest>
        <app-message *ngIf="isShowReply" [ctx]="replyCtx" class="message"> </app-message>
      </div>

      <div class="replies" *ngIf="isShowReplies">
        <app-comment *ngFor="let reply of replies" [comment]="reply" [ctx]="ctx"></app-comment>
      </div>

      <button *ngIf="isShowReplies" class="toggler" (click)="toggleReplies()">Hide Replies</button>
      <button *ngIf="!isShowReplies && replies.length" class="toggler" (click)="toggleReplies()">Show Replies</button>
    </div>
  </div>
</div>

<ng-template #imageWrapper>
  <div
    class="images-wrapper d-flex flex-wrap justify-content-between"
    [ngClass]="{
        'mod-double': comment.fotos.length === 2,
        'mod-triple': comment.fotos.length === 3 || comment.fotos.length % 3 === 0,
        'mod-mixed-one': comment.fotos.length > 3 && comment.fotos.length % 3 === 1,
        'mod-mixed-two': comment.fotos.length > 3 && comment.fotos.length % 3 === 2
      }"
  >
    <!--      <img *ngFor="let photo of comment.fotos" [src]="photo.url | baseUrl" class="img-fluid">-->
    <div *ngFor="let photo of comment.fotos; index as i" class="img-item" (click)="toggleImageFullscreen(i)">
      <img [src]="photo.url | baseUrl" />
    </div>
  </div>
</ng-template>

<app-image-viewer
  *ngIf="imageIndex !== null"
  [images]="comment.fotos"
  [index]="imageIndex"
  (close)="toggleImageFullscreen(null)"
></app-image-viewer>
