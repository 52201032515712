import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { environment } from 'src/environments/environment';
import {
  AmplitudeModule,
  NoopAmplitudeModule
} from 'src/app/shared/modules/amplitude';

import { AppEffects } from './application.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([AppEffects]),
    environment.production
      ? AmplitudeModule.forRoot(environment.amplitude)
      : NoopAmplitudeModule
  ]
})
export class ApplicationModule {}
