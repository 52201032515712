import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { IDoc } from '../../models/doc';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocsService {
  constructor(private base: BaseService) {}

  public get() {
    return this.base
      .get('file/docs')
      .pipe(map((response: any) => response.docs as IDoc[]));
  }

  public add(files: File[]) {
    return from(files).pipe(
      mergeMap((file) => this.addDoc(file)),
      toArray()
    );
  }

  protected addDoc(file: File) {
    return this.base
      .post('file/add', { file })
      .pipe(map((response: any) => response.doc as IDoc));
  }
}
