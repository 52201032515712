<mat-form-field>
  <mat-label>{{ ctx.placeholder }}</mat-label>
  <textarea
    matInput
    [placeholder]="ctx.placeholder"
    [maxLength]="ctx.maxLength"
    [formControl]="ctx.control"
    #about
  ></textarea>
  <div class="character-counter d-flex justify-content-end">{{ about.textLength }}/{{ about.maxLength }}</div>
</mat-form-field>
