import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[appMobileView]'
})
export class MobileViewDirective implements OnInit {
  @Input() tabs: { left: string; middle: string };
  constructor(private el: ElementRef<HTMLDivElement>) {}

  private active = 'left';
  @HostListener('click', ['$event']) onClick(e) {
    if (
      (e.target.classList as DOMTokenList).contains('tab') &&
      (e.target.parentElement.classList as DOMTokenList).contains('mobile-tabs')
    ) {
      let tabName;

      if ((e.target.classList as DOMTokenList).contains('left')) {
        tabName = 'left';
      }

      if ((e.target.classList as DOMTokenList).contains('middle')) {
        tabName = 'middle';
      }

      document
        .querySelector('.mobile-tabs .active')
        ?.classList.remove('active');
      document
        .querySelector(`.mobile-tabs .${tabName}`)
        ?.classList.add('active');
      document
        .querySelector(`.mobile-tabs`)
        ?.classList.remove('left', 'middle');
      document.querySelector(`.mobile-tabs`)?.classList.add(tabName);
      this.active = tabName;
    }
  }

  ngOnInit(): void {
    let text = `<div class="mobile-tabs d-flex d-md-none ${this.active}">`;
    if (this.tabs?.left) {
      text += `<div class="tab left ${
        this.active === 'left' ? 'active' : ''
      }">${this.tabs.left}</div>`;
    }

    if (this.tabs?.middle) {
      text += `<div class="tab middle ${
        this.active === 'middle' ? 'active' : ''
      }">${this.tabs.middle}</div>`;
    }

    const html = new DOMParser().parseFromString(text, 'text/html');
    this.el.nativeElement.prepend(html.body.firstChild);
  }
}
