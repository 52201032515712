export const monitor = {
  id: `MONITOR`,
  title: `Monitor`,
  svg: `
  <g clip-path="url(#clip28)">
  <path fill="#95A5A5"
    d="M4.22 15.52h14.4c.76 0 1.38-.62 1.38-1.38V1.38C20 .62 19.38 0 18.62 0H1.38C.62 0 0 .62 0 1.38v12.76c0 .73.57 1.34 1.3 1.37h2.92z" />
  <path fill="#285680" d="M18.62 1.72v2.42H1.38V1.72c0-.19.15-.34.34-.34h16.56c.19 0 .34.15.34.34z" />
  <path fill="#90BAE1" d="M18.62 4.14v8.27c0 .2-.15.35-.34.35H1.72a.34.34 0 01-.34-.35V4.14h17.24z" />
  <path fill="#B0D3F0" d="M18.62 4.14v3.25C10.18 5.5 4.54 8.77 1.38 11.54v-7.4h17.24z" />
  <path fill="#7F8C8D" d="M7.59 15.52h4.82v1.72H7.6v-1.72z" />
  <path fill="#95A5A5"
    d="M7.24 17.24h5.52c1.14 0 2.07.93 2.07 2.07 0 .38-.31.69-.7.69H5.87a.69.69 0 01-.69-.69c0-1.14.93-2.07 2.07-2.07zM18.28 17.59c.95 0 1.72.77 1.72 1.72 0 .38-.3.69-.69.69h-2.07a.69.69 0 01-.69-.69c0-.95.77-1.72 1.73-1.72z" />
  <path fill="#7F8C8D"
    d="M18.28 18.62a.34.34 0 01-.35-.34v-.7a.34.34 0 11.7 0v.7c0 .19-.16.34-.35.34zM10.69 18.97H9.31a.34.34 0 110-.7h1.38a.34.34 0 110 .7z" />
  <path fill="#E64C3C" d="M3.1 2.76a.34.34 0 11-.69 0 .34.34 0 01.7 0z" />
  <path fill="#F0C419" d="M4.48 2.76a.34.34 0 11-.69 0 .34.34 0 01.7 0z" />
  <path fill="#4FBA6F" d="M5.86 2.76a.34.34 0 11-.69 0 .34.34 0 01.7 0z" />
  <path fill="#2C3E50"
    d="M3.1 6.55h4.14c.2 0 .35.16.35.35V10c0 .2-.16.34-.35.34H3.1a.34.34 0 01-.34-.34V6.9c0-.2.15-.35.34-.35z" />
  <path fill="#FDD7AD" d="M16.55 7.59a2.07 2.07 0 11-4.14 0 2.07 2.07 0 014.14 0z" />
  <path fill="#955BA5" d="M11.38 12.76c.4-1 1.63-1.73 3.1-1.73.82-.01 1.62.24 2.3.71.35.26.63.61.8 1.02h-6.2z" />
  <path fill="#35495E" d="M7.59 8.78l1.67 1.44c.3.29.74.04.74-.4V7.07c0-.44-.45-.68-.74-.4L7.59 7.92v.86z" />
</g>
<defs>
  <clipPath id="clip28">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>
  `
};
