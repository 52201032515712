<a [group]="post.group">
  <app-avatar [src]="post.group.icon" [placeholder]="post.group | initials" class="medium right"></app-avatar>
</a>

<div>
  <div class="author d-flex align-items-center">
    <a [group]="post.group">
      <div class="name d-flex align-items-center"> {{ post.group.name }} </div>
    </a>
  </div>

  <div class="author d-flex align-items-center">
    <a [profile]="post.user">
      <app-avatar [src]="post.user.icon" [placeholder]="post.user | initials" class="small right"></app-avatar>
    </a>
    <div class="d-flex align-items-center">
      <a [profile]="post.user" class="name">{{ post.user.name }}</a>
    </div>
  </div>
</div>
<span class="create_time_ago ml-auto">{{ createdTime | timeAgo }}</span>
