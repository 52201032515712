import { ClubActions } from '../actions';
import { IClub } from '../../model';

export interface State {
  club: null | IClub;
  isLoading: boolean;
}

const initialState: State = {
  club: null,
  isLoading: false
};

export function Reducer(
  state = initialState,
  action: ClubActions.Actions
): State {
  switch (action.type) {
    case ClubActions.ActionTypes.LOAD_CLUB:
      return {
        ...state,
        isLoading: true
      };
    case ClubActions.ActionTypes.LOAD_CLUB_SUCCESS:
      return {
        ...state,
        club: action.payload.club,
        isLoading: false
      };
    case ClubActions.ActionTypes.REFRESH_CLUB_SUCCESS:
      return {
        ...state,
        club: action.payload.club
      };
    case ClubActions.ActionTypes.CLUB_REMOVE_MEMBER_SUCCESS: {
      const { club } = state;
      if (!club) {
        return state;
      }

      return {
        ...state,
        club: {
          ...club,
          counts: {
            ...club.counts,
            members: club.counts.members - 1
          }
        }
      };
    }
    case ClubActions.ActionTypes.CLUB_INVITE_MEMBER_SUCCESS: {
      const { club } = state;
      if (!club) {
        return state;
      }

      return {
        ...state,
        club: {
          ...club,
          counts: {
            ...club.counts,
            members: club.counts.members + 1
          }
        }
      };
    }
    case ClubActions.ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST_SUCCESS: {
      const { club } = state;
      if (!club) {
        return state;
      }

      return {
        ...state,
        club: {
          ...club,
          counts: {
            ...club.counts,
            members: club.counts.members + 1,
            requests: club.counts.requests - 1
          }
        }
      };
    }
    case ClubActions.ActionTypes.CLUB_DECLINE_MEMBER_REQUEST_SUCCESS: {
      const { club } = state;
      if (!club) {
        return state;
      }

      return {
        ...state,
        club: {
          ...club,
          counts: {
            ...club.counts,
            requests: club.counts.requests - 1
          }
        }
      };
    }
    case ClubActions.ActionTypes.JOIN_TO_CLUB_SUCCESS: {
      const { club } = state;
      if (!club) {
        return state;
      }

      return {
        ...state,
        club: {
          ...club,
          is_requested: true
        }
      };
    }
    case ClubActions.ActionTypes.LEAVE_CLUB_SUCCESS: {
      const { club } = state;
      if (!club) {
        return state;
      }

      return {
        ...state,
        club: {
          ...club,
          is_requested: false,
          is_subscriber: false
        }
      };
    }
    case ClubActions.ActionTypes.UPDATE_CLUB_SUCCESS: {
      const { club } = state;
      if (!club) {
        return state;
      }

      return {
        ...state,
        club: action.payload.club
      };
    }
    default:
      return state;
  }
}

export const getClubInfo = (state: State) => state.club;
export const isLoadingClubInfo = (state: State) => state.isLoading;
