import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';
import { IDiscussion } from '../../../shared/models/discussion';

import * as DiscussionsActions from './discussions.actions';

export const discussionsFeatureKey = 'discussions';

export interface State {
  discussions: { [key: number]: IDiscussion };
  count: number;
}

export const initialState: State = {
  discussions: null,
  count: null
};

export const reducer = createReducer(
  initialState,
  on(DiscussionsActions.searchDiscussions, (state, action): State => {
    return {
      ...state,
      discussions: null,
      count: null
    };
  }),
  on(DiscussionsActions.searchDiscussionsSuccess, (state, action): State => {
    return {
      ...state,
      discussions: action.discussions,
      count: action.count
    };
  })
);

export const selectState = createFeatureSelector<State>(discussionsFeatureKey);

export const selectDiscussions = () =>
  createSelector(selectState, (state: State) => {
    if (!state.discussions) {
      return { items: null, count: state.count };
    }

    return { items: Object.values(state.discussions), count: state.count };
  });
