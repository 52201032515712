import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';
import { IOrganization } from 'src/app/shared/models/user/organization';
import { Job } from 'src/app/core/services/organization/organization.model';
import { search } from 'src/app/shared/icons/groups/svg/search';
import { ResponseMessage } from '../../models/base-model.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(
    private base: BaseService,
    private convertIdService: ConvertIdService,
    private auth: AuthService
  ) {}

  public loadChapters(id: string, page: number, searchTerm: string = '') {
    const urlParams = new URLSearchParams({
      page: page + ''
    });

    if (searchTerm) {
      urlParams.append('text', searchTerm);
    }

    return this.base.get(`chapters/${this.getId(id)}?${urlParams}`).pipe(
      map((response: any) => ({
        chapters: response.chapters,
        count: response.count
      }))
    );
  }

  public loadMembers(id: string, page: number, searchTerm: string = '') {
    const urlParams = new URLSearchParams({
      page: page + ''
    });

    if (searchTerm) {
      urlParams.append('text', searchTerm);
    }

    return this.base.get(`user/members/${this.getId(id)}?${urlParams}`).pipe(
      map((response: any) => ({
        members: response.members,
        count: response.count
      }))
    );
  }

  public loadOrganization(id: string | number) {
    const entry = this.auth.isFullyAuthorized ? 'user' : 'guest';
    const _id = typeof id === 'number' ? id : this.getId(id);

    return this.base.get(`${entry}/view/${_id}`).pipe(
      map((response: any) => ({
        organization: response.user as IOrganization
      }))
    );
  }

  public loadingJobs(id: string, page: number) {
    const urlParams = new URLSearchParams({
      page: page + ''
    });

    return this.base.get(`user/jobs/${this.getId(id)}?${urlParams}`).pipe(
      map((response: any) => ({
        jobs: response.jobs ?? [],
        count: response.count
      }))
    );
  }

  public createJob(job: Job) {
    return this.base
      .post('user/job-add/0', job)
      .pipe(map((response: any) => ({ job: response.job as Job })));
  }

  public editJob(job: Job) {
    return this.base
      .post(`user/job-add/${job.id}`, job)
      .pipe(map((response: any) => ({ job: response.job as Job })));
  }

  public deleteJob(job: Job) {
    return this.base.get(`user/job-del/${job.id}`).pipe(
      map((response: any) => ({
        message: response.message as ResponseMessage
      }))
    );
  }

  public inviteToProject(email: string, orgId: string) {
    return this.base.get(`user/invite?email=${email}&org_id=${orgId}`);
  }

  public loadReviews(id) {
    return this.base
      .get(`review/user/${this.getId(id)}`)
      .pipe(map((response: any) => ({ reviews: response.reviews ?? [] })));
  }

  public addReview(obj_id: number, text: string, is_anonymous: boolean) {
    return this.base
      .post('review/add', { obj_id, text, privaci: is_anonymous ? 1 : 0 })
      .pipe(map((response: any) => ({ review: response.review })));
  }

  // TODO:
  // remove this function and rename the above one
  // related to members in particular group
  getMembers(id, page) {
    const urlParams = new URLSearchParams({
      page
    });

    return this.base.get(`user/members-school/${id}?${urlParams}`).pipe(
      map((response: any) => ({
        members: response.members_school,
        count: response.count
      }))
    );
  }

  protected getId(id: string): string {
    return this.convertIdService.convertIdStringToNumber(id);
  }
}
