import { docsAdapter, docsKey, IDocsState } from './docs.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const { selectAll } = docsAdapter.getSelectors();

const selectState = createFeatureSelector<IDocsState>(docsKey);

export const selectAllDocs = createSelector(selectState, (state: IDocsState) =>
  selectAll(state)
);
