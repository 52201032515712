import { createAction, props } from '@ngrx/store';

import { Group } from './group.model';

export const resetGroups = createAction('[Groups/API] Reset Groups');

export const loadGroups = createAction(
  '[Groups/API] Load Groups',
  props<{
    typ: string;
    page: number;
    categoryID?: number;
    weeklyStartFrom?: number;
  }>()
);

export const loadGroupsSuccess = createAction(
  '[Groups/API] Load Groups Success',
  props<{ count: number; groups: Group[] }>()
);

export const loadOrgs = createAction(
  '[Groups/API] Load Orgs',
  props<{ page: number; categoryID?: number }>()
);

export const loadOrgsSuccess = createAction(
  '[Groups/API] Load Orgs Success',
  props<{ count: number; orgs: Group[] }>()
);

export const searchGroups = createAction(
  '[Groups/API] Search Groups',
  props<{ searchParams: {} }>()
);

export const searchGroupsSuccess = createAction(
  '[Groups/API] Search Groups Success',
  // props<{ count: number; groups: { [id: string]: Group } }>()
  props<{ count: number; groups: Group[] }>()
);

export const subscribeTo = createAction(
  '[Groups/API] Subscribe to Group',
  props<{ id: number }>()
);

export const subscribeToSuccess = createAction(
  '[Groups/API] Subscribe to Group Success',
  props<{ id: number }>()
);

export const unsubscribeFrom = createAction(
  '[Groups/API] Unsubscribe From Group',
  props<{ id: number }>()
);

export const unsubscribeFromSuccess = createAction(
  '[Groups/API] Unsubscribe From Group Success',
  props<{ id: number }>()
);

export const unrequestGroup = createAction(
  '[Groups/API] Group Un-Request',
  props<{ group_id: number; user_id: number }>()
);

export const unrequestGroupSuccess = createAction(
  '[Groups/API] Group Un-Request Success',
  props<{ group_id: number; user_id: number }>()
);

export const createGroup = createAction(
  '[Groups/API] Create Group',
  props<{ group: any }>()
);

export const createGroupSuccess = createAction(
  '[Groups/API] Create Group Success',
  props<{ group: Group }>()
);

export const updateUserToModerator = createAction(
  '[Groups/API] Update User To Moderator',
  props<{ groupId: number; userId: number }>()
);

export const updateUserToModeratorSuccess = createAction(
  '[Groups/API] Update User To Moderator Success',
  props<{ groupId: number; userId: number }>()
);

export const updateUserToMember = createAction(
  '[Groups/API] Update User To Member',
  props<{ groupId: number; userId: number }>()
);

export const updateUserToMemberSuccess = createAction(
  '[Groups/API] Update User To Member Success',
  props<{ groupId: number; userId: number }>()
);
