import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { IPost } from '../../../models/post';

@Injectable({
  providedIn: 'root'
})
export class SavedPostsService {
  constructor(private base: BaseService) {}

  public getSavedPosts(page: number): Observable<IPost[]> {
    return this.base
      .get(`saved-posts?page=${page}`)
      .pipe(map((res) => res.posts));
  }

  public addSavedPost(post: IPost) {
    console.log('test: ', post);

    return this.base
      .get(`saved-posts/add/${post.id}`)
      .pipe(map((res) => res.posts));
  }

  public deleteSavedPost(post: IPost) {
    console.log('test 2: ', post);

    return this.base
      .get(`saved-posts/del/${post.id}`)
      .pipe(map((res) => res.posts));
  }
}
