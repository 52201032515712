import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  IMessageCtx,
  IMessage,
  MessageComponent
} from '../../../message/message.component';

@Component({
  selector: 'app-share-post-dialog',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharePostDialogComponent {
  readonly messageCtx: IMessageCtx = {
    placeholder: ``,
    isHideAvatar: true,
    isTextarea: true,
    isHideControls: true,
    submit: (message) => this.close(message),
    isSupportTags: true,
    isSupportLinkPreview: true,
    focus: true
  };

  @ViewChild(MessageComponent) message: MessageComponent;

  constructor(private dialogRef: MatDialogRef<SharePostDialogComponent>) {}

  close(result?: IMessage): void {
    this.dialogRef.close(result);
  }

  share() {
    this.message.onSubmit();
  }
}
