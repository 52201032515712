import { IIcon } from '../../model/icon';

export const subGallery: IIcon = {
  id: `SUB_GALLERY`,
  svg: `
   <path fill="#547580" d="M1.6 3.2v-.7c0-.9.6-1.6 1.5-1.6h10.3c1 0 1.6.7 1.6 1.6v7.7c0 .9-.7 1.6-1.6 1.6L1.6 3.2z" />
  <path fill="#ECF0F1"
    d="M11.9 3.2H1.6C.6 3.2 0 4 0 4.8v7.7c0 .9.7 1.6 1.6 1.6h10.3c.9 0 1.5-.7 1.5-1.6V4.8c0-.9-.6-1.6-1.5-1.6z" />
  <path fill="#84B5CB" d="M11.9 13c.3 0 .5-.2.5-.5V4.8c0-.3-.2-.5-.5-.5H1.6c-.3 0-.6.2-.6.5v7.7c0 .3.3.6.6.6h10.3z" />
  <path fill="#F0C419" d="M8 7.1a.8.8 0 100-1.5A.8.8 0 008 7z" />
  <path fill="#5D4C72" d="M12.4 7.9v4.6c0 .3-.2.6-.5.6H9.3A8.8 8.8 0 007 9.7a9.8 9.8 0 015.4-1.8z" />
  <path fill="#955BA5" d="M9.3 13H1.6a.5.5 0 01-.6-.5V7.4c.9 0 3.7.2 6 2.3 1 1 1.8 2 2.3 3.4z" />
`
};
