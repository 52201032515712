import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';

import { IGroupChatMember } from '../../../model';

@Component({
  selector: 'app-chat-new-group-chat-channel-dialog',
  templateUrl: './new-group-chat-channel-dialog.component.html',
  styleUrls: ['./new-group-chat-channel-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewGroupChatChannelDialogComponent {
  private _selectedParticipants: Set<number> = new Set();

  readonly fields = {
    name: new FormControl(null, Validators.required)
  };

  readonly groupChatChannelForm = new FormGroup(this.fields);

  constructor(
    private authService: AuthService,
    private dialogRef: MatDialogRef<NewGroupChatChannelDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: NewGroupChatChannelDialogComponent.Data
  ) {}

  get participants$() {
    return this.data.chatParticipants$.pipe(
      filter((_) => !!_),
      map((_) => _.filter((_) => !this.authService.isMyId(_.id)))
    );
  }

  get isLoadingParticipants$() {
    return this.data.isLoadingParticipants$;
  }

  isSelectedContact(id: number) {
    return this._selectedParticipants.has(id);
  }

  onSelectContact(id: number) {
    this._selectedParticipants.add(id);
  }

  onUnSelectContact(id: number) {
    this._selectedParticipants.delete(id);
  }

  onCreate() {
    const selectedParticipants = [...this._selectedParticipants];

    this.dialogRef.close({
      title: this.groupChatChannelForm.value.name,
      participants: selectedParticipants
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}

export namespace NewGroupChatChannelDialogComponent {
  export interface Data {
    chatParticipants$: Observable<IGroupChatMember[]>;
    isLoadingParticipants$: Observable<boolean>;
  }
}
