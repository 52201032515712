import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ICompetitionProject } from '../../../../projects/model';

export interface Data {
  project: ICompetitionProject;
}

@Component({
  selector: 'app-competition-review-result',
  templateUrl: './competition-project-view.component.html',
  styleUrls: ['./competition-project-view.component.scss']
})
export class CompetitionProjectViewComponent {
  get project() {
    return this.data.project;
  }
  constructor(
    private dialogRef: MatDialogRef<CompetitionProjectViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  onParticipate() {
    this.dialogRef.close({ openParticipate: true });
  }
}
