<div class="profile-name d-flex align-items-center" [ngSwitch]="profile?.type">
  <ng-container *ngSwitchCase="'student'"> {{profile.name}} {{profile.student.lastname}} </ng-container>
  <ng-container *ngSwitchCase="'teacher'">
    {{profile.name}} {{profile.teacher.lastname}}
    <svg viewBox="0 0 20 20" *ngIf="profile?.is_creditable" class="verify-icon"><use href="#verified" /></svg>
  </ng-container>
  <ng-container *ngSwitchCase="'org'">
    {{profile.name}}
    <svg viewBox="0 0 20 20" *ngIf="profile?.is_creditable" class="verify-icon"><use href="#verified" /></svg>
  </ng-container>
  <!-- <div class="status online"></div> -->
</div>
<div *ngIf="profile?.is_creditable" class="text-center">Verified Educator</div>
