import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SharedModule } from 'src/app/shared/shared.module';

import { ChatComponent } from './containers/chat/chat.component';
import { ChatMessagesBoxComponent } from './containers/chat-messages-box/chat-messages-box.component';
import { ChatMessagesBoxChunk } from './containers/chat-messages-box/chat-messages-box-chunk/chat-messages-box-chunk.component';
import { ChatInfoComponent } from './containers/chat-info/chat-info.component';
import { ChatRoutingModule } from './chat-routing.module';
import { ChatSharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    ChatComponent,
    ChatMessagesBoxComponent,
    ChatMessagesBoxChunk,
    ChatInfoComponent
  ],
  imports: [
    InfiniteScrollModule,
    CommonModule,
    SharedModule,
    ChatRoutingModule,
    ChatSharedModule
  ],
  exports: []
})
export class ChatModule {}
