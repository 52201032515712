import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';
import { IMessage } from 'src/app/shared/components/message/message.component';
import {
  addGroupPost,
  addPost,
  addPostToUser,
  sharePost,
  updatePost
} from 'src/app/shared/ui/post/data/store/actions';

import {
  ReadNewsSuccessAction,
  ReadPrivateNewsSuccessAction
} from '../actions/posts.action';

@Injectable({
  providedIn: 'root'
})
export class PostsFacade {
  constructor(
    private readonly _store: Store<any>,
    private readonly _authService: AuthService,
    // TODO: redo to APIService when add v1 to it
    private readonly _baseService: BaseService,
    private readonly _convertIdService: ConvertIdService
  ) {}

  addPost(message: IMessage) {
    this._store.dispatch(
      addPost({
        post: {
          description: message.text,
          links: message.linksToPreview,
          foto: message.images,
          file: message.docs
        }
      })
    );
  }

  addGroupPost(id: number, message: IMessage) {
    this._store.dispatch(
      addGroupPost({
        id,
        post: {
          description: message.text,
          links: message.linksToPreview,
          foto: message.images,
          file: message.docs
        }
      })
    );
  }

  addPostToUser(id: string, message: IMessage) {
    this._store.dispatch(
      addPostToUser({
        post: {
          description: message.text,
          links: message.linksToPreview,
          foto: message.images,
          file: message.docs
        },
        userId: id
      })
    );
  }

  updatePost(id: number, message: IMessage) {
    this._store.dispatch(
      updatePost({
        id,
        post: {
          description: message.text,
          foto: message.images,
          file: message.docs,
          links: message.linksToPreview
        }
      })
    );
  }

  sharePost(id: number, message: IMessage, isShouldAdd: boolean = false) {
    this._store.dispatch(
      sharePost({
        isShouldAdd,
        post: {
          share: id,
          description: message.text,
          foto: message.images,
          file: message.docs,
          links: message.linksToPreview
        }
      })
    );
  }

  readNewsSuccess() {
    this._store.dispatch(new ReadNewsSuccessAction());
  }

  readPrivateNewsSuccess() {
    this._store.dispatch(new ReadPrivateNewsSuccessAction());
  }

  loadPostsForUser(userId: string, page: number) {
    const url = `${
      this._authService.isFullyAuthorized ? 'post/user' : 'guest/user-posts'
    }/${this._convertIdService.convertIdStringToNumber(userId)}?page=${page}`;

    return this._baseService.get(url).pipe(
      // TODO: add type
      map((_: any) => _.posts)
    );
  }

  loadPost(id: string) {
    const url = `${
      this._authService.isFullyAuthorized ? 'post/view' : 'guest/post'
    }/${id}`;

    return this._baseService.get(url).pipe(
      // TODO: add type
      map((_: any) => _.post)
    );
  }
}
