<div class="dialog-content-overlay">
  <button type="button" class="btn-close" (click)="close()"></button>
  <div class="modal-title mt-4"> Check your email </div>
  <div class="modal-description"> If you don't see the email in your inbox, check your spam folder. </div>
  <div class="form mx-auto mt-3 mb-4">
    <div class="form-el">
      <button class="button button-big" (click)="openLoginDialog(); false">Back to log in</button>
    </div>
  </div>
</div>
