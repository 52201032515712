import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoopAmplitudeDirective } from './directives';

@NgModule({
  declarations: [NoopAmplitudeDirective],
  exports: [NoopAmplitudeDirective],
  imports: [CommonModule]
})
export class NoopAmplitudeModule {}
