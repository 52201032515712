import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-connected-success-dialog',
  templateUrl: './account-connected-success-dialog.component.html',
  styleUrls: ['./account-connected-success-dialog.component.scss']
})
export class AccountConnectedSuccessDialogComponent implements OnDestroy {
  static FRAGMENT_ID = 'account_connected_success';

  constructor(
    private readonly _dialogRef: MatDialogRef<AccountConnectedSuccessDialogComponent>
  ) {}

  ngOnDestroy(): void {
    if (
      window.location.hash ===
      `#${AccountConnectedSuccessDialogComponent.FRAGMENT_ID}`
    ) {
      window.location.hash = '';
    }
  }

  onClose() {
    this._dialogRef.close();
  }
}
