import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './state';
import { loadImages } from '../images/images.actions';
import { loadDocs } from '../docs/docs.actions';
import { AuthService } from '../../../core/services/auth.service';
import { loadYourSchool } from '../../components/sidebars/your-school/store/your-school.actions';
import { TUser } from '../../models/user/user';
import { GUEST } from '../../../core/constants/guest';

@Injectable({
  providedIn: 'root'
})
export class InitialService {
  protected _prevUserId: number;

  constructor(private store: Store<AppState>, private auth: AuthService) {}

  public init() {
    this.auth.user$.subscribe((user) => {
      const isSame = this._prevUserId === user?.id;
      const isGuest = user === GUEST;
      if (!user || isSame || isGuest) return; // #Return#
      this._prevUserId = user.id;
      this.initialLoad(user);
    });
  }

  protected initialLoad(user: TUser) {
    this.store.dispatch(loadImages());
    this.store.dispatch(loadDocs());
    if (user.type !== 'org') this.store.dispatch(loadYourSchool());
  }
}
