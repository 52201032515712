import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppSignInDialogComponent } from 'src/app/shared/modules/sign-in-dialog/components';

@Component({
  selector: 'app-no-auth-header',
  templateUrl: './no-auth-header.component.html',
  styleUrls: ['./no-auth-header.component.scss']
})
export class NoAuthHeaderComponent {
  constructor(private readonly _dialog: MatDialog) {}

  onSignin() {
    return this._dialog.open(AppSignInDialogComponent, {
      autoFocus: false
    });
  }
}
