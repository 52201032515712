<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title>Invite Students or Teachers</h1>
  <div mat-dialog-content>
    <div class="form-wrapper p-0">
      <form>
        <mat-form-field class="w-100">
          <mat-label>Enter email</mat-label>
          <input [formControl]="rawEmails" matInput placeholder="student@email.com, teacher@email.com" />
        </mat-form-field>
        <div *ngIf="!(isValid$ | async)" class="error">
          <span>Email is not in appropriate format</span>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" [disabled]="!(isValid$ | async)" (click)="invite()">Invite via Email</button>
  </div>
</div>
