<main class="groups">
  <section *ngFor="let group of groups" class="group">
    <h1 class="title">{{ group[0] }}</h1>
    <section class="emojis">
      <button
        *ngFor="let emoji of group[1]"
        [title]="emoji[1]"
        class="emoji"
        (mouseenter)="hovered = emoji"
        (mouseleave)="hovered = ['', '']"
        (click)="onSelect(emoji); false"
      >
        {{ emoji[0] }}
      </button>
    </section>
  </section>
</main>

<section class="hovered">
  <span>{{ hovered[0] }}</span>
  <span>{{ hovered[1] }}</span>
</section>

<section *ngIf="variations" class="variations" (click)="closeVariation(); false">
  <div class="block">
    <button *ngFor="let variation of variations" class="emoji" (click)="onSelect([variation, '']); false">
      {{ variation }}
    </button>
  </div>
</section>
