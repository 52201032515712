<ng-template #dialogBody>
  <h2 mat-dialog-title>{{title}}</h2>
  <mat-dialog-content class="mat-typography">
    <mat-form-field class="example-full-width">
      <textarea matInput placeholder="Ex. It makes me feel..." [(ngModel)]="value"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <!-- <button mat-button mat-dialog-close>Cancel</button> -->
    <button mat-button type="button" [mat-dialog-close]="value" cdkFocusInitial>Send</button>
  </mat-dialog-actions>
</ng-template>
