import { ActionReducerMap } from '@ngrx/store';

import {
  State as ChatsListReducerState,
  Reducer as ChatsListReducer
} from './chats-list.reducer';
import {
  State as ChatMessagesReducerState,
  Reducer as ChatMessagesReducer
} from './chat-messages.reducer';
import {
  State as ChatMessagesChunksReducerState,
  Reducer as ChatMessagesChunksReducer
} from './chat-messages-chunks.reducer';
import {
  State as SelectedChannelReducerState,
  Reducer as SelectedChannelReducer
} from './selected-channel.reducer';
import {
  State as SelectedChatReducerState,
  Reducer as SelectedChatReducer
} from './selected-chat.reducer';

export interface State {
  chats: ChatsListReducerState;
  chatMessages: ChatMessagesReducerState;
  chatMessagesChunks: ChatMessagesChunksReducerState;
  selectedChannel: SelectedChannelReducerState;
  selectedChat: SelectedChatReducerState;
}

export const chatFeatureKey = 'chat';

export const reducers: ActionReducerMap<State> = {
  chats: ChatsListReducer,
  chatMessages: ChatMessagesReducer,
  chatMessagesChunks: ChatMessagesChunksReducer,
  selectedChannel: SelectedChannelReducer,
  selectedChat: SelectedChatReducer
};
