import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable()
export class LeftNavMenuService implements OnDestroy {
  private readonly _initialized$ = new BehaviorSubject<boolean>(false);
  private readonly _isRenderLeftNavMenu$ = new BehaviorSubject<boolean>(true);

  ngOnDestroy() {
    this._initialized$.complete();
    this._isRenderLeftNavMenu$.complete();
  }

  get initialized$() {
    return this._initialized$.asObservable();
  }

  get isRenderLeftNavMenu$() {
    return this._initialized$.pipe(
      filter((_) => !!_),
      mergeMap((_) => this._isRenderLeftNavMenu$)
    );
  }

  initialized() {
    this._initialized$.next(true);
  }

  hideLeftNavMenu() {
    this._isRenderLeftNavMenu$.next(false);
  }

  renderLeftNavMenu() {
    this._isRenderLeftNavMenu$.next(true);
  }
}
