<section class="adder" [class.invalid-wrapp]="ctx.values.touched && ctx.values.invalid" [title]="tooltip">
  <section class="inputs">
    <!--    <app-autocomplete *ngFor="let input of ctx.inputs;"-->
    <!--                     [style.width]="width"-->
    <!--                     [ctx]="input"></app-autocomplete>-->
    <app-autocomplete *ngFor="let input of ctx.inputs" [ctx]="input"></app-autocomplete>
  </section>

  <button class="add" (click)="add()" [disabled]="isDisabled | async" [icon]="'PLUS_WHITE:14'"> </button>
</section>

<section class="tags">
  <div *ngFor="let value of ctx.values.value; index as i" class="tag">
    <button class="delete" (click)="delete(i)">
      <svg viewBox="0 0 8 8">
        <use href="#close"></use>
      </svg>
    </button>
    <span>{{ ctx.getTitle(value) }}</span>
  </div>
</section>
