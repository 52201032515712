import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import { Dialog, Message } from './dialog.model';
import * as DialogsActions from './dialogs.actions';

export const dialogsFeatureKey = 'dialogs';

export interface State {
  dialogs: { [id: number]: Dialog };
  messages: { [id: number]: Message[] };
  nextPageToLoad: number;
  nextMessagesPageToLoad: number;
  selectedDialogID: number;
}

export const initialState: State = {
  dialogs: null,
  messages: {},
  nextPageToLoad: 1,
  nextMessagesPageToLoad: 1,
  selectedDialogID: null
};

export const reducer = createReducer(
  initialState,
  on(DialogsActions.loadMessages, (state, action): State => {
    return {
      ...state,
      messages: {
        ...state.messages,
        [action.dialog_id]: null
      },
      nextMessagesPageToLoad: 1,
      selectedDialogID: action.dialog_id
    };
  }),
  on(DialogsActions.loadMessagesSuccess, (state, action): State => {
    const currentMessages = state.messages[action.dialog.id] || [];

    const accumulatedMessages = _.orderBy(
      _.uniqBy(
        [...action.messages, ...currentMessages],
        (message) => message.id
      ).filter(({ text, fotos, docs }) => text || fotos.length || docs.length),
      'id'
    );

    const dialogs =
      state.dialogs && state.dialogs[action.dialog.id]
        ? state.dialogs
        : {
            ...(state.dialogs || {}),
            [action.dialog.id]: action.dialog
          };

    const nextMessagesPageToLoad = action.nextPageToLoad;

    return {
      ...state,
      dialogs,
      messages: {
        ...state.messages,
        [action.dialog.id]: accumulatedMessages
      },
      selectedDialogID: action.dialog.id,
      nextMessagesPageToLoad
    };
  }),
  on(DialogsActions.loadDialogs, (state, action): State => {
    return {
      ...initialState
    };
  }),
  on(DialogsActions.loadDialogsSuccess, (state, action): State => {
    const dialogsAsObject = action.dialogs.reduce(
      (acc: { [key: string]: Dialog }, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );

    const nextDialogs =
      state.nextPageToLoad === 1
        ? dialogsAsObject
        : { ...state.dialogs, ...dialogsAsObject };

    const nextPageToLoad =
      action.dialogs.length > 0
        ? state.nextPageToLoad + 1
        : state.nextPageToLoad;

    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        ...nextDialogs
      },
      nextPageToLoad
    };
  }),
  on(DialogsActions.updateDialogsSuccess, (state, action): State => {
    const dialogsAsObject = action.dialogs.reduce(
      (acc: { [key: string]: Dialog }, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );

    return {
      ...state,
      dialogs: dialogsAsObject
    };
  }),
  on(DialogsActions.readDialogSuccess, (state, action): State => {
    if (!state.dialogs || !state.dialogs[action.id]?.message?.is_new) {
      return state;
    }

    return {
      ...state,
      dialogs: {
        ...state.dialogs,
        [action.id]: {
          ...state.dialogs[action.id],
          message: {
            ...state.dialogs[action.id].message,
            is_new: false
          }
        }
      }
    };
  }),
  on(DialogsActions.newMessageSuccess, (state, action): State => {
    const id = action.dialog.id;
    const currentMessages = state.messages[id] || [];
    const newMessages = _.uniqBy(
      [...currentMessages, action.message],
      (message) => message.id
    );

    const currentDialog = (state.dialogs && state.dialogs[id]) || {};

    const newState = {
      ...state,
      messages: {
        ...state.messages,
        [id]: newMessages
      },
      dialogs: {
        ...state.dialogs,
        [id]: {
          ...currentDialog,
          ...action.dialog,
          message: {
            text: action.message.text,
            is_my: action.dialog.message.is_my,
            is_new: action.dialog.message.is_new,
            date: action.message.created_at
          }
        }
      }
    };

    return newState;
  }),
  on(DialogsActions.resetNextPageToLoad, (state, action) => {
    return {
      ...state,
      nextPageToLoad: 1
    };
  })
);

export const selectState = createFeatureSelector<State>(dialogsFeatureKey);

export const selectDialogs = () =>
  createSelector(selectState, (state: State) => {
    if (!state.dialogs) {
      return null;
    }

    return Object.values(state.dialogs).filter((_) => !!_);
  });

export const selectCurrentDialog = () =>
  createSelector(selectState, (state: State) => {
    if (!state.dialogs) {
      return null;
    }

    return state.dialogs[state.selectedDialogID];
  });

export const selectMessagesList = () =>
  createSelector(
    selectState,
    (state: State) => state.messages[state.selectedDialogID]
  );

export const selectCompanionID = (dialog_id: number) =>
  createSelector(selectState, (state: State) => {
    if (!state.dialogs) {
      return null;
    }

    return state.dialogs[dialog_id]?.user_to;
  });

export const selectNextPageToLoad = () =>
  createSelector(selectState, (state: State) => state.nextPageToLoad);

export const selectMessagesNextPageToLoad = () =>
  createSelector(selectState, (state: State) => state.nextMessagesPageToLoad);
