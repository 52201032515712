import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppStickyScrollDirective } from './directives/sticky-scroll/sticky-scroll.directive';

@NgModule({
  declarations: [AppStickyScrollDirective],
  imports: [CommonModule],
  exports: [AppStickyScrollDirective]
})
export class AppStickyScrollModule {}
