import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';

import {
  ChatsListActions,
  MessageActions,
  SelectedChannelActions,
  ChannelActions
} from './actions';
import { ChatService } from './service';

@Injectable({
  providedIn: 'root'
})
export class ChatStoreEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly chatService: ChatService
  ) {}

  @Effect()
  loadGroupChats$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChatsListActions.LoadGroupChatsAction>(
        ChatsListActions.ActionTypes.LOAD_GROUP_CHATS
      ),
      switchMap((params) =>
        this.chatService
          .loadGroupChats()
          .pipe(
            switchMap((chats) => [
              new ChatsListActions.LoadGroupChatsSuccessAction({ chats })
            ])
          )
      )
    )
  );

  @Effect()
  newGroupChat$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChatsListActions.NewGroupChatAction>(
        ChatsListActions.ActionTypes.NEW_GROUP_CHAT
      ),
      switchMap((params) =>
        this.chatService
          .newGroupChat(params.payload.chat)
          .pipe(
            switchMap((chat) => [
              new ChatsListActions.NewGroupChatSuccessAction({ chat })
            ])
          )
      )
    )
  );

  @Effect({ dispatch: false })
  groupChatMembers$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChatsListActions.LoadGroupChatMembersAction>(
        ChatsListActions.ActionTypes.LOAD_GROUP_CHAT_MEMBERS
      ),
      tap((_) => this.chatService.chatsMembers(_.payload.chatId))
    )
  );

  @Effect({ dispatch: false })
  editGroupChatMembers$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChatsListActions.EditGroupChatMembersAction>(
        ChatsListActions.ActionTypes.EDIT_GROUP_CHAT_MEMBERS
      ),
      tap((_) =>
        this.chatService.editGroupChatMembers(
          _.payload.chatId,
          _.payload.membersToAdd,
          _.payload.membersToRemove
        )
      )
    )
  );

  @Effect({ dispatch: false })
  editGroupChat$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChatsListActions.EditGroupChatAction>(
        ChatsListActions.ActionTypes.EDIT_GROUP_CHAT
      ),
      tap((params) => this.chatService.editGroupChat(params.payload.chat))
    )
  );

  @Effect()
  loadSelectedChannelInfo$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<SelectedChannelActions.LoadSelectedChannelAction>(
        SelectedChannelActions.ActionTypes.LOAD_SELECTED_CHANNEL
      ),
      switchMap((params) =>
        this.chatService.loadChannel(params.payload.channelId).pipe(
          switchMap((channel) => [
            new SelectedChannelActions.LoadSelectedChannelSuccessAction({
              channel
            })
          ])
        )
      )
    )
  );

  @Effect({ dispatch: false })
  newGroupChatChannel$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChannelActions.NewChannelAction>(
        ChannelActions.ActionTypes.NEW_CHANNEL
      ),
      tap((_) => this.chatService.newChannel(_.payload.channel))
    )
  );

  @Effect({ dispatch: false })
  channelMembers$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<SelectedChannelActions.LoadChannelMembersAction>(
        SelectedChannelActions.ActionTypes.LOAD_CHANNEL_MEMBERS
      ),
      tap((_) => this.chatService.channelMembers(_.payload.channelId))
    )
  );

  @Effect({ dispatch: false })
  editChannelMembers$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<SelectedChannelActions.EditChannelMembersAction>(
        SelectedChannelActions.ActionTypes.EDIT_CHANNEL_MEMBERS
      ),
      tap((_) =>
        this.chatService.editChannelMembers(
          _.payload.channelId,
          _.payload.membersToAdd,
          _.payload.membersToRemove
        )
      )
    )
  );

  @Effect({ dispatch: false })
  channelDelete$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChannelActions.DeleteChannelAction>(
        ChannelActions.ActionTypes.DELETE_CHANNEL
      ),
      tap((_) => this.chatService.channelDelete(_.payload.channelId))
    )
  );

  @Effect({ dispatch: false })
  channelLeave$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChannelActions.LeaveChannelAction>(
        ChannelActions.ActionTypes.LEAVE_CHANNEL
      ),
      tap((_) => this.chatService.channelLeave(_.payload.channelId))
    )
  );

  @Effect({ dispatch: false })
  channelEdit$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChannelActions.EditChannelAction>(
        ChannelActions.ActionTypes.EDIT_CHANNEL
      ),
      tap((_) => this.chatService.channelEdit(_.payload.channel))
    )
  );

  @Effect()
  loadMessages$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<MessageActions.LoadMessagesAction>(
        MessageActions.ActionTypes.LOAG_MESSAGES
      ),
      switchMap((params) =>
        this.chatService
          .loadMessages(params.payload.channelId, params.payload.page)
          .pipe(
            switchMap(({ messages, page }) => [
              new MessageActions.LoadMessagesSuccessAction({
                page: params.payload.page,
                messages,
                nextPageToLoad: page
              })
            ])
          )
      )
    )
  );

  @Effect({ dispatch: false })
  newMessage$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<MessageActions.NewMessageAction>(
        MessageActions.ActionTypes.NEW_MESSAGE
      ),
      tap((_) => this.chatService.newMessage(_.payload.message))
    )
  );

  @Effect()
  loadUnreadChannels$ = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<ChatsListActions.LoadUnreadChannelsAction>(
        ChatsListActions.ActionTypes.LOAD_UNREAD_CHANNELS
      ),
      switchMap((_) =>
        this.chatService.loadUnreadChannels().pipe(
          switchMap((_) => [
            new ChatsListActions.LoadUnreadChannelsSuccessAction({
              channels: _.map((_) => _.channelId)
            })
          ])
        )
      )
    )
  );
}
