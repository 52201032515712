<div class="other-block block">
  <div class="title">Suggested articles </div>
  <app-loader-2 *ngIf="!articles.items"></app-loader-2>
  <div class="items" *ngIf="articles.items">
    <div class="item d-flex align-items-center" *ngFor="let article of articles.items | randomElements:3">
      <div class="item-data">
        <div class="item-title">
          <a [article]="article">{{ article.name | textTruncate: 40 }}</a>
        </div>
        <div class="item-desc" [innerHTML]="article?.text | textTruncate: 60 | safeUserInput"> </div>
      </div>

      <a [article]="article" class="item-link">
        <span>Read</span>
      </a>
    </div>
  </div>
</div>
