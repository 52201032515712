import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import { People } from './people.model';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  constructor(private base: BaseService) {}

  searchPeople(params): Observable<{ count: number; people: People[] }> {
    return this.search('people', params).pipe(
      map((_) => ({ count: _.count, people: _.people }))
    );
  }

  private search(kind, params) {
    console.log('kinds: ', kind, params);

    const requestParams = { ...params };
    const { q, page } = requestParams;

    delete requestParams['q'];

    const urlParams = new URLSearchParams({
      ...params,
      text: q ? q : '',
      page
    });

    return this.base.get(`search/${kind}?${urlParams}`);
  }
}
