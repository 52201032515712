import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { SharedModule } from 'src/app/shared/shared.module';

import { FileToDataURLPipe } from './pipes';

@NgModule({
  declarations: [FileToDataURLPipe],
  exports: [FileToDataURLPipe]
})
export class FileToDataURLModule {}
