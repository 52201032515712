import { Pipe, PipeTransform } from '@angular/core';

import _ from 'lodash';

@Pipe({
  name: 'isValidEmail'
})
export class IsValidEmailPipe implements PipeTransform {
  private _emailInputForm: HTMLInputElement;

  constructor() {
    this._emailInputForm = document.createElement('input');
    this._emailInputForm.type = 'email';
    this._emailInputForm.required = true;
  }

  transform(value: string): boolean {
    return this.isValidEmail(value);
  }

  private isValidEmail(value: string): boolean {
    if (!_.isFunction(this._emailInputForm.checkValidity)) {
      return /\S+@\S+\.\S+/.test(value);
    }

    this._emailInputForm.value = value;

    return this._emailInputForm.checkValidity();
  }
}
