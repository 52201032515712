import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ICompetitionReview } from '../../../model';
import { CompetitionsFacade } from '../../../store';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface Data {
  review: ICompetitionReview;
}

@Component({
  selector: 'app-competition-review-result',
  templateUrl: './competition-review-result.component.html',
  styleUrls: ['./competition-review-result.component.scss']
})
export class CompetitionReviewResultComponent {
  get review() {
    return this.data.review;
  }

  readonly preReviewedFields = {
    jury_id: new FormControl('', Validators.required)
  };
  readonly postReviewedFields = {
    note: new FormControl('', Validators.required)
  };

  public resultForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<CompetitionReviewResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private readonly competitionsFacade: CompetitionsFacade
  ) {
    if (data.review.status < 2) {
      this.resultForm = new FormGroup(this.preReviewedFields);
      this.resultForm.get('jury_id').setValue(data.review.jury_id);
    } else if (data.review.status === 2) {
      this.resultForm = new FormGroup(this.postReviewedFields);
      this.resultForm.get('note').setValue(data.review.note);
    } else {
      this.resultForm = new FormGroup({ none: new FormControl() });
    }
  }

  get availableJury$() {
    return this.competitionsFacade.competitionJury$.pipe(
      map((_) =>
        _.filter(
          (jury) =>
            jury.status === 1 &&
            jury.projects_id.includes(this.data.review.project.id)
        )
      )
    );
  }

  findJuryName$(id: number) {
    return this.availableJury$.pipe(
      map((_) => _.find((item) => item.id === id))
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSubmit(status = 0) {
    if (this.review.status === 4) {
      status = this.review.status;
    }
    this.dialogRef.close({ ...this.review, ...this.resultForm.value, status });
  }
}
