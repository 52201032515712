<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title>Edit subchannel</h1>
  <div mat-dialog-content>
    <div class="form-wrapper p-0">
      <form [formGroup]="groupChatChannelForm" (ngSubmit)="onSave()">
        <mat-form-field class="w-100 mb-3">
          <mat-label>Subchannel name</mat-label>
          <input matInput formControlName="name" placeholder="Subchannel name" required />
        </mat-form-field>

        <div class="members-wrapper mb-3">
          <div class="members-header d-flex align-items-center">
            <div class="icon">
              <svg viewBox="0 0 20 20">
                <use href="#group" />
              </svg>
            </div>
            Add members to subchannel
          </div>
          <app-loader-three-dots *ngIf="isLoadingChatParticipants$ | async; else participants"></app-loader-three-dots>
          <ng-template #participants>
            <div class="members-list">
              <div class="members-item d-flex align-items-center" *ngFor="let participant of participants$ | async">
                <div class="author d-flex align-items-center">
                  <div class="avatar rounded-circle">
                    <app-avatar [src]="participant.icon" [placeholder]="participant | initials" class="img-fluid">
                    </app-avatar>
                  </div>
                  <div>
                    <div class="name d-flex align-items-center"> {{ participant.name }} </div>
                    <div class="status"> {{ participant.type_name }} </div>
                  </div>
                </div>
                <div class="actions">
                  <div
                    class="action action-del"
                    *ngIf="isSelectedContact(participant.id); else add"
                    (click)="onUnSelectContact(participant.id); false"
                  >
                    Delete
                  </div>
                  <ng-template #add>
                    <div class="action action-add" (click)="onSelectContact(participant.id); false"> Add + </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" [disabled]="groupChatChannelForm.invalid" (click)="onSave(); false">Save</button>
  </div>
</div>
