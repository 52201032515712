export const PARRENT_MARK = `parrentMark`;

export function getValue(object: unknown) {
  return object !== null && typeof object === 'object'
    ? object['value']
    : object;
}

export function getLablel(object: unknown) {
  return object !== null && typeof object === 'object'
    ? object['label']
    : object;
}

export function getOrg(organization: unknown, address: unknown) {
  if (typeof organization === `object`) {
    return {
      name: organization['value'],
      address: {
        name: address,
        lat: organization['lat'],
        lng: organization['lng']
      }
    };
  } else {
    const _address = typeof address === 'object' ? address['value'] : address;

    return {
      name: organization,
      address: {
        name: _address
      }
    };
  }
}

export function getActivity([title, organization, address]: string[]) {
  return {
    name: getValue(title),
    body: getOrg(organization, address)
  };
}

export function getScore([name, value]: any) {
  return {
    name,
    value
  };
}

export function getDate(value: Date | string): string {
  const date = value instanceof Date ? value : new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthPrefix = (month + ``).length === 1 ? `0` : ``;
  const dayPrefix = (day + ``).length === 1 ? `0` : ``;

  const string = `${year}-${monthPrefix}${month}-${dayPrefix}${day}`;

  return string;
}
