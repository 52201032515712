import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'src/environments/environment';

// TODO: similar logic in a colleges project

@Pipe({
  name: 'appBaseUrl'
})
export class AppBaseUrlPipe implements PipeTransform {
  transform(url: null | string, type?: 'assets'): null | string {
    if (!url) {
      return null;
    }

    const endpoint = this.getEndpoint(type);
    if (url.startsWith(endpoint)) {
      return url;
    }

    const url1 = new URL(url, endpoint);
    return url1.toString();
  }

  private getEndpoint(type?: 'assets') {
    switch (type) {
      case 'assets':
        return environment.endpoint;
      default:
        return environment.url;
    }
  }
}
