<input type="file" accept="image/*" class="file" (change)="fileChangeEvent($event)" #File />

<section (click)="File.click()">
  <ng-content></ng-content>
</section>

<section *ngIf="imageChangedEvent" class="cropper">
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="!!ctx.aspectRatio"
    [aspectRatio]="ctx.aspectRatio"
    [format]="ctx.format"
    [roundCropper]="ctx.rounded"
    [imageQuality]="70"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>
</section>
