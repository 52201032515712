import { IIcon } from '../../model/icon';

export const subComplain: IIcon = {
  id: `SUB_COMPLAIN`,
  svg: `
  <g clip-path="url(#clip18)">
  <path fill="#F29C1F"
    d="M13.19 4.63V2.84c0-.57-.46-1.03-1.03-1.03H9.94L8.16.26a1.04 1.04 0 00-1.32 0L5.06 1.8H2.84c-.57 0-1.03.46-1.03 1.03v1.8L0 6.2v7.76C0 14.54.46 15 1.03 15h12.94c.57 0 1.03-.46 1.03-1.03V6.2l-1.81-1.58z" />
  <path fill="#F0C419" d="M15 6.2v7.77c0 .57-.46 1.03-1.03 1.03h-.22l-4.69-3.88L13.2 7.7 15 6.2z" />
  <path fill="#F3D55B" d="M13.75 15H1.25l4.69-3.88.9-.75c.38-.31.94-.31 1.32 0l.9.75 4.7 3.88z" />
  <path fill="#F0C419" d="M5.94 11.12L1.24 15h-.2C.45 15 0 14.54 0 13.97V6.2l1.81 1.5 4.13 3.41z" />
  <path fill="#F5EFCA"
    d="M13.19 2.84V7.7l-4.13 3.42-.9-.75a1.04 1.04 0 00-1.32 0l-.9.75L1.8 7.7V2.84c0-.57.46-1.03 1.03-1.03h9.32c.57 0 1.03.46 1.03 1.03z" />
  <path fill="#E64C3C"
    d="M10.4 3.43a.93.93 0 00-1.38-.05L7.5 4.97 5.98 3.39a.93.93 0 00-1.37.04.87.87 0 00.12 1.24l1.73 1.38L4.9 7.67a.5.5 0 00.02.7c.2.18.48.19.68.03l1.9-1.52L9.4 8.4c.2.16.49.15.68-.02a.5.5 0 00.02-.71L8.54 6.05l1.73-1.38a.86.86 0 00.12-1.24z" />
</g>
<defs>
  <clipPath id="clip18">
    <path fill="#fff" d="M0 0h15v15H0z" />
  </clipPath>
</defs>
  `
};
