import { createAction, props } from '@ngrx/store';

import { Profile } from './suggestions.model';

export const loadSuggestedProfiles = createAction(
  '[Suggested/API] Load Suggested Profiles',
  props<{ N: number }>()
);

export const loadSuggestedProfilesSuccess = createAction(
  '[Suggested/API] Load Suggested Profiles Success',
  props<{ profiles: { [id: string]: Profile } }>()
);
