<div class="dialog-content-overlay">
  <button class="btn-close" (click)="closeDialog(); false"></button>
  <h1 mat-dialog-title *ngIf="teamName; else forAllTeams">Message to team: {{teamName}}</h1>
  <ng-template #forAllTeams>
    <h1 mat-dialog-title>Message to teams</h1>
  </ng-template>
  <div mat-dialog-content class="mb-4">
    <div class="form-wrapper py-0 px-3">
      <form [formGroup]="newPostForm" (ngSubmit)="onSend()">
        <div class="message-wrapper-silver">
          <mat-form-field class="w-100">
            <mat-label>Message</mat-label>
            <!-- TODO: reuse message component logic -->
            <textarea
              formControlName="message"
              matInput
              rows="6"
              placeholder="Message"
              [maxLength]="5000"
              required
              #message
            ></textarea>
            <div class="character-counter">{{message.textLength}}/{{message.maxLength}}</div>
          </mat-form-field>

          <div class="d-flex">
            <div class="btns d-flex align-items-center ml-auto">
              <button (click)="onSelectAsset(ASSETS.DOCS); false">
                <svg viewBox="0 0 20 20">
                  <use href="#files" />
                </svg>
              </button>
              <button (click)="onSelectAsset(ASSETS.IMAGES); false">
                <svg viewBox="0 0 20 20">
                  <use href="#photo" />
                </svg>
              </button>
            </div>
          </div>

          <section class="assets">
            <app-select-docs [hidden]="selectedAsset !== ASSETS.DOCS" [ctx]="docs"></app-select-docs>
            <app-select-images [hidden]="selectedAsset !== ASSETS.IMAGES" [ctx]="images"> </app-select-images>
            <!-- TODO: add emoji -->
          </section>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSend(); false" [disabled]="!newPostForm.valid">Send</button>
  </div>
</div>
