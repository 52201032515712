import { Pipe, PipeTransform } from '@angular/core';

import { RoutingService } from 'src/app/core/services/routing.service';
import { ConvertNameService } from 'src/app/shared/services/convert-name.service';

@Pipe({
  name: 'dogToLink'
})
export class DogToLinkPipe implements PipeTransform {
  constructor(
    private readonly _rountingService: RoutingService,
    private readonly _convertNameService: ConvertNameService
  ) {}

  transform(value: string = '', ...args: any[]): string {
    const tags = args[0];

    if (!tags || !tags.length) {
      return value;
    }

    const text = tags.reduce(this.applyTagToText.bind(this), value);

    return text;
  }

  private applyTagToText(text, tag) {
    const url = this.generateURL(tag);
    if (!url) {
      return text;
    }

    const tagNode = document.createElement('a');
    tagNode.setAttribute('contenteditable', 'false');
    tagNode.setAttribute('target', '_blank');
    // TODO: how to name this data-tag so no one use it ?
    // should set data-tag in order to have a link to the user while editing
    tagNode.setAttribute('data-tag', tag.user_id + '');
    tagNode.appendChild(document.createTextNode(tag.text));
    tagNode.href = url.toString();

    return text.split(tag.string).join(tagNode.outerHTML);
  }

  private generateURL(tag) {
    const name = tag.text.slice(1);
    const id = tag.user_id + ''.slice(2);

    const convertedName = this._convertNameService.convertName(name);

    return this._rountingService.getProfileLinkForIdName(id, convertedName);
  }
}
