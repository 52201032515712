import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as Registration from './reducers/registration.reducer';
import { State, registrationFeatureKey } from './reducers';

export const registrationModuleState = createFeatureSelector<State>(
  registrationFeatureKey
);

export const selectRegistrationState = createSelector(
  registrationModuleState,
  (state: State) => state.registration
);

export const isLoading = createSelector(
  selectRegistrationState,
  Registration.isLoading
);

export const isRegistered = createSelector(
  selectRegistrationState,
  Registration.isRegistered
);

export const authData = createSelector(
  selectRegistrationState,
  Registration.authData
);
