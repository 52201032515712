export function convertIdStringToNumber(id: string): string {
  const chrArr = ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm'];
  let idArray = id.split('');
  idArray.shift();
  let idNumber = idArray
    .map((item) => chrArr.findIndex((el) => el === item))
    .join('');

  return idNumber;
}

export function convertIdNumberToString(id: number | string) {
  const chrArr = ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm'];
  const idArray = id.toString(10).split('').map(Number);
  let idString = idArray.map((item) => chrArr[item]).join('');

  return `_${idString}`;
}
