import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter, mergeMap, shareReplay, take, tap } from 'rxjs/operators';

import {
  selectPosts,
  selectStatus
} from 'src/app/shared/ui/post/data/store/selector';
import {
  addGroupPost,
  complainePost,
  deletePost,
  loadPostsByGroupId,
  paginateGroupPosts,
  updatePost
} from 'src/app/shared/ui/post/data/store/actions';
import { IMessage } from 'src/app/shared/components/message/message.component';
import { IPost } from 'src/app/shared/models/post';
import { TStatus } from 'src/app/shared/models/status';

import {
  IClub,
  IClubMediaFile,
  IClubMediaPhoto,
  IClubMember,
  IClubMemberType
} from '../model';
import { ClubActions } from './actions';
import { State } from './reducers';
import {
  getClubInfo,
  isLoadingClubInfo,
  isLoadingClubMediaFiles,
  getClubMediaPhotos,
  getClubMediaFiles,
  isLoadingClubMediaPhotos,
  getClubMediaFilesAmountOfPages,
  getClubMediaPhotosAmountOfPages,
  isLoadingClubMembers,
  getClubMembersAmountOfPages,
  getClubMembers,
  getClubRequests,
  isLoadingClubRequests,
  getClubRequestsAmountOfPages
} from './selectors';

@Injectable()
export class ClubsFacade {
  readonly club$: Observable<IClub>;
  readonly clubIsLoading$: Observable<boolean>;

  readonly posts$: Observable<IPost[]>;
  readonly postsStatus$: Observable<TStatus>;

  readonly membersIsLoading$: Observable<boolean>;
  readonly members$: Observable<IClubMember[]>;
  readonly membersAmountOfPages$: Observable<number>;

  readonly mediaFilesIsLoading$: Observable<boolean>;
  readonly mediaFiles$: Observable<IClubMediaFile[]>;
  readonly mediaFilesAmountOfPages$: Observable<number>;

  readonly mediaPhotosIsLoading$: Observable<boolean>;
  readonly mediaPhotos$: Observable<IClubMediaPhoto[]>;
  readonly mediaPhotosAmountOfPages$: Observable<number>;

  readonly requestsIsLoading$: Observable<boolean>;
  readonly requests$: Observable<IClubMember[]>;
  readonly requestsAmountOfPages$: Observable<number>;

  constructor(
    private readonly _store: Store<State>,
    private readonly _actions$: Actions
  ) {
    this.club$ = this._store.pipe(select(getClubInfo), shareReplay(1));
    this.clubIsLoading$ = this._store.pipe(select(isLoadingClubInfo));

    this.posts$ = this._store.select(selectPosts({}));
    this.postsStatus$ = this._store.select(selectStatus());

    this.membersIsLoading$ = this._store.select(isLoadingClubMembers);
    this.members$ = this._store.pipe(select(getClubMembers));
    this.membersAmountOfPages$ = this._store.select(
      getClubMembersAmountOfPages
    );

    this.mediaFilesIsLoading$ = this._store.select(isLoadingClubMediaFiles);
    this.mediaFiles$ = this._store.select(getClubMediaFiles);
    this.mediaFilesAmountOfPages$ = this._store.select(
      getClubMediaFilesAmountOfPages
    );

    this.mediaPhotosIsLoading$ = this._store.select(isLoadingClubMediaPhotos);
    this.mediaPhotos$ = this._store.select(getClubMediaPhotos);
    this.mediaPhotosAmountOfPages$ = this._store.select(
      getClubMediaPhotosAmountOfPages
    );

    this.requestsIsLoading$ = this._store.pipe(select(isLoadingClubRequests));
    this.requests$ = this._store.pipe(select(getClubRequests));
    this.requestsAmountOfPages$ = this._store.pipe(
      select(getClubRequestsAmountOfPages)
    );
  }

  loadClub({ clubId }: { clubId: number }) {
    this._store.dispatch(new ClubActions.LoadClubAction({ clubId }));
  }

  updateClub({ id, club }: { id: number; club: IClub }) {
    this._store.dispatch(new ClubActions.UpdateClubAction({ id, club }));
  }

  removeClub({ id }: { id: number }) {
    this._store.dispatch(new ClubActions.RemoveClubAction({ id }));
  }

  loadMembers({
    clubId,
    term,
    page
  }: {
    clubId: number;
    term?: string;
    page: number;
  }) {
    this._store.dispatch(
      new ClubActions.LoadClubMembersAction({ clubId, term, page })
    );
  }

  loadRequests({ clubId, page }: { clubId: number; page: number }) {
    this._store.dispatch(
      new ClubActions.LoadClubRequestsAction({ clubId, page })
    );
  }

  loadMediaPhotos({ clubId, page }: { clubId: number; page: number }) {
    this._store.dispatch(
      new ClubActions.LoadClubMediaPhotosAction({ clubId, page })
    );
  }

  loadMediaFiles({ clubId, page }: { clubId: number; page: number }) {
    this._store.dispatch(
      new ClubActions.LoadClubMediaFilesAction({ clubId, page })
    );
  }

  uploadMediaPhoto({ clubId, file }: { clubId: number; file: File }) {
    this._store.dispatch(
      new ClubActions.ClubUploadMediaPhotoAction({ clubId, file })
    );
  }

  uploadMediaFile({ clubId, file }: { clubId: number; file: File }) {
    this._store.dispatch(
      new ClubActions.ClubUploadMediaFileAction({ clubId, file })
    );
  }

  updateMemberType(data: {
    clubId: number;
    memberId: number;
    type: IClubMemberType;
  }) {
    this._store.dispatch(new ClubActions.ClubUpdateMemberTypeAction(data));
  }

  connectMember(data: { memberId: number }) {
    this._store.dispatch(new ClubActions.ClubConnectMemberAction(data));
  }

  disconnectMember(data: { memberId: number }) {
    this._store.dispatch(new ClubActions.ClubDisconnectMemberAction(data));
  }

  removeMember(data: { clubId: number; memberId: number }) {
    this._store.dispatch(new ClubActions.ClubRemoveMemberAction(data));
  }

  acceptRequest(data: { clubId: number; memberId: number }) {
    this._store.dispatch(new ClubActions.ClubAcceptMemberRequestAction(data));
  }

  declineRequest(data: { clubId: number; memberId: number }) {
    this._store.dispatch(new ClubActions.ClubDeclineMemberRequestAction(data));
  }

  inviteMember(data: { clubId: number; email: string }) {
    this._store.dispatch(new ClubActions.ClubInviteMemberAction(data));
  }

  join(data: { clubId: number; message?: string }) {
    this._store.dispatch(new ClubActions.JoinToClubAction(data));
  }

  leave(data: { clubId: number }) {
    this._store.dispatch(new ClubActions.LeaveClubAction(data));
  }

  loadPosts({ id }: { id: number }) {
    this._store.dispatch(loadPostsByGroupId({ id }));
  }

  loadPostsPage(id: number, page: number) {
    this._store.dispatch(paginateGroupPosts({ id, nextPage: page }));
  }

  addPost(id: number, message: IMessage) {
    this._store.dispatch(
      addGroupPost({
        id,
        post: {
          description: message.text,
          links: message.linksToPreview,
          foto: message.images,
          file: message.docs
        }
      })
    );
  }

  deletePost(id: number) {
    this._store.dispatch(
      deletePost({
        id
      })
    );
  }

  updatePost(id: number, message: IMessage) {
    this._store.dispatch(
      updatePost({
        id,
        post: {
          description: message.text,
          links: message.linksToPreview,
          foto: message.images,
          file: message.docs
        }
      })
    );
  }

  complainPost(id: number) {
    this._store.dispatch(
      complainePost({
        id
      })
    );
  }
}
