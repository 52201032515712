import { IIcon } from '../../model/icon';

export const share: IIcon = {
  id: `SHARE`,
  svg: `
  <g clip-path="url(#clip12)">
  <path fill="#DF4D60"
    d="M12.41 3.6v-.87a.72.72 0 011.21-.52l6.15 5.5a.7.7 0 010 1.04l-6.15 5.5a.72.72 0 01-1.2-.53V3.6z" />
  <path fill="#FF5364"
    d="M0 11.33v5.86c.24-5.86 6.55-5.86 6.55-5.86h2.42c.38 0 .69.31.69.7v1.7a.72.72 0 001.2.52l1.55-1.4 4.6-4.1a.7.7 0 000-1.05l-4.6-4.1-1.55-1.4a.72.72 0 00-1.2.53v1.7c0 .39-.31.7-.7.7h-2.4S0 5.12 0 11.32z" />
</g>
<defs>
  <clipPath id="clip12">
    <path fill="#fff" d="M0 .02h20v20H0z" />
  </clipPath>
</defs>`
};
