import { IIcon } from '../../model/icon';

export const location: IIcon = {
  id: `LOCATION`,
  svg: `
  <path
  d="M17.6 7.6c0 1-.5 2.4-1.2 3.8a35.6 35.6 0 01-5.2 7.6l-.6.7a.8.8 0 01-1.2 0l-.6-.7a63.5 63.5 0 01-5.2-7.6 9.1 9.1 0 01-1.2-3.8 7.6 7.6 0 0115.2 0z"
  fill="#F2C94C" />
<path d="M13.6 7.2a3.6 3.6 0 11-7.2 0 3.6 3.6 0 017.2 0z" fill="#F2994A" />
<path d="M12.4 7.2a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0z" fill="#fff" />
</g>
<g id="cap">
<path d="M.4 14.4l1-2.1 1 2.1a1 1 0 01-.9 1.3h-.2a1 1 0 01-.9-1.3zM20 8.8l-10 4.8L0 8.8l10-5.5 10 5.5z"
  fill="#6581C9" />
<path d="M1.4 13.3a.3.3 0 01-.4-.4V9.5c0-.2.2-.4.3-.4l8.7-1a.3.3 0 110 .7l-8.3 1v3.1c0 .2-.1.4-.3.4z"
  fill="#445793" />
<path
  d="M10 13.6l-6.2-3v3a2 2 0 001.1 1.7l.7.4c2.8 1.4 6 1.4 8.8 0l.7-.4a2 2 0 001.1-1.7v-3l-6.2 3zM11.4 8.5a1.4 1.4 0 11-2.8 0 1.4 1.4 0 012.8 0z"
  fill="#445793" />`
};
