import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupCategoryName'
})
export class GroupCategoryNamePipe implements PipeTransform {
  private parseCategory(category: number): string {
    switch (category) {
      case 1:
        return 'STEM';
      case 2:
        return 'Business';
      case 3:
        return 'Community service';
      case 4:
        return 'Humanities';
      case 5:
        return 'Social-sciences';
      case 6:
        return 'Other';
      default:
        return 'Other';
    }
  }

  transform(value: number): unknown {
    return this.parseCategory(value);
  }
}
