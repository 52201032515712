import { createReducer, on } from '@ngrx/store';

import * as PostActions from 'src/app/shared/ui/post/data/store/actions';

import { IComment } from '../../../models/comment';
import { IPost } from '../../../models/post';
import { getStatus, TStatus } from '../../../models/status';
import {
  addSavedPost,
  clearSavedPosts,
  deleteSavedPost,
  loadSavedPosts
} from './posts.saved.actions';

import { updateValue } from '../../../ui/post/data/store/reducer';

export interface ISavedPostsState {
  posts: IPost[];
  status: TStatus;
}

export interface ICommentsState {
  values: IComment[];
  status: TStatus;
  count: number;
}

export const initialState: ISavedPostsState = {
  posts: [],
  status: `INITIAL`
};

const POSTS_IN_PACK = 20;

export const savedPostsReducer = createReducer(
  initialState,
  on(clearSavedPosts, (state) => ({ ...state, posts: [], status: `INITIAL` })),
  on(loadSavedPosts.init, (state, action) => ({
    ...state,
    status: action.payload.page === 1 ? `LOADING` : `MERGING`
  })),
  on(loadSavedPosts.success, (state, action) => ({
    ...state,
    posts: [...state.posts, ...action.payload],
    status: getStatus(action.payload, POSTS_IN_PACK)
  })),
  on(addSavedPost.success, (state, action) => {
    const posts = [{ ...action.payload, is_saved: true }, ...state.posts];
    const status = state.posts.length === 0 ? `FULL` : state.status;
    return { ...state, posts, status };
  }),
  on(deleteSavedPost.success, (state, action) => {
    const posts = state.posts.filter(({ id }) => id !== action.payload.id);
    const status =
      state.status === `FULL` && posts.length === 0 ? `EMPTY` : state.status;
    return { ...state, posts, status };
  }),
  on(PostActions.likePostSuccess, (state, action) => ({
    ...state,
    posts: updateValue(state.posts, +action.id, (value) => ({
      ...value,
      likes: {
        count: action.count
      }
    }))
  })),
  on(PostActions.deletePostSuccess, (state, action) => ({
    ...state,
    posts: state.posts.filter(({ id }) => +id !== +action.id)
  })),
  on(PostActions.updatePostSuccess, (state, action) => ({
    ...state,
    posts: updateValue(state.posts, +action.post.id, (value) => ({
      ...value,
      ...action.post.changes
    }))
  }))
);
