import { Pipe, PipeTransform } from '@angular/core';

import linkifyHtml from 'linkifyjs/html';

@Pipe({
  name: 'links'
})
export class LinksPipe implements PipeTransform {
  public static LINK_IDENTIFIER = 'parse-link';

  transform(value: string): string {
    const template = document.createElement('template');
    template.innerHTML = this.linkify(value);

    const links = template.content.querySelectorAll('a');

    links.forEach((link) => this.patchinkInPlace(link));

    return template.innerHTML;
  }

  private linkify(value: string) {
    return linkifyHtml(value, {
      defaultProtocol: 'https',
      target: {
        url: '_blank'
      }
    });
  }

  private patchinkInPlace(originLink: HTMLAnchorElement) {
    const href = originLink.getAttribute('href');
    const target = originLink.getAttribute('target');

    // in order to remove all attributes, which linkifyHtml inserted
    Array.from(originLink.attributes).forEach((attr) =>
      originLink.removeAttribute(attr.name)
    );

    originLink.setAttribute('href', href);
    originLink.setAttribute('target', target);

    originLink.classList.add(LinksPipe.LINK_IDENTIFIER);
  }
}
