import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RoutingService } from 'src/app/core/services/routing.service';

import { AppBaseUrlPipe } from '../../../baseurl/pipes';
import { SafeUserInputPipe } from '../../../safe-user-input/pipes';

@Directive({
  selector: 'a[article]',
  providers: [AppBaseUrlPipe, SafeUserInputPipe]
})
export class ArticleLinkDirective implements OnInit {
  @Input() article: { id: number; name: string; tags: Array<object> };
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_blank';

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _routingService: RoutingService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe,
    // TODO: consider to use ArticleLinkService instead
    private readonly _appSafeUserInputPipe: SafeUserInputPipe
  ) {}

  ngOnInit(): void {
    this._el.nativeElement.setAttribute('href', this.route);
    this._el.nativeElement.setAttribute('target', this.target);
  }

  private get route() {
    const name = this._appSafeUserInputPipe.transform(this.article.name, {
      tags: this.article.tags
    });

    const link = this._routingService.getDiscussionLink(this.article.id, name);
    const url = this._router.serializeUrl(this._router.createUrlTree(link));

    return this._appBaseUrlPipe.transform(url);
  }
}
