import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, competitionsFeatureKey } from '../reducers';
import * as ShortListCompetitions from '../reducers/competitions-short-list.reducer';
import * as CurrentCompetitions from '../reducers/current-competitions.reducer';

const competitionsModuleState = createFeatureSelector<State>(
  competitionsFeatureKey
);

const selectCompetitionsShortListState = createSelector(
  competitionsModuleState,
  (state: State) => state.competitionsShortList
);

export const getCompetitionsShortList = createSelector(
  selectCompetitionsShortListState,
  ShortListCompetitions.competitionsShortList
);

export const isLoadingCompetitionsShortList = createSelector(
  selectCompetitionsShortListState,
  ShortListCompetitions.isLoading
);
