import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nbspClear'
})
export class NbspClearPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&');
  }
}
