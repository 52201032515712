<div class="ceil-item">
  <div class="ceil-logo-wrapper">
    <div class="ceil-logo">
      <a [profile]="organization">
        <app-avatar [src]="organization.icon" [placeholder]="organization | initials" class="img-fluid"> </app-avatar>
      </a>
    </div>
  </div>
  <div class="ceil-content">
    <div class="ceil-text">
      <a [profile]="organization">
        <div class="ceil-title">{{ organization.name | textTruncate: 30 }}</div>
      </a>
      <div class="ceil-description">{{ organization.type_name | textTruncate: 50 }}</div>
    </div>
    <div class="ceil-explore-link">
      <a
        *ngIf="!organization.is_friend && !organization.friend_request; else disconnectBlock"
        (click)="onFollow(organization); false"
        >Follow</a
      >
      <ng-template #disconnectBlock>
        <a (click)="onUnfollow(organization); false">Unfollow</a>
      </ng-template>
    </div>
  </div>
</div>
