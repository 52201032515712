<app-loader *ngIf="isLoading$ | async; else competitions"></app-loader>
<ng-template #competitions>
  <app-competitions-cmp
    [canCreateCompetition]="canCreateCompetition$ | async"
    [currentCompetitions]="currentCompetitions$ | async"
    [hasMoreCurrentCompetitionsToLoad]="hasMoreCurrentCompetitionsToLoad$ | async"
    [isLoadingCurrentCompetitions]="isLoadingCurrentCompetitions$ | async"
    [upcomingCompetitions]="upcomingCompetitions$ | async"
    [hasMoreUpcomingCompetitionsToLoad]="hasMoreUpcomingCompetitionsToLoad$ | async"
    [isLoadingUpcomingCompetitions]="isLoadingUpcomingCompetitions$ | async"
    [myCompetitions]="myCompetitions$ | async"
    [hasMoreMyCompetitionsToLoad]="hasMoreMyCompetitionsToLoad$ | async"
    [isLoadingMyCompetitions]="isLoadingMyCompetitions$ | async"
    [previousCompetitions]="previousCompetitions$ | async"
    [hasMorePreviousCompetitionsToLoad]="hasMorePreviousCompetitionsToLoad$ | async"
    [isLoadingPreviousCompetitions]="isLoadingPreviousCompetitions$ | async"
    (create)="onCreateCompetition()"
    (loadMoreCurrentCompetitions)="onLoadMoreCurrentCompetitions()"
    (loadMoreUpcomingCompetitions)="onLoadMoreUpcomingCompetitions()"
    (loadMoreMyCompetitions)="onLoadMoreMyCompetitions()"
    (loadMorePreviousCompetitions)="onLoadMorePreviousCompetitions()"
  ></app-competitions-cmp>
</ng-template>
