import { IIcon } from '../../model/icon';

export const add: IIcon = {
  id: `ADD`,
  svg: ` <g clip-path="url(#clip26)">
  <path fill="#FDD7AD" d="M8.51 2.33a2.33 2.33 0 11-4.65 0 2.33 2.33 0 014.65 0z" />
  <path fill="#E64C3C"
    d="M10.37 7.44v2.8c0 .5-.41.92-.93.92a.94.94 0 01-.93-.93v1.86H3.86v-1.86c0 .52-.42.93-.93.93a.91.91 0 01-.66-.27.91.91 0 01-.27-.66V7.44c0-.77.62-1.4 1.4-1.4h5.58c.77 0 1.4.63 1.4 1.4z" />
  <path fill="#C03A2B"
    d="M8.51 10.7a.47.47 0 01-.46-.47V7.91a.47.47 0 01.93 0v2.32c0 .26-.21.47-.47.47zM3.86 10.7a.47.47 0 01-.46-.47V7.91a.47.47 0 11.93 0v2.32c0 .26-.21.47-.47.47z" />
  <path fill="#C03A2B" d="M8.51 10.7a.47.47 0 01-.46-.47V9.3a.47.47 0 01.93 0v.93c0 .26-.21.47-.47.47z" />
  <path fill="#35495E" d="M8.51 12.1v4.41a1.16 1.16 0 11-2.32 0 1.16 1.16 0 01-2.33 0V12.1h4.65z" />
  <path fill="#C03A2B" d="M3.86 10.7a.47.47 0 01-.46-.47V9.3a.47.47 0 11.93 0v.93c0 .26-.21.47-.47.47z" />
  <path fill="#FDD7AD" d="M16.88 2.33a2.33 2.33 0 11-4.65 0 2.33 2.33 0 014.65 0z" />
  <path fill="#E64C3C"
    d="M18.74 7.44v2.8c0 .5-.41.92-.93.92a.94.94 0 01-.93-.93v1.86h-4.65v-1.86c0 .52-.41.93-.93.93a.94.94 0 01-.93-.93V7.44c0-.77.63-1.4 1.4-1.4h5.58c.77 0 1.4.63 1.4 1.4z" />
  <path fill="#C03A2B"
    d="M16.88 10.7a.47.47 0 01-.46-.47V7.91a.47.47 0 01.93 0v2.32c0 .26-.2.47-.47.47zM12.23 10.7a.47.47 0 01-.46-.47V7.91a.47.47 0 01.93 0v2.32c0 .26-.21.47-.47.47z" />
  <path fill="#C03A2B" d="M16.88 10.7a.47.47 0 01-.46-.47V9.3a.47.47 0 01.93 0v.93c0 .26-.2.47-.47.47z" />
  <path fill="#35495E" d="M16.88 12.1v4.41a1.16 1.16 0 01-2.32 0 1.16 1.16 0 11-2.33 0V12.1h4.65z" />
  <path fill="#FDD7AD" d="M12.7 5.12a2.33 2.33 0 11-4.65 0 2.33 2.33 0 014.65 0z" />
  <path fill="#4FBA6F"
    d="M14.56 9.77v2.79c0 .51-.42.93-.93.93a.94.94 0 01-.93-.93v1.86H8.05v-1.86c0 .51-.42.93-.93.93a.94.94 0 01-.93-.93v-2.8c0-.76.62-1.39 1.4-1.39h5.57c.77 0 1.4.63 1.4 1.4z" />
  <path fill="#24AE5F"
    d="M12.7 13.02a.47.47 0 01-.47-.46v-2.33a.47.47 0 01.93 0v2.33c0 .26-.2.46-.46.46zM8.05 13.02a.47.47 0 01-.47-.46v-2.33a.47.47 0 01.93 0v2.33c0 .26-.2.46-.46.46z" />
  <path fill="#24AE5F" d="M12.7 13.02a.47.47 0 01-.47-.46v-.93a.47.47 0 11.93 0v.93c0 .26-.2.46-.46.46z" />
  <path fill="#285680" d="M12.7 14.42v4.42a1.16 1.16 0 11-2.33 0 1.16 1.16 0 11-2.32 0v-4.42h4.65z" />
  <path fill="#2C3E50" d="M10.37 19.3a.46.46 0 01-.46-.46v-2.1a.47.47 0 11.93 0v2.1c0 .25-.21.46-.47.46z" />
  <path fill="#24AE5F" d="M8.05 13.02a.47.47 0 01-.47-.46v-.93a.47.47 0 11.93 0v.93c0 .26-.2.46-.46.46z" />
  <path fill="#2C3E50"
    d="M6.19 16.98a.47.47 0 01-.47-.47v-2.1a.47.47 0 01.93 0v2.1c0 .26-.2.47-.46.47zM14.56 16.98a.47.47 0 01-.47-.47v-2.1a.47.47 0 01.93 0v2.1c0 .26-.2.47-.46.47z" />
</g>
<defs>
  <clipPath id="clip26">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>`
};
