<div
  class="chat-item group"
  [class.opened]="isOpened$(chat.id) | async"
  *ngFor="let chat of chats; trackBy: chatTrackBy"
>
  <div>
    <div
      class="d-flex group-data"
      [class.current]="chat.id === activeChatId"
      (click)="onToggle(chat.id); false"
      [ngClass]="{'new-message': isChatHasChannelsWithNewMessages(chat)}"
    >
      <div class="avatar">
        <app-avatar [placeholder]="{ name: chat.title } | initials" class="img-fluid"></app-avatar>
      </div>
      <div class="chat-data">
        <div class="chat-top d-flex">
          <div class="name"> {{ chat.title }} </div>
          <div class="date ml-auto"> {{ chat.createdAt * 1000 | date }} </div>
        </div>
      </div>
      <div
        class="chat-edit-btn"
        *ngIf="isMyGroupChat(chat)"
        (click)="onEditChat(chat); $event.stopPropagation(); false"
      >
        <svg viewBox="0 0 12 14">
          <use href="#new-edit-pencil" />
        </svg>
      </div>
    </div>
    <div class="group-wrap">
      <a
        class="group-chat-item"
        [ngClass]="{'new-message': channel.isUnread}"
        [routerLink]="getRoute(channel.id)"
        *ngFor="let channel of channels(chat); trackBy: channelTrackBy"
        appIsActiveLink
        [activeLinkClass]="'current'"
      >
        <div class="d-flex">
          <div class="avatar">
            <app-avatar [placeholder]="{ name: channel.title } | initials" class="img-fluid"></app-avatar>
          </div>
          <div class="group-chat-data d-flex">
            <div>
              <div class="name"> #{{ channel.title }} </div>
              <div class="chat-last-message d-flex" *ngIf="channel.message">
                <app-chat-channel-last-message [message]="channel.message"> </app-chat-channel-last-message>
              </div>
            </div>
            <ng-container [ngSwitch]="true">
              <div class="date ml-auto" *ngSwitchCase="!!channel.message?.createdAt">
                {{ channel.message.createdAt * 1000 | date:'HH:mm MMM dd, yyyy' }}
              </div>
              <div class="date ml-auto" *ngSwitchDefault>
                {{ channel.createdAt * 1000 | date:'HH:mm MMM dd, yyyy' }}
              </div>
            </ng-container>
          </div>
        </div>
      </a>
      <div (click)="onAddChannel(chat.id); false" class="chat-add-channel-btn">+ Create subchannel</div>
    </div>
  </div>
</div>
