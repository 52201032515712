import { createReducer, on } from '@ngrx/store';
import * as Immutable from 'immutable';
import _ from 'lodash';

import * as PostActions from './actions';
import { initialState } from './state';
import { getStatus2 } from '../../../../models/status';
import { ICommentsState } from '../model/state';
import {
  addSavedPost,
  deleteSavedPost
} from '../../../../stores/posts/saved/posts.saved.actions';
import { IId } from 'src/app/shared/models/id';
import { IPost } from 'src/app/shared/models/post';
import { postView } from 'src/app/core/services/posts/posts.actions';

export function updateValue<T extends IId>(
  values: T[],
  id: string | number,
  updater: (value: T) => T
): T[] {
  // TODO: replace to object index
  const index = values.findIndex((value) => value.id === id);

  if (index === -1) return [...values];

  const left = values.slice(0, index);
  const right = values.slice(index + 1);
  const updated = updater(values[index]);

  return [...left, updated, ...right];
}

export const postsReducer = createReducer(
  initialState,
  on(PostActions.loadPostsSuccess, (state, action) => {
    const postsOrderedIDs = Immutable.OrderedSet<number>(action.posts.keys());

    return {
      ...state,
      posts: action.posts,
      postsOrderedIDs,
      status: getStatus2(action.posts.size),
      nextPage: 2
    };
  }),
  on(PostActions.loadPostsNoPermissionsError, (state, action) => ({
    ...state,
    posts: null,
    status: 'NO_PERMISSIONS'
  })),
  on(PostActions.addPostSuccess, (state, { post }) => {
    const posts = Immutable.OrderedMap<number, IPost>()
      .set(post.id, post)
      .merge(state.posts);

    return {
      ...state,
      posts,
      status: getStatus2(posts.size)
    };
  }),
  on(addSavedPost.success, (state, action) => {
    const posts = state.posts.update(
      action.payload.id,
      (post) =>
        post && {
          ...post,
          is_saved: true
        }
    );

    const [postView] = state.postView;
    const newPostView =
      postView && postView?.id === action.payload.id
        ? {
            ...postView,
            is_saved: true
          }
        : postView;

    return {
      ...state,
      posts,
      postView: newPostView ? [newPostView] : []
    };
  }),
  on(deleteSavedPost.success, (state, action) => {
    const posts = state.posts.update(
      action.payload.id,
      (post) =>
        post && {
          ...post,
          is_saved: false
        }
    );

    const [postView] = state.postView;
    const newPostView =
      postView && postView?.id === action.payload.id
        ? {
            ...postView,
            is_saved: false
          }
        : postView;

    return {
      ...state,
      posts,
      postView: newPostView ? [newPostView] : []
    };
  }),
  on(PostActions.updatePostSuccess, (state, action) => {
    const post = state.posts.get(action.post.id) || {};

    const newPost = {
      ...post,
      ...action.post.changes
    };

    const posts = state.posts.has(post.id)
      ? state.posts.set(post.id, newPost)
      : state.posts;
    const postView =
      state.postView[0]?.id === action.post.id ? [newPost] : state.postView;

    return {
      ...state,
      posts,
      postView
    };
  }),

  on(PostActions.deletePostSuccess, (state, action) => {
    const post = state.posts.get(action.id);
    if (!post) {
      return state;
    }

    const posts = state.posts.delete(post.id);

    return {
      ...state,
      status: getStatus2(posts.size),
      posts
    };
  }),
  on(PostActions.pinPostSuccess, (state, action) => {
    const post = state.posts.get(action.post.id);
    if (!post) {
      return state;
    }

    // TODO: refactor!
    // access window from store ? really ?
    window.scrollTo({ top: 0, behavior: `smooth` });

    const posts = state.posts.update(post.id, (post) => ({
      ...post,
      pinned: 1
    }));

    return {
      ...state,
      posts
    };
  }),
  on(PostActions.unpinPostSuccess, (state, action) => {
    const post = state.posts.get(action.post.id);
    if (!post) {
      return state;
    }

    const posts = state.posts.update(post.id, (post) => ({
      ...post,
      pinned: 0
    }));

    return {
      ...state,
      posts
    };
  }),
  on(PostActions.paginatePosts, (state) => ({ ...state, status: `MERGING` })),
  on(PostActions.paginateGroupPosts, (state) => ({
    ...state,
    status: `MERGING`
  })),
  on(PostActions.paginatePostsByUser, (state) => ({
    ...state,
    status: `MERGING`
  })),
  on(PostActions.paginatePostsSuccess, (state, action) => {
    const posts = state.posts.merge(action.posts);

    return {
      ...state,
      posts,
      status: getStatus2(posts.size),
      nextPage: action.posts.size > 0 ? state.nextPage + 1 : state.nextPage
    };
  }),
  on(PostActions.loadCommentsForPost, (state, action) => {
    const currCommments = state.comments[action.post.id];

    return {
      ...state,
      comments: {
        ...state.comments,
        [action.post.id]: {
          status: `LOADING`,
          count: currCommments?.count,
          values: [...(currCommments?.values ?? [])]
        }
      }
    };
  }),
  on(PostActions.clearComments, (state) => ({ ...state, comments: {} })),
  on(
    PostActions.loadCommentsForPostSuccess,
    (state, { post, comments, isFromPost }) => {
      const currCommments = state.comments[post.id];
      const values = _.uniqBy(
        [...(currCommments?.values ?? []), ...comments],
        'id'
      );
      const count = currCommments?.count ?? post.comments?.count;
      const expectedSize = isFromPost ? 3 : 20;
      const status = comments.length < expectedSize ? `FULL` : `READY`;
      const replies = {
        ...state.replies
      };

      for (const comment of comments) {
        replies[comment.id] = {
          count: comment.replies.length,
          status: `READY`,
          values: comment.replies
        };
      }

      return {
        ...state,
        replies,
        comments: {
          ...state.comments,
          [post.id]: { status, values, count }
        }
      };
    }
  ),
  on(PostActions.addCommentToPostSuccess, (state, action) => {
    const currCommments = state.comments[action.post_id];
    const comments = {
      ...state.comments,
      [action.post_id]: {
        values: [action.comment, ...(currCommments?.values ?? [])],
        status: currCommments?.status ?? `FULL`,
        count: (currCommments?.count ?? 0) + 1
      }
    };
    return {
      ...state,
      comments
    };
  }),
  on(PostActions.loadPostsByGroupId, (state, action) => ({
    ...state,
    status: `LOADING`
  })),
  on(PostActions.loadPostsByUserId, (state, action) => ({
    ...state,
    status: `LOADING`
  })),
  on(PostActions.loadPosts, (state, action) => ({
    ...state,
    status: `LOADING`
  })),
  on(PostActions.loadPostsById, (state, action) => {
    return {
      ...state,
      postView: state.postView.find((el) => el.id !== Number(action.id))
        ? []
        : state.postView
    };
  }),
  on(PostActions.loadPostsByIdSuccess, (state, action) => {
    return {
      ...state,
      postView: [action.post]
    };
  }),
  on(PostActions.likeCommentSuccess, (state, { comment, count }) => {
    const currComments = state.comments[comment.post_id];
    let comments = currComments;
    let replies: Record<number, ICommentsState> = state.replies;

    if (comment.is_reply) {
      const repl = state.replies[comment.reply.comment_id];
      const values = [...repl.values];
      const messIndex = values.findIndex(({ id }) => id === comment.id);

      values[messIndex] = {
        ...values[messIndex],
        count_like: count
      };

      replies = {
        ...state.replies,
        [comment.reply.comment_id]: {
          ...repl,
          values
        }
      };
    } else {
      const index = currComments.values.findIndex(
        (item) => item.id === comment.id
      );
      const postComments = [...currComments.values];

      postComments[index] = {
        ...postComments[index],
        count_like: count
      };

      comments = {
        ...currComments,
        values: postComments
      } as ICommentsState;
    }

    return {
      ...state,
      replies,
      comments: {
        ...state.comments,
        [comment.post_id]: comments
      }
    };
  }),
  on(PostActions.likePostSuccess, (state, action) => {
    const posts = state.posts.update(
      action.id,
      (post) =>
        post && {
          ...post,
          is_liked: !post.is_liked,
          likes: {
            count: action.count
          }
        }
    );

    const [postView] = state.postView;
    const newPostView =
      postView && postView?.id === action.id
        ? {
            ...postView,
            is_liked: !postView.is_liked,
            likes: {
              count: action.count
            }
          }
        : postView;

    return {
      ...state,
      posts,
      postView: newPostView ? [newPostView] : []
    };
  }),
  on(PostActions.clearPosts, (state) => ({
    ...state,
    posts: Immutable.OrderedMap(),
    postsOrderedIDs: Immutable.OrderedSet()
  })),
  on(PostActions.deleteCommentSuccess, (state, { comment }) => {
    const currComments = state.comments[comment.post_id];
    let filtred = currComments.values;
    let replies = state.replies;

    if (comment.is_reply) {
      const replId = comment.reply.comment_id;
      const commnerTreplies = state.replies[replId].values;
      const removed = commnerTreplies.filter(({ id }) => id !== comment.id);

      replies = {
        ...state.replies,
        [replId]: {
          values: removed,
          status: `READY`,
          count: removed.length
        }
      };
    } else {
      filtred = currComments.values.filter(({ id }) => id !== comment.id);
    }

    return {
      ...state,
      replies,
      comments: {
        ...state.comments,
        [comment.post_id]: {
          values: filtred,
          status: currComments.status,
          count: currComments.count - 1
        }
      }
    };
  }),
  on(PostActions.replyCommentSuccess, (state, { comment }) => {
    const parrent_id = comment.reply.comment_id;
    const post_id = comment.post_id;

    const repliesS: ICommentsState = {
      values: [comment, ...(state.replies[parrent_id]?.values ?? [])],
      count: 0,
      status: `READY`
    };

    const replies = {
      ...state.replies,
      [parrent_id]: repliesS
    };

    const commentsState = state.comments[post_id];
    const comments = {
      ...state.comments,
      [post_id]: {
        ...commentsState,
        count: (commentsState?.count ?? 0) + 1
      }
    };

    return {
      ...state,
      comments,
      replies
    };
  })
  // TODO: uncomment when fix render issue (refresh)
  // on(PostActions.postViewedSuccess,
  //   (state, action) => {
  //     return {
  //       ...state,
  //       posts: updateValue<IPost>(state.posts, action.id, (value) => ({
  //         ...value,
  //         is_viewed: true
  //       }))
  //     }
  //   }
  // )
);
