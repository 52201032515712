import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import {
  FormArray,
  FormControl,
  AbstractControl,
  FormGroup
} from '@angular/forms';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { filter, switchMap, map, startWith, tap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { environment } from '../../../../../environments/environment';
import { IAutocompleteCtx } from '../../autocomplete/autocomplete.component';
import { IInputCtx } from '../../input/input.component';

@Component({
  selector: 'app-multi-tags',
  templateUrl: './multi.component.html',
  styleUrls: ['./multi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiTagsComponent<T> implements OnInit, OnDestroy {
  @Input() readonly ctx: IMultiTagsCtx<T>;

  // public width: string;
  public isDisabled: Observable<boolean>;
  public group: FormGroup;
  public tooltip = ``;
  public sbcr: Subscription;

  constructor() {}

  public ngOnInit(): void {
    // this.width = (100 / this.ctx.inputs.length) - 1 + `%`;

    const streams = this.ctx.inputs.map(({ control }) => control.valueChanges);
    const emtyInputs = combineLatest(streams).pipe(
      map((inputs) => inputs.some((input) => !input)),
      startWith(true)
    );

    this.isDisabled = combineLatest(
      [emtyInputs, this.ctx.isDisabled].filter(Boolean)
    ).pipe(
      map((values) => values.some(Boolean)),
      startWith(true)
    );

    const fields = {};
    for (let i = 0; i < this.ctx.inputs.length; i++) {
      fields[i] = this.ctx.inputs[i].control;
    }

    this.group = new FormGroup(fields);

    const getMess = () => {
      const errors = this.ctx.values.errors;

      if (errors?.minLength || errors?.required) {
        const min = errors.requiredLength ?? 1;
        const postfix = min > 1 ? `s` : ``;
        this.tooltip = `You should provide at least ${min} value${postfix}`;
      } else {
        this.tooltip = ``;
      }
    };

    getMess();

    this.sbcr = this.ctx.values.statusChanges.subscribe(getMess);
  }

  public ngOnDestroy() {
    this.sbcr.unsubscribe();
  }

  public add() {
    const inputs: string[] = [];

    for (const input of this.ctx.inputs) {
      inputs.push(input.control.value);
      input.control.setValue(``);
    }

    const value = this.ctx.map(inputs);
    const values = Array.isArray(value) ? value : [value];

    for (const value of values) {
      const control = new FormControl(value);

      this.ctx.values.push(control);
    }
  }

  public delete(index: number) {
    this.ctx.values.removeAt(index);
  }
}

export interface IMultiTagsCtx<T> {
  inputs: IAutocompleteCtx<unknown>[];
  values: FormArray;
  map(inputs: string[]): T | T[];
  getTitle(value: T): string;
  isDisabled?: Observable<boolean>;
}
