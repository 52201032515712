import { MessageTagOptionsComponent } from './../message/tag-options/tag-options.component';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorage } from 'src/app/core/storage/interfaces/local-storage.interface';
import { IDoc } from '../../models/doc';
import { IEditable } from '../../models/editable';
import { IImage, ILocalImage } from '../../models/image';
import { DocsFacade } from '../../stores/docs/docs.facade';
import { ImagesFacade } from '../../stores/images/images.facade';

interface Contact {
  id: number;
  name: string;
  online_str: string;
  icon: string;
  // TODO: remove this stuff
  // just id, name, online_str and icon
  // related to app-avatar TODOs
  teacher?: {
    lastname: string;
  };
  student?: {
    lastname: string;
  };
}

type TAsset = `IMAGES` | `DOCS` | `EMOJIS` | null;

export interface IMessage {
  readonly text: string;
  readonly images: number[];
  readonly docs: number[];
}

@Component({
  selector: 'app-new-message-dialog-with-additional-field',
  templateUrl: './new-message-dialog-with-additional-field.component.html',
  styleUrls: ['./new-message-dialog-with-additional-field.component.scss']
})
export class NewMessageDialogWithAdditionalFieldComponent implements OnInit {
  message: string = '';
  @ViewChild(`Text`, { static: true })
  readonly textRef: ElementRef<any>;
  public editable: IEditable;
  public asset: TAsset = null;
  public isLong = false;
  public line = 0;
  public tag = ``;
  public prevHtml = ``;
  public currWord = ``;

  public isSuggestArticle = false;
  public isArticleRejected = false;

  private _isShowOptions = true;
  readonly options: MessageTagOptionsComponent;
  readonly images = {
    stream: this.imagesFacade.all,
    saved: new Set<IImage>(),
    local: new Set<ILocalImage>()
  };

  readonly docs = {
    stream: this.docsFacade.all,
    saved: new Set<IDoc>(),
    local: new Set<File>()
  };

  constructor(
    public dialogRef: MatDialogRef<NewMessageDialogWithAdditionalFieldComponent>,
    readonly imagesFacade: ImagesFacade,
    readonly docsFacade: DocsFacade,
    readonly localStorage: LocalStorage,
    @Inject(MAT_DIALOG_DATA)
    public data: { contact: Contact; receiverID: string; customTitle?: string }
  ) {}

  ngOnInit(): void {}

  public onSelectAsset(asset: TAsset) {
    if (this.asset === asset) {
      this.asset = null;
    } else {
      this.asset = asset;
    }
  }

  public onKeyDown(e: KeyboardEvent) {
    const isTabOrDown = e.keyCode === 9 || e.keyCode === 40;

    if (isTabOrDown && this.options) {
      e.preventDefault();
      this.options.setFocused(0);
    }
  }

  public onEmojiSelection(emoji: string) {
    this.textRef.nativeElement.value += emoji;
  }

  public onTextChange() {
    const el = this.textRef.nativeElement;
    const text = el.value;

    const isLong = text.length > 35;

    // this.isSuggestArticle = text.length > 500 && !this.isArticleRejected;
    this.currWord = text;

    this._isShowOptions = true;
  }

  // ---------------------------------------------
  protected getCurrentWord(html: string, text: string) {
    const index = this.getCursorIndex(html);
    const isSpace = (char: string) => /\s/.exec(char);
    let start = 0;
    let end = html.length;

    for (let i = index; i > 0; i--) {
      if (isSpace(text[i])) {
        start = i + 1;
        break;
      }
    }

    for (let i = index; i < text.length - 1; i++) {
      if (isSpace(text[i])) {
        end = i;
        break;
      }
    }

    end = isSpace(text[end]) ? end - 1 : end;

    const word = text.slice(start, end + 1);

    return { word, start, end };
  }

  protected getCursorIndex(html: string) {
    let minus = 0;
    let state: 'MINUS' | 'IGNORE' = 'IGNORE';

    for (let i = 0; i < this.prevHtml.length; i++) {
      const curr = html[i];
      const prev = this.prevHtml[i];

      if (curr === `<`) state = 'MINUS';
      if (state === 'MINUS') minus += 1;
      if (curr === `>`) state = 'IGNORE';

      if (prev !== curr) return i - minus;
    }

    return html.length - 1;
  }

  protected async getMessage() {
    const host = this.textRef.nativeElement;
    const html = host.value;
    const text = html
      .replace(/&lt;/g, `<`)
      .replace(/&gt;/g, `>`)
      .replace(/&nbsp;/g, ' ')
      .replace(/<div><br><\/div>/g, '\n')
      .replace(/<br>/g, '\n')
      .replace(/<div>/g, '\n')
      .replace(/<\/div>/g, '');

    const savedImages = [...this.images.saved].map(({ id }) => id);
    const savedDocs = [...this.docs.saved].map(({ id }) => id);
    const _images = [...this.images.local].map(({ file }) => file);
    const _docs = [...this.docs.local];

    this.clear();

    const [images, docs] = await Promise.all([
      this.imagesFacade.add(_images),
      this.docsFacade.add(_docs)
    ]);

    const addedImages = images.map(({ id }) => id);
    const addedDocs = docs.map(({ id }) => id);

    const message: IMessage = {
      text: this.adjustText(text),
      images: [...addedImages, ...savedImages],
      docs: [...addedDocs, ...savedDocs]
    };

    return message;
  }

  // converts
  // <a data-tag="@=1" data-id="1">@hey1</a> test <a data-tag="@=2" data-id="1">@hey2</a>
  // to
  // @=1 test @=2
  private adjustText(text: string): string {
    const template = document.createElement('template');
    template.innerHTML = text;

    const mentions = template.content.querySelectorAll('a[data-tag]');

    mentions.forEach((mention) => {
      // @ts-ignore
      text = text.replace(mention.outerHTML, mention.dataset.tag);
    });

    return text;
  }

  protected clear() {
    this.textRef.nativeElement.innerHTML = ``;
    this.images.saved.clear();
    this.images.local.clear();
    this.docs.saved.clear();
    this.docs.local.clear();
    this.isSuggestArticle = false;
    this.isArticleRejected = false;
    this.asset = null;
    this.isLong = false;
  }

  async closeDialog(isSend): Promise<void> {
    const message = await this.getMessage();
    this.dialogRef.close({ message, isSend });
  }
}
