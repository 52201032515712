import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { SIGN_IN_DIALOG } from '../../sign-in-dialog.token';

@Component({
  selector: 'app-forgot-password-check-email-dialog',
  templateUrl: './forgot-password-check-email-dialog.component.html',
  styleUrls: ['./forgot-password-check-email-dialog.component.scss']
})
export class AppForgotPasswordCheckEmailDialogComponent {
  constructor(
    private readonly _matDialog: MatDialog,
    private readonly _matDialogRef: MatDialogRef<AppForgotPasswordCheckEmailDialogComponent>,
    @Inject(SIGN_IN_DIALOG) private readonly AppSignInDialogComponent
  ) {}

  close() {
    this._matDialogRef.close();
  }

  openLoginDialog() {
    this._matDialogRef.close();
    this._matDialog.open(this.AppSignInDialogComponent);
  }
}
