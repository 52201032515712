import { addInfoMessage, deleteInfoMessage } from './info-message.actions';
import { InfoMessage } from './info-message.model';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import * as fromStore from './info-message.reducer';
import { select, Store } from '@ngrx/store';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

@Component({
  selector: 'app-info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class InfoMessageComponent implements OnInit {
  alerts$: Observable<InfoMessage[]>;

  index: number = 1;
  constructor(private store: Store<fromStore.State>) {
    this.alerts$ = store.pipe(select(fromStore.selectAll));
  }

  ngOnInit(): void {}

  addMessage() {
    this.index = this.index + 1;
    this.store.dispatch(
      addInfoMessage({
        infoMessage: {
          type: this.index % 2 ? 'alert-success' : 'alert-warning',
          message: `Message #${this.index} `
        }
      })
    );
  }

  delete(id) {
    this.store.dispatch(
      deleteInfoMessage({
        id
      })
    );
  }
}
