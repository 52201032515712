export const userNetwork = {
  id: `USER_NETWORK`,
  title: `User Network`,
  svg: `
  <path fill="#E57E25"
  d="M9.7517 2.7653C8.5717.5207 5.9386-.5374 3.5336.2666 1.1286 1.0706-.339 3.4995.068 6.0024c.407 2.503 2.5685 4.3417 5.1043 4.3422.0828 0 .1655-.0035.2483-.0069l4.331-7.5724z" />
<path fill="#FDD7AD"
  d="M7.2275 3.5651A5.193 5.193 0 005.262 5.8582a.7497.7497 0 01-.0897.0035c-1.1132.0006-2.0274-.8797-2.0687-1.9922-.0414-1.1125.805-2.0582 1.9152-2.1402 1.1102-.082 2.0863.7293 2.2087 1.8358z" />
<path fill="#E57E25"
  d="M10.2482 2.7653c1.18-2.2446 3.8131-3.3027 6.2181-2.4987 2.405.804 3.8725 3.2329 3.4655 5.7358-.407 2.503-2.5685 4.3417-5.1043 4.3422-.0828 0-.1655-.0035-.2483-.0069l-4.331-7.5724z" />
<path fill="#FDD7AD"
  d="M16.8965 3.7927c0 1.1427-.9263 2.069-2.069 2.069a.749.749 0 01-.0896-.0035 5.1936 5.1936 0 00-1.9656-2.293c.1261-1.087 1.0767-1.888 2.1692-1.8276 1.0926.0603 1.9493.9609 1.955 2.0551z" />
<path fill="#3D324C"
  d="M1.0344 16.551a.3448.3448 0 01-.3448-.3448v-4.8276a.345.345 0 01.0524-.1828l1.2138-1.9413a.3454.3454 0 01.5862.3655l-1.1628 1.8576v4.7286a.3448.3448 0 01-.3448.3448zM5.1725 16.5514a.3448.3448 0 01-.3449-.3448v-2.4138c0-.0626.0171-.124.0493-.1776l1.269-2.1138a.3449.3449 0 01.5914.3551l-1.22 2.0318v2.3183a.3448.3448 0 01-.3448.3448zM18.9656 16.551a.3448.3448 0 01-.3448-.3448v-4.7286L17.4597 9.62a.3455.3455 0 01.5862-.3655l1.2138 1.9413a.3446.3446 0 01.0507.1828v4.8276a.3448.3448 0 01-.3448.3448zM14.8275 16.5514a.3448.3448 0 01-.3448-.3448v-2.3183l-1.22-2.0318a.3448.3448 0 11.5913-.3551l1.269 2.1138a.345.345 0 01.0493.1776v2.4138a.3448.3448 0 01-.3448.3448zM10 18.276a.3448.3448 0 01-.3448-.3448v-4.8276a.3448.3448 0 01.6897 0v4.8276A.3448.3448 0 0110 18.276z" />
<path fill="#F0C419"
  d="M18.862 8.4063a5.1576 5.1576 0 01-4.0345 1.9379 5.8792 5.8792 0 01-.2483-.0069 5.1722 5.1722 0 00.1621-4.4724.7207.7207 0 01.0862-.0034c1.969 0 3.6138 1.0862 4.0345 2.5448zM5.4206 10.3373a5.8773 5.8773 0 01-.2482.0069 5.1576 5.1576 0 01-4.0345-1.938c.4207-1.4585 2.0655-2.5447 4.0345-2.5447a.7212.7212 0 01.0862.0034 5.1724 5.1724 0 00.162 4.4724z" />
<path fill="#955BA5"
  d="M15.1723 7.931c.0019 2.6532-2.0023 4.8782-4.6411 5.1528-2.6388.2745-5.0581-1.4903-5.6025-4.0869-.5444-2.5966.9624-5.1845 3.4893-5.993 2.5269-.8084 5.256.4244 6.3199 2.8548.2861.6534.434 1.359.4344 2.0724z" />
<path fill="#FDD7AD"
  d="M10.0001 8.6204c1.1426 0 2.0689-.9264 2.0689-2.069 0-1.1427-.9263-2.069-2.0689-2.069-1.1427 0-2.069.9263-2.069 2.069 0 1.1426.9263 2.069 2.069 2.069z" />
<path fill="#FF5364"
  d="M14.0344 11.1649a5.1684 5.1684 0 01-8.069 0C6.3861 9.7063 8.031 8.6201 10 8.6201c1.969 0 3.6138 1.0862 4.0345 2.5448zM10 19.9996c.5713 0 1.0344-.4631 1.0344-1.0345 0-.5713-.4631-1.0344-1.0345-1.0344-.5713 0-1.0344.4631-1.0344 1.0344 0 .5714.4631 1.0345 1.0344 1.0345zM14.8275 18.275c.5713 0 1.0345-.4631 1.0345-1.0345 0-.5713-.4632-1.0344-1.0345-1.0344-.5713 0-1.0345.4631-1.0345 1.0344 0 .5714.4632 1.0345 1.0345 1.0345zM18.9656 18.275c.5713 0 1.0345-.4631 1.0345-1.0345 0-.5713-.4632-1.0344-1.0345-1.0344-.5714 0-1.0345.4631-1.0345 1.0344 0 .5714.4631 1.0345 1.0345 1.0345zM1.0345 18.275c.5713 0 1.0345-.4631 1.0345-1.0345 0-.5713-.4632-1.0344-1.0345-1.0344-.5713 0-1.0345.4631-1.0345 1.0344 0 .5714.4632 1.0345 1.0345 1.0345zM5.1723 18.275c.5713 0 1.0345-.4631 1.0345-1.0345 0-.5713-.4632-1.0344-1.0345-1.0344-.5713 0-1.0345.4631-1.0345 1.0344 0 .5714.4632 1.0345 1.0345 1.0345z" />

  `
};
