import { TEmoji } from '../model/type';

export const smileys: TEmoji[] = [
  ['😀', 'grinning face'],
  ['😃', 'grinning face with big eyes'],
  ['😄', 'grinning face with smiling eyes'],
  ['😁', 'beaming face with smiling eyes'],
  ['😆', 'grinning squinting face'],
  ['😅', 'grinning face with sweat'],
  ['🤣', 'rolling on the floor laughing'],
  ['😂', 'face with tears of joy'],
  ['🙂', 'slightly smiling face'],
  ['🙃', 'upside-down face'],
  ['😉', 'winking face'],
  ['😊', 'smiling face with smiling eyes'],
  ['😇', 'smiling face with halo'],
  ['🥰', 'smiling face with hearts'],
  ['😍', 'smiling face with heart-eyes'],
  ['🤩', 'star-struck'],
  ['😘', 'face blowing a kiss'],
  ['😗', 'kissing face'],
  ['😚', 'kissing face with closed eyes'],
  ['😙', 'kissing face with smiling eyes'],
  ['😋', 'face savoring food'],
  ['😛', 'face with tongue'],
  ['😜', 'winking face with tongue'],
  ['🤪', 'zany face'],
  ['😝', 'squinting face with tongue'],
  ['🤑', 'money-mouth face'],
  ['🤗', 'hugging face'],
  ['🤭', 'face with hand over mouth'],
  ['🤫', 'shushing face'],
  ['🤔', 'thinking face'],
  ['🤐', 'zipper-mouth face'],
  ['🤨', 'face with raised eyebrow'],
  ['😐', 'neutral face'],
  ['😑', 'expressionless face'],
  ['😶', 'face without mouth'],
  ['😏', 'smirking face'],
  ['😒', 'unamused face'],
  ['🙄', 'face with rolling eyes'],
  ['😬', 'grimacing face'],
  ['🤥', 'lying face'],
  ['😌', 'relieved face'],
  ['😔', 'pensive face'],
  ['😪', 'sleepy face'],
  ['🤤', 'drooling face'],
  ['😴', 'sleeping face'],
  ['😷', 'face with medical mask'],
  ['🤒', 'face with thermometer'],
  ['🤕', 'face with head-bandage'],
  ['🤢', 'nauseated face'],
  ['🤮', 'face vomiting'],
  ['🤧', 'sneezing face'],
  ['🥶', 'cold face'],
  ['🥴', 'woozy face'],
  ['😵', 'dizzy face'],
  ['🤯', 'exploding head'],
  ['🤠', 'cowboy hat face'],
  ['🥳', 'partying face'],
  ['😎', 'smiling face with sunglasses'],
  ['🤓', 'nerd face'],
  ['🧐', 'face with monocle'],
  ['😕', 'confused face'],
  ['😟', 'worried face'],
  ['🙁', 'slightly frowning face'],
  ['☹️', 'frowning face'],
  ['😮', 'face with open mouth'],
  ['😯', 'hushed face'],
  ['😲', 'astonished face'],
  ['😳', 'flushed face'],
  ['🥺', 'pleading face'],
  ['😦', 'frowning face with open mouth'],
  ['😧', 'anguished face'],
  ['😨', 'fearful face'],
  ['😰', 'anxious face with sweat'],
  ['😥', 'sad but relieved face'],
  ['😢', 'crying face'],
  ['😭', 'loudly crying face'],
  ['😱', 'face screaming in fear'],
  ['😖', 'confounded face'],
  ['😣', 'persevering face'],
  ['😞', 'disappointed face'],
  ['😓', 'downcast face with sweat'],
  ['😩', 'weary face'],
  ['😫', 'tired face'],
  ['🥱', 'yawning face'],
  ['😤', 'face with steam from nose'],
  ['😠', 'angry face'],
  ['🥵', 'hot face'],
  ['😡', 'pouting face'],
  ['🤬', 'face with symbols on mouth'],
  ['😈', 'smiling face with horns'],
  ['👿', 'angry face with horns'],
  ['💀', 'skull'],
  ['☠️', 'skull and crossbones'],
  ['💩', 'pile of poo'],
  ['🤡', 'clown face'],
  ['👹', 'ogre'],
  ['👺', 'goblin'],
  ['👻', 'ghost'],
  ['👽', 'alien'],
  ['👾', 'alien monster'],
  ['🤖', 'robot'],
  ['😺', 'grinning cat'],
  ['😸', 'grinning cat with smiling eyes'],
  ['😹', 'cat with tears of joy'],
  ['😻', 'smiling cat with heart-eyes'],
  ['😼', 'cat with wry smile'],
  ['😽', 'kissing cat'],
  ['🙀', 'weary cat'],
  ['😿', 'crying cat'],
  ['😾', 'pouting cat'],
  ['🙈', 'see-no-evil monkey'],
  ['🙉', 'hear-no-evil monkey'],
  ['🙊', 'speak-no-evil monkey'],
  ['💋', 'kiss mark'],
  ['💌', 'love letter'],
  ['💘', 'heart with arrow'],
  ['💝', 'heart with ribbon'],
  ['💖', 'sparkling heart'],
  ['💗', 'growing heart'],
  ['💓', 'beating heart'],
  ['💞', 'revolving hearts'],
  ['💕', 'two hearts'],
  ['💟', 'heart decoration'],
  ['❣️', 'heart exclamation'],
  ['💔', 'broken heart'],
  ['❤️', 'heart', ['🖤', '🤍', '🧡', '💛', '💚', '💙', '💜', '🤎']],
  ['💯', 'hundred points'],
  ['💢', 'anger symbol'],
  ['💥', 'collision'],
  ['💫', 'dizzy'],
  ['💦', 'sweat droplets'],
  ['💨', 'dashing away'],
  ['🕳️', 'hole'],
  ['💣', 'bomb'],
  ['💬', 'speech balloon'],
  ['👁️‍🗨️', 'eye in speech bubble'],
  ['🗨️', 'left speech bubble'],
  ['🗯️', 'right anger bubble'],
  ['💭', 'thought balloon'],
  ['💤', 'zzz']
];
