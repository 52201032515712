import { Action } from '@ngrx/store';

import { ICompetitionProject, IProject } from '../../model';

export enum ActionTypes {
  LIST_PROJECTS = '[Projects] List Projects',
  LIST_PROJECTS_FAILURE = '[Projects] List Projects Failure',
  LIST_PROJECTS_SUCCESS = '[Projects] List Projects Success',

  LIST_COMPETITION_PROJECTS = '[Projects] List Competition Projects',
  LIST_COMPETITION_PROJECTS_FAILURE = '[Projects] List Competition Projects Failure',
  LIST_COMPETITION_PROJECTS_SUCCESS = '[Projects] List Competition Projects Success',

  CLEAN_PROJECTS = '[Projects] Clean Projects'
}

export class ListProjectsAction implements Action {
  readonly type = ActionTypes.LIST_PROJECTS;
  constructor(
    public payload: { page: number; count: number; categoryId: number }
  ) {}
}

export class ListProjectsFailureAction implements Action {
  readonly type = ActionTypes.LIST_PROJECTS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ListProjectsSuccessAction implements Action {
  readonly type = ActionTypes.LIST_PROJECTS_SUCCESS;
  constructor(
    public payload: { projects: Array<IProject>; totalAmount: number }
  ) {}
}

export class ListCompetitionProjectsAction implements Action {
  readonly type = ActionTypes.LIST_COMPETITION_PROJECTS;
  constructor(
    public payload: { competitionId: number; page?: number; term?: string }
  ) {}
}

export class ListCompetitionProjectsSuccessAction implements Action {
  readonly type = ActionTypes.LIST_COMPETITION_PROJECTS_SUCCESS;
  constructor(
    public payload: {
      projects: Array<ICompetitionProject>;
      totalAmount: number;
    }
  ) {}
}

export class ListCompetitionProjectsFailureAction implements Action {
  readonly type = ActionTypes.LIST_COMPETITION_PROJECTS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CleanProjectsAction implements Action {
  readonly type = ActionTypes.CLEAN_PROJECTS;
}

export type Actions =
  | ListProjectsAction
  | ListProjectsFailureAction
  | ListProjectsSuccessAction
  | ListCompetitionProjectsAction
  | ListCompetitionProjectsFailureAction
  | ListCompetitionProjectsSuccessAction
  | CleanProjectsAction;
