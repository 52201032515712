import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import {
  getSelectedCompetition,
  getSelectedCompetitionParticipants,
  getSelectedCompetitionRequests,
  getSelectedCompetitionReviews,
  getSelectedCompetitionParticipantsTotalAmount,
  getSelectedCompetitionRequestsTotalAmount,
  getSelectedCompetitionReviewsTotalAmount,
  isUpdatingSelectedCompetition,
  getCurrentCompetitions,
  isLoadingCurrentCompetitions,
  getUpcomingCompetitions,
  isLoadingUpcomingCompetitions,
  getPreviousCompetitions,
  isLoadingPreviousCompetitions,
  getMyCompetitions,
  isLoadingMyCompetitions,
  hasMoreCurrentCompetitionsToLoad,
  hasMoreUpcomingCompetitionsToLoad,
  hasMorePreviousCompetitionsToLoad,
  hasMoreMyCompetitionsToLoad,
  getLastLoadedPagePreviousCompetitions,
  getLastLoadedPageMyCompetitions,
  getLastLoadedPageUpcomingCompetitions,
  getLastLoadedPageCurrentCompetitions,
  getCompetitionsShortList,
  isLoadingCompetitionsShortList,
  getSelectedCompetitionTeams,
  getSelectedCompetitionTeamsTotalAmount,
  getSelectedCompetitionGroupsMembers,
  getSelectedCompetitionGroupsMembersTotalAmount,
  getSelectedCompetitionGroupsMembersIsLoading,
  getSelectedCompetitionMyGroups,
  getSelectedCompetitionTeamsPagesAmount,
  getSelectedCompetitionTeamsIsLoading,
  getSelectedCompetitionJury
} from './selectors';
import { CompetitionActions, CompetitionsActions } from './actions';
import { ProjectsActions } from '../../projects/store/actions';
import { State } from './reducers';
import {
  ICompetition,
  ICompetitionGroup,
  ICompetitionJury,
  ICompetitionMember,
  ICompetitionParticipant,
  ICompetitionRequest,
  ICompetitionResult,
  ICompetitionReview,
  ICompetitionTeam,
  INewCompetitionTeam
} from '../model';
import { AuthService } from '../../../core/services/auth.service';
import { ICompetitionProject } from '../../projects/model';
import {
  getCompetitionProjectsList,
  getCompetitionProjectsPagesAmount
} from '../../projects/store/selectors';

@Injectable()
export class CompetitionsFacade {
  readonly currentCompetitions$: Observable<ICompetition[]>;
  readonly lastLoadedPageCurrentCompetitions$: Observable<number>;
  readonly hasMoreCurrentCompetitionsToLoad$: Observable<boolean>;
  readonly isLoadingCurrentCompetitions$: Observable<boolean>;

  readonly upcomingCompetitions$: Observable<ICompetition[]>;
  readonly lastLoadedPageUpcomingCompetitions$: Observable<number>;
  readonly hasMoreUpcomingCompetitionsToLoad$: Observable<boolean>;
  readonly isLoadingUpcomingCompetitions$: Observable<boolean>;

  readonly myCompetitions$: Observable<ICompetition[]>;
  readonly lastLoadedPageMyCompetitions$: Observable<number>;
  readonly hasMoreMyCompetitionsToLoad$: Observable<boolean>;
  readonly isLoadingMyCompetitions$: Observable<boolean>;

  readonly previousCompetitions$: Observable<ICompetition[]>;
  readonly lastLoadedPagePreviousCompetitions$: Observable<number>;
  readonly hasMorePreviousCompetitionsToLoad$: Observable<boolean>;
  readonly isLoadingPreviousCompetitions$: Observable<boolean>;

  readonly competitionsShortList$: Observable<{
    current: ICompetition;
    upcoming: ICompetition;
    previous: ICompetition;
  }>;
  readonly isLoadingCompetitionsShortList$: Observable<boolean>;

  readonly competition$: Observable<ICompetition>;
  readonly isUpdatingSelectedCompetition$: Observable<boolean>;
  readonly newCompetitionCreated$: Subject<ICompetition>;
  readonly participants$: Observable<ICompetitionParticipant[]>;
  readonly participantsTotalAmount$: Observable<number>;
  readonly requests$: Observable<ICompetitionRequest[]>;
  readonly requestsTotalAmount$: Observable<number>;
  readonly reviews$: Observable<ICompetitionReview[]>;
  readonly reviewsTotalAmount$: Observable<number>;
  readonly submitCompetitionNewsSuccess$ = new Subject<void>();
  readonly submitCompetitionResultSuccess$ = new Subject<void>();
  readonly updateCompetitionResultSuccess$ = new Subject<void>();
  readonly competitionProjects$: Observable<ICompetitionProject[]>;
  readonly competitionProjectsPagesAmount$: Observable<number>;
  readonly competitionTeams$: Observable<ICompetitionTeam[]>;
  readonly competitionTeamsTotalAmount$: Observable<number>;
  readonly competitionTeamsPagesAmount$: Observable<number>;
  readonly competitionTeamsIsLoading$: Observable<boolean>;
  readonly competitionGroupsMembers$: Observable<ICompetitionMember[]>;
  readonly competitionGroupsMembersTotalAmount$: Observable<number>;
  readonly competitionGroupsMembersIsLoading$: Observable<boolean>;
  readonly competitionMyGroups$: Observable<ICompetitionGroup[]>;
  readonly competitionJury$: Observable<ICompetitionJury[]>;

  constructor(
    private readonly store$: Store<State>,
    private readonly actions$: Actions,
    private readonly authService: AuthService
  ) {
    this.currentCompetitions$ = this.store$.pipe(
      select(getCurrentCompetitions)
    );
    this.lastLoadedPageCurrentCompetitions$ = this.store$.pipe(
      select(getLastLoadedPageCurrentCompetitions)
    );
    this.isLoadingCurrentCompetitions$ = this.store$.pipe(
      select(isLoadingCurrentCompetitions)
    );
    this.hasMoreCurrentCompetitionsToLoad$ = this.store$.pipe(
      select(hasMoreCurrentCompetitionsToLoad)
    );

    this.upcomingCompetitions$ = this.store$.pipe(
      select(getUpcomingCompetitions)
    );
    this.lastLoadedPageUpcomingCompetitions$ = this.store$.pipe(
      select(getLastLoadedPageUpcomingCompetitions)
    );
    this.isLoadingUpcomingCompetitions$ = this.store$.pipe(
      select(isLoadingUpcomingCompetitions)
    );
    this.hasMoreUpcomingCompetitionsToLoad$ = this.store$.pipe(
      select(hasMoreUpcomingCompetitionsToLoad)
    );

    this.competitionsShortList$ = this.store$.pipe(
      select(getCompetitionsShortList)
    );
    this.isLoadingCompetitionsShortList$ = this.store$.pipe(
      select(isLoadingCompetitionsShortList)
    );

    this.myCompetitions$ = this.store$.pipe(select(getMyCompetitions));
    this.lastLoadedPageMyCompetitions$ = this.store$.pipe(
      select(getLastLoadedPageMyCompetitions)
    );
    this.isLoadingMyCompetitions$ = this.store$.pipe(
      select(isLoadingMyCompetitions)
    );
    this.hasMoreMyCompetitionsToLoad$ = this.store$.pipe(
      select(hasMoreMyCompetitionsToLoad)
    );

    this.previousCompetitions$ = this.store$.pipe(
      select(getPreviousCompetitions)
    );
    this.lastLoadedPagePreviousCompetitions$ = this.store$.pipe(
      select(getLastLoadedPagePreviousCompetitions)
    );
    this.isLoadingPreviousCompetitions$ = this.store$.pipe(
      select(isLoadingPreviousCompetitions)
    );
    this.hasMorePreviousCompetitionsToLoad$ = this.store$.pipe(
      select(hasMorePreviousCompetitionsToLoad)
    );

    this.isUpdatingSelectedCompetition$ = this.store$.pipe(
      select(isUpdatingSelectedCompetition)
    );
    this.newCompetitionCreated$ = new Subject<ICompetition>();
    this.competition$ = this.store$.pipe(select(getSelectedCompetition));
    this.participants$ = this.store$.pipe(
      select(getSelectedCompetitionParticipants)
    );
    this.participantsTotalAmount$ = this.store$.pipe(
      select(getSelectedCompetitionParticipantsTotalAmount)
    );
    this.requests$ = this.store$.pipe(select(getSelectedCompetitionRequests));
    this.requestsTotalAmount$ = this.store$.pipe(
      select(getSelectedCompetitionRequestsTotalAmount)
    );
    this.reviews$ = this.store$.pipe(select(getSelectedCompetitionReviews));
    this.reviewsTotalAmount$ = this.store$.pipe(
      select(getSelectedCompetitionReviewsTotalAmount)
    );
    this.competitionProjects$ = this.store$.pipe(
      select(getCompetitionProjectsList)
    );
    this.competitionProjectsPagesAmount$ = this.store$.pipe(
      select(getCompetitionProjectsPagesAmount)
    );
    this.competitionTeams$ = this.store$.pipe(
      select(getSelectedCompetitionTeams)
    );
    this.competitionTeamsTotalAmount$ = this.store$.pipe(
      select(getSelectedCompetitionTeamsTotalAmount)
    );
    this.competitionTeamsPagesAmount$ = this.store$.pipe(
      select(getSelectedCompetitionTeamsPagesAmount)
    );
    this.competitionTeamsIsLoading$ = this.store$.pipe(
      select(getSelectedCompetitionTeamsIsLoading)
    );
    this.competitionGroupsMembers$ = this.store$.pipe(
      select(getSelectedCompetitionGroupsMembers)
    );
    this.competitionGroupsMembersTotalAmount$ = this.store$.pipe(
      select(getSelectedCompetitionGroupsMembersTotalAmount)
    );
    this.competitionGroupsMembersIsLoading$ = this.store$.pipe(
      select(getSelectedCompetitionGroupsMembersIsLoading)
    );
    this.competitionMyGroups$ = this.store$.pipe(
      select(getSelectedCompetitionMyGroups)
    );
    this.competitionJury$ = this.store$.pipe(
      select(getSelectedCompetitionJury)
    );

    this.actions$
      .pipe(
        ofType(CompetitionActions.ActionTypes.SUBMIT_COMPETITION_NEW_SUCCESS),
        tap((_) => this.submitCompetitionNewsSuccess$.next())
      )
      .subscribe((_) => _);

    this.actions$
      .pipe(
        ofType(
          CompetitionActions.ActionTypes.SUBMIT_COMPETITION_RESULT_SUCCESS
        ),
        tap((_) => this.submitCompetitionResultSuccess$.next())
      )
      .subscribe((_) => _);

    this.actions$
      .pipe(
        ofType(
          CompetitionActions.ActionTypes.UPDATE_COMPETITION_RESULT_SUCCESS
        ),
        tap((_) => this.updateCompetitionResultSuccess$.next())
      )
      .subscribe((_) => _);

    this.actions$
      .pipe(
        ofType<CompetitionActions.CreateCompetitionSuccessAction>(
          CompetitionActions.ActionTypes.CREATE_COMPETITION_SUCCESS
        ),
        tap((_) => this.newCompetitionCreated$.next(_.payload.competition))
      )
      .subscribe((_) => _);
  }

  get canCreateCompetition$() {
    return this.authService.user$.pipe(map((_) => _?.type === 'org'));
  }

  loadCompetitions() {
    this.loadCurrentCompetitions({ page: 1 });
    this.loadUpcomingCompetitions({ page: 1 });
    this.loadMyCompetitions({ page: 1 });
    this.loadPreviousCompetitions({ page: 1 });
  }

  loadCurrentCompetitions({ page }: { page: number }) {
    this.store$.dispatch(
      new CompetitionsActions.LoadCurrentCompetitionsAction({ page })
    );
  }

  loadUpcomingCompetitions({ page }: { page: number }) {
    this.store$.dispatch(
      new CompetitionsActions.LoadUpcomingCompetitionsAction({ page })
    );
  }

  loadMyCompetitions({ page }: { page: number }) {
    this.authService.user$
      .pipe(
        take(1),
        filter((_) => !!_),
        tap((_) =>
          this.store$.dispatch(
            new CompetitionsActions.LoadMyCompetitionsAction({ page })
          )
        )
      )
      .subscribe();
  }

  loadPreviousCompetitions({ page }: { page: number }) {
    this.store$.dispatch(
      new CompetitionsActions.LoadPreviousCompetitionsAction({ page })
    );
  }

  loadCompetition({ id }: { id: number }) {
    this.store$.dispatch(new CompetitionActions.LoadCompetitionAction({ id }));
  }

  loadCompetitionsShortList() {
    this.store$.dispatch(
      new CompetitionsActions.LoadCompetitionsShortListAction()
    );
  }

  createCompetition({ competition }: { competition: object }) {
    this.store$.dispatch(
      new CompetitionActions.CreateCompetitionAction({ competition })
    );
  }

  updateCompetition({ id, competition }: { id: number; competition: object }) {
    this.store$.dispatch(
      new CompetitionActions.UpdateCompetitionAction({ id, competition })
    );
  }

  joinCompetition({ id, groupID }: { id: number; groupID: number }) {
    this.store$.dispatch(
      new CompetitionActions.JoinCompetitionAction({ id, groupID })
    );
  }

  leaveCompetition({ id, groupID }: { id: number; groupID: number }) {
    this.store$.dispatch(
      new CompetitionActions.LeaveCompetitionAction({ id, groupID })
    );
  }

  loadCompetitionRequests({ id, page }: { id: number; page: number }) {
    this.store$.dispatch(
      new CompetitionActions.LoadCompetitionRequestsAction({ id, page })
    );
  }

  loadCompetitionReviews({ id, page }: { id: number; page: number }) {
    this.store$.dispatch(
      new CompetitionActions.LoadCompetitionReviewsAction({ id, page })
    );
  }

  loadCompetitionJurySubmissions({
    token,
    juryId,
    competitionId,
    page
  }: {
    token: string;
    juryId: number;
    competitionId: number;
    page: number;
  }) {
    this.store$.dispatch(
      new CompetitionActions.LoadCompetitionJurySubmissionsAction({
        token,
        juryId,
        competitionId,
        page
      })
    );
  }

  loadCompetitionParticipants({ id, page }: { id: number; page: number }) {
    this.store$.dispatch(
      new CompetitionActions.LoadCompetitionParticipantsAction({ id, page })
    );
  }

  acceptCompetitionRequest({ id, groupID }: { id: number; groupID: number }) {
    this.store$.dispatch(
      new CompetitionActions.AcceptCompetitionRequestAction({ id, groupID })
    );
  }

  declineCompetitionRequest({ id, groupID }: { id: number; groupID: number }) {
    this.store$.dispatch(
      new CompetitionActions.DeclineCompetitionRequestAction({ id, groupID })
    );
  }

  removeCompetitionParticipant({
    id,
    groupID
  }: {
    id: number;
    groupID: number;
  }) {
    this.store$.dispatch(
      new CompetitionActions.RemoveCompetitionParticipantAction({ id, groupID })
    );
  }

  submitCompetitionNews({ id, message }) {
    this.store$.dispatch(
      new CompetitionActions.SubmitCompetitionNewsAction({ id, message })
    );
  }

  submitCompetitionResult({ result }: { result: ICompetitionResult }) {
    this.store$.dispatch(
      new CompetitionActions.SubmitCompetitionResultAction({ result })
    );
  }

  loadCompetitionProjects({
    competitionId,
    page,
    term
  }: {
    competitionId: number;
    page?: number;
    term?: string;
  }) {
    this.store$.dispatch(
      new ProjectsActions.ListCompetitionProjectsAction({
        competitionId,
        page,
        term
      })
    );
  }

  addCompetitionTeam({ team }: { team: INewCompetitionTeam }) {
    this.store$.dispatch(
      new CompetitionActions.AddCompetitionTeamAction({ team })
    );
  }

  confirmCompetitionTeam({ team }: { team: ICompetitionTeam }) {
    this.store$.dispatch(
      new CompetitionActions.ConfirmCompetitionTeamAction({ team })
    );
  }

  rejectCompetitionTeam({ team }: { team: ICompetitionTeam }) {
    this.store$.dispatch(
      new CompetitionActions.RejectCompetitionTeamAction({ team })
    );
  }

  removeCompetitionTeam({ team }: { team: ICompetitionTeam }) {
    this.store$.dispatch(
      new CompetitionActions.RemoveCompetitionTeamAction({ team })
    );
  }

  updateSubmission({ submission }: { submission: ICompetitionReview }) {
    this.store$.dispatch(
      new CompetitionActions.UpdateCompetitionResultAction({ submission })
    );
  }

  rateSubmission({
    submission,
    jury
  }: {
    submission: ICompetitionReview;
    jury: ICompetitionJury;
  }) {
    this.store$.dispatch(
      new CompetitionActions.RateCompetitionResultAction({ submission, jury })
    );
  }

  loadCompetitionTeams({
    competitionId,
    page,
    term
  }: {
    competitionId: number;
    page?: number;
    term?: string;
  }) {
    this.store$.dispatch(
      new CompetitionActions.LoadCompetitionTeamsAction({
        competitionId,
        page,
        term
      })
    );
  }

  findMembers({ competitionId, groupId, page, term }) {
    this.store$.dispatch(
      new CompetitionActions.FindMembersAction({
        competitionId,
        groupId,
        page,
        term
      })
    );
  }

  loadMyGroups() {
    this.store$.dispatch(new CompetitionActions.LoadMyGroupsAction());
  }

  addJury(jury: ICompetitionJury) {
    this.store$.dispatch(new CompetitionActions.AddJuryAction({ jury }));
  }

  editJury(jury: ICompetitionJury) {
    this.store$.dispatch(new CompetitionActions.EditJuryAction({ jury }));
  }

  loadJury({ competitionId }: { competitionId: number }) {
    this.store$.dispatch(
      new CompetitionActions.LoadJuryAction({ competitionId })
    );
  }

  deleteJury({ jury }: { jury: ICompetitionJury }) {
    this.store$.dispatch(new CompetitionActions.DeleteJuryAction({ jury }));
  }
}
