import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';

import { NotEmptyURLSearchParams } from 'src/app/shared/types/not-empty-url-search-params.type';
import { APIService } from 'src/app/shared/services/api.service';

interface ListParams {
  category?: string;
  page: number;
  term: string;
  isWeekly?: boolean;
  weeklyStartFrom?: number;
}

@Injectable({
  providedIn: 'root'
})
export class EduniverseService {
  constructor(
    private base: BaseService,
    private readonly _apiService: APIService
  ) {}

  getAll() {
    return this.base.get('eduniverse');
  }

  getList(
    key,
    { category, page, term, isWeekly, weeklyStartFrom }: ListParams
  ) {
    const params = new NotEmptyURLSearchParams({
      category_id: category,
      page: page + '',
      text: term || '',
      start_from: isWeekly ? weeklyStartFrom + '' : null
    });

    if (isWeekly) {
      return this.base.get(`weekly/${key}?${params}`);
    }

    return this._apiService.get(`eduniverse/${key}?${params}`);
  }
}
