import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { tap, filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  private dialogConfig: MatDialogConfig = {
    width: '30vw',
    maxWidth: '1140px',
    minHeight: '245px',
    minWidth: '300px',
    panelClass: 'custom-dialog'
  };

  public dialogMessage = 'Are you sure?';

  @ViewChild('confirmDialog', { static: true }) dialogBody: TemplateRef<any>;

  constructor(private dialog: MatDialog) {}

  openDialog(dialogMessage?: string) {
    if (dialogMessage) {
      this.dialogMessage = dialogMessage;
    }

    return this.dialog.open(this.dialogBody, this.dialogConfig);
  }

  confirm(dialogMessage?: string) {
    return this.openDialog(dialogMessage)
      .afterClosed()
      .pipe(
        tap(console.log),
        filter((val) => val),
        first()
      );
  }
}
