import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  Inject
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil } from 'rxjs/operators';

import _ from 'lodash';

import { Group } from 'src/app/core/services/groups/group.model';
import { AutoCompleteService } from 'src/app/core/services/autocomplete.service';
import { getValue } from '../../../registration/mappers';
import { IInputCtx } from '../../form';

@Component({
  selector: 'app-create-attached-school-club-chapter-dialog',
  templateUrl: './create-attached-school-club-chapter-dialog.component.html',
  styleUrls: ['./create-attached-school-club-chapter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAttachedSchoolClubChapterDialogComponent
  implements OnInit, OnDestroy
{
  schools$ = new BehaviorSubject<
    Array<{ address: string; location: string; value: string }>
  >([]);
  schoolKeyUp = new Subject<KeyboardEvent>();

  private unsub$: Subject<any> = new Subject<any>();

  // TODO: refactor
  // use service
  categories = [
    { id: 1, title: 'STEM' },
    { id: 2, title: 'Business' },
    { id: 3, title: 'Community Service' },
    { id: 4, title: 'Humanities' },
    { id: 5, title: 'Social Sciences' },
    { id: 6, title: 'Other' }
  ];

  accesses = [
    { id: 1, title: 'Open for all' },
    { id: 2, title: 'Open to RoundPier members' },
    { id: 3, title: 'Private group' }
  ];

  ages = [
    { id: 1, title: '3-6 years' },
    { id: 2, title: '7-9 years' },
    { id: 3, title: '10-13 years' },
    { id: 4, title: '14-17 years' },
    { id: 5, title: 'any' }
  ];

  loadedImage: string | ArrayBuffer = null;
  newKeyword = '';

  readonly fields = {
    image: new FormControl(),
    icon_id: new FormControl(),
    country: new FormControl(),
    city: new FormControl(),
    name: new FormControl(null, Validators.required),
    school: new FormControl(null),
    address: new FormControl(null),
    age_group_id: new FormControl(5, Validators.required),
    description: new FormControl(null, Validators.required),
    category_id: new FormControl(null, Validators.required),
    link: new FormControl(null, Validators.required),
    access: new FormControl(1, Validators.required),
    zip: new FormControl(),
    keywords: new FormControl([])
  };

  newGroup = new FormGroup(this.fields);

  readonly country = this.auto.getCountry(this.fields.country);
  readonly city = this.auto.getCity(this.fields.city);
  readonly zip: IInputCtx = {
    control: this.fields.zip,
    placeholder: `Zip / Post Code`
  };

  constructor(
    private auto: AutoCompleteService,
    private dialogRef: MatDialogRef<Group>,
    private cd: ChangeDetectorRef,
    private autocompeleteService: AutoCompleteService,
    @Inject(MAT_DIALOG_DATA)
    public data: { groupInfo?: Group; isOrgType: boolean; orgName: string }
  ) {
    this.probablyPatchForm(this.data?.groupInfo);
  }

  get keywords() {
    return this.newGroup.value.keywords;
  }

  get currentImage() {
    if (this.newGroup.value.image instanceof File) {
      return null;
    }

    return this.newGroup.value.image;
  }

  onChangeKeyword(newValue) {
    this.newKeyword = newValue;
  }

  onSubmit() {
    let payload = this.newGroup.value;

    payload = {
      ...payload,
      address: payload.address || '',
      country: getValue(payload.country),
      city: getValue(payload.city)
    };

    this.dialogRef.close({
      type: 'submit',
      id: this.data?.groupInfo?.id || 0,
      payload
    });
  }

  onAddKeyword() {
    if (!this.newKeyword) {
      return;
    }

    this.newGroup.patchValue({
      keywords: _.uniqBy([...this.newGroup.value.keywords, this.newKeyword])
    });

    this.newKeyword = '';
  }

  onRemoveKeyword(keyword) {
    this.newGroup.patchValue({
      keywords: this.newGroup.value.keywords.filter((k) => k !== keyword)
    });
  }

  onImageChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      const that = this;
      reader.onload = function () {
        that.newGroup.patchValue({
          image: file
        });

        that.loadedImage = this.result;

        that.cd.markForCheck();
      };
    }
  }

  onSelectSchoolFromList(school) {
    this.autocompeleteService
      .getAddressForLocation(school)
      .pipe(takeUntil(this.unsub$))
      .subscribe((res) => {
        this.newGroup.patchValue({ address: res });
        this.cd.detectChanges();
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.schoolKeyUp
      .pipe(
        takeUntil(this.unsub$),
        debounceTime(300),
        map((event) => (event.target as HTMLInputElement).value),
        mergeMap((data) => this.autocompeleteService.get(data))
      )
      .subscribe((res) => {
        this.schools$.next(res);
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsub$.next('');
    this.unsub$.unsubscribe();
  }

  private probablyPatchForm(groupInfo?: Group) {
    if (!groupInfo) {
      return;
    }

    console.log('probablyPatchForm: ', groupInfo);

    this.newGroup.patchValue({
      ...groupInfo,
      age_group_id: groupInfo.agegroup.id,
      country: groupInfo.location?.country.name,
      city: groupInfo.location?.city?.name,
      zip: groupInfo.location?.zip,
      keywords: groupInfo.keywords?.map((keyword) => keyword.name),
      school: groupInfo.body?.name,
      address: groupInfo.body?.address.name,
      image: groupInfo.icon,
      icon_id: groupInfo.icon_id,
      category_id: groupInfo.category?.id
    });
  }
}
