import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-discussion-badge',
  templateUrl: './discussion-badge.component.html',
  styleUrls: ['./discussion-badge.component.scss']
})
export class DiscussionBadgeComponent {
  @Input() discussion: any;
}
