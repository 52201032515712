import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post-link-preview',
  templateUrl: './post-link-preview.component.html',
  styleUrls: ['./post-link-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostLinkPreviewComponent implements OnInit, OnDestroy {
  @Input() url: string;

  @Output() render = new EventEmitter<void>();
  @Output() renderSuccess = new EventEmitter<void>();
  @Output() renderFailure = new EventEmitter<void>();
  @Output() destroy = new EventEmitter<void>();

  urlData$ = new BehaviorSubject(null);

  private httpClient: HttpClient;

  private componentDestroy$ = new Subject();

  constructor(private httpBackend: HttpBackend) {
    this.httpClient = new HttpClient(this.httpBackend);
  }

  ngOnInit(): void {
    // wait prop binding
    setTimeout(() => this.render.emit());

    this.fetchPreviewData(this.url)
      .pipe(
        takeUntil(this.componentDestroy$),
        take(1),
        filter((_) => !!_),
        tap((_) => {
          _.status ? this.renderSuccess.emit() : this.renderFailure.emit();
        }),
        filter((_) => !!_.status),
        tap((_) =>
          this.urlData$.next({
            url: _.url,
            ..._.data
          })
        )
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
    this.destroy.emit();
  }

  private fetchPreviewData(url: string) {
    return this.httpClient.get<{ status: boolean; data: any; url: string }>(
      `${environment.endpoint}/link-parser`,
      {
        params: new HttpParams().append('url', url)
      }
    );
  }
}
