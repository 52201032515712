<div class="logo">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 35">
    <path
      [attr.fill]="color"
      fill-rule="evenodd"
      d="M36.2 24.2h-6.5V35H15C7.4 35 0 32.8 0 22.6c0-8 5.5-11.8 12.8-11.8h6.5V0h14.8C41.6 0 49 2.2 49 12.4c0 8.1-5.5 11.8-12.8 11.8zM19.3 19h-5c-2.5 0-4.3.8-4.3 3.7 0 2.5 1.4 3.7 4.5 3.7h4.8V19zM34.5 8.6h-4.8V16h5c2.5 0 4.3-.8 4.3-3.7 0-2.5-1.4-3.7-4.5-3.7z"
      clip-rule="evenodd"
    />
  </svg>
</div>
