import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import * as ContactsActions from 'src/app/core/services/contacts/contacts.actions';
import * as OrganizationsActions from 'src/app/core/services/organizations/organizations.actions';
import * as TopActions from './top.actions';
import {
  Event,
  People,
  Group,
  Organization,
  Discussion,
  Clubs
} from './top.model';

export const topFeatureKey = 'top';

export interface State {
  people: { [id: string]: People };
  events: { [id: string]: Event };
  groups: { [id: string]: Group };
  organizations: { [id: string]: Organization };
  discussions: { [id: string]: Discussion };
  clubs: { [id: string]: Clubs };
}

export const initialState: State = {
  people: null,
  events: null,
  groups: null,
  organizations: null,
  discussions: null,
  clubs: null
};

export const reducer = createReducer(
  initialState,
  on(ContactsActions.addContactSuccess, (state, action): State => {
    if (!state.people || !state.people[action.id]) {
      return state;
    }

    return {
      ...state,
      people: {
        ...state.people,
        [action.id]: {
          ...state.people[action.id],
          friend_request: true
        }
      }
    };
  }),
  on(ContactsActions.removeContactSuccess, (state, action): State => {
    if (!state.people || !state.people[action.id]) {
      return state;
    }

    return {
      ...state,
      people: {
        ...state.people,
        [action.id]: {
          ...state.people[action.id],
          friend_request: false,
          is_friend: false
        }
      }
    };
  }),
  on(OrganizationsActions.orgSubscribeToSuccess, (state, action): State => {
    if (!state.organizations || !state.organizations[action.id]) {
      return state;
    }

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [action.id]: {
          ...state.organizations[action.id],
          friend_request: true
        }
      }
    };
  }),
  on(OrganizationsActions.orgUnSubscribeFromSuccess, (state, action): State => {
    if (!state.organizations || !state.organizations[action.id]) {
      return state;
    }

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [action.id]: {
          ...state.organizations[action.id],
          friend_request: false,
          is_friend: false
        }
      }
    };
  }),
  on(TopActions.searchTop, (state, action): State => {
    return initialState;
  }),
  on(TopActions.searchTopSuccess, (state, action): State => {
    return {
      people: action.people,
      events: action.events,
      groups: action.groups,
      organizations: action.organizations,
      discussions: action.discussions,
      clubs: action.clubs
    };
  })
);

export const selectState = createFeatureSelector<State>(topFeatureKey);

export const selectTopPeople = () =>
  createSelector(selectState, (state: State) => {
    if (!state.people) {
      return null;
    }

    return Object.values(state.people);
  });

export const selectTopEvents = () =>
  createSelector(selectState, (state: State) => {
    if (!state.events) {
      return null;
    }

    return Object.values(state.events);
  });

export const selectTopGroups = () =>
  createSelector(selectState, (state: State) => {
    if (!state.groups) {
      return null;
    }

    return Object.values(state.groups);
  });

export const selectTopOrganizations = () =>
  createSelector(selectState, (state: State) => {
    if (!state.organizations) {
      return null;
    }

    return Object.values(state.organizations);
  });

export const selectTopDiscussions = () =>
  createSelector(selectState, (state: State) => {
    if (!state.discussions) {
      return null;
    }

    return Object.values(state.discussions);
  });

export const selectTopClubs = () =>
  createSelector(selectState, (state: State) => {
    if (!state.clubs) {
      return null;
    }

    return Object.values(state.clubs);
  });

export const selectTop = () =>
  createSelector(selectState, (state: State) => {
    if (
      !state.discussions ||
      !state.events ||
      !state.groups ||
      !state.organizations ||
      !state.people ||
      !state.clubs
    ) {
      return null;
    }

    return {
      discussions: Object.values(state.discussions),
      events: Object.values(state.events),
      groups: Object.values(state.groups),
      organizations: Object.values(state.organizations),
      people: Object.values(state.people),
      clubs: Object.values(state.clubs)
    };
  });
