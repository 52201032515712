import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ChatsListActions, MessageActions } from '../actions';
import { IMessage } from '../../model';

export const featureAdapter: EntityAdapter<IMessage> =
  createEntityAdapter<IMessage>({
    selectId: (model) => model.id,
    sortComparer: (a: IMessage, b: IMessage): number => a.id - b.id
  });

export interface State extends EntityState<IMessage> {
  page: number;
  nextPageToLoad: number;
  isLoadingMessages: boolean;
  isLoadedMessages: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  page: null,
  nextPageToLoad: 0,
  isLoadingMessages: false,
  isLoadedMessages: false
});

export function Reducer(
  state = initialState,
  action: ChatsListActions.Actions | MessageActions.Actions
): State {
  switch (action.type) {
    case MessageActions.ActionTypes.LOAG_MESSAGES:
      return {
        ...state,
        isLoadingMessages: true
      };
    case MessageActions.ActionTypes.NEW_MESSAGE_FOR_CHANNEL_SUCCESS:
      return featureAdapter.addOne(action.payload.message, {
        ...state
      });
    case MessageActions.ActionTypes.LOAD_MESSAGES_SUCCESS:
      return featureAdapter.addMany(action.payload.messages, {
        ...state,
        page: action.payload.page,
        nextPageToLoad: action.payload.nextPageToLoad,
        isLoadingMessages: false,
        isLoadedMessages: true
      });
    case MessageActions.ActionTypes.CLEAN_MESSAGES:
      return {
        ...initialState,
        isLoadingMessages: false,
        isLoadedMessages: false
      };
    default:
      return state;
  }
}
