import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, competitionsFeatureKey } from '../reducers';
import * as PreviousCompetitions from '../reducers/previous-competitions.reducer';

const competitionsModuleState = createFeatureSelector<State>(
  competitionsFeatureKey
);

const selectPreviousCompetitionsState = createSelector(
  competitionsModuleState,
  (state: State) => state.previousCompetitions
);

export const getPreviousCompetitions = createSelector(
  selectPreviousCompetitionsState,
  PreviousCompetitions.selectCompetitions
);

export const isLoadingPreviousCompetitions = createSelector(
  selectPreviousCompetitionsState,
  PreviousCompetitions.isLoading
);

export const hasMorePreviousCompetitionsToLoad = createSelector(
  selectPreviousCompetitionsState,
  PreviousCompetitions.hasMoreCompetitionsToLoad
);

export const getLastLoadedPagePreviousCompetitions = createSelector(
  selectPreviousCompetitionsState,
  PreviousCompetitions.lastLoadedPage
);
