import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

// TODO: remove in a favor to ImageCropperModule

// ==================================================
//                   Model
// ==================================================
export interface IImageCropperCtx {
  format: string;
  aspectRatio?: number;
  rounded?: boolean;
  on?: {
    cropped(avatar: IAvatar): void;
  };
}

export interface IAvatar {
  file: File;
  file_x?: string;
  file_y?: string;
  file_width?: string;
  file_height?: string;
  base64?: string;
  cropedFile?: File;
}

/*
https://www.npmjs.com/package/ngx-image-cropper
*/

// ==================================================
//                   Component
// ==================================================
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements OnInit, AfterViewInit {
  @Input() readonly autoClick: boolean;
  @Input() readonly ctx: IImageCropperCtx;
  @Output() fileOutput: EventEmitter<any> = new EventEmitter();
  @ViewChild('File') File: ElementRef;

  public imageChangedEvent: Event;

  constructor() {}

  public ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.autoClick) {
      this.File.nativeElement.click();
    }
  }

  public fileChangeEvent(event: Event): void {
    this.imageChangedEvent = event;
  }

  public imageCropped(event: ImageCroppedEvent) {
    const image: File = this.imageChangedEvent.target['files'][0];

    var filesize = image.size / 1024 / 1024;
    if (filesize > 8) {
      alert('Please select image size less than 8 MB');
      this.imageChangedEvent = null;
      return false;
    }
    const avatar = {
      file: image,
      file_x: event.imagePosition.x1 + ``,
      file_width: event.imagePosition.x2 + ``,
      file_y: event.imagePosition.y1 + ``,
      file_height: event.imagePosition.y2 + ``,
      base64: event.base64,
      cropedFile: this.dataURLtoFile(event.base64, image.name)
    };
    this.ctx.on.cropped(avatar);
    this.fileOutput.emit(avatar);
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  public imageLoaded() {
    // show cropper
  }

  public cropperReady() {
    // cropper ready
  }

  public loadImageFailed() {
    // show message
  }
}
