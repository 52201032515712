export const disconnect = {
  id: `DISCONNECT`,
  title: `Disconnect`,
  svg: `
    <g clip-path="url(#clip29)">
    <path fill="#894B9D"
      d="M7.33 12.23h1.1A2.57 2.57 0 0111 14.79v2.94c0 .2-.16.36-.37.36H.37a.37.37 0 01-.37-.36v-2.94a2.57 2.57 0 012.57-2.56h4.76z" />
    <path fill="#3D324C"
      d="M2.2 15.52v2.57h-.73v-2.57a.37.37 0 11.73 0zM9.53 15.52v2.57H8.8v-2.57a.37.37 0 11.73 0z" />
    <path fill="#955BA5" d="M6.74 9.8c-.22.62-.4 1.68.6 2.42a1.83 1.83 0 11-3.67 0c.98-.74.81-1.8.59-2.43h2.48z" />
    <path fill="#B49ACA"
      d="M10.27 6.36a1.1 1.1 0 01-1.3 1.08 3.77 3.77 0 01-2.23 2.35c-.8.31-1.68.31-2.48 0a3.77 3.77 0 01-2.22-2.35 1.1 1.1 0 11-.2-2.18V3.74C.44 2.54.73-.24.73-.24c1.1 2.2 5.86.37 7.7 1.47 1.83 1.1.73 4.03.73 4.03.6 0 1.1.5 1.1 1.1z" />
    <path fill="#2980BA"
      d="M16.4 14.64h1.07c1.4 0 2.53 1.13 2.53 2.53v2.88c0 .2-.16.36-.36.36H9.54a.36.36 0 01-.37-.36v-2.88c0-1.4 1.13-2.53 2.53-2.53h4.7z" />
    <path fill="#285680"
      d="M11.34 17.89v2.52h-.72V17.9a.36.36 0 11.72 0zM18.56 17.89v2.52h-.73V17.9a.36.36 0 11.73 0z" />
    <path fill="#3B97D3" d="M15.81 12.25c-.22.61-.39 1.66.58 2.39a1.8 1.8 0 01-3.6 0c.96-.73.8-1.78.57-2.4h2.45z" />
    <path fill="#84B5CB"
      d="M19.28 8.86a1.08 1.08 0 01-1.29 1.07 3.71 3.71 0 01-2.18 2.32c-.79.3-1.66.3-2.45 0a3.71 3.71 0 01-2.18-2.32 1.08 1.08 0 11-.2-2.14v-1.5C9.62 5.1 9.89 2.36 9.89 2.36c1.09 2.16 5.78.36 7.58 1.44 1.8 1.08.73 3.97.73 3.97.6 0 1.08.49 1.08 1.08z" />
    <path fill="#E64C3C" d="M16.55 6.9a3.45 3.45 0 100-6.9 3.45 3.45 0 000 6.9z" />
    <path fill="#fff" d="M18.5 3a.5.5 0 010 1h-4a.5.5 0 010-1h4z" />
  </g>
  <defs>
    <clipPath id="clip29">
      <path fill="#fff" d="M0 0h20v20H0z" />
    </clipPath>
  </defs>
`
};
