import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import Pipes from './pipes';

@NgModule({
  declarations: [...Pipes],
  imports: [CommonModule],
  exports: [...Pipes]
})
export class AppRgbaModule {}
