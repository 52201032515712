import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { environment } from 'src/environments/environment';
import { RegistrationSharedModule } from 'src/app/modules/registration/shared/registration.shared.module';

import { AmplitudeModule, NoopAmplitudeModule } from '../amplitude';
import { AppLoaderModule } from '../loader/loader.module';
import { AppSignUpDialogComponent } from './components';
import { AppBaseURLModule } from '../baseurl/baseurl.module';

@NgModule({
  declarations: [AppSignUpDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    RegistrationSharedModule,
    environment.production
      ? AmplitudeModule.forRoot(environment.amplitude)
      : NoopAmplitudeModule,
    AppBaseURLModule,
    AppLoaderModule
  ],
  exports: [AppSignUpDialogComponent]
})
export class AppSignupDialogModule {}
