import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

// TODO: remove redundant stuff

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent implements OnInit {
  @Input() readonly ctx: IInputCtx;

  constructor() {}

  ngOnInit(): void {}
}

export interface IInputCtx {
  readonly placeholder: string;
  readonly control: AbstractControl;
  readonly hint?: string;
  readonly required?: boolean;
  readonly readonly?: boolean;
}
