import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  UrlSegment,
  UrlSegmentGroup,
  Route
} from '@angular/router';

import { FrontComponent } from './components';

const routes: Routes = [
  {
    // TODO: use nginx with redirect from backend to fragment
    path: 'password',
    loadChildren: () =>
      import('./modules/password/password.module').then((m) => m.PasswordModule)
  },
  {
    // TODO: use nginx with redirect from backend to fragment
    path: 'confirm',
    loadChildren: () =>
      import('./modules/confirm/confirm.module').then((m) => m.ConfirmModule)
  },
  {
    path: '',
    component: FrontComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
        pathMatch: 'full'
      },
      {
        path: 'about-us',
        loadChildren: () =>
          import('./modules/about-us/about-us.module').then(
            (m) => m.AboutUsModule
          )
      },
      {
        path: 'careers',
        loadChildren: () =>
          import('./modules/careers/careers.module').then(
            (m) => m.CareersModule
          )
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./modules/faq/faq.module').then((m) => m.FaqModule)
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./modules/privacy-policy/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule
          )
      },
      {
        path: 'terms-conditions',
        loadChildren: () =>
          import('./modules/terms-conditions/terms-conditions.module').then(
            (m) => m.TermsConditionsModule
          )
      },
      {
        path: 'rules-of-conduct',
        loadChildren: () =>
          import('./modules/rules-of-conduct/rules-of-conduct.module').then(
            (m) => m.RulesOfConductModule
          )
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontRoutingModule {}

export function matcher(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  _route: Route
) {
  const url = group.toString();

  for (const route of routes) {
    if (isRouteMatch(route, url)) {
      return { consumed: [] };
    }

    if (route.children?.find((_) => isRouteMatch(_, url))) {
      return { consumed: [] };
    }
  }

  return null;
}

function isRouteMatch(route: Route, url: string) {
  if (route.pathMatch === 'full') {
    return route.path === url;
  }

  if (!route.path) {
    return false;
  }

  return url.startsWith(route.path);
}
