<ng-template #logo>
  <div class="icon" *ngIf="isShowLogo">
    <svg viewBox="0 0 18 18">
      <use href="#new-location" />
    </svg>
  </div>
</ng-template>

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="TYPES.CITY_COUNTRY">
    <ng-template [ngTemplateOutlet]="logo"></ng-template> <span>{{ prefix }} {{ city }}, {{ country }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="TYPES.CITY">
    <ng-template [ngTemplateOutlet]="logo"></ng-template> <span>{{ prefix }} {{ city }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="TYPES.COUNTRY">
    <ng-template [ngTemplateOutlet]="logo"></ng-template> <span>{{ prefix }} {{ country }}</span>
  </ng-container>
  <ng-container *ngSwitchDefault>{{ default }}</ng-container>
</ng-container>
