import {
  ChannelActions,
  ChatsListActions,
  SelectedChannelActions
} from '../actions';
import { IChannelDetails, IChannelMember } from '../../model';

export interface State {
  channel: IChannelDetails;
  isLoading: boolean;
  members: IChannelMember[];
}

export const initialState: State = {
  channel: null,
  isLoading: false,
  members: null
};

export function Reducer(
  state = initialState,
  action:
    | ChatsListActions.Actions
    | ChannelActions.Actions
    | SelectedChannelActions.Actions
): State {
  switch (action.type) {
    case ChannelActions.ActionTypes.NEW_CHANNEL_SUCCESS: {
      if (!state.channel) {
        return state;
      }

      if (state.channel.id !== action.payload.channel.id) {
        return state;
      }

      return {
        ...state,
        channel: {
          ...state.channel,
          isDeleted: false
        }
      };
    }
    case SelectedChannelActions.ActionTypes.LOAD_SELECTED_CHANNEL:
      return {
        ...state,
        channel: null,
        isLoading: true
      };
    case SelectedChannelActions.ActionTypes.LOAD_SELECTED_CHANNEL_SUCCESS:
      return {
        ...state,
        channel: action.payload.channel,
        isLoading: false
      };
    case SelectedChannelActions.ActionTypes.LOAD_CHANNEL_MEMBERS:
      return {
        ...state
      };
    case SelectedChannelActions.ActionTypes.LOAD_CHANNEL_MEMBERS_SUCCESS:
      return {
        ...state,
        channel: {
          ...state.channel,
          amountOfMembers: action.payload.members.length
        },
        members: action.payload.members
      };
    case SelectedChannelActions.ActionTypes.EDIT_CHANNEL_MEMBERS:
      const amountOfMembersToAdd = action.payload.membersToAdd.length;
      const amountOfMembersToRemove = action.payload.membersToRemove.length;
      const amountOfMembers = amountOfMembersToAdd
        ? amountOfMembersToAdd
        : state.channel.amountOfMembers - amountOfMembersToRemove;

      const members = state.members.filter(
        (_) => !action.payload.membersToRemove.includes(_.id)
      );

      return {
        ...state,
        channel: {
          ...state.channel,
          amountOfMembers
        },
        members
      };
    case ChannelActions.ActionTypes.LEAVE_CHANNEL_SUCCESS: {
      if (!state.members) {
        return state;
      }

      if (state.channel.id !== action.payload.channel.id) {
        return state;
      }

      const members = state.members.filter(
        (_) => _.id !== action.payload.userId
      );

      return {
        ...state,
        channel: {
          ...state.channel,
          amountOfMembers: state.channel.amountOfMembers - 1
        },
        members
      };
    }
    case ChannelActions.ActionTypes.ME_LEAVE_CHANNEL_SUCCESS:
    case ChannelActions.ActionTypes.DELETE_CHANNEL_SUCCESS:
      if (!state.channel) {
        return state;
      }

      if (state.channel.id !== action.payload.channel.id) {
        return state;
      }

      return {
        ...state,
        channel: {
          ...state.channel,
          amountOfMembers: state.channel.amountOfMembers - 1,
          isDeleted: true
        }
      };
    case ChannelActions.ActionTypes.EDIT_CHANNEL_SUCCESS: {
      if (!state.channel) {
        return state;
      }

      if (state.channel.id !== action.payload.channel.id) {
        return state;
      }

      return {
        ...state,
        channel: {
          ...state.channel,
          ...action.payload.channel
        }
      };
    }
    case ChatsListActions.ActionTypes.DELETE_GROUP_CHAT_SUCCESS:
      if (!state.channel) {
        return state;
      }

      if (state.channel.chatId !== action.payload.chat.id) {
        return state;
      }

      return {
        ...state,
        channel: {
          ...state.channel,
          amountOfMembers: state.channel.amountOfMembers - 1,
          isDeleted: true
        }
      };
    case SelectedChannelActions.ActionTypes.CLEAN_CHANNEL_MEMBERS:
      return {
        ...state,
        members: null
      };
    case SelectedChannelActions.ActionTypes.CLEAN_SELECTED_CHANNEL:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export const getChannel = (state: State) => state.channel;
export const isLoadingChannel = (state: State) => state.isLoading;
export const getChannelMembers = (state: State) => state.members;
