import { ActionReducerMap } from '@ngrx/store';
import { IImagesState, imagesKey } from '../images/images.state';
import { IDocsState, docsKey } from '../docs/docs.state';
import { imagesReducer } from '../images/images.reducer';
import { docsReducer } from '../docs/docs.reducer';
import { ImagesEffects } from '../images/images.effects';
import { DocsEffects } from '../docs/docs.effects';

export interface AppState {
  images: IImagesState;
  docs: IDocsState;
}

export const appReducers: ActionReducerMap<AppState> = {
  [imagesKey]: imagesReducer,
  [docsKey]: docsReducer
};

export const appEffects = [ImagesEffects, DocsEffects];
