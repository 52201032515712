import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { searchPeople, searchPeopleSuccess } from './people.actions';
import { PeopleService } from './people.service';

@Injectable()
export class PeopleEffects {
  constructor(
    private actions$: Actions,
    private peopleService: PeopleService
  ) {}

  effectForSearchPeople$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchPeople),
      switchMap((params) =>
        this.peopleService
          .searchPeople(params.searchParams)
          .pipe(
            switchMap(({ count, people }) => [
              searchPeopleSuccess({ count, people: this.normalize(people) })
            ])
          )
      )
    )
  );

  private normalize(organizations) {
    return organizations.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
