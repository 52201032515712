import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { combineLatest, from } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { PlatformService } from 'src/app/shared/services/platform.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthorizedGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private platformService: PlatformService
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (this.platformService.isPlatformServer()) {
      // in order to avoid redirect to the '/' page during first SSR rendering
      return true;
    }

    return this.authService.isLoaded$.pipe(
      filter((_) => _),
      mergeMap((_) =>
        combineLatest([
          this.authService.isFullyAuthorized$,
          this.authService.isPartialAuthorized$
        ])
      ),
      map((_) => this.handler(_))
    );
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    if (this.platformService.isPlatformServer()) {
      // in order to avoid redirect to the '/' page during first SSR rendering
      return true;
    }

    return this.authService.isLoaded$
      .pipe(
        filter((_) => _),
        mergeMap((_) =>
          combineLatest([
            this.authService.isFullyAuthorized$,
            this.authService.isPartialAuthorized$
          ])
        ),
        take(1),
        map((_) => this.handler(_)),
        // TODO: Update angular and return UrlTree object
        // refactor to be similar to activate
        mergeMap((_) => from(this.handleCanLoad(_)))
      )
      .toPromise();
  }

  private handler([isFullyAuthorized, isPartialAuthorized]) {
    if (isFullyAuthorized) {
      return this.router.createUrlTree(['home']);
    }

    if (isPartialAuthorized) {
      return this.router.createUrlTree(['registration']);
    }

    return true;
  }

  private async handleCanLoad(url: boolean | UrlTree) {
    if (url instanceof UrlTree) {
      await this.router.navigateByUrl(url);

      return false;
    }

    return url;
  }
}
