import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import moment from 'moment';

import { IPost } from '../../../../../models/post';
import { IPostCtx } from '../../post.component';

@Component({
  selector: 'app-post-user-header',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostUserHeaderComponent {
  @Input() readonly post: IPost;
  @Input() readonly ctx: IPostCtx;

  get createdTime() {
    return moment.utc(this.post.created_at.date).valueOf();
  }
}
