<app-post-user-header
  *ngIf="ctx.modifyers?.isGroup || post.type === 'user'"
  [post]="post"
  [ctx]="ctx"
  class="post-top d-flex"
></app-post-user-header>

<app-post-group-header
  *ngIf="!ctx.modifyers?.isGroup && post.type === 'group'"
  [post]="post"
  class="post-top d-flex"
></app-post-group-header>
<app-post-discussion-header
  *ngIf="post.type === 'discussion'"
  [post]="post"
  class="post-top d-flex"
></app-post-discussion-header>
<div class="post-content">
  <div [innerHTML]="post.raw_text | safeUserInput:{tags: post.tags}" class="post-content-data" appReadMore> </div>

  <app-post-content *ngIf="post.share" [post]="post.share" [ctx]="ctx" class="re-post"></app-post-content>

  <app-post-link-preview-view [data]="preview" *ngFor="let preview of previews"></app-post-link-preview-view>

  <img
    *ngIf="post.fotos?.length === 1; else imageWrapper"
    [src]="post.fotos[0].url | baseUrl"
    class="img-fluid post-image"
    (click)="toggleImageFullscreen(0)"
  />
</div>

<app-message-doc *ngFor="let doc of post.docs" [doc]="doc" (click)="openDoc(doc)"></app-message-doc>

<ng-template #imageWrapper>
  <div
    class="images-wrapper d-flex flex-wrap"
    [ngClass]="{
                      'mod-double': post.fotos.length === 2,
                      'mod-triple': post.fotos.length === 3 || post.fotos.length % 3 === 0,
                      'mod-mixed-one': post.fotos.length > 3 && post.fotos.length % 3 === 1,
                      'mod-mixed-two': post.fotos.length > 3 && post.fotos.length % 3 === 2
                    }"
  >
    <div *ngFor="let photo of post.fotos; index as i" class="img-item" (click)="toggleImageFullscreen(i)">
      <img [src]="photo.url | baseUrl" />
    </div>
  </div>
</ng-template>

<app-image-viewer
  *ngIf="imageIndex !== null"
  [images]="post.fotos"
  [index]="imageIndex"
  (close)="toggleImageFullscreen(null)"
></app-image-viewer>
