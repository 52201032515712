import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { environment } from 'src/environments/environment';
// import {envirement} from ''

// TODO: move app-link to the modules/links
// and make directive from it

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {
  private _route;

  get route(): any[] | string {
    return this._route;
  }
  @Input()
  set route(val: any[] | string) {
    if (typeof val === 'string') {
      this._route = `${environment.routePrefix}/${val}`;
    } else if (Array.isArray(val) && val[0].length > 1) {
      this._route = [environment.routePrefix, ...val];
    } else {
      this._route = [environment.routePrefix];
    }
  }
  @Input() class: string;
}
