import { Injectable } from '@angular/core';

import { IIcon } from '../../model/icon';
import { icons } from '../../groups';

@Injectable()
export class IconsService {
  readonly all: readonly IIcon[] = [...icons];

  public get(id: string): IIcon {
    const icon = this.all.find(({ id }) => id === id);

    if (icon === undefined) {
      // #Error#
      throw new Error(`Icon with id "${id}" doesn't exist.`);
    }

    return icon;
  }
}
