import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateComponent {
  @Input() readonly ctx: IDateCtx;
  @ViewChild('picker') readonly picker;

  public onInputClick() {
    this.picker.open();
  }
}

export interface IDateCtx {
  readonly placeholder: string;
  readonly control: AbstractControl;
  readonly start: Date;
  readonly isInputDisabled?: boolean;
}
