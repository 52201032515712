<app-header></app-header>

<section class="container d-flex flex-column flex-lg-row" [ngClass]="{'hidden': uninitialized$ | async}">
  <ng-container *ngIf="isRenderLeftNavMenu$ | async">
    <app-left-nav
      class="left-nav-column"
      appStickyScroll
      [appStickyScrollTop]="80"
      [appStickyScrollBottom]="10"
    ></app-left-nav>
  </ng-container>

  <div class="w-100 d-md-block">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </div>

  <div class="right" appStickyScroll [appStickyScrollTop]="80" [appStickyScrollBottom]="10">
    <app-competitions-short-list></app-competitions-short-list>
  </div>
</section>
