import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import { Event, People, Group, Organization, Discussion } from './top.model';
import { Club } from '../clubs/club.model';

@Injectable({
  providedIn: 'root'
})
export class TopService {
  constructor(private base: BaseService) {}

  searchTop(params: { q: string }): Observable<{
    people: People[];
    events: Event[];
    groups: Group[];
    organizations: Organization[];
    discussions: Discussion[];
    clubs: Club[];
  }> {
    return this.search(params).pipe(
      map((_) => ({
        people: _.people,
        events: _.events,
        groups: _.groups,
        organizations: _.organizations,
        discussions: _.discussions,
        clubs: _.clubs
      }))
    );
  }

  private search(params) {
    const urlParams = new URLSearchParams({
      limit: '5',
      text: params.q ? params.q : ''
    });

    return this.base.get(`search?${urlParams}`);
  }
}
