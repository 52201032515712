import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import { Network } from './network.model';
import * as NetworkActions from './networks.actions';

export const networksFeatureKey = 'networks';

const AMOUNT_OF_USERS_ON_ONE_PAGE = 20;

export interface State {
  networks: { [id: number]: Network };
  loading: boolean;
  count: number;
  amountOfPages: number;
}

export const initialState: State = {
  networks: null,
  loading: false,
  count: null,
  amountOfPages: null
};

export const reducer = createReducer(
  initialState,
  on(NetworkActions.loadNetwork, (state, action): State => {
    return {
      ...state,
      networks: null,
      loading: true
    };
  }),
  on(NetworkActions.loadNetworkSuccess, (state, action): State => {
    return {
      ...state,
      networks: action.networks,
      count: action.count,
      loading: false,
      amountOfPages: Math.ceil(action.count / AMOUNT_OF_USERS_ON_ONE_PAGE)
    };
  }),
  on(NetworkActions.disconnectRequestSuccess, (state, action): State => {
    const networks = { ...state.networks };
    delete networks[action.id];

    const count = state.count - 1;

    return {
      ...state,
      networks,
      count,
      loading: false,
      amountOfPages: Math.ceil(count / AMOUNT_OF_USERS_ON_ONE_PAGE)
    };
  })
);

export const selectState = createFeatureSelector<State>(networksFeatureKey);

export const selectNetworks = () =>
  createSelector(selectState, (state: State) => {
    if (!state.networks) {
      return {
        items: null,
        loading: state.loading,
        count: state.count,
        amountOfPages: 0
      };
    }

    return {
      items: Object.values(state.networks),
      loading: state.loading,
      count: state.count,
      amountOfPages: state.amountOfPages
    };
  });
