import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { IconsService } from '../../services';

@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IconsService]
})
export class AppIconsComponent {
  constructor(readonly icons: IconsService, readonly sanitizer: DomSanitizer) {}

  public getTrustedHtml(svg: string) {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
