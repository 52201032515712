<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title>Sharing post</h1>
  <div mat-dialog-content>
    <div class="form-wrapper p-0">
      <app-message [ctx]="messageCtx"></app-message>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="share()">Share</button>
  </div>
</div>
