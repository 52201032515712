import { IIcon } from '../../model/icon';

export const subEditPost: IIcon = {
  id: `SUB_EDIT_POST`,
  svg: `
  <g clip-path="url(#clip17)">
  <path fill="#F3D55B"
    d="M11.38 2.07H1.55C.7 2.07 0 2.77 0 3.62v9.83C0 14.3.7 15 1.55 15h9.83c.86 0 1.55-.7 1.55-1.55V3.62c0-.86-.7-1.55-1.55-1.55z" />
  <path fill="#802F34"
    d="M11.04 3.23L5.75 8.52l-.01.01A.52.52 0 105 7.8l5.3-5.3.74.73zM7.2 10a.52.52 0 00-.73-.74l5.3-5.3.73.73L7.2 10z" />
  <path fill="#FF5364" d="M14.7 2.5l-1.28 1.28-2.2-2.2L12.5.3c.4-.4 1.06-.4 1.47 0l.73.73c.4.4.4 1.06 0 1.47z" />
  <path fill="#FDD7AD" d="M12.32 1.58l-.55.55a.26.26 0 10.37.37l.55-.55a.26.26 0 10-.37-.37z" />
  <path fill="#2C3E50" d="M5.21 10.83l-1.2.5a.26.26 0 01-.34-.34l.5-1.2c.5.15.89.54 1.04 1.04z" />
  <path fill="#F29C1F" d="M6.47 9.26a.52.52 0 00-.72-.74l5.29-5.29.73.73-5.3 5.3z" />
  <path fill="#F9EAB0"
    d="M7.2 10l-1.99.83c-.15-.5-.54-.89-1.04-1.04L5 7.8a.52.52 0 01.74.73l.01-.01a.52.52 0 01.72.74.52.52 0 01.73.74zM1.55 3.36H1.3a.26.26 0 110-.52h.26a.26.26 0 010 .52zM6.47 3.36H2.59a.26.26 0 110-.52h3.88a.26.26 0 010 .52zM11.9 13.97a.26.26 0 01-.26-.26v-.52a.26.26 0 11.52 0v.52c0 .14-.12.26-.26.26z" />
  <path fill="#35495E"
    d="M2.59 11.64h-1.3a.26.26 0 110-.52h1.3a.26.26 0 010 .52zM10.6 11.64H6.47a.26.26 0 110-.52h4.13a.26.26 0 010 .52zM7.24 12.93H1.3a.26.26 0 010-.52h5.95a.26.26 0 010 .52zM10.6 12.93H8.8a.26.26 0 010-.52h1.8a.26.26 0 010 .52z" />
  <path fill="#BDC3C7" d="M13.42 3.78l-.92.91-2.2-2.2.92-.9 2.2 2.19z" />
</g>
<defs>
  <clipPath id="clip17">
    <path fill="#fff" d="M0 0h15v15H0z" />
  </clipPath>
</defs>
  `
};
