import * as Immutable from 'immutable';

import { Error403Component } from './../../../../../pages/error-403/error-403.component';
import { loadResourcesSuccessNoPermissionsError } from './../../../../../core/services/clubs/clubs.actions';
import { Router } from '@angular/router';
import {
  paginateGroupPosts,
  loadPostsSuccess,
  loadPosts,
  loadCommentsForPost,
  loadCommentsForPostSuccess,
  addCommentToPost,
  addCommentToPostSuccess,
  addPost,
  addPostSuccess,
  loadPostsByUserId,
  sharePost,
  sharePostSuccess,
  likePost,
  likePostSuccess,
  deletePost,
  deletePostSuccess,
  complainePost,
  complainePostSuccess,
  loadPostsById,
  loadPostsByIdSuccess,
  deleteComment,
  deleteCommentSuccess,
  updatePost,
  updatePostSuccess,
  likeComment,
  likeCommentSuccess,
  replyComment,
  replyCommentSuccess,
  loadPostsByGroupId,
  paginatePosts,
  paginatePostsSuccess,
  addGroupPost,
  paginatePostsByUser as paginatePostsByUserId,
  pinPost,
  pinPostSuccess,
  unpinPost,
  unpinPostSuccess,
  loadPostsNoPermissionsError,
  addPostError,
  addPostToUser
} from './actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PostsService } from './service';
import {
  switchMap,
  mergeMap,
  map,
  tap,
  catchError,
  filter
} from 'rxjs/operators';
import { addInfoMessage } from '../../../../../pages/components/info-message/info-message.actions';
import { IPost } from '../../../../models/post';
import { TypedAction } from '@ngrx/store/src/models';
import { GroupsService } from '../../../../../core/services/groups/groups.service';
import { GroupService } from '../../../../../core/services/group/group.service';
import { empty, of } from 'rxjs';

import { RoutingService } from 'src/app/core/services/routing.service';

@Injectable()
export class PostEffects {
  constructor(
    private actions$: Actions,
    private service: PostsService,
    private group: GroupService,
    private router: Router,
    private routing: RoutingService,
    private appRouting: RoutingService
  ) {}

  effectForLoadPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPosts),
      switchMap((_) =>
        this.service
          .getPosts(1, _.isPrivate)
          .pipe(
            switchMap((posts) => [
              loadPostsSuccess({ posts: this.normalize(posts) }),
              ...this.getCommentsActins(posts)
            ])
          )
      )
    )
  );

  paginatePosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paginatePosts),
      switchMap((_) => {
        return this.service
          .getPosts(_.nextPage, _.isPrivate)
          .pipe(
            switchMap((posts) => [
              paginatePostsSuccess({ posts: this.normalize(posts) }),
              ...this.getCommentsActins(posts)
            ])
          );
      })
    )
  );

  loadPostsByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPostsByUserId),
      switchMap((args) =>
        this.service
          .getPostsByUserId(args.id, 1)
          .pipe(
            switchMap((posts) => [
              loadPostsSuccess({ posts: this.normalize(posts) }),
              ...this.getCommentsActins(posts)
            ])
          )
      )
    )
  );

  paginatePostsByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paginatePostsByUserId),
      switchMap(({ id, nextPage }) =>
        this.service
          .getPostsByUserId(id, nextPage)
          .pipe(
            switchMap((posts) => [
              paginatePostsSuccess({ posts: this.normalize(posts) }),
              ...this.getCommentsActins(posts)
            ])
          )
      )
    )
  );

  loadPostsByGroupId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPostsByGroupId),
      switchMap((args) =>
        this.group
          .getGroupPosts(args.id, 1)
          .pipe(
            switchMap((posts) => [
              loadPostsSuccess({ posts: this.normalize(posts) }),
              ...this.getCommentsActins(posts)
            ])
          )
      ),
      catchError((err) => {
        if (err.error.code === 403) {
          return of(loadPostsNoPermissionsError());
        } else {
          this.appRouting.goToUnexpectedErrorPage({ skipLocationChange: true });
          return empty();
        }
      })
    )
  );

  paginateGroupPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paginateGroupPosts),
      switchMap(({ id, nextPage: page }) => {
        return this.group
          .getGroupPosts(id, page)
          .pipe(
            switchMap((posts) => [
              paginatePostsSuccess({ posts: this.normalize(posts) }),
              ...this.getCommentsActins(posts)
            ])
          );
      })
    )
  );

  loadPostsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPostsById),
      switchMap((args) =>
        this.service.getPostById(args.id).pipe(
          catchError((_) => this.handleJobError(_.error, { id: args.id })),
          switchMap((post) => [
            loadPostsByIdSuccess({ post }),
            ...this.getCommentsActins([post])
          ])
        )
      )
    )
  );

  pinPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pinPost),
      switchMap(({ post }) => {
        return this.service
          .pinPost(post)
          .pipe(switchMap((post) => [pinPostSuccess({ post })]));
      })
    )
  );

  unpinPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unpinPost),
      switchMap(({ post }) => {
        return this.service
          .unpinPost(post)
          .pipe(switchMap((post) => [unpinPostSuccess({ post })]));
      })
    )
  );

  loadCommentsForPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCommentsForPost),
      mergeMap((args) =>
        this.service.getCommentsToPost(args).pipe(
          map((comments) =>
            loadCommentsForPostSuccess({
              post: args.post,
              comments,
              isFromPost: false
            })
          )
        )
      )
    )
  );

  addPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPost),
      mergeMap((args) =>
        this.service.addPost(args.post).pipe(
          filter((_) => !!_),
          map((post) =>
            addPostSuccess({
              post
            })
          )
        )
      )
    )
  );

  addPostToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPostToUser),
      mergeMap(({ post, userId }) =>
        this.service.addPostToUser(post, userId).pipe(
          map((post) => {
            return post
              ? addPostSuccess({
                  post
                })
              : addInfoMessage({
                  infoMessage: {
                    type: 'alert-danger',
                    message:
                      'You can’t post unless you are connected with the member'
                  }
                });
          })
        )
      )
    )
  );

  addGroupPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addGroupPost),
      mergeMap(({ id, post }) =>
        this.service.addGroupPost(post, id).pipe(
          map((post) => {
            return post
              ? addPostSuccess({
                  post
                })
              : addPostError();
          })
        )
      )
    )
  );

  updatePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePost),
      mergeMap((args) =>
        this.service.updatePost(args.post, args.id).pipe(
          map((post) =>
            updatePostSuccess({
              post: { id: args.id, changes: post }
            })
          )
        )
      )
    )
  );

  addCommentToPost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addCommentToPost),
      mergeMap((args) =>
        this.service.addCommentToPost(args.comment).pipe(
          map((comment) =>
            addCommentToPostSuccess({
              post_id: args.comment.post_id,
              comment
            })
          )
        )
      )
    )
  );

  replyComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replyComment),
      mergeMap(({ comment }) =>
        this.service
          .replyComment(comment)
          .pipe(map((comment) => replyCommentSuccess({ comment })))
      )
    )
  );

  // replyCommentSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(replyCommentSuccess),
  //     mergeMap((args) =>
  //       this.service.getCommentsToPost(args.post_id).pipe(
  //         map((comments) =>
  //           loadCommentsForPostSuccess({
  //             post_id: args.post_id,
  //             comments,
  //           })
  //         )
  //       )
  //     )
  //   )
  // );

  sharePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharePost),
      mergeMap(({ post: share, isShouldAdd }) =>
        this.service.sharePost(share).pipe(
          switchMap((post) => {
            const actions: TypedAction<any>[] = [sharePostSuccess({ post })];

            if (isShouldAdd) actions.push(addPostSuccess({ post }));

            return actions;
          })
        )
      )
    )
  );

  likePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(likePost),
      mergeMap((args) =>
        this.service.likePost(args.id).pipe(
          map(({ count }) =>
            likePostSuccess({
              id: args.id,
              count
            })
          )
        )
      )
    )
  );

  likeComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(likeComment),
      mergeMap(({ comment }) =>
        this.service.likeComment(comment.id).pipe(
          map(({ count }) =>
            likeCommentSuccess({
              comment,
              count
            })
          )
        )
      )
    )
  );

  deletePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePost),
      mergeMap((args) =>
        this.service.deletePost(args.id).pipe(
          map((res) =>
            deletePostSuccess({
              id: args.id
            })
          )
        )
      )
    )
  );

  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteComment),
      mergeMap(({ comment }) =>
        this.service
          .deleteComment(comment.id)
          .pipe(map(() => deleteCommentSuccess({ comment })))
      )
    )
  );

  complainePost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(complainePost),
      mergeMap((args) =>
        this.service.complainePost(args.id).pipe(
          map((res) =>
            complainePostSuccess({
              id: args.id
            })
          )
        )
      )
    )
  );

  // loadProfileInfoById$ = createEffect(
  //   () => this.actions$.pipe(
  //     ofType(loadProfileInfoById),
  //     switchMap((args) => this.service.getUserInfoById(args.profileId).pipe(
  //       map((data) => loadProfileInfoSuccess({ profileInfo: data }))
  //     ))
  //   )
  // );

  private normalize(items: Array<IPost>) {
    return items.reduce((acc, cur) => {
      return acc.set(cur.id, cur);
    }, Immutable.OrderedMap<number, IPost>());
  }

  protected getCommentsActins(posts: IPost[] = []) {
    return posts.map((post) => {
      return loadCommentsForPostSuccess({
        post: post,
        comments: post.comments.list,
        isFromPost: true
      });
    });
  }

  // TODO: refactor
  private handleError(actionToPerform) {
    return (error) => {
      switch (error.code) {
        case 403:
          return actionToPerform(error);
        default:
      }
    };
  }

  private handleJobError(error, params) {
    console.log('handleJobError: ', error);

    switch (error?.code) {
      case 403:
        this.routing.goTo403({
          skipLocationChange: true,
          state: { type: Error403Component.Type.User, ...params }
        });
        break;
      default:
        this.routing.goTo404({ skipLocationChange: true });
    }

    return of();
  }
}
