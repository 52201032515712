import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ClubActions } from '../actions';
import { IClubMember } from '../../model';

export const featureAdapter: EntityAdapter<IClubMember> =
  createEntityAdapter<IClubMember>({
    selectId: (model) => model.id,
    sortComparer: (a: IClubMember, b: IClubMember): number => b.id - a.id
  });

export interface State extends EntityState<IClubMember> {
  isLoading: boolean;
  requestsTotalCount: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  requestsTotalCount: 0
});

export function Reducer(
  state = initialState,
  action: ClubActions.Actions
): State {
  switch (action.type) {
    case ClubActions.ActionTypes.LOAD_CLUB_REQUESTS:
      return {
        ...state,
        isLoading: true
      };
    case ClubActions.ActionTypes.LOAD_CLUB_REQUESTS_SUCCESS:
      return featureAdapter.addMany(action.payload.requests, {
        ...state,
        isLoading: false,
        requestsTotalCount: action.payload.requestsTotalCount
      });
    case ClubActions.ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST:
    case ClubActions.ActionTypes.CLUB_DECLINE_MEMBER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ClubActions.ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST_SUCCESS:
    case ClubActions.ActionTypes.CLUB_DECLINE_MEMBER_REQUEST_SUCCESS: {
      const { memberId } = action.payload;

      return featureAdapter.removeOne(memberId, {
        ...state,
        isLoading: false
      });
    }
    case ClubActions.ActionTypes.CLUB_ACCEPT_MEMBER_REQUEST_SUCCESS: {
      const { memberId } = action.payload;

      return featureAdapter.removeOne(memberId, state);
    }
    case ClubActions.ActionTypes.CLUB_DECLINE_MEMBER_REQUEST_SUCCESS: {
      const { memberId } = action.payload;

      return featureAdapter.removeOne(memberId, state);
    }
    default:
      return state;
  }
}

export const isLoadingClubRequests = (state: State) => state.isLoading;
export const { selectAll: getClubRequests } = featureAdapter.getSelectors();
export const getClubRequestsAmountOfPages = (state: State) =>
  Math.ceil(state.requestsTotalCount / 20);
