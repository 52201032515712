<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title *ngIf="isEditMode; else newMode">Edit - {{ title }}</h1>
  <ng-template #newMode>
    <h1 mat-dialog-title>Create new club project or content</h1>
  </ng-template>
  <div mat-dialog-content>
    <div class="form-wrapper py-0 px-3">
      <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type_id" [compareWith]="compareTypes" required>
            <mat-option [value]="type.id" *ngFor="let type of types"> {{ type.title }} </mat-option>
          </mat-select>
          <mat-hint>i.e. Rushmore Academy or Junior Statesmen of America</mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100" formGroupName="organization">
          <mat-label>Organization</mat-label>
          <input
            matInput
            [matAutocomplete]="organizationSearch.autocomplete"
            formControlName="name"
            (input)="organizationSearch.onSearchChange($event.target.value); onSearchChange($event.target.value)"
            required
          />
          <organization-search (select)="onSelectOrganizationFromList($event)" #organizationSearch="organizationSearch">
          </organization-search>
          <mat-hint>i.e. Future Business Leaders of America</mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" required />
          <mat-hint>i.e. Rushmore Academy or Junior Statesmen of America</mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Topic</mat-label>
          <input matInput formControlName="topic" required />
          <mat-hint>i.e. Rushmore Academy or Junior Statesmen of America</mat-hint>
        </mat-form-field>

        <mat-form-field class="w-100">
          <mat-label>Description</mat-label>
          <textarea matInput rows="6" formControlName="description" [maxLength]="120" #desc required></textarea>
          <div class="character-counter">{{desc.textLength}}/{{desc.maxLength}}</div>
        </mat-form-field>

        <div class="add-item-field-wrapper d-flex">
          <div class="d-flex w-100">
            <mat-form-field class="w-100">
              <mat-label>Article link</mat-label>
              <input matInput type="url" formControlName="url" required />
            </mat-form-field>
          </div>
          <button
            class="add-item-btn d-flex align-items-center justify-content-center"
            title="Create new article"
            (click)="createNewArticle(); false"
          >
            <svg viewBox="0 0 15 14">
              <use href="#plus-white" />
            </svg>
          </button>
          <button
            class="add-item-btn d-flex align-items-center justify-content-center"
            title="Remove article"
            (click)="removeArticle(); false"
            *ngIf="isArticle$ | async"
          >
            <svg viewBox="0 0 15 14">
              <use href="#plus-white" style="transform: rotateZ(45deg); transform-origin: center center" />
            </svg>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSubmit(); false" [disabled]="projectForm.invalid">
      <span *ngIf="isEditMode; else newMode2">Save</span>
      <ng-template #newMode2>
        <span>Create</span>
      </ng-template>
    </button>
  </div>
</div>
