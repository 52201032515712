import { IAuthData } from '../../model';
import { RegistrationActions } from '../actions';

export interface State {
  isLoading: boolean;
  isRegistered: boolean;
  authData: IAuthData;
}

const initialState: State = {
  isLoading: false,
  isRegistered: false,
  authData: null
};

export function Reducer(
  state = initialState,
  action: RegistrationActions.Actions
): State {
  switch (action.type) {
    case RegistrationActions.ActionTypes.STUDENT_REGISTRATION:
      return {
        ...state,
        isLoading: true,
        isRegistered: false
      };
    case RegistrationActions.ActionTypes.STUDENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRegistered: true
      };
    case RegistrationActions.ActionTypes.STUDENT_REGISTRATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isRegistered: false
      };
    case RegistrationActions.ActionTypes.AUTH_DATA_SUCCESS:
      return {
        ...state,
        authData: action.payload
      };
    case RegistrationActions.ActionTypes.REGISTRATION_RESET:
    case RegistrationActions.ActionTypes.REGISTRATION_CANCEL:
      return initialState;
    default:
      return state;
  }
}

export const isLoading = (state: State) => state.isLoading;
export const isRegistered = (state: State) => state.isRegistered;
export const authData = (state: State) => state.authData;
