<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title>Build your network!</h1>
  <div mat-dialog-content>
    <div class="form-wrapper">
      <form>
        <mat-form-field class="w-100">
          <mat-label>Invite to connect</mat-label>
          <textarea
            matInput
            rows="4"
            placeholder="i.e. I would like to connect with you as I am looking for team members…"
            [maxLength]="250"
            #desc
          ></textarea>
          <div class="character-counter">{{desc.textLength}}/{{desc.maxLength}}</div>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSend(desc.value)">Send</button>
  </div>
</div>
