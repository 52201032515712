import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IPost } from '../../../models/post';
import { IDoc } from '../../../models/doc';

@Component({
  selector: 'app-message-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class MessageDocsComponent implements OnInit, OnDestroy {
  @Input() readonly ctx: IMessageDocsCtx;
  @Input() readonly editable?: { docs: IDoc[] };

  public sbscr: Subscription;
  public docs: IDoc[] = [];

  constructor(protected cd: ChangeDetectorRef) {}

  public ngOnInit() {
    // TODO: refactor
    // similar logic within parent component - message
    this.sbscr = this.ctx.stream.subscribe((values) => {
      this.docs = values;
      this.cd.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.sbscr.unsubscribe();
  }

  public onSelectToggle(doc: IDoc) {
    if (this.ctx.saved.has(doc)) {
      this.ctx.saved.delete(doc);
    } else {
      if (this.isCanSelect()) this.ctx.saved.add(doc);
    }
  }

  public onLocalSelect([file]: File[]) {
    this.ctx.local.add(file);
  }

  public onLocalUnselect(doc: File) {
    this.ctx.local.delete(doc);
  }

  public preLocalSelect(e: Event) {
    if (!this.isCanSelect()) {
      e.stopPropagation();
    }
  }

  protected isCanSelect() {
    const total = this.ctx.saved.size + this.ctx.local.size;
    if (total === 10) {
      alert(`Can't select more than 10 docs`);
    } else {
      return true;
    }
  }
}

export interface IMessageDocsCtx {
  stream: Observable<IDoc[]>;
  saved: Set<IDoc>;
  local: Set<File>;
}
