import { Action } from '@ngrx/store';

import { INoticeCounters } from '../../model';

export enum ActionTypes {
  LOAD_NOTICE_COUNTERS = '[Notice-Counters] Load',
  LOAD_NOTICE_COUNTERS_FAILURE = '[Notice-Counters] Load Failure',
  LOAD_NOTICE_COUNTERS_SUCCESS = '[Notice-Counters] Load Success',

  CLEAN_NOTICE_COUNTERS = '[Notice-Counters] Clean'
}

export class LoadNoticeCountersAction implements Action {
  readonly type = ActionTypes.LOAD_NOTICE_COUNTERS;
}

export class LoadNoticeCountersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_NOTICE_COUNTERS_SUCCESS;
  constructor(public payload: INoticeCounters) {}
}

export class LoadNoticeCountersFailureAction implements Action {
  readonly type = ActionTypes.LOAD_NOTICE_COUNTERS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CleanNoticeCountersAction implements Action {
  readonly type = ActionTypes.CLEAN_NOTICE_COUNTERS;
}

export type Actions =
  | LoadNoticeCountersAction
  | LoadNoticeCountersSuccessAction
  | LoadNoticeCountersFailureAction
  | CleanNoticeCountersAction;
