import { IIcon } from '../../model/icon';

export const workGroup: IIcon = {
  id: `WORK_GROUP`,
  svg: `
  <g clip-path="url(#clip27)">
  <path fill="#955BA5"
    d="M12.68 6.55a2.4 2.4 0 00-1.98-1.03H9.32a2.4 2.4 0 00-1.98 1.03l-.08 3.1h5.17l.25-3.1zM6.48 6.55A2.4 2.4 0 004.5 5.52H3.12A2.41 2.41 0 00.7 7.93v1.04c0 .38.31.68.7.69h2.4l2.68-3.1zM16.22 9.66h2.41a.7.7 0 00.7-.7V7.94a2.41 2.41 0 00-2.42-2.41h-1.38a2.4 2.4 0 00-1.98 1.03l2.67 3.1z" />
  <path fill="#F09372"
    d="M5.76 1.39a2.07 2.07 0 10-.8 2.4l.8-2.4zM11.97 1.39a2.07 2.07 0 00-3.9 0l.8 2.4a2.05 2.05 0 002.3 0l.8-2.4zM15.07 3.79a2.07 2.07 0 10-.8-2.4l.8 2.4z" />
  <path fill="#FDD7AD"
    d="M8.98 2.98a2.07 2.07 0 11-4.13.25 2.07 2.07 0 014.13-.25zM15.18 2.98a2.07 2.07 0 11-4.13.24 2.07 2.07 0 014.13-.24z" />
  <path fill="#4FBA6F"
    d="M12.43 6.55h1.38a2.41 2.41 0 012.41 2.42V10c0 .38-.3.69-.69.69H10.7a.69.69 0 01-.69-.69V8.97a2.41 2.41 0 012.42-2.42z" />
  <path fill="#4FBA6F"
    d="M7.94 10.69h1.38a.7.7 0 00.7-.69V8.97A2.41 2.41 0 007.6 6.55H6.22a2.41 2.41 0 00-2.41 2.42V10c0 .38.3.69.69.69h3.44z" />
  <path fill="#FDD7AD"
    d="M9.24 20c-.97 0-1.89-.47-2.45-1.26a18.27 18.27 0 00-1.87-2.28l-.81-.81a.9.9 0 01-.08-1.22.86.86 0 011.26-.04l.34.34a.34.34 0 00.6-.24v-5.3a.9.9 0 01.79-.91.86.86 0 01.92.86v3.45a.86.86 0 011.73 0v.34a.86.86 0 111.72 0v.35a.86.86 0 111.73 0v3.7A3.02 3.02 0 0110.1 20h-.86z" />
  <path fill="#F09372"
    d="M10.36 18.97H8.98a.34.34 0 010-.7h1.38a.34.34 0 010 .7zM7.94 15.17a.34.34 0 01-.34-.34V13.1a.34.34 0 01.69 0v1.73c0 .19-.15.34-.35.34zM9.67 15.17a.34.34 0 01-.35-.34V13.1a.34.34 0 01.7 0v1.73c0 .19-.16.34-.35.34zM11.4 15.17a.34.34 0 01-.35-.34v-1.38a.34.34 0 01.69 0v1.38c0 .19-.16.34-.35.34z" />
  </g>
  <defs>
  <clipPath id="clip27">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
  </defs>
`
};
