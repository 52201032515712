import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ConvertNameService } from 'src/app/shared/services/convert-name.service';
import { environment } from 'src/environments/environment';

import { AppBaseUrlPipe } from '../../../baseurl/pipes';

@Directive({
  selector: 'a[job]',
  providers: [AppBaseUrlPipe]
})
export class JobLinkDirective implements OnInit {
  @Input() job: { id: number; description: string };
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_blank';

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _convertNameService: ConvertNameService,
    private readonly _appBaseUrlPipe: AppBaseUrlPipe
  ) {}

  ngOnInit(): void {
    this._el.nativeElement.setAttribute('href', this.route);
    this._el.nativeElement.setAttribute('target', this.target);
  }

  private get route() {
    const id = this.job.id;
    const description = this.job.description;

    const link = [
      'high_school_job_internship_volunteering',
      this._convertNameService.convertName(`${id}-${description}`)
    ];

    const url = this._router.serializeUrl(this._router.createUrlTree(link));

    return this._appBaseUrlPipe.transform(url);
  }
}
