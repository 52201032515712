import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntil, tap, observeOn, filter } from 'rxjs/operators';
import { fromEvent, Subject, asyncScheduler } from 'rxjs';

// NOTE: This directive is similar to the standard rounterLink, except async navigation.
// In some cases, we attach additional directives to track clicking, etc
// and they should be able to access current URL appropriately

@Directive({
  selector: '[appRouterLink]'
})
export class AppRouterLinkDirective implements OnInit, OnDestroy {
  @Input('appRouterLink') private _appRouterLink: any[];
  @Input('appRouterFragment') private _appRouterFragment?: string;
  @Input('appRouterLinkActive') private _appRouterLinkActive?: string;
  @Input('appRouterLinkMatcher') private _appRouterLinkMatcher?: string;
  @Input('queryParams') private _queryParams = {};

  private readonly url$ = new Subject<string>();
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly el: ElementRef,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly hostElement: ElementRef
  ) {}

  ngOnInit() {
    this.el.nativeElement.style.cursor = 'pointer';

    fromEvent(this.el.nativeElement, 'click')
      .pipe(
        observeOn(asyncScheduler),
        tap((_) => console.log('_appRouterLink: ', this._appRouterLink)),
        tap((_) =>
          this.router.navigate(this._appRouterLink, {
            queryParams: this._queryParams,
            fragment: this._appRouterFragment
          })
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.url$
      .pipe(
        tap((_) => this.handleActiveLink(_)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.router.events
      .pipe(
        filter((_): _ is NavigationEnd => _ instanceof NavigationEnd),
        tap((_) => this.url$.next(_.url)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.url$.next(this.router.url);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleActiveLink(url: string) {
    if (!this._appRouterLinkActive) {
      return;
    }

    const [pathname, _] = url.split('?');

    const path = this.router
      .createUrlTree(this._appRouterLink, { queryParams: this._queryParams })
      .toString();

    const matcher = this._appRouterLinkMatcher
      ? new RegExp(this._appRouterLinkMatcher)
      : null;
    if (matcher?.test(pathname)) {
      return this.renderer.addClass(
        this.hostElement.nativeElement,
        this._appRouterLinkActive
      );
    }

    if (url === path) {
      return this.renderer.addClass(
        this.hostElement.nativeElement,
        this._appRouterLinkActive
      );
    }

    this.renderer.removeClass(
      this.hostElement.nativeElement,
      this._appRouterLinkActive
    );
  }
}
