<section>
  <div class="container d-flex">
    <div class="left-column">
      <div class="chat d-flex flex-column">
        <div class="chat-header d-flex">
          <a [routerLink]="['/messages']" class="chat-header-item">
            <ng-container [ngSwitch]="(amountOfUnreadChats$ | async) > 0">
              <div *ngSwitchCase="true" [matBadge]="amountOfUnreadChats$ | async">Direct messages</div>
              <div *ngSwitchDefault>Direct messages</div>
            </ng-container>
          </a>
          <a [routerLink]="['/group-messages']" class="chat-header-item active">
            <ng-container [ngSwitch]="(amountOfUnreadChannels$ | async) > 0">
              <div *ngSwitchCase="true" [matBadge]="amountOfUnreadChannels$ | async">Channels</div>
              <div *ngSwitchDefault>Channels</div>
            </ng-container>
          </a>
        </div>
        <div class="chat-wrapper">
          <div class="chat-item create" (click)="onCreateNewGroupChat(); false"> + Create channel </div>

          <app-loader-2 *ngIf="isLoading$ | async"></app-loader-2>
          <app-chat-list-of-chats
            [activeChatId]="chatIdForSelectedChannel$ | async"
            [chats]="groupChats$ | async"
            [channelLinkGenerator]="channelLinkGenerator"
            (select)="onSelectChat($event)"
            (addChannel)="onAddChannel($event)"
            (editChat)="onEditChat($event)"
          >
          </app-chat-list-of-chats>
        </div>
      </div>
    </div>

    <ng-container *ngIf="channelId$ | async as channelId; else selectChannelMessage">
      <div class="middle-column app-messages-middle-column d-md-block" [class.visible]="channelId">
        <button class="back" (click)="backToList()">
          <div [icon]="'BACK:16'"></div>
          <div>Group Messages</div>
        </button>
        <div class="messages-wrapper d-flex flex-column">
          <div class="profile-top d-flex">
            <ng-container *ngIf="channelInfo$ | async as channelInfo">
              <div class="avatar">
                <img src="assets/new_img/image%204%20(9).png" alt="" class="img-fluid" />
              </div>
              <div>
                <div class="name"
                  >{{ channelInfo.chatTitle }}, #{{ channelInfo.title }} {{ channelInfo.isDeleted ? '(deleted)' : '' }}
                </div>
                <div class="status">{{ channelInfo.amountOfMembers }} members</div>
              </div>
              <button class="messages-menu-btn" *ngIf="isActiveChannel$ | async">
                <svg viewBox="0 0 20 20">
                  <use href="#menu-dots-green"></use>
                </svg>

                <div class="messages-menu">
                  <div
                    class="messages-menu-item"
                    *ngIf="(isOwner$ | async)"
                    (click)="onEditChannel(channelInfo); false"
                  >
                    <svg viewBox="0 0 15 15">
                      <use href="#literature" />
                    </svg>
                    Edit
                  </div>
                  <!-- <div class="messages-menu-item">
                                    <svg viewBox="0 0 15 15">
                                        <use href="#connections" />
                                    </svg>
                                    Add channel
                                </div>
                                <div class="messages-menu-item">
                                    <svg viewBox="0 0 20 20">
                                        <use href="#friends" />
                                    </svg>
                                    Add companion
                                </div>
                                <div class="messages-menu-item">
                                    <svg viewBox="0 0 15 15">
                                        <use href="#notification-config" />
                                    </svg>
                                    Notifications settings
                                </div> -->
                  <div
                    class="messages-menu-item"
                    (click)="onLeaveChannel(channelInfo); false"
                    *ngIf="!(isOwner$ | async)"
                  >
                    <svg viewBox="0 0 15 15">
                      <use href="#connections" />
                    </svg>
                    Leave
                  </div>
                  <div
                    class="messages-menu-item"
                    (click)="onDeleteChannel(channelInfo); false"
                    *ngIf="(isOwner$ | async)"
                  >
                    <svg viewBox="0 0 15 15">
                      <use href="#sub-delete" />
                    </svg>
                    Delete
                  </div>
                </div>
              </button>
            </ng-container>
          </div>

          <app-chat-messages-box [chatId]="chatId$ | async" [channelId]="channelId"></app-chat-messages-box>
          <div class="add-post-block" *ngIf="isActiveChannel$ | async">
            <app-message [ctx]="messageCtx" class="message"></app-message>
          </div>
        </div>
      </div>

      <div class="right-column d-none d-xl-block">
        <div class="chat block right-block" *ngIf="isActiveChannel$ | async">
          <!-- <div class="title d-flex">Information group chat {{ chatId }}</div> -->
          <div class="right-block-data">
            <div class="profile d-flex flex-column align-items-center">
              <!-- <div class="title d-flex">Group name, #{{ channelInfo.title }}</div> -->
              <ng-container *ngIf="channelInfo$ | async as channelInfo">
                <div class="right-block-avatar">
                  <div class="avatar rounded-circle">
                    <app-avatar [placeholder]="{ name: channelInfo.title } | initials" class="img-fluid"></app-avatar>
                  </div>
                </div>
                <!-- <a href="#" class="name">Group name, #{{ channelInfo.title }}</a> -->
                <div class="position">{{ channelInfo.amountOfMembers }} members</div>
                <a
                  href=""
                  class="link"
                  *ngIf="channelInfo.ownerId | isCurrentUser | async"
                  (click)="onAddParticipants(channelInfo); false"
                  >Add user</a
                >
              </ng-container>
            </div>
            <div class="members-common d-flex"> Members </div>
            <app-chat-info [channelId]="channelId" [isAdmin]="isChannelAdmin$ | async"></app-chat-info>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #selectChannelMessage>
      <div class="middle-column d-md-block">
        <div class="choose-chat">Click on any message to continue the conversation</div>
      </div>
    </ng-template>
  </div>
</section>
