import { Attribute, Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[matInput]'
})
export class AutocompleteOffDirective {
  @HostBinding('attr.name') name;
  @HostBinding('attr.autocomplete') auto;

  constructor(@Attribute('autocomplete') autocomplete: string) {
    autocomplete !== 'on' && this.disableAutocomplete();
  }

  private disableAutocomplete() {
    const value = Math.random().toString(36).substring(2, 5);

    this.name = value;
    this.auto = value;
  }
}
