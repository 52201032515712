import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeAttributes'
})
export class SanitizeAttributesPipe implements PipeTransform {
  transform(value: string): string {
    const template = document.createElement('template');
    template.innerHTML = value;

    const nlArray = Array.from(
      template.content.querySelectorAll<HTMLElement>('*')
    );

    nlArray.forEach((elem) => this.sanitizeElement(elem));

    return template.innerHTML;
  }

  private sanitizeElement(elem: HTMLElement) {
    switch (elem.constructor.name) {
      case 'HTMLAnchorElement':
        return this.sanitizeAnchorElement(elem as HTMLAnchorElement);
      case 'HTMLImageElement':
        return this.sanitizeImageElement(elem as HTMLImageElement);
      default:
        return this.sanitizeUnknownElement(elem);
    }
  }

  private sanitizeUnknownElement(elem: HTMLElement) {
    Array.from(elem.attributes).forEach((attr) =>
      elem.removeAttribute(attr.name)
    );
  }

  private sanitizeImageElement(elem: HTMLImageElement) {
    const src = elem.getAttribute('src');
    const height = elem.getAttribute('height');
    const width = elem.getAttribute('width');

    Array.from(elem.attributes).forEach((attr) =>
      elem.removeAttribute(attr.name)
    );

    src && elem.setAttribute('src', src);
    height && elem.setAttribute('height', height);
    width && elem.setAttribute('width', width);
  }

  private sanitizeAnchorElement(elem: HTMLAnchorElement) {
    const href = elem.getAttribute('href');
    const target = elem.getAttribute('target');

    Array.from(elem.attributes).forEach((attr) =>
      elem.removeAttribute(attr.name)
    );

    href && elem.setAttribute('href', href);
    target && elem.setAttribute('target', target);
  }
}
