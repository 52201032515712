import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  loadImages,
  loadImagesSuccess,
  addImages,
  addImagesSuccess
} from './images.actions';
import { ImagesService } from './images.service';
import { map, switchMap, filter } from 'rxjs/operators';

@Injectable()
export class ImagesEffects {
  constructor(private actions$: Actions, private service: ImagesService) {}

  readonly load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadImages),
      switchMap(() =>
        this.service.get().pipe(
          filter((val) => !!val),
          map((images) => loadImagesSuccess({ images }))
        )
      )
    )
  );

  readonly add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addImages),
      switchMap(({ images }) =>
        this.service
          .add(images)
          .pipe(map((images) => addImagesSuccess({ images })))
      )
    )
  );
}
