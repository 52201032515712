import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { CONFIG, IConfig } from '../../app-store-icon.config';

@Component({
  selector: 'app-apple-store-icon',
  templateUrl: './apple-store-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppleStoreIconComponent {
  constructor(@Inject(CONFIG) private readonly _config: IConfig) {}

  get appleStoreUrl() {
    return this._config.appleStoreURL;
  }
}
