import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppFragmentFocusDirective } from './directives';
import { IConfig } from './interfaces';
import { CONFIG } from './fragment-focus.token';

@NgModule({
  declarations: [AppFragmentFocusDirective],
  imports: [CommonModule],
  exports: [AppFragmentFocusDirective]
})
export class AppFragmentFocusModule {
  static forRoot(config: IConfig): ModuleWithProviders<AppFragmentFocusModule> {
    return {
      ngModule: AppFragmentFocusModule,
      providers: [
        {
          provide: CONFIG,
          useValue: config
        }
      ]
    };
  }
}
