import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';
import { ProfileNameTitlePipe } from 'src/app/shared/pipes/profile-name-title/profile-name-title.pipe';
import { ConvertNameService } from 'src/app/shared/services/convert-name.service';

export interface IProfile {
  id: number;
  type?: string;
  name: string;
  student?: {
    lastname: string;
  };
  teacher?: {
    lastname: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ProfileLinkService {
  constructor(
    private readonly convertNameService: ConvertNameService,
    private readonly convertIdService: ConvertIdService,
    private readonly profileNameTitlePipe: ProfileNameTitlePipe
  ) {}

  generateProfileLink(profile: IProfile) {
    const userName = this.convertUserName(profile);

    return [
      environment.routePrefix,
      this.convertIdService.convertIdNumberToString(profile.id),
      userName
    ];
  }

  private convertUserName(profile: IProfile) {
    const names = this.profileNameTitlePipe.getNames(profile);

    return this.convertNameService.convertName(names.firstname, names.lastname);
  }
}
