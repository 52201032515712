<div class="clubs-wrap">
  <div class="main">
    <button *ngIf="canCreateCompetition" class="button create-competition-button" (click)="onCreateCompetition(); false"
      >+ Create competition</button
    >

    <div class="clubs-section" appFragmentFocus id="current" *ngIf="currentCompetitions.length">
      <a routerLink="." fragment="current" class="clubs-section-title">Current competitions</a>
      <div class="row card-wrap">
        <div
          class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
          *ngFor="let competition of currentCompetitions; trackBy: trackBy"
        >
          <app-competition-card [competition]="competition"></app-competition-card>
        </div>
      </div>

      <app-loader *ngIf="isLoadingCurrentCompetitions"></app-loader>

      <div *ngIf="hasMoreCurrentCompetitionsToLoad" class="d-flex justify-content-center">
        <button (click)="onLoadMoreCurrentCompetitions(); false" class="button">Expand</button>
      </div>
    </div>

    <div class="clubs-section" appFragmentFocus id="upcoming" *ngIf="upcomingCompetitions.length">
      <a routerLink="." fragment="upcoming" class="clubs-section-title">Upcoming competitions</a>
      <div class="row card-wrap">
        <div
          class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
          *ngFor="let competition of upcomingCompetitions; trackBy: trackBy"
        >
          <app-competition-card [competition]="competition"></app-competition-card>
        </div>
      </div>

      <app-loader *ngIf="isLoadingUpcomingCompetitions"></app-loader>

      <div *ngIf="hasMoreUpcomingCompetitionsToLoad" class="d-flex justify-content-center">
        <button (click)="onLoadMoreUpcomingCompetitions(); false" class="button">Expand</button>
      </div>
    </div>

    <div class="clubs-section" appFragmentFocus id="my" *ngIf="myCompetitions.length">
      <a routerLink="." fragment="my" class="clubs-section-title">My competitions</a>
      <div class="row card-wrap">
        <div
          class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
          *ngFor="let competition of myCompetitions; trackBy: trackBy"
        >
          <app-competition-card [competition]="competition"></app-competition-card>
        </div>
      </div>

      <app-loader *ngIf="isLoadingMyCompetitions"></app-loader>

      <div *ngIf="hasMoreMyCompetitionsToLoad" class="d-flex justify-content-center">
        <button (click)="onLoadMoreMyCompetitions(); false" class="button">Expand</button>
      </div>
    </div>

    <div class="clubs-section" appFragmentFocus id="previous" *ngIf="previousCompetitions.length">
      <a routerLink="." fragment="previous" class="clubs-section-title">Previous competitions</a>
      <div class="row card-wrap">
        <div
          class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
          *ngFor="let competition of previousCompetitions; trackBy: trackBy"
        >
          <app-competition-card [competition]="competition"></app-competition-card>
        </div>
      </div>

      <app-loader *ngIf="isLoadingPreviousCompetitions"></app-loader>

      <div *ngIf="hasMorePreviousCompetitionsToLoad" class="d-flex justify-content-center">
        <button (click)="onLoadMorePreviousCompetitions(); false" class="button">Expand</button>
      </div>
    </div>
  </div>
</div>
