import { Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IGroupChat } from '../../model';

import { ChatFacade } from '../../store/facade';

@Component({
  selector: 'app-chat-info',
  templateUrl: './chat-info.component.html',
  styleUrls: ['./chat-info.component.scss']
})
export class ChatInfoComponent implements OnDestroy {
  @Input() isAdmin: boolean;
  @Input() set channelId(value: number) {
    this._componentDestroy$.next();
    this._channelId$.next(value);

    this.init(value);
  }

  private _channelId$ = new BehaviorSubject<number>(null);
  private _componentDestroy$ = new Subject();

  constructor(private readonly chatFacade: ChatFacade) {}

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  get members$() {
    return this.chatFacade.channelMembers$;
  }

  onRemove(id: number) {
    this.chatFacade.channelMembersEdit(this._channelId$.value, [], [id]);
  }

  memberTrackBy(_index: number, member: { id }) {
    return member.id;
  }

  private init(channelId: number) {
    this.chatFacade.cleanChannelMembers();
    this.chatFacade.channelMembers(channelId);

    this.chatFacade
      .onChannelMembers$()
      .pipe(takeUntil(this._componentDestroy$))
      .subscribe((_) => _);
  }
}
