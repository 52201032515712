import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent implements OnInit {
  @Input() readonly ctx: ITextCtx;

  constructor() {}

  ngOnInit(): void {}
}

export interface ITextCtx {
  readonly placeholder: string;
  readonly control: AbstractControl;
  readonly maxLength: number;
}
