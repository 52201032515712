import { IIcon } from '../../model/icon';

export const comment: IIcon = {
  id: `COMMENT`,
  svg: `
  <g clip-path="url(#clip11)">
      <path fill="#0391FD"
        d="M16.55 6.9v7.28c0 .94-.76 1.7-1.7 1.7H7.6l-3.45 3.8v-3.8H1.7a1.7 1.7 0 01-1.7-1.7V6.9c0-.93.76-1.7 1.7-1.7h13.15c.94 0 1.7.77 1.7 1.7z" />
      <path fill="#4362A5"
        d="M18.3.37H5.15c-.94 0-1.7.76-1.7 1.7v3.12h11.4c.94 0 1.7.77 1.7 1.7v4.17h1.75c.94 0 1.7-.76 1.7-1.7v-7.3c0-.93-.76-1.7-1.7-1.7z" />
      <path fill="#fff"
        d="M3.8 11.75a1.03 1.03 0 100-2.07 1.03 1.03 0 000 2.07zM8.28 11.75a1.03 1.03 0 100-2.07 1.03 1.03 0 000 2.07zM12.76 11.75a1.03 1.03 0 100-2.07 1.03 1.03 0 000 2.07z" />
    </g>
    <defs>
      <clipPath id="clip11">
        <path fill="#fff" d="M0 .02h20v20H0z" />
      </clipPath>
    </defs>`
};
