import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { DocsService } from './docs.service';
import { map, switchMap, filter } from 'rxjs/operators';
import {
  loadDocs,
  loadDocsSuccess,
  addDocs,
  addDocsSuccess
} from './docs.actions';

@Injectable()
export class DocsEffects {
  constructor(private actions$: Actions, private service: DocsService) {}

  readonly load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDocs),
      switchMap(() =>
        this.service.get().pipe(
          filter((val) => !!val),
          map((docs) => loadDocsSuccess({ docs }))
        )
      )
    )
  );

  readonly add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDocs),
      switchMap(({ docs }) =>
        this.service.add(docs).pipe(map((docs) => addDocsSuccess({ docs })))
      )
    )
  );
}
