<img
  *ngIf="photos?.length === 1; else imageWrapper"
  [src]="photos[0].url | baseUrl"
  class="img-fluid post-image"
  (click)="toggleImageFullscreen(0)"
  (load)="onImageRendered()"
  (error)="onImageRendered()"
/>

<ng-template #imageWrapper>
  <div
    class="images-wrapper d-flex flex-wrap"
    [ngClass]="{
    'mod-double': photos.length === 2,
    'mod-triple': photos.length === 3 || photos.length % 3 === 0,
    'mod-mixed-one': photos.length > 3 && photos.length % 3 === 1,
    'mod-mixed-two': photos.length > 3 && photos.length % 3 === 2
  }"
  >
    <div *ngFor="let photo of photos; index as i" class="img-item" (click)="toggleImageFullscreen(i)">
      <img [src]="photo.url | baseUrl" (load)="onImageRendered()" (error)="onImageRendered()" />
    </div>
  </div>
</ng-template>

<app-image-viewer
  *ngIf="imageIndex !== null"
  [images]="photos"
  [index]="imageIndex"
  (close)="toggleImageFullscreen(null)"
></app-image-viewer>
