<div class="add-item-field-wrapper d-flex" [formGroup]="form">
  <div class="d-flex align-items-center align-items-sm-start w-100">
    <mat-form-field class="w-100">
      <mat-label>{{label}}</mat-label>
      <input matInput [placeholder]="label" formControlName="name" />
    </mat-form-field>
    <app-button-plus (click)="submit()"></app-button-plus>
  </div>
</div>

<div class="additional-wrapper d-flex flex-wrap">
  <div class="additional-item d-flex align-items-center" *ngFor="let item of selectedItems; let i=index">
    <button class="close" (click)="removeItem(i)">
      <svg viewBox="0 0 8 8">
        <use href="#close" />
      </svg>
    </button>
    <span (click)="selectItem(i)">{{item.name}}</span>
  </div>
</div>
