<ng-container *appHideForGuest>
  <div class="block right-block">
    <div class="title d-flex">Suggested Profiles</div>
    <div class="right-block-data">
      <app-loader-2 *ngIf="!profiles"></app-loader-2>
      <div class="members-list" *ngIf="profiles">
        <div class="member-item d-flex" *ngFor="let profile of profiles">
          <a [profile]="profile">
            <app-avatar [src]="profile.icon" [placeholder]="profile | initials" class="ava small"></app-avatar>
          </a>
          <div class="data">
            <a [profile]="profile" class="name">{{ profile.name }}</a>
            <div class="position">{{ profile.type_name }}</div>
            <a
              href=""
              class="link ml-auto"
              *ngIf="!profile.is_friend && !profile.friend_request"
              (click)="onProfileConnect(profile.id); false"
              >Connect +</a
            >
            <a
              href=""
              class="link ml-auto"
              *ngIf="!profile.is_friend && profile.friend_request"
              (click)="onProfileDisconnect(profile.id); false"
              >Disconnect</a
            >
            <a href="" class="link ml-auto" *ngIf="profile.is_friend" (click)="onProfileDisconnect(profile.id); false"
              >Disconnect</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
