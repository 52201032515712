import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ClubActions } from '../actions';
import { IClubMediaFile } from '../../model';

export const featureAdapter: EntityAdapter<IClubMediaFile> =
  createEntityAdapter<IClubMediaFile>({
    selectId: (model) => model.id,
    sortComparer: (a: IClubMediaFile, b: IClubMediaFile): number => b.id - a.id
  });

export interface State extends EntityState<IClubMediaFile> {
  isLoading: boolean;
  filesTotalCount: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  filesTotalCount: 0
});

export function Reducer(
  state = initialState,
  action: ClubActions.Actions
): State {
  switch (action.type) {
    case ClubActions.ActionTypes.LOAD_CLUB_MEDIA_FILES:
      return {
        ...initialState,
        isLoading: true
      };
    case ClubActions.ActionTypes.LOAD_CLUB_MEDIA_FILES_SUCCESS:
      return featureAdapter.addMany(action.payload.files, {
        ...state,
        isLoading: false,
        filesTotalCount: action.payload.filesTotalCount
      });
    case ClubActions.ActionTypes.CLUB_UPLOAD_MEDIA_FILE:
      return {
        ...state,
        isLoading: true
      };
    case ClubActions.ActionTypes.CLUB_UPLOAD_MEDIA_FILE_SUCCESS:
      return featureAdapter.addOne(action.payload.file, {
        ...state,
        isLoading: false,
        filesTotalCount: state.filesTotalCount + 1
      });
    default:
      return state;
  }
}

export const isLoadingClubMediaFiles = (state: State) => state.isLoading;
export const { selectAll: getClubMediaFiles } = featureAdapter.getSelectors();
export const getClubMediaFilesAmountOfPages = (state: State) =>
  Math.ceil(state.filesTotalCount / 20);
