import { createAction, props } from '@ngrx/store';

import { SummerProgram, AfterSchool, Job, Activiti } from './eduniverse.model';

export const loadSummerProgram = createAction(
  '[Eduniverse/API] Load Summer Program',
  props<{
    page: number;
    term: string;
    isWeekly?: boolean;
    weeklyStartFrom?: number;
  }>()
);

export const loadSummerProgramSuccess = createAction(
  '[Eduniverse/API] Load Summer Program Success',
  props<{ summercamps: SummerProgram[]; totalCount: number }>()
);

export const loadAfterSchool = createAction(
  '[Eduniverse/API] Load After School Activities',
  props<{
    page: number;
    term: string;
    isWeekly?: boolean;
    weeklyStartFrom?: number;
  }>()
);

export const loadAfterSchoolSuccess = createAction(
  '[Eduniverse/API] Load After School Activities Success',
  props<{ after_school: AfterSchool[]; totalCount: number }>()
);

export const loadActivities = createAction(
  '[Eduniverse/API] Load Activities',
  props<{
    page: number;
    term: string;
    isWeekly?: boolean;
    weeklyStartFrom?: number;
  }>()
);

export const loadActivitiesSuccess = createAction(
  '[Eduniverse/API] Load Activities Success',
  props<{ activities: Activiti[]; totalCount: number }>()
);

export const loadStudentOrganizations = createAction(
  '[Eduniverse/API] Load Student Organizations',
  props<{
    page: number;
    term: string;
    isWeekly?: boolean;
    weeklyStartFrom?: number;
  }>()
);

export const loadStudentOrganizationsSuccess = createAction(
  '[Eduniverse/API] Load Student Organizations Success',
  props<{ organizations: any[]; totalCount: number }>()
);

export const loadJobs = createAction(
  '[Eduniverse/API] Load Jobs',
  props<{
    page: number;
    term: string;
    isWeekly?: boolean;
    weeklyStartFrom?: number;
  }>()
);

export const loadJobsSuccess = createAction(
  '[Eduniverse/API] Load Jobs Success',
  props<{ jobs: Job[]; totalCount: number }>()
);

export const loadArticles = createAction(
  '[Eduniverse/API] Load Articles',
  props<{
    category: string;
    page: number;
    term: string;
    isWeekly?: boolean;
    weeklyStartFrom?: number;
  }>()
);

export const loadArticlesSuccess = createAction(
  '[Eduniverse/API] Load Articles Success',
  props<{ articles: any[]; totalCount: number }>()
);

export const loadCollegeQuestions = createAction(
  '[Eduniverse/API] Load College Questions',
  props<{
    page: number;
    term: string;
    isWeekly?: boolean;
    weeklyStartFrom?: number;
  }>()
);

export const loadCollegeQuestionsSuccess = createAction(
  '[Eduniverse/API] Load College Questions Success',
  props<{ questions: any[]; totalCount: number }>()
);
