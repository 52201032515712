// this.store.dispatch(addImages({ images: [back] }));

import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { IImage } from '../../models/image';
import { addImages, addImagesSuccess } from './images.actions';
import { selectAllImages } from './images.selectors';

@Injectable({
  providedIn: `root`
})
export class ImagesFacade {
  readonly all = this.store.select(selectAllImages);

  constructor(protected store: Store, protected actions: Actions) {}

  public async add(images: File[]): Promise<IImage[]> {
    if (images.length === 0) return []; // #Return#

    this.store.dispatch(addImages({ images }));

    const _images =
      this.actions
        .pipe(
          ofType(addImagesSuccess),
          take(1),
          map(({ images }) => images)
        )
        .toPromise() ?? [];

    const saved = await _images;

    return saved;
  }
}
