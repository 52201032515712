import { IIcon } from '../../model/icon';

export const pin: IIcon = {
  id: `PIN`,
  svg: `
  <path fill="#D8D8D8"
      d="M10 10.345a5.52 5.52 0 01-1.345-.173l.655 9.138c.035.38.31.69.69.69.38 0 .655-.31.69-.69l.655-9.138a5.52 5.52 0 01-1.345.173z" />
    <path fill="#00AB8D"
      d="M15.172 5.172A5.165 5.165 0 0010 0a5.165 5.165 0 00-5.172 5.172A5.165 5.165 0 0010 10.345a5.165 5.165 0 005.172-5.173z" />
    <path fill="#069278"
      d="M6.207 5.517c-.207 0-.345-.138-.345-.345A4.15 4.15 0 0110 1.034c.207 0 .345.138.345.345 0 .207-.138.345-.345.345a3.458 3.458 0 00-3.448 3.448c0 .207-.138.345-.345.345z" />
  
  `
};
