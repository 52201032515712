<div class="dialog-content-overlay">
  <button class="btn-close" (click)="onClose()"></button>
  <h1 mat-dialog-title *ngIf="!isAlreadyCreated">Create competition</h1>
  <h1 mat-dialog-title *ngIf="isAlreadyCreated">Edit {{name}}</h1>
  <div mat-dialog-content>
    <div class="form-wrapper py-0 px-3">
      <form [formGroup]="newCompetition" (ngSubmit)="onSubmit()">
        <div class="block-title"> It's an online competition? </div>
        <mat-radio-group class="mb-3" formControlName="is_online" required>
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="w-100">
          <mat-label>Include the name of competition</mat-label>
          <input
            matInput
            placeholder="Include the name of competition*"
            formControlName="name"
            value="Name"
            required
            cdkFocusInitial
            autocomplete="off"
          />
          <mat-hint>i.e. Elementum pulvinar etiam non quam</mat-hint>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-select formControlName="category_id" placeholder="Choose category" required>
            <mat-option [value]="category.id" *ngFor="let category of categories"> {{ category.title }} </mat-option>
          </mat-select>
          <mat-hint>i.e. STEM or Business</mat-hint>
        </mat-form-field>
        <div class="two-fields">
          <mat-form-field>
            <mat-label>Register by</mat-label>
            <input
              matInput
              (click)="date_start.open(); false"
              (focus)="date_start.open(); false"
              [matDatepicker]="date_start"
              [min]="minDate"
              [max]="maxDate"
              formControlName="date_start"
              autocomplete="off"
              [readonly]="true"
            />
            <mat-datepicker-toggle matSuffix [for]="date_start">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #date_start></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>End of the competition</mat-label>
            <input
              matInput
              (click)="date_end.open(); false"
              (focus)="date_end.open(); false"
              [matDatepicker]="date_end"
              [min]="minDate"
              [max]="maxDate"
              formControlName="date_end"
              autocomplete="off"
              [readonly]="true"
            />
            <mat-datepicker-toggle matSuffix [for]="date_end">
              <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #date_end></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="two-fields" *ngIf="!is_online">
          <app-autocomplete-2 [ctx]="country"></app-autocomplete-2>
          <app-autocomplete-2 [ctx]="city"></app-autocomplete-2>
        </div>
        <app-editor [ctx]="description" class="editor rp-editor"></app-editor>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSubmit(); false" [disabled]="!newCompetition.valid">
      <ng-container *ngIf="!isAlreadyCreated">Create</ng-container>
      <ng-container *ngIf="isAlreadyCreated">Save</ng-container>
    </button>
  </div>
</div>
