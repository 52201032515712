import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { IImage } from '../../models/image';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  constructor(private base: BaseService) {}

  public get() {
    return this.base
      .get('file/fotos')
      .pipe(map((response: any) => response.fotos as IImage[]));
  }

  public add(files: File[]) {
    return from(files).pipe(
      mergeMap((file) => this.addImage(file)),
      toArray()
    );
  }

  protected addImage(file: File) {
    return this.base
      .post('file/add', { file })
      .pipe(map((response: any) => response.foto as IImage));
  }
}
