import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TagsComponent implements OnInit {
  @ViewChild('inputText', { static: true }) inputText: any;
  @Input() ctx: ITagsCtx;
  public input = ``;

  constructor() {}

  ngOnInit(): void {}

  public add() {
    const control = new FormControl(this.input);
    this.ctx.control.push(control);
    this.input = ``;
  }

  public delete(tagIndex: number) {
    this.ctx.control.removeAt(tagIndex);
  }
}

export interface ITagsCtx {
  readonly placeholder: string;
  readonly control: FormArray;
  readonly hint?: string;
}
