import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-download-app-dialog',
  templateUrl: './download-app-dialog.component.html',
  styleUrls: ['./download-app-dialog.component.scss']
})
export class DownloadAppDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DownloadAppDialogComponent>) {}

  ngOnInit(): void {}

  public close(): void {
    this.dialogRef.close();
  }
}
