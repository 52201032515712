import { imagesAdapter, IImagesState, imagesKey } from './images.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const { selectAll } = imagesAdapter.getSelectors();

const selectState = createFeatureSelector<IImagesState>(imagesKey);

export const selectAllImages = createSelector(selectState, (state) =>
  selectAll(state)
);
