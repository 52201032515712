import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { smileys } from './groups/smileys';
import { travel } from './groups/travel';
import { activities } from './groups/activities';
import { animals } from './groups/animals';
import { flags } from './groups/flags';
import { food } from './groups/food';
import { objects } from './groups/objects';
import { people } from './groups/people';
import { symbols } from './groups/symbols';
import { TEmoji } from './model/type';

@Component({
  selector: 'app-emoji',
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.scss']
})
export class MessageEmojiComponent {
  @Output() readonly select = new EventEmitter<string>();

  public hovered: TEmoji = [``, ``];
  public variations: string[];

  readonly groups = [
    [`Smileys`, smileys],
    [`People`, people],
    [`Animals`, animals],
    [`Food`, food],
    [`Objects`, objects],
    [`Activities`, activities],
    [`Symbols`, symbols],
    [`Flags`, flags]
  ];

  public onSelect(emoji: TEmoji) {
    if (emoji[2]) {
      this.variations = [emoji[0], ...emoji[2]];
    } else {
      this.closeVariation();
      this.select.emit(emoji[0]);
    }
  }

  public closeVariation() {
    this.variations = null;
  }
}
