import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  constructor(private base: BaseService) {}

  getRequests(_search: string, page: number) {
    return this.base.allGet(`network/request?page=${page}`).pipe(
      map(({ response }) => ({
        requests: response.request,
        count: response.count
      }))
    );
  }

  acceptRequest(id: number) {
    return this.base.get(`network/add/${id}`).pipe(map((_) => ({ id })));
  }

  rejectRequest(id: number) {
    return this.base.get(`network/reject/${id}`).pipe(map((_) => ({ id })));
  }
}
