import {
  State as NoticeCounterReducerState,
  Reducer as NoticeCounterReducer
} from './notice-counter.reducer';

export { NoticeCounterReducerState as State };

export const noticeCounterFeatureKey = 'notice-counter';

export const reducers = NoticeCounterReducer;
