import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-select',
  template: ``,
  styles: []
})
export class BaseSelectComponent implements ControlValueAccessor, OnDestroy {
  @Input() multiple = true;
  @Input() clearable = true;
  @Input() selectPlaceholder = 'Choose ...';
  itemsArray: any[];
  selectedItems: string[] | string;
  destroy$: Subject<boolean> = new Subject<boolean>();

  private propagateChange: any = () => {};
  private propagateTouch: any = () => {};

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSelect() {
    this.propagateChange(this.selectedItems);
    this.propagateTouch(this.selectedItems);
  }

  writeValue(ids: string | string[] | null): void {
    this.selectedItems = ids;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
}
