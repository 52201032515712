import { IIcon } from '../../model/icon';

export const likeWb: IIcon = {
  id: `LIKE_WB`,
  svg: `
  <g clip-path="url(#clip15)">
      <path fill="#BDBDBD"
        d="M12.99 15.04a5.5 5.5 0 01.83-2.5c.46-.71 1.06-1.33 1.46-2.09.8-1.52 1.17-2.6 1.02-4.32A6.4 6.4 0 0010 .37a6.4 6.4 0 00-6.3 5.76c-.16 1.71.2 2.8 1.02 4.32.4.76 1 1.38 1.45 2.1.48.75.76 1.6.84 2.5h5.98zM12.67 17.04v1.67a1 1 0 01-1 1H8.33a.98.98 0 01-.77-.36 1 1 0 01-.23-.64v-1.67h5.34z" />
      <path fill="#828282"
        d="M9 15.37a.33.33 0 01-.33-.33V9.37h-1a1.33 1.33 0 110-2.66H8c.74 0 1.33.6 1.33 1.33v.67H10a.33.33 0 010 .66h-.67v5.67c0 .18-.15.33-.33.33zm-1.33-8a.67.67 0 100 1.34h1v-.67c0-.37-.3-.67-.67-.67h-.33z" />
      <path fill="#828282"
        d="M11 15.37a.33.33 0 01-.33-.33V9.37H10a.33.33 0 010-.66h.67v-.67c0-.74.6-1.33 1.33-1.33h.33a1.33 1.33 0 110 2.66h-1v5.67c0 .18-.15.33-.33.33zm.33-6.66h1a.67.67 0 100-1.34H12c-.37 0-.67.3-.67.67v.67z" />
      <path fill="#BDBDBD"
        d="M2 7.04H.33a.33.33 0 110-.67H2a.33.33 0 110 .67zM1.63 11.87a.33.33 0 01-.17-.62l1.45-.83a.33.33 0 01.33.58l-1.44.83a.33.33 0 01-.17.04zM18.37 11.87a.33.33 0 01-.17-.04L16.76 11a.33.33 0 11.33-.58l1.45.83a.33.33 0 01-.17.62zM19.67 7.04H18a.33.33 0 110-.67h1.67a.33.33 0 110 .67zM16.93 3.04a.33.33 0 01-.17-.62l1.44-.83a.33.33 0 11.34.57L17.09 3a.33.33 0 01-.16.04zM3.07 3.04A.33.33 0 012.9 3l-1.44-.84a.33.33 0 01.33-.57l1.45.83a.33.33 0 01-.17.62z" />
      <path fill="#828282" d="M7.33 15.04h5.34a1 1 0 110 2H7.33a1 1 0 110-2z" />
    </g>
    <defs>
      <clipPath id="clip15">
        <path fill="#fff" d="M0 .02h20v20H0z" />
      </clipPath>
    </defs>
  `
};
