import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'httpPrefix'
})
export class HTTPPrefixPipe implements PipeTransform {
  transform(value: string): string {
    if (value.startsWith('http://') || value.startsWith('https://')) {
      return value;
    }

    return `https://${value}`;
  }
}
