<div class="dialog-content-overlay">
  <h1 mat-dialog-title>Create your team</h1>
  <div mat-dialog-content>
    <div class="form-wrapper p-0">
      <form [formGroup]="team" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100">
          <mat-label>Team name</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Choose your club or private group</mat-label>
          <mat-select formControlName="groupId" required (valueChange)="onChooseClub($event)">
            <mat-option [value]="club.id" *ngFor="let club of clubs"> {{ club.name }} </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="members-wrapper mb-3" *ngIf="isClubSelected">
          <div class="members-header d-flex align-items-center">
            <div class="icon">
              <svg viewBox="0 0 20 20">
                <use href="#group" />
              </svg>
            </div>
            Add members to team
          </div>

          <mat-form-field class="w-100">
            <mat-label>Filter by name</mat-label>
            <input matInput placeholder="Member name" (input)="onFilterChange($event)" autocomplete="off" />
          </mat-form-field>

          <div class="w-100 text-center" appShowUntilLoaded [onLoaded$]="isLoadingMembers$">
            <app-loader-three-dots></app-loader-three-dots>
          </div>
          <div
            class="members-list"
            infiniteScroll
            [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScrolled()"
          >
            <div class="members-item d-flex align-items-center" *ngFor="let member of groupMembers$ | async">
              <div class="author d-flex align-items-center">
                <div class="avatar rounded-circle">
                  <app-avatar [src]="member.icon" [placeholder]="member | initials" class="img-fluid"> </app-avatar>
                </div>
                <div>
                  <div class="name d-flex align-items-center"> {{ member.name }} </div>
                  <div class="status"> {{ member.type }} </div>
                </div>
              </div>
              <div class="actions">
                <span *ngIf="member.participates; else action" class="text-right ml-2 d-flex flex-column"
                  >Already on team: <br />
                  "{{member.participates}}"</span
                >
                <ng-template #action>
                  <div
                    class="action action-del"
                    *ngIf="isSelectedMember(member.id); else add"
                    (click)="onUnSelectMember(member.id); false"
                  >
                    Delete
                  </div>
                </ng-template>
                <ng-template #add>
                  <div class="action action-add" (click)="onSelectMember(member.id); false"> Add + </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSubmit(); false" [disabled]="!team.valid">To compete</button>
  </div>
</div>
