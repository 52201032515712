<div class="ceil-article-item group-event-item">
  <div class="icon">
    <svg viewBox="0 0 30 30">
      <use href="#event-icon" />
    </svg>
  </div>
  <div class="ceil-content">
    <div class="ceil-text">
      <div class="ceil-title"> {{ event.name }} </div>
      <div class="ceil-description">Online - Zoom, Earth</div>
    </div>
    <div class="ceil-event-time">
      <a href="#">Aug 4, 2020, 1:00 pm - 2:00 pm</a>
    </div>
  </div>
</div>
