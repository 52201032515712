<a [competition]="competition" class="competition-card-wrap theme-block">
  <div class="competition-card-background">
    <div class="competition-card-logo">
      <div class="competition-card-logo-icon">
        <svg viewBox="0 0 30 30">
          <use href="#trophy" />
        </svg>
      </div>
    </div>
  </div>
  <div class="competition-card-data">
    <div class="competition-card-title" [matTooltip]="competition.name">{{ competition.name | textTruncate: 50 }}</div>
    <div class="competition-card-deadline" *ngIf="competition.date_start"
      ><span>Starts:</span>{{ competition.date_start | date: 'dd MMM, yyyy' }}</div
    >
    <div class="competition-card-deadline" *ngIf="competition.date_end"
      ><span>Deadline:</span>{{ competition.date_end | date: 'dd MMM, yyyy' }}</div
    >

    <!-- <a [routerLink]="link" class="competition-card-link button" *ngIf="!!link">Join</a> -->
  </div>
</a>
