import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AppSignUpDialogComponent } from 'src/app/shared/modules/sign-up-dialog/components';
import { AppSignInDialogComponent } from 'src/app/shared/modules/sign-in-dialog/components';

@Component({
  selector: 'app-front-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly _scrolled$ = new BehaviorSubject<boolean>(false);
  private readonly _destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.scrolled()
      .pipe(
        map((_) => this._scrolled$.next(_)),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();

    this._scrolled$.complete();
  }

  get scrolled$() {
    return this._scrolled$;
  }

  onSignin() {
    return this._dialog.open(AppSignInDialogComponent, {
      autoFocus: false
    });
  }

  onSignup() {
    return this._dialog.open(AppSignUpDialogComponent, {
      autoFocus: false
    });
  }

  private scrolled() {
    const offset = 100;

    const window = this._document.defaultView;
    if (!window) {
      return of(false);
    }

    return fromEvent(window, 'scroll').pipe(
      map((_) => window.scrollY - offset > 0)
    );
  }
}
