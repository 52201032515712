<div *ngIf="isUpdatingSelectedCompetition$ | async; else competition">
  <app-loader></app-loader>
</div>

<ng-template #competition>
  <div class="competition-wrap" *ngIf="competition$ | async as competition; else loading">
    <div class="competition-header">
      <div class="competition-header-background"></div>
      <div class="competition-main position-relative">
        <div class="competition-info mb-4 mb-xl-3 d-flex flex-column align-items-center flex-xl-row">
          <div class="competition-logo mr-xl-3">
            <svg viewBox="0 0 30 30"><use href="#trophy"></use></svg>
          </div>
          <div class="competition-title mt-2 mt-xl-4"> {{ competition.name }} </div>
        </div>
        <div
          class="competition-actions mb-3 mb-xl-0 d-flex flex-wrap justify-content-center align-items-center flex-xl-column"
        >
          <ng-container *appHideForAuthorized>
            <button class="button" (click)="onJoinNow(); false">Join now</button>
          </ng-container>

          <ng-container *appHideForGuest>
            <ng-container *ngIf="competition.is_autor">
              <!--              <a href="" class="button" (click)="onUpdateTeams(); false">Update Teams</a>-->
              <!--              <a [routerLink]="['./']" class="button" appIsActiveLink [activeLinkClass]="'alt'"-->
              <!--              >Teams</a>-->
              <!--              <a [routerLink]="['requests']" class="button" appIsActiveLink [activeLinkClass]="'alt'"-->
              <!--              >Requests</a>-->
              <!--              <a-->
              <!--                [routerLink]="['reviews']"-->
              <!--                routerLinkActive="alt"-->
              <!--                class="button"-->
              <!--                appIsActiveLink-->
              <!--                [activeLinkClass]="'alt'"-->
              <!--              >Review Submissions</a>-->
              <button class="button" (click)="onEdit(); false">Edit competition</button>
              <button class="button" (click)="onCreateCompetitionProject(competition); false">+ Create project</button>
              <button class="button" (click)="onUpdateTeams({team : null, competition : competition}); false"
                >Update Teams</button
              >
            </ng-container>
            <ng-container *ngIf="!competition.is_autor">
              <button
                class="button"
                *ngIf="!competition.participant && !competition.participant_request && !competition.team_participant && !competition.team_participant_request"
                (click)="onJoin(); false"
                >To compete</button
              >
              <button
                class="button"
                *ngIf="!competition.team_participant_request && (competition.participant || competition.team_participant)"
                (click)="onSubmitResult(); false"
                #submitWorkBtn
                >Submit your work</button
              >
              <div class="d-flex justify-content-center" *ngIf="competition.team_participant_request">
                In order to see some actions, competition needs to be approved by the administrator user
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="competition-sub-info row">
          <div class="col-4">
            <div class="competition-creator d-flex">
              <div class="competition-creator-avatar mr-2">
                <a [profile]="competition.user">
                  <app-avatar
                    [src]="competition.user.icon"
                    [placeholder]="competition.user | initials"
                    class="img-fluid"
                  ></app-avatar>
                </a>
              </div>
              <div class="competition-creator-info">
                <div class="competition-creator-name">
                  <a [profile]="competition.user">{{ competition.user.name }}</a>
                </div>
                <div class="competition-creator-status">Competition Manager</div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="competition-sub-info-item d-flex flex-wrap">
              <span class="competition-sub-info-item-label">Starts:</span>
              <span>{{ competition.date_start | date: 'dd MMM, yyyy'}}</span>
            </div>
            <div class="competition-sub-info-item d-flex flex-wrap">
              <span class="competition-sub-info-item-label">Deadline:</span>
              <span>{{ competition.date_end | date: 'dd MMM, yyyy'}}</span>
            </div>
          </div>
          <div class="col-4">
            <div class="competition-sub-info-item d-flex flex-wrap">
              <span class="competition-sub-info-item-label">Location:</span>
              <span>
                <app-location
                  [city]="competition.location?.city?.name"
                  [country]="competition.location?.country?.name"
                  [default]="'Online-Zoom, Earth'"
                >
                </app-location>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="competition-menu mb-4">
      <mat-tab-group #tabs animationDuration="0ms">
        <mat-tab label="general" *ngIf="!(isCompetitionJury$ | async)">
          <ng-template matTabLabel> General info </ng-template>
          <router-outlet></router-outlet>
          <div
            class="competition-description ql-editor ql-container theme-block"
            [innerHTML]="competition.description | safeUserInput"
          ></div>
          <div class="competition-section px-2 px-sm-0">
            <div class="competition-section-top">
              <div class="competition-section-title">Projects</div>
            </div>
            <div class="competition-projects-wrap" *ngIf="!(isCompetitionProjectsLoading$ | async); else loading">
              <div class="competition-projects-item" *ngFor="let project of generalTabCompetitionProjects$ | async">
                <div class="competition-projects-item-top">
                  <ng-container *appHideForGuest>
                    <svg
                      viewBox="0 0 14 18"
                      class="competition-projects-item-edit"
                      *ngIf="competition.is_autor"
                      (click)="onCreateCompetitionProject(competition, project); false"
                    >
                      <use href="#sub-edit-post" />
                    </svg>
                  </ng-container>
                  <div class="competition-projects-item-icon">
                    <img
                      src="{{project.icon | appBaseUrl:'assets'}}"
                      [alt]="project.title"
                      *ngIf="project.icon_id; else defaultIcon"
                    />
                  </div>
                </div>
                <div class="competition-projects-item-data">
                  <div class="competition-projects-item-title">{{project.title}}</div>
                  <div class="competition-projects-item-deadline">
                    <span>Deadline: </span>
                    {{project.end_at}}
                  </div>
                  <div class="competition-projects-item-link-wrap">
                    <div class="competition-projects-item-link" (click)="onViewProject(project)">View</div>
                    <div class="competition-projects-item-link" (click)="onJoin()">Participate</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="competition-section-action-wrap">
              <button class="button" (click)="switchToTab(1)">Expand</button>
            </div>
          </div>
          <div class="competition-section px-2 px-sm-0">
            <div class="competition-section-top">
              <div class="competition-section-title">Teams</div>
            </div>
            <div class="competition-teams-wrap" *ngIf="!(isCompetitionTeamsLoading$ | async); else loading">
              <div class="competition-teams-item" *ngFor="let team of confirmedCompetitionTeams$ | async">
                <svg
                  class="competition-teams-remove"
                  viewBox="0 0 8 8"
                  *ngIf="competition.is_autor"
                  (click)="onRemoveTeam(team)"
                >
                  <use href="#close-black" />
                </svg>
                <div class="competition-teams-icon">
                  <app-avatar [placeholder]="{ name: team.name } | initials"></app-avatar>
                </div>
                <div class="competition-teams-name">{{team.name}}</div>
                <div class="competition-teams-members">{{team | teamMembers}}</div>
                <div
                  class="competition-teams-action"
                  *ngIf="competition.is_autor"
                  (click)="onUpdateTeams({team : team, competition: competition})"
                  >Write message</div
                >
              </div>
            </div>
            <div class="competition-section-action-wrap">
              <button class="button" (click)="switchToTab(2)">Expand</button>
            </div>
          </div>
          <div class="competition-section" *ngIf="(competitionParticipants$ | async)?.length">
            <div class="competition-section-top">
              <div class="competition-section-title">Participants</div>
            </div>
            <div class="ceils-content-wrapper business-block">
              <div class="business-block-wrap">
                <app-competition-participants [isAdminView]="false"></app-competition-participants>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="projects" *ngIf="!(isCompetitionJury$ | async)">
          <ng-template matTabLabel> Projects </ng-template>
          <div class="competition-section px-2 px-sm-0">
            <div class="competition-section-top">
              <div class="competition-section-title">Projects</div>
              <div class="tab-search">
                <input
                  #searchProjectInput
                  type="text"
                  placeholder="Search project"
                  [(ngModel)]="searchProjectTerm"
                  (keyup.enter)="onProjectSearch(searchProjectInput.value); false"
                  (change)="onProjectSearch(searchProjectInput.value); false"
                />
                <button (click)="onProjectSearch(searchProjectInput.value)">
                  <svg viewBox="0 0 18 18">
                    <use href="#search" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="competition-projects-wrap" *ngIf="!(isCompetitionProjectsLoading$ | async); else loading">
              <div class="competition-projects-item" *ngFor="let project of (competitionProjects$ | async)">
                <div class="competition-projects-item-top">
                  <ng-container *appHideForGuest>
                    <svg
                      viewBox="0 0 14 18"
                      class="competition-projects-item-edit"
                      *ngIf="competition.is_autor"
                      (click)="onCreateCompetitionProject(competition, project); false"
                    >
                      <use href="#sub-edit-post" />
                    </svg>
                  </ng-container>
                  <div class="competition-projects-item-icon">
                    <img
                      src="{{project.icon | appBaseUrl:'assets'}}"
                      [alt]="project.title"
                      *ngIf="project.icon_id; else defaultIcon"
                    />
                  </div>
                </div>
                <div class="competition-projects-item-data">
                  <div class="competition-projects-item-title">{{project.title}}</div>
                  <div class="competition-projects-item-deadline">
                    <span>Deadline: </span>
                    {{project.end_at}}
                  </div>
                  <div class="competition-projects-item-link-wrap">
                    <div class="competition-projects-item-link" (click)="onViewProject(project)">View</div>
                    <div class="competition-projects-item-link" (click)="onJoin()">Participate</div>
                  </div>
                </div>
              </div>
            </div>
            <app-page-counter
              *ngIf="amountOfProjectsPages$ | async"
              [currentPage]="projectsPage"
              [amountOfPages]="amountOfProjectsPages$ | async"
              (setPage)="onSetProjectsPage($event)"
              class="mt-md-auto"
            >
            </app-page-counter>
          </div>
        </mat-tab>
        <mat-tab label="teams" *ngIf="!(isCompetitionJury$ | async)">
          <ng-template matTabLabel> Teams </ng-template>
          <div class="competition-section px-2 px-sm-0">
            <div class="competition-section-top">
              <div class="competition-section-title">Teams</div>
              <div class="tab-search">
                <input
                  #searchTeamInput
                  type="text"
                  placeholder="Search project"
                  [(ngModel)]="searchTeamTerm"
                  (keyup.enter)="onTeamSearch(searchTeamInput.value); false"
                  (change)="onTeamSearch(searchTeamInput.value); false"
                />
                <button (click)="onTeamSearch(searchTeamInput.value)">
                  <svg viewBox="0 0 18 18">
                    <use href="#search" />
                  </svg>
                </button>
              </div>
            </div>
            <div *ngIf="!(isCompetitionTeamsLoading$ | async); else loading">
              <ng-container *appHideForGuest>
                <ng-container *ngIf="competition.is_autor">
                  <h4 class="font-weight-bold">New Teams</h4>
                  <div class="competition-teams-wrap mb-4">
                    <div class="competition-teams-item" *ngFor="let team of (newCompetitionTeams$ | async)">
                      <svg
                        class="competition-teams-remove"
                        viewBox="0 0 8 8"
                        *ngIf="competition.is_autor"
                        (click)="onRemoveTeam(team)"
                      >
                        <use href="#close-black" />
                      </svg>
                      <div class="competition-teams-icon">
                        <app-avatar [placeholder]="{ name: team.name } | initials"></app-avatar>
                      </div>
                      <div class="competition-teams-name">{{team.name}}</div>
                      <div class="competition-teams-members">{{team | teamMembers}}</div>
                      <ng-container *appHideForGuest>
                        <div
                          class="competition-teams-action"
                          *ngIf="competition.is_autor"
                          (click)="onUpdateTeams({team: team, competition: competition})"
                          >Write message</div
                        >
                        <div class="competition-teams-action-wrap" *ngIf="competition.is_autor">
                          <div class="competition-teams-action" (click)="onViewTeam(team); false">View team</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <h3 class="font-weight-bold">Confirmed Teams</h3>
                  <div class="competition-teams-wrap mb-4">
                    <div class="competition-teams-item" *ngFor="let team of (confirmedCompetitionTeams$ | async)">
                      <svg
                        class="competition-teams-remove"
                        viewBox="0 0 8 8"
                        *ngIf="competition.is_autor"
                        (click)="onRemoveTeam(team)"
                      >
                        <use href="#close-black" />
                      </svg>
                      <div class="competition-teams-icon">
                        <app-avatar [placeholder]="{ name: team.name } | initials"></app-avatar>
                      </div>
                      <div class="competition-teams-name">{{team.name}}</div>
                      <div class="competition-teams-members">{{team | teamMembers}}</div>
                      <ng-container *appHideForGuest>
                        <div
                          class="competition-teams-action"
                          *ngIf="competition.is_autor"
                          (click)="onUpdateTeams({team: team, competition: competition})"
                          >Write message</div
                        >
                        <div class="competition-teams-action-wrap" *ngIf="competition.is_autor">
                          <div class="competition-teams-action" (click)="onViewTeam(team); false">View team</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <h3 class="font-weight-bold">Rejected Teams</h3>
                  <div class="competition-teams-wrap">
                    <div class="competition-teams-item" *ngFor="let team of (rejectedCompetitionTeams$ | async)">
                      <svg
                        class="competition-teams-remove"
                        viewBox="0 0 8 8"
                        *ngIf="competition.is_autor"
                        (click)="onRemoveTeam(team)"
                      >
                        <use href="#close-black" />
                      </svg>
                      <div class="competition-teams-icon">
                        <app-avatar [placeholder]="{ name: team.name } | initials"></app-avatar>
                      </div>
                      <div class="competition-teams-name">{{team.name}}</div>
                      <div class="competition-teams-members">{{team | teamMembers}}</div>
                      <ng-container *appHideForGuest>
                        <div
                          class="competition-teams-action"
                          *ngIf="competition.is_autor"
                          (click)="onUpdateTeams({team: team, competition: competition})"
                          >Write message</div
                        >
                        <div class="competition-teams-action-wrap">
                          <div class="competition-teams-action" (click)="onViewTeam(team); false">View team</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!competition.is_autor">
                  <div class="competition-teams-wrap mb-4">
                    <div class="competition-teams-item" *ngFor="let team of (confirmedCompetitionTeams$ | async)">
                      <div class="competition-teams-icon">
                        <app-avatar [placeholder]="{ name: team.name } | initials"></app-avatar>
                      </div>
                      <div class="competition-teams-name">{{team.name}}</div>
                      <div class="competition-teams-members">{{team | teamMembers}}</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <app-page-counter
              *ngIf="amountOfTeamsPages$ | async"
              [currentPage]="teamsPage"
              [amountOfPages]="amountOfTeamsPages$ | async"
              (setPage)="onSetTeamsPage($event)"
              class="mt-md-auto"
            >
            </app-page-counter>
          </div>
        </mat-tab>
        <ng-container *appHideForGuest>
          <mat-tab label="jury" *ngIf="competition.is_autor">
            <ng-template matTabLabel> Jury </ng-template>
            <div class="competition-section px-2 px-sm-0">
              <div class="competition-section-top">
                <div class="competition-section-title">Jury</div>
                <button class="button" (click)="onUpsertJury()">+ Add jury</button>
              </div>
              <div class="competition-jury-wrap" *ngIf="(competitionJury$ | async) as jury; else loading">
                <table mat-table [dataSource]="jury" class="w-100">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="competition-jury-status-{{element.status}}">
                        {{element.status? 'Confirmed' : 'Unconfirmed'}}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="projects">
                    <th mat-header-cell *matHeaderCellDef> Projects </th>
                    <td mat-cell *matCellDef="let element"> {{element.projects_id.length}} </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      <div class="competition-jury-actions">
                        <a
                          class="competition-jury-actions-edit"
                          *ngIf="element.status"
                          (click)="onNotifyJury(element, competition)"
                          >Notify</a
                        >
                        <a class="competition-jury-actions-edit" (click)="onUpsertJury(element)">Edit</a>
                        <a class="competition-jury-actions-delete" (click)="onDeleteJury(element)">Delete</a>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedJuryColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedJuryColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-tab>
        </ng-container>
        <ng-container *appHideForGuest>
          <mat-tab label="submissions" *ngIf="competition.is_autor">
            <ng-template matTabLabel> Submissions </ng-template>
            <div class="competition-section px-2 px-sm-0">
              <div class="competition-section-top">
                <div class="competition-section-title">Submissions</div>
              </div>
              <div class="competition-jury-wrap" *ngIf="(competitionSubmissions$ | async) as submissions; else loading">
                <table mat-table [dataSource]="submissions" class="w-100">
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element"> {{element.description | textTruncate : 100}} </td>
                  </ng-container>

                  <ng-container matColumnDef="project">
                    <th mat-header-cell *matHeaderCellDef> Project </th>
                    <td mat-cell *matCellDef="let element"> {{element.project.title}} </td>
                  </ng-container>

                  <ng-container matColumnDef="team">
                    <th mat-header-cell *matHeaderCellDef> Team </th>
                    <td mat-cell *matCellDef="let element"> {{element.team?.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">
                      <span class="competition-jury-status-{{element.status}}">
                        {{element.status | submissionStatus}}
                      </span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                      <div class="competition-jury-actions">
                        <a class="competition-jury-actions-edit" (click)="onUpsertSubmission(element)">Edit</a>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedSubmissionsColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedSubmissionsColumns;"></tr>
                </table>
              </div>
            </div>
          </mat-tab>
        </ng-container>
        <mat-tab label="submissions" *ngIf="isCompetitionJury$ | async">
          <ng-template matTabLabel> Submissions </ng-template>
          <div class="competition-section px-2 px-sm-0">
            <div class="competition-section-top">
              <div class="competition-section-title">Submissions</div>
            </div>
            <div class="competition-jury-wrap" *ngIf="(competitionSubmissions$ | async); else loading">
              <table mat-table [dataSource]="(jurySubmission$ | async)" class="w-100">
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef> Description </th>
                  <td mat-cell *matCellDef="let element"> {{element.description | textTruncate:100}} </td>
                </ng-container>

                <ng-container matColumnDef="project">
                  <th mat-header-cell *matHeaderCellDef> Project </th>
                  <td mat-cell *matCellDef="let element"> {{element.project.title}} </td>
                </ng-container>

                <ng-container matColumnDef="team">
                  <th mat-header-cell *matHeaderCellDef> Team </th>
                  <td mat-cell *matCellDef="let element"> {{element.team?.name}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let element">
                    <div class="competition-jury-actions">
                      <a class="competition-jury-actions-edit" (click)="onReviewSubmission(element)">Review</a>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedJurySubmissionsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedJurySubmissionsColumns;"></tr>
              </table>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <!--  <div class="container d-flex flex-column flex-lg-row theme-block">-->
  <!--    <section class="ceils-content-wrapper business-block d-flex flex-column">-->
  <!--      <div class="business-block-wrap" *ngIf="competition$ | async as competition; else loading">-->
  <!--        <div class="competition-data">-->
  <!--          <button-->
  <!--            class="competition-data-edit-btn"-->
  <!--            matTooltip="Edit"-->
  <!--            (click)="onEdit(); false"-->
  <!--            *ngIf="isAllowedToEdit$ | async"-->
  <!--          >-->
  <!--            <svg viewBox="0 0 15 15">-->
  <!--              <use href="#edit-pencil" />-->
  <!--            </svg>-->
  <!--          </button>-->
  <!--          <h1 class="competition-data-title">{{ competition.name }}</h1>-->
  <!--          <div class="competition-data-status">-->
  <!--            <app-location-->
  <!--              [city]="competition.location?.city?.name"-->
  <!--              [country]="competition.location?.country?.name"-->
  <!--              [default]="'Online-Zoom, Earth'"-->
  <!--            >-->
  <!--            </app-location>-->
  <!--          </div>-->
  <!--          <div class="competition-data-date">{{ competition | dateRange }}</div>-->
  <!--          <div class="competition-data-creator d-flex">-->
  <!--            <div class="competition-data-creator-avatar">-->
  <!--              <a [profile]="competition.user">-->
  <!--                <app-avatar-->
  <!--                  [src]="competition.user.icon"-->
  <!--                  [placeholder]="competition.user | initials"-->
  <!--                  class="img-fluid"-->
  <!--                ></app-avatar>-->
  <!--              </a>-->
  <!--            </div>-->
  <!--            <div class="competition-data-creator-info">-->
  <!--              <div class="competition-data-creator-status">Competition Manager</div>-->
  <!--              <div class="competition-data-creator-name">-->
  <!--                <a [profile]="competition.user">{{ competition.user.name }}</a>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--          <div class="competition-data-description ql-editor" [innerHTML]="competition.description | safeUserInput">-->
  <!--          </div>-->

  <!--          <ng-container *appHideForAuthorized>-->
  <!--            <div class="competition-data-actions">-->
  <!--              <div class="d-flex justify-content-center h6">Want to compete ?</div>-->
  <!--              <div class="d-flex justify-content-center">-->
  <!--                <button class="competition-data-action" (click)="onJoinNow(); false">Join now</button>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </ng-container>-->

  <!--          <ng-container *appHideForGuest>-->
  <!--            <div class="competition-data-actions d-flex justify-content-center">-->
  <!--              <div *ngIf="competition.participant_request"> Thanks for registering for the competition </div>-->
  <!--            </div>-->
  <!--            <div *ngIf="isApproved$ | async; else notApproved">-->
  <!--              <div class="competition-data-actions d-flex justify-content-center">-->
  <!--                <button-->
  <!--                  class="competition-data-action"-->
  <!--                  *ngIf="!competition.participant && !competition.participant_request"-->
  <!--                  (click)="onJoin(); false"-->
  <!--                  >To compete</button-->
  <!--                >-->
  <!--                <button-->
  <!--                  class="competition-data-action"-->
  <!--                  *ngIf="competition.participant"-->
  <!--                  (click)="onSubmitResult(); false"-->
  <!--                  #submitWorkBtn-->
  <!--                  >Submit your work</button-->
  <!--                >-->
  <!--              </div>-->
  <!--              <div class="competition-data-actions d-flex justify-content-center" *ngIf="competition.is_autor">-->
  <!--&lt;!&ndash;                <a href="" class="competition-data-action" (click)="onUpdateTeams(); false">Update Teams</a>&ndash;&gt;-->
  <!--                <a [routerLink]="['./']" class="competition-data-action" appIsActiveLink [activeLinkClass]="'alt'"-->
  <!--                  >Teams</a-->
  <!--                >-->
  <!--                <a [routerLink]="['requests']" class="competition-data-action" appIsActiveLink [activeLinkClass]="'alt'"-->
  <!--                  >Requests</a-->
  <!--                >-->
  <!--                <a-->
  <!--                  [routerLink]="['reviews']"-->
  <!--                  routerLinkActive="alt"-->
  <!--                  class="competition-data-action"-->
  <!--                  appIsActiveLink-->
  <!--                  [activeLinkClass]="'alt'"-->
  <!--                  >Review Submissions</a-->
  <!--                >-->
  <!--              </div>-->
  <!--            </div>-->
  <!--              <ng-template #notApproved>-->
  <!--                <div class="d-flex justify-content-center">-->
  <!--                  In order to see some actions, competition needs to be approved by the administrator user-->
  <!--                </div>-->
  <!--              </ng-template>-->
  <!--          </ng-container>-->
  <!--        </div>-->

  <!--&lt;!&ndash;        <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>&ndash;&gt;-->
  <!--      </div>-->
  <!--    </section>-->
  <!--  </div>-->
</ng-template>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>

<ng-template #defaultIcon>
  <svg viewBox="0 0 66 66">
    <use href="#project" />
  </svg>
</ng-template>
