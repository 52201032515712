import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ElementRef
} from '@angular/core';
import { IComment } from '../../../models/comment';
import { ICommentCtx } from '../item/comment.component';
import { Observable } from 'rxjs';
import { TStatus } from '../../../models/status';

// ==================================================
//                   Model
// ==================================================
export interface ICommentsCtx extends ICommentCtx {
  paginate(comments: IComment[], page: number): void;
}
// ==================================================
//                   Component
// ==================================================
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsComponent implements OnInit {
  @Input() readonly ctx: ICommentsCtx;
  @Input() readonly status: TStatus;
  @Input() set comments(comments: IComment[]) {
    if (!comments) return; // #Return#
    this.firstThree = comments.slice(0, 3);
    this.other = comments.slice(3);
  }

  public firstThree: IComment[];
  public other: IComment[];

  public isShowReply = false;
  public isShowMore = false;
  public page = 1;

  constructor(protected hostRef: ElementRef<HTMLElement>) {}

  ngOnInit() {}

  public paginate() {
    if (this.status === `FULL`) return; // #Return#

    this.page += 1;
    this.ctx.paginate([...this.firstThree, ...this.other], this.page);
  }

  public showMore() {
    this.isShowMore = true;
    if (this.other.length === 0) this.paginate();
  }

  public hideMore() {
    this.isShowMore = false;
    setTimeout(() => {
      this.hostRef.nativeElement.scrollIntoView({
        behavior: `smooth`
      });
    });
  }
}
