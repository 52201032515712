<div class="other-block block" *ngIf="user && (user.type === 'student' || user.type === 'teacher')">
  <div class="title">RP Programs & Competitions</div>
  <div class="items">
    <div class="item d-flex align-items-center">
      <div class="item-data d-flex align-items-center">
        <div class="item-icon">
          <svg viewBox="0 0 20 20">
            <use href="#startup" />
          </svg>
        </div>
        <a routerLink="/article/roundpier_student_coalition" target="_blank" class="item-title">
          RP Student Coalition
        </a>
      </div>
    </div>
    <div class="item d-flex align-items-center">
      <div class="item-data d-flex align-items-center">
        <div class="item-icon">
          <svg viewBox="0 0 20 20">
            <use href="#startup" />
          </svg>
        </div>
        <a routerLink="/article/ambassador_program" target="_blank" class="item-title"> RP Ambassador Program </a>
      </div>
    </div>
    <div class="item d-flex align-items-center">
      <div class="item-data d-flex align-items-center">
        <div class="item-icon">
          <svg viewBox="0 0 20 20">
            <use href="#startup" />
          </svg>
        </div>
        <!--        <a routerLink="/entrepreneurshipcompetition" target="_blank" class="item-title">-->
        <!--          RP Entrepreneurship Competition-->
        <!--        </a>-->
        <a
          routerLink="/discussions/514/rp-annual-entrepreneurship-and-nonprofit-competition-2021"
          target="_blank"
          class="item-title"
        >
          RP Entrepreneurship Competition
        </a>
      </div>
    </div>
    <div class="item d-flex align-items-center">
      <div class="item-data d-flex align-items-center">
        <div class="item-icon">
          <svg viewBox="0 0 20 20">
            <use href="#startup" />
          </svg>
        </div>
        <a routerLink="/nonprofit_competition_for_high_school_students" target="_blank" class="item-title">
          RP Nonprofit Competition
        </a>
      </div>
    </div>
  </div>
</div>
