import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { CompetitionsActions } from '../actions';
import { ICompetition } from '../../model';

export const featureAdapter: EntityAdapter<ICompetition> =
  createEntityAdapter<ICompetition>({
    selectId: (model) => model.id,
    sortComparer: (a: ICompetition, b: ICompetition): number => b.id - a.id
  });

export interface State extends EntityState<ICompetition> {
  lastLoadedPage: number;
  totalAmount: number;
  isLoading?: boolean;
  error?: Error;
}

export const initialState: State = featureAdapter.getInitialState({
  lastLoadedPage: 0,
  totalAmount: null,
  isLoading: false,
  error: null
});

export function Reducer(
  state = initialState,
  action: CompetitionsActions.Actions
): State {
  switch (action.type) {
    case CompetitionsActions.ActionTypes.LOAD_PREVIOUS_COMPETITIONS:
      return {
        ...state,
        isLoading: true
      };
    case CompetitionsActions.ActionTypes.LOAD_PREVIOUS_COMPETITIONS_SUCCESS:
      return featureAdapter.addMany(action.payload.competitions, {
        ...state,
        lastLoadedPage: action.payload.page,
        totalAmount: action.payload.totalAmount,
        isLoading: false,
        error: null
      });
    default:
      return state;
  }
}

export const { selectAll: selectCompetitions } = featureAdapter.getSelectors();

export const isLoading = (state: State) => state.isLoading;
export const hasMoreCompetitionsToLoad = (state: State) =>
  state.ids.length < state.totalAmount;
export const lastLoadedPage = (state: State) => state.lastLoadedPage;
