import { CompetitionActions } from '../actions';

import {
  ICompetition,
  ICompetitionGroup,
  ICompetitionJury,
  ICompetitionMember,
  ICompetitionParticipant,
  ICompetitionRequest,
  ICompetitionReview,
  ICompetitionTeam
} from '../../model';

const competitionTeamsPerPage = 20;

export interface State {
  competition: ICompetition;
  updatingCompetition: boolean;
  participants: ICompetitionParticipant[];
  participantsTotalAmount: number;
  requests: ICompetitionRequest[];
  requestsTotalAmount: number;
  reviews: ICompetitionReview[];
  reviewsTotalAmount: number;
  teams: ICompetitionTeam[];
  teamsTotalAmount: number;
  teamsIsLoading: boolean;
  groupsMembers: ICompetitionMember[];
  groupsMembersTotalAmount: number;
  groupsMembersIsLoading: boolean;
  myGroups: ICompetitionGroup[];
  jury: ICompetitionJury[];
  juryTotalAmount: number;
  juryIsLoading: boolean;
}

const initialState: State = {
  competition: null,
  updatingCompetition: false,
  participants: null,
  participantsTotalAmount: null,
  requests: null,
  requestsTotalAmount: null,
  reviews: null,
  reviewsTotalAmount: null,
  teams: null,
  teamsTotalAmount: null,
  teamsIsLoading: null,
  groupsMembers: null,
  groupsMembersTotalAmount: null,
  groupsMembersIsLoading: false,
  myGroups: null,
  jury: null,
  juryTotalAmount: null,
  juryIsLoading: null
};

export function Reducer(
  state = initialState,
  action: CompetitionActions.Actions
): State {
  switch (action.type) {
    case CompetitionActions.ActionTypes.LOAD_COMPETITION:
      return initialState;
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_REQUESTS:
      return {
        ...state,
        requests: null
      };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_REVIEWS:
      return {
        ...state,
        reviews: null
      };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_PARTICIPANTS:
      return {
        ...state,
        participants: null
      };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_SUCCESS:
      return {
        ...state,
        competition: action.payload.competition
      };
    case CompetitionActions.ActionTypes.JOIN_COMPETITION_SUCCESS:
      if (!state.competition) {
        return state;
      }

      return {
        ...state,
        competition: {
          ...state.competition,
          participant_request: true
        }
      };
    case CompetitionActions.ActionTypes.LEAVE_COMPETITION_SUCCESS:
      if (!state.competition) {
        return state;
      }

      return {
        ...state,
        competition: {
          ...state.competition,
          participant_request: false,
          participant: false
        }
      };
    case CompetitionActions.ActionTypes.SUBMIT_COMPETITION_RESULT_SUCCESS:
      // TODO modify it
      return {
        ...state
      };
    // if (!state.competition) {
    //   return state;
    // }
    //
    // return {
    //   ...state,
    //   reviews: [action.payload.result, ...(state.reviews || [])],
    //   reviewsTotalAmount: state.reviewsTotalAmount + 1
    // };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload.requests,
        requestsTotalAmount: action.payload.totalAmount
      };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload.reviews,
        reviewsTotalAmount: action.payload.totalAmount
      };
    case CompetitionActions.ActionTypes.UPDATE_COMPETITION_RESULT_SUCCESS:
    case CompetitionActions.ActionTypes.RATE_COMPETITION_RESULT_SUCCESS:
      const reviews = [...state.reviews];
      const reviewIndex = reviews.findIndex(
        (item) => item.id === action.payload.submission.id
      );
      reviews[reviewIndex] = action.payload.submission;

      return {
        ...state,
        reviews
      };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.payload.participants,
        participantsTotalAmount: action.payload.totalAmount
      };
    case CompetitionActions.ActionTypes.ACCEPT_COMPETITION_REQUEST_SUCCESS:
    case CompetitionActions.ActionTypes.DECLINE_COMPETITION_REQUEST_SUCCESS:
      const requests = state.requests.filter(
        ({ id }) => id !== action.payload.groupID
      );

      return {
        ...state,
        requests,
        requestsTotalAmount: state.requestsTotalAmount - 1
      };
    case CompetitionActions.ActionTypes.REMOVE_COMPETITION_PARTICIPANT_SUCCESS:
      const participants = state.participants.filter(
        ({ id }) => id !== action.payload.groupID
      );

      return {
        ...state,
        participants
      };
    case CompetitionActions.ActionTypes.UPDATE_COMPETITION:
      return {
        ...state,
        updatingCompetition: true
      };
    case CompetitionActions.ActionTypes.UPDATE_COMPETITION_SUCCESS:
      return {
        ...state,
        competition: action.payload.competition,
        updatingCompetition: false
      };
    case CompetitionActions.ActionTypes.UPDATE_COMPETITION_FAILURE:
      return {
        ...state,
        updatingCompetition: false
      };
    case CompetitionActions.ActionTypes.ADD_COMPETITION_TEAM_SUCCESS:
      return {
        ...state,
        teams: [action.payload.team, ...(state.teams || [])],
        competition: { ...state.competition, team_participant: true }
      };
    case CompetitionActions.ActionTypes.REJECT_COMPETITION_TEAM:
    case CompetitionActions.ActionTypes.CONFIRM_COMPETITION_TEAM:
    case CompetitionActions.ActionTypes.REMOVE_COMPETITION_TEAM:
      return {
        ...state,
        teamsIsLoading: true
      };
    case CompetitionActions.ActionTypes.REJECT_COMPETITION_TEAM_FAILURE:
    case CompetitionActions.ActionTypes.CONFIRM_COMPETITION_TEAM_FAILURE:
    case CompetitionActions.ActionTypes.REMOVE_COMPETITION_TEAM_FAILURE:
      return {
        ...state,
        teamsIsLoading: false
      };
    case CompetitionActions.ActionTypes.REJECT_COMPETITION_TEAM_SUCCESS:
    case CompetitionActions.ActionTypes.CONFIRM_COMPETITION_TEAM_SUCCESS:
      const teams = (state.teams || []).filter(
        (team) => team.id !== action.payload.team.id
      );
      return {
        ...state,
        teams: [action.payload.team, ...teams],
        teamsIsLoading: false
      };
    case CompetitionActions.ActionTypes.REMOVE_COMPETITION_TEAM_SUCCESS:
      const filteredTeams = (state.teams || []).filter(
        (team) => team.id !== action.payload.team.id
      );
      return {
        ...state,
        teamsIsLoading: false,
        teamsTotalAmount: state.teamsTotalAmount - 1,
        teams: [...filteredTeams]
      };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_TEAMS:
      return {
        ...state,
        teamsIsLoading: true
      };
    case CompetitionActions.ActionTypes.LOAD_COMPETITION_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload.teams,
        teamsTotalAmount: action.payload.teamsTotalAmount,
        teamsIsLoading: false
      };
    case CompetitionActions.ActionTypes.FIND_MEMBERS:
      return {
        ...state,
        groupsMembers: [],
        groupsMembersTotalAmount: null,
        groupsMembersIsLoading: true
      };
    case CompetitionActions.ActionTypes.FIND_MEMBERS_SUCCESS:
      return {
        ...state,
        groupsMembers: action.payload.members,
        groupsMembersTotalAmount: action.payload.membersTotalAmount,
        groupsMembersIsLoading: false
      };
    case CompetitionActions.ActionTypes.LOAD_MY_GROUPS_SUCCESS:
      return {
        ...state,
        myGroups: action.payload.groups
      };
    case CompetitionActions.ActionTypes.LOAD_JURY:
      return {
        ...state,
        jury: [],
        juryIsLoading: true
      };
    case CompetitionActions.ActionTypes.LOAD_JURY_SUCCESS:
      return {
        ...state,
        jury: action.payload.jury,
        juryIsLoading: false
      };
    case CompetitionActions.ActionTypes.ADD_JURY_SUCCESS:
    case CompetitionActions.ActionTypes.EDIT_JURY_SUCCESS:
      const index = state.jury.findIndex(
        (_) => _.id === action.payload.jury.id
      );
      const newJury = [...(state.jury || [])];
      if (index > -1) {
        newJury[index] = action.payload.jury;
      } else {
        newJury.unshift(action.payload.jury);
      }
      return {
        ...state,
        jury: newJury
      };
    case CompetitionActions.ActionTypes.DELETE_JURY_SUCCESS:
      return {
        ...state,
        jury: state.jury.filter((_) => _.id !== action.payload.jury.id)
      };
    default:
      return state;
  }
}

export const getSelectedCompetition = (state: State) => state.competition;
export const isUpdatingSelectedCompetition = (state: State) =>
  state.updatingCompetition;
export const getSelectedCompetitionParticipants = (state: State) =>
  state.participants;
export const getSelectedCompetitionParticipantsTotalAmount = (state: State) =>
  state.participantsTotalAmount;
export const getSelectedCompetitionRequests = (state: State) => state.requests;
export const getSelectedCompetitionRequestsTotalAmount = (state: State) =>
  state.requestsTotalAmount;
export const getSelectedCompetitionReviews = (state: State) => state.reviews;
export const getSelectedCompetitionReviewsTotalAmount = (state: State) =>
  state.reviewsTotalAmount;
export const getSelectedCompetitionTeams = (state: State) => state.teams;
export const getSelectedCompetitionTeamsTotalAmount = (state: State) =>
  state.teamsTotalAmount;
export const getSelectedCompetitionTeamsPagesAmount = (state: State) =>
  Math.ceil(state.teamsTotalAmount / competitionTeamsPerPage);
export const getSelectedCompetitionTeamsIsLoading = (state: State) =>
  state.teamsIsLoading;
export const getSelectedCompetitionGroupsMembers = (state: State) =>
  state.groupsMembers;
export const getSelectedCompetitionGroupsMembersTotalAmount = (state: State) =>
  state.groupsMembersTotalAmount;
export const getSelectedCompetitionGroupsMembersIsLoading = (state: State) =>
  state.groupsMembersIsLoading;
export const getSelectedCompetitionMyGroups = (state: State) => state.myGroups;
export const getSelectedCompetitionJury = (state: State) => state.jury;
