import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { CompetitionsStoreModule } from 'src/app/modules/competitions/store';
import { ChatStoreModule } from 'src/app/modules/chat/store';
import { PostStoreModule } from 'src/app/modules/posts/store';
import { NoticeCounterStoreModule } from 'src/app/modules/notice-counter/store';
import { ProjectsStoreModule } from 'src/app/modules/projects/store';
import { RegistrationStoreModule } from 'src/app/modules/registration/store';

// TODO: eliminate this root-store

@NgModule({
  imports: [
    CommonModule,
    CompetitionsStoreModule,
    ChatStoreModule,
    PostStoreModule,
    NoticeCounterStoreModule,
    ProjectsStoreModule,
    RegistrationStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    })
  ],
  declarations: []
})
export class RootStoreModule {}
