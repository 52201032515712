import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError, of } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  share,
  switchMap,
  tap
} from 'rxjs/operators';
import { AuthService } from './auth.service';
import { FormControl } from '@angular/forms';
import { IAutocompleteCtx } from '../../shared/form';
import { IOption } from '../../registration/organization/organization.service';
import { ajax } from 'rxjs/ajax';

interface ServerAnswer {
  code: number;
  status: 'ok' | 'error';
  response: any;
  message?: { text: string; type: string };
}

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  endpoint: string = `${environment.endpoint}${environment.prefix}`;

  constructor(protected httpClient: HttpClient) {}

  private getUrl(path: string | number = null): string {
    if (path) {
      return `${this.endpoint}${path}`;
    } else {
      return `${this.endpoint}`;
    }
  }

  get(path: string): Observable<any> {
    return this.httpClient
      .get(this.getUrl(path))
      .pipe(
        switchMap((responseData: ServerAnswer) => {
          if (responseData.code === 401) {
            return throwError({
              error: { code: 401, description: responseData.status }
            });
          }

          if (responseData.code === 403) {
            return throwError({
              error: { code: 403, description: responseData.status }
            });
          }

          if (!responseData.response) {
            return throwError({
              error: { code: 400, description: 'Internal error' }
            });
          }

          return of(responseData.response);
        })
      )
      .pipe(share());
  }

  allGet(path: string): Observable<any> {
    return this.httpClient
      .get(this.getUrl(path))
      .pipe(
        map((responseData: ServerAnswer) => {
          if (responseData.code >= 400) {
            throw new Error(
              (responseData.message && responseData.message.text) ||
                'Internal error'
            );
          }
          return responseData;
        })
      )
      .pipe(share());
  }

  post(path: string, data: any): Observable<any> {
    return this.httpClient
      .post(this.getUrl(path), this.convertModelToFormData(data))
      .pipe(
        map((responseData: ServerAnswer) => {
          if (responseData.code >= 400 || responseData.status === 'error') {
            if (
              responseData &&
              responseData.message &&
              responseData.message.text
            ) {
              alert(
                responseData &&
                  responseData.message &&
                  responseData.message.text
              );
            }
            throw new Error(
              `Error with code ${responseData.code}: ${responseData.status}`
            );
            // throwError(new Error(responseData && responseData.message && responseData.message.text));
          }
          return responseData.response;
        })
      );
  }

  convertModelToFormData(data = {}, form = null, namespace = '') {
    let files = {};
    let model = {};
    for (let propertyName in data) {
      if (
        data.hasOwnProperty(propertyName) &&
        data[propertyName] instanceof File
      ) {
        files[propertyName] = data[propertyName];
      } else {
        model[propertyName] = data[propertyName];
      }
    }

    model = JSON.parse(JSON.stringify(model));
    let formData: any = form || new FormData();

    for (let propertyName in model) {
      if (
        !model.hasOwnProperty(propertyName) ||
        model[propertyName] === undefined
      )
        continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof File) {
        formData.append(formKey, model[propertyName]);
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          if (typeof element === 'object')
            this.convertModelToFormData(element, formData, tempFormKey);
          else formData.append(tempFormKey, element.toString());
        });
      } else if (
        typeof model[propertyName] === 'object' &&
        !(model[propertyName] instanceof File)
      )
        this.convertModelToFormData(model[propertyName], formData, formKey);
      else if (typeof model[propertyName] === 'boolean') {
        formData.append(formKey, model[propertyName] ? 1 : 0);
      } else {
        formData.append(formKey, model[propertyName].toString());
      }
    }

    for (let propertyName in files) {
      if (files.hasOwnProperty(propertyName)) {
        formData.append(propertyName, files[propertyName]);
      }
    }

    return formData;
  }

  getFields(path: string): Observable<any> {
    return this.httpClient
      .get(this.getUrl(`../get/field/${path}`))
      .pipe(
        switchMap((responseData: ServerAnswer) => {
          if (responseData.code === 403) {
            return throwError({
              error: { code: 403, description: responseData.status }
            });
          }

          if (!responseData) {
            return throwError({
              error: { code: 400, description: 'Internal error' }
            });
          }

          return of(responseData);
        })
      )
      .pipe(share());
  }
}
