import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-connected-error-dialog',
  templateUrl: './account-connected-error-dialog.component.html',
  styleUrls: ['./account-connected-error-dialog.component.scss']
})
export class AccountConnectedErrorDialogComponent implements OnDestroy {
  static FRAGMENT_ID = 'account_connected_error';

  constructor(
    private readonly _dialogRef: MatDialogRef<AccountConnectedErrorDialogComponent>
  ) {}

  ngOnDestroy(): void {
    if (
      window.location.hash ===
      `#${AccountConnectedErrorDialogComponent.FRAGMENT_ID}`
    ) {
      window.location.hash = '';
    }
  }

  onClose() {
    this._dialogRef.close();
  }
}
