import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import { Contact } from './contact.model';
import * as ContactActions from './contacts.actions';

const AMOUNT_OF_CONTACTS_PER_PAGE = 20;

export const contactsFeatureKey = 'contacts';

export interface State {
  contacts: { [id: number]: Contact };
  isLoadingContacts: boolean;
  nextPage: number;
}

export const initialState: State = {
  contacts: null,
  isLoadingContacts: false,
  nextPage: 1
};

export const reducer = createReducer(
  initialState,
  on(ContactActions.clearState, (state, action): State => {
    return initialState;
  }),
  on(ContactActions.loadContacts, (state, action): State => {
    return {
      ...state,
      contacts: {
        ...state.contacts
      },
      isLoadingContacts: true
    };
  }),
  on(ContactActions.loadContactsSuccess, (state, action): State => {
    const nextPage = state.nextPage + 1;

    return {
      ...state,
      contacts: {
        ...state.contacts,
        ...action.contacts
      },
      isLoadingContacts: false,
      nextPage:
        Object.keys(action.contacts).length >= AMOUNT_OF_CONTACTS_PER_PAGE
          ? nextPage
          : state.nextPage
    };
  })
);

export const selectState = createFeatureSelector<State>(contactsFeatureKey);

export const selectContacts = () =>
  createSelector(selectState, (state: State) => {
    if (!state.contacts) {
      return null;
    }

    return Object.values(state.contacts);
  });

export const isLoadingContacts = () =>
  createSelector(selectState, (state: State) => state.isLoadingContacts);

export const contactsNextPageToLoad = () =>
  createSelector(selectState, (state: State) => state.nextPage);
