import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';

import { environment } from 'src/environments/environment';

import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly _token$ = new BehaviorSubject<null | string>(this._token());

  private readonly _status$ = new BehaviorSubject<TokenService.TokenStatus>(
    TokenService.TokenStatus.UNDEFINED
  );

  private readonly _destroy$ = new Subject();

  constructor(
    private readonly _cookieService: CookieService,
    private readonly _platformService: PlatformService
  ) {
    this._platformService.isPlatformBrowser() &&
      this.monitorToken$()
        .pipe(takeUntil(this._destroy$))
        .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get token() {
    return this._token$.value;
  }

  get token$() {
    return this._token$.pipe(distinctUntilChanged(), takeUntil(this._destroy$));
  }

  get status() {
    return this._status$.value;
  }

  get status$() {
    return this._status$.pipe(
      distinctUntilChanged(),
      takeUntil(this._destroy$)
    );
  }

  setToken(
    token: string,
    status: TokenService.TokenStatus,
    isRememberMe: boolean
  ): void {
    this._status$.next(TokenService.TokenStatus.CHANGING_TOKEN);

    this._setToken(token, isRememberMe);

    this._token$.next(token);
    this._status$.next(status);
  }

  removeToken(): void {
    this._status$.next(TokenService.TokenStatus.CHANGING_TOKEN);

    this._removeToken();

    this._token$.next(null);
    this._status$.next(TokenService.TokenStatus.LOGGED_OUT);
  }

  setTokenStatus(status: TokenService.TokenStatus) {
    this._status$.next(status);
  }

  private monitorToken$(): Observable<void> {
    return interval(500).pipe(
      map(() => this._token()),
      distinctUntilChanged(),
      map((_) => {
        if (this._token$.value === _) {
          return;
        }

        this._status$.next(TokenService.TokenStatus.CHANGING_TOKEN);
        this._token$.next(_);
        this._status$.next(TokenService.TokenStatus.UNDEFINED);

        return;
      })
    );
  }

  private _token(): null | string {
    return this._cookieService.get(environment.tokenName) || null;
  }

  private _setToken(token: string, isRememberMe: boolean): void {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    const options: { expires?: Date; domain?: string; path: string } = {
      expires: isRememberMe ? oneYearFromNow : undefined,
      path: '/'
    };

    if (environment.domain) {
      options.domain = environment.domain;
    }

    this._cookieService.put(environment.tokenName, token, options);
  }

  private _removeToken(): void {
    const options: { domain?: string; path: string } = {
      path: '/'
    };

    if (environment.domain) {
      options.domain = environment.domain;
    }

    this._cookieService.remove(environment.tokenName, options);
  }
}

export namespace TokenService {
  export enum TokenStatus {
    UNDEFINED = 0,
    // NOTE: to handle case when we changed token, but status is still old
    CHANGING_TOKEN = 1,
    LOGGED_OUT = 2,
    PARTIAL_SIGNUP = 3,
    FULL_SIGNUP = 4
  }
}
