import { IIcon } from '../../model/icon';

export const menuDots: IIcon = {
  id: `MENU_DOTS`,
  svg: `
  <g clip-path="url(#clip5)" fill="#BDBDBD">
  <path
    d="M2.4 12.4a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8zM17.6 12.4a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8zM10 12.4a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z" />
</g>
<defs>
  <clipPath id="clip5">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>`
};
