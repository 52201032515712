import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SharedModule } from 'src/app/shared/shared.module';
import { AppLoaderModule } from 'src/app/shared/modules';
import { ClubsStoreModule } from 'src/app/modules/clubs/store';

import Components from './components';
import Dialogs from './dialogs';
import Pipes from './pipes';
import Services from './services';

const COMPONENTS = [...Components, ...Dialogs];

const SERVICES = [...Services];

const PIPES = [...Pipes];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES],
  imports: [
    CommonModule,
    AppLoaderModule,
    SharedModule,
    InfiniteScrollModule,
    ClubsStoreModule
  ],
  exports: [...COMPONENTS, ...PIPES],
  providers: [...SERVICES]
})
export class CompetitionsSharedModule {}
