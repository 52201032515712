import { createAction, props } from '@ngrx/store';

import { Dialog, Message } from './dialog.model';

export const loadDialogs = createAction('[Dialogs/API] Load Dialogs');

export const loadMoreDialogs = createAction(
  '[Dialogs/API] Load More Dialogs',
  props<{ page: number }>()
);

export const loadDialogsSuccess = createAction(
  '[Dialogs/API] Load Dialogs Success',
  props<{ dialogs: Dialog[] }>()
);

export const updateDialogs = createAction(
  '[Dialogs/API] Update Dialogs',
  props<{ page: number }>()
);

export const updateDialogsSuccess = createAction(
  '[Dialogs/API] Update Dialogs Success',
  props<{ dialogs: Dialog[] }>()
);

export const readDialog = createAction(
  '[Dialogs/API] Read Dialog',
  props<{ id: number }>()
);

export const readDialogSuccess = createAction(
  '[Dialogs/API] Read Dialog Success',
  props<{ id: number }>()
);

export const loadDialogSuccess = createAction(
  '[Dialogs/API] Load Dialog Success',
  props<{ messages: Message[] }>()
);

export const sendMessage = createAction(
  '[Dialogs/API] Send Message',
  props<{ id: number; message: Message }>()
);

export const sendMessageSuccess = createAction(
  '[Dialogs/API] Send Message Success',
  props<{ message: Message }>()
);

export const countNewDialogs = createAction(
  '[Dialogs/API] Count New Dialogs',
  props<{ count: number }>()
);

export const countNewDialogsSuccess = createAction(
  '[Dialogs/API] Count New Dialogs Success',
  props<{ count: number }>()
);

export const newMessage = createAction(
  '[Dialogs/API] New Message',
  props<{ dialog: any; message: Message }>()
);

export const newMessageSuccess = createAction(
  '[Dialogs/API] New Message Success',
  props<{ dialog: Dialog; message: Message }>()
);

export const loadMessages = createAction(
  '[Dialogs/API] Load Messages',
  props<{ dialog_id: number; page: number }>()
);

export const loadMessagesSuccess = createAction(
  '[Dialogs/API] Load Messages Success',
  props<{ nextPageToLoad: number; dialog: Dialog; messages: Message[] }>()
);

export const resetNextPageToLoad = createAction(
  '[Dialogs/API] Reset Next Page To Load'
);
