import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ICompetitionResult } from '../../../model';

@Component({
  selector: 'app-competition-submit-result',
  templateUrl: './competition-submit-result.component.html',
  styleUrls: ['./competition-submit-result.component.scss']
})
export class CompetitionSubmitResultComponent implements OnInit, OnDestroy {
  readonly ASSETS = CompetitionWritePostComponent.ASSETS;

  readonly images = {
    upload: new Subject<void>(),
    uploaded: new Subject<number[]>()
  };

  readonly docs = {
    upload: new Subject<void>(),
    uploaded: new Subject<number[]>()
  };

  readonly fields = {
    project: new FormControl(null, Validators.required),
    message: new FormControl('', Validators.required)
  };

  submitResultForm = new FormGroup(this.fields);
  selectedAsset: CompetitionWritePostComponent.ASSETS = null;

  private readonly _isSending$ = new BehaviorSubject(false);
  private readonly _componentDestroy$ = new Subject();

  constructor(
    private dialogRef: MatDialogRef<
      CompetitionSubmitResultComponent,
      ICompetitionResult
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: { projects: { id: number; title: string }[] }
  ) {}

  ngOnInit() {
    forkJoin([
      this.docs.uploaded.asObservable(),
      this.images.uploaded.asObservable()
    ])
      .pipe(
        takeUntil(this._componentDestroy$),
        map(([docs, images]) => ({
          projectID: this.fields.project.value,
          message: this.submitResultForm.value.message,
          docs,
          images
        })),
        tap((_) => this._isSending$.next(false)),
        map((_) => this.dialogRef.close({ ..._ }))
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  get projects() {
    return this.data?.projects;
  }

  get isSending$() {
    return this._isSending$;
  }

  onSelectAsset(selectedAsset: CompetitionWritePostComponent.ASSETS) {
    this.selectedAsset =
      this.selectedAsset === selectedAsset ? null : selectedAsset;
  }

  onSend() {
    if (!this.submitResultForm.value.message) {
      return;
    }

    this._isSending$.next(true);

    this.docs.upload.next();
    this.images.upload.next();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

export namespace CompetitionWritePostComponent {
  export enum ASSETS {
    DOCS,
    IMAGES
  }
}
