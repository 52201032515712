import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';
import { AuthService } from '../auth.service';
import { ConvertIdService } from '../convert-id.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {
  constructor(
    private base: BaseService,
    private convertIdService: ConvertIdService,
    private auth: AuthService
  ) {}

  getJobById(id: string) {
    const _id = Number(id);
    const url = this.auth.isFullyAuthorized ? 'jobs/view' : 'guest/job';

    return this.base
      .get(
        `${url}/` +
          (Number.isSafeInteger(_id)
            ? _id
            : this.convertIdService.convertIdStringToNumber(id))
      )
      .pipe(map((response: any) => ({ job: response.job })));
  }
}
