<div class="dialog-content-overlay">
  <button type="button" class="btn-close" (click)="onClose(); false;"></button>
  <div class="modal-title mt-4"> Log in </div>
  <form class="form mx-auto mt-3 mb-4" [formGroup]="form" (ngSubmit)="onSubmitForm()">
    <div class="form-el">
      <input type="email" name="email" formControlName="email" placeholder="Email" />
    </div>
    <div class="form-el">
      <input type="password" name="password" formControlName="password" placeholder="Password" />
    </div>
    <div class="d-flex justify-content-between">
      <div class="form-el">
        <label class="input-checkbox">
          <input type="checkbox" formControlName="remember" />
          <span>Remember me</span>
        </label>
      </div>
      <div class="form-el">
        <a (click)="onOpenForgotDialog(); false;" [amplitude]="'/forgot-password'"> Forgot password? </a>
      </div>
    </div>
    <div class="form-el">
      <button class="button button-big" [disabled]="form.invalid" [amplitude]="'/login'">Log in</button>
    </div>
    <div class="text">or</div>
    <div class="alt-btn-wrap d-flex">
      <button class="button button-big alt alt-btn-item" (click)="onAppleSignin(); false;" [amplitude]="'/login-apple'">
        Continue with
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.15 16.82C3.62 16.8 1.53 11.64 1.53 9c0-4.3 3.22-5.24 4.46-5.24.56 0 1.16.22 1.69.42.37.13.75.27.96.27.13 0 .43-.12.7-.22.55-.23 1.25-.5 2.07-.5.62 0 2.46.13 3.57 1.8l.26.39-.37.28c-.54.4-1.51 1.14-1.51 2.6 0 1.73 1.1 2.4 1.63 2.71.24.14.48.29.48.6 0 .21-1.66 4.67-4.06 4.67-.6 0-1-.18-1.38-.33-.37-.16-.69-.3-1.22-.3-.27 0-.6.13-.96.27-.5.18-1.05.39-1.68.39h-.02Z"
            fill="#000"
          />
          <path d="M11.67 0c.06 2.26-1.56 3.83-3.17 3.73C8.23 1.93 10.1 0 11.67 0Z" fill="#000" />
        </svg>
      </button>
      <button
        class="button button-big alt alt-btn-item"
        (click)="onGoogleSignin(); false;"
        [amplitude]="'/login-google'"
      >
        Continue with
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m3.55 9.67-.56 2.08-2.04.04A7.96 7.96 0 0 1 .9 4.32l1.82.33.8 1.8a4.76 4.76 0 0 0 .04 3.22Z"
            fill="#FBBB00"
          />
          <path
            d="M15.86 6.5a8.02 8.02 0 0 1-2.85 7.74l-2.28-.12-.33-2.02a4.77 4.77 0 0 0 2.05-2.43H8.18V6.5h7.68Z"
            fill="#518EF8"
          />
          <path d="M13 14.24A8 8 0 0 1 .96 11.79l2.6-2.12a4.76 4.76 0 0 0 6.85 2.43l2.6 2.14Z" fill="#28B446" />
          <path d="m13.1 1.84-2.59 2.12a4.76 4.76 0 0 0-7.01 2.5L.9 4.31a8 8 0 0 1 12.2-2.48Z" fill="#F14336" />
        </svg>
      </button>
    </div>
  </form>
</div>
