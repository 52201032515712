import { Action } from '@ngrx/store';

import { IChannelDetails, IChannelMember } from '../../model';

// TODO: rename to channel.action.ts

export enum ActionTypes {
  LOAD_SELECTED_CHANNEL = '[Channel] Load Selected Channel',
  LOAD_SELECTED_CHANNEL_FAILURE = '[Channel] Load Selected Channel Failure',
  LOAD_SELECTED_CHANNEL_SUCCESS = '[Channel] Load Selected Channel Success',

  CLEAN_SELECTED_CHANNEL = '[Channel] Clean Selected Channel',

  LOAD_CHANNEL_MEMBERS = '[Channel] Load Channel Members',
  LOAD_CHANNEL_MEMBERS_FAILURE = '[Channel] Load Channel Members Failure',
  LOAD_CHANNEL_MEMBERS_SUCCESS = '[Channel] Load Channel Members Success',

  EDIT_CHANNEL_MEMBERS = '[Channel] Edit Channel Members',

  CLEAN_CHANNEL_MEMBERS = '[Channel] Clean Channel Members'
}

export class LoadSelectedChannelAction implements Action {
  readonly type = ActionTypes.LOAD_SELECTED_CHANNEL;
  constructor(public payload: { channelId: number }) {}
}

export class LoadSelectedChannelSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SELECTED_CHANNEL_SUCCESS;
  constructor(public payload: { channel: IChannelDetails }) {}
}

export class LoadSelectedChannelFailureAction implements Action {
  readonly type = ActionTypes.LOAD_SELECTED_CHANNEL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CleanSelectedChannelAction implements Action {
  readonly type = ActionTypes.CLEAN_SELECTED_CHANNEL;
}

export class LoadChannelMembersAction implements Action {
  readonly type = ActionTypes.LOAD_CHANNEL_MEMBERS;
  constructor(public payload: { channelId: number }) {}
}

export class LoadChannelMembersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CHANNEL_MEMBERS_SUCCESS;
  constructor(
    public payload: { members: IChannelMember[]; channelId: number }
  ) {}
}

export class LoadChannelMembersFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CHANNEL_MEMBERS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class EditChannelMembersAction implements Action {
  readonly type = ActionTypes.EDIT_CHANNEL_MEMBERS;
  constructor(
    public payload: {
      channelId: number;
      membersToAdd: number[];
      membersToRemove: number[];
    }
  ) {}
}

export class CleanChannelMembersAction implements Action {
  readonly type = ActionTypes.CLEAN_CHANNEL_MEMBERS;
  constructor() {}
}

export type Actions =
  | LoadSelectedChannelAction
  | LoadSelectedChannelSuccessAction
  | LoadSelectedChannelFailureAction
  | CleanSelectedChannelAction
  | LoadChannelMembersAction
  | LoadChannelMembersSuccessAction
  | LoadChannelMembersFailureAction
  | EditChannelMembersAction
  | CleanChannelMembersAction;
