import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ICompetitionReview } from '../../../model';

@Component({
  selector: 'app-competition-review',
  templateUrl: './competition-review.component.html',
  styleUrls: ['./competition-review.component.scss']
})
export class CompetitionReviewComponent {
  @Input() review: ICompetitionReview;

  @Output() readMore = new EventEmitter<ICompetitionReview>();

  onReadMore() {
    this.readMore.emit(this.review);
  }
}
