import { Pipe, PipeTransform } from '@angular/core';

// TODO: why not nl2br ?

@Pipe({
  name: 'newLine'
})
export class NewLinePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return '';
    }
    return value.replace(/\u21B5/g, '<br/>');
  }
}
