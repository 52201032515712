import { Pipe, PipeTransform } from '@angular/core';

interface Profile {
  type?: string;
  name: string;
  student?: {
    lastname: string;
  };
  teacher?: {
    lastname: string;
  };
}

@Pipe({
  name: 'profileNameTitle'
})
export class ProfileNameTitlePipe implements PipeTransform {
  transform(profile: Profile, type: string = null): string {
    const names = this.getNames(profile);

    if (!names) {
      return null;
    }

    switch (type) {
      case 'firstname':
        return names.firstname;
      default:
        return names.lastname
          ? `${names.firstname} ${names.lastname}`
          : names.firstname;
    }
  }

  getNames(profile: Profile): { firstname: string; lastname: string } {
    switch (profile.type.toLowerCase()) {
      case 'student':
        return { firstname: profile.name, lastname: profile.student?.lastname };
      case 'teacher':
        return { firstname: profile.name, lastname: profile.teacher?.lastname };
      case 'org':
        return { firstname: profile.name, lastname: null };
      default:
        return null;
    }
  }
}
