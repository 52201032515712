import { environment } from 'src/environments/environment';

function productionOnly(
  _target: Object,
  _propertyKey: string,
  descriptor: PropertyDescriptor
) {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args) {
    return environment.production ? originalMethod.apply(this, args) : () => {};
  };

  return descriptor;
}

export { productionOnly };
