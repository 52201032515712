import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ResizeObserver } from '@juggle/resize-observer';

import { PlatformService } from 'src/app/shared/services/platform.service';

@Directive({
  selector: '[appStickyScroll]'
})
export class AppStickyScrollDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() appStickyScrollTop: number;
  @Input() appStickyScrollBottom: number;

  private readonly _observer: ResizeObserver;
  private readonly _resized$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _el: ElementRef<HTMLDivElement>,
    private readonly _platformService: PlatformService
  ) {
    this._observer = new ResizeObserver(() => {
      this._resized$.next();
    });

    this._resized$
      .pipe(
        tap((_) => this.adjustPosition()),
        takeUntil(this._destroy$)
      )
      .subscribe();

    fromEvent(
      this._platformService.isPlatformBrowser() ? window : null,
      'resize'
    )
      .pipe(
        tap((_) => this.adjustPosition()),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this._observer.observe(this._el.nativeElement);
  }

  ngAfterViewInit(): void {
    this.adjustPosition();
  }

  ngOnDestroy(): void {
    this._observer.disconnect();

    this._destroy$.next();
    this._destroy$.complete();
  }

  private adjustPosition() {
    const el = this._el.nativeElement;

    const { height } = el.getBoundingClientRect();

    el.style.position = 'sticky';

    if (height + this.appStickyScrollTop <= window.innerHeight) {
      el.style.alignSelf = 'flex-start';
      el.style.top = `${this.appStickyScrollTop}px`;
      el.style.bottom = 'unset';

      return;
    }

    el.style.alignSelf = 'flex-end';
    el.style.bottom = `${this.appStickyScrollBottom}px`;
    el.style.top = 'unset';
  }
}
