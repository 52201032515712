import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  Subject
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';

import { Organization } from 'src/app/core/services/organization/organization.model';
import { OrganizationsService } from 'src/app/core/services/organizations/organizations.service';

@Component({
  selector: 'organization-search',
  exportAs: 'organizationSearch',
  templateUrl: './organization-search.component.html'
})
export class OrganizationSearchComponent implements OnInit, OnDestroy {
  organizations = new BehaviorSubject<Organization[]>([]);

  @Output() select = new EventEmitter<Organization>();

  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;

  private readonly _search$ = new BehaviorSubject<string>('');
  private readonly _page$ = new BehaviorSubject<number>(1);
  private readonly _destroy$ = new Subject<void>();
  private readonly _closed$ = new Subject<void>();
  private _isLoadMore = true;
  private _isLoading = false;
  private readonly _searchTypingIntervalMs = 300;

  constructor(private readonly organizationsService: OrganizationsService) {}

  ngOnInit() {
    this._search$
      .pipe(
        distinctUntilChanged(),
        tap((_) => this.reset()),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => _);

    combineLatest([this._search$, this._page$])
      .pipe(
        debounceTime(this._searchTypingIntervalMs),
        map((_) => ({
          term: _[0],
          page: _[1]
        })),
        filter((_) => this._isLoadMore && !this._isLoading),
        tap((_) => (this._isLoading = true)),
        switchMap((_) => this.loadOrganizations(_)),
        tap((_) => this.handleLoadedOrganizations(_)),
        tap((_) => (this._isLoading = false)),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._closed$.next();
    this._closed$.complete();

    this._destroy$.next();
    this._destroy$.complete();
  }

  get isLoading() {
    return this._isLoading;
  }

  opened() {
    // NOTE: be sure to wait until element
    // would be available within the DOM
    setTimeout(() => {
      fromEvent<MouseEvent>(this.autocomplete.panel.nativeElement, 'scroll')
        .pipe(
          map((_) => _.target),
          filter((_) => !this._isLoading),
          tap((_: HTMLDivElement) => this.handleScroll(_)),
          takeUntil(this._closed$)
        )
        .subscribe((_) => _);
    });
  }

  closed() {
    this._closed$.next();
    this._closed$.complete();
  }

  onSearchChange(data) {
    this._search$.next(data);
  }

  onSelect(organization: Organization) {
    this.select.emit(organization);
  }

  private loadOrganizations(params: {
    term: string;
    page: number;
  }): Observable<any[]> {
    return this.organizationsService
      .searchOrganizations({
        q: params.term,
        page: params.page
      })
      .pipe(map((_) => _.organizations));
  }

  private handleLoadedOrganizations(organizations: Organization[]) {
    if (organizations.length === 0) {
      this._isLoadMore = false;

      return;
    }

    this.organizations.next([...this.organizations.value, ...organizations]);
  }

  private handleScroll(elem: HTMLDivElement) {
    if (elem.offsetHeight + elem.scrollTop < elem.scrollHeight) {
      return;
    }

    this._page$.next(this._page$.value + 1);
  }

  private reset() {
    this.organizations.next([]);
    this._page$.next(1);

    this._isLoadMore = true;
  }
}
