<div *ngIf="state" class="state">
  <div>{{ state }}</div>
</div>

<section class="options" (keydown)="onKeyDown($event)">
  <button
    *ngFor="let option of options | async; index as i"
    class="option"
    [tabindex]="i + 1"
    (focus)="onFocus(i)"
    (click)="onSelect(option)"
    #Option
  >
    <a [profile]="option">
      <app-avatar [src]="option.icon" [placeholder]="option | initials" class="small"></app-avatar>
    </a>
    <div>{{ option.name }}</div>
  </button>
</section>
