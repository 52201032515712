import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import { Event } from './event.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private base: BaseService) {}

  getAll() {
    return this.base.get('events').pipe(map((res) => res.groups));
  }

  loadEvents(
    id: number,
    page: number
  ): Observable<{ count: number; events: Event[] }> {
    const urlParams = new URLSearchParams({
      page: page + ''
    });

    // TODO: this is a wrong URL to get club's events
    // return this.base.get(`user/events/${id}?${urlParams}`).pipe(
    //   map(res => ({ count: res.count, events: res.events }))
    // );

    return of({ count: 0, events: [] });
  }

  searchEvents(params): Observable<{ count: number; events: Event[] }> {
    return this.search('events', params).pipe(
      map((_) => ({ count: _.count, events: _.events }))
    );
  }

  private search(kind, params) {
    console.log('kinds: ', kind, params);

    const requestParams = { ...params };
    const { q, page } = requestParams;

    delete requestParams['q'];

    const urlParams = new URLSearchParams({
      ...params,
      text: q ? q : '',
      page
    });

    return this.base.get(`search/${kind}?${urlParams}`);
  }
}
