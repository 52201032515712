import { IIcon } from '../../model/icon';

export const plus: IIcon = {
  id: `PLUS`,
  svg: `
  <path d="M13.2 5.7h-4v-4a1.8 1.8 0 00-3.5 0v4h-4a1.8 1.8 0 000 3.6h4v4a1.8 1.8 0 003.6 0v-4h4a1.8 1.8 0 000-3.6z"
  fill="#BDBDBD" />`
};

export const plusWhite: IIcon = {
  id: `PLUS_WHITE`,
  svg: `
  <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white"/>
  `
};
