import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';

import { NewCompetitionComponent } from '../../shared/dialogs';
import { CompetitionsFacade } from '../../store';

@Component({
  selector: 'app-competitions',
  templateUrl: './competitions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitionsComponent implements OnInit {
  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly competitionsFacade: CompetitionsFacade
  ) {}

  get isLoading$() {
    return this.authService.user$.pipe(
      // TODO: Refactor this weird thing with the stub user
      // in which id equals to 0
      switchMap((_) =>
        _ && _.id ? this.isLoadingWithMy$ : this.isLoadingWithoutMy$
      )
    );
  }

  get canCreateCompetition$() {
    return this.competitionsFacade.canCreateCompetition$;
  }

  get currentCompetitions$() {
    return this.competitionsFacade.currentCompetitions$;
  }

  get hasMoreCurrentCompetitionsToLoad$() {
    return this.competitionsFacade.hasMoreCurrentCompetitionsToLoad$;
  }

  get isLoadingCurrentCompetitions$() {
    return this.competitionsFacade.isLoadingCurrentCompetitions$;
  }

  get upcomingCompetitions$() {
    return this.competitionsFacade.upcomingCompetitions$;
  }

  get hasMoreUpcomingCompetitionsToLoad$() {
    return this.competitionsFacade.hasMoreUpcomingCompetitionsToLoad$;
  }

  get isLoadingUpcomingCompetitions$() {
    return this.competitionsFacade.isLoadingUpcomingCompetitions$;
  }

  get myCompetitions$() {
    return this.competitionsFacade.myCompetitions$;
  }

  get hasMoreMyCompetitionsToLoad$() {
    return this.competitionsFacade.hasMoreMyCompetitionsToLoad$;
  }

  get isLoadingMyCompetitions$() {
    return this.competitionsFacade.isLoadingMyCompetitions$;
  }

  get previousCompetitions$() {
    return this.competitionsFacade.previousCompetitions$;
  }

  get hasMorePreviousCompetitionsToLoad$() {
    return this.competitionsFacade.hasMorePreviousCompetitionsToLoad$;
  }

  get isLoadingPreviousCompetitions$() {
    return this.competitionsFacade.isLoadingPreviousCompetitions$;
  }

  private get isLoadingWithMy$() {
    return combineLatest([
      this.competitionsFacade.lastLoadedPageCurrentCompetitions$,
      this.competitionsFacade.lastLoadedPageUpcomingCompetitions$,
      this.competitionsFacade.lastLoadedPageMyCompetitions$,
      this.competitionsFacade.lastLoadedPagePreviousCompetitions$,
      this.competitionsFacade.isLoadingCurrentCompetitions$,
      this.competitionsFacade.isLoadingUpcomingCompetitions$,
      this.competitionsFacade.isLoadingMyCompetitions$,
      this.competitionsFacade.isLoadingPreviousCompetitions$
    ]).pipe(
      map(
        (_) => _.slice(0, 4).some((_) => _ === 0) && _.slice(4).some((_) => _)
      ),
      distinctUntilChanged()
    );
  }

  private get isLoadingWithoutMy$() {
    return combineLatest([
      this.competitionsFacade.lastLoadedPageCurrentCompetitions$,
      this.competitionsFacade.lastLoadedPageUpcomingCompetitions$,
      this.competitionsFacade.lastLoadedPagePreviousCompetitions$,
      this.competitionsFacade.isLoadingCurrentCompetitions$,
      this.competitionsFacade.isLoadingUpcomingCompetitions$,
      this.competitionsFacade.isLoadingPreviousCompetitions$
    ]).pipe(
      map(
        (_) => _.slice(0, 3).some((_) => _ === 0) && _.slice(3).some((_) => _)
      ),
      distinctUntilChanged()
    );
  }

  ngOnInit() {
    this.competitionsFacade.loadCompetitions();
  }

  onCreateCompetition() {
    return this.dialog
      .open(NewCompetitionComponent)
      .afterClosed()
      .pipe(
        filter((_) => !!_?.competition),
        map((_) =>
          this.competitionsFacade.createCompetition({
            competition: _.competition
          })
        )
      )
      .subscribe((_) => _);
  }

  onLoadMoreCurrentCompetitions() {
    this.competitionsFacade.lastLoadedPageCurrentCompetitions$
      .pipe(
        take(1),
        tap((_) =>
          this.competitionsFacade.loadCurrentCompetitions({ page: _ + 1 })
        )
      )
      .subscribe();
  }

  onLoadMoreUpcomingCompetitions() {
    this.competitionsFacade.lastLoadedPageUpcomingCompetitions$
      .pipe(
        take(1),
        tap((_) =>
          this.competitionsFacade.loadUpcomingCompetitions({ page: _ + 1 })
        )
      )
      .subscribe();
  }

  onLoadMoreMyCompetitions() {
    this.competitionsFacade.lastLoadedPageMyCompetitions$
      .pipe(
        take(1),
        tap((_) => this.competitionsFacade.loadMyCompetitions({ page: _ + 1 }))
      )
      .subscribe();
  }

  onLoadMorePreviousCompetitions() {
    this.competitionsFacade.lastLoadedPagePreviousCompetitions$
      .pipe(
        take(1),
        tap((_) =>
          this.competitionsFacade.loadPreviousCompetitions({ page: _ + 1 })
        )
      )
      .subscribe();
  }
}
