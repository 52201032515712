import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import moment from 'moment';

import { TStatus } from 'src/app/shared/models/status';
import { IComment } from 'src/app/shared/models/comment';
import { IPost } from 'src/app/shared/models/post';

import { PostState } from '../model/state';
import { postsFeatureKey } from './state';

export const selectState = createFeatureSelector<PostState>(postsFeatureKey);

type isDesc = boolean;

export const selectComments = (id: number) =>
  createSelector(
    selectState,
    (state: PostState) => state?.comments[id]?.values ?? []
  );

export const selectReplies = (commentId: number) =>
  createSelector(
    selectState,
    (state: PostState): IComment[] => state?.replies[commentId]?.values ?? []
  );

export const selectCommentsStatus = (id: number) =>
  createSelector(
    selectState,
    (state: PostState): TStatus => state?.comments[id]?.status
  );

export const selectCommentsCount = (id: number) =>
  createSelector(selectState, (state: PostState) => state?.comments[id]?.count);

export const selectPosts = (orderBy: { pinned?: isDesc }) => {
  const initialOrdering = new Map([
    [
      // only posts with a pin logic should be order by date
      (post: IPost) =>
        orderBy.pinned && 'pinned' in post && moment(post.created_at.date),
      'desc'
    ]
  ]);

  const customOrdering = {
    ...Object.keys(orderBy).reduce((acc, key) => {
      acc[key] = orderBy[key] ? 'desc' : 'asc';
      return acc;
    }, {})
  };

  const keys = [...Object.keys(customOrdering), ...initialOrdering.keys()];
  const ordering = [
    ...Object.values(customOrdering),
    ...initialOrdering.values()
  ];

  return createSelector(selectState, (state: PostState) => {
    const posts = state.posts.toArray();

    return _.orderBy(posts, keys, ordering);
  });
};

export const selectPostView = () =>
  createSelector(selectState, (state: PostState) => state.postView[0]);

export const selectStatus = () =>
  createSelector(selectState, (state: PostState) => state.status);

export const selectNextPages = createSelector(
  selectState,
  (state) => state.nextPage
);

export const selectIsCommentsFromPost = (id: number) =>
  createSelector(
    selectState,
    (state: PostState) => state && state.comments[id].values
  );
