import { IIcon } from '../../model/icon';

export const friends: IIcon = {
  id: `FRIENDS`,
  svg: `
  <g clip-path="url(#clip20)">
  <path fill="#2980BA"
    d="M7 11.9h1.1c1.3 0 2.4 1 2.4 2.4v2.8c0 .2-.1.3-.3.3H.5a.3.3 0 01-.3-.3v-2.8c0-1.3 1-2.4 2.4-2.4H7z" />
  <path fill="#285680" d="M2.2 15v2.4h-.6V15a.3.3 0 11.6 0zM9.1 15v2.4h-.7V15a.3.3 0 11.7 0z" />
  <path fill="#3B97D3" d="M6.5 9.6c-.2.6-.4 1.6.6 2.3a1.7 1.7 0 01-3.5 0c1-.7.8-1.7.6-2.3h2.3z" />
  <path fill="#84B5CB"
    d="M9.8 6.4a1 1 0 01-1.2 1c-.3 1-1 1.8-2 2.2-.8.3-1.7.3-2.4 0-1-.4-1.8-1.2-2.1-2.2h-.2a1 1 0 110-2V3.8C.6 2.8.9.2.9.2c1 2 5.5.3 7.2 1.4 1.7 1 .7 3.7.7 3.7.6 0 1 .5 1 1z" />
  <path fill="#894B9D"
    d="M16.6 14.5h1a2.4 2.4 0 012.4 2.4v2.8l-.3.3H10a.3.3 0 01-.3-.3v-2.8c0-1.3 1-2.4 2.4-2.4h4.5z" />
  <path fill="#3D324C" d="M11.7 17.6V20H11v-2.4a.3.3 0 11.7 0zM18.6 17.6V20H18v-2.4a.3.3 0 01.7 0z" />
  <path fill="#955BA5" d="M16 12.2c-.2.6-.4 1.6.6 2.3a1.7 1.7 0 11-3.5 0c1-.7.8-1.7.6-2.3H16z" />
  <path fill="#B49ACA"
    d="M19.3 9a1 1 0 01-1.2 1c-.3 1-1.1 1.8-2.1 2.2-.8.3-1.6.3-2.3 0-1-.4-1.8-1.2-2.1-2.2h-.2a1 1 0 110-2V6.4c-1.3-1.1-1-3.7-1-3.7 1 2 5.5.3 7.2 1.3 1.7 1 .7 3.8.7 3.8.5 0 1 .5 1 1z" />
  <path fill="#00AB8D" d="M16.6 6.9a3.4 3.4 0 100-6.9 3.4 3.4 0 000 6.9z" />
  <path fill="#fff" d="M16 1.5a.5.5 0 011 0v4a.5.5 0 01-1 0v-4z" />
  <path fill="#fff" d="M18.5 3a.5.5 0 010 1h-4a.5.5 0 010-1h4z" />
</g>
<defs>
  <clipPath id="clip20">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>
  `
};
