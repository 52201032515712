import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IGroupChat } from '../../model';
import { ChatsListActions, MessageActions, ChannelActions } from '../actions';

export const featureAdapter: EntityAdapter<IGroupChat> =
  createEntityAdapter<IGroupChat>({
    selectId: (model) => model.id,
    sortComparer: (a: IGroupChat, b: IGroupChat): number => b.id - a.id
  });

export interface State extends EntityState<IGroupChat> {
  isLoading?: boolean;
  error?: Error;
  unreadChannels: {};
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  unreadChannels: {}
});

export function Reducer(
  state = initialState,
  action:
    | ChatsListActions.Actions
    | MessageActions.Actions
    | ChannelActions.Actions
): State {
  switch (action.type) {
    case ChatsListActions.ActionTypes.LOAD_GROUP_CHATS:
      return {
        ...initialState,
        isLoading: true,
        unreadChannels: state.unreadChannels
      };
    case ChatsListActions.ActionTypes.NEW_GROUP_CHAT:
      return {
        ...state,
        isLoading: true
      };
    case ChatsListActions.ActionTypes.NEW_GROUP_CHAT_SUCCESS:
      const newChannels = action.payload.chat.channels.reduce(
        (acc, channel) => ({ ...acc, [channel.id]: true }),
        {}
      );

      return featureAdapter.addOne(action.payload.chat, {
        ...state,
        isLoading: false,
        error: null,
        unreadChannels: {
          ...state.unreadChannels,
          ...newChannels
        }
      });
    case ChatsListActions.ActionTypes.EDIT_GROUP_CHAT_SUCCESS: {
      const chat = state.entities[action.payload.chat.id];
      if (!chat) {
        return state;
      }

      return featureAdapter.updateOne(
        {
          id: chat.id,
          changes: {
            ...chat,
            title: action.payload.chat.title
          }
        },
        {
          ...state,
          isLoading: false,
          error: null
        }
      );
    }
    case ChatsListActions.ActionTypes.DELETE_GROUP_CHAT_SUCCESS: {
      return featureAdapter.removeOne(action.payload.chat.id, state);
    }
    case ChatsListActions.ActionTypes.LOAD_GROUP_CHATS_SUCCESS:
      return featureAdapter.addAll(action.payload.chats, {
        ...state,
        isLoading: false,
        error: null
      });
    case ChannelActions.ActionTypes.NEW_CHANNEL_SUCCESS:
      const chat = state.entities[action.payload.channel.chatId];
      if (!chat) {
        return state;
      }

      const channelIndex = chat.channels.findIndex(
        (_) => _.id === action.payload.channel.id
      );
      if (channelIndex !== -1) {
        return state;
      }

      return featureAdapter.updateOne(
        {
          id: chat.id,
          changes: {
            ...chat,
            channels: [...chat.channels, action.payload.channel]
          }
        },
        {
          ...state,
          isLoading: false,
          error: null,
          unreadChannels: {
            ...state.unreadChannels,
            [action.payload.channel.id]: true
          }
        }
      );
    case MessageActions.ActionTypes.NEW_MESSAGE_SUCCESS: {
      const chat = state.entities[action.payload.message.chatId];
      if (!chat) {
        return state;
      }

      const channelIndex = chat.channels.findIndex(
        (_) => _.id === action.payload.message.channelId
      );
      if (channelIndex === -1) {
        return state;
      }

      return featureAdapter.updateOne(
        {
          id: chat.id,
          changes: {
            ...chat,
            channels: [
              ...chat.channels.slice(0, channelIndex),
              {
                ...chat.channels[channelIndex],
                message: action.payload.message,
                isUnread: true
              },
              ...chat.channels.slice(channelIndex + 1)
            ]
          }
        },
        {
          ...state,
          unreadChannels: {
            ...state.unreadChannels,
            [action.payload.message.channelId]: true
          }
        }
      );
    }
    case ChannelActions.ActionTypes.READ_CHANNEL:
    case ChannelActions.ActionTypes.READ_CHANNEL_SUCCESS: {
      const chat = state.entities[action.payload.chatId];
      if (!chat) {
        return state;
      }

      const channelIndex = chat.channels.findIndex(
        (_) => _.id === action.payload.channelId
      );
      if (channelIndex === -1) {
        return state;
      }

      if (!chat.channels[channelIndex].isUnread) {
        return state;
      }

      const unreadChannels = { ...state.unreadChannels };
      delete unreadChannels[action.payload.channelId];

      return featureAdapter.updateOne(
        {
          id: chat.id,
          changes: {
            ...chat,
            channels: [
              ...chat.channels.slice(0, channelIndex),
              { ...chat.channels[channelIndex], isUnread: false },
              ...chat.channels.slice(channelIndex + 1)
            ]
          }
        },
        {
          ...state,
          unreadChannels
        }
      );
    }
    case ChannelActions.ActionTypes.LEAVE_CHANNEL_SUCCESS: {
      const unreadChannels = { ...state.unreadChannels };
      delete unreadChannels[action.payload.channel.id];

      return {
        ...state,
        unreadChannels
      };
    }
    case ChannelActions.ActionTypes.ME_LEAVE_CHANNEL_SUCCESS:
    case ChannelActions.ActionTypes.DELETE_CHANNEL_SUCCESS: {
      const chat = state.entities[action.payload.channel.chatId];
      if (!chat) {
        return state;
      }

      const channelIndex = chat.channels.findIndex(
        (_) => _.id === action.payload.channel.id
      );
      if (channelIndex === -1) {
        return state;
      }

      const unreadChannels = { ...state.unreadChannels };
      delete unreadChannels[action.payload.channel.id];

      return featureAdapter.updateOne(
        {
          id: chat.id,
          changes: {
            ...chat,
            channels: [
              ...chat.channels.slice(0, channelIndex),
              ...chat.channels.slice(channelIndex + 1)
            ]
          }
        },
        {
          ...state,
          unreadChannels
        }
      );
    }
    case ChannelActions.ActionTypes.EDIT_CHANNEL_SUCCESS: {
      const chat = state.entities[action.payload.channel.chatId];
      if (!chat) {
        return state;
      }

      const channelIndex = chat.channels.findIndex(
        (_) => _.id === action.payload.channel.id
      );
      if (channelIndex === -1) {
        return state;
      }

      return featureAdapter.updateOne(
        {
          id: chat.id,
          changes: {
            ...chat,
            channels: [
              ...chat.channels.slice(0, channelIndex),
              {
                ...chat.channels[channelIndex],
                title: action.payload.channel.title
              },
              ...chat.channels.slice(channelIndex + 1)
            ]
          }
        },
        {
          ...state
        }
      );
    }
    case ChatsListActions.ActionTypes.LOAD_UNREAD_CHANNELS_SUCCESS:
      return {
        ...state,
        unreadChannels: action.payload.channels.reduce(
          (acc, curr) => ({ ...acc, [curr]: true }),
          {}
        )
      };
    case ChatsListActions.ActionTypes.LOAD_GROUP_CHATS_FAILURE:
    case ChatsListActions.ActionTypes.NEW_GROUP_CHAT_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

export const { selectAll: selectGroupChats } = featureAdapter.getSelectors();

export const isLoading = (state: State) => state.isLoading;
export const getAmountOfUnreadChannels = (state: State) =>
  Object.keys(state.unreadChannels).length;
