import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from './reducers';
import {
  INewArticle,
  INewProject,
  IProject,
  IArticle,
  INewCompetitionProject
} from '../model';
import { ProjectActions, ProjectsActions } from './actions';
import {
  getProjectsList,
  isLoadingProjectsList,
  getProjectsTotalAmount
} from './selectors';
import { Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectsFacade {
  readonly projects$: Observable<Array<IProject>>;
  readonly totalAmountOfProjects$: Observable<number>;
  readonly isLoading$: Observable<boolean>;

  readonly amountOfProjectsPerPage = 4;

  constructor(
    private readonly store: Store<State>,
    private readonly actions$: Actions
  ) {
    this.projects$ = this.store.pipe(
      select(getProjectsList(this.amountOfProjectsPerPage))
    );
    this.totalAmountOfProjects$ = this.store.pipe(
      select(getProjectsTotalAmount)
    );
    this.isLoading$ = this.store.pipe(select(isLoadingProjectsList));
  }

  clean() {
    this.store.dispatch(new ProjectsActions.CleanProjectsAction());
  }

  list(params: { page: number; categoryId: number }) {
    this.store.dispatch(
      new ProjectsActions.ListProjectsAction({
        count: this.amountOfProjectsPerPage,
        ...params
      })
    );
  }

  create(project: INewProject) {
    this.store.dispatch(new ProjectActions.CreateProjectAction({ project }));
  }

  edit(project: IProject) {
    this.store.dispatch(new ProjectActions.EditProjectAction({ project }));
  }

  delete(id: number) {
    this.store.dispatch(new ProjectActions.DeleteProjectAction({ id }));
  }

  createNewCompetitionProject(
    project: INewCompetitionProject & { id?: number }
  ) {
    if (project.id) {
      this.store.dispatch(
        new ProjectActions.UpdateCompetitionProjectAction({ project })
      );
    } else {
      this.store.dispatch(
        new ProjectActions.CreateCompetitionProjectAction({ project })
      );
    }
    console.log(project);
  }

  createNewArticle(article: INewArticle): Observable<IArticle> {
    this.store.dispatch(
      new ProjectActions.CreateProjectArticleAction({ article })
    );

    return new Observable((subscriber) => {
      const subscription = this.actions$
        .pipe(
          ofType<ProjectActions.CreateProjectArticleSuccessAction>(
            ProjectActions.ActionTypes.CREATE_PROJECT_ARTICLE_SUCCESS
          ),
          tap((_) => subscriber.next(_.payload.article))
        )
        .subscribe((_) => _);

      return () => {
        subscription.unsubscribe();
      };
    });
  }
}
