import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { CompetitionActions, CompetitionsActions } from '../actions';
import { ICompetition } from '../../model';

export const featureAdapter: EntityAdapter<ICompetition> =
  createEntityAdapter<ICompetition>({
    selectId: (model) => model.id,
    sortComparer: (a: ICompetition, b: ICompetition): number => b.id - a.id
  });

export interface State extends EntityState<ICompetition> {
  lastLoadedPage: number;
  totalAmount: number;
  isLoading?: boolean;
  error?: Error;
}

export const initialState: State = featureAdapter.getInitialState({
  lastLoadedPage: 0,
  totalAmount: null,
  isLoading: false,
  error: null
});

export function Reducer(
  state = initialState,
  action: CompetitionActions.Actions | CompetitionsActions.Actions
): State {
  switch (action.type) {
    case CompetitionsActions.ActionTypes.LOAD_MY_COMPETITIONS:
      return {
        ...state,
        isLoading: true
      };
    case CompetitionActions.ActionTypes.CREATE_COMPETITION_SUCCESS:
      return featureAdapter.addOne(action.payload.competition, {
        ...state,
        totalAmount: state.totalAmount + 1,
        error: null
      });
    case CompetitionsActions.ActionTypes.LOAD_MY_COMPETITIONS_SUCCESS:
      return featureAdapter.addMany(action.payload.competitions, {
        ...state,
        lastLoadedPage: action.payload.page,
        totalAmount: action.payload.totalAmount,
        isLoading: false,
        error: null
      });
    case CompetitionsActions.ActionTypes.CLEAN_MY_COMPETITIONS:
      return initialState;
    default:
      return state;
  }
}

export const { selectAll: selectCompetitions } = featureAdapter.getSelectors();

export const isLoading = (state: State) => state.isLoading;
export const hasMoreCompetitionsToLoad = (state: State) =>
  state.ids.length < state.totalAmount;
export const lastLoadedPage = (state: State) => state.lastLoadedPage;
