import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-event-badge',
  templateUrl: './event-badge.component.html',
  styleUrls: ['./event-badge.component.scss']
})
export class EventBadgeComponent {
  @Input() event: any;
}
