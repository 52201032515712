import { medal } from './svg/medal';
import { add } from './svg/add';
import { arrow } from './svg/arrow';
import { bell } from './svg/bell';
import { calendar } from './svg/calendar';
import { call } from './svg/call';
import { chat } from './svg/chat';
import { check } from './svg/check';
import { comment } from './svg/comment';
import { commentWb } from './svg/comment-wb';
import { computer } from './svg/computer';
import { disconnect } from './svg/disconnect';
import { doc } from './svg/doc';
import { earth } from './svg/earth';
import { earthNetwork } from './svg/earth-network';
import { emoji } from './svg/emoji';
import { friends } from './svg/friends';
import { group } from './svg/group';
import { groups } from './svg/groups';
import { image } from './svg/image';
import { joinGroup } from './svg/join-group';
import { like } from './svg/like';
import { likeWb } from './svg/like-wb';
import { location } from './svg/location';
import { mail } from './svg/mail';
import { menuDots } from './svg/menu-dots';
import { menuDown } from './svg/menu-down';
import { message } from './svg/message';
import { monitor } from './svg/monitor';
import { options } from './svg/options';
import { pencil } from './svg/pencil';
import { pin } from './svg/pin';
import { planet } from './svg/planet';
import { plus, plusWhite } from './svg/plus';
import { profilePlus } from './svg/profile-plus';
import { reply } from './svg/reply';
import { school } from './svg/school';
import { search } from './svg/search';
import { send } from './svg/send';
import { share } from './svg/share';
import { startup } from './svg/startup';
import { student } from './svg/student';
import { subComplain } from './svg/sub-complain';
import { subDelete } from './svg/sub-delete';
import { subEditPost } from './svg/sub-edit-post';
import { subEditProfile } from './svg/sub-edit-profile';
import { subEvents } from './svg/sub-events';
import { subGallery } from './svg/sub-gallery';
import { subInvite } from './svg/sub-invite';
import { subLink } from './svg/sub-link';
import { subNetwork } from './svg/sub-network';
import { sun } from './svg/sun';
import { think } from './svg/think';
import { university } from './svg/university';
import { userCheck } from './svg/user-check';
import { userNetwork } from './svg/user-network';
import { work } from './svg/work';
import { workGroup } from './svg/work-group';
import { pdf } from './svg/pdf';
import { reg_avatar } from './svg/registration/avatar';
import { reg_basic } from './svg/registration/basic';
import { reg_educator_basic } from './svg/registration/educator_basic';
import { reg_other } from './svg/registration/other';
import { close } from './svg/close';
import { privateGroup } from './svg/privateGroup';
import { back } from './svg/back';
import { save, saved } from './svg/save';
import { creator } from './svg/creator';
import { colleges } from './svg/colleges';

export const icons = Object.freeze([
  add,
  arrow,
  bell,
  calendar,
  call,
  chat,
  check,
  comment,
  commentWb,
  computer,
  disconnect,
  doc,
  earth,
  earthNetwork,
  emoji,
  friends,
  group,
  groups,
  image,
  joinGroup,
  like,
  likeWb,
  location,
  mail,
  medal,
  menuDots,
  menuDown,
  message,
  monitor,
  options,
  pencil,
  pin,
  planet,
  plus,
  profilePlus,
  reply,
  school,
  search,
  send,
  share,
  startup,
  student,
  subComplain,
  subDelete,
  subEditPost,
  subEditProfile,
  subEvents,
  subGallery,
  subInvite,
  subLink,
  subNetwork,
  sun,
  think,
  university,
  userCheck,
  userNetwork,
  work,
  workGroup,
  pdf,
  reg_avatar,
  reg_basic,
  reg_educator_basic,
  reg_other,
  plusWhite,
  close,
  privateGroup,
  back,
  save,
  saved,
  creator,
  colleges
]);
