import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

import {
  IGroupChat,
  IMessage,
  INewGroupChat,
  INewMessage,
  INewChannel,
  IChannelMember,
  IChannelDetails,
  IEditChannel,
  IEditGroupChat,
  IGroupChatMember
} from '../model';
import {
  ChatsListActions,
  MessageActions,
  SelectedChannelActions,
  ChannelActions
} from './actions';
import { State } from './reducers';
import {
  getGroupChatsList,
  isLoadingGroupChats,
  isLoadingMessages,
  isLoadedMessages,
  getSelectedChannel,
  getChannelMembers,
  nextMessagesPage,
  getMessagesChunks,
  getAmountOfUnreadChannels,
  groupChatMembers,
  isLoadingGroupChatMembers
} from './selectors';
import { ChatService } from './service';

@Injectable({
  providedIn: 'root'
})
export class ChatFacade {
  readonly groupChats$: Observable<IGroupChat[]>;
  readonly isLoadingGroupChats$: Observable<boolean>;
  readonly selectedChannel$: Observable<IChannelDetails>;
  readonly channelMembers$: Observable<IChannelMember[]>;
  readonly groupChatMembers$: Observable<IGroupChatMember[]>;
  readonly isLoadingGroupChatMembers$: Observable<boolean>;
  readonly messages$: Observable<IMessage[]>;
  readonly isLoadingMessages$: Observable<boolean>;
  readonly isLoadedMessages$: Observable<boolean>;
  readonly nextMessagesPage$: Observable<number>;
  readonly messagesChunks$: Observable<Array<{ messages: IMessage[] }>>;
  readonly amountOfUnreadChannels$: Observable<number>;

  constructor(
    private readonly store: Store<State>,
    private readonly chatService: ChatService,
    private readonly authService: AuthService
  ) {
    this.groupChats$ = this.store.pipe(select(getGroupChatsList));
    this.isLoadingGroupChats$ = this.store.pipe(select(isLoadingGroupChats));
    this.selectedChannel$ = this.store.pipe(select(getSelectedChannel));
    this.channelMembers$ = this.store.pipe(select(getChannelMembers));
    this.groupChatMembers$ = this.store.pipe(select(groupChatMembers));
    this.isLoadingGroupChatMembers$ = this.store.pipe(
      select(isLoadingGroupChatMembers)
    );
    this.isLoadingMessages$ = this.store.pipe(select(isLoadingMessages));
    this.isLoadedMessages$ = this.store.pipe(select(isLoadedMessages));
    this.nextMessagesPage$ = this.store.pipe(select(nextMessagesPage));
    this.messagesChunks$ = this.store.pipe(select(getMessagesChunks));
    this.amountOfUnreadChannels$ = this.store.pipe(
      select(getAmountOfUnreadChannels)
    );
  }

  async init({ token, userId }: { token: string; userId: number }) {
    return this.chatService.init({ token, userId });
  }

  async deinit() {
    return this.chatService.deinit();
  }

  onGroupChatsNew$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onGroupChatsNew((chat) => {
        this.store.dispatch(
          new ChatsListActions.NewGroupChatSuccessAction({ chat })
        );
      });

      return tearDown;
    });
  }

  loadGroupChats({ page }: { page: number }) {
    this.store.dispatch(new ChatsListActions.LoadGroupChatsAction({ page }));
  }

  newGroupChat(chat: INewGroupChat) {
    this.store.dispatch(new ChatsListActions.NewGroupChatAction({ chat }));
  }

  groupChatsMembers(chatId: number) {
    this.store.dispatch(
      new ChatsListActions.LoadGroupChatMembersAction({ chatId })
    );
  }

  onGroupChatsMembers$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onChatsMembers((members, chatId) => {
        this.store.dispatch(
          new ChatsListActions.LoadGroupChatMembersSuccessAction({
            members,
            chatId
          })
        );
      });

      return tearDown;
    });
  }

  groupChatsMembersEdit(
    chatId: number,
    membersToAdd: number[],
    membersToRemove: number[]
  ) {
    this.store.dispatch(
      new ChatsListActions.EditGroupChatMembersAction({
        chatId,
        membersToAdd,
        membersToRemove
      })
    );
  }

  editGroupChats(chat: IEditGroupChat) {
    this.store.dispatch(new ChatsListActions.EditGroupChatAction({ chat }));
  }

  onGroupChatsEdit$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onGroupChatsEdit((chat) => {
        this.store.dispatch(
          new ChatsListActions.EditGroupChatSuccessAction({ chat })
        );
      });

      return tearDown;
    });
  }

  onGroupChatsDelete$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onGroupChatsDelete((chat) => {
        this.store.dispatch(
          new ChatsListActions.DeleteGroupChatSuccessAction({ chat })
        );
      });

      return tearDown;
    });
  }

  newGroupChatChannel(channel: INewChannel) {
    this.store.dispatch(new ChannelActions.NewChannelAction({ channel }));
  }

  onChannelsNew$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onNewChannels((channel) => {
        this.store.dispatch(
          new ChannelActions.NewChannelSuccessAction({ channel })
        );
      });

      return tearDown;
    });
  }

  channelMembers(channelId: number) {
    this.store.dispatch(
      new SelectedChannelActions.LoadChannelMembersAction({ channelId })
    );
  }

  cleanChannelMembers() {
    this.store.dispatch(new SelectedChannelActions.CleanChannelMembersAction());
  }

  channelMembersEdit(
    channelId: number,
    membersToAdd: number[],
    membersToRemove: number[]
  ) {
    this.store.dispatch(
      new SelectedChannelActions.EditChannelMembersAction({
        channelId,
        membersToAdd,
        membersToRemove
      })
    );
  }

  cleanSelectedChannel() {
    this.store.dispatch(
      new SelectedChannelActions.CleanSelectedChannelAction()
    );
  }

  loadSelectedChannel(channelId: number) {
    this.store.dispatch(
      new SelectedChannelActions.LoadSelectedChannelAction({ channelId })
    );
  }

  onChannelMembers$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onChannelsMembers(
        (members, channelId) => {
          this.store.dispatch(
            new SelectedChannelActions.LoadChannelMembersSuccessAction({
              members,
              channelId
            })
          );
        }
      );

      return tearDown;
    });
  }

  loadUnreadChannels() {
    this.store.dispatch(new ChatsListActions.LoadUnreadChannelsAction());
  }

  readChannel(chatId: number, channelId: number) {
    this.chatService.readChannel(chatId, channelId);

    this.store.dispatch(
      new ChannelActions.ReadChannelAction({ chatId, channelId })
    );
  }

  onReadChannel$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onReadChannel((readChannelRespose) => {
        this.store.dispatch(
          new ChannelActions.ReadChannelSuccessAction(readChannelRespose)
        );
      });

      return tearDown;
    });
  }

  editChannel(channel: IEditChannel) {
    this.store.dispatch(new ChannelActions.EditChannelAction({ channel }));
  }

  onEditChannel$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onEditChannel((channel) => {
        this.store.dispatch(
          new ChannelActions.EditChannelSuccessAction({ channel })
        );
      });

      return tearDown;
    });
  }

  channelLeave({ chatId, channelId }: { chatId: number; channelId: number }) {
    this.store.dispatch(
      new ChannelActions.LeaveChannelAction({ chatId, channelId })
    );
  }

  onLeaveChannel$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onLeaveChannel((channel, userId) => {
        if (this.authService.isMyId(userId)) {
          return this.store.dispatch(
            new ChannelActions.MeLeaveChannelSuccessAction({ channel, userId })
          );
        }

        this.store.dispatch(
          new ChannelActions.LeaveChannelSuccessAction({ channel, userId })
        );
      });

      return tearDown;
    });
  }

  channelDelete({ chatId, channelId }: { chatId: number; channelId: number }) {
    this.store.dispatch(
      new ChannelActions.DeleteChannelAction({ chatId, channelId })
    );
  }

  onDeleteChannel$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onDeleteChannel((channel) => {
        this.store.dispatch(
          new ChannelActions.DeleteChannelSuccessAction({ channel })
        );
      });

      return tearDown;
    });
  }

  cleanMessages() {
    this.store.dispatch(new MessageActions.CleanMessagesAction());
  }

  loadMessages(channelId: number, page: number) {
    this.store.dispatch(
      new MessageActions.LoadMessagesAction({ channelId, page })
    );
  }

  onMessagesNew$() {
    return new Observable((_) => {
      const tearDown = this.chatService.onMessagesNew((message) => {
        this.store.dispatch(
          new MessageActions.NewMessageSuccessAction({ message })
        );
      });

      return tearDown;
    });
  }

  onMessagesNewForChannel$(channelId: number) {
    return new Observable((_) => {
      const tearDown = this.chatService.onMessagesNew((message) => {
        if (channelId && channelId !== message.channelId) {
          return;
        }

        this.store.dispatch(
          new MessageActions.NewMessageForChannelSuccessAction({ message })
        );
      });

      return tearDown;
    });
  }

  newMessage(message: INewMessage) {
    this.store.dispatch(new MessageActions.NewMessageAction({ message }));
  }
}
