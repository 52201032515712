import { IIcon } from '../../model/icon';

export const school: IIcon = {
  id: `SCHOOL`,
  svg: `
  <g clip-path="url(#clip1)">
  <path fill="#E04C34" d="M15 1.02h3.56c.28 0 .5.22.5.5v2.04a.5.5 0 01-.5.5H15V1.03z" />
  <path fill="#F1D24B" d="M28.73 13.22v15.76c0 .56-.46 1.02-1.02 1.02H2.3c-.56 0-1.02-.46-1.02-1.02V13.22h27.46z" />
  <path fill="#F85360"
    d="M20.08 12.2h9.16c.28 0 .5.23.5.51v1.02a.5.5 0 01-.5.5H.76a.5.5 0 01-.5-.5V12.7c0-.28.22-.5.5-.5h19.32z" />
  <path fill="#D94D5D" d="M21.1 26.95v2.03H8.9v-2.03c0-.56.45-1.02 1.02-1.02h10.16c.57 0 1.02.46 1.02 1.02z" />
  <path fill="#EDC100" d="M20.08 8.84v17.1H9.92V8.83L15 6.1l5.08 2.74z" />
  <path fill="#C7804C" d="M15 6.61a.5.5 0 01-.5-.5V.5a.5.5 0 011 0v5.6a.5.5 0 01-.5.5z" />
  <path fill="#F85360"
    d="M21.61 10.17a.5.5 0 01-.24-.06L15 6.68 8.63 10.1a.5.5 0 11-.48-.9l6.6-3.56a.5.5 0 01.5 0l6.6 3.56a.5.5 0 01-.24.96z" />
  <path fill="#A1693E" d="M11.95 25.93v-4.57a3.05 3.05 0 016.1 0v4.57h-6.1z" />
  <path fill="#F8E8AB" d="M15 16.27a3.05 3.05 0 100-6.1 3.05 3.05 0 000 6.1z" />
  <path fill="#ED9A00"
    d="M15 16.78a3.56 3.56 0 110-7.12 3.56 3.56 0 010 7.12zm0-6.1a2.54 2.54 0 100 5.08 2.54 2.54 0 000-5.08z" />
  <path fill="#36495F"
    d="M15 14.24a.5.5 0 01-.5-.51v-.51a.5.5 0 01.14-.36l.5-.5a.5.5 0 01.73.71l-.36.36v.3a.5.5 0 01-.51.5z" />
  <path fill="#86B4CC"
    d="M7.48 16.8c-1.04.06-3.83.4-4.14 2.19a.5.5 0 01-.03-.18V17.3c0-.28.22-.51.5-.51h3.56a.4.4 0 01.11.02z" />
  <path fill="#4397D5"
    d="M7.88 17.29v1.52a.5.5 0 01-.5.51H3.8a.51.51 0 01-.47-.33c.3-1.8 3.1-2.13 4.14-2.2.08.02.14.05.2.1.13.1.2.24.2.4z" />
  <path fill="#ED9A00" d="M13.47 23.9a1.02 1.02 0 100-2.04 1.02 1.02 0 000 2.04z" />
  <path fill="#86B4CC"
    d="M7.48 21.37c-1.04.07-3.83.4-4.14 2.2a.5.5 0 01-.03-.18v-1.53c0-.28.22-.5.5-.5h3.56a.4.4 0 01.11.01z" />
  <path fill="#4397D5"
    d="M7.88 21.86v1.53a.5.5 0 01-.5.5H3.8a.51.51 0 01-.47-.33c.3-1.79 3.1-2.12 4.14-2.19.08.02.14.05.2.1.13.09.2.24.2.4z" />
  <path fill="#86B4CC"
    d="M26.3 16.8c-1.05.06-3.84.4-4.15 2.19a.5.5 0 01-.03-.18V17.3c0-.28.23-.51.5-.51h3.57a.4.4 0 01.1.02z" />
  <path fill="#4397D5"
    d="M26.7 17.29v1.52a.5.5 0 01-.51.51h-3.56a.51.51 0 01-.48-.33c.3-1.8 3.1-2.13 4.15-2.2.07.02.14.05.2.1.12.1.2.24.2.4z" />
  <path fill="#86B4CC"
    d="M26.3 21.37c-1.05.07-3.84.4-4.15 2.2a.5.5 0 01-.03-.18v-1.53c0-.28.23-.5.5-.5h3.57a.4.4 0 01.1.01z" />
  <path fill="#4397D5"
    d="M26.7 21.86v1.53a.5.5 0 01-.51.5h-3.56a.51.51 0 01-.48-.33c.3-1.79 3.1-2.12 4.15-2.19.07.02.14.05.2.1.12.09.2.24.2.4z" />
  <path fill="#F85360" d="M22.12 28.98V30H7.88v-1.02c0-.56.46-1.01 1.02-1.01h12.2c.56 0 1.02.45 1.02 1.01z" />
</g>
<defs>
  <clipPath id="clip1">
    <path fill="#fff" d="M0 0h30v30H0z" />
  </clipPath>
</defs>`
};
