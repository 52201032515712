import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { tap, filter, first } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-write-message-modal',
  templateUrl: './write-message-modal.component.html',
  styleUrls: ['./write-message-modal.component.scss']
})
export class WriteMessageModalComponent {
  private dialogConfig: MatDialogConfig = {
    width: '400px',
    maxWidth: '1140px',
    minHeight: '245px',
    panelClass: 'custom-dialog'
  };

  public title = 'Are you sure?';

  @ViewChild('dialogBody', { static: true }) dialogBody: TemplateRef<any>;

  value: string;
  constructor(public dialog: MatDialog) {}

  openDialog(title?: string) {
    if (title) {
      this.title = title;
    }

    return this.dialog.open(this.dialogBody, this.dialogConfig);
  }

  confirm(title?: string) {
    return this.openDialog(title)
      .afterClosed()
      .pipe(
        filter((val) => val),
        tap(() => (this.value = '')),
        first()
      );
  }
}
