import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { Profile } from 'src/app/core/services/suggestions/suggestions.model';
import { RoutingService } from '../../../core/services/routing.service';

@Component({
  selector: 'app-suggested-profiles-right-bar',
  templateUrl: './suggested-profiles-right-bar.component.html',
  styleUrls: ['./suggested-profiles-right-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestedProfilesRightBarComponent {
  @Input() profiles: Profile[];

  @Output() connect = new EventEmitter<number>();
  @Output() disconnect = new EventEmitter<number>();

  constructor(private routing: RoutingService) {}

  onProfileConnect(id) {
    this.connect.emit(id);
  }

  onProfileDisconnect(id) {
    this.disconnect.emit(id);
  }

  public goToProfile(e: Event, profile) {
    e.preventDefault();
    this.routing.goToProfile(profile);
  }
}
