import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { environment } from 'src/environments/environment';

import { AmplitudeModule, NoopAmplitudeModule } from '../amplitude';
import { AppHideForModule } from '../hide-for/hide-for.module';
import { AppRouterLinkModule } from '../router-link/router-link.module';
import { LeftNavComponent } from './components';
import { CONFIG, IConfig } from './left-nav.config';

@NgModule({
  declarations: [LeftNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    environment.production
      ? AmplitudeModule.forRoot(environment.amplitude)
      : NoopAmplitudeModule,
    AppHideForModule,
    AppRouterLinkModule
  ],
  exports: [LeftNavComponent]
})
export class AppLeftNavModule {
  static forRoot(config: IConfig): ModuleWithProviders<AppLeftNavModule> {
    return {
      ngModule: AppLeftNavModule,
      providers: [
        {
          provide: CONFIG,
          useValue: config
        }
      ]
    };
  }
}
