import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ConvertNameService } from 'src/app/shared/services/convert-name.service';

export interface IPost {
  id: number;
  // TODO: use real type
  tags: Array<object>;
  raw_text: string;
}

@Injectable({
  providedIn: 'root'
})
export class PostLinkService {
  constructor(private readonly convertNameService: ConvertNameService) {}

  generatePostLink(post: IPost) {
    const postTextWithTagsProcessed = post.tags.reduce<string>(
      (acc: string, tag: any): string => {
        return acc.replace(tag.string, tag.text);
      },
      post.raw_text
    );

    // TODO: may contain emojies, this will lead to incorrect url
    const postURLText = this.convertNameService.convertName(
      postTextWithTagsProcessed
    );

    return [environment.routePrefix, 'post', post.id, postURLText];
  }
}
