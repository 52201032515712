import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AppBaseURLModule,
  AppLoaderModule,
  AppRouterLinkModule
} from 'src/app/shared/modules';

import { CompetitionsSharedModule } from '../../shared/shared.module';
import { CompetitionsStoreModule } from '../../store/competitions-store.module';
import Components from './components';
import Containers, { CompetitionsShortListComponent } from './containers';

@NgModule({
  declarations: [...Components, ...Containers],
  imports: [
    CommonModule,
    AppLoaderModule,
    AppRouterLinkModule,
    AppBaseURLModule,
    CompetitionsSharedModule,
    CompetitionsStoreModule
  ],
  exports: [CompetitionsShortListComponent]
})
export class CompetitionsShortListModule {}
