import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2
} from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: 'a[appIsActiveLink]'
})
export class IsActiveLinkDirective implements AfterViewInit, OnDestroy {
  @Input() activeLinkClass: string;

  private readonly componentDestroy$ = new Subject();

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly hostElement: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => _ instanceof NavigationEnd),
        distinctUntilChanged(
          (prev: NavigationEnd, curr: NavigationEnd) => prev.url === curr.url
        ),
        tap((_) => this.handleLocationChange(_.url))
      )
      .subscribe((_) => _);

    this.handleLocationChange(this.location.path());
  }

  private handleLocationChange(path: string) {
    const urlPath = this.getCleanPath(path);

    const currentPathWithoutBaseURL = this.hostElement.nativeElement.href.slice(
      this.hostElement.nativeElement.href.indexOf(
        this.hostElement.nativeElement.baseURI
      ) +
        this.hostElement.nativeElement.baseURI.length -
        1
    );

    if (currentPathWithoutBaseURL === urlPath) {
      return this.renderer.addClass(
        this.hostElement.nativeElement,
        this.activeLinkClass
      );
    }

    this.renderer.removeClass(
      this.hostElement.nativeElement,
      this.activeLinkClass
    );
  }

  private getCleanPath(path: string) {
    const urlObj = new URL(window.location.origin + path);

    return urlObj.pathname;
  }
}
