import { IIcon } from '../../model/icon';

export const student: IIcon = {
  id: `STUDENT`,
  svg: `
  <path fill="#F09372"
  d="M12.45 12v.13c0 1.18.95 2.17 2.2 2.29l1.15.2c1.25.12 2.2 1.1 2.2 2.29v2.23c0 .23-.1.45-.27.6a.95.95 0 01-.65.26H2.92a.95.95 0 01-.65-.25.83.83 0 01-.27-.61V16.9c0-1.18.95-2.17 2.2-2.29l1.15-.2a2.36 2.36 0 002.2-2.29V12h4.9z" />
<path fill="#2C2F38"
  d="M18 16.64v2.42c0 .25-.1.5-.28.67a1 1 0 01-.7.27H10v-2.26c.52 0 .94-.4.94-.9v-.45c0-.5-.41-.9-.93-.9l.5-.79.9 2.14L14.75 14l.92.16A2.53 2.53 0 0118 16.64zM10 17.74V20H2.87a.84.84 0 01-.62-.27.97.97 0 01-.25-.67v-2.42c0-1.27.9-2.35 2.08-2.48l.83-.16 2.98 2.84.82-2.14.44.78c-.46.01-.83.41-.83.9v.46c0 .5.37.9.84.9H10z" />
<path fill="#D1D4D1" d="M9 15l1 1.67L11 15l1 2.08V20H8v-2.92L9 15z" />
<path fill="#E64C3C"
  d="M9.67 16h.66c.37 0 .67.36.67.8v.4c0 .44-.3.8-.67.8h-.66c-.37 0-.67-.36-.67-.8v-.4c0-.44.3-.8.67-.8z" />
<path fill="#C03A2B" d="M11 20l-.55-2h-.9L9 20h2z" />
<path fill="#FDD7AD"
  d="M6.36 9.54A3.55 3.55 0 0010 13c2 0 3.64-1.55 3.64-3.46A2.23 2.23 0 0015 7.77c0-1.2-.76-1.3-1.36-.98v-1.2C13.64 4.17 12.42 3 10.9 3H9.09a2.66 2.66 0 00-2.73 2.6v1.18c-.6-.3-1.36-.2-1.36.98.1.78.62 1.45 1.36 1.78z" />
<path fill="#E6E7E8"
  d="M14.72 14.36l-3.02 2.66-.85-2.12 2.21-1.25c.38.37.87.6 1.39.65l.27.06zM9.15 14.9l-.85 2.12-3.02-2.66.27-.06a2.31 2.31 0 001.39-.65l2.21 1.25z" />
<path fill="#F0C419" d="M3.44 5.5a.35.35 0 01-.34-.34V2.74a.35.35 0 11.69 0v2.42c0 .19-.16.34-.35.34z" />
<path fill="#35495E"
  d="M13.8 4.81v1.38l-2.98 1.22c-.52.22-1.12.22-1.65 0L6.2 6.19V4.81c0-.67.24-1.31.68-1.81l.06-.07.1-.1.1-.1.12-.1.1-.06.13-.09.1-.07.14-.07.12-.05.14-.06.12-.04a2.15 2.15 0 01.27-.08l.19-.03.1-.01c.1-.02.2-.02.3-.02h2.07c.1 0 .2 0 .29.02h.1l.19.04c.04 0 .07.02.11.03a2.11 2.11 0 01.28.09l.14.06.12.05.14.07.1.07.13.09.1.07a3 3 0 01.12.1l.1.08.1.11.06.06c.44.51.68 1.15.68 1.82z" />
<path fill="#2C3E50"
  d="M16.68 3.06l-6.56 2.4a.36.36 0 01-.24 0l-6.56-2.4a.34.34 0 010-.64L9.88.02a.36.36 0 01.24 0l6.56 2.4a.34.34 0 010 .64z" />

  `
};
