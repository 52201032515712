import { createAction, props } from '@ngrx/store';
import { IMessage } from 'src/app/shared/components/message/message.component';
import { IPost, IPostForm } from 'src/app/shared/models/post';

import { Club, File, Photo, Resource } from './club.model';
import { Group } from '../groups/group.model';

export const selectClub = createAction(
  '[Clubs/API] Select Club',
  props<{ club: Club }>()
);

export const unselectClub = createAction('[Clubs/API] Unselect Club');

export const loadClubPhotos = createAction(
  '[Clubs/API] Load Club Photos',
  props<{ id: number; page: number }>()
);

export const loadClubPhotosSuccess = createAction(
  '[Clubs/API] Load Club Photos Success',
  props<{ id: number; count: number; photos: Photo[] }>()
);

export const loadClubFiles = createAction(
  '[Clubs/API] Load Club Files',
  props<{ id: number; page: number }>()
);

export const loadClubFilesSuccess = createAction(
  '[Clubs/API] Load Club Files Success',
  props<{ id: number; count: number; files: File[] }>()
);

export const attachPhotos = createAction(
  '[Clubs/API] Attach Photos',
  props<{ id: number; photos: Photo[] }>()
);

export const attachPhotosSuccess = createAction(
  '[Clubs/API] Attach Photos Success',
  props<{ id: number; photos: Photo[] }>()
);

export const removePhoto = createAction(
  '[Clubs/API] Remove Photo',
  props<{ id: number; photoID: number }>()
);

export const removePhotoSuccess = createAction(
  '[Clubs/API] Remove Photo Success',
  props<{ id: number; photoID: number }>()
);

export const attachFiles = createAction(
  '[Clubs/API] Attach Files',
  props<{ id: number; files: File[] }>()
);

export const attachFilesSuccess = createAction(
  '[Clubs/API] Attach Files Success',
  props<{ id: number; files: File[] }>()
);

export const removeFile = createAction(
  '[Clubs/API] Remove Files',
  props<{ id: number; fileID: number }>()
);

export const removeFilesSuccess = createAction(
  '[Clubs/API] Remove Files Success',
  props<{ id: number; fileID: number }>()
);

export const loadResources = createAction(
  '[Clubs/API] Load Resources',
  props<{ id: number; page: number }>()
);

export const loadResourcesSuccess = createAction(
  '[Clubs/API] Load Resources Success',
  props<{ id: number; resources: Resource[] }>()
);

export const loadResourcesSuccessNoPermissionsError = createAction(
  '[Clubs/API] Load Resources No Permissions Error',
  props<{ id: number }>()
);

export const postResource = createAction(
  '[Clubs/API] Post Resource',
  props<{ id: number; message: IMessage }>()
);

export const postResourceSuccess = createAction(
  '[Clubs/API] Post Resource Success',
  props<{ id: number; post: IPost }>()
);

export const likeResource = createAction(
  '[Clubs/API] Like Resource',
  props<{ id: number; resourceID: number }>()
);

export const likeResourceSuccess = createAction(
  '[Clubs/API] Like Resource Success',
  props<{ id: number; resourceID: number; amountOfLikes: number }>()
);

export const updateResource = createAction(
  '[Clubs/API] Update Resource',
  props<{ id: number; resourceID: number; post: IPostForm }>()
);

export const updateResourceSuccess = createAction(
  '[Clubs/API] Update Resource Success',
  props<{ id: number; resourceID: number; post: IPost }>()
);

export const deleteResource = createAction(
  '[Clubs/API] Delete Resource',
  props<{ id: number; resourceID: number }>()
);

export const deleteResourceSuccess = createAction(
  '[Clubs/API] Delete Resource Success',
  props<{ id: number; resourceID: number }>()
);

export const saveResource = createAction(
  '[Clubs/API] Save Resource',
  props<{ id: number; resourceID: number }>()
);

export const saveResourceSuccess = createAction(
  '[Clubs/API] Save Resource Success',
  props<{ id: number; resourceID: number }>()
);

export const unsaveResource = createAction(
  '[Clubs/API] Un-Save Resource',
  props<{ id: number; resourceID: number }>()
);

export const unsaveResourceSuccess = createAction(
  '[Clubs/API] Un-Save Resource Success',
  props<{ id: number; resourceID: number }>()
);

export const searchClubs = createAction(
  '[Clubs/API] Search Clubs',
  props<{ searchParams: {} }>()
);

export const searchClubsSuccess = createAction(
  '[Clubs/API] Search Clubs Success',
  props<{ count: number; clubs: { [id: string]: Club } }>()
);
