import { IIcon } from '../../model/icon';

export const profilePlus: IIcon = {
  id: `PROFILE_PLUS`,
  svg: `
  <rect width="36" height="36" fill="#F2F2F2" rx="18" />
  <path fill="#BDBDBD"
    d="M22.7 16.2h-2.89v-2.9a1.31 1.31 0 00-2.62 0v2.89h-2.9a1.31 1.31 0 000 2.62h2.9v2.9a1.31 1.31 0 002.62 0v-2.9h2.9a1.31 1.31 0 000-2.62z" />
  `
};
