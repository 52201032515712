import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

type IGoogleAuth2 = any;

export interface IGoogleAuthData {
  token: string;
  user: null | {
    firstName: string;
    lastName: string;
  };
}

@Injectable()
export class GoogleAuthService {
  login(): Observable<IGoogleAuthData> {
    // @ts-ignore
    return this.loadLibrary().pipe(mergeMap((_) => from(this.processLogin(_))));
  }

  private loadLibrary(): Observable<IGoogleAuth2> {
    return new Observable((observer) => {
      // @ts-ignore
      gapi.load('auth2', () => {
        // @ts-ignore
        observer.next(gapi.auth2);
        observer.complete();
      });
    });
  }

  private async processLogin(
    GoogleAuth2: IGoogleAuth2
  ): Promise<null | IGoogleAuthData> {
    const auth2 = GoogleAuth2.init({
      client_id: environment.auth.googleClientID,
      scope: 'profile email'
    });

    try {
      const res = await auth2.signIn({ prompt: 'select_account' });
      if (!res) {
        throw new Error('Invalid google signin');
      }

      const { id_token: token } = res.getAuthResponse();
      const firstName = res.getBasicProfile()?.getGivenName() || null;
      const lastName = res.getBasicProfile()?.getFamilyName() || null;

      return {
        token,
        user: { firstName, lastName }
      };
    } catch (error) {
      if (error.error === 'popup_closed_by_user') {
        return null;
      }

      throw error;
    }
  }
}
