import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// NOTE: use root provider
// cuz we need to import this module is every module where we need isCurrentAccount$,
// otherwise it would be a copy of this service

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly _account$ = new BehaviorSubject<null | IAccount>(null);

  get account$() {
    return this._account$;
  }

  setCurrentAccount(account: IAccount) {
    this._account$.next(account);
  }
}

interface IAccount {
  id: number;
}
