import { createAction, props } from '@ngrx/store';

import { IOrganization } from 'src/app/shared/models/user/organization';
import { Member, Chapter, Job, Review } from './organization.model';
import { ResponseMessage } from '../../models/base-model.model';

export const clearOrganization = createAction(
  '[Organization/API] Organization Clear'
);

export const loadOrganization = createAction(
  '[Organization/API] Organization',
  props<{ id: string | number }>()
);

export const loadOrganizationSuccess = createAction(
  '[Organization/API] Organization Success',
  props<{ organization: IOrganization }>()
);

export const getMembers = createAction(
  '[Organization/API] Members',
  props<{ id: number; page: number }>()
);

export const getMembersSuccess = createAction(
  '[Organization/API] Members Success',
  props<{ members: { [id: number]: Member }; count: number }>()
);

export const getOrgMembers = createAction(
  '[Organization/API] Org Members',
  props<{ id: string; page: number; searchTerm: string }>()
);

export const getOrgMembersSuccess = createAction(
  '[Organization/API] Org Members Success',
  props<{ members: { [id: number]: Member }; count: number }>()
);

export const loadOrgChapters = createAction(
  '[Organization/API] Org Chapters',
  props<{ id: string; page: number; searchTerm: string }>()
);

export const loadOrgChaptersSuccess = createAction(
  '[Organization/API] Org Chapters Success',
  props<{ chapters: { [id: number]: Chapter }; count: number }>()
);

export const loadOrgJobs = createAction(
  '[Organization/API] Org Jobs',
  props<{ id: string; page: number }>()
);

export const loadOrgJobsSuccess = createAction(
  '[Organization/API] Org Jobs Success',
  props<{ jobs: { [id: number]: Job }; count: number; page: number }>()
);

export const createJob = createAction(
  '[Organization/API] Create Job',
  props<{ job: Job }>()
);

export const createJobSuccess = createAction(
  '[Organization/API] Create Job Success',
  props<{ job: Job }>()
);

export const editJob = createAction(
  '[Organization/API] Edit Job',
  props<{ job: Job }>()
);

export const editJobSuccess = createAction(
  '[Organization/API] Edit Job Success',
  props<{ job: Job }>()
);

export const deleteJob = createAction(
  '[Organization/API] Delete Job',
  props<{ job: Job }>()
);

export const deleteJobSuccess = createAction(
  '[Organization/API] Delete Job Success',
  props<{ job: Job }>()
);

export const inviteToProject = createAction(
  '[Organization/API] Invite To Project',
  props<{ email: string; orgId: string }>()
);

export const loadOrgReviews = createAction(
  '[Organization/API] Org Reviews',
  props<{ id: string }>()
);

export const loadOrgReviewsSuccess = createAction(
  '[Organization/API] Org Reviews Success',
  props<{ reviews: Review[] }>()
);

export const addReview = createAction(
  '[Organization/API] Add Review',
  props<{ obj_id: number; text: string; is_anonymous: boolean }>()
);

export const addReviewSuccess = createAction(
  '[Organization/API] Add Review Success',
  props<{ review: Review }>()
);
