import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import moment from 'moment/moment';
import { IEditorCtx } from '../../../../../shared/form';
import {
  IArticle,
  ICompetitionProject,
  INewCompetitionProject
} from '../../../../projects/model';
import { CreateNewArticleDialogComponent } from '../../../../projects/shared/dialogs';
import { BaseUrlPipe } from '../../../../../shared/pipes/base-url/base-url.pipe';
import { RoutingService } from '../../../../../core/services/routing.service';

@Component({
  selector: 'app-competition-create-project',
  templateUrl: './competition-create-project-dialog.component.html',
  styleUrls: ['./competition-create-project-dialog.component.scss']
})
export class CompetitionCreateProjectDialogComponent
  implements OnInit, OnDestroy
{
  private readonly _destroy$ = new Subject();
  private readonly _article = new BehaviorSubject<IArticle>(null);

  loadedImage: string | ArrayBuffer = null;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      categoryId: number;
      user_id: number;
      competition_id: number;
      project: ICompetitionProject;
    },
    private readonly dialog: MatDialog,
    private readonly baseUrl: BaseUrlPipe,
    private readonly routing: RoutingService,
    private readonly router: Router,
    private cd: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CompetitionCreateProjectDialogComponent>
  ) {
    this._article
      .pipe(
        tap((_) => this.onArticle(_)),
        takeUntil(this._destroy$)
      )
      .subscribe((_) => _);
  }

  get currentImage() {
    if (this.competitionProjectForm.value.image instanceof File) {
      return null;
    }

    return this.competitionProjectForm.value.image;
  }

  get isArticle$() {
    return this._article.pipe(map((_) => !!_));
  }

  get isEdit() {
    return !!this.data.project;
  }

  readonly fields = {
    image: new FormControl(),
    title: new FormControl('', Validators.required),
    topic: new FormControl(''),
    start_at: new FormControl('', Validators.required),
    end_at: new FormControl('', Validators.required),
    url: new FormControl(''),
    description: new FormControl('', Validators.required),
    description_for_jury: new FormControl('', Validators.required)
  };

  readonly description: IEditorCtx = {
    control: this.fields.description
  };

  readonly description_for_jury: IEditorCtx = {
    control: this.fields.description_for_jury
  };

  competitionProjectForm = new FormGroup(this.fields);

  get minDate(): Date {
    if (this.isEdit) {
      return new Date(0);
    } else {
      return new Date();
    }
  }

  get minEndDate(): Date {
    if (this.competitionProjectForm.value.start_at) {
      return this.competitionProjectForm.value.start_at;
    }

    return new Date();
  }

  get maxDate(): Date {
    const cd = new Date();

    cd.setFullYear(cd.getFullYear() + 1);

    return cd;
  }
  ngOnInit(): void {
    if (this.data.project) {
      this.competitionProjectForm.patchValue(this.data.project);
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onImageChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      const that = this;
      reader.onload = function () {
        that.competitionProjectForm.patchValue({
          image: file
        });

        that.loadedImage = this.result;

        that.cd.markForCheck();
      };
    }
  }

  onSubmit() {
    const dateStart = moment(this.competitionProjectForm.value.start_at).format(
      'YYYY-MM-DD'
    );
    const dateEnd = moment(this.competitionProjectForm.value.end_at).format(
      'YYYY-MM-DD'
    );

    const response: INewCompetitionProject & { id?: number } = {
      id: this.data?.project?.id,
      competition_id: this.data.competition_id,
      title: this.competitionProjectForm.value.title,
      topic: this.competitionProjectForm.value.topic,
      type_id: 3, // competition project
      category_id: this.data.categoryId,
      user_id: this.data.user_id,
      image: this.competitionProjectForm.value.image,
      description: this.competitionProjectForm.value.description,
      start_at: dateStart,
      end_at: dateEnd,
      description_for_jury:
        this.competitionProjectForm.value.description_for_jury,
      article_id: null,
      url: null
    };

    if (this._article.value) {
      response.article_id = this._article.value.id;
      response.url = null;
    } else {
      response.url = this.competitionProjectForm.value.url;
      response.article_id = null;
    }

    this.dialogRef.close(response);
  }

  createNewArticle() {
    const dialogRef = this.dialog.open<
      CreateNewArticleDialogComponent,
      void,
      IArticle
    >(CreateNewArticleDialogComponent);

    return dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this._destroy$),
        filter((_) => !!_),
        tap((_) => this._article.next(_))
      )
      .subscribe((_) => _);
  }

  removeArticle() {
    this._article.next(null);
  }

  private onArticle(article?: IArticle) {
    if (!article) {
      this.competitionProjectForm.get('url').patchValue(null);
      this.competitionProjectForm.get('url').enable({ onlySelf: true });

      this.competitionProjectForm.updateValueAndValidity();

      return;
    }

    const articleRawLink = this.routing.getDiscussionLink(
      article.id,
      article.name
    );
    const articleLink = this.router.serializeUrl(
      this.router.createUrlTree(articleRawLink)
    );

    this.competitionProjectForm
      .get('url')
      .patchValue(this.baseUrl.transform(articleLink));
    this.competitionProjectForm.get('url').disable({ onlySelf: true });

    this.competitionProjectForm.updateValueAndValidity();
  }
}
