<div
  class="notification-box"
  #notificationsContainer
  (scroll)="onNotificationsScroll()"
  (click)="$event.stopPropagation()"
>
  <ng-container *ngIf="!notifications">
    <app-loader-2></app-loader-2>
  </ng-container>

  <ng-container *ngIf="notifications">
    <div class="notification-tabs d-flex">
      <div>Your notifications</div>
    </div>
    <div class="notification-wrapper">
      <div
        class="notification-item d-flex"
        [ngClass]="{'new' : !notification.status}"
        *ngFor="let notification of notifications"
      >
        <a [href]="notification.obj.url" target="_blank" *ngIf="notification.obj?.url; else plainText">
          <ng-container [ngTemplateOutlet]="notifTemplate" [ngTemplateOutletContext]="{ $implicit: notification }">
          </ng-container>
        </a>
        <ng-template #plainText>
          <ng-container [ngTemplateOutlet]="notifTemplate" [ngTemplateOutletContext]="{ $implicit: notification }">
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #notifTemplate let-notif>
  <a [profile]="notif.user">
    <app-avatar [src]="notif.user.icon" [placeholder]="notif.user | initials" class="ava medium"> </app-avatar>
  </a>

  <div class="d-flex flex-column justify-content-center w-100">
    <div class="title" [innerHTML]="notif.description"></div>
    <div class="date"> {{ notif.created_at | timeAgo: true }} </div>
  </div>
</ng-template>
