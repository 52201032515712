import { IIcon } from '../../model/icon';

export const commentWb: IIcon = {
  id: `COMMENT_WB`,
  svg: `
  <path fill="#BDBDBD"
  d="M16.55 6.87v7.3c0 .93-.76 1.7-1.7 1.7H7.6l-3.45 3.79v-3.8H1.7a1.7 1.7 0 01-1.7-1.7V6.87c0-.94.76-1.7 1.7-1.7h13.15c.94 0 1.7.76 1.7 1.7z" />
<path fill="#828282"
  d="M18.3.34H5.15c-.94 0-1.7.77-1.7 1.7v3.13h11.4c.94 0 1.7.76 1.7 1.7v4.16h1.75c.94 0 1.7-.76 1.7-1.7V2.04c0-.93-.76-1.7-1.7-1.7z" />
<path fill="#fff"
  d="M3.8 11.72a1.03 1.03 0 100-2.06 1.03 1.03 0 000 2.06zM8.28 11.72a1.03 1.03 0 100-2.06 1.03 1.03 0 000 2.06zM12.76 11.72a1.03 1.03 0 100-2.06 1.03 1.03 0 000 2.06z" />
`
};
