import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ChatsListActions, MessageActions } from '../actions';
import { IMessage } from '../../model';

interface T {
  page: number;
  messages: IMessage[];
}

export const featureAdapter: EntityAdapter<T> = createEntityAdapter<T>({
  selectId: (model) => model.page,
  sortComparer: (a: T, b: T): number => b.page - a.page
});

export interface State extends EntityState<T> {
  nextPageToLoad: number;
  isLoadingMessages: boolean;
  isLoadedMessages: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  nextPageToLoad: 0,
  isLoadingMessages: false,
  isLoadedMessages: false
});

export function Reducer(
  state = initialState,
  action: ChatsListActions.Actions | MessageActions.Actions
): State {
  switch (action.type) {
    case MessageActions.ActionTypes.LOAG_MESSAGES:
      return {
        ...state,
        isLoadingMessages: true
      };
    case MessageActions.ActionTypes.NEW_MESSAGE_FOR_CHANNEL_SUCCESS:
      const firstChunkId = 0;
      const firstChunk = state.entities[firstChunkId];
      if (!firstChunk) {
        return state;
      }

      return featureAdapter.updateOne(
        {
          id: firstChunk.page,
          changes: {
            messages: [...firstChunk.messages, action.payload.message]
          }
        },
        {
          ...state
        }
      );
    case MessageActions.ActionTypes.LOAD_MESSAGES_SUCCESS:
      return featureAdapter.addOne(
        { page: action.payload.page, messages: action.payload.messages },
        {
          ...state,
          nextPageToLoad: action.payload.nextPageToLoad,
          isLoadingMessages: false,
          isLoadedMessages: true
        }
      );
    case MessageActions.ActionTypes.CLEAN_MESSAGES:
      return {
        ...initialState,
        isLoadingMessages: false,
        isLoadedMessages: false
      };
    default:
      return state;
  }
}

export const isLoadingMessages = (state: State) => state.isLoadingMessages;
export const isLoadedMessages = (state: State) => state.isLoadedMessages;
export const nextMessagesPageToLoad = (state: State) => state.nextPageToLoad;
export const { selectAll: getMessagesChunks } = featureAdapter.getSelectors();
