<app-post *ngFor="let post of posts; trackBy: identify" [post]="post" [ctx]="ctx"></app-post>

<ng-container *ngIf="status$ | async as status">
  <section class="paginator" [visibility]="paginate">
    <ng-container [ngSwitch]="true">
      <app-loader-2 *ngSwitchCase="status === 'MERGING' || status === 'LOADING'"></app-loader-2>
      <app-no-posts
        *ngSwitchCase="status === 'EMPTY' || status === 'NO_PERMISSIONS'"
        class="d-block theme-block"
      ></app-no-posts>
    </ng-container>
  </section>
</ng-container>
