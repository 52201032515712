import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';

import { CompetitionActions, CompetitionsActions } from './actions';
import { CompetitionsService } from './service';

@Injectable()
export class CompetitionStoreEffects {
  constructor(
    private readonly competitionsService: CompetitionsService,
    private readonly actions$: Actions,
    private readonly authService: AuthService
  ) {}

  @Effect()
  loadCurrentCompetitions$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionsActions.LoadCurrentCompetitionsAction>(
        CompetitionsActions.ActionTypes.LOAD_CURRENT_COMPETITIONS
      ),
      switchMap(({ payload: { page } }) =>
        this.competitionsService.loadCurrentCompetitions(page).pipe(
          switchMap(({ competitions, totalAmount }) => [
            new CompetitionsActions.LoadCurrentCompetitionsSuccessAction({
              competitions,
              totalAmount,
              page
            })
          ]),
          catchError((_) => [
            new CompetitionsActions.LoadCurrentCompetitionsFailureAction({
              error: _.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadUpcomingCompetitions$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionsActions.LoadUpcomingCompetitionsAction>(
        CompetitionsActions.ActionTypes.LOAD_UPCOMING_COMPETITIONS
      ),
      switchMap(({ payload: { page } }) =>
        this.competitionsService.loadUpcomingCompetitions(page).pipe(
          switchMap(({ competitions, totalAmount }) => [
            new CompetitionsActions.LoadUpcomingCompetitionsSuccessAction({
              competitions,
              totalAmount,
              page
            })
          ]),
          catchError((_) => [
            new CompetitionsActions.LoadUpcomingCompetitionsFailureAction({
              error: _.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadMyCompetitionsOnSignin$ = this.authService.user$.pipe(
    // TODO: Refactor this weird thing with the stub user
    // in which id equals to 0
    filter((_) => !!_ && !!_.id),
    switchMap((_) => [
      new CompetitionsActions.LoadMyCompetitionsAction({ page: 1 })
    ])
  );

  @Effect()
  clearMyCompetitionsOnSignout$ = this.authService.user$.pipe(
    // TODO: Refactor this weird thing with the stub user
    // in which id equals to 0
    filter((_) => !_ || !_.id),
    switchMap((_) => [new CompetitionsActions.CleanMyCompetitionsAction()])
  );

  @Effect()
  loadMyCompetitions$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionsActions.LoadMyCompetitionsAction>(
        CompetitionsActions.ActionTypes.LOAD_MY_COMPETITIONS
      ),
      switchMap(({ payload: { page } }) =>
        this.competitionsService.loadMyCompetitions(page).pipe(
          switchMap(({ competitions, totalAmount }) => [
            new CompetitionsActions.LoadMyCompetitionsSuccessAction({
              competitions,
              totalAmount,
              page
            })
          ]),
          catchError((_) => [
            new CompetitionsActions.LoadMyCompetitionsFailureAction({
              error: _.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadPreviousCompetitions$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionsActions.LoadPreviousCompetitionsAction>(
        CompetitionsActions.ActionTypes.LOAD_PREVIOUS_COMPETITIONS
      ),
      switchMap(({ payload: { page } }) =>
        this.competitionsService.loadPreviousCompetitions(page).pipe(
          switchMap(({ competitions, totalAmount }) => [
            new CompetitionsActions.LoadPreviousCompetitionsSuccessAction({
              competitions,
              totalAmount,
              page
            })
          ]),
          catchError((_) => [
            new CompetitionsActions.LoadPreviousCompetitionsFailureAction({
              error: _.message
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadCompetitionsShortList$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.LoadCompetitionAction>(
        CompetitionsActions.ActionTypes.LOAD_COMPETITIONS_SHORT_LIST
      ),
      switchMap((params) =>
        this.competitionsService.loadCompetitionsShortList().pipe(
          switchMap(({ current, upcoming, previous }) => [
            new CompetitionsActions.LoadCompetitionsShortListSuccessAction({
              current,
              upcoming,
              previous
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.LoadCompetitionAction>(
        CompetitionActions.ActionTypes.LOAD_COMPETITION
      ),
      switchMap((params) =>
        this.competitionsService.loadCompetition(params.payload.id).pipe(
          switchMap((competition) => [
            new CompetitionActions.LoadCompetitionSuccessAction({
              competition
            })
          ])
        )
      )
    )
  );

  @Effect()
  loadCompetitionParticipants$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.LoadCompetitionParticipantsAction>(
        CompetitionActions.ActionTypes.LOAD_COMPETITION_PARTICIPANTS
      ),
      switchMap((params) =>
        this.competitionsService
          .loadCompetitionParticipants(params.payload.id, params.payload.page)
          .pipe(
            switchMap(({ participants, totalAmount }) => [
              new CompetitionActions.LoadCompetitionParticipantsSuccessAction({
                participants,
                totalAmount
              })
            ])
          )
      )
    )
  );

  @Effect()
  loadCompetitionRequests$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.LoadCompetitionRequestsAction>(
        CompetitionActions.ActionTypes.LOAD_COMPETITION_REQUESTS
      ),
      switchMap((params) =>
        this.competitionsService
          .loadCompetitionRequests(params.payload.id, params.payload.page)
          .pipe(
            switchMap(({ requests, totalAmount }) => [
              new CompetitionActions.LoadCompetitionRequestsSuccessAction({
                requests,
                totalAmount
              })
            ])
          )
      )
    )
  );

  @Effect()
  loadCompetitionReviews$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.LoadCompetitionReviewsAction>(
        CompetitionActions.ActionTypes.LOAD_COMPETITION_REVIEWS
      ),
      switchMap((params) =>
        this.competitionsService
          .loadCompetitionReviews(params.payload.id, params.payload.page)
          .pipe(
            switchMap(({ reviews, totalAmount }) => [
              new CompetitionActions.LoadCompetitionReviewsSuccessAction({
                reviews,
                totalAmount
              })
            ])
          )
      )
    )
  );

  @Effect()
  loadCompetitionJurySubmissions$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.LoadCompetitionJurySubmissionsAction>(
        CompetitionActions.ActionTypes.LOAD_COMPETITION_JURY_SUBMISSIONS
      ),
      switchMap((params) =>
        this.competitionsService
          .loadCompetitionJurySubmissions(
            params.payload.token,
            params.payload.juryId,
            params.payload.competitionId,
            params.payload.page
          )
          .pipe(
            switchMap(({ reviews, totalAmount }) => [
              new CompetitionActions.LoadCompetitionReviewsSuccessAction({
                reviews,
                totalAmount
              })
            ])
          )
      )
    )
  );

  @Effect()
  createCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.CreateCompetitionAction>(
        CompetitionActions.ActionTypes.CREATE_COMPETITION
      ),
      switchMap((params) =>
        this.competitionsService
          .createCompetition(params.payload.competition)
          .pipe(
            switchMap(({ competition }) => [
              new CompetitionActions.CreateCompetitionSuccessAction({
                competition
              })
            ])
          )
      )
    )
  );

  @Effect()
  updateCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.UpdateCompetitionAction>(
        CompetitionActions.ActionTypes.UPDATE_COMPETITION
      ),
      switchMap((params) =>
        this.competitionsService
          .updateCompetition(params.payload.id, params.payload.competition)
          .pipe(
            switchMap(({ competition }) => [
              new CompetitionActions.UpdateCompetitionSuccessAction({
                competition
              })
            ]),
            catchError((error) => {
              return of(
                new CompetitionActions.UpdateCompetitionFailureAction({ error })
              );
            })
          )
      )
    )
  );

  @Effect()
  joinCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.JoinCompetitionAction>(
        CompetitionActions.ActionTypes.JOIN_COMPETITION
      ),
      switchMap((params) =>
        this.competitionsService
          .joinCompetition(params.payload.id, params.payload.groupID)
          .pipe(
            switchMap((_) => [
              new CompetitionActions.JoinCompetitionSuccessAction({})
            ])
          )
      )
    )
  );

  @Effect()
  leaveCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.LeaveCompetitionAction>(
        CompetitionActions.ActionTypes.LEAVE_COMPETITION
      ),
      switchMap((params) =>
        this.competitionsService
          .leaveCompetition(params.payload.id, params.payload.groupID)
          .pipe(
            switchMap((_) => [
              new CompetitionActions.LeaveCompetitionSuccessAction({})
            ])
          )
      )
    )
  );

  @Effect()
  acceptParticipantCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.AcceptCompetitionRequestAction>(
        CompetitionActions.ActionTypes.ACCEPT_COMPETITION_REQUEST
      ),
      switchMap((params) =>
        this.competitionsService
          .acceptCompetitionRequest(params.payload.id, params.payload.groupID)
          .pipe(
            switchMap((_) => [
              new CompetitionActions.AcceptCompetitionRequestSuccessAction({
                groupID: params.payload.groupID
              })
            ])
          )
      )
    )
  );

  @Effect()
  declineParticipantCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.DeclineCompetitionRequestAction>(
        CompetitionActions.ActionTypes.DECLINE_COMPETITION_REQUEST
      ),
      switchMap((params) =>
        this.competitionsService
          .declineCompetitionRequest(params.payload.id, params.payload.groupID)
          .pipe(
            switchMap((_) => [
              new CompetitionActions.DeclineCompetitionRequestSuccessAction({
                groupID: params.payload.groupID
              })
            ])
          )
      )
    )
  );

  @Effect()
  removeParticipantCompetition$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.RemoveCompetitionParticipantAction>(
        CompetitionActions.ActionTypes.REMOVE_COMPETITION_PARTICIPANT
      ),
      switchMap((params) =>
        this.competitionsService
          .removeCompetitionParticipant(
            params.payload.id,
            params.payload.groupID
          )
          .pipe(
            switchMap((_) => [
              new CompetitionActions.RemoveCompetitionParticipantSuccessAction({
                groupID: params.payload.groupID
              })
            ])
          )
      )
    )
  );

  @Effect()
  submitCompetitionNews$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.SubmitCompetitionNewsAction>(
        CompetitionActions.ActionTypes.SUBMIT_COMPETITION_NEW
      ),
      switchMap((params) =>
        this.competitionsService
          .submitNews(params.payload.id, params.payload.message)
          .pipe(
            switchMap((_) => [
              new CompetitionActions.SubmitCompetitionNewsSuccessAction({})
            ])
          )
      )
    )
  );

  @Effect()
  submitCompetitionResult$: Observable<Action> = this.actions$.pipe(() =>
    this.actions$.pipe(
      ofType<CompetitionActions.SubmitCompetitionResultAction>(
        CompetitionActions.ActionTypes.SUBMIT_COMPETITION_RESULT
      ),
      switchMap((params) =>
        this.competitionsService.submitResult(params.payload.result).pipe(
          switchMap((_) => [
            new CompetitionActions.SubmitCompetitionResultSuccessAction({
              result: _.result
            })
          ])
        )
      )
    )
  );

  @Effect()
  addCompetitionTeam$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.AddCompetitionTeamAction>(
      CompetitionActions.ActionTypes.ADD_COMPETITION_TEAM
    ),
    switchMap((params) =>
      this.competitionsService.createTeam(params.payload.team).pipe(
        switchMap((_) => {
          return this.competitionsService
            .addTeamToCompetitions({
              competitionId: params.payload.team.competitionId,
              teamID: _.team.id
            })
            .pipe(
              switchMap(() => [
                new CompetitionActions.AddCompetitionTeamSuccessAction({
                  team: _.team,
                  competitionId: params.payload.team.competitionId
                })
              ])
            );
        })
      )
    )
  );

  @Effect()
  addCompetitionTeamSuccess$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.AddCompetitionTeamSuccessAction>(
      CompetitionActions.ActionTypes.ADD_COMPETITION_TEAM_SUCCESS
    ),
    switchMap((params) =>
      this.competitionsService
        .loadCompetition(params.payload.competitionId)
        .pipe(
          switchMap((competition) => [
            new CompetitionActions.LoadCompetitionSuccessAction({
              competition
            })
          ])
        )
    )
  );

  @Effect()
  confirmCompetitionTeam$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.ConfirmCompetitionTeamAction>(
      CompetitionActions.ActionTypes.CONFIRM_COMPETITION_TEAM
    ),
    switchMap((params) =>
      this.competitionsService
        .confirmCompetitionTeam({ team: params.payload.team })
        .pipe(
          switchMap((_) => [
            new CompetitionActions.ConfirmCompetitionTeamSuccessAction({
              team: _.team
            })
          ])
        )
    )
  );

  @Effect()
  rejectCompetitionTeam$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.RejectCompetitionTeamAction>(
      CompetitionActions.ActionTypes.REJECT_COMPETITION_TEAM
    ),
    switchMap((params) =>
      this.competitionsService
        .rejectCompetitionTeam({ team: params.payload.team })
        .pipe(
          switchMap((_) => [
            new CompetitionActions.RejectCompetitionTeamSuccessAction({
              team: _.team
            })
          ])
        )
    )
  );

  @Effect()
  removeCompetitionTeam$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.RemoveCompetitionTeamAction>(
      CompetitionActions.ActionTypes.REMOVE_COMPETITION_TEAM
    ),
    switchMap((params) =>
      this.competitionsService
        .removeCompetitionTeam({ team: params.payload.team })
        .pipe(
          switchMap((_) => [
            new CompetitionActions.RemoveCompetitionTeamSuccessAction({
              team: _.team
            })
          ])
        )
    )
  );

  @Effect()
  loadCompetitionTeams$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.LoadCompetitionTeamsAction>(
      CompetitionActions.ActionTypes.LOAD_COMPETITION_TEAMS
    ),
    switchMap((params) =>
      this.competitionsService
        .loadCompetitionTeams({
          competitionId: params.payload.competitionId,
          page: params.payload.page,
          term: params.payload.term
        })
        .pipe(
          switchMap((payload) => [
            new CompetitionActions.LoadCompetitionTeamsSuccessAction(payload)
          ])
        )
    )
  );

  @Effect()
  findMembers$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.FindMembersAction>(
      CompetitionActions.ActionTypes.FIND_MEMBERS
    ),
    switchMap((params) =>
      this.competitionsService
        .findMembers(params.payload)
        .pipe(
          switchMap((payload) => [
            new CompetitionActions.FindMembersSuccessAction(payload)
          ])
        )
    )
  );

  @Effect()
  loadMyGroups$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.LoadMyGroupsSuccessAction>(
      CompetitionActions.ActionTypes.LOAD_MY_GROUPS
    ),
    switchMap((params) =>
      this.competitionsService
        .getMyGroups()
        .pipe(
          switchMap((payload) => [
            new CompetitionActions.LoadMyGroupsSuccessAction(payload)
          ])
        )
    )
  );

  @Effect()
  addJury$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.AddJuryAction>(
      CompetitionActions.ActionTypes.ADD_JURY
    ),
    switchMap((params) =>
      this.competitionsService
        .addJury({ jury: params.payload.jury })
        .pipe(
          switchMap((_) => [new CompetitionActions.AddJurySuccessAction(_)])
        )
    )
  );

  @Effect()
  editJury$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.EditJuryAction>(
      CompetitionActions.ActionTypes.EDIT_JURY
    ),
    switchMap((params) =>
      this.competitionsService
        .editJury({ jury: params.payload.jury })
        .pipe(
          switchMap((_) => [new CompetitionActions.EditJurySuccessAction(_)])
        )
    )
  );

  @Effect()
  deleteJury$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.DeleteJuryAction>(
      CompetitionActions.ActionTypes.DELETE_JURY
    ),
    switchMap((params) =>
      this.competitionsService
        .deleteJury({ juriId: params.payload.jury.id })
        .pipe(
          switchMap((_) => [
            new CompetitionActions.DeleteJurySuccessAction({
              jury: params.payload.jury
            })
          ])
        )
    )
  );

  @Effect()
  loadJury$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.LoadJuryAction>(
      CompetitionActions.ActionTypes.LOAD_JURY
    ),
    switchMap((params) =>
      this.competitionsService
        .loadJuri({ competitionId: params.payload.competitionId })
        .pipe(
          switchMap(({ jury }) => [
            new CompetitionActions.LoadJurySuccessAction({ jury })
          ])
        )
    )
  );

  @Effect()
  updateSubmission$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.UpdateCompetitionResultAction>(
      CompetitionActions.ActionTypes.UPDATE_COMPETITION_RESULT
    ),
    switchMap((params) =>
      this.competitionsService
        .updateSubmission({ result: params.payload.submission })
        .pipe(
          switchMap(({ result }) => [
            new CompetitionActions.UpdateCompetitionResultSuccessAction({
              submission: result
            })
          ])
        )
    )
  );

  @Effect()
  rateSubmission$: Observable<Action> = this.actions$.pipe(
    ofType<CompetitionActions.RateCompetitionResultAction>(
      CompetitionActions.ActionTypes.RATE_COMPETITION_RESULT
    ),
    switchMap((params) =>
      this.competitionsService
        .rateSubmission({
          result: params.payload.submission,
          jury: params.payload.jury
        })
        .pipe(
          switchMap(({ result }) => [
            new CompetitionActions.RateCompetitionResultSuccessAction({
              submission: result
            })
          ])
        )
    )
  );
}
