import { createReducer, on } from '@ngrx/store';
import { imagesInitialState, imagesAdapter } from './images.state';
import { loadImagesSuccess, addImagesSuccess } from './images.actions';

export const imagesReducer = createReducer(
  imagesInitialState,
  on(loadImagesSuccess, (state, action) =>
    imagesAdapter.setAll(action.images, {
      ...state
    })
  ),
  on(addImagesSuccess, (state, action) =>
    imagesAdapter.addMany(action.images, {
      ...state
    })
  )
);
