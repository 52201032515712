import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

@Injectable()
export class MetaService {
  constructor(private base: BaseService) {}

  getMeta(pageURL: string) {
    const url = this.adjustURL(pageURL);

    return this.base
      .get(`meta?referer=${url}`)
      .pipe(map((response: any) => ({ meta: response.meta })));
  }

  private adjustURL(url: string) {
    const _url = new URL(url);

    return `https://roundpier.com${_url.pathname}`;
  }
}
