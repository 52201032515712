import { CompetitionsActions } from '../actions';
import { ICompetition } from '../../model';

export interface State {
  current: ICompetition;
  upcoming: ICompetition;
  previous: ICompetition;
  isLoading: boolean;
}

export const initialState: State = {
  current: null,
  upcoming: null,
  previous: null,
  isLoading: false
};

export function Reducer(
  state = initialState,
  action: CompetitionsActions.Actions
): State {
  switch (action.type) {
    case CompetitionsActions.ActionTypes.LOAD_COMPETITIONS_SHORT_LIST:
      return {
        ...state,
        isLoading: true
      };
    case CompetitionsActions.ActionTypes.LOAD_COMPETITIONS_SHORT_LIST_SUCCESS:
      return {
        ...state,
        current: action.payload.current,
        upcoming: action.payload.upcoming,
        previous: action.payload.previous,
        isLoading: false
      };
    default:
      return state;
  }
}

export const isLoading = (state: State) => state.isLoading;
export const competitionsShortList = (state: State) => ({
  current: state.current,
  upcoming: state.upcoming,
  previous: state.previous
});
