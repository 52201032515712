import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import {
  loadSuggestedProfiles,
  loadSuggestedProfilesSuccess
} from './suggestions.actions';
import { switchMap, map, catchError } from 'rxjs/operators';

import { SuggestionsService } from './suggestions.service';

@Injectable()
export class SuggestionsEffects {
  constructor(private actions$: Actions, private service: SuggestionsService) {}

  effectForSearchGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSuggestedProfiles),
      switchMap((params) =>
        this.service.getSuggested(params.N).pipe(
          switchMap(({ profiles }) => [
            loadSuggestedProfilesSuccess({
              profiles: this.normalize(profiles)
            })
          ])
        )
      )
    )
  );

  private normalize(items) {
    return items.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
