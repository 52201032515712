import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(size: unknown) {
    if (typeof size !== 'number') return size;

    if (size < 1000) {
      return `${size} Byte`;
    } else if (size > 1000 && size < 1_000_000) {
      return `${(size / 1000).toFixed(2)} Kb`;
    } else if (size > 1_000_000 && size < 1_000_000_000) {
      return `${(size / 1_000_000).toFixed(2)} Mb`;
    } else if (size > 1_000_000_000 && size < 1_000_000_000_000) {
      return `${(size / 1_000_000_000).toFixed(2)} Gb`;
    }
  }
}
