import { FormBuilder, Validators, FormControl } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import {
  AppleAuthService,
  GoogleAuthService
} from 'src/app/modules/registration/shared/services';
import { RegistrationFacade } from 'src/app/modules/registration/store';
import { AuthService } from 'src/app/core/services/auth.service';

import { FORGOT_PASSWORD_DIALOG } from '../../sign-in-dialog.token';

@Component({
  selector: 'app-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSignInDialogComponent implements OnDestroy {
  readonly fields = {
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ]),
    remember: new FormControl(false)
  };

  readonly form = this._fb.group(this.fields);

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _matDialog: MatDialog,
    private readonly _matDialogRef: MatDialogRef<AppSignInDialogComponent>,
    private readonly _authService: AuthService,
    private readonly _appleAuthService: AppleAuthService,
    private readonly _googleAuthService: GoogleAuthService,
    private readonly _registrationFacade: RegistrationFacade,
    @Inject(FORGOT_PASSWORD_DIALOG)
    private readonly AppForgotPasswordDialogComponent
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onClose() {
    this._matDialogRef.close();
  }

  onOpenForgotDialog() {
    this._matDialogRef.close();
    this._matDialog.open(this.AppForgotPasswordDialogComponent, {
      autoFocus: false
    });
  }

  onSubmitForm() {
    this._authService
      .login(this.form.getRawValue())
      .pipe(tap((_) => this._matDialog.closeAll()))
      .subscribe();
  }

  onAppleSignin() {
    this._appleAuthService
      .login()
      .pipe(
        filter((_) => !!_),
        tap((_) => this._registrationFacade.appleAuth(_)),
        tap((_) => this._matDialog.closeAll())
      )
      .subscribe();
  }

  onGoogleSignin() {
    this._googleAuthService
      .login()
      .pipe(
        filter((_) => !!_),
        tap((_) => this._registrationFacade.googleAuth(_)),
        tap((_) => this._matDialog.closeAll())
      )
      .subscribe();
  }
}
