import { createAction, props } from '@ngrx/store';

import { Network } from './network.model';

export const loadNetwork = createAction(
  '[Network/API] Load Network',
  props<{ page: number; search: string }>()
);

export const loadNetworkSuccess = createAction(
  '[Network/API] Load Network Success',
  props<{ networks: Network[]; count: number }>()
);

export const disconnectRequest = createAction(
  '[Network/API] Disconnect Request',
  props<{ id: number; page: number }>()
);

export const disconnectRequestSuccess = createAction(
  '[Network/API] Disconnect Request Success',
  props<{ id: number }>()
);
