import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MetaService } from './services/meta.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [MetaService]
})
export class MetaModule {
  constructor(
    // NOTE: Inject it in order to instantiate service
    private readonly _metaService: MetaService
  ) {}
}
