import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import * as Immutable from 'immutable';

import {
  INewPost,
  INewGroupPost,
  INewPostShare
} from 'src/app/modules/posts/model';

import { PostForm, IPost } from '../../../../models/post';
import { IComment, ICommentForm } from '../../../../models/comment';

export const loadPosts = createAction(
  '[Post/API] Load Posts',
  props<{ posts: PostForm[]; isPrivate?: boolean }>()
);

export const loadPostsSuccess = createAction(
  '[Post/API] Load Posts Success',
  props<{ posts: Immutable.OrderedMap<number, IPost> }>()
);

export const loadPostsNoPermissionsError = createAction(
  '[Post/API] Load Posts No Pormissions Error'
);

export const paginatePosts = createAction(
  '[Post/API] Paginate Posts',
  props<{ nextPage: number; isPrivate?: boolean }>()
);

export const paginatePostsByUser = createAction(
  '[Post/API] Paginate Posts By User',
  props<{ id: string; nextPage: number }>()
);

export const paginatePostsSuccess = createAction(
  '[Post/API] Paginate Posts Success',
  props<{ posts: Immutable.OrderedMap<number, IPost> }>()
);

export const pinPost = createAction(
  '[Post/API] Pin Posts',
  props<{ post: IPost }>()
);

export const pinPostSuccess = createAction(
  '[Post/API] Pin Posts Success',
  props<{ post: IPost }>()
);

export const unpinPost = createAction(
  '[Post/API] Unpin Posts',
  props<{ post: IPost }>()
);

export const unpinPostSuccess = createAction(
  '[Post/API] Unpin Posts Success',
  props<{ post: IPost }>()
);

export const paginateGroupPosts = createAction(
  '[Post/API] Paginate Group Posts',
  props<{ id: number; nextPage: number }>()
);

export const addPost = createAction(
  '[Post/API] Add Post',
  props<{ post: INewPost }>()
);

export const addPostSuccess = createAction(
  '[Post/API] Add Post Success',
  props<{ post: IPost }>()
);

export const addPostToUser = createAction(
  '[Post/API] Add Post To User',
  props<{ post: INewGroupPost; userId: string }>()
);

export const addGroupPost = createAction(
  '[Post/API] Add Group Post',
  props<{ id: number; post: INewGroupPost }>()
);

export const addPostError = createAction('[Post/API] Add Post Error');

export const updatePost = createAction(
  '[Post/API] Update Post',
  props<{ id: number; post: INewGroupPost }>()
);

export const updatePostSuccess = createAction(
  '[Post/API] Update Post Success',
  props<{ post: Update<IPost> }>()
);

export const updatePosts = createAction(
  '[Post/API] Update Posts',
  props<{ posts: Update<PostForm>[] }>()
);

export const likePost = createAction(
  '[Post/API] Like Post',
  props<{ id: number }>()
);

export const likePostSuccess = createAction(
  '[Post/API] Like Post Success',
  props<{ id: number; count: number }>()
);

export const likeComment = createAction(
  '[Post/API] Like Comment',
  props<{ comment: IComment }>()
);

export const likeCommentSuccess = createAction(
  '[Post/API] Like Comment Success',
  props<{ comment: IComment; count: number }>()
);

export const deletePost = createAction(
  '[Post/API] Delete Post',
  props<{ id: number }>()
);

export const deletePostSuccess = createAction(
  '[Post/API] Delete Post Success',
  props<{ id: number }>()
);

export const complainePost = createAction(
  '[Post/API] Complaine Post',
  props<{ id: number }>()
);

export const complainePostSuccess = createAction(
  '[Post/API] Complaine Post Success',
  props<{ id: number }>()
);

export const deletePosts = createAction(
  '[Post/API] Delete Posts',
  props<{ ids: string[] }>()
);

export const clearPosts = createAction('[Post/API] Clear Posts');

export const loadPostsByUserId = createAction(
  '[Post/API] Load Posts By User Id',
  props<{ id: string }>()
);

export const loadPostsById = createAction(
  '[Post/API] Load Posts By Id',
  props<{ id: number }>()
);

export const loadPostsByIdSuccess = createAction(
  '[Post/API] Load Posts By Id Success',
  props<{ post: IPost }>()
);

export const loadPostsByGroupId = createAction(
  '[Post/API] Load Posts By Group Id',
  props<{ id: number }>()
);

export const loadPostsByGroupIdSuccess = createAction(
  '[Post/API] Load Posts By Group Id Success',
  props<{ id: number }>()
);

export const loadPostsByDiscussionId = createAction(
  '[Post/API] Load Posts By Discussion Id',
  props<{ id: number }>()
);

export const loadPostsByDiscussionIdSuccess = createAction(
  '[Post/API] Load Posts By Discussion Id Success',
  props<{ id: number }>()
);

export const sharePost = createAction(
  '[Post/API] Share Post',
  props<{ post: INewPostShare; isShouldAdd?: boolean }>()
);

export const sharePostSuccess = createAction(
  '[Post/API] Share Post Success',
  props<{ post: IPost }>()
);

export const loadCommentsForPost = createAction(
  '[Post/API] Load Comments For Post',
  props<{ post: IPost; offset: number }>()
);

export const loadCommentsForPostSuccess = createAction(
  '[Post/API] Load Comments For Post Success',
  props<{ post: IPost; isFromPost: boolean; comments: IComment[] }>()
);

export const clearComments = createAction('[Post/API] Clear Comments');

export const addCommentToPost = createAction(
  '[Post/API] Add Comment To Post',
  props<{ comment: ICommentForm }>()
);

export const addCommentToPostSuccess = createAction(
  '[Post/API] Add Comment To Post Success',
  props<{ post_id: number; comment: IComment }>()
);

export const replyComment = createAction(
  '[Post/API] Reply Comment',
  props<{ comment: ICommentForm }>()
);

export const replyCommentSuccess = createAction(
  '[Post/API] Reply Comment Success',
  props<{ comment: IComment }>()
);

export const deleteComment = createAction(
  '[Post/API] Delete Comment',
  props<{ comment: IComment }>()
);

export const deleteCommentSuccess = createAction(
  '[Post/API] Delete Commen Success',
  props<{ comment: IComment }>()
);

export const postViewedSuccess = createAction(
  '[Post/API] Viewed Success',
  props<{ id: number }>()
);
