<div class="block right-block">
  <div class="title d-flex">After school & other</div>
  <div class="right-block-data">
    <app-loader-2 *ngIf="!afterSchoolPrograms?.items"></app-loader-2>
    <div class="members-list" *ngIf="afterSchoolPrograms?.items">
      <div *ngFor="let program of afterSchoolPrograms.items | randomElements:3" class="member-item d-flex">
        <a [profile]="program">
          <app-avatar [src]="program.icon" [placeholder]="program | initials" class="ava"> </app-avatar>
        </a>

        <div class="data">
          <a [profile]="program" class="name">{{ program.name }}</a>
          <div class="position">{{ program.location?.city?.name }}, {{ program.location?.country?.name }} </div>
          <div class="link">
            <a [profile]="program">Explore</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
