import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-org-avatar',
  templateUrl: './default-org-avatar.component.html',
  styleUrls: ['./default-org-avatar.component.scss']
})
export class DefaultOrgAvatarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
