import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

import { IEditorCtx, IInputCtx } from 'src/app/shared/form';

import { IArticle } from '../../../model';
import { ProjectsFacade } from '../../../store';

@Component({
  selector: 'app-create-new-article-dialog',
  templateUrl: './create-new-article-dialog.component.html',
  styleUrls: ['./create-new-article-dialog.component.scss']
})
export class CreateNewArticleDialogComponent {
  readonly fields = {
    title: new FormControl(null, [Validators.required]),
    text: new FormControl(null, [Validators.required])
  };

  readonly articleForm = new FormGroup(this.fields);

  readonly title: IInputCtx = {
    control: this.fields.title,
    placeholder: 'Title',
    hint: 'Online education in California schools',
    required: true
  };

  readonly text: IEditorCtx = {
    control: this.fields.text
  };

  constructor(
    private dialogRef: MatDialogRef<CreateNewArticleDialogComponent, IArticle>,
    private readonly projectsFacade: ProjectsFacade
  ) {}

  save() {
    this.projectsFacade
      .createNewArticle({
        name: this.fields.title.value,
        description: this.fields.text.value
      })
      .pipe(tap((_) => this.dialogRef.close(_)))
      .subscribe((_) => _);
  }
}
