import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { IYourSchool } from './your-school.model';
import { loadYourSchoolSuccess } from './your-school.actions';
import {
  addContactSuccess,
  removeContactSuccess
} from '../../../../../core/services/contacts/contacts.actions';

export const youSchoolFeatureKey = 'yourSchool';

export interface State {
  school: IYourSchool | null;
}

export const initialState: State = {
  school: null
};

export const youSchoolReducer = createReducer(
  initialState,
  on(loadYourSchoolSuccess, (state, { school }) => ({ ...state, school })),
  on(addContactSuccess, (state, action) =>
    getConnectState(state, action.id, true)
  ),
  on(removeContactSuccess, (state, action) =>
    getConnectState(state, action.id, false)
  )
);

function getConnectState(state: State, id: number, type: boolean) {
  if (!state.school) {
    return state;
  }

  const school = state.school;
  const isSchool = school.id === id;

  if (isSchool) {
    // #Return#
    return {
      ...state,
      school: { ...school, is_friend: type, friend_request: false }
    };
  }

  const memberIndex = school.members.findIndex((member) => member.id === id);

  if (memberIndex !== -1) {
    // #Return#
    const members = [...school.members];

    members[memberIndex] = {
      ...members[memberIndex],
      is_friend: type,
      friend_request: false
    };

    return { ...state, school: { ...school, members } };
  }

  return state;
}

export const selectState = createFeatureSelector<State>(youSchoolFeatureKey);

export const selectYourSchool = () =>
  createSelector(selectState, (state: State) => state.school);
