import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export interface ProfileInfo {
  id: number;
  icon_id: number;
  location_id: number;
  name: string;
  type: string;
  status_id: number;
  evaluation: any;
  school: any;
  progress: number;
  is_mobile: number;
  activity: number;
  ip_start: any;
  ip_run: any;
  run_time: any;
  student: {
    id: number;
    cur_school_id: number;
    about_me: any;
    youtube: any;
    born: any;
    school_id: number;
    lastname: string;
    currentschool: {
      id: number;
      address_id: number;
      name: string;
      address: {
        id: number;
        name: string;
        lat: number;
        lng: number;
      };
    };
  };
  parents: any;
  language: any;
  scores: any;
  location: {
    id: number;
    city: {
      id: number;
      name: string;
    };
    country: {
      id: number;
      name: string;
    };
    zip: number;
    lat: number;
    lng: number;
  };
  previosschool: {
    id: number;
    address_id: number;
    name: string;
    pivot: {
      user_id: number;
      reference_id: number;
    };
    address: {
      id: number;
      name: string;
      lat: number;
      lng: number;
    };
  }[];
  // afterschool: [{
  //   id: 694;
  //   reference_id: 347;
  //   body_id: 2193;
  //   pivot: {
  //     user_id: 2093;
  //     reference_id: 694
  //   };
  //   body: {
  //     id: 2193;
  //     address_id: 1838;
  //     name: Tegvägen;
  //     address: {
  //       id: 1838;
  //       name: Tegvägen; Järfälla; Sweden;
  //       lat: 59.41095370;
  //       lng: 17.79742810
  //     }
  //   };
  //   reference: {
  //     id: number;
  //     name: string;
  //   }
  // }];
  interest: {
    id: number;
    name: string;
    pivot: {
      user_id: number;
      associate_id: number;
    };
  }[];
  interests: any;
  otherexperience: any;
  honorsandavards: any;
  icon: string;
  is_friend: boolean;
  features: any;
  key: string;
  teacher?: {
    id: number;
    cur_school_id: number;
    about_me: any;
    youtube: any;
    born: any;
    school_id: number;
    lastname: string;
    currentschool: {
      id: number;
      address_id: number;
      name: string;
      address: {
        id: number;
        name: string;
        lat: number;
        lng: number;
      };
    };
  };
}

export const loadProfileInfo = createAction('[Profile/API] Load Profile Info');

export const loadProfileInfoSuccess = createAction(
  '[Profile/API] Load Profile Info Success',
  props<{ profileInfo: ProfileInfo }>()
);

export const loadProfileInfoById = createAction(
  '[Profile/API] Load Profile Info By Id',
  props<{ profileId: string }>()
);

export const loadProfileInfoByIdSuccess = createAction(
  '[Profile/API Load Profile Info By Success',
  props<{ profileId: string; profileInfo: ProfileInfo }>()
);
