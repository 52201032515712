import { isPlatformServer } from '@angular/common';
import {
  Directive,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  selector: '[appShellNoRender]'
})
export class AppShellNoRenderDirective implements OnInit {
  constructor(
    private readonly _viewContainer: ViewContainerRef,
    private readonly _templateRef: TemplateRef<any>,
    @Inject(PLATFORM_ID) private readonly _platformId
  ) {}

  ngOnInit() {
    if (isPlatformServer(this._platformId)) {
      return this._viewContainer.clear();
    }

    return this._viewContainer.createEmbeddedView(this._templateRef);
  }
}
