import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class SuggestionsService {
  constructor(private base: BaseService) {}

  getSuggested(N: number) {
    return this.base
      .get(`suggested/short/${N}`)
      .pipe(map((_) => ({ profiles: _.suggested.users })));
  }

  // /api/suggested/short/N?token=1_e7754d01f4ed8abb0883fae18ffe7a44
}
