import {
  EventEmitter,
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  Input,
  ViewChild,
  TemplateRef,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFileComponent {
  @ViewChild(`Selector`) readonly selector: ElementRef<HTMLInputElement>;

  @Input() readonly disabled = false;
  @Input() readonly types: string;
  @Input() readonly count: 'ONE' | 'MANY' = 'ONE';

  @Output() readonly select = new EventEmitter<File[]>();

  public onChange(files: FileList) {
    const array: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      array.push(file);
    }

    this.selector.nativeElement.value = null;
    this.select.emit(array);
  }

  public onSelect() {
    const selector = this.selector.nativeElement;
    selector.click();
  }
}
