import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnInit,
  OnChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import linkifyHtml from 'linkifyjs/html';
import lodash from 'lodash';

import { RoutingService } from 'src/app/core/services/routing.service';

import { IImage, ILocalImage } from '../../models/image';
import { IDoc } from '../../models/doc';
import { AuthService } from '../../../core/services/auth.service';
import { ConvertIdService } from '../../../core/services/convert-id.service';
import { People } from '../../../core/services/to_remove_search/search.model';
import { MessageTagOptionsComponent } from './tag-options/tag-options.component';
import { ImagesFacade } from '../../stores/images/images.facade';
import { DocsFacade } from '../../stores/docs/docs.facade';
import { LocalStorage } from '../../../core/storage/interfaces/local-storage.interface';
import { LS_DISCUSSION } from '../../../core/constants/localStorage';
import { IEditable } from '../../models/editable';
import { ProfileLinkService } from '../../services/links';
import { IsValidEmailPipe } from '../../pipes';

export interface IMessage {
  readonly text: string;
  readonly linksToPreview?: string[];
  readonly images: number[];
  // TODO: rename to files
  readonly docs: number[];
}

type TAsset = `IMAGES` | `DOCS` | `EMOJIS` | null;

export interface IMessageCtx {
  placeholder: string;
  submit(message: IMessage): void;
  close?(): void;
  isHideAvatar?: boolean;
  isTextarea?: boolean;
  isHideControls?: boolean;
  isSuggestArticle?: boolean;
  isSupportTags?: boolean;
  isSupportLinkPreview?: boolean;
  edit?: () => IEditable;
  focus?: boolean;
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent implements OnInit, OnChanges, OnDestroy {
  static readonly LONG_MESSAGE_LENGTH = 100;
  static readonly LONG_MESSAGE_AMOUNT_OF_LINES = 3;
  static readonly LENGTH_TO_SUGGEST_WRITE_ARTICLE = 500;

  @Input() readonly ctx: IMessageCtx;

  @ViewChild(`Text`, { static: true })
  readonly textRef: ElementRef<HTMLDivElement>;
  @ViewChild(MessageTagOptionsComponent, { static: false })
  readonly options: MessageTagOptionsComponent;

  public editable: IEditable;
  public asset: TAsset = null;

  message$ = new BehaviorSubject<string>('');
  tag$ = new BehaviorSubject<string>(null);
  tagDataSupplier$ = new Subject<{ tagNode: HTMLAnchorElement }>();
  emojiSupplier$ = new Subject<string>();
  removeTag$ = new Subject<void>();

  private _editable$ = new BehaviorSubject<IEditable>(null);
  private _isSuggestArticle$ = new BehaviorSubject<boolean>(false);
  private _links$ = new BehaviorSubject([]);
  private _linksToShow = new Map();
  private _componentDestroy$ = new Subject();

  readonly images = {
    stream: this.imagesFacade.all,
    saved: new Set<IImage>(),
    local: new Set<ILocalImage>()
  };

  readonly docs = {
    stream: this.docsFacade.all,
    saved: new Set<IDoc>(),
    local: new Set<File>()
  };

  constructor(
    readonly auth: AuthService,
    readonly routing: RoutingService,
    readonly converId: ConvertIdService,
    readonly imagesFacade: ImagesFacade,
    readonly docsFacade: DocsFacade,
    readonly localStorage: LocalStorage,
    private readonly router: Router,
    private readonly profileLinkService: ProfileLinkService,
    private readonly isValidEmailPipe: IsValidEmailPipe
  ) {}

  get links$() {
    return this._links$;
  }

  get isSupportLinkPreview() {
    return this.ctx.isSupportLinkPreview;
  }

  get isDisabled(): boolean {
    return (
      !this.message$.value &&
      !this.images.saved.size &&
      !this.images.local.size &&
      !this.docs.saved.size &&
      !this.docs.local.size
    );
  }

  get isLongMessage() {
    const contentLength = this.contentLength(this.message$.value);
    const amountOfLines = this.amountOfLines(this.message$.value);

    return (
      contentLength > MessageComponent.LONG_MESSAGE_LENGTH ||
      amountOfLines >= MessageComponent.LONG_MESSAGE_AMOUNT_OF_LINES
    );
  }

  get isSuggestArticle$() {
    return this._isSuggestArticle$;
  }

  get isShowOptions$() {
    return combineLatest([of(this.ctx.isSupportTags), this.tag$]).pipe(
      map((_) => _[0] && _[1] != null)
    );
  }

  get placeholder() {
    return this.ctx.placeholder;
  }

  get focus() {
    return this.ctx.focus;
  }

  ngOnInit() {
    this.message$
      .pipe(
        debounceTime(200),
        tap((_) => this.handleLinks(_)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);

    this._editable$
      .pipe(
        filter((_) => !!_),
        tap((_) => this.onEditable(_)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  ngOnChanges() {
    this._editable$.next(this.ctx.edit ? this.ctx.edit() : null);
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  onArticleRedirect() {
    const text = this.message$.value;

    if (!text) {
      return;
    }

    const json = JSON.stringify({ text });

    this.localStorage.setItem(LS_DISCUSSION, json);
  }

  rejectArticle() {
    this._isSuggestArticle$.next(false);
    this._isSuggestArticle$.complete();
  }

  onEmojiSelection(emoji: string) {
    this.emojiSupplier$.next(emoji);
  }

  onSelectAsset(asset: TAsset) {
    if (this.asset === asset) {
      this.asset = null;
    } else {
      this.asset = asset;
    }
  }

  async onSubmit() {
    const message = await this.getMessage();
    this.ctx.submit(message);
  }

  onClose() {
    this.ctx.close();
  }

  onShowPreviewLink(url: string) {
    this._linksToShow.set(url, true);
  }

  onHidePreviewLink(url: string) {
    this._linksToShow.set(url, false);
  }

  onMessageChange(message: string) {
    this.message$.next(message.trim());

    if (!this.ctx?.isSuggestArticle) {
      return;
    }

    const contentLength = this.contentLength(message);

    this._isSuggestArticle$.next(
      contentLength > MessageComponent.LENGTH_TO_SUGGEST_WRITE_ARTICLE
    );
  }

  onUserTagSelect(user: People) {
    const link = this.profileLinkService.generateProfileLink(user);

    const tagNode = document.createElement('a');
    tagNode.setAttribute('contenteditable', 'false');
    tagNode.setAttribute('target', '_blank');
    // TODO: how to name this data-tag so no one use it ?
    tagNode.setAttribute('data-tag', user.id + '');
    tagNode.appendChild(document.createTextNode(user.name));
    tagNode.href = this.router.createUrlTree(link).toString();

    this.tagDataSupplier$.next({ tagNode });
  }

  onSearchByTag(tag: string) {
    this.tag$.next(tag);
  }

  onRemoveTag() {
    this.removeTag$.next();
  }

  private onEditable(editable: IEditable) {
    if (!editable) {
      return;
    }

    this.message$.next(editable.text);

    combineLatest([this.images.stream, this.docs.stream])
      .pipe(
        take(1),
        tap(([images, docs]) => this.setFromEditable(editable, images, docs))
      )
      .subscribe((_) => _);
  }

  private async getMessage() {
    const layers = [
      this.adjustTagIDs.bind(this),
      this.tagsToNewlines.bind(this),
      this.htmlDecode.bind(this),
      (_: string) => _.trim()
    ];

    const text = layers.reduce((acc, layer) => {
      return layer(acc);
    }, this.message$.value);

    const savedImages = [...this.images.saved].map(({ id }) => id);
    const savedDocs = [...this.docs.saved].map(({ id }) => id);
    const _images = [...this.images.local].map(({ file }) => file);
    const _docs = [...this.docs.local];

    const linksToPreview = this._links$.value.filter((_) =>
      this._linksToShow.get(_)
    );

    this.clear();

    const [images, docs] = await Promise.all([
      this.imagesFacade.add(_images),
      this.docsFacade.add(_docs)
    ]);

    const addedImages = images.map(({ id }) => id);
    const addedDocs = docs.map(({ id }) => id);

    const message: IMessage = {
      text,
      linksToPreview,
      images: [...addedImages, ...savedImages],
      docs: [...addedDocs, ...savedDocs]
    };

    return message;
  }

  // converts
  // <a data-tag="1" data-id="1">@hey1</a> test <a data-tag="2" data-id="1">@hey2</a>
  // to
  // @=1 test @=2
  private adjustTagIDs(text: string): string {
    const template = document.createElement('template');
    template.innerHTML = text;

    // TODO: probably rename data-tag to be sure no one use it
    const mentions = template.content.querySelectorAll('a[data-tag]');

    mentions.forEach((mention) => {
      // @ts-ignore
      text = text.replace(mention.outerHTML, `@=${mention.dataset.tag}`);
    });

    return text;
  }

  private clear() {
    this.tag$.next(null);
    this.message$.next('');
    this.images.saved.clear();
    this.images.local.clear();
    this.docs.saved.clear();
    this.docs.local.clear();
    this.asset = null;

    this._links$.complete();
    this._links$ = new BehaviorSubject([]);
    this._linksToShow = new Map();

    this._isSuggestArticle$ = new BehaviorSubject<boolean>(false);
  }

  private handleLinks(message: string) {
    const linksFromText = this.getLinksFromText(message);
    // make a preview only for the first link in a post
    const linksPreview = linksFromText.slice(0, 1);

    this._links$.next(linksPreview);
  }

  private getLinksFromText(text: string): Array<string> {
    const template = document.createElement('template');
    template.innerHTML = this.linkify(text);

    const links = Array.from(template.content.querySelectorAll('a'))
      // TODO: probably rename data-tag to be sure no one use it
      .filter((_) => !_.hasAttribute('data-tag'))
      .map((link: HTMLAnchorElement) => link.href)
      // linkify add 'mailto:' part to the emails, remove it
      // don't rely on it, too unreliable
      .map((_) => _.replace('mailto:', ''))
      // don't count emails as a links
      .filter((_) => !this.isValidEmailPipe.transform(_));

    return lodash.uniq(links);
  }

  private linkify(value: string) {
    return linkifyHtml(value, {
      defaultProtocol: 'https',
      target: {
        url: '_blank'
      }
    });
  }

  private htmlDecode(input) {
    const template = document.createElement('template');
    template.innerHTML = input;

    return template.content.textContent;
  }

  private contentLength(content: string) {
    const template = document.createElement('template');
    template.innerHTML = content;

    return template.content.textContent.length;
  }

  private tagsToNewlines(content: string) {
    const template = document.createElement('template');
    template.innerHTML = content;

    template.content.querySelectorAll('br').forEach((_) => {
      _.replaceWith(document.createTextNode('\n'));
    });

    return template.content.textContent;
  }

  private amountOfLines(content: string) {
    const template = document.createElement('template');
    template.innerHTML = content;

    return template.content.textContent.split('\n').length;
  }

  private setFromEditable(editable: IEditable, images: IImage[], docs: IDoc[]) {
    for (const doc of editable?.images || []) {
      const saved = images.find(({ id }) => doc.id === id);

      if (saved) {
        this.images.saved.add(saved);
      }
    }

    for (const doc of editable?.docs || []) {
      const saved = docs.find(({ id }) => doc.id === id);

      if (saved) {
        this.docs.saved.add(saved);
      }
    }
  }
}
