import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, competitionsFeatureKey } from '../reducers';
import * as CurrentCompetitions from '../reducers/current-competitions.reducer';

const competitionsModuleState = createFeatureSelector<State>(
  competitionsFeatureKey
);

const selectCurrentCompetitionsState = createSelector(
  competitionsModuleState,
  (state: State) => state.currentCompetitions
);

export const getCurrentCompetitions = createSelector(
  selectCurrentCompetitionsState,
  CurrentCompetitions.selectCompetitions
);

export const isLoadingCurrentCompetitions = createSelector(
  selectCurrentCompetitionsState,
  CurrentCompetitions.isLoading
);

export const hasMoreCurrentCompetitionsToLoad = createSelector(
  selectCurrentCompetitionsState,
  CurrentCompetitions.hasMoreCompetitionsToLoad
);

export const getLastLoadedPageCurrentCompetitions = createSelector(
  selectCurrentCompetitionsState,
  CurrentCompetitions.lastLoadedPage
);
