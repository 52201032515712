import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ClubActions } from '../actions';
import { IClubMediaPhoto } from '../../model';

export const featureAdapter: EntityAdapter<IClubMediaPhoto> =
  createEntityAdapter<IClubMediaPhoto>({
    selectId: (model) => model.id,
    sortComparer: (a: IClubMediaPhoto, b: IClubMediaPhoto): number =>
      b.id - a.id
  });

export interface State extends EntityState<IClubMediaPhoto> {
  isLoading: boolean;
  photosTotalCount: number;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  photosTotalCount: 0
});

export function Reducer(
  state = initialState,
  action: ClubActions.Actions
): State {
  switch (action.type) {
    case ClubActions.ActionTypes.LOAD_CLUB_MEDIA_PHOTOS:
      return {
        ...initialState,
        isLoading: true
      };
    case ClubActions.ActionTypes.LOAD_CLUB_MEDIA_PHOTOS_SUCCESS:
      return featureAdapter.addMany(action.payload.photos, {
        ...state,
        isLoading: false,
        photosTotalCount: action.payload.photosTotalCount
      });
    case ClubActions.ActionTypes.CLUB_UPLOAD_MEDIA_PHOTO:
      return {
        ...state,
        isLoading: true
      };
    case ClubActions.ActionTypes.CLUB_UPLOAD_MEDIA_PHOTO_SUCCESS:
      return featureAdapter.addOne(action.payload.photo, {
        ...state,
        isLoading: false,
        filesTotalCount: state.photosTotalCount + 1
      });
    default:
      return state;
  }
}

export const isLoadingClubMediaPhotos = (state: State) => state.isLoading;
export const { selectAll: getClubMediaPhotos } = featureAdapter.getSelectors();
export const getClubMediaPhotosAmountOfPages = (state: State) =>
  Math.ceil(state.photosTotalCount / 20);
