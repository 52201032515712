import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profileImg'
})
export class ProfileImgPipe implements PipeTransform {
  transform(url: string): string {
    return url.replace('post.jpg', 'profile.jpg');
  }
}
