import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AutocompleteOffDirective } from './directives/autocomplete/autocomplete.directive';
import { AutocompleteService } from './services/autocomplete/autocomplete.service';

@NgModule({
  declarations: [AutocompleteOffDirective],
  imports: [CommonModule],
  exports: [AutocompleteOffDirective],
  providers: [AutocompleteService]
})
export class AutocompleteModule {}
