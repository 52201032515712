import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import moment from 'moment';
import { MatTabGroup } from '@angular/material/tabs';

import { AuthService } from 'src/app/core/services/auth.service';

import { AppSignInDialogComponent } from 'src/app/shared/modules/sign-in-dialog/components';
import {
  ICompetition,
  ICompetitionGroup,
  ICompetitionJury,
  ICompetitionNews,
  ICompetitionResult,
  ICompetitionReview,
  ICompetitionTeam,
  INewCompetitionTeam
} from '../../model';
import { CompetitionsFacade } from '../../store';
import { CompetitionParticipantsComponent } from '../competition-participants/competition-participants.component';
import { CompetitionRequestsComponent } from '../competition-requests/competition-requests.component';
import {
  CompetitionChooseClubDialogComponent,
  NewCompetitionComponent,
  CompetitionWritePostComponent,
  CompetitionSubmitResultComponent,
  CompetitionCreateProjectDialogComponent,
  CompetitionUpsertJuryComponent,
  CompetitionReviewResultComponent,
  CompetitionJuryRateSubmissionDialogComponent,
  CompetitionProjectViewComponent,
  CompetitionTeamViewComponent
} from '../../shared/dialogs';
import { ProjectsFacade } from '../../../projects/store';
import {
  ICompetitionProject,
  INewCompetitionProject
} from '../../../projects/model';
import { CompetitionsService } from '../../store/service';

@Component({
  selector: 'app-competition-info',
  templateUrl: './competition-info.component.html',
  styleUrls: ['./competition-info.component.scss']
})
export class CompetitionInfoComponent implements OnInit, OnDestroy {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly competitionsService: CompetitionsService,
    private readonly competitionsFacade: CompetitionsFacade,
    private readonly projectsFacade: ProjectsFacade
  ) {
    this.competitionsFacade.submitCompetitionNewsSuccess$
      .pipe(
        takeUntil(this.componentDestroy$),
        tap((_) => this.onSubmitCompetitionNewsSuccess())
      )
      .subscribe((_) => _);

    this.competitionsFacade.submitCompetitionResultSuccess$
      .pipe(
        takeUntil(this.componentDestroy$),
        tap((_) => this.onSubmitCompetitionResultSuccess())
      )
      .subscribe((_) => _);

    this.competitionsFacade.updateCompetitionResultSuccess$
      .pipe(
        takeUntil(this.componentDestroy$),
        tap((_) => this.onUpdateCompetitionResultSuccess())
      )
      .subscribe((_) => _);
  }

  get isAllowedToEdit$() {
    return combineLatest([
      this.competition$,
      // handle case when backend returns is_author for not org user
      this.authService.isOrgType$()
    ]).pipe(
      takeUntil(this.componentDestroy$),
      map(([competition, isOrgType]) => competition?.is_autor && isOrgType)
    );
  }

  get jury$() {
    return this.route.queryParams.pipe(
      filter((_) => !!_.jury_token),
      switchMap((_) => {
        return this.competitionsService.checkJuryToken(_.jury_token).pipe(
          first(),
          filter((res) => !!res),
          map((res) => res.jury)
        );
      })
    );
  }

  get isApproved$() {
    const STATUS_APPROVED = 1;

    return this.competitionsFacade.competition$.pipe(
      filter((_) => !!_),
      map((_) => _.status === STATUS_APPROVED)
    );
  }

  get competition$() {
    return this.competitionsFacade.competition$;
  }

  get competitionProjects$() {
    return this.competitionsFacade.competitionProjects$;
  }

  get competitionTeams$() {
    return this.competitionsFacade.competitionTeams$;
  }

  get newCompetitionTeams$() {
    return this.competitionTeams$.pipe(
      map((_) => _.filter((team) => team.status === 0))
    );
  }

  get confirmedCompetitionTeams$() {
    return this.competitionTeams$.pipe(
      map((_) => _.filter((team) => team.status === 1))
    );
  }

  get rejectedCompetitionTeams$() {
    return this.competitionTeams$.pipe(
      map((_) => _.filter((team) => team.status === 2))
    );
  }

  get isCompetitionProjectsLoading$() {
    return this.projectsFacade.isLoading$;
  }

  get isCompetitionTeamsLoading$() {
    return this.competitionsFacade.competitionTeamsIsLoading$;
  }

  get isUpdatingSelectedCompetition$() {
    return this.competitionsFacade.isUpdatingSelectedCompetition$;
  }

  get competitionParticipants$() {
    return this.competitionsFacade.participants$;
  }

  get amountOfProjectsPages$() {
    return this.competitionsFacade.competitionProjectsPagesAmount$;
  }

  get amountOfTeamsPages$() {
    return this.competitionsFacade.competitionTeamsPagesAmount$;
  }

  get competitionJury$() {
    return this.competitionsFacade.competitionJury$;
  }

  get competitionSubmissions$() {
    return this.competitionsFacade.reviews$;
  }

  get jurySubmission$() {
    return this.competitionSubmissions$.pipe(
      map((_) => _.filter((submission) => submission.status === 1))
    );
  }

  get dateRange$() {
    return this.competitionsFacade.competition$.pipe(
      filter((_) => !!_),
      map(
        (_) =>
          `${moment(_.date_start).format('DD MMM YYYY')} - ${moment(
            _.date_end
          ).format('DD MMM YYYY')}`
      )
    );
  }

  private static readonly NOTIFICATION_DURATION_MS = 10 * 1000;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @ViewChild('submitWorkBtn') submitWorkBtn: ElementRef;
  private readonly id$ = new BehaviorSubject(null);
  private readonly componentDestroy$ = new Subject();
  public readonly isCompetitionJury$ = new BehaviorSubject<boolean>(false);
  public projectsPage = 1;
  public searchProjectTerm = '';
  public teamsPage = 1;
  public searchTeamTerm = '';
  public generalTabCompetitionProjects$: Observable<ICompetitionProject[]>;
  public generalTabCompetitionTeams$: Observable<ICompetitionTeam[]>;
  public displayedJuryColumns = [
    'name',
    'email',
    'status',
    'projects',
    'actions'
  ];

  public displayedSubmissionsColumns = [
    'description',
    'project',
    'team',
    'status',
    'actions'
  ];

  public displayedJurySubmissionsColumns = [
    'description',
    'project',
    'team',
    'actions'
  ];

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_.id),
        tap((_) => this.id$.next(_.id))
      )
      .subscribe((_) => _);

    this.id$
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_),
        tap((_) => this.competitionsFacade.loadCompetition({ id: _ }))
      )
      .subscribe((_) => _);

    this.competitionsFacade.competition$
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_),
        tap((_) =>
          this.competitionsFacade.loadCompetitionProjects({
            competitionId: _.id
          })
        ),
        tap((_) =>
          this.competitionsFacade.loadCompetitionTeams({ competitionId: _.id })
        ),
        tap((_) => {
          if (_.is_autor) {
            this.competitionsFacade.loadJury({ competitionId: _.id });
          }
        }),
        tap((_) => {
          if (_.is_autor) {
            this.competitionsFacade.loadCompetitionReviews({
              id: _.id,
              page: 1
            });
          }
        }),
        tap((_) => {
          this.route.queryParams
            .pipe(
              filter((params) => !!params.jury_token),
              switchMap((params) => {
                return this.competitionsService
                  .checkJuryToken(params.jury_token)
                  .pipe(
                    first(),
                    filter((res) => !!res),
                    tap((res) =>
                      this.competitionsFacade.loadCompetitionJurySubmissions({
                        token: params.jury_token,
                        juryId: res.jury.id,
                        competitionId: _.id,
                        page: 1
                      })
                    ),
                    map((res) => res.jury)
                  );
              })
            )
            .subscribe((res) => this.isCompetitionJury$.next(!!res));
        })
      )
      .subscribe(() => {
        this.generalTabCompetitionTeams$ = this.competitionTeams$.pipe(
          takeUntil(this.componentDestroy$),
          filter((_) => !!_ && !!_.length),
          first(),
          map((_) => _.slice(0, 4))
        );

        this.generalTabCompetitionProjects$ = this.competitionProjects$.pipe(
          takeUntil(this.componentDestroy$),
          filter((_) => !!_ && !!_.length),
          first(),
          map((_) => _.slice(0, 4))
        );
      });

    this.authService.user$
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_ && !!_.id)
      )
      .subscribe((_) =>
        this.competitionsFacade.loadCompetition({ id: this.id$.value })
      );
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  onOutletLoaded(
    component: CompetitionParticipantsComponent | CompetitionRequestsComponent
  ) {
    this.competition$
      .pipe(
        takeUntil(this.componentDestroy$),
        take(1),
        tap((_) => (component.isAdminView = _.is_autor))
      )
      .subscribe((_) => _);
  }

  onJoin() {
    if (!this.id$.value) {
      return;
    }

    // TODO on second opening data loaded after open modal and not updated
    this.competitionsFacade.loadMyGroups();

    this.competitionsFacade.competitionMyGroups$
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_),
        take(1),
        mergeMap((clubs) => {
          return this.dialog
            .open<
              CompetitionChooseClubDialogComponent,
              { clubs: ICompetitionGroup[]; competitionId: number },
              INewCompetitionTeam
            >(CompetitionChooseClubDialogComponent, {
              data: { clubs, competitionId: +this.id$.value }
            })
            .afterClosed();
        }),
        filter((_) => !!_?.name),
        tap((team) =>
          this.competitionsFacade.addCompetitionTeam({
            team: {
              ...team,
              competitionId: this.id$.value
            }
          })
        )
      )
      .subscribe((_) => _);
  }

  onEdit() {
    this.competition$
      .pipe(
        takeUntil(this.componentDestroy$),
        take(1),
        mergeMap((_) => {
          const dialogRef = this.dialog.open(NewCompetitionComponent, {
            data: { competition: _ }
          });

          return dialogRef.afterClosed();
        }),
        filter((_) => _?.competition),
        tap((_) => {
          this.competitionsFacade.updateCompetition({
            id: this.id$.value,
            competition: _.competition
          });
        })
      )
      .subscribe((_) => _);
  }

  onUpdateTeams({
    team,
    competition
  }: {
    team?: ICompetitionTeam;
    competition: ICompetition;
  }) {
    const dialogRef = this.dialog.open<
      CompetitionWritePostComponent,
      {
        team?: ICompetitionTeam;
        competition: ICompetition;
      },
      ICompetitionNews
    >(CompetitionWritePostComponent, {
      data: { team, competition }
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((_) => !!_),
        map((_) =>
          this.competitionsFacade.submitCompetitionNews({
            id: team?.id,
            message: _
          })
        )
      )
      .subscribe((_) => _);
  }

  onSubmitResult() {
    this.submitWorkBtn.nativeElement.disabled = true;

    this.competition$
      .pipe(
        takeUntil(this.componentDestroy$),
        take(1),
        mergeMap((competition) =>
          this.competitionsService
            .getProjectsAllList({ competitionId: competition.id })
            .pipe(
              mergeMap((_) =>
                this.dialog
                  .open<
                    CompetitionSubmitResultComponent,
                    object,
                    ICompetitionResult
                  >(CompetitionSubmitResultComponent, {
                    data: { projects: _ }
                  })
                  .afterClosed()
              ),
              tap((_) => (this.submitWorkBtn.nativeElement.disabled = false)),
              filter((_) => !!_),
              tap((_) =>
                this.competitionsFacade.submitCompetitionResult({
                  result: _
                })
              )
            )
        )
      )
      .subscribe((_) => _);
  }

  onJoinNow() {
    return this.dialog.open(AppSignInDialogComponent);
  }

  switchToTab(index: number) {
    this.tabGroup.selectedIndex = index;
  }

  private onSubmitCompetitionNewsSuccess() {
    this.snackBar.open('Update has been sent', 'OK', {
      duration: CompetitionInfoComponent.NOTIFICATION_DURATION_MS
    });
  }

  private onSubmitCompetitionResultSuccess() {
    this.snackBar.open('Your submission has been sent', 'OK', {
      duration: CompetitionInfoComponent.NOTIFICATION_DURATION_MS
    });
  }

  private onUpdateCompetitionResultSuccess() {
    this.snackBar.open('Submission updated', 'OK', {
      duration: CompetitionInfoComponent.NOTIFICATION_DURATION_MS
    });
  }

  onCreateCompetitionProject(
    competition: ICompetition,
    project?: ICompetitionProject
  ) {
    // console.log(project);
    const dialogRef = this.dialog.open<
      CompetitionCreateProjectDialogComponent,
      {
        categoryId: number;
        user_id: number;
        competition_id: number;
        project: ICompetitionProject;
      },
      INewCompetitionProject
    >(CompetitionCreateProjectDialogComponent, {
      data: {
        categoryId: competition.category.id,
        user_id: competition.user.id,
        competition_id: competition.id,
        project
      }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.componentDestroy$))
      .subscribe((_) => this.upsertCompetitionProject(_));
    // .subscribe((_) => console.log(_));
  }

  private upsertCompetitionProject(
    competitionProject?: INewCompetitionProject & { id?: number }
  ) {
    if (!competitionProject) {
      return;
    }

    this.projectsFacade.createNewCompetitionProject(competitionProject);
  }

  onSetProjectsPage(page: number) {
    this.projectsPage = page;
    this.competitionsFacade.loadCompetitionProjects({
      competitionId: this.id$.value,
      page
    });
  }

  onProjectSearch(term: string) {
    this.competitionsFacade.loadCompetitionProjects({
      competitionId: this.id$.value,
      page: this.projectsPage,
      term
    });
  }

  onTeamSearch(term: string) {
    this.competitionsFacade.loadCompetitionTeams({
      competitionId: this.id$.value,
      page: this.teamsPage,
      term
    });
  }

  onSetTeamsPage(page: number) {
    this.teamsPage = page;
    this.competitionsFacade.loadCompetitionTeams({
      competitionId: this.id$.value,
      page: this.teamsPage
    });
  }

  onUpsertJury(jury?: ICompetitionJury) {
    const dialogRef = this.dialog.open<
      CompetitionUpsertJuryComponent,
      { competitionId: number; jury?: ICompetitionJury },
      ICompetitionJury
    >(CompetitionUpsertJuryComponent, {
      data: {
        competitionId: this.id$.value,
        jury
      }
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_)
      )
      .subscribe((jury) =>
        jury.id
          ? this.competitionsFacade.editJury(jury)
          : this.competitionsFacade.addJury(jury)
      );
  }

  onDeleteJury(jury: ICompetitionJury) {
    if (confirm(`Delete jury - ${jury.name} [${jury.email}]?`)) {
      this.competitionsFacade.deleteJury({ jury });
    }
  }

  onUpsertSubmission(review: ICompetitionReview) {
    const dialogRef = this.dialog.open(CompetitionReviewResultComponent, {
      data: { review }
    });

    return dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.componentDestroy$),
        tap((_) => this.competitionsFacade.updateSubmission({ submission: _ }))
      )
      .subscribe();
  }

  onReviewSubmission(review: ICompetitionReview) {
    const dialogRef = this.dialog.open(
      CompetitionJuryRateSubmissionDialogComponent,
      {
        data: { review }
      }
    );

    return dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_),
        switchMap((_) => {
          return this.jury$.pipe(
            switchMap((jury) => {
              return this.route.queryParams.pipe(
                filter((params) => !!params.jury_token),
                tap((params) =>
                  this.competitionsFacade.rateSubmission({
                    submission: _,
                    jury: { ...jury, token: params.jury_token }
                  })
                )
              );
            })
          );
        })
      )
      .subscribe((_) =>
        this.snackBar.open('Update has been sent', 'OK', {
          duration: CompetitionInfoComponent.NOTIFICATION_DURATION_MS
        })
      );
  }

  onNotifyJury(jury: ICompetitionJury, competition: ICompetition) {
    if (confirm(`Notify jury ${jury.name}?`)) {
      this.competitionsService
        .notifyJury(jury, competition)
        .pipe(takeUntil(this.componentDestroy$))
        .subscribe((_) =>
          this.snackBar.open('Update has been sent', 'OK', {
            duration: CompetitionInfoComponent.NOTIFICATION_DURATION_MS
          })
        );
    }
  }

  onViewProject(project: ICompetitionProject) {
    const dialogRef = this.dialog.open<
      CompetitionProjectViewComponent,
      { project: ICompetitionProject },
      { openParticipate?: boolean }
    >(CompetitionProjectViewComponent, { data: { project } });

    return dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_)
      )
      .subscribe((_) => this.onJoin());
  }

  onViewTeam(team: ICompetitionTeam) {
    const dialogRef = this.dialog.open<
      CompetitionTeamViewComponent,
      { team: ICompetitionTeam },
      {
        confirm?: boolean;
        reject?: boolean;
      }
    >(CompetitionTeamViewComponent, { data: { team } });

    return dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.componentDestroy$),
        filter((_) => !!_)
      )
      .subscribe((_) => {
        if (_.confirm) {
          this.competitionsFacade.confirmCompetitionTeam({ team });
        }
        if (_.reject) {
          this.competitionsFacade.rejectCompetitionTeam({ team });
        }
        this.snackBar.open('Update has been sent', 'OK', {
          duration: CompetitionInfoComponent.NOTIFICATION_DURATION_MS
        });
      });
  }

  onRemoveTeam(team: ICompetitionTeam) {
    if (confirm(`Remove team: ${team.name}?`)) {
      this.competitionsFacade.removeCompetitionTeam({ team });
    }
  }
}
