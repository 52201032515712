import { Pipe } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe {
  transform(date: number, isFullAgoDesc = false): string {
    if (isFullAgoDesc) {
      return moment(date).fromNow();
    }

    const now = new Date().getTime();
    const seconds = Math.floor((now - date) / 1000);

    const intervalType = this.intervalType(seconds);

    if (!intervalType) {
      return 'now';
    }

    return `${intervalType.interval}${intervalType.type} ago`;
  }

  private intervalType(seconds: number): {
    interval: number;
    type: 'y' | 'mth' | 'd' | 'h' | 'm';
  } {
    const secondsInYear = 31536000;
    const secondsInMonth = 2592000;
    const secondsInDay = 86400;
    const secondsInHour = 3600;
    const secondsInMinute = 60;

    const years = Math.floor(seconds / secondsInYear);

    if (years >= 1) {
      return { interval: years, type: 'y' };
    }

    const months = Math.floor(seconds / secondsInMonth);

    if (months >= 1) {
      return { interval: months, type: 'mth' };
    }

    const days = Math.floor(seconds / secondsInDay);

    if (days >= 1) {
      return { interval: days, type: 'd' };
    }

    const hours = Math.floor(seconds / secondsInHour);

    if (hours >= 1) {
      return { interval: hours, type: 'h' };
    }

    const minutes = Math.floor(seconds / secondsInMinute);

    if (minutes >= 1) {
      return { interval: minutes, type: 'm' };
    }

    return null;
  }
}
