import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private base: BaseService) {}

  getDialogs(page) {
    return this.base.allGet(`message?page=${page}`).pipe(
      map(({ response: { dialogs } }) => ({
        dialogs: dialogs.map(this.mapDialogData)
      }))
    );
  }

  getDialogById(id) {
    return this.base
      .get(`message/dialog/${id}`)
      .pipe(map((res) => ({ messages: res.messages })));
  }

  private mapDialogData(dialog) {
    return {
      ...dialog,
      updated_at: {
        ...dialog.updated_at,
        date: moment(dialog.updated_at.date)
      }
    };
  }
}
