import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class MessagePhotosComponent {
  @Input() readonly photos: Array<{ url: string }>;

  @Output() loaded = new EventEmitter();

  public imageIndex: number = null;

  onImageRendered() {
    this.loaded.emit();
  }

  toggleImageFullscreen(index: number) {
    this.imageIndex = index;
  }
}
