import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseFormControlComponent } from '../base-form-control/base-form-control.component';

@Component({
  selector: 'app-base-form-control-array',
  template: ``,
  styleUrls: []
})
export class BaseFormControlArrayComponent extends BaseFormControlComponent {
  @Input() label: string;
  disabled: boolean;
  form: FormGroup;

  submit() {
    const value = this.form.getRawValue();
    if (value.id) {
      const idx = this.selectedItems.findIndex((item) => item.id === value.id);
      this.selectedItems[idx] = value;
    } else {
      this.selectedItems.push(this.form.getRawValue());
    }
    this.form.reset();
  }

  selectItem(index) {
    if (this.disabled) {
      return false;
    }
    this.form.patchValue(this.selectedItems[index]);
  }

  removeItem(index: number) {
    this.selectedItems.splice(index, 1);
  }
}
