import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { BaseService } from 'src/app/core/services/base.service';
import { NotEmptyURLSearchParams } from 'src/app/shared/types/not-empty-url-search-params.type';

import { Request, Group } from './group.model';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  constructor(private base: BaseService, private auth: AuthService) {}

  getGroupInfo(id) {
    const entry = this.auth.isFullyAuthorized ? 'group/view' : 'guest/group';

    return this.base
      .get(`${entry}/${id}`)
      .pipe(map((res) => ({ group: res.group })));
  }

  getGroupPosts(id, page: number) {
    const entry = this.auth.isFullyAuthorized
      ? 'group/posts'
      : 'guest/group-posts';

    return this.base
      .get(`${entry}/${id}?page=${page}`)
      .pipe(map((res) => res.posts));
  }

  getGroupNetwork(id: number, page: number, searchTerm = '') {
    const urlParams = new NotEmptyURLSearchParams({
      page: page + '',
      text: searchTerm
    });

    return this.base
      .get(`group/network/${id}?${urlParams}`)
      .pipe(map((res) => ({ users: res.users, count: res.count })));
  }

  groupRequests(id, page, searchTerm = '') {
    const urlParams = new URLSearchParams({
      page,
      text: searchTerm
    });

    return this.base
      .get(`group/requests/${id}?${urlParams}`)
      .pipe(map((res) => ({ requests: res.users, count: res.count })));
  }

  subscribeGroup(id: number, text: string = '') {
    // TODO (ROUN-323)
    // URLSearchParams will NOT ignore element if it's undefined
    // instead, it will be the 'undefined' string.
    // In some cases, it can be a problem, cuz undefined will be placed within query string
    const urlParams = new URLSearchParams({
      text
    });

    return this.base.get(`group/subscribe/${id}?${urlParams}`);
  }

  unsubscribeGroup(id: number) {
    return this.base.get(`group/unsubscribe/${id}`);
  }

  unrequestGroup(group: number, id: number) {
    const url = new URLSearchParams({
      user_id: id + ''
    });

    return this.base.get(`group/unrequest/${group}?${url}`);
  }

  accessGroup(group: number, id: number) {
    return this.base.get(`group/access/${group}?user_id=${id}`);
  }

  unaccessGroup(group: number, id: number) {
    return this.base.get(`group/unaccess/${group}?user_id=${id}`);
  }

  updateGroup(
    id,
    data: {
      organization?: {
        name: string;
        address: string;
      };
      school?: {
        name: string;
        address: string;
      };
      [key: string]: any;
    }
  ): Observable<{ group: Group }> {
    return this.base
      .post(`group/edit/${id}`, {
        ...data,
        org: data.organization
          ? {
              name: data.organization.name,
              address: { name: data.organization.address }
            }
          : null,
        body: data.school
          ? { name: data.school.name, address: { name: data.school.address } }
          : null
      })
      .pipe(map((res) => ({ group: res.group })));
  }

  removeGroup(id): Observable<{ id: number }> {
    return this.base.get(`group/del/${id}`).pipe(map((_) => ({ id })));
  }
}
