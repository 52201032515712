import { Location, DOCUMENT } from '@angular/common';
import { Injectable, Optional, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { combineLatest, of, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  mergeMap,
  skip,
  switchMap,
  tap,
  map
} from 'rxjs/operators';

import { RegistrationActions } from 'src/app/modules/registration/store/actions';
import { AuthService } from 'src/app/core/services/auth.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { IAuthSuccessData } from 'src/app/modules/registration/model';
import { RegistrationFacade } from 'src/app/modules/registration/store';
import { AmplitudeService } from 'src/app/shared/modules/amplitude/services';
import { TUser } from 'src/app/shared/models/user/user';

@Injectable({
  providedIn: 'root'
})
export class AppEffects {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _authService: AuthService,
    private readonly _tokenService: TokenService,
    @Optional() private readonly _amplitudeService: AmplitudeService
  ) {}

  @Effect({ dispatch: false })
  pageLoaded$ = this._router.events.pipe(
    filter((_): _ is NavigationEnd => _ instanceof NavigationEnd),
    map((_) => this.document.URL),
    distinctUntilChanged(),
    tap((_) =>
      this._amplitudeService?.logEvent('/global/page-loaded', {
        url: _,
        referrer: this.document.referrer
      })
    )
  );

  @Effect({ dispatch: false })
  runGuardsOnAuthStateUpdate$ = this._router.events.pipe(
    filter((_) => _ instanceof NavigationEnd),
    filter(
      (_) => !this._router.getCurrentNavigation().extras?.skipLocationChange
    ),
    distinctUntilChanged(
      (x: NavigationEnd, y: NavigationEnd) =>
        x?.urlAfterRedirects === y?.urlAfterRedirects
    ),
    switchMap((_) => this._authService.status$),
    skip(1),
    filter((_) => _ !== TokenService.TokenStatus.UNDEFINED),
    distinctUntilChanged(),
    tap((_) => this._router.navigateByUrl(this._location.path(true)))
  );

  @Effect({ dispatch: false })
  doneRegistration$ = this._actions$.pipe(
    ofType<RegistrationActions.StudentRegistrationSuccessAction>(
      RegistrationActions.ActionTypes.STUDENT_REGISTRATION_SUCCESS
    ),
    mergeMap((_) => this._authService.tokenLogin())
  );

  @Effect({ dispatch: false })
  cancelRegistration$ = this._actions$.pipe(
    ofType<RegistrationActions.RegistrationCancelAction>(
      RegistrationActions.ActionTypes.REGISTRATION_CANCEL
    ),
    tap((_) => this._tokenService.removeToken())
  );

  @Effect({ dispatch: false })
  appleAuth$ = this._actions$.pipe(
    ofType<RegistrationActions.AppleAuthRegistrationSuccessAction>(
      RegistrationActions.ActionTypes.APPLE_AUTH_SUCCESS
    ),
    tap((_) => this.onAuth(_.payload, RegistrationFacade.SocialAuthType.APPLE))
  );

  @Effect({ dispatch: false })
  googleAuth$ = this._actions$.pipe(
    ofType<RegistrationActions.GoogleAuthRegistrationSuccessAction>(
      RegistrationActions.ActionTypes.GOOGLE_AUTH_SUCCESS
    ),
    tap((_) => this.onAuth(_.payload, RegistrationFacade.SocialAuthType.GOOGLE))
  );

  @Effect({ dispatch: false })
  passwordlessAuth$ = this._actions$.pipe(
    ofType<RegistrationActions.PasswordlessAuthRegistrationSuccessAction>(
      RegistrationActions.ActionTypes.PASSWORDLESS_AUTH_SUCCESS
    ),
    tap((_) => {
      this._authService.setUserType(_.payload.type);
      this._authService.setToken(_.payload.token, _.payload.status_id, true);
    })
  );

  @Effect({ dispatch: false })
  attachEmail$ = this._actions$.pipe(
    ofType<RegistrationActions.AttachToEmailRegistrationSuccessAction>(
      RegistrationActions.ActionTypes.ATTACH_TO_EMAIL_SUCCESS
    ),
    tap((_) => {
      this._router.navigate(['registration', 'social-auth-success'], {
        state: { success: true }
      });
    })
  );

  @Effect({ dispatch: false })
  initializeAmplitute$ = this._authService.isLoaded$.pipe(
    filter((_) => _),
    mergeMap((_) =>
      combineLatest([
        this._authService.user$,
        this._authService.isNotAuthorized$,
        this._authService.isPartialAuthorized$,
        this._authService.isFullyAuthorized$,
        this._tokenService.token$,
        this._tokenService.status$
      ])
    ),
    mergeMap(
      ([
        user,
        isNotAuthorized,
        isPartialAuthorized,
        isFullyAuthorized,
        token,
        status
      ]) =>
        this.initializeAmplitute(
          user,
          isNotAuthorized,
          isPartialAuthorized,
          isFullyAuthorized,
          token,
          status
        )
    )
  );

  private onAuth(
    data: IAuthSuccessData,
    type: RegistrationFacade.SocialAuthType
  ) {
    if (!data.token) {
      this._router.navigate(['registration', 'social-auth-selection'], {
        state: { email: data.email, type }
      });

      return;
    }

    this._authService.setUserType(data.type);
    this._authService.setToken(data.token, data.status_id, true);
  }

  private initializeAmplitute(
    user: null | TUser,
    isNotAuthorized: boolean,
    isPartialAuthorized: boolean,
    isFullyAuthorized: boolean,
    token: string,
    status: TokenService.TokenStatus
  ): Observable<void> {
    if ((isPartialAuthorized || isFullyAuthorized) && !user) {
      // NOTE: skip if we authorized and don't have a user object to set yet
      return of();
    }

    return this._amplitudeService
      ? this._amplitudeService.init({
          id: user?.id || null,
          user,
          isNotAuthorized,
          isPartialAuthorized,
          isFullyAuthorized,
          token,
          status
        })
      : of();
  }
}
