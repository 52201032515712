import { Injectable } from '@angular/core';
import { switchMap, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  searchDiscussions,
  searchDiscussionsSuccess
} from './discussions.actions';
import { DiscussionsService } from './discussions.service';

@Injectable()
export class DiscussionsEffects {
  constructor(
    private actions$: Actions,
    private discussionsService: DiscussionsService
  ) {}

  effectForSearchDiscussions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchDiscussions),
      switchMap((params) =>
        this.discussionsService.searchDiscussions(params.searchParams).pipe(
          switchMap(({ count, discussions }) => [
            searchDiscussionsSuccess({
              count,
              discussions: this.normalize(discussions)
            })
          ])
        )
      )
    )
  );

  private normalize(events) {
    return events.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
