import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import { Notification } from './notification.model';
import * as NotificationsActions from './notifications.actions';
import * as UserActions from '../user/user.actions';

export const notificationsFeatureKey = 'notifications';

export interface State {
  nextPageToLoad: number;
  notifications: Notification[];
}

export const initialState: State = {
  nextPageToLoad: 1,
  notifications: null
};

export const reducer = createReducer(
  initialState,
  on(UserActions.userLogout, () => {
    return initialState;
  }),
  on(NotificationsActions.loadNotificationsSuccess, (state, action) => {
    const notifications = state.notifications ? [...state.notifications] : [];

    return {
      ...state,
      notifications: _.uniqBy(
        [...notifications, ...action.notifications],
        (notification) => notification.id
      ),
      nextPageToLoad:
        action.notifications.length === 0
          ? state.nextPageToLoad
          : state.nextPageToLoad + 1
    };
  }),
  on(NotificationsActions.readNotificationSuccess, (state, action) => {
    return {
      ...state,
      notifications: state.notifications.filter(
        (notification) => notification.id !== action.id
      )
    };
  }),
  on(NotificationsActions.resetNextPageToLoad, (state, action) => {
    return {
      ...state,
      nextPageToLoad: 1,
      notifications: null
    };
  })
);

export const selectState = createFeatureSelector<State>(
  notificationsFeatureKey
);

export const selectNotifications = () =>
  createSelector(selectState, (state: State) => state.notifications);

export const selectNextPageToLoad = () =>
  createSelector(selectState, (state: State) => state.nextPageToLoad);
