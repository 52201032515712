import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnInit {
  @Input() readonly ctx: ICheckboxCtx;

  constructor() {}

  ngOnInit(): void {}

  public onInputClick() {}
}

export interface ICheckboxCtx {
  placeholder: string;
  control: FormControl;
}
