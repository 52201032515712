import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import {
  AppleStoreIconComponent,
  GoogleStoreIconComponent
} from './components';
import { CONFIG, IConfig } from './app-store-icon.config';

@NgModule({
  declarations: [AppleStoreIconComponent, GoogleStoreIconComponent],
  imports: [CommonModule],
  exports: [AppleStoreIconComponent, GoogleStoreIconComponent]
})
export class AppStoreIconModule {
  static forRoot(config: IConfig): ModuleWithProviders<AppStoreIconModule> {
    return {
      ngModule: AppStoreIconModule,
      providers: [
        {
          provide: CONFIG,
          useValue: config
        }
      ]
    };
  }
}
