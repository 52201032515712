import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ViewedService {
  private readonly _viewedPosts = new Set<number>();

  constructor(private base: BaseService, private auth: AuthService) {}

  view(id: number, type: ViewedService.Type) {
    if (!this.auth.isFullyAuthorized) {
      return;
    }

    const internalStructure = this.getInternalStructureForType(type);
    // if don't support this type, just return
    if (!internalStructure) {
      return of(false);
    }

    // if already processed similar request, just return
    if (internalStructure.has(id)) {
      return of(true);
    }

    return this.viewRequest(id).pipe(tap((_) => internalStructure.add(id)));
  }

  private getInternalStructureForType(type: ViewedService.Type) {
    switch (type) {
      case ViewedService.Type.Post:
        return this._viewedPosts;
      default:
        return null;
    }
  }

  private viewRequest(id: number) {
    return this.base.get(`post/viewed/${id}`).pipe(map((_) => true));
  }
}

export namespace ViewedService {
  export enum Type {
    Post = 'post'
  }
}
