<ng-template #confirmDialog>
  <button type="button" class="close-button" [mat-dialog-close]="false"></button>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12">
        <h3 class="text-center">{{dialogMessage}}</h3>
      </div>
    </div>
    <div class="row justify-content-center align-items-center mt-5">
      <div class="col-auto">
        <button type="button" class="close" [mat-dialog-close]="false">Cancel</button>
      </div>
      <div class="col-auto">
        <button type="button" class="app-action-btn delete" [mat-dialog-close]="true">Delete</button>
      </div>
    </div>
  </div>
</ng-template>
