import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { BaseFormControlComponent } from '../base-form-control/base-form-control.component';
import { BaseFormControlArrayComponent } from '../base-form-control-array/base-form-control-array.component';

@Component({
  selector: 'app-form-control-array',
  templateUrl: './form-control-array.component.html',
  styleUrls: ['./form-control-array.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlArrayComponent),
      multi: true
    }
  ]
})
export class FormControlArrayComponent
  extends BaseFormControlArrayComponent
  implements OnInit
{
  form: FormGroup;
  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [],
      id: []
    });
  }
}
