import {
  EventEmitter,
  Component,
  Input,
  Output,
  AfterViewChecked
} from '@angular/core';

@Component({
  selector: 'app-message-text',
  templateUrl: './text.component.html'
})
export class MessageTextComponent implements AfterViewChecked {
  @Input() text: string;

  @Output() loaded = new EventEmitter();

  ngAfterViewChecked() {
    this.loaded.emit();
  }
}
