import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import * as EventsActions from './events.actions';
import { Event } from './event.model';

export const eventsFeatureKey = 'events';

export interface State {
  events: { [key: number]: Event };
  count: number;
  loadEvents: boolean;
}

export const initialState: State = {
  events: null,
  count: null,
  loadEvents: false
};

export const reducer = createReducer(
  initialState,
  on(EventsActions.searchEvents, (state, action): State => {
    return {
      ...state,
      events: null,
      count: null
    };
  }),
  on(EventsActions.searchEventsSuccess, (state, action): State => {
    return {
      ...state,
      events: action.events,
      count: action.count
    };
  })
);

export const selectState = createFeatureSelector<State>(eventsFeatureKey);

export const selectEvents = () =>
  createSelector(selectState, (state: State) => {
    if (!state.events) {
      return { items: null, loadEvents: false, count: state.count };
    }

    return {
      items: Object.values(state.events),
      loadEvents: state.loadEvents,
      count: state.count
    };
  });
