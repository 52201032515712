<div class="dialog-content-overlay">
  <h1 mat-dialog-title>{{juryId? 'Edit' : 'Add'}} jury</h1>
  <div mat-dialog-content>
    <div class="form-wrapper p-0">
      <form [formGroup]="jury" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100">
          <mat-label>Full name</mat-label>
          <input matInput formControlName="name" type="text" required [readonly]="!!juryId" />
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" type="email" required [readonly]="!!juryId" />
        </mat-form-field>
        <div *ngIf="(projects$ | async) as projects; else loading">
          <div class="projects-label">Select projects</div>
          <div class="projects-wrap">
            <div *ngFor="let project of projects; let i = index" formArrayName="projects_id">
              <mat-checkbox [formControlName]="i">{{project.title}}</mat-checkbox>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSubmit(); false" [disabled]="!jury.valid"
      >{{juryId? 'Update' : 'Invite'}}</button
    >
  </div>
</div>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
