import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { AfterSchool } from 'src/app/core/services/eduniverse/eduniverse.model';
import { RoutingService } from '../../../core/services/routing.service';

@Component({
  selector: 'app-after-school-programs-right-bar',
  templateUrl: './after-school-programs-right-bar.component.html',
  styleUrls: ['./after-school-programs-right-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AfterSchoolProgramsRightBarComponent {
  @Input() afterSchoolPrograms: { totalCount: number; items: AfterSchool[] };

  constructor(private routing: RoutingService) {}

  public goToProfile(profile) {
    this.routing.goToProfile(profile);
  }
}
