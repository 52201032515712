import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  tap
} from 'rxjs/operators';

@Component({
  selector: 'app-new-message-input-field',
  templateUrl: './new-message-input-field.component.html',
  styleUrls: ['./new-message-input-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewMessageInputFieldComponent
  implements OnDestroy, OnChanges, AfterViewInit
{
  static readonly TAG = '@';

  @Input() message: string;
  @Input() removeTag$: Subject<void>;
  @Input() emojiSupplier$: Subject<string>;
  @Input() tagDataSupplier$: Subject<{ tagNode: HTMLAnchorElement }>;
  @Input() placeholder? = '';
  @Input() focus? = false;

  @Output() tag = new EventEmitter<string>();
  @Output() messageChange = new EventEmitter<string>();

  @ViewChild(`Text`, { static: true })
  readonly textRef: ElementRef<HTMLDivElement>;

  private _tagSymbol$ = new Subject<void>();
  private _tag$ = new BehaviorSubject<string>(null);
  private _componentDestroy$ = new Subject<void>();

  constructor() {
    this._tag$
      .pipe(
        takeUntil(this._componentDestroy$),
        distinctUntilChanged(),
        tap((_) => this.tag.emit(_))
      )
      .subscribe((_) => _);

    this._tagSymbol$
      .pipe(
        takeUntil(this._componentDestroy$),
        // only one active tag symbol can be in one time
        filter((_) => [undefined, null].includes(this._tag$.value)),
        tap((_) => this.onTagCharacterEnter())
      )
      .subscribe((_) => _);
  }

  ngOnChanges(changes: SimpleChanges) {
    // hot fix to reset value to empty string
    // probably we should listen to all changes and update value from it
    // but we need update cursor position properly (case when character was removed from input and we need to place it properly)
    if (changes.message.currentValue === '') {
      this.textRef.nativeElement.innerHTML = '';
    }
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  ngAfterViewInit() {
    const el = this.textRef.nativeElement;

    el.setAttribute('placeholder', this.placeholder);
    el.innerHTML = this.message;
    this.focus && el.focus();

    this.emojiSupplier$
      .pipe(
        takeUntil(this._componentDestroy$),
        tap((_) => this.onEmoji(_)),
        map((_) => this.nodeContentWithoutTags(this.textRef.nativeElement)),
        tap((_) => this.messageChange.emit(_.innerHTML))
      )
      .subscribe((_) => _);

    this.tagDataSupplier$
      .pipe(
        takeUntil(this._componentDestroy$),
        tap((_) => this.onSelect(_.tagNode)),
        tap((_) => this._tag$.next(null)),
        map((_) => this.nodeContentWithoutTags(this.textRef.nativeElement)),
        tap((_) => this.messageChange.emit(_.innerHTML))
      )
      .subscribe((_) => _);

    fromEvent(this.textRef.nativeElement, 'paste')
      .pipe(
        takeUntil(this._componentDestroy$),
        map((_: ClipboardEvent) => {
          _.preventDefault();

          return _.clipboardData.getData('text/plain');
        }),
        map((_) => document.execCommand('insertHTML', false, _))
      )
      .subscribe((_) => _);

    fromEvent<KeyboardEvent>(this.textRef.nativeElement, 'keydown')
      .pipe(
        takeUntil(this._componentDestroy$),
        // delay in order to wait until symbol will be printed within innerHTML
        // cannout use keyup, cuz tag can be splitted in two parts (e.g. "@" can be splitted to ["Shift", "2"])
        this.waitTagRender,
        this.filterTagSymbol,
        tap((_) => this._tagSymbol$.next())
      )
      .subscribe((_) => _);

    fromEvent(this.textRef.nativeElement, 'input')
      .pipe(
        takeUntil(this._componentDestroy$),
        // delay in order to wait until symbol will be printed within innerHTML
        this.waitTagRender,
        map(
          (_) =>
            this.textRef.nativeElement.querySelector('[__tag]')?.textContent
        ),
        tap((_) => this._tag$.next(_))
      )
      .subscribe((_) => _);

    fromEvent(this.textRef.nativeElement, 'input')
      .pipe(
        takeUntil(this._componentDestroy$),
        tap((_) => this.guaranteeTagInvatiant()),
        map((_) => this.nodeContentWithoutTags(this.textRef.nativeElement)),
        tap((_) => this.messageChange.emit(_.innerHTML))
      )
      .subscribe((_) => _);

    fromEvent<KeyboardEvent>(this.textRef.nativeElement, 'keyup')
      .pipe(
        takeUntil(this._componentDestroy$),
        filter((_) => ['Escape'].includes(_.key)),
        tap((_) => this.removeTag()),
        tap((_) => this._tag$.next(null))
      )
      .subscribe((_) => _);

    this.removeTag$
      .pipe(
        takeUntil(this._componentDestroy$),
        tap((_) => this.removeTag()),
        tap((_) => this._tag$.next(null))
      )
      .subscribe((_) => _);
  }

  private removeTag() {
    const currentPosition = this.textContentCurrentCaretPosition();

    const node = this.nodeContentWithoutTags(this.textRef.nativeElement);
    this.textRef.nativeElement.innerHTML = node.innerHTML;

    this.setCaret(this.textRef.nativeElement, currentPosition);
  }

  private nodeContentWithoutTags(node: HTMLDivElement) {
    const node1 = node.cloneNode(true) as HTMLDivElement;

    const tagStart = node1.querySelector('[__tag_start]');
    const tag = node1.querySelector('[__tag]');
    const tagEnd = node1.querySelector('[__tag_end]');

    if (tagStart) {
      node1.replaceChild(
        document.createTextNode(NewMessageInputFieldComponent.TAG),
        tagStart
      );
    }

    if (tag) {
      node1.replaceChild(document.createTextNode(tag.textContent), tag);
    }

    if (tagEnd) {
      node1.removeChild(tagEnd);
    }

    return node1;
  }

  private onSelect(tagNode: HTMLAnchorElement) {
    const tagStart = this.textRef.nativeElement.querySelector('[__tag_start]');
    const tag = this.textRef.nativeElement.querySelector('[__tag]');
    const tagEnd = this.textRef.nativeElement.querySelector('[__tag_end]');

    if (tagStart) {
      this.textRef.nativeElement.removeChild(tagStart);
    }

    if (tag) {
      this.textRef.nativeElement.replaceChild(tagNode, tag);
    }

    if (tagEnd) {
      this.textRef.nativeElement.removeChild(tagEnd);
    }

    // NOTE: add space element at the end of tag in order to fix
    // space and cursor issues within the Safari browser
    const space = document.createTextNode(' ');
    const elem = document.createElement('span');
    elem.appendChild(space);

    tagNode.insertAdjacentElement('afterend', elem);

    // NOTE: just point to the end of space, to show user
    // that we inserted a space right after the tagged user
    this.setCaretWithinChildNode(elem, space.length);
  }

  // TODO: fix one more new line after inserting emoji on blur input
  private onEmoji(emoji: string) {
    let currentPosition = this.innerHTMLCurrentCaretPosition();

    if (currentPosition < 0) {
      currentPosition = this.textRef.nativeElement.innerHTML.length;
    }

    const before = this.textRef.nativeElement.innerHTML.slice(
      0,
      currentPosition
    );
    const after = this.textRef.nativeElement.innerHTML.slice(currentPosition);

    this.textRef.nativeElement.innerHTML = '';

    const beforeElement = this.createElementFromHTML(before);
    const emojiElement = document.createTextNode(emoji);
    const afterElement = this.createElementFromHTML(after);

    beforeElement &&
      beforeElement.forEach((_) => this.textRef.nativeElement.appendChild(_));
    emojiElement && this.textRef.nativeElement.appendChild(emojiElement);
    afterElement &&
      afterElement.forEach((_) => this.textRef.nativeElement.appendChild(_));

    this.setCaretWithinChildNode(emojiElement, emojiElement.length);

    this.textRef.nativeElement.focus();
  }

  createElementFromHTML(htmlString: string) {
    const template = document.createElement('template');
    template.innerHTML = htmlString;

    // NOTE: have no idea but Array.from is really important here
    // would be nice to digest further
    return Array.from(template.content.childNodes);
  }

  private onTagCharacterEnter() {
    const currentPosition = this.innerHTMLCurrentCaretPosition();

    const before = this.textRef.nativeElement.innerHTML.slice(
      0,
      // -1 in order to exclude entered tag symbol
      currentPosition - 1
    );

    const after = this.textRef.nativeElement.innerHTML.slice(currentPosition);

    const tagStart = this.buildTagStart();
    const tag = this.buildTag();
    const tagEnd = this.buildTagEnd();

    this.textRef.nativeElement.innerHTML = before;
    this.textRef.nativeElement.appendChild(tagStart);
    this.textRef.nativeElement.appendChild(tag);
    this.textRef.nativeElement.appendChild(tagEnd);
    this.textRef.nativeElement.innerHTML += after;

    this.setCaretWithinChildNode(
      this.textRef.nativeElement.querySelector('[__tag]')
    );

    this.textRef.nativeElement.focus();
  }

  private innerHTMLCurrentCaretPosition(): number {
    const mark = '\u0001';
    const target = document.createTextNode(mark);

    if (!document.getSelection().rangeCount) {
      return this.textRef.nativeElement.innerHTML.length;
    }

    document.getSelection().getRangeAt(0).insertNode(target);

    const currentPosition = this.textRef.nativeElement.innerHTML.indexOf(mark);

    target.parentNode.removeChild(target);

    return currentPosition;
  }

  private textContentCurrentCaretPosition(): number {
    const mark = '\u0001';
    const target = document.createTextNode(mark);

    if (!document.getSelection().rangeCount) {
      return this.textRef.nativeElement.textContent.length;
    }

    document.getSelection().getRangeAt(0).insertNode(target);

    const currentPosition =
      this.textRef.nativeElement.textContent.indexOf(mark);

    target.parentNode.removeChild(target);

    return currentPosition;
  }

  private setCaret(node: Node, position = 0) {
    if (!node) {
      return;
    }

    for (const childNode of Array.from(node.childNodes)) {
      // @ts-ignore
      const nodeValue = childNode.innerHTML || childNode.textContent;
      const nodeContentLength = nodeValue.length;

      if (position <= nodeContentLength) {
        return this.setCaretWithinChildNode(childNode, position);
      }

      position -= nodeContentLength;
    }
  }

  private setCaretWithinChildNode(
    cNode: HTMLElement | ChildNode,
    position = 0
  ) {
    if (!cNode) {
      return;
    }

    // @ts-ignore
    const nodeContent = cNode.innerHTML || cNode.textContent;
    const nodeContentLength = nodeContent.length;

    if (position > nodeContentLength) {
      return;
    }

    const range = document.createRange();
    const sel = window.getSelection();

    range.setStart(cNode, position);
    range.collapse(true);

    sel.removeAllRanges();
    sel.addRange(range);
  }

  private guaranteeTagInvatiant() {
    const tagStart = this.textRef.nativeElement.querySelector('[__tag_start]');
    const tag = this.textRef.nativeElement.querySelector('[__tag]');
    const tagEnd = this.textRef.nativeElement.querySelector('[__tag_end]');

    if (!tagStart && tag) {
      this.textRef.nativeElement.replaceChild(
        document.createTextNode(tag.textContent),
        tag
      );
    }

    !tagStart && tagEnd && tagEnd.remove();

    if (tagStart && !tag) {
      tagStart.parentNode.insertBefore(this.buildTag(), tagStart.nextSibling);

      this.setCaretWithinChildNode(
        this.textRef.nativeElement.querySelector('[__tag]')
      );
    }

    if (tagStart && !tagEnd) {
      tag.parentNode.insertBefore(this.buildTagEnd(), tag.nextSibling);

      this.setCaretWithinChildNode(
        this.textRef.nativeElement.querySelector('[__tag]')
      );
    }
  }

  private filterTagSymbol = (
    source$: Observable<KeyboardEvent>
  ): Observable<KeyboardEvent> => {
    return source$.pipe(
      filter((_) => [NewMessageInputFieldComponent.TAG].includes(_.key))
    );
  };

  private waitTagRender = (
    source$: Observable<KeyboardEvent>
  ): Observable<KeyboardEvent> => {
    return source$.pipe(delay(1));
  };

  private buildTagStart() {
    const tag = document.createElement('span');
    tag.setAttribute('__tag_start', '');
    tag.setAttribute('contenteditable', 'false');
    tag.appendChild(document.createTextNode(NewMessageInputFieldComponent.TAG));

    return tag;
  }

  private buildTag() {
    const tag = document.createElement('span');
    tag.setAttribute('__tag', '');
    tag.setAttribute('contenteditable', 'true');

    return tag;
  }

  private buildTagEnd() {
    const tag = document.createElement('span');
    tag.setAttribute('__tag_end', '');
    tag.setAttribute('contenteditable', 'false');

    return tag;
  }
}
