import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import * as OrganizationsActions from './organizations.actions';
import { Organization } from './organization.model';

export const organizationsFeatureKey = 'organizations';

export interface State {
  organizations: { [key: number]: Organization };
  organization: Organization;
  count: number;
}

export const initialState: State = {
  organizations: null,
  organization: null,
  count: null
};

export const reducer = createReducer(
  initialState,
  on(OrganizationsActions.searchOrganizations, (state, action): State => {
    return {
      ...state,
      organizations: null,
      count: null
    };
  }),
  on(
    OrganizationsActions.searchOrganizationsSuccess,
    (state, action): State => {
      return {
        ...state,
        organizations: action.organizations,
        count: action.count
      };
    }
  ),
  on(OrganizationsActions.orgSubscribeToSuccess, (state, action): State => {
    if (!state.organizations || !state.organizations[action.id]) {
      return state;
    }

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [action.id]: {
          ...state.organizations[action.id],
          is_friend: true
        }
      }
    };
  }),
  on(OrganizationsActions.orgUnSubscribeFromSuccess, (state, action): State => {
    if (!state.organizations || !state.organizations[action.id]) {
      return state;
    }

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [action.id]: {
          ...state.organizations[action.id],
          is_friend: false
        }
      }
    };
  }),
  on(OrganizationsActions.loadOrganization, (state, action): State => {
    return {
      ...state,
      organization: null
    };
  }),
  on(OrganizationsActions.loadOrganizationSuccess, (state, action): State => {
    return {
      ...state,
      organization: action.organization
    };
  }),
  on(OrganizationsActions.resetOrganization, (state, action): State => {
    return {
      ...state,
      organization: null
    };
  })
);

export const selectState = createFeatureSelector<State>(
  organizationsFeatureKey
);

export const selectOrganizations = () =>
  createSelector(selectState, (state: State) => {
    if (!state.organizations) {
      return { items: null, count: state.count };
    }

    return { items: Object.values(state.organizations), count: state.count };
  });

export const selectOrganization = () =>
  createSelector(selectState, (state: State) => {
    return state.organization;
  });
