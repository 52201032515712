import { Pipe, PipeTransform } from '@angular/core';
import normalizeUrl from 'normalize-url';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'baseUrl'
})
export class BaseUrlPipe implements PipeTransform {
  transform(value: null | string): string {
    if (!value) {
      return null;
    }

    return normalizeUrl(this.getURL(value), { stripWWW: false });
  }

  private getURL(sufix: string) {
    if (sufix.startsWith(environment.endpoint)) {
      return sufix;
    }

    return [environment.endpoint, sufix].join('/');
  }
}
