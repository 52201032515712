import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, of, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';

import { ContactsFacade } from 'src/app/core/services/contacts/contacts.facade';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  IMessage as INewMessage,
  IMessageCtx
} from 'src/app/shared/components/message/message.component';

import { IChannel, IChannelDetails, IGroupChat } from '../../model';
import {
  NewGroupChatChannelDialogComponent,
  NewGroupChatDialogComponent,
  AddParticipantsToGroupChatChannelDialogComponent,
  EditGroupChatChannelDialogComponent,
  EditGroupChatDialogComponent
} from '../../shared/dialogs';
import { ChatFacade } from '../../store';
import { ChatsListActions, ChannelActions } from '../../store/actions';
import { NewGroupChatSuccessAction } from '../../store/actions/chats-list.action';
import { NewChannelSuccessAction } from '../../store/actions/channel.action';
import { GroupChatsSectionsService } from '../../shared/services';
import { NoticeCounterFacade } from 'src/app/root-store';
import { RoutingService } from '../../../../core/services/routing.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  private _channelId$ = new BehaviorSubject<number>(null);
  private _componentDestroy$ = new Subject();

  readonly messageCtx: IMessageCtx = {
    placeholder: 'Write a message or attach file',
    submit: (message) => this.onMessageSubmitSend(message),
    isSupportTags: false,
    isSupportLinkPreview: false
  };

  constructor(
    private readonly dialog: MatDialog,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly chatFacade: ChatFacade,
    private readonly contactsFacade: ContactsFacade,
    private readonly groupChatsSectionsService: GroupChatsSectionsService,
    private readonly noticeCounterFacade: NoticeCounterFacade,
    private routing: RoutingService
  ) {
    this.activatedRoute.paramMap
      .pipe(
        map((_) => _.get('id')),
        tap((_) => this.onInit(_)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  get groupChats$() {
    return this.chatFacade.groupChats$;
  }

  get isLoading$() {
    return this.chatFacade.isLoadingGroupChats$;
  }

  get channelInfo$() {
    return this.chatFacade.selectedChannel$;
  }

  get chatIdForSelectedChannel$() {
    return this.chatFacade.selectedChannel$.pipe(
      filter((_) => !!_),
      map((_) => _.chatId)
    );
  }

  get isOwner$() {
    return this.chatFacade.selectedChannel$.pipe(
      filter((_) => !!_),
      map((_) => this.authService.isMyId(_.ownerId))
    );
  }

  get isActiveChannel$() {
    return this.chatFacade.selectedChannel$.pipe(
      filter((_) => !!_),
      map((_) => !_.isDeleted)
    );
  }

  get chatId$() {
    return this.chatFacade.selectedChannel$.pipe(
      filter((_) => !!_),
      map((_) => _.chatId)
    );
  }

  get channelId$() {
    return this._channelId$;
  }

  get isChannelAdmin$() {
    return this.chatFacade.selectedChannel$.pipe(
      filter((_) => !!_),
      map((_) => this.authService.isMyId(_.ownerId))
    );
  }

  get amountOfUnreadChannels$() {
    return this.chatFacade.amountOfUnreadChannels$;
  }

  get amountOfUnreadChats$() {
    return this.noticeCounterFacade.messages$;
  }

  ngOnInit() {
    this.actions$
      .pipe(
        takeUntil(this._componentDestroy$),
        ofType(ChatsListActions.ActionTypes.NEW_GROUP_CHAT_SUCCESS),
        filter(
          (_: NewGroupChatSuccessAction) => !!_.payload.chat.channels[0]?.id
        ),
        filter(
          (_: NewGroupChatSuccessAction) =>
            _.payload.chat.ownerId === this.authService.userID()
        ),
        tap((_: NewGroupChatSuccessAction) =>
          this.onSelectChat(_.payload.chat.channels[0].id)
        ),
        tap((_: NewGroupChatSuccessAction) =>
          this.groupChatsSectionsService.open(_.payload.chat.id)
        )
      )
      .subscribe((_) => _);

    this.actions$
      .pipe(
        takeUntil(this._componentDestroy$),
        ofType(ChannelActions.ActionTypes.NEW_CHANNEL_SUCCESS),
        filter(
          (_: NewChannelSuccessAction) =>
            _.payload.channel.ownerId === this.authService.userID()
        ),
        tap((_: NewChannelSuccessAction) =>
          this.onSelectChat(_.payload.channel.id)
        ),
        tap((_: NewChannelSuccessAction) =>
          this.groupChatsSectionsService.open(_.payload.channel.chatId)
        )
      )
      .subscribe((_) => _);

    this.chatFacade
      .onGroupChatsEdit$()
      .pipe(takeUntil(this._componentDestroy$))
      .subscribe((_) => _);

    this.chatFacade
      .onGroupChatsMembers$()
      .pipe(takeUntil(this._componentDestroy$))
      .subscribe((_) => _);

    this.chatFacade
      .onEditChannel$()
      .pipe(takeUntil(this._componentDestroy$))
      .subscribe((_) => _);

    this._channelId$
      .pipe(
        takeUntil(this._componentDestroy$),
        filter((_) => !_),
        tap((_) => this.chatFacade.cleanSelectedChannel())
      )
      .subscribe((_) => _);

    this._channelId$
      .pipe(
        takeUntil(this._componentDestroy$),
        filter((_) => !!_),
        tap((_) => this.chatFacade.loadSelectedChannel(_))
      )
      .subscribe((_) => _);

    // TODO: add pagination
    this.chatFacade.loadGroupChats({ page: 1 });
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  onSelectChat(id: number) {
    this.router.navigate([id], { relativeTo: this.activatedRoute.parent });
  }

  channelLinkGenerator = (id: number) => {
    return this.router
      .createUrlTree([id], { relativeTo: this.activatedRoute.parent })
      .toString();
  };

  onCreateNewGroupChat() {
    this.contactsFacade.loadContacts();

    const dialogRef = this.dialog.open<
      NewGroupChatDialogComponent,
      NewGroupChatDialogComponent.Data,
      { title: string; participants: number[] }
    >(NewGroupChatDialogComponent, {
      data: {
        contacts$: this.contactsFacade.contacts$,
        isLoadingContacts$: this.contactsFacade.isLoadingContacts$
      }
    });

    const destroy$ = new Subject<void>();

    dialogRef.componentInstance.loadMoreContacts
      .pipe(
        tap((_) => this.onLoadContacts()),
        takeUntil(destroy$)
      )
      .subscribe();

    dialogRef.componentInstance.filter
      .pipe(
        debounceTime(300),
        tap((_) => this.onFilterContacts(_)),
        takeUntil(destroy$)
      )
      .subscribe();

    dialogRef
      .afterClosed()
      .pipe(
        tap((_) => {
          destroy$.next();
          destroy$.complete();
        }),
        tap((_) => this.contactsFacade.clear()),
        filter((_) => !!_),
        tap((_) => this.chatFacade.newGroupChat(_)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  onEditChat(chat: IGroupChat) {
    this.chatFacade.groupChatsMembers(chat.id);
    this.contactsFacade.loadContacts();

    const dialogRef = this.dialog.open<
      EditGroupChatDialogComponent,
      EditGroupChatDialogComponent.Data,
      { title: string; membersToAdd: number[]; membersToRemove: number[] }
    >(EditGroupChatDialogComponent, {
      data: {
        chatTitle$: of(chat.title),
        chatParticipants$: this.chatFacade.groupChatMembers$,
        isLoadingChatParticipants$: this.chatFacade.isLoadingGroupChatMembers$,
        contacts$: this.contactsFacade.contacts$,
        isLoadingContacts$: this.contactsFacade.isLoadingContacts$
      }
    });

    const destroy$ = new Subject<void>();

    dialogRef.componentInstance.loadMoreContacts
      .pipe(
        tap((_) => this.onLoadContacts()),
        takeUntil(destroy$)
      )
      .subscribe();

    dialogRef.componentInstance.filter
      .pipe(
        debounceTime(300),
        tap((_) => this.onFilterContacts(_)),
        takeUntil(destroy$)
      )
      .subscribe();

    dialogRef
      .afterClosed()
      .pipe(
        tap((_) => {
          destroy$.next();
          destroy$.complete();
        }),
        tap((_) => this.contactsFacade.clear()),
        filter((_) => !!_),
        tap((_) => {
          this.chatFacade.editGroupChats({ id: chat.id, title: _.title });
          this.chatFacade.groupChatsMembersEdit(
            chat.id,
            _.membersToAdd,
            _.membersToRemove
          );
        }),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  onAddChannel(chatId: number) {
    this.chatFacade.groupChatsMembers(chatId);

    const dialogRef = this.dialog.open<
      NewGroupChatChannelDialogComponent,
      NewGroupChatChannelDialogComponent.Data,
      { title: string; participants: number[] }
    >(NewGroupChatChannelDialogComponent, {
      data: {
        chatParticipants$: this.chatFacade.groupChatMembers$,
        isLoadingParticipants$: this.chatFacade.isLoadingGroupChatMembers$
      }
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((_) => !!_),
        tap((_) => this.chatFacade.newGroupChatChannel({ ..._, chatId })),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  onEditChannel(channel: IChannelDetails) {
    this.chatFacade.groupChatsMembers(channel.chatId);
    this.chatFacade.channelMembers(channel.id);

    const dialogRef = this.dialog.open<
      EditGroupChatChannelDialogComponent,
      EditGroupChatChannelDialogComponent.Data,
      { title: string; membersToAdd: number[]; membersToRemove: number[] }
    >(EditGroupChatChannelDialogComponent, {
      data: {
        chatParticipants$: this.chatFacade.groupChatMembers$,
        isLoadingChatParticipants$: this.chatFacade.isLoadingGroupChatMembers$,
        channel$: this.chatFacade.selectedChannel$,
        channelMembers$: this.chatFacade.channelMembers$
      }
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((_) => !!_),
        tap((_) => {
          this.chatFacade.editChannel({ id: channel.id, title: _.title });
          this.chatFacade.channelMembersEdit(
            channel.id,
            _.membersToAdd,
            _.membersToRemove
          );
        }),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  onAddParticipants(channelInfo: IChannel) {
    this.chatFacade.groupChatsMembers(channelInfo.chatId);
    this.chatFacade.channelMembers(channelInfo.id);

    const dialogRef = this.dialog.open<
      AddParticipantsToGroupChatChannelDialogComponent,
      AddParticipantsToGroupChatChannelDialogComponent.Data,
      { title: string; membersToAdd: number[]; membersToRemove: number[] }
    >(AddParticipantsToGroupChatChannelDialogComponent, {
      data: {
        chatTitle$: this.channelInfo$.pipe(map((_) => _?.chatTitle)),
        channelTitle$: this.channelInfo$.pipe(map((_) => _?.title)),
        chatMembers$: this.chatFacade.groupChatMembers$,
        channelMembers$: this.chatFacade.channelMembers$
      }
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((_) => !!_),
        tap((_) =>
          this.chatFacade.channelMembersEdit(
            channelInfo.id,
            _.membersToAdd,
            _.membersToRemove
          )
        ),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  onLeaveChannel(channel: IChannelDetails) {
    this.chatFacade.channelLeave({
      chatId: channel.chatId,
      channelId: channel.id
    });
  }

  onDeleteChannel(channel: IChannelDetails) {
    this.chatFacade.channelDelete({
      chatId: channel.chatId,
      channelId: channel.id
    });
  }

  private onMessageSubmitSend(message: INewMessage) {
    this.chatFacade.newMessage({
      content: message.text,
      channelId: this._channelId$.value,
      photos_ids: message.images,
      files_ids: message.docs
    });
  }

  private onInit(id?: string) {
    if (!id) {
      return this._channelId$.next(null);
    }

    const channelId = Number(id);
    if (!Number.isSafeInteger(channelId)) {
      return;
    }

    this._channelId$.next(channelId);
  }

  private onLoadContacts() {
    this.contactsFacade.nextPageToLoad$
      .pipe(
        take(1),
        takeUntil(this._componentDestroy$),
        filter((_) => !!_),
        tap((_) => this.contactsFacade.loadContacts(_))
      )
      .subscribe((_) => _);
  }

  private onFilterContacts(value: string) {
    const page = 1;

    this.contactsFacade.clear();
    this.contactsFacade.loadContacts(page, value);
  }

  backToList() {
    this.routing.goToGroupMessages();
  }
}
