import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appShowUntilLoaded]'
})
export class ShowUntilLoadedDirective implements OnInit, OnDestroy {
  @Input() onLoaded$?: Observable<boolean>;
  @Input() onLoadedOnce$?: Observable<boolean>;

  private readonly _componentDestroy$ = new Subject();

  constructor(private readonly hostElement: ElementRef) {}

  ngOnInit() {
    this.showHostElement();

    this.onLoaded$ &&
      this.onLoaded$
        .pipe(tap((_) => (_ ? this.hideHostElement() : this.showHostElement())))
        .subscribe((_) => _);

    this.onLoadedOnce$ &&
      this.onLoadedOnce$
        .pipe(
          takeUntil(this._componentDestroy$),
          take(1),
          tap((_) => this.hideHostElement())
        )
        .subscribe((_) => _);
  }

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  private hideHostElement() {
    // TODO: consider to add this logic
    // this.hostElement.nativeElement.style.visibility = 'hidden';
    this.hostElement.nativeElement.hidden = true;
  }

  private showHostElement() {
    // TODO: consider to add this logic
    // this.hostElement.nativeElement.style.visibility = 'visible';
    this.hostElement.nativeElement.hidden = false;
  }
}
