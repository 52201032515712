import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import lodash from 'lodash';

import { AuthService } from 'src/app/core/services/auth.service';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';

@Pipe({
  name: 'isCurrentUser'
})
export class IsCurrentUserPipe implements PipeTransform {
  constructor(
    private readonly _auth: AuthService,
    private readonly _convertIdService: ConvertIdService
  ) {}

  transform(id: number | string, type?: 'mangled'): Observable<boolean> {
    if (type === 'mangled' && lodash.isString(id)) {
      return this.isCurrentUser(this.convertIdStringToNumber(id as string));
    }

    if (Number.isSafeInteger(id as number)) {
      return this.isCurrentUser(id as number);
    }

    return of(false);
  }

  private isCurrentUser(id: null | number) {
    return this._auth.user$.pipe(map((_) => _ && id && _.id === id));
  }

  private convertIdStringToNumber(id: string): null | number {
    const convertedId = Number(
      this._convertIdService.convertIdStringToNumber(id)
    );

    return Number.isSafeInteger(convertedId) ? convertedId : null;
  }
}
