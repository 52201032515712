import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { ChatFacade } from '../../store/facade';
import { IMessage } from '../../model/message.model';
import { animationFrame } from 'rxjs/internal/scheduler/animationFrame';

@Component({
  selector: 'app-chat-messages-box',
  templateUrl: './chat-messages-box.component.html',
  styleUrls: ['./chat-messages-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// TODO: bug with multile images in message
export class ChatMessagesBoxComponent implements OnDestroy {
  @Input() chatId: number;
  @Input() set channelId(value: number) {
    this._channelId$.next(value);

    this._componentDestroy$.next();

    this.init(value);
  }

  @ViewChild('messageContainer') private set __messageContainer(
    element: ElementRef
  ) {
    this._messageContainer$.next(element);
  }

  private _channelId$ = new BehaviorSubject<number>(null);
  private _messageContainer$ = new BehaviorSubject(null);
  private _amountOfLoadingChunks$ = new BehaviorSubject(0);
  private _loadedChunk$ = new Subject();
  private _isChunkLoading$: { [key: string]: BehaviorSubject<boolean> } = {};
  private _initialRenderDone$ = new BehaviorSubject(false);
  private _componentDestroy$ = new Subject();

  constructor(private readonly chatFacade: ChatFacade) {}

  ngOnDestroy() {
    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  get messagesChunks$() {
    return this.chatFacade.isLoadedMessages$.pipe(
      filter((_) => _),
      mergeMap((_) => this.chatFacade.messagesChunks$)
    );
  }

  get isLoading$() {
    return combineLatest([
      this.chatFacade.isLoadingMessages$,
      this._amountOfLoadingChunks$
    ]).pipe(map((_) => _[0] || _[1] > 0));
  }

  chunkTrackBy(_index: number, chunk: { page: number; messages: IMessage[] }) {
    return chunk.page;
  }

  onScrolled() {
    combineLatest([
      this.chatFacade.nextMessagesPage$,
      this._amountOfLoadingChunks$
    ])
      .pipe(
        takeUntil(this._componentDestroy$),
        take(1),
        map((_) => ({
          nextMessagesPage: _[0],
          amountOfLoadingChunks: _[1]
        })),
        filter((_) => _.amountOfLoadingChunks === 0),
        tap((_) =>
          this.chatFacade.loadMessages(
            this._channelId$.value,
            _.nextMessagesPage
          )
        )
      )
      .subscribe((_) => _);
  }

  onLoadChunk(id: number) {
    this._isChunkLoading$[id] =
      this._isChunkLoading$[id] || new BehaviorSubject(true);
    this._isChunkLoading$[id].next(true);

    this._amountOfLoadingChunks$.next(this._amountOfLoadingChunks$.value + 1);
  }

  onLoadedChunk(id: number) {
    this._isChunkLoading$[id].next(false);
    this._amountOfLoadingChunks$.next(this._amountOfLoadingChunks$.value - 1);

    this._loadedChunk$.next(id);
  }

  isChunkLoaded$(id: number) {
    this._isChunkLoading$[id] =
      this._isChunkLoading$[id] || new BehaviorSubject(true);

    return this._isChunkLoading$[id].pipe(
      map((_) => !_),
      filter((_) => _),
      takeUntil(this._componentDestroy$)
    );
  }

  private init(channelId: number) {
    this._amountOfLoadingChunks$.next(0);
    this._isChunkLoading$ = {};
    this._initialRenderDone$.next(false);

    this.chatFacade.cleanMessages();
    this.chatFacade.loadMessages(channelId, 0);

    this.chatFacade
      .onMessagesNewForChannel$(channelId)
      .pipe(takeUntil(this._componentDestroy$))
      .subscribe((_) => _);

    this.scrollToBottonOnChunkRender();
    this.readChannelOnChunkUpdate();
  }

  private scrollToBottonOnChunkRender() {
    combineLatest([this._loadedChunk$, this._messageContainer$])
      .pipe(
        filter((_) => _[0] === 0 && _[1]),
        mergeMap((_) => of(0, animationFrame)),
        tap((_) =>
          this.scrollToBottom(
            this._messageContainer$.value,
            !this._initialRenderDone$.value
          )
        ),
        tap((_) => this._initialRenderDone$.next(true)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  private readChannelOnChunkUpdate() {
    this._loadedChunk$
      .pipe(
        filter((_) => _ === 0),
        mergeMap((_) => of(0, animationFrame)),
        tap((_) =>
          this.chatFacade.readChannel(this.chatId, this._channelId$.value)
        ),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);
  }

  private scrollToBottom(container: ElementRef, force = false): void {
    try {
      const { scrollTop, offsetHeight, scrollHeight } = container.nativeElement;

      if (
        Math.abs(scrollTop + offsetHeight - scrollHeight) < offsetHeight ||
        force
      ) {
        container.nativeElement.scrollTop = scrollHeight;
      }
    } catch (err) {
      // TODO: handle error
      console.log('Error: ', err);
    }
  }
}
