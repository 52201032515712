import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { loadYourSchool, loadYourSchoolSuccess } from './your-school.actions';
import { YourSchoolService } from './your-school.service';

@Injectable()
export class YourSchoolEffects {
  constructor(private actions: Actions, private service: YourSchoolService) {}

  readonly load = createEffect(() =>
    this.actions.pipe(
      ofType(loadYourSchool),
      switchMap((_) =>
        this.service
          .load()
          .pipe(switchMap((school) => [loadYourSchoolSuccess({ school })]))
      )
    )
  );
}
