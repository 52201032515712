import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';
import { NotEmptyURLSearchParams } from 'src/app/shared/types/not-empty-url-search-params.type';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  constructor(private base: BaseService) {}

  getContacts(page: number, searchTerm = '') {
    const params = new NotEmptyURLSearchParams({
      page: page + '',
      term: searchTerm
    });

    return this.base.get(`network?${params}`).pipe(map((res) => res.contacts));
  }

  addContact(id: number) {
    return this.base.get(`network/add/${id}`).pipe(map((_res) => ({ id })));
  }

  removeContact(id: number) {
    return this.base.get(`network/del/${id}`).pipe(map((_res) => ({ id })));
  }
}
