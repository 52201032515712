import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { HomeProfileService } from './profile.service';

import {
  loadProfileInfo,
  loadProfileInfoSuccess,
  loadProfileInfoById
} from './profile.actions';

@Injectable()
export class ProfileInfoEffects {
  constructor(private actions$: Actions, private service: HomeProfileService) {}

  effectForLoadProfileInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProfileInfo),
      switchMap(() =>
        this.service
          .getUserInfo()
          .pipe(
            switchMap((data) => [loadProfileInfoSuccess({ profileInfo: data })])
          )
      )
    )
  );

  loadProfileInfoById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProfileInfoById),
      switchMap((args) =>
        this.service
          .getUserInfoById(args.profileId)
          .pipe(map((data) => loadProfileInfoSuccess({ profileInfo: data })))
      )
    )
  );
}
