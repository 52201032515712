import { createAction } from '@ngrx/store';
import { IPost } from '../../../models/post';
import { getAction } from '../../../utils/getAction';

export const savedPostsKey = 'savedPosts';

export const loadSavedPosts = getAction<{ page: number }, IPost[]>(
  savedPostsKey,
  `Load Saved Posts`
);
export const addSavedPost = getAction<IPost, IPost>(
  savedPostsKey,
  `Add Saved Posts`
);
export const deleteSavedPost = getAction<IPost, IPost>(
  savedPostsKey,
  `Delete Saved Posts`
);

export const clearSavedPosts = createAction('Clear Saved Posts');
