import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import {
  AppFragmentFocusModule,
  AppHideForModule,
  AppLoaderModule,
  AppSigninDialogModule,
  AppTooltipModule
} from 'src/app/shared/modules';

import { CompetitionsSharedModule } from './shared/shared.module';

import Components from './components';
import Containers from './containers';
import { CompetitionsRoutingModule } from './competitions-routing.module';

@NgModule({
  declarations: [...Components, ...Containers],
  imports: [
    CommonModule,
    AppHideForModule,
    AppSigninDialogModule,
    AppLoaderModule,
    AppTooltipModule,
    AppFragmentFocusModule.forRoot({
      top: 80
    }),
    SharedModule,
    CompetitionsRoutingModule,
    CompetitionsSharedModule
  ],
  exports: [CompetitionsSharedModule]
})
export class CompetitionsModule {}
