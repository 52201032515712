import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-block',
  templateUrl: './sidebar-block.component.html',
  styleUrls: ['./sidebar-block.component.scss']
})
export class SidebarBlockComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  constructor() {}

  ngOnInit(): void {}
}
