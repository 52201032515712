import { IIcon } from '../../model/icon';

export const university: IIcon = {
  id: `UNIVERSITY`,
  svg: `
  <g clip-path="url(#clip21)">
  <path fill="#3F5C6C"
    d="M1.04 5.86h-.6a.34.34 0 01-.33-.43L.97 2a.34.34 0 01.33-.27h17.4c.16 0 .3.11.33.27l.86 3.44a.34.34 0 01-.33.43H1.04z" />
  <path fill="#F3D55B"
    d="M19.1 5.86V17.6H1.15V5.86h2.68a.7.7 0 00.5-.2l1.3-1.32 2.97-2.96L9.88.1a.35.35 0 01.5 0l1.27 1.28 2.96 2.96 1.32 1.32c.13.13.3.2.49.2h2.67z" />
  <path fill="#E64C3C" d="M10 12.41c-.76 0-1.38.62-1.38 1.38v3.72h2.76V13.8c0-.76-.62-1.38-1.38-1.38z" />
  <path fill="#CBB292" d="M8.62 13.8h2.76v3.71H8.62V13.8z" />
  <path fill="#8697CB"
    d="M3.8 7.59H2.75a.34.34 0 00-.35.34v2.76c0 .2.16.35.35.35h1.03c.2 0 .35-.16.35-.35V7.93a.34.34 0 00-.35-.34z" />
  <path fill="#90BAE1"
    d="M6.9 7.59H5.86a.34.34 0 00-.34.34v2.76c0 .2.15.35.34.35H6.9c.19 0 .34-.16.34-.35V7.93a.34.34 0 00-.34-.34zM3.8 12.41H2.75a.34.34 0 00-.35.35v2.76c0 .19.16.34.35.34h1.03c.2 0 .35-.15.35-.34v-2.76c0-.2-.16-.35-.35-.35z" />
  <path fill="#8697CB"
    d="M6.9 12.41H5.86a.34.34 0 00-.34.35v2.76c0 .19.15.34.34.34H6.9c.19 0 .34-.15.34-.34v-2.76c0-.2-.15-.35-.34-.35z" />
  <path fill="#90BAE1"
    d="M14.14 7.59H13.1c-.19 0-.34.15-.34.34v2.76c0 .2.15.35.34.35h1.04c.19 0 .34-.16.34-.35V7.93a.34.34 0 00-.34-.34z" />
  <path fill="#8697CB"
    d="M17.24 7.59h-1.03a.34.34 0 00-.35.34v2.76c0 .2.16.35.35.35h1.03c.2 0 .35-.16.35-.35V7.93a.34.34 0 00-.35-.34zM14.14 12.41H13.1c-.19 0-.34.16-.34.35v2.76c0 .19.15.34.34.34h1.04c.19 0 .34-.15.34-.34v-2.76c0-.2-.15-.35-.34-.35z" />
  <path fill="#90BAE1"
    d="M17.24 12.41h-1.03a.34.34 0 00-.35.35v2.76c0 .19.16.34.35.34h1.03c.2 0 .35-.15.35-.34v-2.76c0-.2-.16-.35-.35-.35z" />
  <path fill="#8697CB"
    d="M11.03 8.28H8.97a.34.34 0 00-.35.34v2.07c0 .2.16.34.35.34h2.06c.2 0 .35-.15.35-.34V8.62a.34.34 0 00-.35-.34z" />
  <path fill="#D1D4D1" d="M.7 17.24H19.3c.2 0 .35.16.35.35v1.2H.35v-1.2c0-.2.15-.35.34-.35z" />
  <path fill="#fff" d="M12.07 4.83a2.07 2.07 0 11-4.14 0 2.07 2.07 0 014.14 0z" />
  <path fill="#2C2F38" d="M10.69 5.17H10a.34.34 0 01-.34-.34v-.7a.34.34 0 01.69 0v.35h.34a.34.34 0 010 .7z" />
  <path fill="#464F5D"
    d="M2.41 9.66h1.73v1.03c0 .19-.16.34-.35.34H2.76a.34.34 0 01-.35-.34V9.66zM5.52 9.66h1.72v1.03c0 .19-.15.34-.34.34H5.86a.34.34 0 01-.34-.34V9.66zM2.41 14.48h1.73v1.04c0 .19-.16.34-.35.34H2.76a.34.34 0 01-.35-.34v-1.04zM5.52 14.48h1.72v1.04c0 .19-.15.34-.34.34H5.86a.34.34 0 01-.34-.34v-1.04zM12.76 9.66h1.72v1.03c0 .19-.15.34-.34.34H13.1a.34.34 0 01-.34-.34V9.66zM15.86 9.66h1.73v1.03c0 .19-.16.34-.35.34h-1.03a.35.35 0 01-.35-.34V9.66zM12.76 14.48h1.72v1.04c0 .19-.15.34-.34.34H13.1a.34.34 0 01-.34-.34v-1.04zM15.86 14.48h1.73v1.04c0 .19-.16.34-.35.34h-1.03a.34.34 0 01-.35-.34v-1.04z" />
  <path fill="#2C2F38" d="M10.69 15.52a.34.34 0 11-.69 0 .34.34 0 01.69 0z" />
  <path fill="#A5A5A4"
    d="M.35 18.62h19.3c.2 0 .35.16.35.35v.69c0 .19-.15.34-.34.34H.35a.34.34 0 01-.35-.34v-.7c0-.18.16-.34.35-.34z" />
  <path fill="#464F5D" d="M8.62 9.66h2.76v1.03c0 .19-.15.34-.35.34H8.97a.34.34 0 01-.35-.34V9.66z" />
  <path fill="#A5A5A4"
    d="M3.1 0h.35c.38 0 .69.31.69.69V3.1c0 .2-.16.35-.35.35H2.76a.34.34 0 01-.35-.35V.7c0-.38.31-.69.7-.69z" />
</g>
<defs>
  <clipPath id="clip21">
    <path fill="#fff" d="M0 0h20v20H0z" />
  </clipPath>
</defs>
  `
};
