import { Injectable } from '@angular/core';
import {
  convertIdNumberToString,
  convertIdStringToNumber
} from '../../shared/utils/idConverter';

@Injectable({
  providedIn: 'root'
})
export class ConvertIdService {
  constructor() {}

  convertIdStringToNumber(id: string): string {
    return convertIdStringToNumber(id);
  }

  convertIdNumberToString(id: number | string) {
    return convertIdNumberToString(id);
  }
}
