import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import _ from 'lodash';

import * as JobActions from './job.actions';
import { Job } from './job.model';

export const jobFeatureKey = 'job';

export interface State {
  job: Job;
}

export const initialState: State = {
  job: null
};

export const reducer = createReducer(
  initialState,
  on(JobActions.loadJob, (state, action): State => {
    return {
      job: null
    };
  }),
  on(JobActions.loadJobSuccess, (state, action): State => {
    return {
      ...state,
      job: action.job
    };
  })
);

export const selectState = createFeatureSelector<State>(jobFeatureKey);

export const selectJob = () =>
  createSelector(selectState, (state: State) => {
    return state.job;
  });
