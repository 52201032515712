import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, merge, of, forkJoin, from } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  mergeMap,
  share,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { APIService } from 'src/app/shared/services/api.service';

// TODO: move to the google service
declare var google;

@Injectable()
export class AutocompleteService {
  constructor(private readonly _apiService: APIService) {}

  organizations(
    searchValue: string
  ): Observable<Array<AutocompleteService.IOrganization>> {
    return forkJoin([
      this.getApplicationOrganizations(searchValue),
      this.getGoogleMapsOrganizations(searchValue)
    ]).pipe(map((_: any) => _.flat()));
  }

  countries(
    searchValue: string
  ): Observable<Array<AutocompleteService.ICountry>> {
    if (!searchValue) {
      return of([]);
    }

    return this._apiService
      .get('get/field/country?term=' + searchValue, 'v1')
      .pipe(map((_: any) => _.map((_) => ({ id: _.id, title: _.value }))));
  }

  cities(searchValue: string): Observable<Array<AutocompleteService.ICity>> {
    if (!searchValue) {
      return of([]);
    }

    return this._apiService
      .get('get/field/city?term=' + searchValue, 'v1')
      .pipe(map((_: any) => _.map((_) => ({ id: _.id, title: _.value }))));
  }

  interests(searchValue: string): Observable<Array<AutocompleteService.ICity>> {
    if (!searchValue) {
      return of([]);
    }

    return this._apiService
      .get('get/field/interest?term=' + searchValue, 'v1')
      .pipe(map((_: any) => _.map((_) => ({ id: _.id, title: _.value }))));
  }

  private getApplicationOrganizations(
    value: string
  ): Observable<AutocompleteService.IOrganization[]> {
    if (!value) {
      return of([]);
    }

    return this._apiService.get('autocomplete/org?term=' + value, 'v1').pipe(
      map((responseData: any) =>
        responseData.map((item) => ({
          title: item.value,
          address: item.address
        }))
      )
    );
  }

  private getGoogleMapsOrganizations(
    value: string
  ): Observable<AutocompleteService.IOrganization[]> {
    if (!value) {
      return of([]);
    }

    // TODO: where do we get this google maps object ? this should be a service
    const serviceAutocomplete = new google.maps.places.AutocompleteService();

    return from(
      serviceAutocomplete.getPlacePredictions({
        input: value,
        types: ['establishment']
      })
    ).pipe(
      map((_: any) => _.predictions),
      switchMap((_: Array<{ place_id: string }>) =>
        forkJoin(_.map((_) => this.getGoogleMapsPlaceDetails(_.place_id)))
      ),
      map((_) =>
        _.filter((_) => _).map((_) => ({ title: _.name, address: _.address }))
      )
    );
  }

  private getGoogleMapsPlaceDetails(
    placeId: string
  ): Observable<{ name: string; address: string }> {
    // TODO: do we need this fancy new call inside function call ?
    const serviceAutocomplete = new google.maps.places.PlacesService(
      new google.maps.Map(document.createElement('div'))
    );

    return new Observable((observer) => {
      serviceAutocomplete.getDetails({ placeId }, (item) => {
        observer.next(
          item ? { address: item.formatted_address, name: item.name } : null
        );
        observer.complete();
      });
    });
  }
}

export namespace AutocompleteService {
  export interface IOrganization {
    title: string;
    address: string;
  }

  export interface ICountry {
    id: number;
    title: string;
  }

  export interface ICity {
    id: number;
    title: string;
  }
}
