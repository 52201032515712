import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { ICompetition } from '../../model';

@Component({
  selector: 'app-competitions-cmp',
  templateUrl: './competitions.component.html',
  styleUrls: ['./competitions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompetitionsComponent {
  @Input('canCreateCompetition')
  private readonly _canCreateCompetition: boolean;

  @Input('currentCompetitions')
  private readonly _currentCompetitions: ICompetition[];
  @Input('hasMoreCurrentCompetitionsToLoad')
  private readonly _hasMoreCurrentCompetitionsToLoad: boolean;
  @Input('isLoadingCurrentCompetitions')
  private readonly _isLoadingCurrentCompetitions: boolean;

  @Input('upcomingCompetitions')
  private readonly _upcomingCompetitions: ICompetition[];
  @Input('hasMoreUpcomingCompetitionsToLoad')
  private readonly _hasMoreUpcomingCompetitionsToLoad: boolean;
  @Input('isLoadingUpcomingCompetitions')
  private readonly _isLoadingUpcomingCompetitions: boolean;

  @Input('previousCompetitions')
  private readonly _previousCompetitions: ICompetition[];
  @Input('hasMorePreviousCompetitionsToLoad')
  private readonly _hasMorePreviousCompetitionsToLoad: boolean;
  @Input('isLoadingPreviousCompetitions')
  private readonly _isLoadingPreviousCompetitions: boolean;

  @Input('myCompetitions') private readonly _myCompetitions: ICompetition[];
  @Input('hasMoreMyCompetitionsToLoad')
  private readonly _hasMoreMyCompetitionsToLoad: boolean;
  @Input('isLoadingMyCompetitions')
  private readonly _isLoadingMyCompetitions: boolean;

  @Output('create') private readonly _create = new EventEmitter<void>();
  @Output('loadMoreCurrentCompetitions')
  private readonly _loadMoreCurrentCompetitions = new EventEmitter<void>();
  @Output('loadMoreUpcomingCompetitions')
  private readonly _loadMoreUpcomingCompetitions = new EventEmitter<void>();
  @Output('loadMoreMyCompetitions') private readonly _loadMoreMyCompetitions =
    new EventEmitter<void>();
  @Output('loadMorePreviousCompetitions')
  private readonly _loadMorePreviousCompetitions = new EventEmitter<void>();

  get canCreateCompetition() {
    return this._canCreateCompetition;
  }

  get currentCompetitions() {
    return this._currentCompetitions;
  }

  get hasMoreCurrentCompetitionsToLoad() {
    return this._hasMoreCurrentCompetitionsToLoad;
  }

  get isLoadingCurrentCompetitions() {
    return this._isLoadingCurrentCompetitions;
  }

  get upcomingCompetitions() {
    return this._upcomingCompetitions;
  }

  get hasMoreUpcomingCompetitionsToLoad() {
    return this._hasMoreUpcomingCompetitionsToLoad;
  }

  get isLoadingUpcomingCompetitions() {
    return this._isLoadingUpcomingCompetitions;
  }

  get previousCompetitions() {
    return this._previousCompetitions;
  }

  get hasMorePreviousCompetitionsToLoad() {
    return this._hasMorePreviousCompetitionsToLoad;
  }

  get isLoadingPreviousCompetitions() {
    return this._isLoadingPreviousCompetitions;
  }

  get myCompetitions() {
    return this._myCompetitions;
  }

  get hasMoreMyCompetitionsToLoad() {
    return this._hasMoreMyCompetitionsToLoad;
  }

  get isLoadingMyCompetitions() {
    return this._isLoadingMyCompetitions;
  }

  onCreateCompetition() {
    this._create.emit();
  }

  onLoadMoreCurrentCompetitions() {
    this._loadMoreCurrentCompetitions.emit();
  }

  onLoadMoreUpcomingCompetitions() {
    this._loadMoreUpcomingCompetitions.emit();
  }

  onLoadMoreMyCompetitions() {
    this._loadMoreMyCompetitions.emit();
  }

  onLoadMorePreviousCompetitions() {
    this._loadMorePreviousCompetitions.emit();
  }

  trackBy(_index: number, item: ICompetition) {
    return item.id;
  }
}
