<ng-template #cropDialog>
  <div class="d-flex">
    <button type="button" class="close-button ml-auto" [mat-dialog-close]="false"></button>
  </div>
  <div class="container mt-5">
    <app-image-cropper [ctx]="ctx" (fileOutput)="saveFile($event)" [autoClick]="true">
      <div class="background">
        <div class="circle">
          <div [icon]="'PLUS:15'"></div>
        </div>
      </div>
    </app-image-cropper>
    <div class="d-flex justify-content-center align-items-center mt-5">
      <div class="col-auto">
        <button type="button" class="close" (click)="delete()">Delete</button>
      </div>
      <div class="col-auto">
        <button type="button" class="app-action-btn delete" [mat-dialog-close]="file">Save</button>
      </div>
    </div>
  </div>
</ng-template>
