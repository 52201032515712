import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  Input
} from '@angular/core';
import { IPost } from '../../../../models/post';
import { IDoc } from '../../../../models/doc';
import { RoutingService } from '../../../../../core/services/routing.service';
import { IPostCtx } from '../post.component';
import { getUserIdFromLink } from '../../../../utils/getUserIdFromLink';

@Component({
  selector: 'app-post-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostContentComponent implements OnInit, OnChanges {
  @Input() readonly post: IPost;
  @Input() readonly ctx: IPostCtx;

  public imageIndex: number = null;

  get previews() {
    return (
      this.post.data
        .filter((_) => _.status)
        // TODO: refactor, same logic in several places
        // move to the store level (default url)
        .map((_) => ({ url: _.url, ..._.data }))
    );
  }

  constructor(readonly routing: RoutingService) {}
  // ---------------------------------------------
  //                   Lifecycle
  // ---------------------------------------------
  ngOnInit(): void {}

  ngOnChanges() {}
  // ---------------------------------------------
  //
  // ---------------------------------------------
  public toggleImageFullscreen(index: number) {
    this.imageIndex = index;
  }

  public openDoc(doc: IDoc) {
    this.routing.openDoc(doc);
  }

  public clickDescription(event: Event) {
    const profile = getUserIdFromLink(event, this.post.description);

    if (profile) {
      this.routing.goToProfileWithName(profile);
    }
  }
}
