import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import lodash from 'lodash';

import { BaseService } from 'src/app/core/services/base.service';
import { IClub } from '../../../clubs/model';

@Injectable()
export class ClubsService {
  constructor(private base: BaseService) {}

  getClubsForCompetition(): Observable<IClub[]> {
    // TODO its loaded only first page of clubs/group; use groups actions/service
    return forkJoin([
      this.getSubscribedClubs(),
      this.getSubscribedPrivate()
    ]).pipe(
      map((_) => {
        return lodash.flatten(_);
      })
    );
  }

  private getSubscribedClubs() {
    return this.base
      .get(`group/subscribed-clubs?page=1`)
      .pipe(map((_) => _.groups));
  }

  private getSubscribedPrivate() {
    return this.base
      .get(`group/subscribed-private?page=1`)
      .pipe(map((_) => _.groups));
  }
}
