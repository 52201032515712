import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(private base: BaseService) {}

  getNetwork(_search: string, page: number) {
    return this.base.allGet(`network?page=${page}`).pipe(
      map(({ response }) => ({
        networks: response.contacts,
        count: response.count
      }))
    );
  }

  disconnectRequest(id: number) {
    return this.base.get(`network/del/${id}`).pipe(map((_) => ({ id })));
  }
}
