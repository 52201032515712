import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppLoaderModule } from 'src/app/shared/modules/loader/loader.module';
import { AppAvatarModule } from 'src/app/shared/modules/avatar/avatar.module';
import { AppLinksModule } from 'src/app/shared/modules/links/links.module';
import { AppInitialsModule } from 'src/app/shared/modules/initials/initials.module';

import { ProfileExamplesComponent } from './components/profile-examples/profile-examples.component';

@NgModule({
  declarations: [ProfileExamplesComponent],
  imports: [
    CommonModule,
    SlickCarouselModule,
    AppLoaderModule,
    AppLinksModule,
    AppAvatarModule,
    AppInitialsModule
  ],
  exports: [ProfileExamplesComponent]
})
export class AppProfileExamplesModule {}
