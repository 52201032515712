<div class="ceil-article-item group-event-item">
  <a [group]="group">
    <div class="icon">
      <svg viewBox="0 0 30 30">
        <use href="#group-icon" />
      </svg>
    </div>
  </a>
  <div class="ceil-content">
    <div class="ceil-text">
      <a [group]="group">
        <div class="ceil-title">{{ group.name | textTruncate: 30 }} </div>
      </a>
      <div class="ceil-description">
        {{ group.location?.city?.name }} <span *ngIf="group.location?.city?.name">,</span>
        {{ group.location?.country?.name }}
      </div>
    </div>
    <div class="ceil-text"> {{ group.members }} members </div>
  </div>
</div>
