import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, flatMap, map, take, tap } from 'rxjs/internal/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { TokenService } from 'src/app/shared/services/token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    private tokenService: TokenService,
    private appRouting: RoutingService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.tokenService.token$.pipe(
      take(1),
      map((_) => (_ ? request.clone({ setParams: { token: _ } }) : request)),
      flatMap((_) => next.handle(_)),
      tap(this.onEvent),
      catchError(this.onError)
    );
  }

  private onEvent = (event: any) => {
    if (!(event instanceof HttpResponse)) {
      return event;
    }

    if (this.isInvalidTokenResponse(event.body.code)) {
      this.auth.logout();

      return event;
    }

    if (this.isValidResponse(event.body.code)) {
      return event;
    }

    if (this.isPageDoesNotExist(event.body.code)) {
      return this.appRouting.goTo404({ skipLocationChange: true });
    }

    return this.appRouting.goToUnexpectedErrorPage({
      skipLocationChange: true
    });
  };

  private onError = async (error: any) => {
    if (!(error instanceof HttpErrorResponse)) {
      return;
    }

    if (this.isIncompleteRegistration(error.error.code)) {
      return new HttpResponse({
        body: error.error
      });
    }

    if (this.isInvalidTokenResponse(error.error.code)) {
      this.auth.logout();

      return error;
    }

    if (this.isPageDoesNotExist(error.error.code)) {
      return this.appRouting.goTo404({ skipLocationChange: true });
    }

    return this.appRouting.goToUnexpectedErrorPage({
      skipLocationChange: true
    });
  };

  private isInvalidTokenResponse(code) {
    return code === 401;
  }

  private isPageDoesNotExist(code) {
    return code === 404;
  }

  private isIncompleteRegistration(code) {
    return code === 406;
  }

  private isValidResponse(code) {
    // 403 is also considered to be a valid (private account)
    // can be handled on the component layer
    //
    // 0 code is a valid code for v2 api
    return code === 0 || code === 200 || code === 403;
  }
}
