import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppBaseURLModule } from '../baseurl/baseurl.module';
import { AppSafeUserInputModule } from '../safe-user-input/safe-user-input.module';
import Directives from './directives';
import Services from './services';

@NgModule({
  declarations: [...Directives],
  imports: [CommonModule, AppBaseURLModule, AppSafeUserInputModule],
  exports: [...Directives],
  providers: [...Services]
})
export class AppLinksModule {}
