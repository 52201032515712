import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkPrefix'
})
export class LinkPrefixPipe implements PipeTransform {
  public transform(link: string, mode: 'cut' | 'add'): string {
    const isHttp = link.startsWith(`http`);
    let _link = link;
    if (_link[_link.length - 1] === `/`) _link = _link.slice(0, -1);

    if (isHttp && mode === 'cut') return _link.split(`//`)[1];
    if (!isHttp && mode === 'add') return `http://${_link}`;
    return _link;
  }
}
