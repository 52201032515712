import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as ClubMediaPhotos from '../reducers/club-media-photos.reducer';
import { State, clubFeatureKey } from '../reducers';

const clubModuleState = createFeatureSelector<State>(clubFeatureKey);
const selectClubMediaPhotosState = createSelector(
  clubModuleState,
  (state: State) => state.clubMediaPhotos
);

export const isLoadingClubMediaPhotos = createSelector(
  selectClubMediaPhotosState,
  ClubMediaPhotos.isLoadingClubMediaPhotos
);
export const getClubMediaPhotos = createSelector(
  selectClubMediaPhotosState,
  ClubMediaPhotos.getClubMediaPhotos
);
export const getClubMediaPhotosAmountOfPages = createSelector(
  selectClubMediaPhotosState,
  ClubMediaPhotos.getClubMediaPhotosAmountOfPages
);
