import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject
} from '@angular/core';

import { CONFIG, IConfig } from '../../left-nav.config';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeftNavComponent {
  constructor(
    @Inject(CONFIG) private readonly _config: IConfig,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  get collegesURL() {
    return this._config.collegesURL;
  }
}
