import { Injectable } from '@angular/core';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import { APIService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ImagesService } from 'src/app/shared/stores/images/images.service';
import { DocsService } from 'src/app/shared/stores/docs/docs.service';

import { NotEmptyURLSearchParams } from '../../../shared/types/not-empty-url-search-params.type';
import {
  IClub,
  IClubMediaFile,
  IClubMediaPhoto,
  IClubMember,
  IClubMemberType
} from '../model';

@Injectable()
export class ClubService {
  constructor(
    private readonly _authService: AuthService,
    private readonly _apiService: APIService,
    private readonly _docsService: DocsService,
    private readonly _imagesService: ImagesService
  ) {}

  loadClub(id: number) {
    const entry = this._authService.isFullyAuthorized
      ? `group/view/${id}`
      : `guest/group/${id}`;

    return this._apiService
      .get<{ group: IClub }>(entry, 'v1')
      .pipe(map((_) => ({ club: _.group })));
  }

  // TODO: refactor this logic and add type
  updateClub(id: number, club: any) {
    return this._imagesService.add([club.image]).pipe(
      mergeMap((_) => {
        const obj = {
          ...club,
          // TODO: refactor this part
          org: club.organization.name
            ? {
                name: club.organization.name,
                address: { name: club.organization.address }
              }
            : null,
          body: club.school.name
            ? {
                name: club.school.name,
                address: { name: club.school.address }
              }
            : null
        };

        if (_[0]?.id || club.icon_id) {
          obj.foto = [_[0]?.id || club.icon_id];
        }

        delete obj.organization;
        delete obj.school;

        return this._apiService.post<{ group: IClub }>(
          `group/edit/${id}`,
          obj,
          'v1'
        );
      }),
      map((_) => _.group)
    );
  }

  removeClub(id: number): Observable<void> {
    return this._apiService
      .get(`group/del/${id}`, 'v1')
      .pipe(map((_) => void 0));
  }

  loadMembers(data: { clubId: number; term?: string; page: number }) {
    const params = new NotEmptyURLSearchParams({
      text: data.term,
      page: data.page + ''
    });

    return this._apiService
      .get<{ members: IClubMember[]; count: number }>(
        `clubs/${data.clubId}/network?${params}`
      )
      .pipe(map((_) => ({ members: _.members, membersTotalCount: _.count })));
  }

  loadMediaPhotos(id: number, page: number) {
    const params = new NotEmptyURLSearchParams({
      page: page + ''
    });

    const entry = this._authService.isFullyAuthorized
      ? `group/fotos/${id}?${params}`
      : `guest/club-fotos/${id}?${params}`;

    return this._apiService
      .get<{ fotos: IClubMediaPhoto[]; count: number }>(entry, 'v1')
      .pipe(map((_) => ({ photos: _.fotos, photosTotalCount: _.count })));
  }

  loadMediaFiles(id: number, page: number) {
    const params = new NotEmptyURLSearchParams({
      page: page + ''
    });

    const entry = this._authService.isFullyAuthorized
      ? `group/files/${id}?${params}`
      : `guest/club-files/${id}?${params}`;

    return this._apiService
      .get<{ files: IClubMediaFile[]; count: number }>(entry, 'v1')
      .pipe(map((_) => ({ files: _.files, filesTotalCount: _.count })));
  }

  uploadMediaPhoto(id: number, file: File) {
    return this._imagesService.add([file]).pipe(
      switchMap((_) =>
        this._apiService
          .post<{ fotos: IClubMediaPhoto[] }>(
            `group/add-foto/${id}`,
            {
              foto: _.map((photo) => photo.id)
            },
            'v1'
          )
          .pipe(map((_) => ({ photo: _.fotos[0] })))
      )
    );
  }

  uploadMediaFile(id: number, file: File) {
    return this._docsService.add([file]).pipe(
      switchMap((_) =>
        this._apiService
          .post<{ files: IClubMediaFile[] }>(
            `group/add-file/${id}`,
            {
              file: _.map((photo) => photo.id)
            },
            'v1'
          )
          .pipe(map((_) => ({ file: _.files[0] })))
      )
    );
  }

  loadRequests(id: number) {
    return this._apiService
      .get<{ users: IClubMember[]; count: number }>(
        `group/requests/${id}`,
        'v1'
      )
      .pipe(map((_) => ({ requests: _.users, requestsTotalCount: _.count })));
  }

  updateMemberType(data: {
    clubId: number;
    memberId: number;
    type: IClubMemberType;
  }) {
    const memberAccessId = data.type === 'moderator' ? 1 : 0;

    const params = new NotEmptyURLSearchParams({
      user_id: data.memberId ? data.memberId + '' : null,
      access: memberAccessId + ''
    });

    return this._apiService
      .get(`group/set-moderator/${data.clubId}?${params}`, 'v1')
      .pipe(map((_) => data));
  }

  connectMember(data: { memberId: number }) {
    return this._apiService
      .get<{ users: IClubMember[]; count: number }>(
        `network/add/${data.memberId}`,
        'v1'
      )
      .pipe(map((_) => data));
  }

  disconnectMember(data: { memberId: number }) {
    return this._apiService
      .get<{ users: IClubMember[]; count: number }>(
        `network/del/${data.memberId}`,
        'v1'
      )
      .pipe(map((_) => data));
  }

  removeMember(data: { clubId: number; memberId: number }) {
    const params = new NotEmptyURLSearchParams({
      user_id: data.memberId ? data.memberId + '' : null
    });

    return this._apiService
      .get<{ users: IClubMember[]; count: number }>(
        `group/unaccess/${data.clubId}?${params}`,
        'v1'
      )
      .pipe(map((_) => data));
  }

  inviteMember(data: { clubId: number; email: string }) {
    return this._apiService
      .post<void>(`group/invite/${data.clubId}`, { email: data.email }, 'v1')
      .pipe(map((_) => void 0));
  }

  join(data: { clubId: number; message?: string }) {
    const params = new NotEmptyURLSearchParams({
      text: data.message ? data.message + '' : null
    });

    return this._apiService
      .get<void>(`group/subscribe/${data.clubId}?${params}`, 'v1')
      .pipe(map((_) => void 0));
  }

  leave(data: { clubId: number }) {
    return combineLatest([
      this._apiService
        .get<void>(`group/unrequest/${data.clubId}`, 'v1')
        .pipe(map((_) => void 0)),
      this._apiService
        .get<void>(`group/unsubscribe/${data.clubId}`, 'v1')
        .pipe(map((_) => void 0))
    ]);
  }

  message(data: { clubId: number; message?: string }) {
    const params = new NotEmptyURLSearchParams({
      text: data.message ? data.message + '' : null
    });

    return this._apiService
      .get<void>(`group/subscribe/${data.clubId}?${params}`, 'v1')
      .pipe(map((_) => void 0));
  }

  acceptRequest(data: { clubId: number; memberId: number }) {
    const params = new NotEmptyURLSearchParams({
      user_id: data.memberId ? data.memberId + '' : null
    });

    return this._apiService
      .get<void>(`group/access/${data.clubId}?${params}`, 'v1')
      .pipe(map((_) => data));
  }

  declineRequest(data: { clubId: number; memberId: number }) {
    const params = new NotEmptyURLSearchParams({
      user_id: data.memberId ? data.memberId + '' : null
    });

    return this._apiService
      .get<void>(`group/unaccess/${data.clubId}?${params}`, 'v1')
      .pipe(map((_) => data));
  }
}
