import { Injectable } from '@angular/core';
import { of, zip, forkJoin } from 'rxjs';
import { switchMap, map, tap, mergeAll, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  loadNetwork,
  loadNetworkSuccess,
  disconnectRequest,
  disconnectRequestSuccess
} from './networks.actions';
import { NetworkService } from './networks.service';

@Injectable()
export class NetworksEffects {
  constructor(
    private actions$: Actions,
    private networksService: NetworkService
  ) {}

  effectForLoadNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNetwork),
      switchMap((params) => this.loadNetworks(params))
    )
  );

  effectForDisconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disconnectRequest),
      mergeMap((params) =>
        forkJoin({
          params: of(params),
          disconnect: this.networksService.disconnectRequest(params.id)
        })
      ),
      mergeMap(({ params, disconnect: { id } }) => {
        disconnectRequestSuccess({ id });

        return this.loadNetworks({ search: '', page: params.page });
      })
    )
  );

  private loadNetworks = (params: { search: string; page: number }) => {
    return this.networksService
      .getNetwork(params.search, params.page)
      .pipe(
        switchMap(({ count, networks }) => [
          loadNetworkSuccess({ count, networks: this.normalize(networks) })
        ])
      );
  };

  private normalize(requests) {
    return requests.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
