import { Injectable } from '@angular/core';
import { switchMap, filter, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import * as moment from 'moment';

import {
  loadDialogs,
  loadMoreDialogs,
  loadDialogsSuccess,
  readDialog,
  readDialogSuccess,
  sendMessage,
  sendMessageSuccess,
  countNewDialogs,
  countNewDialogsSuccess,
  newMessage,
  newMessageSuccess,
  updateDialogs,
  updateDialogsSuccess,
  loadMessages
} from './dialogs.actions';
import { DialogService } from './dialogs.service';
import { AuthService } from '../auth.service';
import { Dialog } from './dialog.model';
import { SocketService } from '../socket.service';

@Injectable()
export class DialogsEffects {
  constructor(
    private actions$: Actions,
    private service: DialogService,
    private authService: AuthService,
    private socketService: SocketService
  ) {}

  effectForCountNewDialogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countNewDialogs),
      switchMap((params) =>
        of(params).pipe(
          switchMap(({ count }) => [countNewDialogsSuccess({ count })])
        )
      )
    )
  );

  effectForNewMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newMessage),
      switchMap((params) =>
        of(params).pipe(
          switchMap(({ dialog, message }) => {
            console.log(
              'new message here: ',
              dialog,
              message,
              this.authService.userID()
            );
            return [
              newMessageSuccess({
                dialog: this.postProcessDialog(dialog),
                message: this.postProcessMessage(message)
              })
            ];
          })
        )
      )
    )
  );

  effectForLoadDialogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDialogs),
      switchMap((params) =>
        forkJoin({
          dialogs: this.service.getDialogs(1)
        }).pipe(
          switchMap((data) => [
            loadDialogsSuccess({
              dialogs: data.dialogs.dialogs.map((dialog) =>
                this.postProcessDialog(dialog)
              )
            })
          ])
        )
      )
    )
  );

  effectForLoadMoreDialogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreDialogs),
      switchMap((params) =>
        forkJoin({
          dialogs: this.service.getDialogs(params.page)
        }).pipe(
          switchMap((data) => [
            loadDialogsSuccess({
              dialogs: data.dialogs.dialogs.map((dialog) =>
                this.postProcessDialog(dialog)
              )
            })
          ])
        )
      )
    )
  );

  effectForLoadMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMessages),
        tap((params) =>
          this.socketService.getDialog(params.dialog_id, params.page)
        )
      ),
    { dispatch: false }
  );

  effectForUpdateDialogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDialogs),
      switchMap((params) =>
        forkJoin({
          dialogs: this.service.getDialogs(params.page)
        }).pipe(
          switchMap((data) => [
            updateDialogsSuccess({
              dialogs: data.dialogs.dialogs.map((dialog) =>
                this.postProcessDialog(dialog)
              )
            })
          ])
        )
      )
    )
  );

  effectForReadDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(readDialog),
      switchMap(({ id }) => {
        return [readDialogSuccess({ id })];
      })
    )
  );

  private postProcessDialog(dialog): Dialog {
    const is_my = Number.isInteger(dialog.sender)
      ? !!dialog.sender
      : dialog.sender === 'user';

    const firstname = dialog.companion?.name || '';
    const lastname =
      (dialog.companion?.student || dialog.companion?.teacher)?.lastname || '';

    // can return 'icon.url' from socket API
    // and 'icon' from REST API
    const icon = dialog.companion?.icon?.url || dialog.companion?.icon;

    return {
      id: dialog.id,
      name: lastname ? `${firstname} ${lastname}` : firstname,
      icon,
      user_to: dialog.companion?.id,
      user_type: dialog.companion?.type,
      message: {
        text: dialog.message,
        is_my,
        is_new: !!dialog.is_new,
        date: moment(dialog.updated_at.date).toDate()
      }
    };
  }

  private postProcessMessage(message) {
    return {
      ...message,
      created_at: moment(message.created_at)
    };
  }
}
