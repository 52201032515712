import { createAction, props } from '@ngrx/store';

import { Contact } from './contact.model';

export const loadContacts = createAction(
  '[Contacts/API] Load Contacts',
  props<{ page: number; searchTerm?: string }>()
);

export const loadContactsSuccess = createAction(
  '[Contacts/API] Load Contacts Success',
  props<{ contacts: Contact[] }>()
);

export const addContact = createAction(
  '[Contacts/API] Add Contact',
  props<{ id: number }>()
);

export const addContactSuccess = createAction(
  '[Contacts/API] Add Contact Success',
  props<{ id: number }>()
);

export const removeContact = createAction(
  '[Contacts/API] Remove Contact',
  props<{ id: number }>()
);

export const removeContactSuccess = createAction(
  '[Contacts/API] Remove Contact Success',
  props<{ id: number }>()
);

export const clearState = createAction(
  '[Contacts/API] Clear State',
  props<{}>()
);
