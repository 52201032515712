import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { CONFIG, IConfig } from '../../app-store-icon.config';

@Component({
  selector: 'app-google-store-icon',
  templateUrl: './google-store-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleStoreIconComponent {
  constructor(@Inject(CONFIG) private readonly _config: IConfig) {}

  get googlePlayURL() {
    return this._config.googlePlayURL;
  }
}
