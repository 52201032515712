import { createAction, props } from '@ngrx/store';

import { Event } from './event.model';

export const loadEvents = createAction(
  '[Events/API] Load',
  props<{ id: number; page: number }>()
);

export const loadEventsSuccess = createAction(
  '[Events/API] Load Success',
  props<{ count: number; events: Event[] }>()
);

export const searchEvents = createAction(
  '[Events/API] Search',
  props<{ searchParams: {} }>()
);

export const searchEventsSuccess = createAction(
  '[Events/API] Search Success',
  props<{ count: number; events: { [id: string]: Event } }>()
);
