import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer =
    new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    // Encode parentheses
    url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
    // Use the default serializer.
    return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    // convert
    //    'medical-immersion-summer-academy-%2528misa%2529' to
    //    'medical-immersion-summer-academy-(misa)'
    // otherwise, will replace %25 to %2525 every page refresh
    return this._defaultUrlSerializer
      .serialize(tree)
      .replace(/%25/g, '%')
      .replace(/%25/g, '%')
      .replace(/%28/g, '(')
      .replace(/%29/g, ')');
  }
}
