import { Pipe, PipeTransform } from '@angular/core';

import * as mimeDB from 'mime-db';

interface IValue {
  name: string;
  mime: string;
}

@Pipe({
  name: 'mimeToExtension'
})
export class MimeToExtension implements PipeTransform {
  transform(value: IValue): string {
    const filename = value.name;
    const mimeType = value.mime;

    const fileExt = this.getFileExt(filename);

    if (mimeDB[mimeType]?.extensions?.includes(fileExt)) {
      return filename;
    }

    return mimeDB[mimeType]
      ? `${filename}.${mimeDB[mimeType].extensions[0]}`
      : filename;
  }

  private getFileExt(filename: string) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }
}
