<div
  class="post-block theme-block position-relative"
  appIsViewable
  [viewableOn]="isViewable"
  [viewableItem]="post"
  (viewed)="onViewed($event)"
>
  <ng-container *ngIf="!editMode else edit_post">
    <div *ngIf="!isAutoPost; else autoPost" id="viewMode">
      <app-post-content [post]="post" [ctx]="ctx"></app-post-content>
    </div>

    <div class="post-actions d-flex">
      <div *ngIf="isLikeSupported" class="action d-flex align-items-center">
        <div class="count">{{post.likes.count || 0 }}</div>
        <div class="icon" (click)="like()">
          <svg viewBox="0 0 20 20">
            <use [attr.href]="post.is_liked ? '#like' : '#like-wb'" />
          </svg>
        </div>
      </div>
      <div class="action null-action d-flex align-items-center" *ngIf="!(ctx.modifyers?.isForbidComments | async)">
        <div class="count">{{ (comments?.count | async) || 0 }}</div>
        <div class="icon">
          <svg viewBox="0 0 20 20">
            <use [attr.href]="(comments?.count | async)? '#comment' : '#comment-wb'" />
          </svg>
        </div>
      </div>
      <div *ngIf="isShareSupported" class="action null-action d-flex align-items-center">
        <div class="count">{{post.shared.count || 0}}</div>
        <button class="icon" [disabled]="post.is_autor" (click)="share()">
          <svg viewBox="0 0 20 20">
            <use [attr.href]="post.shared.count? '#share' : '#share-wb'" />
          </svg>
        </button>
      </div>

      <ng-container *ngIf="isSaveSupported">
        <!-- TODO: consider to remove appHideForGuest from this logic, use isSaveSupported-->
        <button *appHideForGuest class="save" [icon]="post.is_saved ? 'SAVED' : 'SAVE'" (click)="save()"> </button>
      </ng-container>

      <div *appHideForGuest class="action d-flex align-items-center ml-auto mr-0">
        <div class="icon">
          <svg viewBox="0 0 20 20">
            <use href="#options" />
          </svg>
        </div>

        <div class="options-menu">
          <a *ngIf="ctx.pin && post.is_autor && post.type === 'user'" class="menu-item d-flex" (click)="pin()">
            <div [icon]="'PIN'"></div>
            <span *ngIf="post.pinned">Unpin</span>
            <span *ngIf="!post.pinned">Pin</span>
          </a>

          <a [post]="post" class="menu-item d-flex">
            <div class="icon">
              <svg viewBox="0 0 15 15">
                <use href="#reply" />
              </svg>
            </div>
            View post
          </a>
          <div class="menu-item d-flex" (click)="onCopyLink(post); false">
            <div class="icon">
              <svg viewBox="0 0 15 15">
                <use href="#sub-link" />
              </svg>
            </div>
            Copy post link
          </div>
          <a
            *ngIf="isUpdateSupported && post.is_editor && post.is_editable"
            (click)="toggleEditMode()"
            class="menu-item d-flex"
          >
            <div class="icon">
              <svg viewBox="0 0 15 15">
                <use href="#sub-edit-post" />
              </svg>
            </div>
            Edit post
          </a>
          <a *ngIf="isComplainSupported" class="menu-item d-flex" (click)="complaine()">
            <div class="icon">
              <svg viewBox="0 0 15 15">
                <use href="#sub-complain" />
              </svg>
            </div>
            Complain
          </a>
          <a
            *ngIf="isDeleteSupported && post.is_editor && !ctx.modifyers?.forbidDelete"
            class="menu-item d-flex"
            (click)="delete()"
          >
            <div class="icon">
              <svg viewBox="0 0 15 15">
                <use href="#sub-delete" />
              </svg>
            </div>
            Delete
          </a>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!(ctx.modifyers?.isForbidComments | async)">
      <app-message *appHideForGuest class="message post-comment-form" [ctx]="writeCommentCtx"> </app-message>
    </ng-container>

    <app-comments
      *ngIf="comments?.count | async"
      [comments]="comments.values | async"
      [ctx]="commentsCtx"
      [status]="comments.status | async"
    >
    </app-comments>
  </ng-container>

  <ng-template #edit_post>
    <app-message [ctx]="editPostCtx" class="edit-message"></app-message>
  </ng-template>

  <ng-template #autoPost>
    <app-post-user-header
      *ngIf="ctx.modifyers?.isGroup || post.type === 'user'"
      [post]="post"
      [ctx]="ctx"
      class="post-top d-flex"
    >
    </app-post-user-header>
    <div class="post-content">
      <div class="auto-post d-flex flex-column align-items-center justify-content-center">
        <img *ngIf="post.fotos.length" src="{{post.fotos[0]?.url | baseUrl}}" class="auto-post-image img-fluid" />
        <div class="auto-post-message" [innerHTML]="post.description | nl2br"></div>
      </div>
    </div>
  </ng-template>
</div>
