<div class="section" *ngIf="current">
  <div class="section-title">Current competitions</div>
  <app-competition-card [competition]="current"></app-competition-card>
  <div class="theme-block mt-2 d-flex justify-content-center link-wrapper">
    <a [href]="'/competitions#current' | appBaseUrl" target="_blank" class="link">All competitions</a>
  </div>
</div>

<div class="section" *ngIf="upcoming">
  <div class="section-title">Upcoming competitions</div>
  <app-competition-card [competition]="upcoming"></app-competition-card>
  <div class="theme-block mt-2 d-flex justify-content-center link-wrapper">
    <a [href]="'/competitions#upcoming' | appBaseUrl" target="_blank" class="link">All competitions</a>
  </div>
</div>

<div class="section" *ngIf="previous">
  <div class="section-title">Previous competitions</div>
  <app-competition-card [competition]="previous"></app-competition-card>
  <div class="theme-block mt-2 d-flex justify-content-center link-wrapper">
    <a [href]="'/competitions#previous' | appBaseUrl" target="_blank" class="link">All competitions</a>
  </div>
</div>
