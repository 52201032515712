<ng-content></ng-content>

<div class="d-flex justify-content-center justify-content-md-end mt-md-auto paginator" *ngIf="isValid">
  <div class="page-pagination">
    <button class="page-pagination-item left-button" (click)="onPrevPage(); false">
      <span class="arrow">
        <svg viewBox="0 0 26 26">
          <use href="#arrow" />
        </svg>
      </span>
    </button>
    <input
      type="text"
      class="page-pagination-item"
      [value]="currentPage"
      (change)="onChangePageNumber($event.target.value)"
      (keyup.enter)="onSetPage(); false"
    />
    <div class="page-pagination-item page-pagination-number">of {{ amountOfPages }} </div>
    <button class="page-pagination-item right-button" (click)="onNextPage(); false">
      <span class="arrow">
        <svg viewBox="0 0 24 24">
          <use href="#arrow" />
        </svg>
      </span>
    </button>
  </div>
</div>
