import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// TODO: do we really need this class ?

@Pipe({
  name: 'safeText'
})
export class SafeTextPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(value: any, ...args: unknown[]): any {
    return value.filter((_) => this.isSafe(_.text));
  }

  private isSafe(value: string) {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    const parsedValue = doc.documentElement.textContent;

    const sanitizedValue = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      parsedValue
    );

    const d = document.createElement('div');
    d.innerHTML = sanitizedValue;

    return d.innerText === parsedValue;
  }
}
