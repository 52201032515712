<ng-container *ngIf="participants$ | async as participants; else loading">
  <ng-container *ngIf="participants.length; else noParticipants">
    <app-paginator
      class="d-flex flex-wrap flex-column flex-md-row justify-content-md-between ceils-content-section"
      [currentPage]="currentPage$ | async"
      [amountOfPages]="participantsTotalAmountOfPages$ | async"
    >
      <app-competition-view-participant
        class="ceil-article-item business-block-item"
        [participant]="participant"
        [isAdminView]="isAdminView"
        [showAccept]="false"
        (decline)="onRemove($event)"
        *ngFor="let participant of participants"
      >
      </app-competition-view-participant>
    </app-paginator>
  </ng-container>

  <ng-template #noParticipants>
    <app-paginator [currentPage]="currentPage$ | async" [amountOfPages]="participantsTotalAmountOfPages$ | async">
      <div class="no-participants">No participants</div>
    </app-paginator>
  </ng-template>
</ng-container>

<ng-template #loading>
  <app-loader></app-loader>
</ng-template>
