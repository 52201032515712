import { createAction, props } from '@ngrx/store';
import { IImage } from '../../models/image';

export const loadImages = createAction('[FILE] Load Images');

export const loadImagesSuccess = createAction(
  '[FILE] Load Images Success',
  props<{ images: IImage[] }>()
);

export const addImages = createAction(
  '[FILE] Add Images',
  props<{ images: File[] }>()
);

export const addImagesSuccess = createAction(
  '[FILE] Add Images Success',
  props<{ images: IImage[] }>()
);
