import { IIcon } from '../../model/icon';

export const sun: IIcon = {
  id: `SUN`,
  svg: `
  <path
  d="M18.0527 12.3395L18.6936 14.7345C18.7436 14.9211 18.6328 15.1129 18.4461 15.1629L16.0513 15.8037C15.9305 15.8361 15.8361 15.9304 15.8038 16.0513L15.1629 18.4461C15.1129 18.6327 14.9212 18.7435 14.7345 18.6936L12.3395 18.0526C12.2188 18.0203 12.09 18.0548 12.0016 18.1432L10.2473 19.8975C10.1818 19.9631 10.0928 20 10 20C9.90723 20 9.81824 19.9631 9.75262 19.8975L7.99832 18.1432C7.90996 18.0549 7.78121 18.0204 7.66051 18.0527L5.26543 18.6936C5.07879 18.7436 4.88699 18.6328 4.83707 18.4461L4.19617 16.0513C4.16383 15.9305 4.06949 15.8361 3.94867 15.8038L1.55387 15.1629C1.36723 15.1129 1.25645 14.9212 1.30637 14.7345L1.94734 12.3395C1.97965 12.2188 1.94512 12.09 1.85676 12.0016L0.102461 10.2473C0.036875 10.1818 0 10.0928 0 10C0 9.90723 0.036875 9.81824 0.102461 9.75262L1.85676 7.99832C1.94512 7.90996 1.97961 7.78121 1.94734 7.66051L1.30637 5.26543C1.25641 5.07879 1.36723 4.88699 1.55387 4.83707L3.94871 4.19617C4.06953 4.16383 4.16387 4.06949 4.19621 3.94867L4.83711 1.55383C4.88707 1.36719 5.07883 1.25641 5.26547 1.30633L7.66055 1.9473C7.78125 1.97961 7.91 1.94508 7.99836 1.85672L9.75266 0.102422C9.81824 0.036875 9.90723 0 10 0C10.0928 0 10.1818 0.036875 10.2474 0.102461L12.0017 1.85676C12.09 1.94512 12.2188 1.97961 12.3395 1.94734L14.7345 1.30637C14.9212 1.25641 15.113 1.36723 15.1629 1.55387L15.8038 3.94871C15.8361 4.06953 15.9305 4.16387 16.0513 4.19621L18.4461 4.83711C18.6328 4.88707 18.7436 5.07883 18.6936 5.26547L18.0527 7.66055C18.0204 7.78125 18.0549 7.91 18.1432 7.99836L19.8975 9.75266C19.9631 9.81824 20 9.90723 20 10C20 10.0929 19.9631 10.1818 19.8975 10.2474L18.1432 12.0017C18.0549 12.09 18.0204 12.2188 18.0527 12.3395Z"
  fill="#F29C1F" />
<path
  d="M10 16.5517C13.6184 16.5517 16.5517 13.6184 16.5517 9.99996C16.5517 6.38155 13.6184 3.44824 10 3.44824C6.38161 3.44824 3.4483 6.38155 3.4483 9.99996C3.4483 13.6184 6.38161 16.5517 10 16.5517Z"
  fill="#F0C419" />
<path
  d="M4.83869 10.6898C4.65744 10.6896 4.50725 10.5492 4.49487 10.3684C4.48678 10.2466 4.48276 10.1239 4.48276 10.0001C4.47709 8.26304 5.29576 6.62623 6.68912 5.58889C6.84135 5.47432 7.05764 5.50486 7.17217 5.65709C7.28674 5.80932 7.25619 6.02561 7.10397 6.14014C5.8844 7.04764 5.16768 8.47991 5.1724 10.0001C5.1724 10.108 5.17576 10.2153 5.18319 10.3215C5.1894 10.4128 5.15905 10.5028 5.09885 10.5717C5.03865 10.6406 4.95354 10.6828 4.86225 10.6889C4.85451 10.6894 4.84643 10.6898 4.83869 10.6898Z"
  fill="#F9EAB0" />
  `
};
