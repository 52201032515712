import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { Contact } from 'src/app/core/services/contacts/contact.model';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-chat-new-group-chat-dialog',
  templateUrl: './new-group-chat-dialog.component.html',
  styleUrls: ['./new-group-chat-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewGroupChatDialogComponent {
  @Output() loadMoreContacts = new EventEmitter<void>();
  @Output() filter = new EventEmitter<string>();

  private _selectedParticipants: Set<number> = new Set();

  readonly fields = {
    name: new FormControl(null, Validators.required)
  };

  readonly groupChatForm = new FormGroup(this.fields);

  constructor(
    private dialogRef: MatDialogRef<NewGroupChatDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewGroupChatDialogComponent.Data
  ) {}

  get contacts$() {
    return this.data.contacts$;
  }

  get isLoadedContacts$() {
    return this.data.isLoadingContacts$.pipe(map((_) => !_));
  }

  isSelectedContact(id: number) {
    return this._selectedParticipants.has(id);
  }

  onSelectContact(id: number) {
    this._selectedParticipants.add(id);
  }

  onUnSelectContact(id: number) {
    this._selectedParticipants.delete(id);
  }

  onCreate() {
    const selectedParticipants = [...this._selectedParticipants];

    this.dialogRef.close({
      title: this.groupChatForm.value.name,
      participants: selectedParticipants
    });
  }

  onScrolled() {
    this.loadMoreContacts.emit();
  }

  onFilterChange(event: Event) {
    const { value } = event.target as HTMLInputElement;

    this.filter.emit(value);
  }

  close(): void {
    this.dialogRef.close();
  }
}

export namespace NewGroupChatDialogComponent {
  export interface Data {
    contacts$: Observable<Contact[]>;
    isLoadingContacts$: Observable<boolean>;
  }
}
