<div class="other-block block">
  <div class="title">References</div>
  <div class="items">
    <form *ngIf="!isMy" class="recommendation" [formGroup]="reviewForm" (ngSubmit)="submitReview()">
      <div class="d-flex align-items-center">
        <div class="input-wrapper d-flex">
          <input type="text" formControlName="text" placeholder="Write recommendation" />
        </div>
        <button class="send" type="submit">
          <svg viewBox="0 0 26 25">
            <use href="#send" />
          </svg>
        </button>
      </div>
      <div class="anonymous d-flex align-items-center">
        <label><input type="checkbox" formControlName="privaci" /> <span>Anonymous publication</span></label>
      </div>
    </form>
    <ng-container *ngFor="let review of references">
      <div class="item d-flex" *ngIf="!review.is_anonimous">
        <a [profile]="review.user">
          <app-avatar [src]="review.user.icon" [placeholder]="review.user | initials" class="little right"></app-avatar>
        </a>
        <div class="data">
          <a [profile]="review.user" class="name"> {{review.user.name}} </a>
          <div class="position">{{review.user.type_name}}</div>
          <div class="description" [innerHTML]="review.text | safeUserInput"></div>
        </div>
      </div>
      <div class="item d-flex" *ngIf="review.is_anonimous">
        <div class="avatar rounded-circle">
          <img src="/assets/new_img/avatar%20(4).png" alt="profile-name" class="img-fluid" />
        </div>
        <div class="data">
          <div class="name">Anonymous</div>
          <div class="description" [innerHTML]="review.text | safeUserInput"></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
