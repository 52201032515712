import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import { tap, takeUntil, filter, map, mergeMap } from 'rxjs/operators';

import { PlatformService } from 'src/app/shared/services/platform.service';

import { CONFIG } from '../amplitude.token';
import { IConfig, IEvent } from '../interfaces';

interface IUserProperties {
  [key: string]: any;
}

type IAmptitude = any;

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService implements OnDestroy {
  private static MAX_BUFFER_SIZE = 10;

  private _amplitudeClient = null;
  private _logEvent$ = new Subject<{
    event: IEvent;
    eventProperties: object;
  }>();
  private _initialized$ = new BehaviorSubject<boolean>(false);
  private _destroy$ = new Subject<void>();

  constructor(
    @Optional() @Inject(CONFIG) private readonly _config: null | IConfig,
    private readonly _platformService: PlatformService
  ) {
    this._logEvent$
      .pipe(
        AmplitudeService.buffer(
          this._initialized$,
          AmplitudeService.MAX_BUFFER_SIZE
        ),
        tap((_) => this._amplitudeClient.logEvent(_.event, _.eventProperties)),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._initialized$.complete();

    this._destroy$.next();
    this._destroy$.complete();
  }

  init(user: { [id: string]: number } | IUserProperties): Observable<void> {
    if (!this._config) {
      return of(null);
    }

    if (this._amplitudeClient) {
      this._amplitudeClient.setUserId(user?.id || null);
      this._amplitudeClient.setUserProperties(user ? user : null);

      return of(null);
    }

    return this.loadLibrary().pipe(
      filter((_) => !!_),
      // NOTE: initialize library just the first load,
      // so we can call this 'init' method any amount of times
      filter((_) => !this._amplitudeClient),
      tap((_) => (this._amplitudeClient = _)),
      mergeMap((_) => (_ ? this.initLibrary(_) : of(null))),
      tap((_) => {
        _.setUserId(user?.id || null);
        _.setUserProperties(user ? user : null);
      }),
      tap((_) => this._initialized$.next(true))
    );
  }

  logEvent(event: IEvent, eventProperties: object = {}) {
    this._logEvent$.next({ event, eventProperties });
  }

  private loadLibrary(): Observable<null | IAmptitude> {
    if (this._platformService.isPlatformServer()) {
      return of(null);
    }

    return from(import('amplitude-js')).pipe(map((_) => _.default));
  }

  private initLibrary(amplitudeClient: IAmptitude): Observable<IAmptitude> {
    return new Observable((observer) => {
      amplitudeClient.init(this._config.apiKey, null, null, () => {
        observer.next(amplitudeClient);
        observer.complete();
      });
    });
  }

  private static buffer(
    isFlush$: BehaviorSubject<boolean>,
    bufferSize: number
  ) {
    return <T>(source: Observable<T>): Observable<T> => {
      let buffer = [];

      return new Observable((subscriber) => {
        const add = (value: T) => {
          buffer = [value, ...buffer];
          buffer = buffer.slice(0, bufferSize);
        };

        const flush = () => {
          buffer.forEach((_) => subscriber.next(_));
          buffer = [];
        };

        const isFlush$Sub = isFlush$
          .pipe(
            filter((_) => _),
            tap(flush)
          )
          .subscribe();

        const subscription = source.subscribe({
          next(value) {
            add(value);

            if (isFlush$.value) {
              flush();
            }
          },
          error(error) {
            subscriber.error(error);
          },
          complete() {
            subscriber.complete();
          }
        });

        return () => {
          isFlush$Sub.unsubscribe();
          subscription.unsubscribe();
        };
      });
    };
  }
}
