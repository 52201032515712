import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { map } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';

@Injectable({
  providedIn: 'root'
})
export class HomeProfileService {
  constructor(
    private base: BaseService,
    private auth: AuthService,
    private convertIdService: ConvertIdService
  ) {}

  isAuthorized() {
    return this.auth.isFullyAuthorized;
  }

  userID() {
    return this.auth.userID();
  }

  getUserInfo() {
    return this.base.get('user').pipe(map((response: any) => response.user));
  }

  getUserInfoById(id: string) {
    // TODO: refactor
    // similar method in pages/profile/info/store
    const url = `${
      this.auth.isFullyAuthorized ? 'user' : 'guest'
    }/view/${this.convertIdService.convertIdStringToNumber(id)}`;

    return this.base.get(url).pipe(
      // TODO: add type
      map((_: any) => _.user)
    );
  }
}
