import { Injectable } from '@angular/core';
import { switchMap, tap, concatMap } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  loadGroups,
  loadGroupsSuccess,
  subscribeTo,
  subscribeToSuccess,
  unsubscribeFrom,
  unsubscribeFromSuccess,
  searchGroups,
  searchGroupsSuccess,
  createGroup,
  createGroupSuccess,
  unrequestGroup,
  unrequestGroupSuccess,
  loadOrgs,
  loadOrgsSuccess,
  updateUserToModerator,
  updateUserToModeratorSuccess,
  updateUserToMemberSuccess,
  updateUserToMember
} from './groups.actions';
import { GroupsService } from './groups.service';
import { ClubsService } from 'src/app/core/services/clubs/clubs.service';
import { OrganizationsService } from 'src/app/core/services/organizations/organizations.service';
import { ImagesService } from 'src/app/shared/stores/images/images.service';

@Injectable()
export class GroupsEffects {
  constructor(
    private actions$: Actions,
    private groupsService: GroupsService,
    private readonly clubsService: ClubsService,
    private readonly organizationsService: OrganizationsService,
    private imagesService: ImagesService
  ) {}

  effectForGroupList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroups),
      switchMap((params) =>
        this.groups(params).pipe(
          switchMap(({ count, groups }) => [
            loadGroupsSuccess({ count, groups })
          ])
        )
      )
    )
  );

  effectForBusinessOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrgs),
      switchMap(({ page, categoryID }) =>
        this.organizationsService
          .getOrganizations(page, categoryID)
          .pipe(
            switchMap(({ organizations, count }) => [
              loadOrgsSuccess({ orgs: this.normalize(organizations), count })
            ])
          )
      )
    )
  );

  effectForSearchGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchGroups),
      switchMap((params) =>
        this.groupsService
          .searchGroups(params.searchParams)
          .pipe(
            switchMap(({ count, groups }) => [
              searchGroupsSuccess({ count, groups })
            ])
          )
      )
    )
  );

  effectForCreateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createGroup),
      tap((_) => console.log('create group effect: ', _)),
      concatMap((params) =>
        forkJoin([
          of(params),
          params.group.image
            ? this.imagesService.add([params.group.image])
            : of([])
        ])
      ),
      tap((_) => console.log('after loaded app: ', _)),
      switchMap((params) => {
        const foto = params[1][0]?.id ? [params[1][0]?.id] : [];
        return this.groupsService
          .createGroup({ ...params[0].group, foto })
          .pipe(switchMap(({ group }) => [createGroupSuccess({ group })]));
      })
    )
  );

  effectForGroupSubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeTo),
      switchMap(({ id }) =>
        this.groupsService
          .subscribeGroup(id)
          .pipe(
            switchMap((success) =>
              success ? [subscribeToSuccess({ id })] : []
            )
          )
      )
    )
  );

  effectForGroupUnsubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unsubscribeFrom),
      switchMap(({ id }) =>
        this.groupsService
          .unsubscribeGroup(id)
          .pipe(
            switchMap((success) =>
              success ? [unsubscribeFromSuccess({ id })] : []
            )
          )
      )
    )
  );

  effectForUnRequestGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(unrequestGroup),
      switchMap(({ group_id, user_id }) =>
        this.groupsService
          .unrequestGroup(group_id, user_id)
          .pipe(switchMap(() => [unrequestGroupSuccess({ group_id, user_id })]))
      )
    )
  );

  effectUpdateUserToModerator = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserToModerator),
      switchMap(({ groupId, userId }) =>
        this.groupsService
          .updateUserToModerator(groupId, userId)
          .pipe(
            switchMap(() => [updateUserToModeratorSuccess({ groupId, userId })])
          )
      )
    )
  );

  effectUpdateUserToMember = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserToMember),
      switchMap(({ groupId, userId }) =>
        this.groupsService
          .updateUserToMember(groupId, userId)
          .pipe(
            switchMap(() => [updateUserToMemberSuccess({ groupId, userId })])
          )
      )
    )
  );

  private groups({
    typ,
    page,
    categoryID,
    weeklyStartFrom
  }: {
    typ: string;
    page: number;
    categoryID?: number;
    weeklyStartFrom?: number;
  }) {
    // TODO: Refactor
    // don't match groups type and probably should use enum via the facade
    switch (typ.toLocaleLowerCase()) {
      case 'general':
        return this.groupsService.getAll(page);
      case 'subscribed':
        return this.groupsService.getSubscribedGroups(page);
      case 'mygroups':
        return this.groupsService.getMyGroups(page);
      case 'clubs':
        return this.clubsService.getClubs(page, categoryID);
      case 'private':
        return this.groupsService.getPrivate(page);
      case 'subscribed-clubs':
        return this.groupsService.getSubscribedClubs(page);
      case 'recent-clubs':
        return this.groupsService.getRecentClubs(page, weeklyStartFrom);
      case 'subscribed-private':
        return this.groupsService.getSubscribedPrivate(page);
      default:
        return this.groupsService.getAll(page);
    }
  }

  private normalize(groups) {
    return groups.reduce((acc, cur) => {
      acc[cur.id] = cur;

      return acc;
    }, {});
  }
}
