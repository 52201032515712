import { Action } from '@ngrx/store';

import { ICompetition } from '../../model';

export enum ActionTypes {
  LOAD_CURRENT_COMPETITIONS = '[Competitions] Load Current Competitions',
  LOAD_CURRENT_COMPETITIONS_FAILURE = '[Competitions] Load Current Competitions Failure',
  LOAD_CURRENT_COMPETITIONS_SUCCESS = '[Competitions] Load Current Competitions Success',

  LOAD_UPCOMING_COMPETITIONS = '[Competitions] Load Upcoming Competitions',
  LOAD_UPCOMING_COMPETITIONS_FAILURE = '[Competitions] Load Upcoming Competitions Failure',
  LOAD_UPCOMING_COMPETITIONS_SUCCESS = '[Competitions] Load Upcoming Competitions Success',

  LOAD_MY_COMPETITIONS = '[Competitions] Load My Competitions',
  LOAD_MY_COMPETITIONS_FAILURE = '[Competitions] Load My Competitions Failure',
  LOAD_MY_COMPETITIONS_SUCCESS = '[Competitions] Load My Competitions Success',
  CLEAN_MY_COMPETITIONS = '[Competitions] Clean My Competitions',

  LOAD_PREVIOUS_COMPETITIONS = '[Competitions] Load Previous Competitions',
  LOAD_PREVIOUS_COMPETITIONS_FAILURE = '[Competitions] Load Previous Competitions Failure',
  LOAD_PREVIOUS_COMPETITIONS_SUCCESS = '[Competitions] Load Previous Competitions Success',

  LOAD_COMPETITIONS_SHORT_LIST = '[Competitions] Load Competitions Short List',
  LOAD_COMPETITIONS_SHORT_LIST_FAILURE = '[Competitions] Load Competitions Short List Failure',
  LOAD_COMPETITIONS_SHORT_LIST_SUCCESS = '[Competitions] Load Competitions Short List Success'
}

export class LoadCurrentCompetitionsAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_COMPETITIONS;
  constructor(public payload: { page: number }) {}
}

export class LoadCurrentCompetitionsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_COMPETITIONS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCurrentCompetitionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CURRENT_COMPETITIONS_SUCCESS;
  constructor(
    public payload: {
      competitions: ICompetition[];
      totalAmount: number;
      page: number;
    }
  ) {}
}

export class LoadUpcomingCompetitionsAction implements Action {
  readonly type = ActionTypes.LOAD_UPCOMING_COMPETITIONS;
  constructor(public payload: { page: number }) {}
}

export class LoadUpcomingCompetitionsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_UPCOMING_COMPETITIONS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadUpcomingCompetitionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_UPCOMING_COMPETITIONS_SUCCESS;
  constructor(
    public payload: {
      competitions: ICompetition[];
      totalAmount: number;
      page: number;
    }
  ) {}
}

export class LoadMyCompetitionsAction implements Action {
  readonly type = ActionTypes.LOAD_MY_COMPETITIONS;
  constructor(public payload: { page: number }) {}
}

export class LoadMyCompetitionsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MY_COMPETITIONS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadMyCompetitionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MY_COMPETITIONS_SUCCESS;
  constructor(
    public payload: {
      competitions: ICompetition[];
      totalAmount: number;
      page: number;
    }
  ) {}
}

export class CleanMyCompetitionsAction implements Action {
  readonly type = ActionTypes.CLEAN_MY_COMPETITIONS;
}

export class LoadPreviousCompetitionsAction implements Action {
  readonly type = ActionTypes.LOAD_PREVIOUS_COMPETITIONS;
  constructor(public payload: { page: number }) {}
}

export class LoadPreviousCompetitionsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PREVIOUS_COMPETITIONS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadPreviousCompetitionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PREVIOUS_COMPETITIONS_SUCCESS;
  constructor(
    public payload: {
      competitions: ICompetition[];
      totalAmount: number;
      page: number;
    }
  ) {}
}

export class LoadCompetitionsShortListAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITIONS_SHORT_LIST;
}

export class LoadCompetitionsShortListFailureAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITIONS_SHORT_LIST_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadCompetitionsShortListSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COMPETITIONS_SHORT_LIST_SUCCESS;
  constructor(
    public payload: {
      current: ICompetition;
      upcoming: ICompetition;
      previous: ICompetition;
    }
  ) {}
}

export type Actions =
  | LoadCurrentCompetitionsAction
  | LoadCurrentCompetitionsFailureAction
  | LoadCurrentCompetitionsSuccessAction
  | LoadUpcomingCompetitionsAction
  | LoadUpcomingCompetitionsFailureAction
  | LoadUpcomingCompetitionsSuccessAction
  | LoadMyCompetitionsAction
  | LoadMyCompetitionsFailureAction
  | LoadMyCompetitionsSuccessAction
  | CleanMyCompetitionsAction
  | LoadPreviousCompetitionsAction
  | LoadPreviousCompetitionsFailureAction
  | LoadPreviousCompetitionsSuccessAction
  | LoadCompetitionsShortListAction
  | LoadCompetitionsShortListFailureAction
  | LoadCompetitionsShortListSuccessAction;
