import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

interface ILinkPreview {
  readonly title: string;
  readonly type?: string;
  readonly description?: string;
  readonly site_name?: string;
  readonly image?: string;
  readonly url?: string;
  readonly video?: string;
  readonly youtubeVideoId?: string;
}

@Component({
  selector: 'app-post-link-preview-view',
  templateUrl: './post-link-preview-view.component.html',
  styleUrls: ['./post-link-preview-view.component.scss']
})
export class PostLinkPreviewViewComponent implements AfterViewInit {
  @Input() data: ILinkPreview;

  @ViewChild('linkPreviewWrapper') linkPreviewWrapper: ElementRef;

  get isVideoPreview() {
    return this.data.site_name === 'YouTube' && this.data.video;
  }

  ngAfterViewInit() {
    const linkPreviewTag = this.createPreview(this.data);

    this.linkPreviewWrapper.nativeElement.appendChild(linkPreviewTag);
  }

  private createPreview(preview: ILinkPreview) {
    const isVideoPreview = this.isVideoPreview;

    const linkPreviewTag = document.createElement('div');
    const linkPreviewImageTag = document.createElement(
      isVideoPreview ? 'div' : 'a'
    );
    const linkPreviewTitleTag = document.createElement('a');

    const linkPreviewDescriptionTag = document.createElement('div');

    linkPreviewTag.className = 'link-preview';
    if (preview.image) {
      if (!isVideoPreview) {
        (linkPreviewImageTag as HTMLAnchorElement).target = '_blank';
        (linkPreviewImageTag as HTMLAnchorElement).href = preview.url;
      }

      linkPreviewImageTag.className = isVideoPreview
        ? 'link-preview-image video'
        : 'link-preview-image';

      const imgTag = document.createElement('img');
      imgTag.className = 'img-fluid';
      imgTag.src = preview.image;

      if (isVideoPreview) {
        const playBtn = document.createElement('div');
        playBtn.className = 'play-button';
        linkPreviewImageTag.onclick = (e: MouseEvent) => {
          this.doYoutubePreview(preview, linkPreviewImageTag);
        };
        linkPreviewImageTag.appendChild(playBtn);
      }
      linkPreviewImageTag.appendChild(imgTag);
      linkPreviewTag.appendChild(linkPreviewImageTag);
    }

    linkPreviewTitleTag.target = '_blank';
    linkPreviewTitleTag.href = preview.url;
    linkPreviewTitleTag.className = 'link-preview-title';
    linkPreviewTitleTag.innerText = preview.title;
    linkPreviewTag.appendChild(linkPreviewTitleTag);

    linkPreviewDescriptionTag.className = 'link-preview-description';
    linkPreviewDescriptionTag.innerText = isVideoPreview
      ? preview.site_name
      : preview.description?.slice(0, 100) || '';
    linkPreviewTag.appendChild(linkPreviewDescriptionTag);

    return linkPreviewTag;
  }

  private doYoutubePreview(preview: ILinkPreview, el: HTMLElement) {
    const embed = preview.video;
    const iframe = document.createElement('iframe');

    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
    );
    iframe.setAttribute('src', embed + '?rel=0&showinfo=0&autoplay=1');

    el.innerHTML = '';
    el.appendChild(iframe);
  }
}
