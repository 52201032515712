import { Injectable } from '@angular/core';
import RPChats from 'rp-chat';
import { from } from 'rxjs';
import * as Sentry from '@sentry/browser';

import { environment } from 'src/environments/environment';

import {
  IGroupChat,
  INewGroupChat,
  IMessage,
  INewMessage,
  IChannel,
  IChannelMember,
  INewChannel,
  IReadChannel,
  IEditChannel,
  IEditGroupChat,
  IGroupChatMember
} from '../model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private _rpChat: RPChats = null;

  async init({ token, userId }: { token: string; userId: number }) {
    if (this._rpChat) {
      throw new Error('RPChat already initialized');
    }

    this._rpChat = new RPChats({
      host: environment.groupChatSocketHost,
      port: environment.groupChatSocketPort,
      token: token,
      userId: userId
    });

    try {
      await this._rpChat.init();
    } catch (error) {
      this._rpChat = null;

      Sentry.captureException(error, {
        extra: { token, userId }
      });

      await new Promise((resolve) => setTimeout(resolve, 1000));

      return await this.init({ token, userId });
    }
  }

  async deinit() {
    if (!this._rpChat) {
      return;
    }

    // NOTE: in order to avoid already initialized error during the long deinit process,
    // use temp variable to run it and be ready to accept new init call right away
    const rpChat = this._rpChat;
    this._rpChat = null;

    await rpChat.deinit();
  }

  loadGroupChats() {
    return from(this._rpChat.chatsAsync());
  }

  chatsMembers(chatId: number) {
    return from(this._rpChat.chatsMembersAsync(chatId));
  }

  chatMembersEdit(
    chatId: number,
    membersToAdd: number[],
    membersToRemove: number[]
  ) {
    return this._rpChat.chatsMembersEdit(chatId, membersToAdd, membersToRemove);
  }

  onChatsMembers(cb: (members: IGroupChatMember[], chatId: number) => void) {
    return this._rpChat.onChatsMembers(cb);
  }

  channelDelete(channelId: number) {
    return this._rpChat.channelsDelete({ id: channelId });
  }

  onDeleteChannel(cb: (channel: IChannel) => void) {
    return this._rpChat.onChannelsDelete(cb);
  }

  channelLeave(channelId: number) {
    return this._rpChat.channelsLeave({ id: channelId });
  }

  onLeaveChannel(cb: (channel: IChannel, userId: number) => void) {
    return this._rpChat.onChannelsLeave(cb);
  }

  newGroupChat(chat: INewGroupChat) {
    return from(this._rpChat.chatsNewAsync(chat));
  }

  editGroupChatMembers(
    chatId: number,
    membersToAdd: number[],
    membersToRemove: number[]
  ) {
    return this._rpChat.chatsMembersEdit(chatId, membersToAdd, membersToRemove);
  }

  onGroupChatsNew(cb: (chat: IGroupChat) => void) {
    return this._rpChat.onChatsNew(cb);
  }

  editGroupChat(chat: IEditGroupChat) {
    return this._rpChat.chatsEditAsync(chat);
  }

  onGroupChatsEdit(cb: (chat: IGroupChat) => void) {
    return this._rpChat.onChatsEdit(cb);
  }

  onGroupChatsDelete(cb: (chat: IGroupChat) => void) {
    return this._rpChat.onChatsDelete(cb);
  }

  loadMessages(channelId: number, page: number) {
    return from(this._rpChat.messagesAsync({ channelId, page }));
  }

  loadChannel(channelId: number) {
    return from(this._rpChat.channelsDescribeAsync(channelId));
  }

  editChannelMembers(
    channelId: number,
    membersToAdd: number[],
    membersToRemove: number[]
  ) {
    return this._rpChat.channelsMembersEdit(
      channelId,
      membersToAdd,
      membersToRemove
    );
  }

  channelMembers(channelId: number) {
    return from(this._rpChat.channelsMembers(channelId));
  }

  onChannelsMembers(
    cb: (members: IChannelMember[], channelId: number) => void
  ) {
    return this._rpChat.onChannelsMembers(cb);
  }

  newChannel(newChannel: INewChannel) {
    return from(this._rpChat.channelsNew(newChannel));
  }

  onNewChannels(cb: (channel: IChannel) => void) {
    return this._rpChat.onChannelsNew(cb);
  }

  newMessage(message: INewMessage) {
    return this._rpChat.messagesNew(message);
  }

  onMessagesNew(cb: (message: IMessage) => void) {
    return this._rpChat.onMessagesNew(cb);
  }

  loadUnreadChannels() {
    return from(this._rpChat.channelsUnreadAsync());
  }

  readChannel(chatId, channelId) {
    this._rpChat.channelsRead({ chatId, channelId });
  }

  onReadChannel(cb: (readChannelResponse: IReadChannel) => void) {
    return this._rpChat.onChannelsRead(cb);
  }

  channelEdit(channel: IEditChannel) {
    this._rpChat.channelsEdit(channel);
  }

  onEditChannel(cb: (channel: IChannel) => void) {
    return this._rpChat.onChannelsEdit(cb);
  }
}
