import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  combineLatest,
  forkJoin,
  from,
  merge,
  Observable,
  of,
  Subject,
  timer
} from 'rxjs';
import {
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  skip,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Location } from '@angular/common';

import { PlatformService } from 'src/app/shared/services/platform.service';
import { FBQService } from 'src/app/shared/services/fbq.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SocketService } from 'src/app/core/services/socket.service';
import { AppForgotPasswordDialogComponent } from 'src/app/shared/modules/sign-in-dialog/components';
import { AppSignUpDialogComponent } from 'src/app/shared/modules/sign-up-dialog/components';
import {
  AccountConnectedErrorDialogComponent,
  AccountConnectedSuccessDialogComponent
} from 'src/app/shared/modules/account-connected-dialog/components';

import { InitialService } from './shared/stores/_root/initial';
import { StartYourJourneyDialogComponent } from './shared/components/start-your-journey-dialog/start-your-journey-dialog.component';
import { DownloadAppDialogComponent } from './shared/components/download-app-dialog/download-app-dialog.component';
import { FirstVisitService } from './shared/services/first-visit.service';
import { TUser } from './shared/models/user/user';
import {
  ChatFacade,
  NoticeCounterFacade,
  RegistrationFacade
} from './root-store';
import { ProfileLinkService } from './shared/services/links';
import { GUEST } from './core/constants/guest';
import { AccountService } from './shared/modules/account/services';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  private _isLoaded$: Observable<boolean>;
  private _userTearDown$ = new Subject();
  private _componentDestroy$ = new Subject();

  get isBrowser() {
    return this.platformService.isPlatformBrowser();
  }

  private get isAndroid() {
    return this.firstVisit.getMobileOperatingSystem() === 'Android';
  }

  constructor(
    private router: Router,
    private readonly activeatedRoute: ActivatedRoute,
    private readonly location: Location,
    private authService: AuthService,
    private socketService: SocketService,
    private initial: InitialService,
    private platformService: PlatformService,
    private dialog: MatDialog,
    private firstVisit: FirstVisitService,
    private readonly _actions$: Actions,
    private readonly profileLinkService: ProfileLinkService,
    private readonly fbqService: FBQService,
    private readonly chatFacade: ChatFacade,
    private readonly noticeCounterFacade: NoticeCounterFacade,
    private readonly _registrationFacade: RegistrationFacade,
    private readonly _accountService: AccountService
  ) {
    if (this.platformService.isPlatformBrowser()) {
      this.router.events
        .pipe(takeUntil(this._componentDestroy$))
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.doGtag(event);
          }
        });

      // TODO: show this dialog only once
      // TODO: this dialog should not be shown along with other ones
      // this.router.events.pipe(
      //   filter(_ => _ instanceof NavigationEnd),
      //   take(1),
      //   tap(event => this.openStart(event)),
      //   takeUntil(this._componentDestroy$)
      // ).subscribe(_ => _);

      this.router.events
        .pipe(
          filter((_) => _ instanceof NavigationEnd),
          tap((_) => this.fbqService.trackPageView()),
          takeUntil(this._componentDestroy$)
        )
        .subscribe((_) => _);
    }

    this._userTearDown$
      .pipe(
        tap((_) => this.onUserTeardown()),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);

    this.activeatedRoute.fragment
      .pipe(
        filter((_) => !!_),
        tap((_) => this.handleFragmen(_)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe((_) => _);

    this._isLoaded$ = this.authService.isLoaded$.pipe(
      delay(500),
      takeUntil(this._componentDestroy$)
    );

    this.authService.user$
      .pipe(
        tap((_) => this._accountService.setCurrentAccount(_)),
        takeUntil(this._componentDestroy$)
      )
      .subscribe();
  }

  ngOnInit() {
    this.initial.init();
    this.fbqService.load();

    if (this.platformService.isPlatformBrowser()) {
      this.authService.user$.subscribe((_) => this.socketService.setup(_));

      // TODO: move to the application modules
      // and refactor
      this.authService.token$
        .pipe(
          distinctUntilChanged(),
          // skip initial null value
          // and wait for not nullable to handle user token
          filter((_) => !!_),
          take(1),
          mergeMap((_) => this.authService.token$),
          mergeMap((_) => this.handleUserToken()),
          takeUntil(this._componentDestroy$)
        )
        .subscribe((_) => _);

      this.authService.user$
        .pipe(
          distinctUntilChanged((x, y) => x?.id === y?.id),
          filter((_) => this.isAuthorizedUser(_)),
          tap((_) => this.onUserSetup(_)),
          takeUntil(this._componentDestroy$)
        )
        .subscribe((_) => _);

      this.authService.user$
        .pipe(
          distinctUntilChanged((x, y) => x?.id === y?.id),
          // TODO: remove guest when refactor auth
          filter((_) => !_ || _ === GUEST),
          tap((_) => this._userTearDown$.next()),
          takeUntil(this._componentDestroy$)
        )
        .subscribe((_) => _);
    }
  }

  private handleUserToken() {
    return this.authService.tokenLogin();
  }

  ngOnDestroy() {
    this._userTearDown$.next();
    this._userTearDown$.complete();

    this._componentDestroy$.next();
    this._componentDestroy$.complete();
  }

  get isLoaded$() {
    return this._isLoaded$;
  }

  private isAuthorizedUser(user?: TUser) {
    return user?.status_id === 2;
  }

  // // TODO: show this dialog only once
  // private openStart(event) {
  //   if (
  //     // TODO: refactor check to the list of URLs
  //     // TODO: use fragments to check
  //     event.urlAfterRedirects !== '/' &&
  //     event.urlAfterRedirects !== '/404' &&
  //     event.urlAfterRedirects !== '/#sign_up_modal' &&
  //     event.urlAfterRedirects !== '/#forgot_password_modal' &&
  //     !this.authService.getToken()
  //   ) {
  //     this.dialog.open(StartYourJourneyDialogComponent, {
  //       width: '500px'
  //     });

  //     // TODO: add check for iOS

  //     if (this.isAndroid) {
  //       this.dialog.open(DownloadAppDialogComponent, {
  //         width: '90%',
  //         maxWidth: '450px'
  //       });
  //     }
  //   }
  // }

  private doGtag(event) {
    gtag('config', 'G-67V1BMCL3X', {
      page_path: event.urlAfterRedirects
    });
  }

  private async onUserSetup(user: TUser) {
    const token = this.authService.getToken();
    if (!token) {
      return;
    }

    await this.onInitGroupChat(token, user.id);
    await this.onFetchNoticeCounters();
  }

  private async onUserTeardown() {
    this.onCleanNoticeCounters();

    this._registrationFacade.reset();

    await this.chatFacade.deinit();
  }

  private async onInitGroupChat(token: string, userId: number) {
    await this.chatFacade.init({ token, userId });

    this.chatFacade
      .onReadChannel$()
      .pipe(takeUntil(this._userTearDown$))
      .subscribe((_) => _);

    this.chatFacade
      .onMessagesNew$()
      .pipe(takeUntil(this._userTearDown$))
      .subscribe((_) => _);

    this.chatFacade
      .onDeleteChannel$()
      .pipe(takeUntil(this._userTearDown$))
      .subscribe((_) => _);

    this.chatFacade
      .onLeaveChannel$()
      .pipe(takeUntil(this._userTearDown$))
      .subscribe((_) => _);

    this.chatFacade
      .onChannelsNew$()
      .pipe(takeUntil(this._userTearDown$))
      .subscribe((_) => _);

    this.chatFacade
      .onGroupChatsNew$()
      .pipe(takeUntil(this._userTearDown$))
      .subscribe((_) => _);

    this.chatFacade
      .onGroupChatsDelete$()
      .pipe(takeUntil(this._userTearDown$))
      .subscribe((_) => _);

    this.chatFacade.loadUnreadChannels();
  }

  private onFetchNoticeCounters() {
    this.noticeCounterFacade.loadNoticeCounters();
  }

  private onCleanNoticeCounters() {
    this.noticeCounterFacade.cleanNoticeCounters();
  }

  private handleFragmen(fragment: string) {
    switch (fragment.toLowerCase()) {
      case 'sign_up_modal':
        return this.dialog.open(AppSignUpDialogComponent, {
          autoFocus: false
        });
      case 'forgot_password_modal':
        return this.dialog.open(AppForgotPasswordDialogComponent, {
          autoFocus: false
        });
      case AccountConnectedErrorDialogComponent.FRAGMENT_ID:
        return this.dialog.open(AccountConnectedErrorDialogComponent, {
          autoFocus: false
        });
      case AccountConnectedSuccessDialogComponent.FRAGMENT_ID:
        return this.dialog.open(AccountConnectedSuccessDialogComponent, {
          autoFocus: false
        });
      default:
        return;
    }
  }
}
