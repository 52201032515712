import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { tap, filter, first, delay } from 'rxjs/operators';
import { IImageCropperCtx } from '../../form';

// TODO: remove in a favor to ImageCropperModule

@Component({
  selector: 'app-crop-image-modal',
  templateUrl: './crop-image-modal.component.html',
  styleUrls: ['./crop-image-modal.component.scss']
})
export class CropImageModalComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  private dialogConfig: MatDialogConfig = {
    width: '30vw',
    maxWidth: '1140px',
    minWidth: '500px',
    panelClass: 'custom-dialog'
  };

  public ctx: IImageCropperCtx;
  file: any;
  @ViewChild('cropDialog', { static: true }) dialogBody: TemplateRef<any>;

  openDialog(ctx?: IImageCropperCtx) {
    if (ctx) {
      this.ctx = ctx;
    }
    return this.dialog.open(this.dialogBody, this.dialogConfig);
  }

  saveFile(file) {
    this.file = file;
  }

  uploadImage(dialog?: IImageCropperCtx) {
    return this.openDialog(dialog).afterClosed().pipe(
      // filter(val => val),
      first()
    );
  }

  delete() {
    this.file = null;
    this.ctx.on.cropped(null);
    this.dialog.closeAll();
  }
}
