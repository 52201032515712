<div class="block right-block">
  <div class="title d-flex">{{ text | async }}</div>
  <div class="right-block-data">
    <div class="profile d-flex flex-column align-items-center">
      <div class="right-block-avatar">
        <div class="avatar rounded-circle">
          <a [profile]="school">
            <app-avatar [src]="school.icon" [placeholder]="school | initials" alt="profile-name" class="img-fluid">
            </app-avatar>
          </a>
        </div>
      </div>
      <div class="name">{{ school.name }}</div>
      <div class="position">{{ school.type_name }}</div>

      <button
        *ngIf="!school.is_friend && !school.friend_request"
        class="link"
        (click)="onProfileConnect(school.id); false"
        >Connect +</button
      >
      <button
        *ngIf="!school.is_friend && school.friend_request"
        class="link"
        (click)="onProfileDisconnect(school.id); false"
        >Disconnect</button
      >
      <button *ngIf="school.is_friend" class="link" (click)="onProfileDisconnect(school.id); false">Disconnect</button>
    </div>
    <div class="members-common d-flex"> Members <div class="ml-auto">{{ school.count }}</div> </div>
    <div class="members-list">
      <div *ngFor="let member of school.members" class="member-item d-flex">
        <a [profile]="member">
          <app-avatar [src]="member.icon" [placeholder]="member | initials" class="small right"></app-avatar>
        </a>
        <div class="data">
          <a [profile]="member">
            <div class="name">{{ member.name }}</div>
          </a>
          <div class="position">{{ member.type_name }}</div>
          <button
            class="link ml-auto"
            *ngIf="!member.is_friend && !member.friend_request"
            (click)="onProfileConnect(member.id); false"
            >Connect +</button
          >
          <button
            class="link ml-auto"
            *ngIf="!member.is_friend && member.friend_request"
            (click)="onProfileDisconnect(member.id); false"
            >Disconnect</button
          >
          <button class="link ml-auto" *ngIf="member.is_friend" (click)="onProfileDisconnect(member.id); false"
            >Disconnect</button
          >
        </div>
      </div>
    </div>
  </div>
</div>
