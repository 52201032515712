<div class="dialog-content-overlay">
  <button class="btn-close" (click)="close()"></button>
  <h1 mat-dialog-title *ngIf="!isAlreadyCreated">Create new private group</h1>
  <h1 mat-dialog-title *ngIf="isAlreadyCreated">Edit {{name}}</h1>
  <div mat-dialog-content>
    <div class="form-wrapper py-0 px-3">
      <form [formGroup]="newGroup" (ngSubmit)="onSubmit()">
        <div class="background-wrapper d-flex mb-4" (click)="fInput.click()">
          <div class="holder">
            <img [src]="loadedImage" class="loadedImage" *ngIf="loadedImage" />
            <img [src]="currentImage | baseUrl" class="loadedImage" *ngIf="currentImage" />
          </div>
          <div class="d-flex flex-column align-items-center justify-content-center m-auto">
            <div class="plus-icon">
              <input #fInput type="file" (change)="onImageChange($event)" accept="image/*" />
              <svg viewBox="0 0 15 14">
                <use href="#plus-white"></use>
              </svg>
            </div>
            <div class="placeholder mt-2">
              <span>Add an image</span>
            </div>
          </div>
        </div>

        <mat-form-field class="w-100">
          <mat-label>Private group name</mat-label>
          <input matInput placeholder="Private group name" formControlName="name" required />
          <mat-hint>Write private group name</mat-hint>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Age group</mat-label>
          <mat-select formControlName="age_group_id" required>
            <mat-option [value]="age.id" *ngFor="let age of ages"> {{ age.title }} </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="three">
          <app-autocomplete [ctx]="country"></app-autocomplete>
          <app-autocomplete [ctx]="city"></app-autocomplete>
          <app-input [ctx]="zip"></app-input>
        </div>
        <div class="add-item-field-wrapper d-flex">
          <div class="d-flex w-100">
            <mat-form-field class="w-100">
              <mat-label>Enter keywords </mat-label>
              <input
                matInput
                placeholder="Enter keywords "
                [value]="newKeyword"
                (change)="onChangeKeyword($event.target.value)"
              />
              <mat-hint>i.e. business, entrepreneuship erc</mat-hint>
            </mat-form-field>
            <button
              class="add-item-btn d-flex align-items-center justify-content-center"
              (click)="onAddKeyword(); false"
            >
              <svg viewBox="0 0 15 14">
                <use href="#plus-white" />
              </svg>
            </button>
          </div>
        </div>
        <div class="additional-wrapper d-flex flex-wrap">
          <div class="additional-item d-flex align-items-center" *ngFor="let keyword of keywords">
            <button class="close" (click)="onRemoveKeyword(keyword)">
              <svg viewBox="0 0 8 8">
                <use href="#close" />
              </svg>
            </button>
            {{ keyword }}
          </div>
        </div>
        <mat-form-field class="w-100">
          <mat-label>Description</mat-label>
          <textarea
            formControlName="description"
            matInput
            rows="6"
            placeholder="Description"
            [maxLength]="250"
            required
            #desc
          ></textarea>
          <div class="character-counter">{{desc.textLength}}/{{desc.maxLength}}</div>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div mat-dialog-actions>
    <button class="main-action-btn" (click)="onSubmit(); false" [disabled]="!newGroup.valid">Save</button>
  </div>
</div>
