import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import Components from './components';

const COMPONENTS = [...Components];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [...COMPONENTS],
  providers: []
})
export class PostSharedModule {}
