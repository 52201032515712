import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as Projects from './reducers/projects.reducer';
import { State, projectsFeatureKey } from './reducers';

export const projectsModuleState =
  createFeatureSelector<State>(projectsFeatureKey);

export const selectProjectsState = createSelector(
  projectsModuleState,
  (state: State) => state
);

export const getProjectsList = (count) =>
  createSelector(selectProjectsState, Projects.selectAllProjects(count));

export const getProjectsTotalAmount = createSelector(
  selectProjectsState,
  Projects.getProjectsTotalAmount
);

export const getCompetitionProjectsList = createSelector(
  selectProjectsState,
  Projects.getCompetitionProjects
);

export const getCompetitionProjectsPagesAmount = createSelector(
  selectProjectsState,
  Projects.getCompetitionProjectsPagesAmount
);

export const isLoadingProjectsList = createSelector(
  selectProjectsState,
  Projects.isLoading
);
