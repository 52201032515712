import { Action } from '@ngrx/store';

import {
  IEditGroupChat,
  IGroupChat,
  IGroupChatMember,
  INewGroupChat
} from '../../model';

export enum ActionTypes {
  LOAD_GROUP_CHATS = '[Chat] Load Group Chats',
  LOAD_GROUP_CHATS_FAILURE = '[Chat] Load Group Chats Failure',
  LOAD_GROUP_CHATS_SUCCESS = '[Chat] Load Group Chats Success',

  // Move to chat.action.ts

  NEW_GROUP_CHAT = '[Chat] New Group Chat',
  NEW_GROUP_CHAT_FAILURE = '[Chat] New Group Chat Failure',
  NEW_GROUP_CHAT_SUCCESS = '[Chat] New Group Chat Success',

  EDIT_GROUP_CHAT = '[Chat] Edit Group Chat',
  EDIT_GROUP_CHAT_FAILURE = '[Chat] Edit Group Chat Failure',
  EDIT_GROUP_CHAT_SUCCESS = '[Chat] Edit Group Chat Success',

  DELETE_GROUP_CHAT = '[Chat] Delete Group Chat',
  DELETE_GROUP_CHAT_FAILURE = '[Chat] Delete Group Chat Failure',
  DELETE_GROUP_CHAT_SUCCESS = '[Chat] Delete Group Chat Success',

  LOAD_GROUP_CHAT_MEMBERS = '[Channel] Load Group Chat Members',
  LOAD_GROUP_CHAT_MEMBERS_FAILURE = '[Channel] Load Group Chat Members Failure',
  LOAD_GROUP_CHAT_MEMBERS_SUCCESS = '[Channel] Load Group Chat Members Success',

  EDIT_GROUP_CHAT_MEMBERS = '[Channel] Edit Group Chat Members',

  LOAD_UNREAD_CHANNELS = '[Chat] Load Unread Channels',
  LOAD_UNREAD_CHANNELS_FAILURE = '[Chat] Laod Unread Channels Failure',
  LOAD_UNREAD_CHANNELS_SUCCESS = '[Chat] Load Unread Channels Success'
}

export class LoadGroupChatsAction implements Action {
  readonly type = ActionTypes.LOAD_GROUP_CHATS;
  constructor(public payload: { page: number }) {}
}

export class LoadGroupChatsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_GROUP_CHATS_SUCCESS;
  constructor(public payload: { chats: IGroupChat[] }) {}
}

export class LoadGroupChatsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_GROUP_CHATS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class NewGroupChatAction implements Action {
  readonly type = ActionTypes.NEW_GROUP_CHAT;
  constructor(public payload: { chat: INewGroupChat }) {}
}

export class NewGroupChatSuccessAction implements Action {
  readonly type = ActionTypes.NEW_GROUP_CHAT_SUCCESS;
  constructor(public payload: { chat: IGroupChat }) {}
}

export class NewGroupChatFailureAction implements Action {
  readonly type = ActionTypes.NEW_GROUP_CHAT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class EditGroupChatAction implements Action {
  readonly type = ActionTypes.EDIT_GROUP_CHAT;
  constructor(public payload: { chat: IEditGroupChat }) {}
}

export class EditGroupChatSuccessAction implements Action {
  readonly type = ActionTypes.EDIT_GROUP_CHAT_SUCCESS;
  constructor(public payload: { chat: IGroupChat }) {}
}

export class EditGroupChatFailureAction implements Action {
  readonly type = ActionTypes.EDIT_GROUP_CHAT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteGroupChatAction implements Action {
  readonly type = ActionTypes.DELETE_GROUP_CHAT;
  constructor(public payload: { chatId: number }) {}
}

export class DeleteGroupChatSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_GROUP_CHAT_SUCCESS;
  constructor(public payload: { chat: IGroupChat }) {}
}

export class DeleteGroupChatFailureAction implements Action {
  readonly type = ActionTypes.DELETE_GROUP_CHAT_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadGroupChatMembersAction implements Action {
  readonly type = ActionTypes.LOAD_GROUP_CHAT_MEMBERS;
  constructor(public payload: { chatId: number }) {}
}

export class LoadGroupChatMembersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_GROUP_CHAT_MEMBERS_SUCCESS;
  constructor(
    public payload: { members: IGroupChatMember[]; chatId: number }
  ) {}
}

export class LoadGroupChatMembersFailureAction implements Action {
  readonly type = ActionTypes.LOAD_GROUP_CHAT_MEMBERS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class EditGroupChatMembersAction implements Action {
  readonly type = ActionTypes.EDIT_GROUP_CHAT_MEMBERS;
  constructor(
    public payload: {
      chatId: number;
      membersToAdd: number[];
      membersToRemove: number[];
    }
  ) {}
}

export class LoadUnreadChannelsAction implements Action {
  readonly type = ActionTypes.LOAD_UNREAD_CHANNELS;
  constructor() {}
}

export class LoadUnreadChannelsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_UNREAD_CHANNELS_SUCCESS;
  constructor(public payload: { channels: number[] }) {}
}

export class LoadUnreadChannelsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_UNREAD_CHANNELS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | LoadGroupChatsAction
  | LoadGroupChatsSuccessAction
  | LoadGroupChatsFailureAction
  | NewGroupChatAction
  | NewGroupChatSuccessAction
  | NewGroupChatFailureAction
  | EditGroupChatAction
  | EditGroupChatSuccessAction
  | EditGroupChatFailureAction
  | DeleteGroupChatAction
  | DeleteGroupChatSuccessAction
  | DeleteGroupChatFailureAction
  | LoadGroupChatMembersAction
  | LoadGroupChatMembersSuccessAction
  | LoadGroupChatMembersFailureAction
  | EditGroupChatMembersAction
  | LoadUnreadChannelsAction
  | LoadUnreadChannelsSuccessAction
  | LoadUnreadChannelsFailureAction;
