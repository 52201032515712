import { IIcon } from '../../model/icon';

export const subDelete: IIcon = {
  id: `SUB_DELETE`,
  svg: `
  <path fill="#C2C2C2"
  d="M3 2.5v12c0 .5.5.5.56.5h7.88c.3 0 .56-.22.56-.5v-12c0-.28-.25-.5-.56-.5H3.56c-.3 0-.56.22-.56.5z" />
<path fill="#35495E" d="M5 2V.57c0-.31.28-.57.62-.57h3.75c.35 0 .63.26.63.57V2H5z" />
<path fill="#7F8C8D"
  d="M8.1 5.52c0-.29-.27-.52-.6-.52-.33 0-.6.23-.6.52v7.24c0 .28.27.52.6.52.33 0 .6-.24.6-.52V5.52zM5.19 5.52c0-.29-.27-.52-.6-.52-.32 0-.59.23-.59.52v7.24c0 .28.27.52.6.52.32 0 .59-.24.59-.52V5.52zM11 5.52c0-.29-.27-.52-.6-.52-.32 0-.59.23-.59.52v7.24c0 .28.27.52.6.52.32 0 .59-.24.59-.52V5.52z" />
<path fill="#547580" d="M12 2.5V4H3V2.5c0-.28.25-.5.56-.5h7.88c.3 0 .56.22.56.5z" />

  `
};
