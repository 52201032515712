import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ExtendableError } from 'src/app/core/errors/ExtendableError';

interface ResponseV1<T> {
  code: number;
  response?: T;
  // probably here another type
  message?: string;
}

interface ResponseV2<T> {
  code: number;
  payload?: T;
  message?: string;
}

// TODO: simir service in colleges
// should be reusable across apps

@Injectable({
  providedIn: 'root'
})
export class APIService {
  constructor(private readonly _httpClient: HttpClient) {}

  get<T>(path: string, version: 'v1' | 'v2' = 'v2'): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this._httpClient
      .get<ResponseV1<T> | ResponseV2<T>>(url.href)
      .pipe(
        share(),
        map(version === 'v1' ? this.handleResponseV1 : this.handleResponseV2)
      );
  }

  post<T, D = any>(
    path: string,
    data: D,
    version: 'v1' | 'v2' = 'v2'
  ): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this._httpClient
      .post<ResponseV1<T> | ResponseV2<T>>(url.href, data)
      .pipe(
        share(),
        map(version === 'v1' ? this.handleResponseV1 : this.handleResponseV2)
      );
  }

  handleResponseV1<T>(response: ResponseV1<T>): T {
    if (response.code !== 200) {
      throw new ExtendableError(response.message, response.code);
    }

    return response.response;
  }

  handleResponseV2<T>(response: ResponseV2<T>): T {
    if (response.code !== 0) {
      throw new ExtendableError(response.message, response.code);
    }

    return response.payload;
  }

  private getEndpointForVersion(version: 'v1' | 'v2') {
    switch (version) {
      case 'v1':
        return `${environment.endpoint}/api/`;
      case 'v2':
        return `${environment.endpoint}/api/v2/`;
      default:
        throw new Error('Invalid endpoint version');
    }
  }
}
