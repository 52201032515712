import { IIcon } from '../../model/icon';

export const planet: IIcon = {
  id: `PLANET`,
  svg: `
  <path
  d="M21.2 22.8c1.9-1.5 3.2-3.7 3.6-6.2-2.3 1.3-5.3 1.7-8.6 2.7l-1 .3-3 .8c-1.6.3-3 1.4-4.3 1.5a9.9 9.9 0 007.7 3l5.6-2.1zM8.5 7.5a9.9 9.9 0 00-2.2 12.3c1-.1 2 .7 3.3.4l1.7-.4 3.5-1 .6-.2 5.5-1.8c1.6-.6 3-2.2 4-2.8a10 10 0 00-5.6-8L8.5 7.6z"
  fill="#4482C3" />
<path d="M3.1 14.2a.5.5 0 01-.5-.5 12.5 12.5 0 0122-6.6.5.5 0 11-.8.6 11.4 11.4 0 00-20.2 6c0 .3-.2.5-.5.5z"
  fill="#F3D55B" />
<path d="M19.3 6c-1.5 1-3 1.7-4.7 2.3a8 8 0 01-5.1 0l-1-.8a10 10 0 0110.8-1.4z" fill="#ECF0F1" />
<path
  d="M24.9 14a40 40 0 01-4 1.8c-.4-.7-.5-2-1.5-2.2-.6-.1-1.4 0-2-.4-.3-.4-.5-.9-.6-1.4 0-.5.2-1 .6-1.2.4-.2.8-.1 1.1-.4L20 8.7l1.8-1a10 10 0 013 6.3zM10.5 14.4c-.8.7-2 1.2-2 2.4.2.9.7 1.6 1 2.4l-3.2.6a9.8 9.8 0 01.4-10.3l2.6 1.3c.8.3 1.6.5 2 1.3.1.9-.1 1.7-.8 2.3z"
  fill="#4FBA6F" />
<path
  d="M15 27.5c-2.8 0-5.6-1-7.8-2.7A.5.5 0 018 24c2 1.6 4.5 2.5 7.1 2.4 5.1 0 9.7-3.4 11-8.3a.5.5 0 111 .2 12.5 12.5 0 01-12 9.2z"
  fill="#F3D55B" />
<path d="M21.2 22.8a9.8 9.8 0 01-5.6 2c.2-.4.3-1 .6-1.4.6-1.2 2.4-1.8 3.7-1.5.4.3.9.5 1.3.9z" fill="#ECF0F1" />
<path
  d="M14.8 17.8l.6-.1A80.6 80.6 0 0025 14c1.5-.7 2.3-1.4 2.2-1.8-.2-.3-1.1-.4-2.7-.3-.1-.5-.4-1-.7-1.6 3.4-.3 5.8.2 6.2 1.4.3 1.3-1.7 3.1-5.1 5a49.3 49.3 0 01-9.5 3.6l-.5-2.5zM12.1 21.2c-1.5.3-2.9.6-4.2.7-4.3.6-7.4.2-7.8-1.2-.4-1.2 1.6-3 5-4.9l.3 1.8c-1.8.9-2.8 1.6-2.7 2 .2.5 1.5.5 3.6.2a41.8 41.8 0 005-1l.8 2.4z"
  fill="#FF5364" />
<path d="M15.5 19.4c0 .3 0 .7-.2 1a2.1 2.1 0 11.2-1z" fill="#FB7B76" />
  `
};
