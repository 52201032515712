import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SharedModule } from 'src/app/shared/shared.module';

import Components, { ChannelLastMessage } from './components';
import Dialogs from './dialogs';

const COMPONENTS = [...Components, ...Dialogs];

@NgModule({
  declarations: [...COMPONENTS, ChannelLastMessage],
  imports: [InfiniteScrollModule, CommonModule, SharedModule],
  exports: [...COMPONENTS]
})
export class ChatSharedModule {}
