import { InjectionToken } from '@angular/core';

import {
  AppForgotPasswordCheckEmailDialogComponent,
  AppForgotPasswordDialogComponent,
  AppSignInDialogComponent
} from './components';

export const SIGN_IN_DIALOG = new InjectionToken<AppSignInDialogComponent>(
  'sign-in-dialog'
);
export const FORGOT_PASSWORD_CHECK_EMAIL_DIALOG =
  new InjectionToken<AppForgotPasswordCheckEmailDialogComponent>(
    'forgot-password-check-email-dialog'
  );
export const FORGOT_PASSWORD_DIALOG =
  new InjectionToken<AppForgotPasswordDialogComponent>(
    'forgot-password-dialog'
  );
