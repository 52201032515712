import { PLATFORM_ID, Inject, Injectable } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  isPlatformServer() {
    return isPlatformServer(this.platformId);
  }
}
