import { IIcon } from '../../model/icon';

export const emoji: IIcon = {
  id: `EMOJI`,
  svg: `
  <path
  d="M10.6 17.9l4 1.6a1.5 1.5 0 002.2-1.5l-.4-4.4c0-.4.1-.8.4-1.1L19.6 9a1.5 1.5 0 00-.8-2.5l-4.2-1c-.4-.2-.8-.4-1-.8l-2.3-3.7a1.6 1.6 0 00-2.6 0L6.4 4.8c-.2.4-.6.6-1 .7l-4.2 1a1.5 1.5 0 00-.8 2.6l2.8 3.4c.3.3.4.7.4 1L3.2 18a1.5 1.5 0 002.2 1.5l4-1.6c.4-.2.8-.2 1.2 0z"
  fill="#F3D55B" />
<path
  d="M19.6 9l-2.8 3.5c-.3.3-.4.7-.4 1l.4 4.5a1.5 1.5 0 01-2.2 1.5l-4-1.6-.6-.2V.3c.5 0 1 .3 1.3.8l2.3 3.7c.2.4.6.6 1 .7l4.2 1a1.5 1.5 0 01.8 2.6z"
  fill="#F0C419" />
<path
  d="M12.4 10.1c-.5 0-1-.2-1.3-.5a.3.3 0 01.5-.5 1 1 0 001.6 0 .3.3 0 01.5.5 2 2 0 01-1.3.5zM7.6 10.1a2 2 0 01-1.3-.5.3.3 0 01.5-.5 1 1 0 001.6 0 .3.3 0 01.5.5c-.4.3-.8.5-1.3.5z"
  fill="#35495E" />
<path d="M10 14.5c-3 0-3.4-2.4-3.4-2.4a.3.3 0 01.6 0s.3 1.7 2.8 1.7 2.8-1.8 2.8-1.8a.3.3 0 01.6.1s-.3 2.4-3.4 2.4z"
  fill="#802D40" />`
};
