import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AppShellNoRenderDirective,
  AppShellRenderDirective
} from './directives';

@NgModule({
  declarations: [AppShellNoRenderDirective, AppShellRenderDirective],
  imports: [CommonModule],
  exports: [AppShellNoRenderDirective, AppShellRenderDirective]
})
export class AppShellRenderModule {}
