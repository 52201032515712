import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as ContactsActions from 'src/app/core/services/contacts/contacts.actions';

import * as SuggestedActions from './suggestions.actions';
import { Profile } from './suggestions.model';

export const suggestionsFeatureKey = 'suggestions';

export interface State {
  profiles: { [id: string]: Profile };
}

export const initialState: State = {
  profiles: null
};

export const reducer = createReducer(
  initialState,
  on(SuggestedActions.loadSuggestedProfiles, (state, action): State => {
    return {
      ...state,
      profiles: null
    };
  }),
  on(SuggestedActions.loadSuggestedProfilesSuccess, (state, action): State => {
    return {
      ...state,
      profiles: action.profiles
    };
  }),
  on(ContactsActions.addContactSuccess, (state, action): State => {
    if (!state.profiles || !state.profiles[action.id]) {
      return state;
    }

    return {
      ...state,
      profiles: {
        ...state.profiles,
        [action.id]: {
          ...state.profiles[action.id],
          friend_request: true
        }
      }
    };
  }),
  on(ContactsActions.removeContactSuccess, (state, action): State => {
    if (!state.profiles || !state.profiles[action.id]) {
      return state;
    }

    return {
      ...state,
      profiles: {
        ...state.profiles,
        [action.id]: {
          ...state.profiles[action.id],
          is_friend: false,
          friend_request: false
        }
      }
    };
  })
);

export const selectState = createFeatureSelector<State>(suggestionsFeatureKey);

export const selectSuggestedProfiles = () =>
  createSelector(selectState, (state: State) => {
    if (!state.profiles) {
      return null;
    }

    return Object.values(state.profiles);
  });
