import { Injectable } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ViewedService } from 'src/app/shared/services/viewed-service';
import { postView } from './posts.actions';
import { postViewedSuccess } from 'src/app/shared/ui/post/data/store/actions';

@Injectable({
  providedIn: 'root'
})
export class PostsEffects {
  constructor(
    private actions$: Actions,
    private viewedService: ViewedService
  ) {}

  effectForView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postView),
      mergeMap((params) =>
        this.viewedService.view(params.id, ViewedService.Type.Post).pipe(
          filter((_) => _),
          map((_) => postViewedSuccess({ id: params.id }))
        )
      )
    )
  );
}
