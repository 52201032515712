import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConvertIdService } from 'src/app/core/services/convert-id.service';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferencesComponent implements OnInit, OnChanges {
  @Input() userId: any;
  @Input() references: any;
  @Output() submitReference: EventEmitter<any> = new EventEmitter();

  reviewForm: FormGroup;
  reviews$: Observable<any>;
  isMy: boolean;
  constructor(
    private store: Store<any>,
    private fb: FormBuilder,
    private auth: AuthService,
    private convertIdService: ConvertIdService
  ) {}

  ngOnInit(): void {
    this.reviewForm = this.fb.group({
      text: [, Validators.required],
      obj_id: [],
      privaci: []
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userId && this.userId) {
      this.isMy = this.auth.isMyId(
        this.convertIdService.convertIdStringToNumber(this.userId + '')
      );
    }
  }

  submitReview() {
    this.submitReference.emit(this.reviewForm.getRawValue());
    this.reviewForm.reset();
  }
}
