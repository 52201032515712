import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, competitionsFeatureKey } from '../reducers';
import * as MyCompetitions from '../reducers/my-competitions.reducer';

const competitionsModuleState = createFeatureSelector<State>(
  competitionsFeatureKey
);

const selectMyCompetitionsState = createSelector(
  competitionsModuleState,
  (state: State) => state.myCompetitions
);

export const getMyCompetitions = createSelector(
  selectMyCompetitionsState,
  MyCompetitions.selectCompetitions
);

export const isLoadingMyCompetitions = createSelector(
  selectMyCompetitionsState,
  MyCompetitions.isLoading
);

export const hasMoreMyCompetitionsToLoad = createSelector(
  selectMyCompetitionsState,
  MyCompetitions.hasMoreCompetitionsToLoad
);

export const getLastLoadedPageMyCompetitions = createSelector(
  selectMyCompetitionsState,
  MyCompetitions.lastLoadedPage
);
