import { TEmoji } from '../model/type';

export const people: TEmoji[] = [
  ['👋', 'waving hand', ['👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿']],
  ['🤚', 'raised back of hand', ['🤚🏻', '🤚🏼', '🤚🏽', '🤚🏾', '🤚🏿']],
  ['🖐️', 'hand with fingers splayed', ['🖐🏻', '🖐🏼', '🖐🏽', '🖐🏾', '🖐🏿']],
  ['✋', 'raised hand', ['✋🏻', '✋🏼', '✋🏽', '✋🏾', '✋🏿']],
  ['🖖', 'vulcan salute', ['🖖🏻', '🖖🏼', '🖖🏽', '🖖🏾', '🖖🏿']],
  ['👌', 'OK hand', ['👌🏻', '👌🏼', '👌🏽', '👌🏾', '👌🏿']],
  ['🤏', 'pinching hand', ['🤏🏻', '🤏🏼', '🤏🏽', '🤏🏾', '🤏🏿']],
  ['✌️', 'victory hand', ['✌🏻', '✌🏼', '✌🏽', '✌🏾', '✌🏿']],
  ['🤞', 'crossed fingers', ['🤞🏻', '🤞🏼', '🤞🏽', '🤞🏾', '🤞🏿']],
  ['🤟', 'love-you gesture', ['🤟🏻', '🤟🏼', '🤟🏽', '🤟🏾', '🤟🏿']],
  ['🤘', 'sign of the horns', ['🤘🏻', '🤘🏼', '🤘🏽', '🤘🏾', '🤘🏿']],
  ['🤙', 'call me hand', ['🤙🏻', '🤙🏼', '🤙🏽', '🤙🏾', '🤙🏿']],
  ['👈', 'backhand index pointing left', ['👈🏻', '👈🏼', '👈🏽', '👈🏾', '👈🏿']],
  ['👉', 'backhand index pointing right', ['👉🏻', '👉🏼', '👉🏽', '👉🏾', '👉🏿']],
  ['👆', 'backhand index pointing up', ['👆🏻', '👆🏼', '👆🏽', '👆🏾', '👆🏿']],
  ['🖕', 'middle finger', ['🖕🏻', '🖕🏼', '🖕🏽', '🖕🏾', '🖕🏿']],
  ['👇', 'backhand index pointing down', ['👇🏻', '👇🏼', '👇🏽', '👇🏾', '👇🏿']],
  ['☝️', 'index pointing up', ['☝🏻', '☝🏼', '☝🏽', '☝🏾', '☝🏿']],
  ['👍', 'thumbs up', ['👍🏻', '👍🏼', '👍🏽', '👍🏾', '👍🏿']],
  ['👎', 'thumbs down', ['👎🏻', '👎🏼', '👎🏽', '👎🏾', '👎🏿']],
  ['✊', 'raised fist', ['✊🏻', '✊🏼', '✊🏽', '✊🏾', '✊🏿']],
  ['👊', 'oncoming fist', ['👊🏻', '👊🏼', '👊🏽', '👊🏾', '👊🏿']],
  ['🤛', 'left-facing fist', ['🤛🏻', '🤛🏼', '🤛🏽', '🤛🏾', '🤛🏿']],
  ['🤜', 'right-facing fist', ['🤜🏻', '🤜🏼', '🤜🏽', '🤜🏾', '🤜🏿']],
  ['👏', 'clapping hands', ['👏🏻', '👏🏼', '👏🏽', '👏🏾', '👏🏿']],
  ['🙌', 'raising hands', ['🙌🏻', '🙌🏼', '🙌🏽', '🙌🏾', '🙌🏿']],
  ['👐', 'open hands', ['👐🏻', '👐🏼', '👐🏽', '👐🏾', '👐🏿']],
  ['🤲', 'palms up together', ['🤲🏻', '🤲🏼', '🤲🏽', '🤲🏾', '🤲🏿']],
  ['🤝', 'handshake'],
  ['🙏', 'folded hands', ['🙏🏻', '🙏🏼', '🙏🏽', '🙏🏾', '🙏🏿']],
  ['✍️', 'writing hand', ['✍🏻', '✍🏼', '✍🏽', '✍🏾', '✍🏿']],
  ['💅', 'nail polish', ['💅🏻', '💅🏼', '💅🏽', '💅🏾', '💅🏿']],
  ['🤳', 'selfie', ['🤳🏻', '🤳🏼', '🤳🏽', '🤳🏾', '🤳🏿']],
  ['💪', 'flexed biceps', ['💪🏻', '💪🏼', '💪🏽', '💪🏾', '💪🏿']],
  ['🦾', 'mechanical arm'],
  ['🦿', 'mechanical leg'],
  ['🦵', 'leg', ['🦵🏻', '🦵🏼', '🦵🏽', '🦵🏾', '🦵🏿']],
  ['🦶', 'foot', ['🦶🏻', '🦶🏼', '🦶🏽', '🦶🏾', '🦶🏿']],
  ['👂', 'ear', ['👂🏻', '👂🏼', '👂🏽', '👂🏾', '👂🏿']],
  ['🦻', 'ear with hearing aid', ['🦻🏻', '🦻🏼', '🦻🏽', '🦻🏾', '🦻🏿']],
  ['👃', 'nose', ['👃🏻', '👃🏼', '👃🏽', '👃🏾', '👃🏿']],
  ['🧠', 'brain'],
  ['🦷', 'tooth'],
  ['🦴', 'bone'],
  ['👀', 'eyes'],
  ['👁️', 'eye'],
  ['👅', 'tongue'],
  ['👄', 'mouth'],
  ['👶', 'baby', ['👶🏻', '👶🏼', '👶🏽', '👶🏾', '👶🏿']],
  ['🧒', 'child', ['🧒🏻', '🧒🏼', '🧒🏽', '🧒🏾', '🧒🏿']],
  ['👦', 'boy', ['👦🏻', '👦🏼', '👦🏽', '👦🏾', '👦🏿']],
  ['👧', 'girl', ['👧🏻', '👧🏼', '👧🏽', '👧🏾', '👧🏿']],
  ['🧑', 'person', ['🧑🏻', '🧑🏼', '🧑🏽', '🧑🏾', '🧑🏿']],
  ['👱', 'person with blond hair', ['👱🏻', '👱🏼', '👱🏽', '👱🏾', '👱🏿']],
  ['👨', 'man', ['👨🏻', '👨🏼', '👨🏽', '👨🏾', '👨🏿']],
  ['🧔', 'man with beard', ['🧔🏻', '🧔🏼', '🧔🏽', '🧔🏾', '🧔🏿']],
  ['👨‍🦰', 'man with red hair', ['👨🏻‍🦰', '👨🏼‍🦰', '👨🏽‍🦰', '👨🏾‍🦰', '👨🏿‍🦰']],
  ['👨‍🦱', 'man with curly hair', ['👨🏻‍🦱', '👨🏼‍🦱', '👨🏽‍🦱', '👨🏾‍🦱', '👨🏿‍🦱']],
  ['👨‍🦳', 'man with white hair', ['👨🏻‍🦳', '👨🏼‍🦳', '👨🏽‍🦳', '👨🏾‍🦳', '👨🏿‍🦳']],
  ['👨‍🦲', 'man with no hair', ['👨🏻‍🦲', '👨🏼‍🦲', '👨🏽‍🦲', '👨🏾‍🦲', '👨🏿‍🦲']],
  ['👩', 'woman', ['👩🏻', '👩🏼', '👩🏽', '👩🏾', '👩🏿']],
  ['👩‍🦰', 'woman with red hair', ['👩🏻‍🦰', '👩🏼‍🦰', '👩🏽‍🦰', '👩🏾‍🦰', '👩🏿‍🦰']],
  ['👩‍🦱', 'woman with curly hair', ['👩🏻‍🦱', '👩🏼‍🦱', '👩🏽‍🦱', '👩🏾‍🦱', '👩🏿‍🦱']],
  ['👩‍🦳', 'woman with white hair', ['👩🏻‍🦳', '👩🏼‍🦳', '👩🏽‍🦳', '👩🏾‍🦳', '👩🏿‍🦳']],
  ['👩‍🦲', 'woman with no hair', ['👩🏻‍🦲', '👩🏼‍🦲', '👩🏽‍🦲', '👩🏾‍🦲', '👩🏿‍🦲']],
  ['👱‍♀️', 'woman with blond hair', ['👱🏻‍♀️', '👱🏼‍♀️', '👱🏽‍♀️', '👱🏾‍♀️', '👱🏿‍♀️']],
  ['👱‍♂️', 'man with blond hair', ['👱🏻‍♂️', '👱🏼‍♂️', '👱🏽‍♂️', '👱🏾‍♂️', '👱🏿‍♂️']],
  ['🧓', 'older person', ['🧓🏻', '🧓🏼', '🧓🏽', '🧓🏾', '🧓🏿']],
  ['👴', 'old man', ['👴🏻', '👴🏼', '👴🏽', '👴🏾', '👴🏿']],
  ['👵', 'old woman', ['👵🏻', '👵🏼', '👵🏽', '👵🏾', '👵🏿']],
  ['🙍', 'person frowning', ['🙍🏻', '🙍🏼', '🙍🏽', '🙍🏾', '🙍🏿']],
  ['🙍‍♂️', 'man frowning', ['🙍🏻‍♂️', '🙍🏼‍♂️', '🙍🏽‍♂️', '🙍🏾‍♂️', '🙍🏿‍♂️']],
  ['🙍‍♀️', 'woman frowning', ['🙍🏻‍♀️', '🙍🏼‍♀️', '🙍🏽‍♀️', '🙍🏾‍♀️', '🙍🏿‍♀️']],
  ['🙎', 'person pouting', ['🙎🏻', '🙎🏼', '🙎🏽', '🙎🏾', '🙎🏿']],
  ['🙎‍♂️', 'man pouting', ['🙎🏻‍♂️', '🙎🏼‍♂️', '🙎🏽‍♂️', '🙎🏾‍♂️', '🙎🏿‍♂️']],
  ['🙎‍♀️', 'woman pouting', ['🙎🏻‍♀️', '🙎🏼‍♀️', '🙎🏽‍♀️', '🙎🏾‍♀️', '🙎🏿‍♀️']],
  ['🙅', 'person gesturing NO', ['🙅🏻', '🙅🏼', '🙅🏽', '🙅🏾', '🙅🏿']],
  ['🙅‍♂️', 'man gesturing NO', ['🙅🏻‍♂️', '🙅🏼‍♂️', '🙅🏽‍♂️', '🙅🏾‍♂️', '🙅🏿‍♂️']],
  ['🙅‍♀️', 'woman gesturing NO', ['🙅🏻‍♀️', '🙅🏼‍♀️', '🙅🏽‍♀️', '🙅🏾‍♀️', '🙅🏿‍♀️']],
  ['🙆', 'person gesturing OK', ['🙆🏻', '🙆🏼', '🙆🏽', '🙆🏾', '🙆🏿']],
  ['🙆‍♂️', 'man gesturing OK', ['🙆🏻‍♂️', '🙆🏼‍♂️', '🙆🏽‍♂️', '🙆🏾‍♂️', '🙆🏿‍♂️']],
  ['🙆‍♀️', 'woman gesturing OK', ['🙆🏻‍♀️', '🙆🏼‍♀️', '🙆🏽‍♀️', '🙆🏾‍♀️', '🙆🏿‍♀️']],
  ['💁', 'person tipping hand', ['💁🏻', '💁🏼', '💁🏽', '💁🏾', '💁🏿']],
  ['💁‍♂️', 'man tipping hand', ['💁🏻‍♂️', '💁🏼‍♂️', '💁🏽‍♂️', '💁🏾‍♂️', '💁🏿‍♂️']],
  ['💁‍♀️', 'woman tipping hand', ['💁🏻‍♀️', '💁🏼‍♀️', '💁🏽‍♀️', '💁🏾‍♀️', '💁🏿‍♀️']],
  ['🙋', 'person raising hand', ['🙋🏻', '🙋🏼', '🙋🏽', '🙋🏾', '🙋🏿']],
  ['🙋‍♂️', 'man raising hand', ['🙋🏻‍♂️', '🙋🏼‍♂️', '🙋🏽‍♂️', '🙋🏾‍♂️', '🙋🏿‍♂️']],
  ['🙋‍♀️', 'woman raising hand', ['🙋🏻‍♀️', '🙋🏼‍♀️', '🙋🏽‍♀️', '🙋🏾‍♀️', '🙋🏿‍♀️']],
  ['🧏', 'deaf person', ['🧏🏻', '🧏🏼', '🧏🏽', '🧏🏾', '🧏🏿']],
  ['🧏‍♂️', 'deaf man', ['🧏🏻‍♂️', '🧏🏼‍♂️', '🧏🏽‍♂️', '🧏🏾‍♂️', '🧏🏿‍♂️']],
  ['🧏‍♀️', 'deaf woman', ['🧏🏻‍♀️', '🧏🏼‍♀️', '🧏🏽‍♀️', '🧏🏾‍♀️', '🧏🏿‍♀️']],
  ['🙇', 'person bowing', ['🙇🏻', '🙇🏼', '🙇🏽', '🙇🏾', '🙇🏿']],
  ['🙇‍♂️', 'man bowing', ['🙇🏻‍♂️', '🙇🏼‍♂️', '🙇🏽‍♂️', '🙇🏾‍♂️', '🙇🏿‍♂️']],
  ['🙇‍♀️', 'woman bowing', ['🙇🏻‍♀️', '🙇🏼‍♀️', '🙇🏽‍♀️', '🙇🏾‍♀️', '🙇🏿‍♀️']],
  ['🤦', 'person facepalming', ['🤦🏻', '🤦🏼', '🤦🏽', '🤦🏾', '🤦🏿']],
  ['🤦‍♂️', 'man facepalming', ['🤦🏻‍♂️', '🤦🏼‍♂️', '🤦🏽‍♂️', '🤦🏾‍♂️', '🤦🏿‍♂️']],
  ['🤦‍♀️', 'woman facepalming', ['🤦🏻‍♀️', '🤦🏼‍♀️', '🤦🏽‍♀️', '🤦🏾‍♀️', '🤦🏿‍♀️']],
  ['🤷', 'person shrugging', ['🤷🏻', '🤷🏼', '🤷🏽', '🤷🏾', '🤷🏿']],
  ['🤷‍♂️', 'man shrugging', ['🤷🏻‍♂️', '🤷🏼‍♂️', '🤷🏽‍♂️', '🤷🏾‍♂️', '🤷🏿‍♂️']],
  ['🤷‍♀️', 'woman shrugging', ['🤷🏻‍♀️', '🤷🏼‍♀️', '🤷🏽‍♀️', '🤷🏾‍♀️', '🤷🏿‍♀️']],
  ['👨‍⚕️', 'man health worker', ['👨🏻‍⚕️', '👨🏼‍⚕️', '👨🏽‍⚕️', '👨🏾‍⚕️', '👨🏿‍⚕️']],
  ['👩‍⚕️', 'woman health worker', ['👩🏻‍⚕️', '👩🏼‍⚕️', '👩🏽‍⚕️', '👩🏾‍⚕️', '👩🏿‍⚕️']],
  ['👨‍🎓', 'man student', ['👨🏻‍🎓', '👨🏼‍🎓', '👨🏽‍🎓', '👨🏾‍🎓', '👨🏿‍🎓']],
  ['👩‍🎓', 'woman student', ['👩🏻‍🎓', '👩🏼‍🎓', '👩🏽‍🎓', '👩🏾‍🎓', '👩🏿‍🎓']],
  ['👨‍🏫', 'man teacher', ['👨🏻‍🏫', '👨🏼‍🏫', '👨🏽‍🏫', '👨🏾‍🏫', '👨🏿‍🏫']],
  ['👩‍🏫', 'woman teacher', ['👩🏻‍🏫', '👩🏼‍🏫', '👩🏽‍🏫', '👩🏾‍🏫', '👩🏿‍🏫']],
  ['👨‍⚖️', 'man judge', ['👨🏻‍⚖️', '👨🏼‍⚖️', '👨🏽‍⚖️', '👨🏾‍⚖️', '👨🏿‍⚖️']],
  ['👩‍⚖️', 'woman judge', ['👩🏻‍⚖️', '👩🏼‍⚖️', '👩🏽‍⚖️', '👩🏾‍⚖️', '👩🏿‍⚖️']],
  ['👨‍🌾', 'man farmer', ['👨🏻‍🌾', '👨🏼‍🌾', '👨🏽‍🌾', '👨🏾‍🌾', '👨🏿‍🌾']],
  ['👩‍🌾', 'woman farmer', ['👩🏻‍🌾', '👩🏼‍🌾', '👩🏽‍🌾', '👩🏾‍🌾', '👩🏿‍🌾']],
  ['👨‍🍳', 'man cook', ['👨🏻‍🍳', '👨🏼‍🍳', '👨🏽‍🍳', '👨🏾‍🍳', '👨🏿‍🍳']],
  ['👩‍🍳', 'woman cook', ['👩🏻‍🍳', '👩🏼‍🍳', '👩🏽‍🍳', '👩🏾‍🍳', '👩🏿‍🍳']],
  ['👨‍🔧', 'man mechanic', ['👨🏻‍🔧', '👨🏼‍🔧', '👨🏽‍🔧', '👨🏾‍🔧', '👨🏿‍🔧']],
  ['👩‍🔧', 'woman mechanic', ['👩🏻‍🔧', '👩🏼‍🔧', '👩🏽‍🔧', '👩🏾‍🔧', '👩🏿‍🔧']],
  ['👨‍🏭', 'man factory worker', ['👨🏻‍🏭', '👨🏼‍🏭', '👨🏽‍🏭', '👨🏾‍🏭', '👨🏿‍🏭']],
  ['👩‍🏭', 'woman factory worker', ['👩🏻‍🏭', '👩🏼‍🏭', '👩🏽‍🏭', '👩🏾‍🏭', '👩🏿‍🏭']],
  ['👨‍💼', 'man office worker', ['👨🏻‍💼', '👨🏼‍💼', '👨🏽‍💼', '👨🏾‍💼', '👨🏿‍💼']],
  ['👩‍💼', 'woman office worker', ['👩🏻‍💼', '👩🏼‍💼', '👩🏽‍💼', '👩🏾‍💼', '👩🏿‍💼']],
  ['👨‍🔬', 'man scientist', ['👨🏻‍🔬', '👨🏼‍🔬', '👨🏽‍🔬', '👨🏾‍🔬', '👨🏿‍🔬']],
  ['👩‍🔬', 'woman scientist', ['👩🏻‍🔬', '👩🏼‍🔬', '👩🏽‍🔬', '👩🏾‍🔬', '👩🏿‍🔬']],
  ['👨‍💻', 'man technologist', ['👨🏻‍💻', '👨🏼‍💻', '👨🏽‍💻', '👨🏾‍💻', '👨🏿‍💻']],
  ['👩‍💻', 'woman technologist', ['👩🏻‍💻', '👩🏼‍💻', '👩🏽‍💻', '👩🏾‍💻', '👩🏿‍💻']],
  ['👨‍🎤', 'man singer', ['👨🏻‍🎤', '👨🏼‍🎤', '👨🏽‍🎤', '👨🏾‍🎤', '👨🏿‍🎤']],
  ['👩‍🎤', 'woman singer', ['👩🏻‍🎤', '👩🏼‍🎤', '👩🏽‍🎤', '👩🏾‍🎤', '👩🏿‍🎤']],
  ['👨‍🎨', 'man artist', ['👨🏻‍🎨', '👨🏼‍🎨', '👨🏽‍🎨', '👨🏾‍🎨', '👨🏿‍🎨']],
  ['👩‍🎨', 'woman artist', ['👩🏻‍🎨', '👩🏼‍🎨', '👩🏽‍🎨', '👩🏾‍🎨', '👩🏿‍🎨']],
  ['👨‍✈️', 'man pilot', ['👨🏻‍✈️', '👨🏼‍✈️', '👨🏽‍✈️', '👨🏾‍✈️', '👨🏿‍✈️']],
  ['👩‍✈️', 'woman pilot', ['👩🏻‍✈️', '👩🏼‍✈️', '👩🏽‍✈️', '👩🏾‍✈️', '👩🏿‍✈️']],
  ['👨‍🚀', 'man astronaut', ['👨🏻‍🚀', '👨🏼‍🚀', '👨🏽‍🚀', '👨🏾‍🚀', '👨🏿‍🚀']],
  ['👩‍🚀', 'woman astronaut', ['👩🏻‍🚀', '👩🏼‍🚀', '👩🏽‍🚀', '👩🏾‍🚀', '👩🏿‍🚀']],
  ['👨‍🚒', 'man firefighter', ['👨🏻‍🚒', '👨🏼‍🚒', '👨🏽‍🚒', '👨🏾‍🚒', '👨🏿‍🚒']],
  ['👩‍🚒', 'woman firefighter', ['👩🏻‍🚒', '👩🏼‍🚒', '👩🏽‍🚒', '👩🏾‍🚒', '👩🏿‍🚒']],
  ['👮', 'police officer', ['👮🏻', '👮🏼', '👮🏽', '👮🏾', '👮🏿']],
  ['👮‍♂️', 'man police officer', ['👮🏻‍♂️', '👮🏼‍♂️', '👮🏽‍♂️', '👮🏾‍♂️', '👮🏿‍♂️']],
  ['👮‍♀️', 'woman police officer', ['👮🏻‍♀️', '👮🏼‍♀️', '👮🏽‍♀️', '👮🏾‍♀️', '👮🏿‍♀️']],
  ['🕵️', 'detective', ['🕵🏻', '🕵🏼', '🕵🏽', '🕵🏾', '🕵🏿']],
  ['🕵️‍♂️', 'man detective', ['🕵🏻‍♂️', '🕵🏼‍♂️', '🕵🏽‍♂️', '🕵🏾‍♂️', '🕵🏿‍♂️']],
  ['🕵️‍♀️', 'woman detective', ['🕵🏻‍♀️', '🕵🏼‍♀️', '🕵🏽‍♀️', '🕵🏾‍♀️', '🕵🏿‍♀️']],
  ['💂', 'guard', ['💂🏻', '💂🏼', '💂🏽', '💂🏾', '💂🏿']],
  ['💂‍♂️', 'man guard', ['💂🏻‍♂️', '💂🏼‍♂️', '💂🏽‍♂️', '💂🏾‍♂️', '💂🏿‍♂️']],
  ['💂‍♀️', 'woman guard', ['💂🏻‍♀️', '💂🏼‍♀️', '💂🏽‍♀️', '💂🏾‍♀️', '💂🏿‍♀️']],
  ['👷', 'construction worker', ['👷🏻', '👷🏼', '👷🏽', '👷🏾', '👷🏿']],
  ['👷‍♂️', 'man construction worker', ['👷🏻‍♂️', '👷🏼‍♂️', '👷🏽‍♂️', '👷🏾‍♂️', '👷🏿‍♂️']],
  ['👷‍♀️', 'woman construction worker', ['👷🏻‍♀️', '👷🏼‍♀️', '👷🏽‍♀️', '👷🏾‍♀️', '👷🏿‍♀️']],
  ['🤴', 'prince', ['🤴🏻', '🤴🏼', '🤴🏽', '🤴🏾', '🤴🏿']],
  ['👸', 'princess', ['👸🏻', '👸🏼', '👸🏽', '👸🏾', '👸🏿']],
  ['👳', 'person wearing turban', ['👳🏻', '👳🏼', '👳🏽', '👳🏾', '👳🏿']],
  ['👳‍♂️', 'man wearing turban', ['👳🏻‍♂️', '👳🏼‍♂️', '👳🏽‍♂️', '👳🏾‍♂️', '👳🏿‍♂️']],
  ['👳‍♀️', 'woman wearing turban', ['👳🏻‍♀️', '👳🏼‍♀️', '👳🏽‍♀️', '👳🏾‍♀️', '👳🏿‍♀️']],
  ['👲', 'person with skullcap', ['👲🏻', '👲🏼', '👲🏽', '👲🏾', '👲🏿']],
  ['🧕', 'woman with headscarf', ['🧕🏻', '🧕🏼', '🧕🏽', '🧕🏾', '🧕🏿']],
  ['🤵', 'person in tuxedo', ['🤵🏻', '🤵🏼', '🤵🏽', '🤵🏾', '🤵🏿']],
  ['👰', 'person with veil', ['👰🏻', '👰🏼', '👰🏽', '👰🏾', '👰🏿']],
  ['🤰', 'pregnant woman', ['🤰🏻', '🤰🏼', '🤰🏽', '🤰🏾', '🤰🏿']],
  ['🤱', 'breast-feeding', ['🤱🏻', '🤱🏼', '🤱🏽', '🤱🏾', '🤱🏿']],
  ['👩‍🍼', 'woman feeding baby', ['👩🏻‍🍼', '👩🏼‍🍼', '👩🏽‍🍼', '👩🏾‍🍼', '👩🏿‍🍼']],
  ['👨‍🍼', 'man feeding baby', ['👨🏻‍🍼', '👨🏼‍🍼', '👨🏽‍🍼', '👨🏾‍🍼', '👨🏿‍🍼']],
  ['🧑‍🍼', 'person feeding baby', ['🧑🏻‍🍼', '🧑🏼‍🍼', '🧑🏽‍🍼', '🧑🏾‍🍼', '🧑🏿‍🍼']],
  ['👼', 'baby angel', ['👼🏻', '👼🏼', '👼🏽', '👼🏾', '👼🏿']],
  ['🎅', 'Santa Claus', ['🎅🏻', '🎅🏼', '🎅🏽', '🎅🏾', '🎅🏿']],
  ['🤶', 'Mrs. Claus', ['🤶🏻', '🤶🏼', '🤶🏽', '🤶🏾', '🤶🏿']],
  ['🧑‍🎄', 'mx claus', ['🧑🏻‍🎄', '🧑🏼‍🎄', '🧑🏽‍🎄', '🧑🏾‍🎄', '🧑🏿‍🎄']],
  ['🦸', 'superhero', ['🦸🏻', '🦸🏼', '🦸🏽', '🦸🏾', '🦸🏿']],
  ['🦸‍♂️', 'man superhero', ['🦸🏻‍♂️', '🦸🏼‍♂️', '🦸🏽‍♂️', '🦸🏾‍♂️', '🦸🏿‍♂️']],
  ['🦸‍♀️', 'woman superhero', ['🦸🏻‍♀️', '🦸🏼‍♀️', '🦸🏽‍♀️', '🦸🏾‍♀️', '🦸🏿‍♀️']],
  ['🦹', 'supervillain', ['🦹🏻', '🦹🏼', '🦹🏽', '🦹🏾', '🦹🏿']],
  ['🦹‍♂️', 'man supervillain', ['🦹🏻‍♂️', '🦹🏼‍♂️', '🦹🏽‍♂️', '🦹🏾‍♂️', '🦹🏿‍♂️']],
  ['🦹‍♀️', 'woman supervillain', ['🦹🏻‍♀️', '🦹🏼‍♀️', '🦹🏽‍♀️', '🦹🏾‍♀️', '🦹🏿‍♀️']],
  ['🧙', 'mage', ['🧙🏻', '🧙🏼', '🧙🏽', '🧙🏾', '🧙🏿']],
  ['🧙‍♂️', 'man mage', ['🧙🏻‍♂️', '🧙🏼‍♂️', '🧙🏽‍♂️', '🧙🏾‍♂️', '🧙🏿‍♂️']],
  ['🧙‍♀️', 'woman mage', ['🧙🏻‍♀️', '🧙🏼‍♀️', '🧙🏽‍♀️', '🧙🏾‍♀️', '🧙🏿‍♀️']],
  ['🧚', 'fairy', ['🧚🏻', '🧚🏼', '🧚🏽', '🧚🏾', '🧚🏿']],
  ['🧚‍♂️', 'man fairy', ['🧚🏻‍♂️', '🧚🏼‍♂️', '🧚🏽‍♂️', '🧚🏾‍♂️', '🧚🏿‍♂️']],
  ['🧚‍♀️', 'woman fairy', ['🧚🏻‍♀️', '🧚🏼‍♀️', '🧚🏽‍♀️', '🧚🏾‍♀️', '🧚🏿‍♀️']],
  ['🧛', 'vampire', ['🧛🏻', '🧛🏼', '🧛🏽', '🧛🏾', '🧛🏿']],
  ['🧛‍♂️', 'man vampire', ['🧛🏻‍♂️', '🧛🏼‍♂️', '🧛🏽‍♂️', '🧛🏾‍♂️', '🧛🏿‍♂️']],
  ['🧛‍♀️', 'woman vampire', ['🧛🏻‍♀️', '🧛🏼‍♀️', '🧛🏽‍♀️', '🧛🏾‍♀️', '🧛🏿‍♀️']],
  ['🧜', 'merperson', ['🧜🏻', '🧜🏼', '🧜🏽', '🧜🏾', '🧜🏿']],
  ['🧜‍♂️', 'merman', ['🧜🏻‍♂️', '🧜🏼‍♂️', '🧜🏽‍♂️', '🧜🏾‍♂️', '🧜🏿‍♂️']],
  ['🧜‍♀️', 'mermaid', ['🧜🏻‍♀️', '🧜🏼‍♀️', '🧜🏽‍♀️', '🧜🏾‍♀️', '🧜🏿‍♀️']],
  ['🧝', 'elf', ['🧝🏻', '🧝🏼', '🧝🏽', '🧝🏾', '🧝🏿']],
  ['🧝‍♂️', 'man elf', ['🧝🏻‍♂️', '🧝🏼‍♂️', '🧝🏽‍♂️', '🧝🏾‍♂️', '🧝🏿‍♂️']],
  ['🧝‍♀️', 'woman elf', ['🧝🏻‍♀️', '🧝🏼‍♀️', '🧝🏽‍♀️', '🧝🏾‍♀️', '🧝🏿‍♀️']],
  ['🧞', 'genie'],
  ['🧞‍♂️', 'man genie'],
  ['🧞‍♀️', 'woman genie'],
  ['🧟', 'zombie'],
  ['🧟‍♂️', 'man zombie'],
  ['🧟‍♀️', 'woman zombie'],
  ['💆', 'person getting massage', ['💆🏻', '💆🏼', '💆🏽', '💆🏾', '💆🏿']],
  ['💆‍♂️', 'man getting massage', ['💆🏻‍♂️', '💆🏼‍♂️', '💆🏽‍♂️', '💆🏾‍♂️', '💆🏿‍♂️']],
  ['💆‍♀️', 'woman getting massage', ['💆🏻‍♀️', '💆🏼‍♀️', '💆🏽‍♀️', '💆🏾‍♀️', '💆🏿‍♀️']],
  ['💇', 'person getting haircut', ['💇🏻', '💇🏼', '💇🏽', '💇🏾', '💇🏿']],
  ['💇‍♂️', 'man getting haircut', ['💇🏻‍♂️', '💇🏼‍♂️', '💇🏽‍♂️', '💇🏾‍♂️', '💇🏿‍♂️']],
  ['💇‍♀️', 'woman getting haircut', ['💇🏻‍♀️', '💇🏼‍♀️', '💇🏽‍♀️', '💇🏾‍♀️', '💇🏿‍♀️']],
  ['🚶', 'person walking', ['🚶🏻', '🚶🏼', '🚶🏽', '🚶🏾', '🚶🏿']],
  ['🚶‍♂️', 'man walking', ['🚶🏻‍♂️', '🚶🏼‍♂️', '🚶🏽‍♂️', '🚶🏾‍♂️', '🚶🏿‍♂️']],
  ['🚶‍♀️', 'woman walking', ['🚶🏻‍♀️', '🚶🏼‍♀️', '🚶🏽‍♀️', '🚶🏾‍♀️', '🚶🏿‍♀️']],
  ['🧍', 'person standing', ['🧍🏻', '🧍🏼', '🧍🏽', '🧍🏾', '🧍🏿']],
  ['🧍‍♂️', 'man standing', ['🧍🏻‍♂️', '🧍🏼‍♂️', '🧍🏽‍♂️', '🧍🏾‍♂️', '🧍🏿‍♂️']],
  ['🧍‍♀️', 'woman standing', ['🧍🏻‍♀️', '🧍🏼‍♀️', '🧍🏽‍♀️', '🧍🏾‍♀️', '🧍🏿‍♀️']],
  ['🧎', 'person kneeling', ['🧎🏻', '🧎🏼', '🧎🏽', '🧎🏾', '🧎🏿']],
  ['🧎‍♂️', 'man kneeling', ['🧎🏻‍♂️', '🧎🏼‍♂️', '🧎🏽‍♂️', '🧎🏾‍♂️', '🧎🏿‍♂️']],
  ['🧎‍♀️', 'woman kneeling', ['🧎🏻‍♀️', '🧎🏼‍♀️', '🧎🏽‍♀️', '🧎🏾‍♀️', '🧎🏿‍♀️']],
  ['👨‍🦯', 'man with white cane', ['👨🏻‍🦯', '👨🏼‍🦯', '👨🏽‍🦯', '👨🏾‍🦯', '👨🏿‍🦯']],
  ['👩‍🦯', 'woman with white cane', ['👩🏻‍🦯', '👩🏼‍🦯', '👩🏽‍🦯', '👩🏾‍🦯', '👩🏿‍🦯']],
  ['👨‍🦼', 'man in motorized wheelchair', ['👨🏻‍🦼', '👨🏼‍🦼', '👨🏽‍🦼', '👨🏾‍🦼', '👨🏿‍🦼']],
  ['👩‍🦼', 'woman in motorized wheelchair', ['👩🏻‍🦼', '👩🏼‍🦼', '👩🏽‍🦼', '👩🏾‍🦼', '👩🏿‍🦼']],
  ['👨‍🦽', 'man in manual wheelchair', ['👨🏻‍🦽', '👨🏼‍🦽', '👨🏽‍🦽', '👨🏾‍🦽', '👨🏿‍🦽']],
  ['👩‍🦽', 'woman in manual wheelchair', ['👩🏻‍🦽', '👩🏼‍🦽', '👩🏽‍🦽', '👩🏾‍🦽', '👩🏿‍🦽']],
  ['🏃', 'person running', ['🏃🏻', '🏃🏼', '🏃🏽', '🏃🏾', '🏃🏿']],
  ['🏃‍♂️', 'man running', ['🏃🏻‍♂️', '🏃🏼‍♂️', '🏃🏽‍♂️', '🏃🏾‍♂️', '🏃🏿‍♂️']],
  ['🏃‍♀️', 'woman running', ['🏃🏻‍♀️', '🏃🏼‍♀️', '🏃🏽‍♀️', '🏃🏾‍♀️', '🏃🏿‍♀️']],
  ['💃', 'woman dancing', ['💃🏻', '💃🏼', '💃🏽', '💃🏾', '💃🏿']],
  ['🕺', 'man dancing', ['🕺🏻', '🕺🏼', '🕺🏽', '🕺🏾', '🕺🏿']],
  ['🕴️', 'person in suit levitating', ['🕴🏻', '🕴🏼', '🕴🏽', '🕴🏾', '🕴🏿']],
  ['👯', 'people with bunny ears'],
  ['👯‍♂️', 'men with bunny ears'],
  ['👯‍♀️', 'women with bunny ears'],
  ['🧖', 'person in steamy room', ['🧖🏻', '🧖🏼', '🧖🏽', '🧖🏾', '🧖🏿']],
  ['🧖‍♂️', 'man in steamy room', ['🧖🏻‍♂️', '🧖🏼‍♂️', '🧖🏽‍♂️', '🧖🏾‍♂️', '🧖🏿‍♂️']],
  ['🧖‍♀️', 'woman in steamy room', ['🧖🏻‍♀️', '🧖🏼‍♀️', '🧖🏽‍♀️', '🧖🏾‍♀️', '🧖🏿‍♀️']],
  ['🧗', 'person climbing', ['🧗🏻', '🧗🏼', '🧗🏽', '🧗🏾', '🧗🏿']],
  ['🧗‍♂️', 'man climbing', ['🧗🏻‍♂️', '🧗🏼‍♂️', '🧗🏽‍♂️', '🧗🏾‍♂️', '🧗🏿‍♂️']],
  ['🧗‍♀️', 'woman climbing', ['🧗🏻‍♀️', '🧗🏼‍♀️', '🧗🏽‍♀️', '🧗🏾‍♀️', '🧗🏿‍♀️']],
  ['🤺', 'person fencing'],
  ['🏇', 'horse racing', ['🏇🏻', '🏇🏼', '🏇🏽', '🏇🏾', '🏇🏿']],
  ['⛷️', 'skier'],
  ['🏂', 'snowboarder', ['🏂🏻', '🏂🏼', '🏂🏽', '🏂🏾', '🏂🏿']],
  ['🏌️', 'person golfing', ['🏌🏻', '🏌🏼', '🏌🏽', '🏌🏾', '🏌🏿']],
  ['🏌️‍♂️', 'man golfing', ['🏌🏻‍♂️', '🏌🏼‍♂️', '🏌🏽‍♂️', '🏌🏾‍♂️', '🏌🏿‍♂️']],
  ['🏌️‍♀️', 'woman golfing', ['🏌🏻‍♀️', '🏌🏼‍♀️', '🏌🏽‍♀️', '🏌🏾‍♀️', '🏌🏿‍♀️']],
  ['🏄', 'person surfing', ['🏄🏻', '🏄🏼', '🏄🏽', '🏄🏾', '🏄🏿']],
  ['🏄‍♂️', 'man surfing', ['🏄🏻‍♂️', '🏄🏼‍♂️', '🏄🏽‍♂️', '🏄🏾‍♂️', '🏄🏿‍♂️']],
  ['🏄‍♀️', 'woman surfing', ['🏄🏻‍♀️', '🏄🏼‍♀️', '🏄🏽‍♀️', '🏄🏾‍♀️', '🏄🏿‍♀️']],
  ['🚣', 'person rowing boat', ['🚣🏻', '🚣🏼', '🚣🏽', '🚣🏾', '🚣🏿']],
  ['🚣‍♂️', 'man rowing boat', ['🚣🏻‍♂️', '🚣🏼‍♂️', '🚣🏽‍♂️', '🚣🏾‍♂️', '🚣🏿‍♂️']],
  ['🚣‍♀️', 'woman rowing boat', ['🚣🏻‍♀️', '🚣🏼‍♀️', '🚣🏽‍♀️', '🚣🏾‍♀️', '🚣🏿‍♀️']],
  ['🏊', 'person swimming', ['🏊🏻', '🏊🏼', '🏊🏽', '🏊🏾', '🏊🏿']],
  ['🏊‍♂️', 'man swimming', ['🏊🏻‍♂️', '🏊🏼‍♂️', '🏊🏽‍♂️', '🏊🏾‍♂️', '🏊🏿‍♂️']],
  ['🏊‍♀️', 'woman swimming', ['🏊🏻‍♀️', '🏊🏼‍♀️', '🏊🏽‍♀️', '🏊🏾‍♀️', '🏊🏿‍♀️']],
  ['⛹️', 'person bouncing ball', ['⛹🏻', '⛹🏼', '⛹🏽', '⛹🏾', '⛹🏿']],
  ['⛹️‍♂️', 'man bouncing ball', ['⛹🏻‍♂️', '⛹🏼‍♂️', '⛹🏽‍♂️', '⛹🏾‍♂️', '⛹🏿‍♂️']],
  ['⛹️‍♀️', 'woman bouncing ball', ['⛹🏻‍♀️', '⛹🏼‍♀️', '⛹🏽‍♀️', '⛹🏾‍♀️', '⛹🏿‍♀️']],
  ['🏋️', 'person lifting weights', ['🏋🏻', '🏋🏼', '🏋🏽', '🏋🏾', '🏋🏿']],
  ['🏋️‍♂️', 'man lifting weights', ['🏋🏻‍♂️', '🏋🏼‍♂️', '🏋🏽‍♂️', '🏋🏾‍♂️', '🏋🏿‍♂️']],
  ['🏋️‍♀️', 'woman lifting weights', ['🏋🏻‍♀️', '🏋🏼‍♀️', '🏋🏽‍♀️', '🏋🏾‍♀️', '🏋🏿‍♀️']],
  ['🚴', 'person biking', ['🚴🏻', '🚴🏼', '🚴🏽', '🚴🏾', '🚴🏿']],
  ['🚴‍♂️', 'man biking', ['🚴🏻‍♂️', '🚴🏼‍♂️', '🚴🏽‍♂️', '🚴🏾‍♂️', '🚴🏿‍♂️']],
  ['🚴‍♀️', 'woman biking', ['🚴🏻‍♀️', '🚴🏼‍♀️', '🚴🏽‍♀️', '🚴🏾‍♀️', '🚴🏿‍♀️']],
  ['🚵', 'person mountain biking', ['🚵🏻', '🚵🏼', '🚵🏽', '🚵🏾', '🚵🏿']],
  ['🚵‍♂️', 'man mountain biking', ['🚵🏻‍♂️', '🚵🏼‍♂️', '🚵🏽‍♂️', '🚵🏾‍♂️', '🚵🏿‍♂️']],
  ['🚵‍♀️', 'woman mountain biking', ['🚵🏻‍♀️', '🚵🏼‍♀️', '🚵🏽‍♀️', '🚵🏾‍♀️', '🚵🏿‍♀️']],
  ['🤸', 'person cartwheeling', ['🤸🏻', '🤸🏼', '🤸🏽', '🤸🏾', '🤸🏿']],
  ['🤸‍♂️', 'man cartwheeling', ['🤸🏻‍♂️', '🤸🏼‍♂️', '🤸🏽‍♂️', '🤸🏾‍♂️', '🤸🏿‍♂️']],
  ['🤸‍♀️', 'woman cartwheeling', ['🤸🏻‍♀️', '🤸🏼‍♀️', '🤸🏽‍♀️', '🤸🏾‍♀️', '🤸🏿‍♀️']],
  ['🤼', 'people wrestling'],
  ['🤼‍♂️', 'men wrestling'],
  ['🤼‍♀️', 'women wrestling'],
  ['🤽', 'person playing water polo', ['🤽🏻', '🤽🏼', '🤽🏽', '🤽🏾', '🤽🏿']],
  ['🤽‍♂️', 'man playing water polo', ['🤽🏻‍♂️', '🤽🏼‍♂️', '🤽🏽‍♂️', '🤽🏾‍♂️', '🤽🏿‍♂️']],
  ['🤽‍♀️', 'woman playing water polo', ['🤽🏻‍♀️', '🤽🏼‍♀️', '🤽🏽‍♀️', '🤽🏾‍♀️', '🤽🏿‍♀️']],
  ['🤾', 'person playing handball', ['🤾🏻', '🤾🏼', '🤾🏽', '🤾🏾', '🤾🏿']],
  ['🤾‍♂️', 'man playing handball', ['🤾🏻‍♂️', '🤾🏼‍♂️', '🤾🏽‍♂️', '🤾🏾‍♂️', '🤾🏿‍♂️']],
  ['🤾‍♀️', 'woman playing handball', ['🤾🏻‍♀️', '🤾🏼‍♀️', '🤾🏽‍♀️', '🤾🏾‍♀️', '🤾🏿‍♀️']],
  ['🤹', 'person juggling', ['🤹🏻', '🤹🏼', '🤹🏽', '🤹🏾', '🤹🏿']],
  ['🤹‍♂️', 'man juggling', ['🤹🏻‍♂️', '🤹🏼‍♂️', '🤹🏽‍♂️', '🤹🏾‍♂️', '🤹🏿‍♂️']],
  ['🤹‍♀️', 'woman juggling', ['🤹🏻‍♀️', '🤹🏼‍♀️', '🤹🏽‍♀️', '🤹🏾‍♀️', '🤹🏿‍♀️']],
  ['🧘', 'person in lotus position', ['🧘🏻', '🧘🏼', '🧘🏽', '🧘🏾', '🧘🏿']],
  ['🧘‍♂️', 'man in lotus position', ['🧘🏻‍♂️', '🧘🏼‍♂️', '🧘🏽‍♂️', '🧘🏾‍♂️', '🧘🏿‍♂️']],
  ['🧘‍♀️', 'woman in lotus position', ['🧘🏻‍♀️', '🧘🏼‍♀️', '🧘🏽‍♀️', '🧘🏾‍♀️', '🧘🏿‍♀️']],
  ['🛀', 'person taking bath', ['🛀🏻', '🛀🏼', '🛀🏽', '🛀🏾', '🛀🏿']],
  ['🛌', 'person in bed', ['🛌🏻', '🛌🏼', '🛌🏽', '🛌🏾', '🛌🏿']],
  [
    '🧑‍🤝‍🧑',
    'people holding hands',
    [
      '🧑🏻‍🤝‍🧑🏻',
      '🧑🏻‍🤝‍🧑🏼',
      '🧑🏻‍🤝‍🧑🏽',
      '🧑🏻‍🤝‍🧑🏾',
      '🧑🏻‍🤝‍🧑🏿',
      '🧑🏼‍🤝‍🧑🏻',
      '🧑🏼‍🤝‍🧑🏼',
      '🧑🏼‍🤝‍🧑🏽',
      '🧑🏼‍🤝‍🧑🏾',
      '🧑🏼‍🤝‍🧑🏿',
      '🧑🏽‍🤝‍🧑🏻',
      '🧑🏽‍🤝‍🧑🏼',
      '🧑🏽‍🤝‍🧑🏽',
      '🧑🏽‍🤝‍🧑🏾',
      '🧑🏽‍🤝‍🧑🏿',
      '🧑🏾‍🤝‍🧑🏻',
      '🧑🏾‍🤝‍🧑🏼',
      '🧑🏾‍🤝‍🧑🏽',
      '🧑🏾‍🤝‍🧑🏾',
      '🧑🏾‍🤝‍🧑🏿',
      '🧑🏿‍🤝‍🧑🏻',
      '🧑🏿‍🤝‍🧑🏼',
      '🧑🏿‍🤝‍🧑🏽',
      '🧑🏿‍🤝‍🧑🏾',
      '🧑🏿‍🤝‍🧑🏿'
    ]
  ],
  [
    '👭',
    'women holding hands',
    [
      '👭🏻',
      '👩🏻‍🤝‍👩🏼',
      '👩🏻‍🤝‍👩🏽',
      '👩🏻‍🤝‍👩🏾',
      '👩🏻‍🤝‍👩🏿',
      '👩🏼‍🤝‍👩🏻',
      '👭🏼',
      '👩🏼‍🤝‍👩🏽',
      '👩🏼‍🤝‍👩🏾',
      '👩🏼‍🤝‍👩🏿',
      '👩🏽‍🤝‍👩🏻',
      '👩🏽‍🤝‍👩🏼',
      '👭🏽',
      '👩🏽‍🤝‍👩🏾',
      '👩🏽‍🤝‍👩🏿',
      '👩🏾‍🤝‍👩🏻',
      '👩🏾‍🤝‍👩🏼',
      '👩🏾‍🤝‍👩🏽',
      '👭🏾',
      '👩🏾‍🤝‍👩🏿',
      '👩🏿‍🤝‍👩🏻',
      '👩🏿‍🤝‍👩🏼',
      '👩🏿‍🤝‍👩🏽',
      '👩🏿‍🤝‍👩🏾',
      '👭🏿'
    ]
  ],
  [
    '👫',
    'woman and man holding hands',
    [
      '👫🏻',
      '👩🏻‍🤝‍👨🏼',
      '👩🏻‍🤝‍👨🏽',
      '👩🏻‍🤝‍👨🏾',
      '👩🏻‍🤝‍👨🏿',
      '👩🏼‍🤝‍👨🏻',
      '👫🏼',
      '👩🏼‍🤝‍👨🏽',
      '👩🏼‍🤝‍👨🏾',
      '👩🏼‍🤝‍👨🏿',
      '👩🏽‍🤝‍👨🏻',
      '👩🏽‍🤝‍👨🏼',
      '👫🏽',
      '👩🏽‍🤝‍👨🏾',
      '👩🏽‍🤝‍👨🏿',
      '👩🏾‍🤝‍👨🏻',
      '👩🏾‍🤝‍👨🏼',
      '👩🏾‍🤝‍👨🏽',
      '👫🏾',
      '👩🏾‍🤝‍👨🏿',
      '👩🏿‍🤝‍👨🏻',
      '👩🏿‍🤝‍👨🏼',
      '👩🏿‍🤝‍👨🏽',
      '👩🏿‍🤝‍👨🏾',
      '👫🏿'
    ]
  ],
  [
    '👬',
    'men holding hands',
    [
      '👬🏻',
      '👨🏻‍🤝‍👨🏼',
      '👨🏻‍🤝‍👨🏽',
      '👨🏻‍🤝‍👨🏾',
      '👨🏻‍🤝‍👨🏿',
      '👨🏼‍🤝‍👨🏻',
      '👬🏼',
      '👨🏼‍🤝‍👨🏽',
      '👨🏼‍🤝‍👨🏾',
      '👨🏼‍🤝‍👨🏿',
      '👨🏽‍🤝‍👨🏻',
      '👨🏽‍🤝‍👨🏼',
      '👬🏽',
      '👨🏽‍🤝‍👨🏾',
      '👨🏽‍🤝‍👨🏿',
      '👨🏾‍🤝‍👨🏻',
      '👨🏾‍🤝‍👨🏼',
      '👨🏾‍🤝‍👨🏽',
      '👬🏾',
      '👨🏾‍🤝‍👨🏿',
      '👨🏿‍🤝‍👨🏻',
      '👨🏿‍🤝‍👨🏼',
      '👨🏿‍🤝‍👨🏽',
      '👨🏿‍🤝‍👨🏾',
      '👬🏿'
    ]
  ],
  ['💏', 'kiss', ['👩‍❤️‍💋‍👨', '👨‍❤️‍💋‍👨', '👩‍❤️‍💋‍👩']],
  ['💑', 'couple with heart', ['👩‍❤️‍👨', '👨‍❤️‍👨', '👩‍❤️‍👩']],
  ['👪', 'family'],
  ['👨‍👩‍👦', 'family: man, woman, boy'],
  ['👨‍👩‍👧', 'family: man, woman, girl'],
  ['👨‍👩‍👧‍👦', 'family: man, woman, girl, boy'],
  ['👨‍👩‍👦‍👦', 'family: man, woman, boy, boy'],
  ['👨‍👩‍👧‍👧', 'family: man, woman, girl, girl'],
  ['👨‍👨‍👦', 'family: man, man, boy'],
  ['👨‍👨‍👧', 'family: man, man, girl'],
  ['👨‍👨‍👧‍👦', 'family: man, man, girl, boy'],
  ['👨‍👨‍👦‍👦', 'family: man, man, boy, boy'],
  ['👨‍👨‍👧‍👧', 'family: man, man, girl, girl'],
  ['👩‍👩‍👦', 'family: woman, woman, boy'],
  ['👩‍👩‍👧', 'family: woman, woman, girl'],
  ['👩‍👩‍👧‍👦', 'family: woman, woman, girl, boy'],
  ['👩‍👩‍👦‍👦', 'family: woman, woman, boy, boy'],
  ['👩‍👩‍👧‍👧', 'family: woman, woman, girl, girl'],
  ['👨‍👦', 'family: man, boy'],
  ['👨‍👦‍👦', 'family: man, boy, boy'],
  ['👨‍👧', 'family: man, girl'],
  ['👨‍👧‍👦', 'family: man, girl, boy'],
  ['👨‍👧‍👧', 'family: man, girl, girl'],
  ['👩‍👦', 'family: woman, boy'],
  ['👩‍👦‍👦', 'family: woman, boy, boy'],
  ['👩‍👧', 'family: woman, girl'],
  ['👩‍👧‍👦', 'family: woman, girl, boy'],
  ['👩‍👧‍👧', 'family: woman, girl, girl'],
  ['🗣️', 'speaking head'],
  ['👤', 'bust in silhouette'],
  ['👥', 'busts in silhouette'],
  ['👣', 'footprints']
];
